import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import { isEnabled, isDisabled, checkPermissionWOproduct, convertToBase64, findSettingValue } from '@helpers/utility'
import Referralrock from './popups/referralrock'
import Icon from '@components/icons'
import { segmentEventTrack, trackWithIDAndRole } from '@helpers/segment'
import withPermissionHookHOC from '@helpers/WithPermissionHookHOC'
import GlobalCreate from './globalCreateModel'
import { featureFlags } from '@helpers/configcat'
import { readSegregateV2Flag } from '@helpers/segregateFlag'

import { DialogListener } from '@containers/common/DialogListener'
import _ from 'lodash'
import { withTranslation } from 'react-i18next'

const mapDispatchToProps = ({ auth, messenger, accountsettings, payment }) => {
  return {
    ...auth,
    ...messenger,
    ...accountsettings,
    ...payment
  }
}

const mapStateToProps = ({ auth, messenger, accountsettings, payment }) => {
  return {
    ...auth,
    ...messenger,
    ...accountsettings,
    ...payment
  }
}

class SideBar extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      userMenuVisible: false,
      showDot: true,
      hideAppStore: true,
      hideReferral: true,
      payment_v2: true,
      invoices_v2: true,
      showInvoiceTab: false
    }
  }

  logout() {
    this.props.logout()
  }

  async componentDidMount() {
    await this.init()
    await this.props.fetchOnboarding()
    let segregatePricingFlag = ''
    if (this.props.me && this.props.me._id) {
      await this.props.getSiteParent({
        parentID: this.props.me._id
      })
      segregatePricingFlag =
        typeof this.props.achPaymentFeatureFlag !== 'undefined'
          ? this.props.achPaymentFeatureFlag
          : await this.props.getConfigFlag({
              flag: featureFlags.segregate_invoice_payments.name,
              parentID: this.props.me._id
            })
    }

    if (segregatePricingFlag) {
      const payment_v2_flag = await readSegregateV2Flag(
        this.props.me.account_settings.payment_type,
        'payment_v2',
        this.props.products,
        this.props.me
      )
      const invoice_v2_flag = await readSegregateV2Flag(
        this.props.me.account_settings.payment_type,
        'invoices_v2',
        this.props.products,
        this.props.me
      )
      this.setState({ payment_v2: payment_v2_flag, invoices_v2: invoice_v2_flag, showInvoiceTab: invoice_v2_flag })
    }
  }
  // 555
  static async getDerivedStateFromProps(props) {
    const checkGetSegregatePricingFlag = props.me && props.me._id && typeof props.achPaymentFeatureFlag === 'undefined'
    if (checkGetSegregatePricingFlag) {
      const segregatePricingFlag =
        typeof props.achPaymentFeatureFlag !== 'undefined'
          ? props.achPaymentFeatureFlag
          : await props.getConfigFlag({ flag: featureFlags.segregate_invoice_payments.name, parentID: props.me._id })
      if (segregatePricingFlag && !props.me.account_settings.payment.isInvoiceUnlocked) {
        await props.unlockInvoices({ user: props.user.user_unique_id })
      }
    }
  }

  async init() {
    const settingData = await this.props.getSettingsData({
      option_key: convertToBase64('hide_app_store, hide_referral')
    })
    if (settingData && settingData.settings) {
      const hideAppStore = await findSettingValue(settingData.settings, 'hide_app_store', this.props.me.platform_id)
      const hideReferral = await findSettingValue(settingData.settings, 'hide_referral', this.props.me.platform_id)
      await this.setState({ hideReferral, hideAppStore })
    }
  }

  getTransactionPageRoute() {
    const { onBoardingData, me } = this.props
    if (me && me.account_settings && me.account_settings.payment_type !== 'stripe') {
      if (
        me &&
        me.account_settings &&
        me.account_settings.payment &&
        (me.account_settings.payment.merchant_id || me.account_settings.payment.isInvoiceUnlocked)
      ) {
        if (me.role_id && me.role_id.role_name === 'trial') {
          return `/trial/transactions`
        } else {
          return `/transactions`
        }
      } else {
        return `/trial/activate-transactions`
      }
    } else {
      if (
        me &&
        me.account_settings &&
        me.account_settings.payment &&
        ((onBoardingData && onBoardingData.status === 'tested') || me.account_settings.payment.isInvoiceUnlocked)
      ) {
        if (me.role_id && me.role_id.role_name === 'trial') {
          return `/trial/transactions`
        } else {
          return `/transactions`
        }
      } else if (onBoardingData && onBoardingData.status === 'new') {
        return `/trial/activate-transactions`
      } else {
        return `/trial/activate-transactions`
      }
    }
  }

  getInvoicePageRoute() {
    const { onBoardingData, me } = this.props
    if (me && me.account_settings && me.account_settings.payment_type !== 'stripe') {
      if (
        me &&
        me.account_settings &&
        me.account_settings.payment &&
        (me.account_settings.payment.merchant_id || me.account_settings.payment.isInvoiceUnlocked)
      ) {
        if (me.role_id && me.role_id.role_name === 'trial') {
          return `/trial/invoices`
        } else if (this.state.invoices_v2) {
          return `/trial/invoices`
        } else {
          return `/invoices`
        }
      }
    } else {
      if (
        me &&
        me.account_settings &&
        me.account_settings.payment &&
        ((onBoardingData && onBoardingData.status === 'tested') || me.account_settings.payment.isInvoiceUnlocked)
      ) {
        if (me.role_id && me.role_id.role_name === 'trial') {
          return `/trial/invoices`
        } else {
          return `/invoices`
        }
      } else if (onBoardingData && onBoardingData.status === 'new') {
        return `/trial/activate-transactions`
      } else {
        return `/trial/activate-transactions`
      }
    }
  }

  openReferralrocks() {
    let os = 'desktop'
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      os = 'ios'
      if (/Android/.test(navigator.userAgent)) {
        os = 'android'
      }
    }
    const data = {
      event: 'referral',
      properties: {
        user_id: this.props.me && this.props.me._id,
        role:
          this.props.me &&
          this.props.me.role_id &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name === 'trial'
            ? 'free-trial'
            : this.props.me.role_id.role_name, // “free_trial”, “retail”, “enterprise”
        os: os,
        location: 'navigation',
        step: 'refer_intent'
      }
    }
    segmentEventTrack(data)
    this.setState({ openReferralrock: !this.state.openReferralrock })
  }

  trackUserEnteringInstantSites() {
    trackWithIDAndRole({
      event: 'Instant_Sites_Click',
      user_id: this.props.user.user_unique_id,
      role: this.props.user.role
    })
  }

  cancleRferralrock() {
    this.setState({ openReferralrock: false })
  }

  goToBookingModule() {
    const bookingurl = localStorage.getItem('bookingurl')
    if (bookingurl) {
      return `/${bookingurl}`
    } else {
      if (
        this.props.accountsettings &&
        (this.props.accountsettings.show_pending_appointment || this.props.accountsettings.show_confirmed_appointment)
      ) {
        return `/appointments/calendar`
      } else {
        return `/appointments`
      }
    }
  }

  handelCreateGlobal() {
    this.setState({ showGlobalCreateModal: true })
  }

  handelCreateGlobalClose() {
    this.setState({ showGlobalCreateModal: false })
  }

  render() {
    const t = this.props.t
    // var page = this.props.page || 'home'
    const { showGlobalCreateModal } = this.state
    const { page = 'home', permissions: { isReadOnlyRole } = {} } = this.props

    const shouldShowEstimates =
      isEnabled('estimate', this.props.products) && _.get(this.props, 'me.features.estimates', false)
    const shouldShowBusinessInsurance =
      isEnabled('insurance', this.props.products) &&
      _.get(this.props, 'me.features.businessInsurance', false) &&
      !this.props.showUpgradeTrial

    const checkCustomerOrStaff = () => {
      return this.props.user.role && (this.props.user.role === 'customer' || this.props.user.role === 'staff')
    }

    const checkTrialOrSMBPartner = () => {
      return (
        (this.props.user.role && (this.props.user.role === 'trial' || this.props.user.isSMBPartner)) || isReadOnlyRole
      )
    }

    const checkNotPartnerNoManagerAndSMB = () => {
      return (
        this.props.user.role &&
        (this.props.user.role !== 'partner' || this.props.user.isSMBPartner) &&
        this.props.user.role !== 'manager'
      )
    }

    const productCheck = (product, disabled = '') => {
      if (disabled) {
        return !isEnabled(product, this.props.products) && !isDisabled(disabled, this.props.disabledProducts)
      }
      return this.props.products && isEnabled(product, this.props.products)
    }

    const renderSidebar = () => {
      const navItems = [
        {
          id: 'navHome',
          name: 'home',
          translation: 'home',
          event: 'enterprise_sidebar_home',
          navigate: '/home',
          navigateSMB: '/trial/home',
          icon: 'homeNormal',
          activeIcon: 'homeActive',
          test: 'sidebar-home',
          customerOrStaff: () => checkCustomerOrStaff(),
          trialOrPartner: () => checkTrialOrSMBPartner()
        },
        {
          id: 'navContacthub',
          name: 'contacts',
          translation: 'contact-hub',
          event: '',
          navigate: '/contacts/mycontacts',
          navigateSMB: '',
          icon: 'contactNormal',
          activeIcon: 'contactActive',
          test: 'sidebar-contact-hub',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('contact-manager')
        },
        {
          id: 'navContacthub',
          name: 'contacts',
          translation: 'contact-hub',
          event: '',
          navigate: '/trial/contacts',
          navigateSMB: '/trial/contacts',
          icon: 'contactNormal',
          activeIcon: 'contactActive',
          test: 'sidebar-contact-hub',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            productCheck('contact-manager', 'contact-manager') && (checkCustomerOrStaff() || checkTrialOrSMBPartner())
        },
        {
          id: 'navWebsiteTrial',
          name: 'estimates',
          translation: 'estimates',
          event: '',
          navigate: '/estimates',
          navigateSMB: '',
          icon: 'estimates',
          activeIcon: 'estimatesActive',
          test: 'sidebar-sites',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => shouldShowEstimates
        },
        {
          id: 'navPayment',
          name: 'payment',
          translation: 'payments',
          event: '',
          navigate: this.getTransactionPageRoute(),
          navigateSMB: '',
          icon: 'paymentsNormal',
          activeIcon: 'paymentsActive',
          test: 'sidebar-payments',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            !isDisabled('payment', this.props.disabledProducts) && (checkCustomerOrStaff() || checkTrialOrSMBPartner())
        },
        {
          id: 'navInvoices',
          name: 'invoice',
          translation: 'invoices',
          event: 'enterprise_sidebar_invoices',
          navigate: this.getInvoicePageRoute(),
          navigateSMB: '',
          icon: 'sourceInvoice',
          activeIcon: 'sourceInvoice',
          test: 'sidebar-invoices',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            !isDisabled('payment', this.props.disabledProducts) &&
            this.state.showInvoiceTab &&
            (checkCustomerOrStaff() || checkTrialOrSMBPartner())
        },
        {
          id: 'navMessenger',
          name: 'messenger',
          translation: 'messenger',
          secTranslation: 'new',
          event: 'enterprise_sidebar_messenger',
          navigate: '/messenger',
          navigateSMB: '',
          icon: 'menuMessengerNormal',
          activeIcon: 'menuMessengerActive',
          test: 'sidebar-messenger',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('messenger') && checkNotPartnerNoManagerAndSMB()
        },
        {
          id: 'navMessenger',
          name: 'messenger',
          translation: 'messenger',
          secTranslation: 'new',
          event: 'enterprise_sidebar_messenger',
          navigate: '/trial/messenger',
          navigateSMB: '',
          icon: 'menuMessengerNormal',
          activeIcon: 'menuMessengerActive',
          test: 'sidebar-messenger',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            productCheck('messenger', 'messenger') && (checkCustomerOrStaff() || checkTrialOrSMBPartner())
        },
        {
          id: 'navBooking',
          name: 'booking',
          translation: 'booking',
          event: 'enterprise_sidebar_booking',
          navigate: this.goToBookingModule(),
          navigateSMB: '',
          icon: 'bookingNormal',
          activeIcon: 'bookingActive',
          test: 'sidebar-booking',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('booking')
        },
        {
          id: 'navBooking',
          name: 'booking',
          translation: 'booking',
          event: 'enterprise_sidebar_booking',
          navigate: this.goToBookingModule(),
          navigateSMB: '',
          icon: 'bookingNormal',
          activeIcon: 'bookingActive',
          test: 'sidebar-booking',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('booking', 'booking') && (checkCustomerOrStaff() || checkTrialOrSMBPartner())
        },
        {
          id: 'navReviews',
          name: 'reviews',
          translation: 'reviews',
          event: null,
          navigate: '/reviews/overview',
          navigateSMB: '',
          icon: 'reviewNormal',
          activeIcon: 'reviewActive',
          test: 'sidebar-reviews',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            (productCheck('review-manager') && checkCustomerOrStaff()) ||
            (!productCheck('review-manager') &&
              checkPermissionWOproduct('Custom.Review.Overview.Show', this.props.me) &&
              checkTrialOrSMBPartner())
        },
        {
          id: 'navReviews',
          name: 'reviews',
          translation: 'reviews',
          event: null,
          navigate: '/reviews/overview',
          navigateSMB: '',
          icon: 'reviewNormal',
          activeIcon: 'reviewActive',
          test: 'sidebar-reviews',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('review-manager') && checkTrialOrSMBPartner()
        },
        {
          id: 'navReviewsTrial',
          name: 'reviews',
          translation: 'reviews',
          event: 'enterprise_sidebar_placement',
          navigate: '/trial/reviews',
          navigateSMB: '',
          icon: 'reviewNormal',
          activeIcon: 'reviewActive',
          test: 'sidebar-reviews',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            (!isEnabled('review-manager', this.props.products) &&
              (!isDisabled('review-manager', this.props.disabledProducts) ||
                isEnabled('review-request', this.props.products)) &&
              checkTrialOrSMBPartner() &&
              !checkPermissionWOproduct('Custom.Review.Overview.Show', this.props.me)) ||
            (!isEnabled('review-manager', this.props.products) &&
              (!isDisabled('review-manager', this.props.disabledProducts) ||
                isEnabled('review-request', this.props.products)) &&
              this.props.user.role === 'customer' &&
              checkPermissionWOproduct('Custom.Review.Overview.Show', this.props.me))
        },
        {
          id: 'navPlacement',
          name: 'placement',
          translation: 'placement',
          event: null,
          navigate:
            !isEnabled('gmb', this.props.products) && isEnabled('location', this.props.products)
              ? '/presence'
              : this.props.products &&
                isEnabled('gmb', this.props.products) &&
                isDisabled('location', this.props.products)
              ? '/insights'
              : this.props.products &&
                !isEnabled('gmb', this.props.products) &&
                !isEnabled('location', this.props.products) &&
                isEnabled('posts', this.props.products)
              ? '/posts'
              : (!isEnabled('location', this.props.products) &&
                  (!isDisabled('location', this.props.disabledProducts) ||
                    isEnabled('location', this.props.products)) &&
                  checkTrialOrSMBPartner()) ||
                (!this.props.user.instant_site &&
                  !isEnabled('location', this.props.products) &&
                  (!isDisabled('location', this.props.disabledProducts) ||
                    isEnabled('location', this.props.products)) &&
                  this.props.user &&
                  this.props.user.role &&
                  this.props.user.role !== 'trial')
              ? '/trial/placement-gmb'
              : '',
          navigateSMB: '',
          icon: 'menuPlacementNormal',
          activeIcon: 'menuPlacementActive',
          test: 'sidebar-placement',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            (this.props.products &&
              (isEnabled('gmb', this.props.products) ||
                isEnabled('location', this.props.products) ||
                isEnabled('posts', this.props.products))) ||
            (!isEnabled('location', this.props.products) &&
              (!isDisabled('location', this.props.disabledProducts) || isEnabled('location', this.props.products)) &&
              checkTrialOrSMBPartner()) ||
            (!this.props.user.instant_site &&
              !isEnabled('location', this.props.products) &&
              (!isDisabled('location', this.props.disabledProducts) || isEnabled('location', this.props.products)) &&
              this.props.user &&
              this.props.user.role &&
              this.props.user.role !== 'trial')
        },
        {
          id: 'navInstanSites',
          name: 'instant-sites',
          translation: 'instant-sites',
          event: null,
          navigate: '/instant-sites/site',
          navigateSMB: '',
          icon: 'rocketPrimary',
          activeIcon: 'rocketPrimary',
          test: 'sidebar-instant-sites',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('instant-site') && checkNotPartnerNoManagerAndSMB()
        },
        {
          id:
            !isEnabled('website-design', this.props.products) &&
            !isDisabled('website-design', this.props.disabledProducts) &&
            checkTrialOrSMBPartner()
              ? 'navWebsiteTrial'
              : 'navSites',
          name: 'sites',
          translation: 'sites',
          event: null,
          navigate:
            !isEnabled('website-design', this.props.products) &&
            !isDisabled('website-design', this.props.disabledProducts) &&
            checkTrialOrSMBPartner()
              ? 'trial/website'
              : '/website/mywebsites',
          navigateSMB: '',
          icon: 'sitesNormal',
          activeIcon: 'sitesActive',
          test: 'sidebar-sites',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () =>
            ((productCheck('website-design') || this.props.isSiteEnabled === 'on') &&
              checkNotPartnerNoManagerAndSMB()) ||
            (!isEnabled('website-design', this.props.products) &&
              !isDisabled('website-design', this.props.disabledProducts) &&
              checkTrialOrSMBPartner())
        },
        {
          id: 'campaigns',
          name: 'campaigns',
          translation: 'engagement',
          event: null,
          navigate: '/campaigns',
          navigateSMB: '',
          icon: 'menuEngagementNormal',
          activeIcon: 'menuEngagementActive',
          test: 'sidebar-engagement',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => productCheck('email-campaign') && checkNotPartnerNoManagerAndSMB()
        },
        {
          id: 'business-insurance',
          name: 'business-insurance',
          translation: 'business-insurance',
          event: null,
          navigate: '/business-insurance',
          navigateSMB: '',
          icon: 'insuranceInactive',
          activeIcon: 'insuranceActive',
          test: 'sidebar-sites',
          customerOrStaff: null,
          trialOrPartner: null,
          productCheck: () => shouldShowBusinessInsurance
        }
      ]

      const onClickTrack = (event) => () => {
        segmentEventTrack({
          event,
          properties: {
            user_id: this.props.me && this.props.me._id
          }
        })
      }

      return navItems.map(
        ({
          id,
          name,
          translation,
          event,
          navigate,
          icon,
          activeIcon,
          test,
          customerOrStaff,
          trialOrPartner,
          navigateSMB,
          productCheck,
          secTranslation
        }) => {
          let navigation = navigate
          if (customerOrStaff && customerOrStaff()) {
            navigation = navigate
          }
          if (trialOrPartner && trialOrPartner()) {
            navigation = navigateSMB
          }

          if (productCheck && !productCheck()) {
            return null
          }
          return (
            <li id={id} key={id} className={`item ${page === name ? 'item-active' : ''}`}>
              <Link
                onClick={event ? onClickTrack(event) : () => {}}
                className="d-block text-decoration-none"
                to={this.props.user ? `/${this.props.user.user_unique_id}${navigation}` : ''}>
                <span className="icons d-inline-block">
                  <i className="normal-icon btn-icon ">
                    <Icon name={icon} />
                  </i>
                  <i className="icon-is-active btn-icon ">
                    <Icon name={activeIcon} />
                  </i>
                </span>
                {!secTranslation && (
                  <span className="text" data-test={test}>
                    {t(`sidebar.${translation}`)}
                  </span>
                )}
                {secTranslation && (
                  <span className="text" data-test="sidebar-messenger">
                    {t(`sidebar.${translation}`)}{' '}
                    {this.props.messageCount && this.props.messageCount > 0 ? (
                      <span className="new-message-badge"> {t(`sidebar.${secTranslation}`)}</span>
                    ) : null}{' '}
                  </span>
                )}
              </Link>
            </li>
          )
        }
      )
    }

    return (
      <React.Fragment>
        <DialogListener />
        {this.props.user && (
          <aside className="sidemenu-panel sidemenu-panel-update" id="section_sideMenu">
            <div className="px-15 sidemenu-panel-container">
              <button
                type="button"
                id="unbButton"
                className={`global-create-btn ${
                  showGlobalCreateModal ? 'global-create-btn-bg-active' : 'global-create-btn-bg'
                }`}
                onClick={() => this.handelCreateGlobal()}>
                <Icon name="globalPlusIcon" />
                <span className="global-create-btn-txt">{t('sidebar.new')}</span>
              </button>
              <ul className="b-sidemenu pl-0">
                {renderSidebar()}
                {/* {isEnabled('review-manager', this.props.products) && checkPermissionWOproduct('Custom.Review.Overview.Show', this.props.me) && this.props.user.role === 'trial' ? */}
              </ul>
            </div>
          </aside>
        )}
        {this.state.openReferralrock ? (
          <Referralrock
            open={this.state.openReferralrock}
            onCancel={this.cancleRferralrock.bind(this)}
            me={this.props.me}
          />
        ) : null}
        {this.state.showGlobalCreateModal ? (
          <GlobalCreate
            isReadOnlyRole={isReadOnlyRole}
            me={this.props.me}
            open={this.state.showGlobalCreateModal}
            closeModal={this.handelCreateGlobalClose.bind(this)}
            permissions={this.props.permissions}
            setTabRedirect={this.props.setTabRedirect}
            payment_v2={this.state.payment_v2}
            invoices_v2={this.state.invoices_v2}
          />
        ) : (
          ''
        )}
        {}
      </React.Fragment>
    )
  }
}

export default withTranslation()(withPermissionHookHOC(compose(connect(mapStateToProps, mapDispatchToProps))(SideBar)))
