import React from 'react'
import { Formik, Form } from 'formik'
import Popup from '@components/popups/basic-modal'
import 'react-datepicker/dist/react-datepicker.css'
import { withTranslation } from 'react-i18next'

class ConfirmBooking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  showPopup() {
    this.setState({ open: true })
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    }).catch((e) => {})
  }
  handleSubmit(values, { resetForm }) {
    this.resolve(values)
    this.setState({ open: false })
  }
  handleCancel(values, { resetForm }) {
    this.setState({ open: false })
    this.reject()
  }
  render() {
    const t = this.props.t
    return (
      <Popup
        open={this.state.open}
        title={t('bookings.confirm-appointment')}
        okText={t('bookings.yes-confirm')}
        cancelText="NO"
        onCancel={() => this.setState({ open: false })}
        type="small"
        overrideForm={true}
        modalBackdropClicked={() => this.setState({ open: false })}>
        <Formik
          onReset={this.handleCancel.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form>
                <button type="reset" className="close custom-modal-close-button ">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="layout-wrapper mt-10">
                  <div className="layout-sm-12">
                    <p>{t('bookings.do-you-want-to-confirm-this-appointment')}</p>
                  </div>
                </div>
                <footer className="d-block d-sm-flex mt-30">
                  <button
                    type="submit"
                    className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
                    id="Add-Contact">
                    {t('bookings.yes-confirm')}
                  </button>
                  <button type="reset" className="btn btn-xl m-0 cancel-btn custom-btn-width">
                    {'NO'}
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}
export default withTranslation(null, { withRef: true })(ConfirmBooking)
