import React, { useEffect } from 'react'
import { Box } from '@gositeinc/ui'
import Select from 'react-select'
import { decodeCookies, getCookie, getCookieByKey } from '@helpers/utility'
import { LOGIN_SERVICE_PROVIDERS } from '@helpers/constants'
import { useDispatch, useSelector } from 'react-redux'

const { FACEBOOK } = LOGIN_SERVICE_PROVIDERS

export const FacebookPageSelector = ({ onAccountSelected = () => {} }) => {
  const dispatch = useDispatch()

  const facebookData = useSelector((state) => state.manage_business.facebookData)

  const facebookAccountOptions = facebookData
    ? facebookData.map((account) => ({
      value: {
        ...account
      },
      label: account.name
    }))
    : undefined

  useEffect(() => {
    const provider = getCookieByKey('loginProvider')
    console.log('provider', provider)
    console.log('FACEBOOK.name', FACEBOOK.name)
    if (provider !== FACEBOOK.name) {
      return
    }

    const cookie = getCookie()
    const token = cookie && cookie.token
    const decodeData = decodeCookies(token)
    const tmpProviderToken = decodeData['custom:ACCESS_TOKEN'] || ''

    dispatch.manage_business.connectWithFacebook({
      access_token: tmpProviderToken,
      fields: 'name, location, id, emails, website, phone',
      limit: 500,
      pretty: 0,
      sdk: 'joey',
      suppress_http_code: 1
    })
  }, [])

  console.log(facebookData)

  return (
    <Box>
      <div className="form-group b-select-pages">
        <Select
          id="selectPageFbSyncGeneralInfo"
          className="dropdown-select"
          classNamePrefix="dropdownSelect"
          placeholder="SELECT PAGES"
          options={facebookAccountOptions}
          onChange={async (event) => {
            onAccountSelected(event.value)
          }}
        />
      </div>
    </Box>
  )
}
