import React from "react";
import asyncComponent from "@helpers/async-func";
import { EnterpriseRoute } from "@app/router/access-route"


export default [
    <EnterpriseRoute
        exact
        key="/locations"
        path={"/:userId/locations"}
        layoutSettings={{ page: "locations",title: "Locations" }}
        component={asyncComponent(() => import("./location/list"))}
    />,
    <EnterpriseRoute
        exact
        key="/locations"
        path={"/:userId/location/edit/:locationId"}
        layoutSettings={{ page: "locations", title: "Edit Location" }}
        component={asyncComponent(() => import("./locationEdit"))}
    />,
    <EnterpriseRoute
        exact
        key="/locations"
        path={"/:userId/location-new/add"}
        layoutSettings={{ page: "locations", title: "Add New Location" }}
        component={asyncComponent(() => import("@containers/manage-my-business/addNewLocation"))}
    />,
    <EnterpriseRoute
        exact
        key="/locations"
        path={"/:userId/account-settings-massupdate"}
        layoutSettings={{page: "locations",title:"Bulk Update Settings"}}
        component={asyncComponent(() => import("./bulkUpdateSettings"))}
    />,
    <EnterpriseRoute
        exact
        key="/locations"
        path={"/:userId/partner-bulk-update"}
        layoutSettings={{page: "locations",title: "Bulk Update"}}
        component={asyncComponent(() => import("./bulkUpdate/bulkupdate"))}
    />,
    <EnterpriseRoute
        exact
        key="/locations-search"
        path={"/:userId/locations-search"}
        layoutSettings={{page: "locations",title: "Location Search"}}
        component={asyncComponent(() => import("./location-search/location-search"))}
    />,
]
