import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Button } from '@gositeinc/ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { StepText } from './StepText'
import { StepTitle } from './StepTitle'
import { FormLabel } from './FormLabel'
import * as _ from 'lodash'
import { GMBLocationSelector } from './GMBLocationSelector'
import { segmentEventTrack } from '@helpers/segment'

export const GMBBusinessDetails = ({ step, onBack, onSkipped, onSubmitted }) => {
  const dispatch = useDispatch()

  const me = useSelector((state) => state.auth.me)
  const primaryCategories = useSelector((state) => state.manage_business.primaryCategories)
  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }
    dispatch.manage_business.getPrimaryCategories({ primary: 1, nopaging: true, asc: 1, sort: 'category_name' })

    segmentEventTrack({
      event: 'Onboarding_Social_SignUp',
      properties: {
        Onboarding_Version: 'v4',
        Social_Account: 'Google'
      }
    })
  }, [])

  const schema = Yup.object().shape({
    location: Yup.object().required('Location is a required field')
  })

  const initialValues = {
    location: undefined
  }

  const convertGoogleLocationToGMBSyncPayload = (location) => {
    const payload = {}

    payload.location_name = _.get(location, 'locationName', '')
    payload.location_id = _.get(location, 'locationId', '')
    payload.location_url = _.get(location, 'locationUrl', '')
    payload.place_id = _.get(location, 'placeId', '')
    payload.business_phone = _.get(location, 'businessPhone', '')

    // add
    payload.business_address = _.get(location, 'locationName', '')
    payload.city = _.get(location, 'city', '')
    payload.state = _.get(location, 'state', '')
    payload.zip = _.get(location, 'zip', '')
    payload.account_id = _.get(location, 'accountId', '')
    payload.account_name = _.get(location, 'accountName', '')

    payload.selectedLocationDetails = _.get(location, 'selectedLocationDetails', '')

    payload.hours = _.get(location, 'hours', '')
    payload.websiteUrl = _.get(location, 'websiteUrl', '')
    return payload
  }

  return (
    <Container maxWidth="xs">
      <StepText mt="79px">{step}</StepText>
      <StepTitle mt="4px">Let's import your profile.</StepTitle>

      <Formik
        validateOnBlur
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        isInitialValid={schema.isValidSync(initialValues)}
        validationSchema={schema}
        onSubmit={async ({ location = {} }) => {
          const payload = {
            locationName: location.locationName,
            location_name: location.locationName,
            address: location.businessAddresses,
            city: location.city,
            state: location.state,
            zip: location.zip,
            phone: location.businessPhone,
            emails: me && me.user_email ? [me.user_email] : [],
            websiteUrl: location.websiteUrl,
            hours: location.hours || '',
            location_url: location.locationUrl,
            location_id: location.locationId,
            account_id: location.accountId,
            lat: location.lat,
            lng: location.lng
          }

          // logic copy from first-experience/pages/business-name.tsx
          if (location.placeId) {
            payload.countryCode = 'US'
            payload.isMappable = true
            payload.place_id = location.placeId
            payload.googlePlacesID = location.placeId
          }
          const primaryCategoryRawName =
            location &&
            location.selectedLocationDetails &&
            location.selectedLocationDetails.categories &&
            location.selectedLocationDetails.categories.primaryCategory &&
            location.selectedLocationDetails.categories.primaryCategory.name
          const [, primaryCategoryName = ''] = primaryCategoryRawName && primaryCategoryRawName.split(':')
          const gmbPrimaryCategory = primaryCategoryName && primaryCategoryName.split('_').join(' ')
          const primaryCategoryMatch = primaryCategories.find(
            (obj) => obj.category_name.toLowerCase() === gmbPrimaryCategory.toLowerCase()
          )
          payload.primaryCategoryRawName = primaryCategoryRawName
          payload.primaryCategoryMatch = primaryCategoryMatch
          if (me.location_id) {
            await dispatch.manage_business.updateBusinessSettings({
              _id: me.location_id,
              ...payload
            })
          } else {
            await dispatch.accountsettings.upadteBusinessName({
              ...payload
            })
            await dispatch.auth.getMe()
          }

          segmentEventTrack({
            event: 'Onboarding_Social_Synced',
            properties: {
              Onboarding_Version: 'v4',
              Social_Account: 'Google'
            }
          })

          // logic copied from business-gmail.js
          // this key will be used to sync gmb at home -> index
          const gmbPayload = convertGoogleLocationToGMBSyncPayload(location)
          localStorage.setItem('gmbPayload', JSON.stringify(gmbPayload))
          localStorage.setItem('primaryCategoryMatch', JSON.stringify(primaryCategoryMatch))
          onSubmitted()
        }}
        render={({ isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form className="w-100">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  mt: '39px'
                }}>
                <Box sx={{ width: '100%' }}>
                  <FormLabel mb="10px">
                    SELECT YOUR BUSINESS PROFILE<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>

                  <GMBLocationSelector
                    onLocationSelected={(location) => {
                      setFieldValue('location', location)
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '56px',
                  gap: '16px'
                }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onBack()
                  }}>
                  BACK
                </Button>
                <Button variant="contained" type="submit" disabled={!isValid || isSubmitting}>
                  CONTINUE
                </Button>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '16px'
                }}>
                <Box
                  style={{
                    color: '#0357FF',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    segmentEventTrack({
                      event: 'Onboarding_Social_Skipped',
                      properties: {
                        Onboarding_Version: 'v4',
                        Social_Account: 'Google'
                      }
                    })
                    onSkipped()
                  }}>
                  Proceed without Google My Business
                </Box>
              </Box>
            </Form>
          )
        }}
      />
    </Container>
  )
}
