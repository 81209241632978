import React, { Component, Fragment } from 'react'
import Icon from '@components/icons'
import Popup from '@components/popups/basic-modal'
import i18next from 'i18next'

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  closeModal() {
    this.props.close()
  }
  handleButton(from) {
    this.props.actions(from)
  }
  render() {
    const t = i18next.t
    return (
      <Popup
        open={true}
        title={''}
        hideHeader={true}
        hideFooter={true}
        onCancel={() => this.setState({ open: false })}
        type="default"
        modalBackdropClicked={this.closeModal.bind(this)}
        id="modalAddTransaction">
        <button
          name="btnCloseModal"
          type="reset"
          onClick={this.closeModal.bind(this)}
          className="close  remove-modal-close">
          <span aria-hidden="true">×</span>
        </button>
        <div className="thanks-block pt-30">
          <div className="make-payment-content mb-20">
            <div className="text-center">
              <Fragment>
                <i className="icon-payment mb-15 d-inline-block">
                  <Icon name="tickRoundborder" />
                </i>
                <h2 className="mb-10">{t('payments.successful-connection-to-quickbooks')} </h2>
                <p>
                  {t('payments.gosite-was-successfully-connected-to')} <br /> {t('payments.quickbooks-online-account')}{' '}
                  <b>
                    {this.props.quickbookData &&
                    this.props.quickbookData.data &&
                    this.props.quickbookData.data.CompanyName
                      ? this.props.quickbookData.data.CompanyName
                      : null}
                  </b>
                </p>
              </Fragment>
            </div>
          </div>
          <div className="thanks-btn-block">
            <button onClick={this.closeModal.bind(this)} className="btn btn-xl w-100 mb-15 btn-primary">
              {t('payments.back-to-dashboard')}
            </button>
          </div>
        </div>
      </Popup>
    )
  }
}
