import React from "react";
import onClickOutside from "react-onclickoutside";

class Dropdown extends React.Component {
    handleClickOutside = evt => {
        this.props.handleClickOutside()
    };
    render() {
        return (
            <div>
                {this.props.children}
            </div>
        );
    }
}
export default onClickOutside(Dropdown);
