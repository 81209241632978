import * as constants from './constants'
import { routes } from './apiroute'

const all = {
  routes,
  date_format: 'MMM DD YYYY',
  time_format: 'h:mm a',
  date_time_format: 'MMM DD YYYY h:mm A',
  constants: constants,
  csvSampleUrl: 'https://platform.gosite.run/samples/sample.csv',
  paginationLimit: 50,
  frontendPaginationLimit: 12,
  media_library_limit: 40,
  pagination_limit: 12,
  days: [
    {
      label: 'MONDAY',
      value: 2
    },
    {
      label: 'TUESDAY',
      value: 3
    },
    {
      label: 'WEDNESDAY',
      value: 4
    },
    {
      label: 'THURSDAY',
      value: 5
    },
    {
      label: 'FRIDAY',
      value: 6
    },
    {
      label: 'SATURDAY',
      value: 7
    },
    {
      label: 'SUNDAY',
      value: 1
    }
  ],
  googleMapApiKey: 'AIzaSyAt8oDCZuLzE-Ct1MciSC5P1uG4LslImnA',
  gmbSceretKey: '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',
  amountFieldLength: 12
}

const config = {
  ...all,
  ...(JSON.parse(process.env.REACT_APP_CONFIG as string))
}

export default config
