import * as service from './service'
import notification from '@components/notification'

export default {
  state: {
    reactivating: false,
    reactivateSuccess: true
  },
  reducers: {
    onRequestReactivateSubscription (state) {
      return {
        ...state,
        reactivating: true
      }
    },
    onReativateSuccess (state) {
      notification('success', `Subscription reactivated`)
      return {
        ...state,
        reactivating: false,
        reactivateSuccess: true
      }
    },
    onReativateFailed (state) {
      notification('error', `Failed to activate subscription`)
      return {
        ...state,
        reactivating: false,
        reactivateSuccess: false
      }
    },
    onCleared (state) {
      return {
        ...state,
        reactivating: false,
        reactivateSuccess: true
      }
    }
  },
  effects: {
    clearReactivateState (payload, rootState) {
      this.onCleared()
    },
    async reactivateSubscription (payload, rootState) {
      this.onRequestReactivateSubscription()
      try {
        const res = await service.reactivateSubscription()
        this.onReativateSuccess()
        return res
      } catch (e) {
        this.onReativateFailed()
        console.log(e)
      }
    }
  }
}
