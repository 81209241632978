import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="/requests"
    path={'/:userId/reviews/requests'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./review-requests'))}
  />,
  <RestrictedRoute
    exact
    key="/all-reviews"
    path={'/:userId/all-reviews'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./all-reviews'))}
  />,
  <RestrictedRoute
    exact
    key="/reviews"
    path={'/:userId/reviews'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./all-reviews'))}
  />,
  <RestrictedRoute
    exact
    key="/feedback"
    path={'/:userId/reviews/feedback'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./feedback'))}
  />,
  <RestrictedRoute
    exact
    key="/overview"
    path={'/:userId/reviews/overview'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./overview'))}
  />,
  <RestrictedRoute
    exact
    key="/widgets"
    path={'/:userId/reviews/widgets'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./widgets'))}
  />,
  <RestrictedRoute
    exact
    key="/createwidget"
    path={'/:userId/reviews/createwidget'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./widgets/createWidget'))}
  />,
  <RestrictedRoute
    exact
    key="/createwidget"
    path={'/:userId/reviews/editwidget/:widgetId'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./widgets/createWidget'))}
  />,
  <RestrictedRoute
    exact
    key="/settings"
    path={'/:userId/review/settings'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./settings'))}
  />,
  <RestrictedRoute
    exact
    key="/reviews"
    path={'/:userId/trial/reviews'}
    layoutSettings={{ page: 'reviews' }}
    component={asyncComponent(() => import('./preview'))}
  />
]
