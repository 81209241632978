import * as React from 'react';
import Icon from '../../icons';

interface LoadingButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: string;
  isSubmitting?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
  id?: string;
  name?: string;
}
export const LoadingSubmitButton = ({ onClick, children, isSubmitting, type, id, name }: LoadingButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block'}
      type={type}
      id={id}
      name={name}
      disabled={isSubmitting}
    >
      {children}
      {isSubmitting && (
        <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
          <Icon name="spinner" />
        </i>
      )}
    </button>
  );
};

interface CancelButtonProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children?: string;
  type?: 'button' | 'submit' | 'reset' | undefined;
  id?: string;
  name?: string;
}
export const CancelButton = ({ id, name, onClick, children, type }: CancelButtonProps) => (
  <button type={type} id={id} name={name} className={'btn btn-xl m-0 cancel-btn custom-btn-width'} onClick={onClick}>
    {children}
  </button>
);
