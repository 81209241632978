import React from 'react'
import { ElementsConsumer } from '@stripe/react-stripe-js'

export function withElementsConsumer (WrappedComponent) {
  const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

  const ComponentWithElementsConsumer = (props) => {
    return (
      <ElementsConsumer>
        {({ elements, stripe }) => <WrappedComponent stripeElements={elements} stripe={stripe} {...props} />}
      </ElementsConsumer>
    )
  }

  ComponentWithElementsConsumer.displayName = `withStripeElementsConsumer(${displayName})`

  return ComponentWithElementsConsumer
}
