import React, { PureComponent } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Popup from '@components/popups/basic-modal'
import AsyncCreatableSelect from 'react-select/async-creatable'
import style from '../index.module.scss'
import paymentStyle from './send-payment-link.module.scss'
import { isEnabled, phoneNumberFormat, validateEmail, validateMobile } from '@helpers/utility'
import Icon from '@components/icons'
import notification from '@components/notification'
import { TextInstruction } from '@components/textInstruction'
import config from '@config/index'
import { WisetackPromotionalMessageContainer } from '@app/containers/wisetack/WisetackPromotionalMessageContainer'
import { WisetackDisclosureContainer } from '@app/containers/wisetack/WisetackDisclosureContainer'
import { withTranslation } from 'react-i18next'

let isFormChanged = false

class SendPaymentLinkPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      hasContact: false,
      selectedContact: '',
      editStatus: false,
      showWisetackContainer: false
    }
  }

  closeModal() {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }

  handleCancel(values, { resetForm }) {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }

  handleSubmit(values, { resetForm }) {
    if (this.state.hasContact) {
      const res = {
        contact_id:
          this.state.selectedContact && this.state.selectedContact.contact_id
            ? this.state.selectedContact.contact_id
            : '',
        customer_name: this.state.selectedContact && this.state.selectedContact.full_name,
        message: '',
        requested_amount: values.txtAmount,
        requested_through: {
          email: false,
          sms: false
        }
      }
      if (this.state.selectedContact) {
        if (this.state.selectedContact.mobile) {
          res.customer_number = phoneNumberFormat(this.state.selectedContact.mobile)
          res.requested_through.sms = true
        }
        if (this.state.selectedContact.email && !this.state.selectedContact.mobile) {
          res.customer_email = this.state.selectedContact.email
          res.requested_through.email = true
        }
      }

      if (!res.requested_through.email && res.requested_through.sms) {
        const me = this.props.me || {}
        const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
        const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
        const twilioEnabled = hasTwilioAssigned || isTrialUser

        if (!twilioEnabled) {
          notification('error', this.props.t('payments.twilio-number-not-configured'))
          return
        }
      }
      this.props.sendGetPaidLink(res)
    }
  }

  async componentDidMount() {
    if (this.props && this.props.contactInfo) {
      await this.setState({
        contacts:
          this.props.contactInfo && this.props.contactInfo.email
            ? this.props.contactInfo.email
            : this.props.contactInfo.mobile,
        hasContact: true,
        selectedContact: this.props.contactInfo,
        editStatus: true
      })
    }
  }

  customOptions = ({ value, label, subLabel, image, contactObj, index }, { context }) => {
    if (context === 'value') {
      return (
        <div key={index} className="d-flex flex-grow-0">
          <div className="mr-10">
            <figure className={`mb-0 ${style['b-image']}`}>
              <img
                className={`w-100 rounded-circle ${style['image']}`}
                src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                alt=""
              />
            </figure>
          </div>
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div>{label || subLabel}</div>
          </div>
        </div>
      )
    }
    if (context === 'menu') {
      return (
        <React.Fragment>
          <div data-test="payment-link-contact-option" id="contactOptionValue" className="d-flex flex-grow-0">
            <div className="mr-10">
              <figure className={`mb-0 ${style['b-image']}`}>
                <img
                  className={`w-100 rounded-circle ${style['image']}`}
                  src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                  alt=""
                />
              </figure>
            </div>
            <div className="d-flex flex-grow-1 justify-content-between align-items-center">
              <div>{label || subLabel}</div>
              <div>{label && subLabel ? `${subLabel}` : ''}</div>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  autoSuggestContacts = async (input) => {
    const data = {
      nopaging: true,
      status: 'active',
      excludeNullGroup: true,
      search: input || ''
    }
    this.props.products &&
      isEnabled('contact-manager', this.props.products) &&
      (await this.props.autosuggestContacts(data))
    return [
      {
        options: this.props.contactList && this.props.contactList.length > 0 ? this.props.contactList : []
      }
    ]
  }

  createNewVisible(array) {
    if (array.length > 0) {
      if (array[0] && array[0].options.length === 0) {
        return true
      } else return false
    } else return false
  }

  async handleSelectOption(setFieldValue, data, field, action, amount) {
    isFormChanged = true

    if (action && action.action === 'clear') {
      this.setState({ contacts: [], hasContact: false, editStatus: false })
      setFieldValue(field, '')
    }
    if (data && data.value) {
      this.setState({ contacts: [data.value], hasContact: true, selectedContact: data.contactObj })
      setFieldValue(field, data.value)
    }
    if (action && action.action === 'create-option') {
      const mobileTest = validateMobile(data.value)
      const emailTest = validateEmail(data.value)
      if (data.value && !mobileTest && !emailTest) {
        const me = this.props.me || {}
        const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
        const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
        const twilioEnabled = hasTwilioAssigned || isTrialUser

        const message = twilioEnabled
          ? this.props.t('payments.please-enter-a-valid-email-or-mobile')
          : this.props.t('payments.please-enter-a-valid-email')
        notification('error', message)
        this.setState({ contacts: [], hasContact: false })
        setFieldValue(field, '')
      } else {
        let type = ''
        if (mobileTest) {
          type = 'mobile'
          data.value = phoneNumberFormat(data.value)
        }
        if (emailTest) {
          type = 'email'
        }
        this.props.showContactAddPopup(type, data.value, amount)
      }
    }
  }

  wisetackAllowed(amount) {
    if (amount >= 500 && amount <= 25000) {
      this.setState({
        ...this.state,
        showWisetackContainer: true
      })
    } else {
      if (this.state.showWisetackContainer) {
        this.setState({
          ...this.state,
          showWisetackContainer: false
        })
      }
    }
  }

  render() {
    const t = this.props.t
    return (
      <React.Fragment>
        <Popup
          open={this.props.open}
          title={t('payments.send-payment-link')}
          hideFooter={true}
          onCancel={() => this.closeModal.bind(this)}
          type="basic"
          modalBackdropClicked={() => console.log(null)}
          id="modalSendPaymentLink">
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              txtAmount: this.props && this.props.amount ? this.props.amount : '',
              txtContact: ''
            }}
            validationSchema={Yup.object().shape({
              txtContact: Yup.string().test(
                'len',
                t('payments.only-us-phone-numbers-without-country-code-are-permitted-eg-500-555-0006'),
                (val) => (val ? val.length === 14 : true)
              ),
              txtAmount: Yup.number().required(t('payments.required'))
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form
                  id="frmSentPayemntLink"
                  name="frmSentPayemntLink"
                  className={`pt-20 custom-get-paid-form ${style['get-paid-form']}`}>
                  <button
                    name="btngetPaidCloseModal"
                    type="reset"
                    className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label className="label-text ">
                          {t('payments.amount-1')} <span className="star">*</span>
                        </label>
                        <ErrorMessage
                          id="frmSentPayemntLink_amountError"
                          name="txtAmount"
                          component="span"
                          className="form-error"
                        />
                        <div className="position-relative">
                          <Field
                            data-test="payment-link-amount-field"
                            id="frmSendPaymentLink_txtAmount"
                            className="form-control form-control-lg mb-20 pl-25"
                            type="text"
                            placeholder={t('payments.enter-amount-0')}
                            name="txtAmount"
                            value={values.txtAmount}
                            maxLength={config.amountFieldLength}
                            onChange={(e) => {
                              const { value } = e.target
                              const regex = /^(0*[0-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                              if (regex.test(value.toString()) || value.length === 0) {
                                isFormChanged = true
                                setFieldValue('txtAmount', value)
                              }
                            }}
                            onBlur={() => this.wisetackAllowed(values.txtAmount)}
                          />
                          <span className={`position-absolute ${style['dollar-sing']}`}>$</span>
                        </div>
                        {this.props.showInstruction && values.txtAmount === '' && (
                          <TextInstruction
                            className={paymentStyle['payment-instruction-amount']}
                            strongMessage={t('payments.take-action')}
                            message={t('payments.enter-the-amount-youd-like-to-bill-your-customer')}
                          />
                        )}
                        {this.state.showWisetackContainer && (
                          <WisetackPromotionalMessageContainer
                            isSingleLineLayout
                            amountInCents={Number(values.txtAmount) * 100}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-10 custom-cursor-hide">
                        <label className="label-text">
                          {t('payments.contact')} <span className="star">*</span>
                        </label>
                        <ErrorMessage name="contacts" component="span" className="form-error" />
                        {this.props && this.props.contactInfo && this.state.hasContact && this.state.editStatus ? (
                          <AsyncCreatableSelect
                            data-test="payment-link-contact-field"
                            id="frmSendPaymentLink_txtContact"
                            name="contacts"
                            isClearable
                            placeholder={t('payments.name-phone-or-email')}
                            className="dropdown-select no-arrow-down"
                            classNamePrefix="dropdownSelect"
                            loadOptions={this.autoSuggestContacts}
                            formatOptionLabel={this.customOptions}
                            noOptionsMessage={() => null}
                            menuIsOpen={true}
                            value={
                              this.props && this.props.contactInfo
                                ? {
                                    label: this.props.contactInfo.first_name,
                                    value: this.props.contactInfo.email
                                      ? this.props.contactInfo.email
                                      : this.props.contactInfo.mobile
                                  }
                                : ''
                            }
                            onChange={(event, type) =>
                              this.handleSelectOption(setFieldValue, event, 'contacts', type, values.txtAmount)
                            }
                            formatCreateLabel={(userInput) => (
                              <div className="react-select-unavailable-data">
                                <span className="react-select-highlight-text">{t('payments.send-to')}</span> {userInput}
                              </div>
                            )}
                            isValidNewOption={(inputValue, selectValue, selectOptions) =>
                              this.createNewVisible(selectOptions)
                            }
                          />
                        ) : (
                          <AsyncCreatableSelect
                            data-test="payment-link-contact-field"
                            id="frmSendPaymentLink_txtContact"
                            name="contacts"
                            isClearable
                            placeholder={t('payments.name-phone-or-email')}
                            className="dropdown-select no-arrow-down"
                            classNamePrefix="dropdownSelect"
                            loadOptions={this.autoSuggestContacts}
                            formatOptionLabel={this.customOptions}
                            noOptionsMessage={() => null}
                            menuIsOpen={true}
                            onChange={(event, type) =>
                              this.handleSelectOption(setFieldValue, event, 'contacts', type, values.txtAmount)
                            }
                            formatCreateLabel={(userInput) => (
                              <div className="react-select-unavailable-data">
                                <span className="react-select-highlight-text">{t('payments.send-to')}</span> {userInput}
                              </div>
                            )}
                            isValidNewOption={(inputValue, selectValue, selectOptions) =>
                              this.createNewVisible(selectOptions)
                            }
                          />
                        )}

                        {this.props.showInstruction && values.txtAmount !== '' && !this.state.hasContact && (
                          <TextInstruction
                            className={paymentStyle['payment-instruction-contact']}
                            strongMessage={t('payments.take-action')}
                            message={t('payments.enter-the-phone-number-or-email-of-a-customer-to-send-a-payment-link')}
                          />
                        )}
                      </div>
                    </div>
                    {this.state.showWisetackContainer && (
                      <div className="col-12 mb-30">
                        <WisetackDisclosureContainer amountInCents={Number(values.txtAmount) * 100} />
                      </div>
                    )}
                  </div>
                  {this.state.hasContact ? (
                    <footer className="d-block d-sm-flex">
                      <button
                        data-test="send-payment-link-form-btn"
                        className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block'}
                        id="frmBtnSendPaymentLink"
                        disabled={this.props.paymentLinkLoader}
                        type="submit">
                        {t('payments.send-payment-link-0')}
                        {this.props.paymentLinkLoader && (
                          <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                            <Icon name="spinner" />
                          </i>
                        )}
                      </button>
                      <button
                        data-test="cancel-payment-link-form-btn"
                        type="reset"
                        id="frmBtnSendPaymentLink_btnCancel"
                        className={'btn btn-xl m-0 cancel-btn custom-btn-width'}>
                        {t('payments.cancel-0')}
                      </button>
                    </footer>
                  ) : null}
                </Form>
              )
            }}
          />
        </Popup>
      </React.Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(SendPaymentLinkPopup)
