import React from 'react'
import asyncComponent from '@helpers/async-func'
import { EnterpriseRoute } from '@app/router/access-route'

export default [
  <EnterpriseRoute
    exact
    key="/analytics"
    path={'/:userId/analytics'}
    layoutSettings={{ page: 'home', title: 'Home' }}
    component={asyncComponent(() => import('../home'))}
  />
]
