import { createModel } from '@rematch/core'
import { getPaymentsStatus, getPaymentsFirstTimeActions } from './service'

import { PaymentsStatus, PaymentsGrowthState, FirstTimeAction, FirstTimeActionState } from './types'

interface PaymentsGrowthActiveStatusResponse {
  message: 'active'
  status: PaymentsStatus
  title: string | null
  description: string | null
  action_name: string | null
  deep_link_url: string | null
}

interface PaymentsGrowthActiveActionsResponse {
  message: 'active'
  actions: FirstTimeAction[]
}

interface PaymentsGrowthCompletedStatusResponse {
  message: 'completed'
}

interface PaymentsGrowthNoAppStatusResponse {
  message: 'no-payments-application'
}

interface PaymentsGrowthExpiredStatusResponse {
  message: 'expired' | 'no-card'
}

type PaymentsGrowthStatusResponse =
  | PaymentsGrowthActiveStatusResponse
  | PaymentsGrowthCompletedStatusResponse
  | PaymentsGrowthNoAppStatusResponse
  | PaymentsGrowthExpiredStatusResponse

type PaymentsGrowthActionsResponse =
  | PaymentsGrowthActiveActionsResponse
  | PaymentsGrowthCompletedStatusResponse
  | PaymentsGrowthNoAppStatusResponse

export const paymentsGrowth = createModel()({
  state: {
    statusCard: { show: 'uninitialized', status: null, title: null, description: null },
    actions: {}
  } as PaymentsGrowthState,
  reducers: {
    setPaymentsGrowthStatusCardEnabled: (state, payload: PaymentsGrowthActiveStatusResponse) => {
      return {
        ...state,
        statusCard: { show: 'show', status: payload.status, title: payload.title, description: payload.description }
      }
    },
    setPaymentsGrowthStatusCardDisabled: (state, payload) => {
      return { ...state, statusCard: { ...state.statusCard, show: 'hide' } }
    },
    setPaymentsGrowthFirstTimeActions: (state, payload: PaymentsGrowthActiveActionsResponse) => {
      const actions = <FirstTimeActionState>{}
      console.log('payload', payload)
      payload.actions.forEach(({ action_name, title, description }) => {
        actions[action_name] = { title, description }
      })
      return { ...state, actions }
    }
  },
  effects: (dispatch) => ({
    getPaymentsStatusCard: async (_, rootState) => {
      const {
        statusCard: { show }
      }: PaymentsGrowthState = rootState.paymentsGrowth
      if (show === 'hide') return
      try {
        const data: PaymentsGrowthStatusResponse = await getPaymentsStatus()
        if (data.message !== 'active') {
          dispatch.paymentsGrowth.setPaymentsGrowthStatusCardDisabled()
        } else {
          dispatch.paymentsGrowth.setPaymentsGrowthStatusCardEnabled(data)
        }
      } catch (e) {
        console.error(e)
      }
    },
    getPaymentsFirstTimeCards: async (_, rootState) => {
      try {
        const data: PaymentsGrowthActionsResponse = await getPaymentsFirstTimeActions()
        if (data.message === 'active') {
          dispatch.paymentsGrowth.setPaymentsGrowthFirstTimeActions(data)
        } else {
          dispatch.paymentsGrowth.setPaymentsGrowthFirstTimeActions({ actions: [] })
        }
      } catch (e) {
        console.error(e)
      }
    }
  })
})
