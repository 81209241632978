import React from 'react'
import Popup from '@components/popups/basic-modal'
import InvoicePreview from './invoicePreview'
import { isPastPacificTime } from './invoice-popup-helpers'
import ConfirmInvoice from './confirmInvoice'
import notification from '@components/notification'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
let isFormChanged = false

const INVOICE_DUE_DATE_VALUES = ['upon receipt', '1 week', '2 weeks', '30 days', 'end of month']

const SendRecurringInvoiceConfirmPopup = ({
  open,
  invoiceData,
  closeModal,
  me,
  invoiceSaveLoader,
  recurringInvoiceEdit,
  saveInvoiceData,
  updateContact,
  popupType,
  paymentFeedbackStatus
}) => {
  const { t } = useTranslation()
  const determineTitle = () => {
    if (invoiceData) {
      if (invoiceData.send_email) {
        if (invoiceData.send_sms) {
          return { type: 'email_sms', title: t('payments.send-via-email-and-sms-0') }
        } else {
          return { type: 'email', title: t('payments.send-via-email-0') }
        }
      } else if (invoiceData.send_sms) {
        return { type: 'sms', title: t('payments.send-via-sms-1') }
      }
    }
    return { type: '', title: '' }
  }

  const { type, title } = determineTitle()

  const setIsFormChanged = (value) => {
    isFormChanged = value
  }

  const closeConfirmModal = () => {
    closeModal(isFormChanged)
    isFormChanged = false
  }

  const handleSubmit = async (values, { resetForm }) => {
    const newInvoice = { ...invoiceData }
    if (
      newInvoice.scheduled_date &&
      newInvoice.send_type !== 'immediatly' &&
      new Date(values.scheduled_date).getDate() === new Date().getDate() &&
      new Date(values.scheduled_date).getMonth() === new Date().getMonth() &&
      new Date(values.scheduled_date).getFullYear() === new Date().getFullYear() &&
      isPastPacificTime(10)
    ) {
      notification('error', t('payments.cannot-schedule-on-same-day-past-10am-pacific-time'))
    } else {
      if (values.invoice_title) {
        newInvoice.invoice_title = values.invoice_title
      }
      if (values.invoice_title) {
        newInvoice.invoice_description = values.invoice_description
      }
      newInvoice.review_feedback = values.review_feedback
      if (newInvoice.invoice_due && !INVOICE_DUE_DATE_VALUES.includes(newInvoice.invoice_due)) {
        newInvoice.invoice_due = moment(newInvoice.invoice_due).endOf('day').utc()
      }
      if (values.saveContact) {
        const res = {
          contact_id: newInvoice.customer_id,
          email: newInvoice.customer_email,
          mobile: newInvoice.customer_phone,
          first_name: newInvoice.customer_first_name,
          last_name: newInvoice.customer_last_name
        }
        updateContact(res)
      }
      await saveInvoiceData(newInvoice)
    }
  }

  return (
    <>
      <Popup
        open={open}
        title={title}
        onCancel={closeConfirmModal}
        type="default"
        modalBackdropClicked={() => console.log(null)}
        id="modalSendRecurringInvoiceTypeLink">
        <ConfirmInvoice
          invoiceData={invoiceData}
          onReset={closeConfirmModal}
          onSubmit={handleSubmit}
          invoiceSaveLoader={invoiceSaveLoader}
          typeOfInvoice={type}
          setIsFormChanged={setIsFormChanged}
          popupType={popupType}
          me={me}
          paymentFeedbackStatus={paymentFeedbackStatus}
        />
        <InvoicePreview invoiceData={invoiceData} me={me} invoiceEdit={recurringInvoiceEdit} />
      </Popup>
    </>
  )
}

export default SendRecurringInvoiceConfirmPopup
