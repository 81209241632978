import React from 'react'
import Popup from '../popups/basic-modal'
import Icon from '@components/icons'

import { Box } from '@gositeinc/ui'

export const EstimateSuccessPopup = ({ open, setOpen, title, description, confirmButtonText, onConfirm }) => {
  return (
    <Popup
      open={open}
      center
      type="small"
      title={' '}
      hideFooter={true}
      overrideForm={true}
      modalBackdropClicked={() => setOpen(false)}>
      <Box sx={{ padding: '34px' }}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <Box
              className="warning-box"
              sx={{
                '*': {
                  fill: '#409C1C'
                },
                svg: {
                  width: '82.5px',
                  height: '82.5px'
                }
              }}>
              <Icon className="svg-center-align" name="check" />
            </Box>
            <div className="warning-box-title">{title}</div>
            {typeof description === 'string' ? <p className="text-center">{description}</p> : description}
          </div>
        </div>
        <footer className="d-block d-flex mt-10">
          <button
            type="button"
            className={'btn btn-primary btn-xl border-0 flex-grow-1 d-block d-sm-inline-block'}
            id={'addContactgroup_btnAddGroup'}
            onClick={() => onConfirm()}>
            {confirmButtonText}
          </button>
        </footer>
      </Box>
    </Popup>
  )
}
