import moment from "moment";

const LOCALE = "en";
const DEFAULT_RELATIVE_TIME = moment().locale(LOCALE).localeData()._relativeTime;

/**
 * Short Relative Time Format
 * Examples:
 *  "in 5 days" -> "5d"
 *  "a few seconds ago" -> "15s"
 *  "15 minutes ago" -> "15m"
 *  "an hour ago" -> "1h"
 *  "25 days ago" -> "25d"
 *  "5 months ago" -> "5M"
 *  "2 years ago" -> "2Y"
 */
const SHORT_RELATIVE_TIME = {
    future: "%s",
    past: "%s",
    s: "%ds",
    ss: "%ds",
    m: "1m",
    mm: "%dm",
    h: "1h",
    hh: "%dh",
    d: "1d",
    dd: "%dd",
    M: "1M",
    MM: "%dM",
    y: "1Y",
    yy: "%dY"
};

/**
 * Similar to "moment().fromNow()", but returns relative time strings using the short format.
 * Ref: https://momentjs.com/docs/#/displaying/fromnow/
 * 
 * @param {string} dateStr The date string in UTC format (e.g "2020-01-01T00:00:00.000Z")
 */
export function momentFromNowShort(dateStr) {
    moment.updateLocale(LOCALE, { relativeTime: SHORT_RELATIVE_TIME });
    const result = moment.utc(dateStr).fromNow();

    // NOTE: moment can only update locale configs globally so update it back to its default configs to prevent code side effects
    moment.updateLocale(LOCALE, { relativeTime: DEFAULT_RELATIVE_TIME });
    return result;
}
