
import config from '@config/index'
const siteName = 'growth.gosite.com'
export function getStrapiURL (path) {
  // return `https://cms.gosite.run${path}`
  return config.strapiUrl + path
}

// Helper to make GET requests to Strapi
export async function fetchAPI (path, options = {}) {
  const defaultOptions = {
    headers: {
      'Content-Type': 'application/json'
    }
  }
  const mergedOptions = {
    ...defaultOptions,
    ...options
  }
  const requestUrl = getStrapiURL(path)
  const response = await fetch(requestUrl, mergedOptions)
  // if (!response.ok) {
  //   console.error(response)
  //   console.error(response.statusText)
  //   throw new Error('An error occured please try again')
  // }
  const data = await response.json()
  return data
}

export async function getPageData (params, locale, preview) {
  const slug = params.slug.join('/')
  // Find the pages that match this slug
  const pagesData = await fetchAPI(
    `/pages?slug=${slug}&_locale=${locale}&status=published${preview ? '&status=draft' : ''}&site.site=${siteName}`

  )

  // Make sure we found something, otherwise return null
  if (pagesData == null || pagesData.length === 0) {
    return null
  }

  // Return the first item since there should only be one result per slug
  return pagesData[0]
}

export const getAddons = ({ selectedSubscription, selectedSku, selectedProducts, usersCount, locationsCount }) => {
  const addonIds = []
  selectedProducts.forEach(eachAddOn => {
    if (eachAddOn.config && eachAddOn.config.chargebee_id && eachAddOn.config.chargebee_id[selectedSubscription.name] && eachAddOn.config.chargebee_id[selectedSubscription.name][selectedSku.name]) {
      addonIds.push({ id: eachAddOn.config.chargebee_id[selectedSubscription.name][selectedSku.name] })
    }
  })
  if (usersCount && usersCount[0] && usersCount[0].config && usersCount[0].config.chargebee_id) {
    const _userAddOnData = usersCount[0].config.chargebee_id
    addonIds.push({ id: _userAddOnData[selectedSubscription.name][selectedSku.name], quantity: usersCount[0].value })
  }

  if (locationsCount && locationsCount[0] && locationsCount[0].config && locationsCount[0].config.chargebee_id) {
    const _locationAddOnData = locationsCount[0].config.chargebee_id
    addonIds.push({ id: _locationAddOnData[selectedSubscription.name][selectedSku.name], quantity: locationsCount[0].value })
  }
  return addonIds
}
