import * as React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '@app/store'
import { featureFlags } from '@helpers/configcat'
import { goToUpgradePlan } from '@helpers/utility'
import moment from 'moment'
import config from '@config/index'
import _ from 'lodash'
import { usePermissions } from '@helpers/permission-hooks'
import { segmentEventTrack } from '@helpers/segment'

// local Me interface for type checking and auto-complete
interface Me {
  user_unique_id: string
  platform_id: string
  role_id: {
    role_name: string
  }
  _id: string
  user_email: string
  forceOnboardingFlow: boolean
  locationName: string
  phone: string | null
  address: string | null
  status: string
  isMultiLocation: boolean
  trial_ends_at: Date
  isReadOnlyRole: boolean
  features: Record<keyof typeof featureFlags, boolean>
}

export const useTrialExpired = () => {
  const dispatch = useDispatch()
  const { isReadOnlyRole } = usePermissions()
  const me = useSelector<any, Me>((state) => state.auth.me)

  return React.useMemo(() => {
    if (me && me._id) {
      const isMultiLocation = _.get(me, 'isMultiLocation', undefined)
      const roleName = _.get(me, 'role_id.role_name', undefined)
      const isTrial = _.get(me, 'is_trial', true)
      const trialEndsAtString = _.get(me, 'trial_ends_at', undefined)
      const chargeBeeData = _.get(me, 'chargeBeeData', null)

      if (chargeBeeData) {
        if (
          (chargeBeeData.chargebee_account_id && chargeBeeData.chargebee_subscription_id) ||
          chargeBeeData.chargebee_not_required
        ) {
          return false
        }
      }

      const isTrialRole = roleName === 'trial'

      if (((isMultiLocation || (isTrial && isTrialRole)) && trialEndsAtString) || isReadOnlyRole) {
        const now = moment().tz('America/Los_Angeles')
        const trialEndsAt = moment(trialEndsAtString)
          .tz('America/Los_Angeles')
          .set('hour', 23)
          .set('minute', 59)
          .set('second', 59)
        const seconsLeft = trialEndsAt.diff(now, 'seconds')

        return seconsLeft < 0
      }
    }
  }, [me])
}

export const useFreeTrialExpiredRedirect = (): void => {
  const me = useSelector<any, Me>((state) => state.auth.me)
  const trialExpired = useTrialExpired()
  const { isReadOnlyRole } = usePermissions()

  React.useEffect(() => {
    const asyncEffect = async () => {
      const roleName = _.get(me, 'role_id.role_name', undefined)
    }
    if (me && me._id) {
      asyncEffect()
    }
  }, [me])

  React.useEffect(() => {
    const asyncEffect = async () => {
      const userUniqueId = _.get(me, 'user_unique_id', undefined)
      const trialEndsAt = _.get(me, 'trial_ends_at', undefined)
      const businessProfile = _.get(me, 'businessProfile', undefined)
      if (userUniqueId && trialExpired && !businessProfile) {
        // expired_trial_login_attempt
        segmentEventTrack({
          event: 'expired_trial_login_attempt',
          properties: {
            trial_end_date: trialEndsAt
          }
        })
        await goToUpgradePlan(me)
      }
    }
    if (me && me._id) {
      asyncEffect()
    }
  }, [me, trialExpired, history.location.pathname])
}

export const useHasTrialExpired = () => {
  const dispatch = useDispatch()
  const { isReadOnlyRole } = usePermissions()

  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<any>(undefined)

  const me = useSelector<any, Me>((state) => state.auth.me)

  React.useEffect(() => {
    if (me) {
      setLoading(false)
      setError(undefined)
      return
    }

    dispatch.auth.getMe().then((me: Me) => {
      setLoading(false)
      if (!me) {
        setError(new Error('failed to get me'))
      }
    })
  }, [])

  return React.useMemo(() => {
    if (!me) {
      return {
        loading,
        error
      }
    }

    const hasExpired = (() => {
      const isMultiLocation = _.get(me, 'isMultiLocation', undefined)
      const roleName = _.get(me, 'role_id.role_name', undefined)
      const isTrial = _.get(me, 'is_trial', true)
      const trialEndsAtString = _.get(me, 'trial_ends_at', undefined)
      const chargeBeeData = _.get(me, 'chargeBeeData', null)

      if (chargeBeeData) {
        if (
          (chargeBeeData.chargebee_account_id && chargeBeeData.chargebee_subscription_id) ||
          chargeBeeData.chargebee_not_required
        ) {
          return false
        }
      }

      const isTrialRole = roleName === 'trial'

      if (((isMultiLocation || (isTrial && isTrialRole)) && trialEndsAtString) || isReadOnlyRole) {
        const now = moment().tz('America/Los_Angeles')
        /**
         * RU-211
         * First convert the UTC string "trialEndsAtString" to PST timezone
         * Then once the time converted t UTC timezone change the date to start of the day
         * The issue was we were taking end of the day which was allowing the users to use the application for some extra hours
         * This will ensure that the application can be used only till the start of the day i.e 12.00 am
         * */
        const trialEndsAt = moment(trialEndsAtString).tz('America/Los_Angeles').startOf('day')
        const seconsLeft = trialEndsAt.diff(now, 'seconds')

        return seconsLeft < 0
      }
    })()

    return {
      loading,
      error,
      hasExpired
    }
  }, [me])
}

export const useHasFtuFinished = () => {
  const dispatch = useDispatch()

  const [loading, setLoading] = React.useState<boolean>(true)
  const [error, setError] = React.useState<any>(undefined)

  const me = useSelector<any, Me>((state) => state.auth.me)
  const feStatus = useSelector<any, string>((state) => state.firstExperience.status)

  React.useEffect(() => {
    Promise.all([dispatch.auth.getMe(), dispatch.firstExperience.getFirstExperienceStatus()])
      .then(() => setLoading(false))
      .catch((err) => setError(err))
  }, [])

  const roleName = _.get(me, 'role_id.role_name', undefined)
  const roleShouldSeeOnboarding = new Set(['partner', 'customer', 'trial'])

  if (!roleShouldSeeOnboarding.has(roleName)) {
    return {
      loading,
      error,
      shouldSkip: true
    }
  }

  const isMultiLocation = _.get(me, 'isMultiLocation', false)
  const isBusinessProfile = _.get(me, 'businessProfile', false)
  const platformId = _.get(me, 'platform_id', undefined)

  // enterprise non multi-location user, skip onboarding
  if (!isMultiLocation && !isBusinessProfile && platformId !== config.platformID) {
    return {
      loading,
      error,
      shouldSkip: true
    }
  }

  return {
    loading,
    error,
    hasFinished: feStatus === 'complete'
  }
}

export const useDclStatus = (): { ready: boolean; isDCLUser: boolean; f?: boolean } => {
  const { dclStatusData } = useSelector<any, any>((state) => state.myaccount)
  const me = useSelector<any, Me>((state) => state.auth.me)
  const domainSettings = useSelector<any, any>((state) => state.auth.domainSettings)
  const dispatch = useDispatch()

  React.useEffect(() => {
    if (me && me._id) {
      if (!dclStatusData) {
        dispatch.myaccount.getDclStatus()
      }
      if (!domainSettings) {
        dispatch.auth.validateDomain()
      }
    }
  }, [me])

  return React.useMemo(() => {
    if (!dclStatusData) {
      return { ready: false, isDCLUser: false, dclBucket: null }
    }

    const isEnterpriseUser = _.get(domainSettings, ['company', '_id'], undefined) !== config.platformID

    if (isEnterpriseUser) {
      return { ready: false, isDCLUser: false, isDCLUserInGracePeriod: false, shouldShowDclWarning: false }
    }
    const { bucket: dclBucket, firstUnpaidInvoiceDate, totalAmountDues } = dclStatusData || {}

    return {
      ready: true,
      isDCLUser: dclBucket === 3,
      isDCLUserInGracePeriod: dclBucket === 2,
      shouldShowDclWarning: true,
      dclBucket,
      firstUnpaidInvoiceDate,
      totalAmountDues
    }
  }, [me, dclStatusData])
}

export const withDCLStatus = (Component: any) => {
  return (props: any) => {
    const dclStatus = useDclStatus()
    return <Component dclStatus={dclStatus} {...props} />
  }
}
