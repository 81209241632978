/* eslint-disable camelcase */
// TODO: Need to remove the disabled lint warnig once all variables used in this file are updated to camel case
import moment from 'moment'
import { getAdminCookie } from '@helpers/utility'
import { getConfigCatIdFromMe, getAllExperiments } from './configcat'
import _ from 'lodash'
import { USER_ROLES, ENTERPRISE_ROLES } from './constants'

const adminUser = getAdminCookie() // GROW-29
const { STAFF, READ_ONLY, CUSTOMER, TRIAL, PROSPECT } = USER_ROLES
const { PARTNER, MANAGER } = ENTERPRISE_ROLES
export async function trackSegmentPageSpec (name, number, userid, role) {
  var url = window.location.origin
  if (window.location.path) {
    url = `${url}${window.location.path}`
  }
  const payload = {
    title: window.document.title,
    url: url,
    path: window.location.path,
    referrer: window.document.referrer,
    role: role,
    userid: userid
  }
  if (name === 'contacts') payload.num_of_contacts = number

  const traits = getUserTraits()

  if (adminUser && adminUser.length === 0) window.analytics.page(name, payload, { traits })
}

export async function segmentIdentify (user, role) {
  if (user && user.role_id && user.role_id.role_name) {
    if ([CUSTOMER, STAFF, TRIAL, PARTNER, MANAGER, PROSPECT, READ_ONLY].indexOf(user.role_id.role_name) > -1) {
      let title, customer_type
      if (user.role_id.role_name === TRIAL) {
        title = 'Trial'
      } else if (user.role_id.role_name === READ_ONLY) {
        title = READ_ONLY
      } else if (user.role_id.role_name === 'partner') {
        title = 'Enterprise Manager'
      } else if (user.role_id.role_name === 'manager') {
        title = 'Enterprise Manager'
      } else if (user.role_id.role_name === 'staff') {
        title = 'Location Manager'
      } else if ((user.role_id.role_name === 'customer') && role === 'partner') {
        title = 'Retail'
      } else if ((user.role_id.role_name === 'customer') && role !== 'partner') {
        title = 'Retail User'
      }

      if (user && user.account_settings && user.account_settings.CustomerType) {
        customer_type = user.account_settings.CustomerType
      }

      const products = getActiveUserProducts(user)
      const business_category = getBusinessCategory(user)

      let company = {}
      if ([CUSTOMER, STAFF, TRIAL, PROSPECT].indexOf(user.role_id.role_name) > -1) {
        company = {
          email: user.emails ? user.emails[0] : '',
          id: user.location_id,
          industry: business_category,
          name: user.bussiness_name,
          phone: user.phone
        }
      }

      // get experiments from config cat
      let experiments = await getAllExperiments(getConfigCatIdFromMe(user))
      // map booleans to 'on' or 'off' string
      experiments = _.mapValues(experiments, (val) => (val ? 'on' : 'off'))

      window.analytics.identify(
        user._id,
        {
          name: user.full_name,
          email: user.user_email,
          createdAt: user.created_at,
          id: user.user_unique_id,
          phone: user.contact_phone,
          title: title,
          customer_type: customer_type,
          role: user.role_id.role_name,
          website: user.websiteUrl,
          company: company,
          products: products,
          type: getAccountType(user),
          isAdmin: !!(adminUser && adminUser.length),
          experiments: experiments
        },
        {
          integrations: {
            Intercom: {
              user_hash: user.userIntercomHash
            }
          }
        }
      )
      if (adminUser && adminUser.length === 0) {
        window.analytics.group(user._id, {})
      }
    }
  }
}

export async function segmentEventTrack (eventDetails) {
  if (eventDetails.event && eventDetails.properties) {
    const properties = { ...eventDetails.properties }
    const traits = getUserTraits()

    console.log(adminUser)
    if (adminUser && adminUser.length === 0) {
      console.log(
        eventDetails.event,
        {
          ...properties
        },
        { traits }
      )
      window.analytics.track(
        eventDetails.event,
        {
          ...properties
        },
        { traits }
      )
    }
  }
}

export const segmentEventIdentify = (userId, traits, cb = () => {}) => {
  window.analytics.identify(userId, traits, {}, cb)
}

export function segmentGuestPageIdentify (user) {
  if (user && user._id) {
    window.analytics.identify(user._id, {
      name: `${user.first_name} ${user.last_name}`,
      email: user.user_email,
      createdAt: user.created_at,
      id: user.user_unique_id,
      title: user.role
    })
  }
}

export function segmentTrackFixAppErrAppointment ({ unique_user_id, applicationData, screen }) {
  let payload = {}
  const {
    userRole: role = '',
    created_at: created_date = '',
    mccCode: mcc_code = '',
    userID = {},
    businessID = {}
  } = applicationData
  const { user_email: email_address = '' } = userID
  const { googleCategory = {} } = businessID
  const business_category = googleCategory.displayName || ''

  let isMobile = false
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
    /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
  ) {
    isMobile = true
  } else {
    isMobile = false
  }

  payload = {
    event: 'paymentapplication_error_log',
    properties: {
      unique_user_id,
      created_date: moment(created_date).format('L'),
      role,
      email_address,
      business_category,
      mcc_code,
      booked_appointment_clicked: true,
      booked_appointment_screen: screen,
      device: isMobile ? 'Mobile' : 'Desktop'
    }
  }
  segmentEventTrack(payload)
}

// HELPER FUNCTIONS

const getActiveUserProducts = (user) => {
  if ([CUSTOMER, STAFF, TRIAL, READ_ONLY, PROSPECT].indexOf(user.role_id.role_name) > -1) {
    const products = user.products
      // Filter out products array and return only array of products that are activated
      .filter((product) => product.status === 1 && product.product_id.display_name)
      // Return new array of strings
      .map((product) => product.product_id.display_name)
    return products
  }
  return []
}

const getBusinessCategory = (user) => {
  // Most new users don't have a `bussiness_type` property anymore. Replaced with
  // `primaryCategories` instead. This is to ensure legacy accounts still work
  if (user.bussiness_type) {
    return user.bussiness_type.category_name
  } else if (user.primaryCategories) {
    return user.primaryCategories.category_name
  }
  return ''
}

const getUserTraits = () => {
  if (window && window.analytics && window.analytics.user) {
    const userDetails = window.analytics.user().traits()
    const anonymousId = window.analytics.user().anonymousId()
    const userId = window.analytics.user().id()

    return {
      anonymousId,
      userId,
      ...userDetails
    }
  }

  return {}
}

export function trackPaymentRequestSent (trackingData) {
  const {
    amount,
    total,
    payment_request_type,
    user_id,
    user_unique_id,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    platform
  } = trackingData

  const data = {
    event: 'payment_request_sent',
    properties: {
      payment_request_amount: amount,
      payment_amount_total: total,
      payment_request_type: payment_request_type,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }

  segmentEventTrack(data)
}

export function trackQuickPayRequestSent (trackingData) {
  const {
    amount,
    send_to_contacthub,
    end_user_phone,
    end_user_email_address,
    user_id,
    user_unique_id,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    platform
  } = trackingData

  const data = {
    event: 'quickpay_request_sent',
    properties: {
      payment_request_amount: amount,
      send_to_contacthub: send_to_contacthub,
      end_user_phone: end_user_phone || null,
      end_user_email_address: end_user_email_address || null,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }

  segmentEventTrack(data)
}

/**
 * trackInvoiceRequestSent function tracks two events
 * - if collect_payments is true, tracks "invoice_request_sent"
 * - else tracks "invoice_decoupled_request_sent"
 * @param {*} trackingData
 */
export function trackInvoiceRequestSent (trackingData) {
  const {
    amount,
    end_user_phone,
    end_user_email_address,
    user_id,
    user_unique_id,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    custom_service_status,
    due_date,
    line_items_count,
    line_items_listed,
    send_schedule,
    smart_reminder_status,
    platform,
    collect_payments
  } = trackingData

  const event = collect_payments ? 'invoice_request_sent' : 'invoice_decoupled_request_sent'

  const data = {
    event: event,
    properties: {
      payment_request_amount: amount,
      send_to_contacthub: true,
      end_user_phone: end_user_phone || null,
      end_user_email_address: end_user_email_address || null,
      send_schedule: send_schedule,
      smart_reminder_status: smart_reminder_status,
      due_date: due_date,
      line_items_listed: line_items_listed,
      line_items_count: line_items_count,
      custom_service_status: custom_service_status,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }

  segmentEventTrack(data)
}

export function trackPaymentRequestComplete (trackingData) {
  const {
    amount,
    tip,
    total,
    payment_collection_method,
    payment_request_type,
    user_id,
    user_unique_id,
    end_user_id,
    enduser_payment_button_clicked,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    platform
  } = trackingData

  const data = {
    event: 'payment_request_complete',
    properties: {
      payment_request_amount: amount,
      enduser_payment_button_clicked: enduser_payment_button_clicked,
      tip_amount: tip,
      payment_amount_total: total,
      payment_collection_method: payment_collection_method,
      payment_request_type: payment_request_type,
      user_id: user_id,
      user_unique_id: user_unique_id,
      end_user_id: end_user_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }

  segmentEventTrack(data)
}

export function trackManualTransactionComplete (trackingData) {
  const {
    amount,
    total,
    payment_collection_method,
    user_id,
    user_unique_id,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    platform
  } = trackingData

  const data = {
    event: 'manual_transaction_complete',
    properties: {
      payment_request_amount: amount,
      payment_amount_total: total,
      payment_collection_method: payment_collection_method,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }

  segmentEventTrack(data)
}
const getAccountType = (user) => {
  let type = ''
  const role = user.role_id ? user.role_id.role_name : ''
  if (role === TRIAL || role === READ_ONLY) type = 'free'
  else if (role === CUSTOMER) type = 'smb'
  else if (role === PARTNER) type = 'multi-location'
  return type
}
/**
 * track "invoice_decoupled_request_complete" event
 * When a business owner settles a decoupled invoice with "Mark as Paid",
 * "invoice_decoupled_request_complete" will be fired
 * @param payment_collection_method: "Cash", "Check", "Other"
 * @param {*} trackingData
 */
export function trackDecoupledInvoiceComplete (trackingData) {
  const {
    amount,
    total,
    payment_collection_method,
    user_id,
    user_unique_id,
    end_user_phone,
    end_user_email_address,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    due_date,
    platform
  } = trackingData

  const data = {
    event: 'invoice_decoupled_request_complete',
    properties: {
      payment_request_amount: amount,
      payment_amount_total: total,
      payment_collection_method: payment_collection_method,
      end_user_phone: end_user_phone,
      end_user_email_address: end_user_email_address,
      due_date: due_date,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }
  segmentEventTrack(data)
}

export function trackWithIDAndRole (trackingData) {
  const { event, user_id, role } = trackingData

  const data = {
    event: event,
    properties: {
      user_id: user_id,
      role: role,
      platform: getPlatform()
    }
  }
  segmentEventTrack(data)
}

export function trackPageWithIDAndRole (trackingData) {
  const { pageName, user_id, role } = trackingData

  window.analytics.page(pageName, {
    user_id: user_id,
    role: role,
    platform: getPlatform()
  })
}

export function getPlatform () {
  return 'Web'
  // let platform = /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.exec(navigator.userAgent);
  // if (platform) {
  //     return platform[0];
  // } else {
  //     return "Web";
  // }
}

export function trackWithBusinessData (trackingData) {
  const { event, user_id, role, businessData } = trackingData

  // Track user clicking on "Publish" button and provide business data
  const data = {
    event: event,
    properties: {
      user_id: user_id,
      role: role,
      business_category: businessData && businessData.categories ? businessData.categories.primary : null,
      hide_hours:
        businessData && businessData.hours && businessData.hours.is_public ? !businessData.hours.is_public : 'no',
      hide_address:
        businessData && businessData.location && businessData.location.address_is_public
          ? !businessData.location.address_is_public
          : 'no',
      platform: getPlatform(),
      time_stamp_publish: new Date().toISOString()
    }
  }
  segmentEventTrack(data)
}

export function trackRecurringInvoiceRequestSent (trackingData) {
  const {
    amount,
    end_user_phone,
    end_user_email_address,
    user_id,
    user_unique_id,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    custom_service_status,
    due_date,
    line_items_count,
    line_items_listed,
    send_schedule,
    smart_reminder_status,
    platform,
    collect_payments,
    repeat_schedule,
    start_date,
    end_date_selected,
    end_date,
    due_date_selected
  } = trackingData

  const event = collect_payments ? 'invoice_recurring_request_sent' : 'invoice_decoupled_recurring_request_sent'

  const data = {
    event: event,
    properties: {
      payment_request_amount: amount,
      send_to_contacthub: true,
      end_user_phone: end_user_phone || null,
      end_user_email_address: end_user_email_address || null,
      send_schedule: send_schedule,
      smart_reminder_status: smart_reminder_status,
      due_date: due_date,
      line_items_listed: line_items_listed,
      line_items_count: line_items_count,
      custom_service_status: custom_service_status,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform,
      repeat_schedule: repeat_schedule,
      start_date: start_date,
      end_date_selected: end_date_selected,
      end_date: end_date,
      due_date_selected: due_date_selected,
      date: moment().format('MM-DD-YYYY hh:mm:ss')
    }
  }

  segmentEventTrack(data)
}
export function trackRecurringInvoiceCancel (trackingData) {
  const {
    amount,
    user_id,
    user_unique_id,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    due_date,
    platform,
    start_date,
    end_date_selected,
    end_date,
    due_date_selected,
    payment_request_type,
    isSeries
  } = trackingData

  const event = isSeries ? 'invoice_recurring_series_canceled' : 'invoice_recurring_canceled'

  const data = {
    event: event,
    properties: {
      canceled_recurring_amount_total: amount,
      payment_request_type: payment_request_type,
      start_date: start_date,
      end_date_selected: end_date_selected,
      end_date: end_date,
      due_date_selected: due_date_selected,
      due_date: due_date,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      date: moment().format('MM-DD-YYYY hh:mm:ss'),
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform
    }
  }
  segmentEventTrack(data)
}
export function trackDecoupledRecurringInvoiceComplete (trackingData) {
  const {
    total,
    payment_collection_method,
    user_id,
    user_unique_id,
    end_user_phone,
    end_user_email_address,
    company_id,
    business_name,
    role,
    created_at,
    mcc_code,
    business_category,
    business_email_address,
    due_date,
    platform,
    repeat_schedule,
    start_date,
    end_date_selected,
    end_date,
    due_date_selected
  } = trackingData

  const data = {
    event: 'invoice_decoupled_recurring_request_complete',
    properties: {
      payment_amount_total: total,
      payment_collection_method: payment_collection_method,
      end_user_phone: end_user_phone,
      end_user_email_address: end_user_email_address,
      due_date: due_date,
      user_id: user_id,
      user_unique_id: user_unique_id,
      company_id: company_id,
      business_name: business_name,
      role: role,
      account_creation_date: created_at,
      mcc_code: mcc_code,
      business_category: business_category,
      business_email_address: business_email_address,
      platform: platform,
      repeat_schedule,
      start_date,
      end_date_selected,
      end_date,
      due_date_selected,
      date: moment().format('MM-DD-YYYY hh:mm:ss')
    }
  }
  segmentEventTrack(data)
}
