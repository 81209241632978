import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'

export default {
  state: {
    apiError: false
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess (state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError (state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
            ? data.data.message
            : data && data.error
              ? data.error
              : data && data.data && data.data.error
                ? data.data.error
                : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onfetchBussinessSuccess (state, data) {
      return {
        ...state,
        loading: false,
        bussinessList: data
      }
    },
    resetPasswordSuccess (state, data) {
      notification('success', 'Your password has been updated successfully')
      return {
        ...state,
        loading: false
      }
    },
    skipPasswordModalSuccess (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    clearActivityData (state, data) {
      return {
        ...state,
        dashboardActivityList: '',
        dashboardActivitiesTotl: '',
        dashboardDelCount: ''
      }
    },
    onGetActivityList (state, data) {
      var dashboardDelCount = 0
      var dashboardActivitiesTotl = 0

      for (let i = data.docs.length - 1; i >= 0; i--) {
        if (data.docs[i].meta_data && data.docs[i].meta_data.succesful === 0) {
          data.docs[i].delstatus = 'deleted'
          dashboardDelCount++
        }
      }
      for (let i = data.docs.length - 1; i >= 0; i--) {
        if (!data.docs[i].status || (data.docs[i].status && data.docs[i].status !== 'canceled')) {
          if (data.docs[i].display_message) dashboardActivitiesTotl++
        }
      }
      // let temp = data
      let array = data.docs && data.docs.length ? data.docs : []
      if (state.dashboardActivityList && state.dashboardActivityList.docs && state.dashboardActivityList.docs.length) {
        array = [...state.dashboardActivityList.docs, ...array]
      }
      data.docs = array
      return {
        ...state,
        loading: false,
        dashboardActivityList: data,
        dashboardActivitiesTotl: dashboardActivitiesTotl,
        dashboardDelCount: dashboardDelCount
      }
    }
  },

  effects: {
    async getDashboardActivityList (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getActivityList(payload)
        this.onGetActivityList(res)
      } catch (e) {
        // this.onError(e)
      }
    },
    async fetchBussiness (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchBussiness(payload)
        await this.onfetchBussinessSuccess(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async resetPassword (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.resetPassword(payload)
        await this.resetPasswordSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async skipPasswordModal (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.skipPasswordModal(payload)
        await this.skipPasswordModalSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async flushActivityData (payload) {
      try {
        await this.clearActivityData(payload)
        return true
      } catch (e) {
        console.log(e)
      }
    }
  }
}
