// props
// -----
// columns: table headers to be shown; required
// dataSource: data to be rendered in the table; required
// onSelect: callback fired when checkbox is clicked
// footer: table footer to be shown (eg: pagination)
// loading: show content loader
// className: additional classes for table component
// tableId:id for table

// column options
// --------------
// title: name of the column
// key: key of the column
// dataIndex: name of the field to be mapped from dataSource
// sort: callback for sort caret button. This props enables sorting carets.
// render: provide custom html on how each data in column in rendered
// class: provide extra classes for td elements
// thClassName: provide extra classes for th elements

// functions
// ---------
// clearSelection: clear the selections on the table

import React from "react"
let isES = false
let defaultKey = "_id"

function check(dataSource, selected, index) {
    return isES ? dataSource.find(data => data._source.contact_id === selected[index]) : dataSource.find(data => data[defaultKey] === selected[index])
}
export default class Table extends React.Component {
    state = {
        selected: []
    }
    onItemSelect(event, data) {
        if(this.props.isElasticSearch){
            this.setState(prevState => {
                if (prevState.selected.includes(data._source.contact_id)) {
                    let selected = prevState.selected.filter(id => id !== data._source.contact_id)
                    this.props.onSelect(selected)
                    return { selected: selected,isElasticSearch:true  }
                } else {
                    prevState.selected.push(data._source.contact_id)
                    this.props.onSelect(prevState.selected)
                    return { selected: prevState.selected,isElasticSearch:true }
                }
            })

        }else{
            this.setState(prevState => {
                if (prevState.selected.includes(data[defaultKey])) {
                    let selected = prevState.selected.filter(id => id !== data[defaultKey])
                    this.props.onSelect(selected)
                    return { selected: selected }
                }
                else {
                    prevState.selected.push(data[defaultKey])
                    this.props.onSelect(prevState.selected)
                    return { selected: prevState.selected }
                }
            })
        }

        
    }

    selectAll(event) {
        if (this.state.selected.length === this.props.dataSource.length) {
            this.setState({ selected: [] })
            this.props.onSelect([])
        }
        else {
            this.setState(prevState => {
                let selected ;
                if(this.props.isElasticSearch)
                    selected  = this.props.dataSource.map(item => item._source.contact_id)
                else
                    selected  = this.props.dataSource.map(item => item[defaultKey])
                this.props.onSelect(selected)
                return { selected }
            })
        }
    }
  
    static getDerivedStateFromProps(nextProps, prevState) {
        if (prevState.selected && prevState.selected.length) {
            let selected = Object.assign([], prevState.selected)
            for (let i = 0; i < selected.length; i++) {
                if (nextProps.dataSource && nextProps.dataSource.length && !check(nextProps.dataSource,selected,i)){
                    let data = selected[i]
                    selected = selected.filter(item => item !== data)
                    i--
                }
            }
            return { ...prevState, selected: selected }
        }
        else return null
    }

    clearSelection() {
        this.setState({ selected: [] })
    }

    render() {
        const { columns, dataSource, loading, className ,tableId, isElasticSearch = false,defaultKeys = "_id" } = this.props;
        isES = isElasticSearch;
        defaultKey = defaultKeys
        return (
            <React.Fragment>
                <div className="table-responsive-lg no-table-below-md">
                    <table id={ tableId ? tableId : ""} className={`table rounded shadow ${className ? className : ""} ${this.props.tableId === "tablePlacementList" ? "mb-0" : ""}`}>
                        {loading || (columns && dataSource && columns.length && dataSource.length) ?
                            <React.Fragment>
                                {
                                    !loading ?
                                        <thead className="thead-bg">
                                            <tr>
                                                <React.Fragment>
                                                    {
                                                        columns.map(item => (
                                                            <TH
                                                                key={item.key}
                                                                item={item}
                                                                sortOrder={item.sortOrder}
                                                            />
                                                        ))
                                                    }
                                                    {
                                                        this.props.onSelect ?
                                                            <th key="cbox">
                                                                <div className="form-check mt-2" > 
                                                                    <label id="gs-select-all">
                                                                        <input
                                                                            checked={dataSource.length === this.state.selected.length}
                                                                            onChange={(event) => this.selectAll(event)}
                                                                            className="h-cb gs-select-all"
                                                                            type="checkbox"/>
                                                                        <span className="checkbox-icon"></span>
                                                                    </label>  
                                                                </div>
                                                            </th>

                                                            : null
                                                    }
                                                </React.Fragment>
                                            </tr>
                                        </thead>
                                        :
                                        null
                                }

                                {
                                    !loading ?

                                        <tbody>
                                            {
                                                dataSource && dataSource.length && dataSource.map((data,i) => (
                                                    <tr id={`${this.props.tablekey}-${data._id ? data._id : i}`} key={data[defaultKey] ? `${data[defaultKey]}-${i}` : i} 
                                                        className={

                                                            (data.email && data.mobile ) ? `item-${data[defaultKey]} item-has-email-and-mobile item-has-mobile item-has-email` : ( data.mobile? `item-${data[defaultKey]} item-has-mobile`: (data.email ? `item-${data[defaultKey]} item-has-email` : null) )
                                                        }>
                            
                                                        <React.Fragment>
                                                            {
                                                                columns.map((item, index) => (
                                                                    item.render ?
                                                                        <td className={item.class ? item.class : null} key={item.key}>
                                                                            {item.render(data)}
                                                                        </td>
                                                                        :
                                                                        <td className={item.class ? item.class : null} key={item.key}>
                                                                            <span>{item.dataIndex ? data[item.dataIndex] : null}</span>
                                                                        </td>
                                                                ))
                                                            }

                                                            {
                                                                this.props.onSelect ?
                                                                    <td>
                                                                        <div className="form-check" > 
                                                                            <label><input
                                                                                checked={ isElasticSearch ? this.state.selected.includes(data._source.contact_id)  : this.state.selected.includes(data[defaultKey])}
                                                                                onChange={(event) => this.onItemSelect(event, data)}
                                                                                className="h-cb" type="checkbox"/>
                                                                            <span className="checkbox-icon"></span>
                                                                            </label>  
                                                                        </div>
                                                                    </td>
                                                                    :
                                                                    null
                                                            }
                                                        </React.Fragment>

                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                        :
                                        <tbody>
                                            {
                                                [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((index) => (
                                                    <tr key={index}>
                                                        {
                                                            columns.map(item => (
                                                                <td key={item.key}>
                                                                    <div key={item.key} className="placeholder-loader">&nbsp;</div>
                                                                </td>
                                                            ))
                                                        }
                                                    </tr>
                                                ))
                                            }
                                        </tbody>
                                }

                            </React.Fragment>

                            : 
                            <React.Fragment>
                                { this.props.nodataScreen && this.props.nodataScreenMsg ?
                                    <React.Fragment>
                                        <thead className="thead-bg">
                                            <tr>
                                                {columns.map(item => (
                                                    <TH
                                                        key={item.key}
                                                        item={item}
                                                        sortOrder={item.sortOrder} 
                                                        thClassName={item.thClassName}
                                                    />
                                                ))}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td colSpan="12" className="bg-white">
                                                    <div className="b-no-data">
                                                        <div className="no-data-content-wrap text-center">
                                                            <h2 className="no-data-title">{this.props.nodataScreenMsg}</h2>   
                                                            <p className="no-data-content">{this.props.nodataScreenContent}</p> 
                                                        </div>
                                                        
                                                        
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </React.Fragment>
                                    :null}
                            </React.Fragment>}
                    </table>
                </div>
                {
                    !loading && dataSource && dataSource.length && this.props.footer ?
                        <footer className={"table-footer clearfix"} >
                            {this.props.footer}
                        </footer>
                        : null
                }
            </React.Fragment>
        )
    }
}

class TH extends React.Component {
    async onSortClick(item) {
        if (item.sort)
            await this.props.item.sort(this.props.sortOrder, this.props.item.key)
    }
    render() {
        const { item, sortOrder } = this.props
        return (
            <th key={item.key} className={item && item.thClassName}>
                <span onClick={this.onSortClick.bind(this,item)} className={`sort-table-head ${item.sort ? "table-sort-icon-hover d-flex align-items-center":""}`}>
                    {item.title}
                    {item.sort ?
                        <div className={sortOrder === "DESC" ? "caret-button-down mt-3 ml-5":"caret-button-up ml-5 m-2"}  >
                        </div>
                        : null
                    }
                </span>
            </th>
        )
    }
}