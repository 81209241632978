import { api, catchHandler } from '@helpers/axios'

import config from '@config/index'

export const getActionCenterWithRoute = async () => {
  return api()
    .get(`${config.routes.actionCenter}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export type ClickActions = 'messenger' | 'contacts' | 'review_request' | 'booking'
// we need this for the home page b/c it's not typescript
export const actionCenterClick = {
  messenger: 'messenger',
  contacts: 'contacts',
  reviewRequest: 'review_request',
  booking: 'booking'
} as {
  messenger: ClickActions
  contacts: ClickActions
  reviewRequest: ClickActions
  booking: ClickActions
}

export const postActionCenterClick = async (clickedAction: ClickActions) => {
  return api()
    .post(`${config.routes.actionCenterClick}`, { action: clickedAction })
    .then((res) => res.data)
    .catch(catchHandler)
}
