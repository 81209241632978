import React from 'react'
import { useIsPermitted } from './permission-hooks'

const withPermissionHookHOC = (Component) => {
  return (props) => {
    const permissions = useIsPermitted()
    return <Component permissions={permissions} {...props} />
  }
}

export default withPermissionHookHOC
