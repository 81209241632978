import React from 'react'
import asyncComponent from '@helpers/async-func'
import { EnterpriseRoute } from '@app/router/access-route'

export default [
  <EnterpriseRoute
    exact
    key="/myaccount"
    path={'/:userId/myaccount'}
    layoutSettings={{ page: 'myaccount', title: 'My Account' }}
    component={asyncComponent(() => import('./index'))}
  />
]
