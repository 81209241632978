import React, { PureComponent } from 'react'
import Popup from '@components/popups/basic-modal'
import styles from '../index.module.scss'
import BusinessHours from '@containers/manage-my-business/business-hours/business-hours'
import moment from 'moment'

class BusinessHoursModal extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      businessHours: this.props.business_hours
    }
  }
  closeModal () {
    this.props.close()
  }
  bussinessHoursFormattingFN (businessHours) {
    this.setState({ slotError: false })
    for (const key in businessHours) {
      // eslint-disable-next-line
      businessHours[key].slots &&
        businessHours[key].slots.forEach((slotItem, index) => {
          if (slotItem.time_start1) {
            slotItem.time_start = slotItem.time_start1 + ' ' + slotItem.time_start2
          } else {
            slotItem.time_start = 'AM'
          }
          if (slotItem.time_end1) {
            slotItem.time_end = slotItem.time_end1 + ' ' + slotItem.time_end2
          } else {
            slotItem.time_end = 'PM'
          }
          if (
            slotItem.time_end2 === 'AM' &&
            (slotItem.time_end1 === '12:00' || slotItem.time_end1 === '3:00' || slotItem.time_end1 === '03:00')
          ) {
            console.log('skip validation if end_time == 12:00 AM || 03:00 AM')
          } else {
            if (moment(slotItem.time_start, 'HH:mm A').isAfter(moment(slotItem.time_end, 'HH:mm A'), 'minutes')) {
              this.setState({ slotError: true })
            }
          }
          if (!businessHours[key].open_allday && businessHours[key].open) {
            const start = moment(slotItem.time_start, 'HH:mm A').format('HH:mm A')
            const end = moment(slotItem.time_end, 'HH:mm A').format('HH:mm A')
            if (start && end) {
              if (start === end) {
                this.setState({ slotError: true })
              }
            }
          }
          if (
            !businessHours[key].open_allday &&
            businessHours[key].open &&
            (slotItem.time_start === 'AM' ||
              slotItem.time_end === 'PM' ||
              slotItem.time_start1 === '00:00' ||
              slotItem.time_end1 === '00:00')
          ) {
            this.setState({ slotError: true })
          }
        })
    }
    let result = true
    for (const key in businessHours) {
      if (businessHours[key].open === true) {
        result = false
        break
      }
    }
    this.setState({ businessHoursError: result })
  }
  async handleHoursChange (businessHours) {
    await this.setState({ businessHours: { ...businessHours } })
    await this.bussinessHoursFormattingFN(this.state.businessHours)
  }
  async submitHours () {
    await this.bussinessHoursFormattingFN(this.state.businessHours)
    if (!this.state.slotError && !this.state.businessHoursError) {
      await this.props.submitHours(this.state.businessHours)
      this.props.close()
    }
  }
  render () {
    return (
      <Popup
        open={this.props.open}
        title={this.props.title}
        hideFooter={true}
        type="default"
        overrideForm={true}
        id="modalBusinessHours"
        modalBackdropClicked={this.closeModal.bind(this)}>
        <div className={`common-add-service-modal mt-10 ${styles['add-service-modal']}`}>
          <button
            type="button"
            onClick={this.closeModal.bind(this)}
            id="closeIcon"
            className="close custom-modal-close-button remove-modal-close">
            <span aria-hidden="true"></span>
          </button>
          <div className="row">
            <div className="col-12">
              <BusinessHours
                page="servicePopup"
                handleChange={this.handleHoursChange.bind(this)}
                hours={this.props.business_hours}
              />
            </div>
          </div>
          <footer className="d-block d-sm-flex mt-10">
            <button
              type="button"
              id="bookingHrs_btnSave"
              onClick={this.submitHours.bind(this)}
              className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
              disabled={this.state.slotError || this.state.businessHoursError}>
              {this.props.savetext ? this.props.savetext : 'SAVE CUSTOM HOURS'}
            </button>
            <button
              type="reset"
              id="bookingHrs_btnCancel"
              className="btn btn-xl m-0 cancel-btn custom-btn-width"
              onClick={this.closeModal.bind(this)}>
              CANCEL
            </button>
          </footer>
        </div>
      </Popup>
    )
  }
}

export default BusinessHoursModal
