import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { FirstExperienceData, Status, preScreenData } from './types'

export const getFirstExperience = async (): Promise<FirstExperienceData> =>
  api()
    .get(config.routes.firstExperience)
    .then((res) => res.data)
    .catch(catchHandler)

export const postFirstExperience = async (status: Status): Promise<FirstExperienceData> =>
  api()
    .post(config.routes.firstExperience, { status, signupdevice: 'web' })
    .then((res) => res.data)
    .catch(catchHandler)
export const getPrescreenOptions = async (): Promise<FirstExperienceData> =>
  api()
    .get(config.routes.ftuPrescreen)
    .then((res) => res.data)
    .catch(catchHandler)
export const setPrescreenOptions = async (payoad: preScreenData): Promise<FirstExperienceData> =>
  api()
    .post(config.routes.ftuPrescreen, payoad)
    .then((res) => res.data)
    .catch(catchHandler)
