import React from "react";
import asyncComponent from "@helpers/async-func";
import {RestrictedRoute} from "@app/router/access-route"

export default [
    <RestrictedRoute
        exact
        key="/placement"
        path={"/:userId/insights"}
        layoutSettings={{page: "placement"}}
        component={asyncComponent(() => import("./overview"))}
    />,
    <RestrictedRoute
        exact
        key="/placement"
        path={"/:userId/presence"}
        layoutSettings={{page: "placement"}}
        component={asyncComponent(() => import("./listings"))}
    />,
    <RestrictedRoute
        exact
        key="/placement"
        path={"/:userId/posts"}
        layoutSettings={{page: "placement"}}
        component={asyncComponent(() => import("./posts"))}
    />,
    <RestrictedRoute
        exact
        key="/placement"
        path={"/:userId/questions"}
        layoutSettings={{page: "placement"}}
        component={asyncComponent(() => import("./questions"))}
    />,
    <RestrictedRoute
        exact
        key="/placement/trail"
        path={"/:userId/trial/placement-gmb"}
        layoutSettings={{page: "placement"}}
        component={asyncComponent(() => import("./preview"))}
    />,

]