import React from 'react'
import config from '@config/index'
import Icons from '@components/icons'
import moment from 'moment'
import { UsCurrencyFormatter } from '@helpers/utility'
import { history } from '@app/store'

const Cloader = [1, 2, 3, 4, 5].map((l) => {
  return <div key={l} className="placeholder-loader mb-10 py-15"></div>
})

class HistoryCard extends React.Component {
  gotoTransactionDetailsPage (data) {
    if (data && data.object_id) {
      history.push(`/${this.props.user.user_unique_id}/transaction-details/${data.object_id}`)
    }
  }
  // gotoBookingDetailsPage(item) {
  //     if(this.props.is_enterprise)
  //         history.push(`/${this.props.user.user_unique_id}/enterprise/appointments/appointment-details/${item.object_id}`);
  //     else
  //         history.push(`/${this.props.user.user_unique_id}/appointments/appointment-details/${item.object_id}`);
  // }

  getHistoryIcon (history) {
    let icon
    switch (history.activity_type) {
      case 'new_payment':
        icon = (
          <i className={'btn-icon '}>
            <Icons name="paymentReceived" />
          </i>
        )
        break
      case 'user_appointment':
      case 'admin_appointment':
      case 'website_appointment':
        icon = (
          <i className={'btn-icon '}>
            <Icons name="bookingCreated" />
          </i>
        )
        break
      case 'refund_payment':
        icon = (
          <i className={'btn-icon booking-icon-refund'}>
            <Icons name="refund" />
          </i>
        )
        break
      case 'cancel_payment':
      case 'booking_invoice_cancel':
        icon = (
          <i className={'btn-icon'}>
            <Icons name="paymentCancel" />
          </i>
        )
        break
      case 'booking_rejected':
        icon = (
          <i className={'btn-icon'}>
            <Icons name="bookingRejected" />
          </i>
        )
        break
      case 'appointment_updated':
        icon = (
          <i className={'btn-icon'}>
            <Icons name="booking" />
          </i>
        )
        break
      case 'booking_rescheduled':
        icon = (
          <i className={'btn-icon'}>
            <Icons name="bookingReschedule" />
          </i>
        )
        break
      case 'booking_payment_requested':
        icon = (
          <i className={'btn-icon'}>
            <Icons name="paymentRequested" />
          </i>
        )
        break
      case 'appointment_assigned':
        icon = (
          <i className={'btn-icon'}>
            <Icons name="bookingAssigned" />
          </i>
        )
        break
      default:
        icon = undefined
    }
    return icon
  }

  getHistoryDetail (history) {
    let detail
    switch (history.activity_type) {
      case 'new_payment':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryPaymentReceived" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                Payment received{' '}
              </span>
              <div className={'d-inline-block item-details'}>
                <strong>${UsCurrencyFormatter(history.meta_data.amount)} </strong>
                {moment(history.created_at).format(config.date_time_format)}
              </div>
            </div>
            {this.props.is_enterprise ? null : (
              <button
                id="btnBookingDetailsView"
                name="btnViewBookingDetails"
                type="button"
                className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14"
                onClick={this.gotoTransactionDetailsPage.bind(this, history)}>
                View
              </button>
            )}
          </>
        )
        break
      case 'user_appointment':
      case 'admin_appointment':
      case 'website_appointment':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryDate" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                Booking created{' '}
              </span>
              <div className={' item-details'}>{moment(history.created_at).format(config.date_time_format)}</div>
            </div>
            {/* <button id="btnBookingDetailsView" name="btnViewBookingDetails" type="button" className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14" onClick={this.gotoBookingDetailsPage.bind(this, history)} >View</button> */}
          </>
        )
        break
      case 'refund_payment':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryPaymentRefund" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                Payment refunded{' '}
              </span>
              <div className={'d-inline-block item-details'}>
                <strong>${UsCurrencyFormatter(history.meta_data.amount)} </strong>
                {moment(history.created_at).format(config.date_time_format)}
              </div>
            </div>
            {this.props.is_enterprise ? null : (
              <button
                id="btnBookingDetailsView"
                name="btnViewBookingDetails"
                type="button"
                className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14"
                onClick={this.gotoTransactionDetailsPage.bind(this, history)}>
                View
              </button>
            )}
          </>
        )
        break
      case 'cancel_payment':
      case 'booking_invoice_cancel':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryPaymentRefund" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                Payment canceled{' '}
              </span>
              <div className={'d-inline-block item-details'}>
                {history && history.meta_data && history.meta_data.amount ? (
                  <strong>${UsCurrencyFormatter(history.meta_data.amount)} </strong>
                ) : null}
                {moment(history.created_at).format(config.date_time_format)}
              </div>
            </div>
            {this.props.is_enterprise ? null : (
              <button
                id="btnBookingDetailsView"
                name="btnViewBookingDetails"
                type="button"
                className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14"
                onClick={this.gotoTransactionDetailsPage.bind(this, history)}>
                View
              </button>
            )}
          </>
        )
        break
      case 'booking_rejected':
        detail = (
          <>
            <div>
              <span className={'d-block mb-5 text-secondary item-title'}> Booking canceled </span>
              <div className={' item-details'}>
                <span>{moment(history.created_at).format(config.date_time_format)}</span>
              </div>
            </div>
            {/* <button id="btnBookingDetailsView" name="btnViewBookingDetails" type="button" className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14" onClick={this.gotoBookingDetailsPage.bind(this, history)} >View</button> */}
          </>
        )
        break
      case 'appointment_updated':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryAppointmentUpdated" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                Booking updated{' '}
              </span>
              <div className={' item-details'}>
                <span>{moment(history.created_at).format(config.date_time_format)}</span>
              </div>
            </div>
            {/* <button id="btnBookingDetailsView" name="btnViewBookingDetails" type="button" className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14" onClick={this.gotoBookingDetailsPage.bind(this, history)} >View</button> */}
          </>
        )
        break
      case 'booking_rescheduled':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryRescheduled" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                Booking rescheduled{' '}
              </span>
              <div className={' item-details'}>
                <span>{moment(history.created_at).format(config.date_time_format)}</span>
              </div>
            </div>
            {/* <button id="btnBookingDetailsView" name="btnViewBookingDetails" type="button" className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14" onClick={this.gotoBookingDetailsPage.bind(this, history)} >View</button> */}
          </>
        )
        break
      case 'booking_payment_requested':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryPaymentRequested" className={'d-block mb-5 text-secondary item-title'}>
                Payment requested{' '}
              </span>
              <div className={'d-inline-block item-details'}>
                <strong>${UsCurrencyFormatter(history.meta_data.amount)} </strong>
                {moment(history.created_at).format(config.date_time_format)}
              </div>
            </div>
            {this.props.is_enterprise ? null : (
              <button
                name="btnViewBookingDetails"
                type="button"
                className="btn btn-xs px-0 py-0 ml-15 border-0 text-primary primary-font fz-14"
                onClick={this.gotoTransactionDetailsPage.bind(this, history)}>
                View
              </button>
            )}
          </>
        )
        break
      case 'appointment_assigned':
        detail = (
          <>
            <div>
              <span id="lbl_bookingHistoryRescheduled" className={'d-block mb-5 text-secondary item-title'}>
                {' '}
                {history.comments[0].message}{' '}
              </span>
              <div className={' item-details'}>
                <span>{moment(history.created_at).format(config.date_time_format)}</span>
              </div>
            </div>
          </>
        )
        break
      default:
        detail = undefined
    }
    return detail
  }

  render () {
    return (
      <div className="booking-history overflow-y-scrollbar quick-view-booking-history-wrapper px-20 px-sm-30">
        <h2 className="mb-5">Booking History</h2>
        {this.props._loading ? (
          <div>{Cloader}</div>
        ) : this.props.bookingHistory && this.props.bookingHistory.length ? (
          <ul className={'pl-0 b-history-data pt-15'}>
            {this.props.bookingHistory
              .sort((a, b) => (moment(a.created_at).isAfter(moment(b.created_at)) ? -1 : 0))
              .map((history) => {
                return (
                  <li className={'border border-top-0 border-right-0 border-bottom-0 item'} key={history._id}>
                    <div className="position-relative has-left-space-40">
                      <div className={'position-absolute bg-white d-flex align-items-center h-100 booking-icon'}>
                        {this.getHistoryIcon(history)}
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        {this.getHistoryDetail(history)}
                      </div>
                    </div>
                  </li>
                )
              })}
          </ul>
        ) : null}
      </div>
    )
  }
}

export default HistoryCard
