import { api, invoiceApi, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function getServices (payload) {
  return api()
    .get(`${config.routes.BookingServices}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getStaffServices (payload) {
  return api()
    .get(`${config.routes.bookingServicesStaff}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteServices (payload) {
  return api()
    .delete(`${config.routes.BookingServices}/delete/${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createService (payload) {
  return api()
    .post(`${config.routes.BookingServices}/create/`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getServiceUser (payload) {
  return api()
    .get(`${config.routes.BookingServices}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function UpdateService (payload) {
  return api()
    .put(`${config.routes.BookingServices}/update/${payload._id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sortServices (payload) {
  return api()
    .put(`${config.routes.BookingServices}/sort-order`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBookingServiceCount (payload) {
  return api()
    .get(`${config.routes.getServiceCount}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateBookingServiceLocation (payload) {
  return api()
    .post(config.routes.updateServiceLocation, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getRecurrenceInvoiceList (serviceId) {
  return invoiceApi()
    .get(`${config.routes.recurringInvoiceCount}/${serviceId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
