import React from 'react'
import Popup from '@components/popups/basic-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AsyncCreatableSelect from 'react-select/async-creatable'
import styles from '../index.module.scss'
import Icon from '@components/icons'
import PredefinedTemplate from '@components/predefined-templates'
import { isEnabled, phoneNumberFormat, phoneNumberAutomasking } from '@helpers/utility'
import { numberMask } from '@helpers/inputmask'
import { getReviewShareableLink } from '../service'
import { withTranslation } from 'react-i18next'

const customOptions = ({ value, label, subLabel, type }, { context }) => {
  if (context === 'value') {
    return <div>{phoneNumberFormat(value)}</div>
  }
  if (context === 'menu') {
    return (
      <React.Fragment>
        <div>{label}</div>
        <div>{subLabel ? `${phoneNumberFormat(subLabel)}` : ''}</div>
      </React.Fragment>
    )
  }
}
class ShareableLinkPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      copied: false,
      includeUrl: true,
      message: '',
      smsTemplate: '',
      selectedContact: '',
      accountsettings: {},
      tinyUrl: true,
      contacts: [],
      contact: '',
      defaultMessaage: '',
      shareableURL: ''
    }
    this.onCopy = this.onCopy.bind(this)
    this.handleTextArea = this.handleTextArea.bind(this)
    this.handleSelectOption = this.handleSelectOption.bind(this)
  }
  componentDidMount = () => {
    const getShareableLink = async () => {
      const { url } = await getReviewShareableLink()
      if (url !== '') {
        this.setState({ shareableURL: url })
      }
    }
    getShareableLink().catch(console.error)
  }

  handleSelectOption(setFieldValue, data, field, action) {
    if (action && action.action === 'clear') {
      this.setState({ contacts: [] })
      setFieldValue(field, '')
    }
    if (data && data.value) {
      this.setState({ contacts: [data.value] })
      setFieldValue(field, numberMask(data.value))
    }
  }
  handleContactAdd(setFieldValue, event, field) {
    if (event && event.target.value) {
      const num = event.target.value.replace(/[^0-9]+/g, '')
      this.setState({ contacts: [num] })
      setFieldValue(field, num)
    }
  }
  handleReportAdmin(reportAdmin) {
    reportAdmin()
    this.setState({ open: false })
  }
  async searchContact(inputValue) {
    if (inputValue) {
      const payload = {
        by_mobile: true,
        mobileExist: true,
        search: inputValue
      }
      this.props.products &&
        isEnabled('contact-manager', this.props.products) &&
        (await this.props.getContactList(payload))
      if (this.props.contactList && this.props.contactList.length) {
        return this.props.contactList
      }
    }
  }
  onCopy() {
    this.setState({ copied: true })
    window.$('#shareableLinkTooltip').tooltip('show').attr('data-original-title', 'Copied!')
  }
  handleCheckbox(setFieldValue, value) {
    this.setState({ tinyUrl: value })
  }
  handleTextArea(e) {
    this.setState({ message: e.target.value })
  }
  selectTemplateFn(selectedItem, setFieldValue) {
    setFieldValue('message', selectedItem.message)
    this.setState({ message: selectedItem.message })
  }
  async showPopup(item, accountsettings, predefinedTemplates) {
    this.setState({
      open: true,
      copied: false,
      accountsettings: accountsettings,
      predefinedTemplates: predefinedTemplates
    })
    if (accountsettings && accountsettings.sms_template && accountsettings.bussiness_name) {
      const message = accountsettings.sms_template.replace('{{business_name}}', accountsettings.bussiness_name)
      this.setState({ message: message, defaultMessaage: message })
    }
    window
      .$('#shareableLinkTooltip')
      .tooltip('hide')
      .attr('data-original-title', this.props.t('reviews.copy-url-to-clipboard'))
  }
  async handleSubmit(values, { resetForm }) {
    if (this.state.contacts && this.state.contacts.length && this.state.message) {
      const payload = {}
      payload.contacts = this.state.contacts
      payload.body = this.state.message
      payload.tinyUrl = this.state.tinyUrl

      this.setState({ submitting: true })
      await this.props.sendSms(payload, resetForm)
      this.setState({ submitting: false })
    }
  }
  handleCancel(values, { resetForm }) {
    this.closeModal(true, resetForm)
  }
  closeModal(reset, resetForm) {
    this.setState({ open: false, contacts: [] })
    if (this.props.openGlobalPopupList) {
      this.props.openGlobalPopupList()
    }
    if (reset) this.reset(resetForm)
  }
  reset(resetForm) {
    resetForm({
      message: this.state.defaultMessaage,
      tinyUrl: true,
      contacts: ''
    })
  }

  createNewVisible(input, array) {
    if (input) {
      if (array.length > 0) {
        return false
      } else return true
    }
    return false
  }
  render() {
    const t = this.props.t
    return (
      <Popup
        open={this.state.open}
        title={t('reviews.send-free-sms')}
        okText={t('reviews.send-sms')}
        cancelText="cancel"
        onCancel={this.handleCancel}
        onOk={this.onSendSms}
        type="medium"
        id="modalReviewSharableUrl"
        modalBackdropClicked={this.closeModal.bind(this)}>
        <div className={`${styles['b-share-popup']}`}>
          <Formik
            enableReinitialize
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{
              message: this.state.message,
              tinyUrl: this.state.tinyUrl,
              contacts:
                this.state.contacts && this.state.contacts.length ? this.state.contacts[0].replace('+1', '') : ''
            }}
            validationSchema={Yup.object().shape({
              contacts: Yup.string()
                .required(t('reviews.required'))
                .matches(
                  /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/,
                  t('reviews.only-us-phone-numbers-without-country-code-are-permitted-eg-500-555-0006')
                ),
              message: Yup.string().required(t('reviews.required'))
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, resetForm, setFieldValue }) => {
              return (
                <Form className="frm-shareable-url">
                  <button type="reset" className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  {this.props.me &&
                  this.props.me.account_settings &&
                  this.props.me.account_settings.twilio &&
                  this.props.me.account_settings.twilio.length ? (
                    <React.Fragment>
                      <div className="form-group custom-form-group-dropdown">
                        <label className="label-text">
                          {t('reviews.send-text-message-to')}
                          <ErrorMessage name="contacts" component="span" className="form-error" />
                        </label>
                        {this.state.open ? (
                          <React.Fragment>
                            {this.props.products && isEnabled('contact-manager', this.props.products) ? (
                              <AsyncCreatableSelect
                                id="modalReviewSharableUrl_txtSearchNumber"
                                name="contacts"
                                isClearable
                                // cacheOptions
                                placeholder={t('reviews.search-number')}
                                className="dropdown-select"
                                classNamePrefix="dropdownSelect"
                                formatOptionLabel={customOptions}
                                loadOptions={this.searchContact.bind(this)}
                                noOptionsMessage={() => null}
                                onChange={(event, type) =>
                                  this.handleSelectOption(setFieldValue, event, 'contacts', type)
                                }
                                isValidNewOption={(inputValue, selectValue, selectOptions) =>
                                  this.createNewVisible(inputValue, selectOptions)
                                }
                              />
                            ) : (
                              <div className="input-group">
                                <div className="input-group-prepend mr-10">
                                  <span className="d-flex align-items-center px-10 py-5 text-center border-bottom">
                                    +1
                                  </span>
                                </div>
                                <input
                                  id="modalReviewSharableUrl_txtNumber"
                                  className={'form-control form-control-lg border-top-0 border-left-0 border-right-0'}
                                  type="text"
                                  placeholder={t('reviews.enter-phone-number')}
                                  name="contact"
                                  maxLength={14}
                                  value={values.contacts ? phoneNumberAutomasking(values.contacts) : ''}
                                  onChange={(event) => this.handleContactAdd(setFieldValue, event, 'contacts')}
                                />
                              </div>
                            )}
                          </React.Fragment>
                        ) : null}
                      </div>
                      <div className="form-group">
                        <label className="label-text">
                          {' '}
                          MESSAGE
                          <ErrorMessage name="message" component="span" className="form-error" />
                        </label>
                        {this.state.open ? (
                          <div id="sectionPrefinedTemplate" className="mb-20">
                            <PredefinedTemplate
                              templates={this.state.predefinedTemplates}
                              setFieldValue={setFieldValue}
                              selectTemplateFn={this.selectTemplateFn.bind(this)}
                            />
                          </div>
                        ) : null}
                        <Field
                          id="modalReviewSharableUrl_txtMessage"
                          component="textarea"
                          className={`form-control form-control-lg ${styles['message-box']} `}
                          type="text"
                          placeholder={t('reviews.enter-your-sms-subject')}
                          name="message"
                          onChange={this.handleTextArea}
                          value={values.message}
                          maxLength={320}
                        />
                        <div id="container_progressBar" className={`${styles['character-limit']} mt-15`}>
                          {this.state.message ? (
                            <span
                              className="d-block"
                              style={{ width: (this.state.message.length / 320) * 100 + '%' }}></span>
                          ) : null}
                        </div>
                        <span className={`${styles['character-left']} mb-10`}>
                          {320 - this.state.message.length} {t('reviews.characters-remaining')}
                        </span>
                      </div>
                      <div className="align-items-center mb-20">
                        <div className="form-group d-inline-block">
                          <div className="form-check">
                            <label className="checkbox checkbox-animate no-label m-t-10">
                              <Field
                                type="checkbox"
                                className="h-cb"
                                checked={values.tinyUrl}
                                onChange={(e) => this.handleCheckbox(setFieldValue, e.target.checked)}
                              />
                              <span id="modalReviewSharableUrl_chkIncludeLink" className="checkbox-icon"></span>
                            </label>
                          </div>
                        </div>
                        <div
                          id="container_reviewWidgetTinyUrl"
                          className={`${styles['embedded-code-popup']} d-inline-block border rounded-sm position-relative ml-10 p-15 pr-30`}>
                          <CopyToClipboard
                            onCopy={this.onCopy}
                            text={this.state.shareableURL !== '' && this.state.shareableURL}>
                            <div className={`position-absolute ${styles['url-replicte-icon-position']}`}>
                              <button className="btn" id="btnCopyToClipboardReviewSherableUrl" type="button">
                                <div className="custom-tooltip">
                                  <i className={styles['replicte-icon']}>
                                    <Icon name="replicate" />
                                  </i>
                                  <div className={'custom-tooltip-active'}>
                                    <div className="content-block p-0">
                                      <div className="tooltip-description">
                                        {this.state.copied ? (
                                          <p className="font-weight-light">Copied!</p>
                                        ) : (
                                          <p className="font-weight-light">{t('reviews.copy-url-to-clipboard-1')}</p>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </CopyToClipboard>
                          <code className={styles['embedded-code']} id="transWidgetScript">
                            {this.state.shareableURL !== '' && this.state.shareableURL}
                          </code>
                        </div>
                      </div>
                      <footer className="d-block d-sm-flex">
                        <button
                          id="modalReviewSharableUrl_btnSendSms"
                          type="submit"
                          className="btn btn-primary btn-xl border-0 btn-block mb-20 mb-sm-0 mr-sm-20">
                          {t('reviews.send-sms-0')}
                          {this.state.submitting ? (
                            <i className="btn-icon top-minus-2">
                              <img
                                className="spinner"
                                src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                                alt=""
                              />
                            </i>
                          ) : null}
                        </button>
                        <button
                          id="modalReviewSharableUrl_btnCancel"
                          className="btn btn-xl cancel-btn custom-btn-width"
                          type="reset">
                          CANCEL
                        </button>
                      </footer>
                    </React.Fragment>
                  ) : (
                    <div className="form-group">
                      <p className="mt-10">
                        {' '}
                        {t(
                          'reviews.to-enable-sms-capabilities-you-must-first-verify-your-account-get-started-by-clicking-send-message-in-messenger'
                        )}{' '}
                      </p>
                      <div
                        id="container_reviewWidgetTinyUrl"
                        className={`${styles['embedded-code-popup']} d-inline-block border rounded-sm position-relative mt-20 p-15 pr-30`}>
                        <CopyToClipboard
                          onCopy={this.onCopy}
                          text={this.state.shareableURL !== '' && this.state.shareableURL}>
                          <div className={`position-absolute ${styles['url-replicte-icon-position']}`}>
                            <button className="btn" id="btnCopyToClipboardReviewSherableUrl" type="button">
                              <div className="custom-tooltip">
                                <i className={styles['replicte-icon']}>
                                  <Icon name="replicate" />
                                </i>
                                <div className={'custom-tooltip-active'}>
                                  <div className="content-block p-0">
                                    <div className="tooltip-description">
                                      {this.state.copied ? (
                                        <p className="font-weight-light">{t('reviews.copied')}</p>
                                      ) : (
                                        <p className="font-weight-light">{t('reviews.copy-url-to-clipboard-0')}</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </button>
                          </div>
                        </CopyToClipboard>
                        <code className={styles['embedded-code']} id="transWidgetScript">
                          {this.state.shareableURL !== '' && this.state.shareableURL}
                        </code>
                      </div>
                    </div>
                  )}
                </Form>
              )
            }}
          />
        </div>
      </Popup>
    )
  }
}
export default withTranslation(null, { withRef: true })(ShareableLinkPopup)
