import React from "react"
import asyncComponent from "@helpers/async-func"
import { EnterpriseRoute } from "@app/router/access-route"

export default [
    <EnterpriseRoute 
        exact
        key="/enterprise/managers"
        path="/:userId/enterprise/managers"
        layoutSettings={{ page: "managers", title: "Managers"}}
        component={asyncComponent(() => import("./list"))}
    />
]