import React, { useState, useEffect } from 'react'
// routing
import { Route, Redirect } from 'react-router-dom'
// components
import Layout from '@components/layout'
import Outerlayout from '@components/outerlayout'
import EnterpriseLayout from '@components/enterpriseLayout'
import InstantSiteLayout from '@components/instantSiteLayout'
import UpgradeLayout from '@components/upgradeLayout'
import ReactivateLayout from '@components/reactivateLayout'
import { DCLPopup } from '@components/popups/dcl-popup'
import { DCLGracePeriodPopup } from '@components/popups/dcl-grace-period-popup'
// helpers
import {
  getCookie,
  redirectToAdmin,
  setReferralAppSource,
  isPopupAlreadyOpened,
  setPopupAlreadyOpenedInSession,
  setCookieParentDomain,
  setActiveTotalAccounts
} from '@helpers/utility'
import { USER_ROLES, ENTERPRISE_ROLES } from '@helpers/constants'
import { PermissionConsumer, PermissionProvider } from '@helpers/permission-hooks'
import qs from 'query-string'
import { useDclStatus, useHasFtuFinished, useHasTrialExpired } from './hooks'
import { useSelector, useDispatch } from 'react-redux'
import { history } from '@app/store'
import _ from 'lodash'
import { useRecoilState } from 'recoil'
import { transitioningTabRedirectState, NewButtonTabRedirection } from '@containers/manage-my-business/tab-redirection'
import * as Sentry from '@sentry/react'
import { segmentEventTrack } from '@helpers/segment'
import { featureFlags, getFeatureVal } from '@app/helpers/configcat'
import i18next from 'i18next'
import { locale } from '@app/containers/auth/service'

const { STAFF, READ_ONLY, CUSTOMER, TRIAL, PROSPECT } = USER_ROLES
const userRoles = [STAFF, TRIAL, CUSTOMER, READ_ONLY]
const { PARTNER, MANAGER } = ENTERPRISE_ROLES
const enterpriseRoles = [PARTNER, MANAGER]

const handleLang = async (me) => {
  const langFF = await getFeatureVal(featureFlags.localization, me)
  if (langFF) {
    const res = await locale()
    if (res.locale) {
      localStorage.setItem('language', `${res.locale}`.toLowerCase())
    }
    if (localStorage.getItem('language').includes('en')) {
      i18next.changeLanguage('en')
    } else {
      i18next.changeLanguage('es')
    }
  }
}

export const PublicRoute = ({ component: Component, layoutSettings = {}, ...rest }) => {
  return <Route {...rest} render={(props) => <Component {...props} />} />
}

export const SelfServiceRoute = ({ component: Component, layoutSettings = {}, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const cookie = getCookie()
      return cookie && cookie.token ? (
        [PROSPECT, TRIAL, CUSTOMER, READ_ONLY].includes(cookie.role) ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: `/${cookie.user_unique_id}/self-service/change-password`,
              state: { from: props.location }
            }}
          />
        )
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }}
  />
)

export const OnboardingRoute = ({ component: Component, layoutSettings = {}, ...rest }) => {
  const [tabRedirect, setTabRedirect] = useRecoilState(transitioningTabRedirectState)

  return (
    <PermissionProvider>
      <PermissionConsumer>
        {(permissions) => (
          <>
            <NewButtonTabRedirection tabRedirect={tabRedirect} setTabRedirect={setTabRedirect} />
            <Route
              {...rest}
              render={(props) => {
                const cookie = getCookie()
                const hasRoles = userRoles.includes(cookie.role) || enterpriseRoles.includes(cookie.role)

                return cookie && cookie.token && hasRoles ? (
                  <InstantSiteLayout settings={layoutSettings}>
                    <Component {...props} settings={layoutSettings} permissions={permissions} />
                  </InstantSiteLayout>
                ) : (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location }
                    }}
                  />
                )
              }}
            />
          </>
        )}
      </PermissionConsumer>
    </PermissionProvider>
  )
}

export const RestrictedRoute = ({ component: Component, layoutSettings = {}, ...rest }) => {
  const dispatch = useDispatch()

  localStorage.setItem('redirectPath', window.location.href)
  setCookieParentDomain('deepLink', window.location.href)

  const me = useSelector((state) => state.auth.me)

  const [dclGracePeriodPopupState, setDclGracePeriodPopupState] = useState(true)
  const [tabRedirect, setTabRedirect] = useRecoilState(transitioningTabRedirectState)

  const { ready, isDCLUser, isDCLUserInGracePeriod, shouldShowDclWarning } = useDclStatus()
  const { loading: trialExpiredLoading, hasExpired } = useHasTrialExpired()
  const { loading: ftuLoading, hasFinished, shouldSkip } = useHasFtuFinished()

  const userId = _.get(me, '_id', undefined)
  const feStatus = useSelector((state) => state.firstExperience.status)

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
      return
    }
    handleLang(me)
    dispatch.reactivate.clearReactivateState()

    /** RP-264 EVE-3364 Added condition to show the proper dashboard as per the user type */
    const isPartner = enterpriseRoles.includes(me.role_id.role_name)
    if (isPartner) {
      if (me.isMultiLocation && me.total_accounts <= 1) {
        dispatch.auth.smbDashboardForMultiLocation()
      } else {
        const url = rest.location.pathname
        if (url && (url.includes('/trial/home') || url.includes('/home'))) {
          history.push(`/${me.user_unique_id}/analytics`)
          return
        }
      }
    }

    // setup sentry.io
    setupSentryIo(me)

    if (trialExpiredLoading && ftuLoading) {
      console.log('still loading')
      return
    }

    if (me.status === 'inactive') {
      history.push(`/${me.user_unique_id}/upgrade/new-plan`)
      return
    }

    if (hasExpired) {
      segmentEventTrack({
        event: 'expired_trial_login_attempt',
        properties: {
          trial_end_date: me.trial_ends_at
        }
      })
      if (feStatus !== 'complete') {
        return history.push(`/${me.user_unique_id}/enrolling`)
      }
      history.push(`/${me.user_unique_id}/upgrade/new-plan`)
      return
    }

    if (!hasFinished && !shouldSkip) {
      history.push(`/${me.user_unique_id}/enrolling`)
      return
    }

    if (rest.location.pathname) {
      const cookie = getCookie()
      const pathSplit = rest.location.pathname.split('/')
      if (
        pathSplit[2] === 'messenger' &&
        cookie &&
        cookie.token &&
        ((enterpriseRoles.includes(cookie.role) && !cookie.isMultiLocation) ||
          (cookie.isMultiLocation && cookie.total_accounts > 1))
      ) {
        history.push(`/${pathSplit[1]}/enterprise/messenger?threadId=${pathSplit[1]}`)
        return
      }
    }
  }, [me, trialExpiredLoading, ftuLoading, hasExpired, hasFinished, shouldSkip])

  const onDclGracePeriodPopupClose = () => {
    // Do some things with props or state
    setDclGracePeriodPopupState(false)
    if (userId) {
      setPopupAlreadyOpenedInSession(userId)
    }
  }

  return (
    <PermissionProvider>
      <PermissionConsumer>
        {(permissions) => (
          <>
            <NewButtonTabRedirection tabRedirect={tabRedirect} setTabRedirect={setTabRedirect} />
            <Route
              {...rest}
              render={(props) => {
                const cookie = getCookie()
                if (!layoutSettings.hideLayout) {
                  if (localStorage.getItem('instantSiteId')) localStorage.removeItem('instantSiteId')
                  if (localStorage.getItem('saveLocal')) localStorage.removeItem('saveLocal')
                  if (localStorage.getItem('businessSettings')) localStorage.removeItem('businessSettings')
                }
                let redirectToSMBDashBoard = false

                if (cookie && cookie.token && cookie.isMultiLocation && cookie.role === PARTNER) {
                  if (cookie.total_accounts <= 1) {
                    redirectToSMBDashBoard = true
                  } else {
                    redirectToSMBDashBoard = false
                  }
                }

                return (cookie && cookie.token && userRoles.includes(cookie.role)) || redirectToSMBDashBoard ? (
                  layoutSettings &&
                  layoutSettings.page &&
                  (layoutSettings.page === 'onboarding' || layoutSettings.hideLayout) ? (
                    <InstantSiteLayout settings={layoutSettings}>
                      <Component {...props} settings={layoutSettings} permissions={permissions} />
                    </InstantSiteLayout>
                  ) : (
                    <Layout settings={{ ...layoutSettings, showScrollUp: true }} setTabRedirect={setTabRedirect}>
                      <Component {...props} settings={layoutSettings} permissions={permissions} />
                      <DCLPopup open={ready && isDCLUser} />
                      {/** EVE-2684 Changes */}
                      {userId && !isPopupAlreadyOpened(userId) && !me.businessProfile && (
                        <DCLGracePeriodPopup
                          open={dclGracePeriodPopupState && isDCLUserInGracePeriod && shouldShowDclWarning}
                          closePopup={onDclGracePeriodPopupClose}
                        />
                      )}
                    </Layout>
                  )
                ) : cookie && cookie.token && enterpriseRoles.includes(cookie.role) ? (
                  <EnterpriseLayout settings={{ ...layoutSettings, showScrollUp: true }}>
                    <Component {...props} settings={layoutSettings} permissions={permissions} />
                    <DCLPopup open={ready && isDCLUser} />
                    {userId && !isPopupAlreadyOpened(userId) && (
                      <DCLGracePeriodPopup
                        open={dclGracePeriodPopupState && isDCLUserInGracePeriod && shouldShowDclWarning}
                        closePopup={onDclGracePeriodPopupClose}
                      />
                    )}
                  </EnterpriseLayout>
                ) : (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location }
                    }}
                  />
                )
              }}
            />
          </>
        )}
      </PermissionConsumer>
    </PermissionProvider>
  )
}

export const EnterpriseRoute = ({ component: Component, layoutSettings = {}, ...rest }) => {
  setCookieParentDomain('deepLink', window.location.href)

  const dispatch = useDispatch()
  const me = useSelector((state) => state.auth.me)

  if (me && me.isMultiLocation && me.total_accounts <= 1) {
    setActiveTotalAccounts(me.active_accounts, me.total_accounts)
    window.location.href = `/${me.user_unique_id}/home`
  }

  const [dclGracePeriodPopupState, setDclGracePeriodPopupState] = useState(true)

  const { ready, isDCLUser, isDCLUserInGracePeriod, shouldShowDclWarning } = useDclStatus()
  const { loading: trialExpiredLoading, hasExpired } = useHasTrialExpired()
  const { loading: ftuLoading, hasFinished, shouldSkip } = useHasFtuFinished()

  const userId = _.get(me, '_id', undefined)
  const feStatus = useSelector((state) => state.firstExperience.status)

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
      return
    }

    handleLang(me)

    if (me && me.isMultiLocation) {
      dispatch.auth.enterpriseDashboardForMultiLocation()
    }

    // setup sentry.io
    setupSentryIo(me)

    if (trialExpiredLoading && ftuLoading) {
      console.log('still loading')
      return
    }

    if (me.status === 'inactive') {
      history.push(`/${me.user_unique_id}/upgrade/new-plan`)
      return
    }

    if (hasExpired) {
      segmentEventTrack({
        event: 'expired_trial_login_attempt',
        properties: {
          trial_end_date: me.trial_ends_at
        }
      })
      if (feStatus !== 'complete') {
        return history.push(`/${me.user_unique_id}/enrolling`)
      }
      history.push(`/${me.user_unique_id}/upgrade/new-plan`)
      return
    }

    if (!hasFinished && !shouldSkip) {
      history.push(`/${me.user_unique_id}/enrolling`)
    }
  }, [me, trialExpiredLoading, ftuLoading, hasExpired, hasFinished, shouldSkip])

  const onDclGracePeriodPopupClose = () => {
    // Do some things with props or state
    setDclGracePeriodPopupState(false)
    if (userId) {
      setPopupAlreadyOpenedInSession(userId)
    }
  }

  return (
    <PermissionProvider>
      <PermissionConsumer>
        {(permissions) => (
          <>
            <Route
              {...rest}
              render={(props) => {
                const cookie = getCookie()
                return cookie && cookie.token && enterpriseRoles.includes(cookie.role) ? (
                  <EnterpriseLayout settings={{ ...layoutSettings, showScrollUp: true }}>
                    <Component {...props} settings={layoutSettings} permissions={permissions} />
                    <DCLPopup open={ready && isDCLUser} />
                    {userId && !isPopupAlreadyOpened(userId) && (
                      <DCLGracePeriodPopup
                        open={dclGracePeriodPopupState && isDCLUserInGracePeriod && shouldShowDclWarning}
                        closePopup={onDclGracePeriodPopupClose}
                      />
                    )}
                  </EnterpriseLayout>
                ) : (
                  <Redirect
                    to={{
                      pathname: '/login',
                      state: { from: props.location }
                    }}
                  />
                )
              }}
            />
          </>
        )}
      </PermissionConsumer>
    </PermissionProvider>
  )
}

export const UnRestrictedRoute = ({ component: Component, layoutSettings = {}, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const cookie = getCookie()
      let queryParams
      if (props.location && props.location.search) {
        queryParams = qs.parse(props.location.search)
      }
      if (queryParams && queryParams.source) {
        setReferralAppSource(queryParams.source)
      }
      return queryParams && ['prospect'].includes(queryParams.role) ? (
        <Outerlayout settings={{ ...layoutSettings }}>
          <Component {...props} settings={layoutSettings} />
        </Outerlayout>
      ) : cookie && cookie.token && queryParams && queryParams.upgrade === 'true' ? (
        <Redirect
          to={{
            pathname: `/${cookie.user_unique_id}/upgrade/new-plan`,
            state: { from: props.location },
            search: `backHome=true${queryParams.promo ? `&promo=${queryParams.promo}` : ''}`
          }}
        />
      ) : cookie && cookie.token && userRoles.includes(cookie.role) ? (
        <Redirect
          to={{
            pathname: `/${cookie.user_unique_id}/home`,
            state: { from: props.location }
          }}
        />
      ) : cookie && cookie.token && enterpriseRoles.includes(cookie.role) ? (
        <Redirect
          to={{
            pathname: `/${cookie.user_unique_id}/analytics`,
            state: { from: props.location }
          }}
        />
      ) : cookie && cookie.token && ['prospect'].includes(cookie.role) ? (
        <Redirect
          to={{
            pathname: `/${cookie.user_unique_id}/self-service`,
            state: { from: props.location }
          }}
        />
      ) : cookie && cookie.token ? (
        redirectToAdmin(cookie)
      ) : (
        <Outerlayout settings={{ ...layoutSettings }}>
          <Component {...props} settings={layoutSettings} />
        </Outerlayout>
      )
    }}
  />
)
export const UpgradeRoute = ({ component: Component, layoutSettings = {}, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const cookie = getCookie()
      return cookie && cookie.token ? (
        <UpgradeLayout settings={{ ...layoutSettings, showScrollUp: true }}>
          <Component {...props} settings={layoutSettings} />
        </UpgradeLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }}
  />
)

export const ReactivateRoute = ({ component: Component, layoutSettings = {}, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      const cookie = getCookie()
      return cookie && cookie.token ? (
        <ReactivateLayout settings={{ ...layoutSettings, showScrollUp: true }}>
          <Component {...props} settings={layoutSettings} />
        </ReactivateLayout>
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location }
          }}
        />
      )
    }}
  />
)

const setupSentryIo = (me) => {
  const userId = _.get(me, '_id', undefined)
  const userEmail = _.get(me, 'user_email', undefined)
  if (!userId && !userEmail) {
    console.warn('no user id or email in me, skip setting up sentry')
    return
  }

  Sentry.configureScope((scope) => {
    scope.setUser({
      id: userId || 'N/A',
      email: userEmail || 'N/A'
    })
  })
}
