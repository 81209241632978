import React from 'react'
import { connect } from 'react-redux'
import Icon from '@components/icons'
import { phoneNumberFormat } from '@helpers/utility'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import MessagePopup from '@components/popups/send-message-popup'
import { history } from '@app/store'
import { capitalize } from '@helpers/index'
import Tooltip from '@components/tooltip'
import { withTranslation } from 'react-i18next'

const Cloader = [1].map((l) => {
  return (
    <div key={l}>
      <div className="d-flex align-items-center mb-20">
        <div className="placeholder-loader w-100 py-15 mr-15"></div>
        <div className="placeholder-loader btn-target border-0"></div>
      </div>
      <div className="d-flex align-items-center">
        <div className="placeholder-loader w-50 py-15 mr-15"></div>
        <div className="placeholder-loader w-50 py-15"></div>
      </div>
    </div>
  )
})

const mapStateToProps = ({ auth, booking, contact, common, myaccount, messenger }) => {
  return {
    ...auth,
    ...booking,
    ...contact,
    ...common,
    ...myaccount,
    ...messenger
  }
}

const mapDispatchToProps = ({ auth, booking, contact, common, myaccount, messenger }) => {
  return {
    ...auth,
    ...booking,
    ...contact,
    ...common,
    ...myaccount,
    ...messenger
  }
}

class ContactCard extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false,
      showSendSmsPopup: false,
      twilioEnabled: false
    }
  }

  componentDidMount() {
    this.init()
  }

  async init() {
    if (this.props.bookingData && this.props.bookingData.contact && this.props.bookingData.contact._id) {
      await this.props.messengerLeads(this.props.bookingData.contact._id)
    }
    this.setState({ loading: false })
  }

  async componentDidUpdate(prevProps) {
    // check if contact _id updated
    if (this.props.bookingData && this.props.bookingData.contact && this.props.bookingData.contact._id) {
      if (
        !prevProps.bookingData ||
        !prevProps.bookingData.contact ||
        this.props.bookingData.contact._id !== prevProps.bookingData.contact._id
      ) {
        this.wrapLoading(() => this.props.messengerLeads(this.props.bookingData.contact._id))
      }
    }
  }

  checkActiveThread() {
    if (
      this.props.bookingData &&
      this.props.bookingData.contact &&
      this.props.messageLeads &&
      this.props.messageLeads.length
    ) {
      return true
    } else return false
  }

  async wrapLoading(callback) {
    this.setState({ loading: true })
    await callback()
    this.setState({ loading: false })
  }

  async sendMessageToContact({ values, contactId, recipient, type, resetForm }) {
    await this.props.sendMessageToContactMessenger({
      id: contactId,
      contact_type: type,
      recipient: recipient,
      ...values
    })
    if (!this.props.apiError) {
      this.messagePopup.closeModal(true, resetForm)
    }
    if (this.props.bookingData && this.props.bookingData.contact && this.props.bookingData.contact._id) {
      this.props.messengerLeads(this.props.bookingData.contact._id)
    }
  }

  async openMessagePopup(item, type) {
    await this.setState({ showSendSmsPopup: true })
    await this.messagePopup.showPopup(
      type === 'email' ? item.email : item.mobile,
      item.contact_id,
      type,
      this.props.me,
      this.props.reportAdmin,
      this.props.predefinedTemplates,
      item.first_name,
      item.last_name,
      this.props.is_enterprise ? this.props.customersIds : null
    )
  }

  async onMessageClick() {
    const { bookingData } = this.props
    await this.setState({ showSendSmsPopup: true })
    if (
      this.props.me &&
      this.props.me.two_factor_auth &&
      !this.isTwilioEnable(this.props.me) &&
      this.props.user.role === 'trial'
    ) {
      await this.props.allocateTwilioNumber()
      await this.verifyGetMe()
    }
    if (
      this.props.me &&
      this.props.me.account_settings &&
      this.props.me.account_settings.twilio &&
      this.props.me.account_settings.twilio.length &&
      this.checkActiveThread()
    ) {
      const lead = this.props.messageLeads[0]
      if (lead && lead.lead && lead.lead._id) {
        if (this.props.is_enterprise) {
          history.push(
            `/${this.props.user && this.props.user.user_unique_id}/enterprise/messenger?threadId=${lead.lead._id}`
          )
        } else history.push(`/${this.props.user && this.props.user.user_unique_id}/messenger?threadId=${lead.lead._id}`)
      }
    } else if (bookingData && bookingData.customer_phone) {
      this.openMessagePopup(
        { mobile: bookingData.customer_phone, contact_id: bookingData.contact && bookingData.contact._id },
        'sms'
      )
    }
  }

  onEmailClick() {
    if (this.props.bookingData && this.props.bookingData.customer_email) {
      this.openMessagePopup(
        {
          email: this.props.bookingData.customer_email,
          contact_id: this.props.bookingData.contact && this.props.bookingData.contact._id
        },
        'email'
      )
    }
  }

  gotoContactDetails() {
    const { bookingData } = this.props
    if (bookingData && bookingData.contact && bookingData.contact._id) {
      if (this.props.is_enterprise) {
        history.push(
          `/${this.props.user && this.props.user.user_unique_id}/enterprise/contacts/edit/${bookingData.contact._id}`
        )
      } else {
        history.push(
          `/${this.props.user && this.props.user.user_unique_id}/contacts/mycontacts/${bookingData.contact._id}`
        )
      }
    }
  }
  verifyPhoneOTP(payload) {
    return new Promise((resolve) => {
      this.props.verifyOtp(payload).then(function (response) {
        resolve(response)
      })
    })
  }

  verifyGetMe() {
    return new Promise((resolve) => {
      this.props.getMe().then(function (response) {
        resolve(response)
      })
    })
  }

  async handleSubmitOtp({ payload, resetForm }) {
    payload.enableTwilio = true
    await this.verifyPhoneOTP(payload)
    if (!this.props.apiError) {
      const me = this.props.me
      me.phone_verified = 1
      me.contact_phone = payload.contact_phone
      await this.props.onMeSuccess(me)
      await this.verifyGetMe()
      if (this.isTwilioEnable(me)) {
        await this.setState({ twilioEnabled: true, showSendSmsPopup: true })
      }
      return true
    } else {
      return false
    }
  }
  isTwilioEnable(me) {
    if (
      this.props.me &&
      this.props.me.account_settings &&
      this.props.me.account_settings.twilio &&
      this.props.me.account_settings.twilio.length
    ) {
      return true
    } else {
      return false
    }
  }

  toggleShowSendSmsPopup() {
    this.setState({
      showSendSmsPopup: false
    })
  }

  render() {
    const t = this.props.t
    const { bookingData = {} } = this.props
    return (
      <div className="quick-view-contact-wrapper px-20 px-sm-30 pb-30 mb-20 border-bottom border-color-light">
        {this.props._loading ? (
          <div>{Cloader}</div>
        ) : bookingData && (bookingData._id || bookingData.booking_id) ? (
          <>
            <div className="d-flex justify-content-between mb-15">
              <div>
                <div>
                  <h2 className="text-break mr-15">
                    {bookingData.first_name ? (
                      <>
                        {capitalize(bookingData.first_name || '')} {capitalize(bookingData.last_name || '')}
                      </>
                    ) : bookingData.customer_email ? (
                      bookingData.customer_email
                    ) : bookingData.customer_phone ? (
                      phoneNumberFormat(bookingData.customer_phone)
                    ) : null}
                  </h2>
                  {/* <span className="rounded-circle d-inline-block status status-online mr-5"></span>
                                    Online now */}
                </div>
              </div>
              {/* check if contact is synced and is not deleted */}
              {bookingData &&
              bookingData.contact &&
              bookingData.contact._id &&
              bookingData.contact.status !== 'trash' &&
              bookingData.contact.is_public ? (
                <button
                  type="button"
                  className="rounded d-flex align-items-center p-0 justify-content-center btn-target"
                  id="btnShareableBtn"
                  onClick={this.gotoContactDetails.bind(this)}>
                  <i className={'btn-icon d-flex'}>
                    <Icon name="targetLink" />
                  </i>
                </button>
              ) : null}
            </div>
            <div className="d-flex justify-content-between" style={{ marginBottom: '10px' }}>
              {bookingData && bookingData.customer_phone ? (
                <>
                  <button className="btn btn-block mr-15 btn-outline px-5" onClick={this.onMessageClick.bind(this)}>
                    <i className="btn-icon top-minus-2 icon-message">
                      <Icon name="menuMessengerActive" />
                    </i>
                    <span className="d-inline-block ml-10 fz-12 position-relative top-minus-1">
                      {t('messenger.message')}
                    </span>
                  </button>
                  <button className="btn btn-block btn-outline px-15 mt-0">
                    <Tooltip id="codeCopyTootip" position="bottom" message={t('bookings.copy-to-clipboard')}>
                      <i className="btn-icon icon-phone">
                        <Icon name="phoneReceiver" />
                      </i>
                      <CopyToClipboard
                        text={phoneNumberFormat(bookingData.customer_phone)}
                        onCopy={() => {
                          // notification("success", )
                          window
                            .$('#codeCopyTootip')
                            .tooltip('show')
                            .attr('data-original-title', t('bookings.copied-to-clipboard'))
                        }}>
                        <span className="d-inline-block ml-10 fz-12 position-relative top-minus-1">
                          {phoneNumberFormat(bookingData.customer_phone)}
                        </span>
                      </CopyToClipboard>
                    </Tooltip>
                  </button>
                </>
              ) : bookingData && bookingData.customer_phone ? (
                <>
                  {this.checkActiveThread() ? (
                    <button
                      className={'btn mr-15 px-15 btn-outline btn-block'}
                      onClick={this.onMessageClick.bind(this)}>
                      <i className="btn-icon top-minus-2 icon-message">
                        <Icon name="menuMessengerActive" />
                      </i>
                      <span className="d-inline-block ml-10 fz-12 position-relative top-minus-1">
                        {t('messenger.message')}
                      </span>
                    </button>
                  ) : null}
                </>
              ) : null}
            </div>
            {bookingData && bookingData.customer_email && this.props.permissions.isBookingPermitted && (
              <button className="btn btn-block mt-0 px-15 btn-outline" onClick={this.onEmailClick.bind(this)}>
                <i className="btn-icon icon-email">
                  <Icon name="email" />
                </i>
                <span className="d-inline-block ml-10 fz-12">{t('settings.email-1')}</span>
              </button>
            )}
          </>
        ) : null}
        {this.state.showSendSmsPopup ? (
          <MessagePopup
            ref={(ref) => (this.messagePopup = ref)}
            onSubmit={this.sendMessageToContact.bind(this)}
            verifyMobile={this.props.verifyMobile}
            onRequestOtpOnCall={this.props.onRequestOtpOnCall}
            verifyOtp={this.props.verifyOtp}
            handleSubmitOtp={this.handleSubmitOtp.bind(this)}
            me={this.props.me}
            close={this.toggleShowSendSmsPopup.bind(this)}
            twilioEnabled={this.state.twilioEnabled}
          />
        ) : (
          ''
        )}
      </div>
    )
  }
}

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(ContactCard))
