import React from "react";
import asyncComponent from "@helpers/async-func";
import { RestrictedRoute } from "@app/router/access-route"


export default [
    <RestrictedRoute
        exact
        key="/campaigns"
        path={"/:userId/campaigns"}
        layoutSettings={{ page: "campaigns" }}
        component={asyncComponent(() => import("./list"))}
    />,
    <RestrictedRoute
        exact
        key="/create-campaign"
        path={"/:userId/emailbuilder"}
        layoutSettings={{ page: "campaigns" }}
        component={asyncComponent(() => import("./create"))}
    />,
    <RestrictedRoute
        exact
        key="/email-themes"
        path={"/:userId/emailthemes"}
        layoutSettings={{ page: "campaigns" }}
        component={asyncComponent(() => import("./utility/emailTheme"))}
    />,
    <RestrictedRoute
        exact
        key="/campaign-details"
        path={"/:userId/campaigns/emailDetails/:campaignId"}
        layoutSettings={{ page: "campaigns" }}
        component={asyncComponent(() => import("./view"))}
    />,
]