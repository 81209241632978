import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    path={'/:userId/contacts/mycontacts'}
    key="contact-list"
    layoutSettings={{ page: 'contacts' }}
    component={asyncComponent(() => import('./contacts/list'))}
  />,
  <RestrictedRoute
    exact
    path={'/:userId/contacts/mycontacts/:id'}
    key="contact-edit"
    layoutSettings={{ page: 'contacts' }}
    component={asyncComponent(() => import('./contacts/edit'))}
  />,
  <RestrictedRoute
    exact
    path={'/:userId/contacts/groups'}
    key="group-list"
    layoutSettings={{ page: 'contacts' }}
    component={asyncComponent(() => import('./groups'))}
  />,
  <RestrictedRoute
    exact
    path={'/:userId/contact/settings'}
    key="contact-settings"
    layoutSettings={{ page: 'contacts' }}
    component={asyncComponent(() => import('./settings'))}
  />,
  <RestrictedRoute
    exact
    key="/contact/trail"
    path={'/:userId/trial/contacts'}
    layoutSettings={{ page: 'contacts' }}
    component={asyncComponent(() => import('./preview'))}
  />
]
