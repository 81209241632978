import { mediaApi,api, catchHandler } from "@helpers/axios";
import config from "@config/index"
import {objToUrlParams} from "@helpers/index"

export function fetchMedia(payload) {
    // console.log("mediaApi",mediaApi())
    return mediaApi()
        .get(`${config.routes.fetchMedia}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function fetchFolders() {
    return mediaApi()
        .get(`${config.routes.fetchMedia}/folders?sort=folder&asc=true`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function deleteMedia(payload) {
    return mediaApi()
        .delete(`${config.routes.fetchMedia}/${payload.id}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function onImageUpload(payload,path) {
    if(path){
        return mediaApi()
            .post(`${config.routes.fetchMedia}/${path}upload`,payload)
            .then(res => res.data)
            .catch(catchHandler)
    }else{
        return mediaApi()
            .post(config.routes.onUploadMedia,payload)
            .then(res => res.data)
            .catch(catchHandler)
    }
}
export function fetchIcons() {
    return mediaApi()
        .get(`${config.routes.fetchIcons}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getGooglePhotos(data) {
    return api()
        .post(config.routes.googlePhoto,data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getFbPhotos(data) {
    return api()
        .post(config.routes.facebookPhoto,data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function editUpload(payload) {
    return mediaApi()
        .post(config.routes.editMedia,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getShutterStockMedia(payload) {
    return mediaApi()
        .get(`${config.routes.shutterstockSearch}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function saveShutterstock(payload,url) {
    return mediaApi()
        .post(`${config.routes.saveShutterstock}?${objToUrlParams(url)}`,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getHqShutterstockImage(payload,url) {
    return mediaApi()
        .post(`${config.routes.shutterstockImage}?${objToUrlParams(url)}`,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function downloadShutterstockImage(payload,url) {
    return mediaApi()
        .post(`${config.routes.shutterstockImageDownload}?${objToUrlParams(url)}`,payload)
        .then(res => res.data)
        .catch(catchHandler)
}