// import { store } from '@app/store'

export const paymentChangers = () => {
  return `2.9% + 30¢`
  // To show the exact amount in dollars.
  // const state = store.getState()
  // const accountSettings = state.accountsettings
  // return accountSettings && accountSettings.accountsettings && accountSettings.accountsettings.payment
  //   ? `$${state.accountsettings.accountsettings.payment.online_transaction_fee || 0} + ${state.accountsettings.accountsettings.payment.online_transaction_fee_fixed || 0}¢` : `2.9% + 30¢`
}
