import { getActionCenterWithRoute, postActionCenterClick, ClickActions } from './service'
import { createModel } from '@rematch/core'
import { ActionCardData, ActionCardIcon, ActionNames, ActionAlert, ActionCenterUserStatus } from './types'
import {
  trackACCompleted,
  trackACDidSeeActionCenterFirstTime,
  trackACExpired
} from '@helpers/segmentEvents/actionCenter'

interface ActionCenterApiItem {
  action_name: ActionNames
  title: string
  description: string
  has_clicked: boolean
  is_completed: boolean
  date_completed: string // iso date string
  show_dashboard_element: boolean
  completed_title: string
  completed_description: string
}

interface ActionCenterApiSuccessResult {
  actions: ActionCenterApiItem[]
  all_actions_completed: boolean
  all_actions_date_completed: string // iso date string
  status: 'success'
  first_created: boolean
  created_at: Date
}

interface ActionCenterApiExceptionResult {
  status: 'preexisting_user' | 'expired'
  message: string
}

type ActionCenterApiResult = ActionCenterApiSuccessResult | ActionCenterApiExceptionResult

export interface ActionCenterState {
  cards: ActionCardData[]
  alerts: ActionAlert[]
  status: ActionCenterUserStatus
}

const mapActionToIconName = (actionName: ActionNames): ActionCardIcon | undefined => {
  if (actionName === 'contacts') {
    return 'contact'
  } else if (actionName === 'messenger') {
    return 'messenger'
  } else if (actionName === 'review_request') {
    return 'reviewNormal'
  } else if (actionName === 'booking') {
    return 'ac_bookings'
  } else if (actionName === 'multilocation') {
    return 'locationPointer'
  }
  return undefined
}

// needs RootModel generic for createModel
// does it really tho... ?
export const actionCenter = createModel()({
  state: {
    cards: [],
    alerts: [],
    status: 'uninitialized'
  } as ActionCenterState,
  reducers: {
    setActionCenterSuccessStateFromApi: (state, payload: ActionCenterApiSuccessResult) => {
      // get action cards
      // we don't need the celebration card. It also causes bugs with the number of ProgressChunks
      const cards: ActionCardData[] = payload.actions
        .filter((data) => data.action_name !== 'celebration')
        .reduce((acc: ActionCardData[], { title, description, action_name, is_completed }) => {
          const icon = mapActionToIconName(action_name)
          const card: ActionCardData = {
            title,
            text: description,
            isCompleted: is_completed,
            icon: icon || 'spinner',
            actionName: action_name
          }
          acc.push(card)
          return acc
        }, [])

      // get alerts
      const alerts = payload.actions.reduce((acc, action) => {
        if (action.completed_title && action.completed_description) {
          acc.push({
            title: action.completed_title,
            text: action.completed_description,
            isCompleted: action.is_completed
          })
        }
        return acc
      }, [] as ActionAlert[])

      // update status
      const status: ActionCenterUserStatus = payload.all_actions_completed ? 'all_completed' : 'show'

      // segment track events
      if (status === 'all_completed' && state.status === 'show') trackACCompleted()

      return {
        ...state,
        cards,
        alerts,
        status
      }
    },
    setActionCenterExceptionStateFromApi: (state, payload: ActionCenterApiExceptionResult) => {
      if (payload.status === 'expired') trackACExpired()

      return {
        ...state,
        status: 'exception'
      }
    }
  },
  effects: (dispatch) => ({
    getActionCenter: async (_, rootState) => {
      const { status }: ActionCenterState = rootState.actionCenter

      // don't make request for exception users or when all items are completed
      if (status === 'exception' || status === 'all_completed') return

      try {
        const actionCenterResult: ActionCenterApiResult = await getActionCenterWithRoute()
        if (actionCenterResult.status === 'success') {
          if (actionCenterResult.first_created) trackACDidSeeActionCenterFirstTime()
          dispatch.actionCenter.setActionCenterSuccessStateFromApi(actionCenterResult)
        } else {
          dispatch.actionCenter.setActionCenterExceptionStateFromApi(actionCenterResult)
        }
      } catch (e) {
        console.error(e)
      }
    },
    clickActionCenterCard: async (itemClicked: ClickActions, rootState) => {
      try {
        const data = await postActionCenterClick(itemClicked)
        if (data.message !== 'Success') {
          console.error('Action Center Click Error: ', data.message)
        }
      } catch (error) {
        console.error(error)
      }
    }
  })
})
