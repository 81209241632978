/* eslint-disable camelcase */
import React from 'react'
import moment from 'moment'
import Icon from '@components/icons'
import style from '../index.module.scss'
import { WisetackPromotionalMessageContainer } from '@app/containers/wisetack/WisetackPromotionalMessageContainer'
import { WisetackDisclosureContainer } from '@app/containers/wisetack/WisetackDisclosureContainer'
import { USDOLLAR } from '../constants'
import { useTranslation } from 'react-i18next'

const InvoicePreview = ({ invoiceData = {}, me = {}, invoiceEdit }) => {
  const { t } = useTranslation()
  const {
    customer_first_name,
    customer_last_name,
    customer_phone,
    customer_email,
    customer_address,
    customer_address2,
    customer_city,
    customer_state,
    customer_zip,
    invoice_amount,
    invoice_items
  } = invoiceData

  const processItems = (items) =>
    items.map((item) => (
      <React.Fragment key={item.service_name}>
        <div>
          <div className={style['item-title']}>
            <div style={{ color: '#576C77' }}>{item.service_name}</div>
            <div>{USDOLLAR.format(item.service_price_total)}</div>
          </div>
          <div className={style['item-count']}>
            <div>QTY</div>
            <div>{item.service_quantity}</div>
          </div>
          <div className={style['item-price']}>
            <div>{t('payments.rate')}</div>
            <div>{`$${item.service_price}`}</div>
          </div>
        </div>
        <div className={style['subtotal']} style={{ paddingTop: '0.625rem' }}>
          <div>{t('payments.subtotal')}</div>
          <div className={style['number']}>{USDOLLAR.format(item.service_price_total)}</div>
        </div>
      </React.Fragment>
    ))
  const handleClick = () => {
    invoiceEdit(invoiceData)
  }

  const makeBillToContact = () => {
    return (
      <>
        {customer_first_name && customer_last_name ? (
          <div style={{ paddingBottom: '10px' }}>{`${customer_first_name} ${customer_last_name}`}</div>
        ) : null}
        {customer_address || customer_address2 || customer_city || customer_state || customer_zip ? (
          <div style={{ paddingBottom: '10px' }}>
            {customer_address || ''} {customer_address2 ? ' ' + customer_address2 : ''} <br />
            {customer_city ? customer_city + ', ' : ''} {customer_state ? customer_state + ' ' : ''}
            {customer_zip || ''}
          </div>
        ) : null}
        {customer_phone ? <div style={{ paddingBottom: '10px' }}>{customer_phone}</div> : null}
        {customer_email ? <div>{customer_email}</div> : null}
      </>
    )
  }

  return (
    <div className={style['invoice-preview-container']}>
      <div className={style['header']}>
        <div className={style['title']}>{t('payments.invoice-summary')}</div>
        {invoiceEdit && (
          <div className={style['icon-container']} onClick={handleClick} data-test="edit-invoice-form-btn">
            <Icon name="editPencilPaper" />
            <div data-test="invoice-preview-edit-btn" className={style['link']}>
              {t('payments.edit-invoice')}
            </div>
          </div>
        )}
      </div>
      <div className={style['preview-main']}>
        <div className={style['main-header']}>
          <div className={style['head']}>{`${
            me.bussiness_name ? me.bussiness_name : me.account_settings.bussiness_name
          } - ${moment().format('MMM YYYY')}`}</div>
          <div className={style['sub']}>{`${moment(
            invoiceData.scheduled_date ? invoiceData.scheduled_date : undefined
          ).format('MMMM D, YYYY')}`}</div>
        </div>
        <div className={style['bill-to']}>
          <div className={style['head']}>{t('payments.bill-to-0')}</div>
          <div data-test="invoice-preview-contact" className={style['contact']}>
            {makeBillToContact()}
          </div>
        </div>
        <div data-test="invoice-preview-item-list" className={style['item-list']}>
          {invoice_items && processItems(invoice_items)}
        </div>
        <div className={style['total']}>
          <div className={style['title']}>{t('payments.total')}</div>
          <div>{USDOLLAR.format(invoice_amount)}</div>
        </div>
        <WisetackPromotionalMessageContainer amountInCents={invoice_amount * 100} />
        <WisetackDisclosureContainer amountInCents={invoice_amount * 100} />
      </div>
    </div>
  )
}

export default InvoicePreview
