import * as service from "./services"

export default {
    state: {
  
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state) {
            return {
                ...state,
                loading: false
            };
        },
        onGetSettings(state, data) {
            return {
                ...state,
                loading: false,
                preview: data
            }
        },
        onGetPredefinedMessageTemplates(state, data) {
            return {
                ...state,
                loading: false,
                predefined_templates: data.data
            }
        },
        onListContacts(state, data) {
            return {
                ...state,
                loading: false,
                contacts: data
            }
        },
    },
    effects: {
        async getSettings(user) {
            this.onRequest()
            try {
                let res = await service.getSettings(user)
                this.onGetSettings(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async getPredefinedMessageTemplates(user) {
            this.onRequest()
            try {
                let res = await service.getPredefinedMessageTemplates(user)
                this.onGetPredefinedMessageTemplates(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async savePredefinedTemplates(user,temp) {
            this.onRequest()
            try {
                let res = await service.savePredefinedTemplates(user,temp.review_request.predefined_templates)
                this.onGetPredefinedMessageTemplates(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async listContacts(payload) {
            this.onRequest()
            try {
                let res = await service.listContacts(payload)
                this.onListContacts(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
    }
};
  