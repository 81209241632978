import React from "react"
import InputMask from "react-input-mask";


class TimeInput extends React.Component {
    onChange = (event) => {
        let { field } = this.props;
        this.props.handleChange(event.target.value, field)
    }
    onBlur = (event) =>{
      try {
        this.props.actionOnBlur()
      } catch (error) {
        console.log(error)
      }
    }
    render() {
        let { time, disabled, placeholder ,isTimeLimited } = this.props;
        let startsWithOne;
        if (time) {
            startsWithOne = time[0] === "2"
        }
        let mask;
        if(isTimeLimited){
            mask = [/[0-2]/, startsWithOne ? /[0-3]/ : /[0-9]/, ":", /[0-5]/, /[0-9]/]
        }else{
            mask = [/[0-9]/,/[0-9]/, ":", /[0-5]/, /[0-9]/] 
        }
        return (
            <InputMask id={this.props.id} onBlur={this.onBlur} maskPlaceholder="0" disabled={disabled} className={`form-control form-control-lg border-0 px-0 text-secondary ${this.props.page === "bookingSettings" ? "width-50" : ""}`} placeholder={placeholder} mask={mask} value={time} onChange={this.onChange} />
        );
    }
}
export default TimeInput