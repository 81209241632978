import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'

export default {
  state: {},
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess (state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError (state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
            ? data.data.message
            : data && data.error
              ? data.error
              : data && data.data && data.data.error
                ? data.data.error
                : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    ontriggerContactSalesSuccess (state, data) {
      return {
        ...state,
        contactSalesStatus: data
      }
    },
    ontriggerContactSalesError (state, data) {
      return {
        ...state,
        contactSalesStatus: data
      }
    },
    onListManagersSuccess (state, data) {
      return {
        ...state,
        loading: false,
        managersList: data
      }
    },
    onCreateManagerSuccess (state, data) {
      notification('success', 'User has been created successfully')
      return {
        ...state,
        loading: false,
        createdManager: data.data
      }
    },
    onManageUsersSuccess (state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onGetManagerDataSuccess (state, data) {
      return {
        ...state,
        loading: false,
        managerData: {
          ...data.data,
          tags: data.tags
        }
      }
    },
    onEditManagerSuccess (state, data) {
      return {
        ...state,
        loading: false,
        editedManager: data
      }
    },
    onDeleteManagerSuccess (state, data) {
      notification('success', 'Successfully deleted manager')
      return {
        ...state,
        loading: false
      }
    }
  },
  effects: {
    async listManagers (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.listManagers(payload)
        this.onListManagersSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async createManager (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createManager(payload)
        this.onCreateManagerSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async manageUsers (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.manageUsers(payload)
        this.onManageUsersSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getManagerData (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getManagerData(payload)
        this.onGetManagerDataSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async editManager (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.editManager(payload)
        notification(
          'success',
          payload.status === 'active'
            ? 'User has been activated successfully'
            : payload.status === 'suspended'
              ? 'User has been suspended successfully'
              : 'User has been updated successfully'
        )
        this.onEditManagerSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async triggerContactSales (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.triggerContactSales(payload)
        this.ontriggerContactSalesSuccess(res, payload)
        return res
      } catch (e) {
        console.log(e)
        this.ontriggerContactSalesError(e)
      }
    },
    async deleteManager (payload) {
      this.onRequest()
      try {
        const res = await service.deleteManager(payload)
        this.onDeleteManagerSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
