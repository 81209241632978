import { api, catchHandler } from "@helpers/axios";
import {objToUrlParams} from "@helpers/index"
import config from "@config/index";

export function getSettings(user) {
    return api()
        .get("/accountsettings/getaccountsettings/"+user)
        .then(res => res.data)
        .catch(catchHandler);
}
export function getPredefinedMessageTemplates(user) {
    return api()
        .get("/accountsettings/predefined_templates/"+user)
        .then(res => res.data)
        .catch(catchHandler);
}
export function savePredefinedTemplates(user,temp) {
    return api()
        .post("/accountsettings/predefined_templates/"+user,temp)
        .then(res => res.data)
        .catch(catchHandler);
}
export function listContacts(payload) {
    return api()
        .get(`${config.routes.contacts}?${objToUrlParams(payload)}`)
    // .get(`/contacts?nopaging=true&status=active&count=true`)
        .then(res => res.data)
        .catch(catchHandler);
}
