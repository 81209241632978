import { EVENT_NAMES } from './constants'
import { segmentEventTrack } from '@helpers/segment'

export const trackSendMessage = (properties) => {
  const data = {
    event: EVENT_NAMES.SEND_MESSAGE,
    properties
  }
  segmentEventTrack(data)
}
