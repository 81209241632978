import { useEffect, useState } from 'react'
import { getReviewShareableLink } from '@app/containers/reviews/service'

export const useReviewShareableLink = () => {
  const [shareableURL, setShareableURL] = useState('')
  useEffect(() => {
    const getShareableLink = async () => {
      const { url } = await getReviewShareableLink()
      setShareableURL(url)
    }
    getShareableLink().catch(console.error)
  }, [shareableURL])

  return {
    shareableURL
  }
}
