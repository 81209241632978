import { segmentEventTrack } from '../segment'

export const trackUNBEvent = (me, event) => {
  if (me) {
    // business_email_address: user_email,

    const { _id, user_unique_id, role_id, created_at, primaryCategories, user_email } = me
    const data = {
      event: event,
      properties: {
        user_id: _id,
        user_unique_id: user_unique_id.toString(),
        role: role_id.role_name,
        account_creation_date: created_at,
        business_category: primaryCategories && primaryCategories.category_name,
        email: user_email,
        platform: 'Web'
      }
    }
    segmentEventTrack(data)
  }
}
