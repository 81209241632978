import React from 'react'

const Cloader = [1, 2, 3, 4, 5].map((l) => {
  return <div key={l} className="placeholder-loader mb-10 py-15"></div>
})

class CustomFieldsCard extends React.Component {
  render () {
    return (
      <div className="booking-history overflow-y-scrollbar quick-view-booking-history-wrapper px-20 px-sm-30">
        <h2 className="mb-5">Additional Information</h2>
        {this.props._loading ? (
          <div>{Cloader}</div>
        ) : this.props.bookingData &&
          this.props.bookingData.custom_fields &&
          this.props.bookingData.custom_fields.length > 0 ? (
            <ul className={'pl-0 b-history-data pt-15'}>
              {this.props.bookingData.custom_fields.map((field, index) => {
                return (
                  <div className="pb-20 pt-10 mb-20 border-bottom border-color-light" key={index}>
                    <div className="px-20 px-sm-30">
                      <h5 className="mb-10 light-text-color fz-12">{field.name.toUpperCase()}</h5>
                      {field.type === 'boolean' ? (
                        field.value ? (
                          <div className="mb-3">Yes</div>
                        ) : (
                          <div className="mb-3">No</div>
                        )
                      ) : (
                        <div className="mb-3">{field.value}</div>
                      )}
                    </div>
                  </div>
                )
              })}
            </ul>
          ) : null}
      </div>
    )
  }
}

export default CustomFieldsCard
