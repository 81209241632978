import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { AxiosResponse } from 'axios'
import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript'
import { USDOLLAR } from '../payment/constants'

export type SubscriptionCustomer = {
  firstName: string
  lastName: string
  email: string
}

export type SubscriptionBillingAddress = {
  street: string
  city: string
  state: string
  zip: string
  country: string
}

export type Subscription = {
  planId: string
  customer: SubscriptionCustomer
  billingAddress: SubscriptionBillingAddress
  chargebeeToken: string
  promoCode: string
}

export type EstimateSubscription = {
  planId: string
  promoCode?: string
}

@JsonObject('EstimateSubscriptionResult')
export class EstimateSubscriptionResult {
  @JsonProperty('estimatedAmount', Number)
  estimatedAmount: number = 0

  static fromJson(json: object): EstimateSubscriptionResult {
    return new JsonConvert().deserializeObject(json, EstimateSubscriptionResult)
  }
}

export type EstimateV2SubscriptionResult = {
  priceSummaryInDollars: {
    total: Number
    plan:  Number
    additionalTools: Number
    additionalUsers: Number
    additionalLocations: Number
    annualDiscount: Number
    promocodeDiscount: Number
}
}

export type SubscribeResult = {
  code: string
  message: string
  errorType?: string
}

export type ChargbeBeeKey = {
  key: string
}

export class UpgradeToProPlanDetail {
  priceAfterPromo?: number = undefined
  price: number = 0
  id: string = ''
  promoCode: string = ''
  name: string = ''
  period_unit: string = ''
  period: number = 0

  constructor(obj?: Partial<UpgradeToProPlanDetail>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }

  get priceStringAfterDecimal(): string {
    if (this.priceAfterPromo !== undefined) {
      return `${USDOLLAR.format(this.priceAfterPromo / 100)}`
    }
    return `${USDOLLAR.format(this.price / 100)}`
  }

  get priceStringBeforeDecimal(): string {
    if (this.priceAfterPromo !== undefined) {
      return new Intl.NumberFormat().format(Math.trunc(this.priceAfterPromo / 100))
    }
    return new Intl.NumberFormat().format(Math.trunc(this.price / 100))
  }

  get originalPriceStringAfterDecimal(): string {
    return `${USDOLLAR.format(this.price / 100)}`
  }

  get originalPriceStringBeforeDecimal(): string {
    return new Intl.NumberFormat().format(Math.trunc(this.price / 100))
  }

  get pricePerYear(): number {
    const scale: number | undefined = {
      day: 365,
      week: 52.143,
      month: 12,
      year: 1
    }[this.period_unit]

    if (scale === undefined) {
      throw new Error(`not a valid period_unit - ${this.period_unit}`)
    }

    return (scale * this.price) / this.period
  }
}

@JsonObject('PromoCodeDetail')
export class PromoCodeDetail {
  @JsonProperty('apply_on', String)
  applyOn: 'invoice_amount' | 'each_specified_item' = 'invoice_amount'

  @JsonProperty('created_at', Number)
  createdAt: number = 0

  @JsonProperty('discount_percentage', Number, true)
  discountPercentage?: number = undefined

  @JsonProperty('discount_amount', Number, true)
  discountAmount?: number = undefined

  @JsonProperty('discount_type', String)
  discountType: 'percentage' | 'fixed_amount' = 'fixed_amount'

  @JsonProperty('duration_type', String)
  durationType: 'limited_period' | 'one_time' | 'forever' = 'forever'

  @JsonProperty('id', String)
  id: string = 'INVALID'

  @JsonProperty('invoice_name', String, true)
  invoiceName: string = ''

  @JsonProperty('name', String)
  name: string = 'INVALID'

  @JsonProperty('period', Number, true)
  period?: number = undefined

  @JsonProperty('period_unit', String, true)
  periodUnit?: 'month' | 'day' | 'week' | 'year' = undefined

  @JsonProperty('status', String, true)
  status: 'active' | 'expired' | 'archived' | 'deleted' = 'active'

  @JsonProperty('updated_at', Number, true)
  updatedAt?: number = undefined

  @JsonProperty('plan_ids', [String], true)
  planIds?: string[] = undefined

  constructor(obj?: Partial<PromoCodeDetail>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }

  canAppliedTo(plandId: string): boolean {
    if (!this.planIds) {
      return true
    }

    return this.planIds.indexOf(plandId) >= 0
  }

  static fromJson(json: object): PromoCodeDetail {
    return new JsonConvert().deserializeObject(json, PromoCodeDetail)
  }
}

export const getPromoCodeDetail = (promoCode: string): Promise<PromoCodeDetail | void> =>
  api()
    .get(`${config.routes.chargbeePromoCode}/${promoCode}`)
    .then((res) => PromoCodeDetail.fromJson(res.data))
    .catch(catchHandler)

export const estimateSubscription = (subscription: EstimateSubscription): Promise<EstimateSubscriptionResult | void> =>
  api()
    .post(config.routes.estimateSubscription, subscription)
    .then((res) => EstimateSubscriptionResult.fromJson(res.data))
    .catch(catchHandler)

export const estimateV2Subscription = (subscription: EstimateSubscription): Promise<EstimateV2SubscriptionResult | void> =>
  api()
    .post(config.routes.estimateV2Subscription, subscription)
    .then((res) => res.data)
    .catch(catchHandler)
    
export const createSubscription = (subscription: Subscription): Promise<SubscribeResult> =>
  api()
    .post(config.routes.createSubscription, subscription)
    .then((res) => res.data)
    .catch(catchHandler)

export const createSubscriptionV2 = (subscription: Subscription): Promise<SubscribeResult> =>
  api()
    .post(config.routes.createSubscriptionV2, subscription)
    .then((res) => res.data)
    .catch(catchHandler)

export const fetchChargeBeePublishableKey = (): Promise<ChargbeBeeKey | void> => {
  return api()
    .get<{ publishable_key: string }>(config.routes.chargebeePublishableKey)
    .then((data: AxiosResponse<{ publishable_key: string }>) => {
      return {
        key: atob(data.data.publishable_key)
      }
    })
    .catch(catchHandler)
}

export const fetchChargeBeePlans = (params: { limit: number; offset?: string } = { limit: 10 }) => {
  return api()
    .get(config.routes.chargbeeUpgradePlans, { params })
    .then((res) => res.data)
    .catch(catchHandler)
}

export const fetchUpgradeToProPlan = async (): Promise<UpgradeToProPlanDetail[]> => {
  try {
    let offset = undefined,
      res = undefined
    let plans: { id: string; cf_display_in_gosite_upgrade_page?: string }[] = []
    do {
      res = await fetchChargeBeePlans({ limit: 100, offset })
      offset = res.next_offset
      plans = plans.concat(res.list.map((l: { plan: { id: string } }) => l.plan))
    } while ('next_offset' in res)

    return plans
      .filter((p) => 'cf_display_in_gosite_upgrade_page' in p && p['cf_display_in_gosite_upgrade_page'] === 'True')
      .map((p) => new UpgradeToProPlanDetail(p))
  } catch (err) {
    catchHandler(err)
  }

  return []
}

export const estimateUpdateSubscription = (params: {
  plan_id: string
  addons: Array<{
    id: string
    quantity: number
  }>
}) => {
  return api()
    .post(config.routes.estimateUpdateSubscription, params)
    .then((res) => res.data)
    .catch(catchHandler)
}

export const updateSubscription = (params: {
  planId: string
  productAddons: Array<{
    slug: string
    id: string
  }>
  limitAddons: Array<{
    id: string
    quantity: number
    limitName: string
  }>
}) => {
  return api()
    .put(config.routes.updateSubscription, params)
    .then((res) => res.data)
    .catch(catchHandler)
}


export const getPlan = (planId: string) => {
  return api()
    .get(`${config.routes.getPlan}/${planId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export const migrateUser = async () => {
  return await api()
    .post(config.routes.migrateUser, {})
    .then((res) => res.data)
    .catch(catchHandler)
}
