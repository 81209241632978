import { api, builderApi, catchHandler } from '@helpers/axios';
import { objToUrlParams } from '@helpers/index';
import config from '@config/index';

export function listBusinessDetails(payload) {
  return api()
    .get(`${config.routes.businessData}/search?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function getBusinessDetails(payload) {
  return api()
    .get(`${config.routes.businessData}/details?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function sendRocketFuelBusinessData(payload) {
  return api()
    .post(`${config.routes.rocketDataBussdata}`, payload)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function getRocketFuelBusinessData(payload) {
  return api()
    .get(`${config.routes.rocketDataBussdata}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function createWebsite() {
  return builderApi()
    .post(`${config.routes.websiteV3}?version=0.0.2`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function updateWebsite(payload) {
  return builderApi()
    .patch(`${config.routes.websiteV3}/${payload.id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function getWebsite(id) {
  return builderApi()
    .get(`${config.routes.websiteV3}/${id}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function listWebsite(payload) {
  return builderApi()
    .get(`${config.routes.websiteV3}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function deleteWebsite(id) {
  return builderApi()
    .delete(`${config.routes.websiteV3}/${id}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function publishWebsite(payload) {
  const replaceQueryParam = payload.replace ? `?replace=${payload.replace}` : '';
  return builderApi()
    .post(`${config.routes.websiteV3}/${payload.id}/publish${replaceQueryParam}`)
    .then((res) => res.data)
    .catch((e) => {
      /* 
        Axios automatically throws errors when servers return 400/500 level status codes. The typical pattern in this app is to crash when this occurs.
        In this case this is problematic since the publishing API returns a 400 status code when a user hits their publishing limit, which is an 
        "acceptable" event. */
      if (
        e.response &&
        e.response.data &&
        e.response.data.error &&
        e.response.data.error.type === 'REACHED_PUBLISH_LIMIT'
      ) {
        return e.response.data;
      } else {
        return catchHandler(e);
      }
    });
}
export function publishWebsiteStatus(id) {
  return builderApi()
    .get(`${config.routes.websiteV3}/${id}/publish`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function updatePublishedWebsite(payload) {
  return builderApi()
    .put(`${config.routes.websiteV3}/${payload.id}/publish`, payload.body)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function getSuggestedServices(payload) {
  return api()
    .get(`${config.routes.suggestedServices}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function createProgress(payload) {
  return api()
    .post(`${config.routes.progress}`, payload)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function getProgress(payload) {
  return api()
    .get(`${config.routes.progress}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function updateProgress(payload) {
  return api()
    .put(`${config.routes.progress}/${payload.id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function deleteProgress(payload) {
  return api()
    .delete(`${config.routes.progress}/${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler);
}
export function domainIntent(payload, website_id) {
  return builderApi()
    .post(`${config.routes.websiteV3}/${website_id}/domain-intent`, payload)
    .then((res) => res.data)
    .catch(catchHandler);
}
