import _ from 'lodash'
import React, { useEffect, createContext, useMemo, useContext, FunctionComponent } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '@app/store'
import { USER_ROLES, ROLE_NAME_PATH } from './constants';
import { FeatureFlag, getFeatureVal } from './configcat'

interface IPermissions {
  isContactExportPermitted: boolean,
  isContactDeletePermitted: boolean,
  isBulkContactsDeletePermitted: boolean,
  isBulkContactsExportPermitted: boolean,
  isContactEditPermitted: boolean,
  isContactViewPermitted: boolean,
  isContactCreatePermitted: boolean,
  isReviewCreatePermitted: boolean,
  isReviewViewPermitted: boolean,
  isReviewEditPermitted: boolean,
  isReviewDeletePermitted: boolean,
  isManagePostPermitted: boolean,
  isManageGMBPermitted: boolean,
  isMessengerSendPermitted: boolean,
  isContactVerificationPermitted: boolean,
  isBusinessSettingsManagePermitted: boolean,
  isReadOnlyRole: boolean
}
interface IPermissionConsumer {
  children(permisssions: IPermissions): React.ReactNode;
}

export const usePermissions = () => {
  const dispatch = useDispatch<Dispatch>()
  const me = useSelector((state: RootState) => (state.auth as any).me)
  return useMemo(() => {
    const permissions = new Set<string>(_.get(me, 'permission_names', []))
    const roleName = _.get(me, ROLE_NAME_PATH, '')
    const isReadOnlyRole = roleName === USER_ROLES.READ_ONLY;
    return { permissions, isReadOnlyRole }
  }, [me])
}

export const PermissionsContext = createContext({} as IPermissions)
// @ts-expect-error
export const PermissionProvider: React.FC = ({ children }) => {
  const { permissions, isReadOnlyRole } = usePermissions()
  const permissionsDictionary = useMemo(
    () => ({
      isContactExportPermitted: permissions.has('Admin.Contact.Export'),
      isContactDeletePermitted: permissions.has('Admin.Contact.Delete'),
      isBulkContactsDeletePermitted: permissions.has('Admin.Contact.BulkDelete'),
      isBulkContactsExportPermitted: permissions.has('Admin.Contact.Export'),
      isContactEditPermitted: permissions.has('Admin.Contact.Edit'),
      isContactViewPermitted: permissions.has('Admin.Contact.View'),
      isContactCreatePermitted: permissions.has('Admin.Contact.Create'),
      isReviewCreatePermitted: permissions.has('Admin.ReviewManager.Create'),
      isReviewViewPermitted: permissions.has('Admin.ReviewManager.View'),
      isReviewEditPermitted: permissions.has('Admin.ReviewManager.Edit'),
      isReviewDeletePermitted: permissions.has('Admin.ReviewManager.Delete'),
      isManagePostPermitted: permissions.has('Admin.Post.Manage'),
      isManageGMBPermitted: permissions.has('Admin.GMB.Manage'),
      isMessengerSendPermitted: permissions.has('Admin.Messenger.Manage'),
      isContactVerificationPermitted: permissions.has('User.Contact.Verification'),
      isBusinessSettingsManagePermitted: permissions.has('Admin.BusinessSettings.Manage'),
      isPaymentPermitted: permissions.has("Admin.Payment.Manage"),
      isEngagmentCreatePermitted: permissions.has('Admin.EmailCampaign.Create'),
      isEngagmentViewPermitted: permissions.has('Admin.EmailCampaign.View'),
      isEngagmentEditPermitted: permissions.has('Admin.EmailCampaign.Edit'),
      isEngagmentDeletePermitted: permissions.has('Admin.EmailCampaign.Delete'),
      isBookingPermitted: permissions.has('Admin.Booking.Manage'),
      isSocialSyncPermitted:permissions.has('Admin.Enterprise.SocialSync'),
      isReadOnlyRole
    }),
    [permissions]
  )

  return <PermissionsContext.Provider value={permissionsDictionary}>{children}</PermissionsContext.Provider>
}

export const useIsPermitted = () => useContext(PermissionsContext)

type PermissionRenderFunc = (permissions: any) => JSX.Element

interface IPermissionProps {
  children: PermissionRenderFunc
}

export const Permission: FunctionComponent<IPermissionProps> = ({ children }) => {
  const permissions = useIsPermitted()

  return children(permissions)
}

export const withPermissions = (Component: any) => {
  return (props: any) => {
    const permissions = useIsPermitted()
    return <Component permissions={permissions} {...props} />
  }
}

export const PermissionConsumer: React.FC<IPermissionConsumer> = ({ children }) => {
  return (
    <PermissionsContext.Consumer>
      {context => {
        if (context === undefined) {
          throw new Error('PermissionConsumer must be used within a PermissionProvider')
        }
        return children(context)
      }}
    </PermissionsContext.Consumer>
  )
}

