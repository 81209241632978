import _ from 'lodash'
import React from 'react'
import { Redirect } from 'react-router-dom'
import asyncComponent from '@helpers/async-func'
import { UnRestrictedRoute, PublicRoute } from '@app/router/access-route'

export default [
  <UnRestrictedRoute
    exact
    key="/login"
    path={'/login'}
    layoutSettings={{ hideHeader: true, hideFooter: true }}
    component={asyncComponent(() => import('../auth/login'))}
  />,
  <UnRestrictedRoute
    exact
    key="/signup"
    path={'/signup'}
    layoutSettings={{ hideHeader: true, hideFooter: true }}
    component={asyncComponent(() => import('../auth/signup'))}
  />,
  <UnRestrictedRoute
    exact
    key="/forgot"
    path={'/forgot'}
    layoutSettings={{ hideHeader: true, hideFooter: true }}
    component={asyncComponent(() => import('../auth/forgot'))}
  />,

  <PublicRoute
    exact
    key="/404"
    path={'/404'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./notfound'))}
  />,
  <PublicRoute
    exact
    path={'/socialconnect'}
    key="/socialconnect"
    layoutSettings={{ page: '' }}
    component={asyncComponent(() => import('./utility/social-connect'))}
  />,
  <PublicRoute
  exact
  path={'/thumbtack/redirect'}
  key="/thumbtack/redirect"
  layoutSettings={{ page: '' }}
  component={asyncComponent(() => import('./utility/thumbtack-social-connect'))}
/>,
  <UnRestrictedRoute
    exact
    key="/magic-login"
    path={'/magic-login'}
    layoutSettings={{ hideHeader: true, hideFooter: true }}
    component={asyncComponent(() => import('../auth/magic-login'))}
  />,
  <UnRestrictedRoute
    exact
    key="/reset-password"
    path={'/reset-password'}
    layoutSettings={{ hideHeader: true, hideFooter: true }}
    component={asyncComponent(() => import('../auth/reset-password'))}
  />,
  <PublicRoute
    exact
    key="/view-email"
    path={'/view-email/:hashtoken'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./view-email'))}
  />,
  <PublicRoute
    exact
    key="/payment-feedback"
    path={'/payment-feedback/:id'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={RedirectToReviewFeedback}
    // component={asyncComponent(() => import('./feedbacks/payment-link'))}
  />,
  <PublicRoute
    exact
    key="/thank-you"
    path={'/thank-you/:pid/:cid'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./feedbacks/payment-thanks'))}
  />,
  <PublicRoute
    exact
    key="/verify-email"
    path={'/verify-email'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./verify-emails'))}
  />,
  <PublicRoute
    exact
    key="/review-feedback"
    path={'/review-feedback'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./review-feedback'))}
  />,
  <PublicRoute
    exact
    key="/shutterstock-guidelines"
    path={'/shutterstock-guidelines'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./shutterstock-guidelines'))}
  />,
  <PublicRoute
    exact
    key="/unauthorized"
    path={'/unauthorized'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./unauthorized'))}
  />
]

function RedirectToReviewFeedback ({ match }) {
  const id = _.get(match, 'params.id', '')
  if (id) {
    return <Redirect to={`/review-feedback?pid=${id}`} />
  }

  return asyncComponent(() => import('./feedbacks/payment-link'))
}
