import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="/business-settings"
    path={'/:userId/business-settings/:tabId?/:subPage?'}
    layoutSettings={{ page: 'business-settings' }}
    component={asyncComponent(() => import('../manage-my-business'))}
  />
]
