import React from 'react'
import { useSelector } from 'react-redux'
import BookingPaymentPopup from './booking-payment-popup'
import BookingPaymentStripePopup from './booking-payment-stripe-popup'

function BookingPaymentPopupContainer (props) {
  const paymentType = useSelector((state) => ((state.auth.me || {}).account_settings || {}).payment_type)
  return (paymentType === 'stripe')
    ? <BookingPaymentStripePopup
      open={props.open}
      module={props.module}
      me={props.me}
      isEdit={props.isEdit}
      payloadData={props.payloadData}
      submitText={props.submitText}
      submitData={props.submitData}
      closeModal={props.closeModal}
      predefinedTemplates={props.predefinedTemplates}
      cancelIncompleteBooking={props.cancelIncompleteBooking}
      onBookingEdit={props.onBookingEdit}
      loader={props.loader}
    /> : <BookingPaymentPopup
      open={props.open}
      module={props.module}
      me={props.me}
      isEdit={props.isEdit}
      payloadData={props.payloadData}
      submitText={props.submitText}
      submitData={props.submitData}
      closeModal={props.closeModal}
      predefinedTemplates={props.predefinedTemplates}
      cancelIncompleteBooking={props.cancelIncompleteBooking}
      onBookingEdit={props.onBookingEdit}
      loader={props.loader}
    />
}

export default BookingPaymentPopupContainer
