
import { convertHoursToArray } from "./utility";
import config from "@config/index";
import axios from "axios"

export function  generalTemplate(samplejson = {},businessSettings = {},recentReviews = [],accountsettings = {},updateBusiness = false) {
    let { locationName = "" } = businessSettings

    let businessPhotos = []
    if(businessSettings.photos && businessSettings.photos.length){
        if(businessSettings.photos.length > 5){
            businessPhotos = [...businessSettings.photos]
            businessPhotos = businessPhotos.splice(0,20).map(a => a.url)
        }
    }

    samplejson.pages.home.pageTitle = locationName
    if (!samplejson.account_settings) {
        samplejson.account_settings = {
            timezone: accountsettings.userTimeZone && accountsettings.userTimeZone.key ? accountsettings.userTimeZone.key : "",
            payment: {
                url: accountsettings.payment_url ? accountsettings.payment_url : ""
            },
            booking: {
                url: accountsettings.booking_url ? accountsettings.booking_url : ""
            }
        }
    }
    if(samplejson.business){
        samplejson.business.services = businessSettings.selectedServices ? [...businessSettings.selectedServices] : []
    }
    let paymentLink  = `${config.paymentSite}/${locationName.toLowerCase().replace(/[^a-z0-9]/g, "-")}/payment`
    if(samplejson.pages && samplejson.pages.home && samplejson.pages.home.meta )
        samplejson.pages.home.meta.name = locationName
    if(samplejson.pages.home.sections && samplejson.pages.home.sections.length){
        samplejson.pages.home.sections.forEach(item=> {
            if(item.sectionType === "header"){
                item.name =  locationName
                item.paymentLink = paymentLink
            }else if(item.sectionType === "hero"){
                if(updateBusiness){
                    item.title =  locationName;
                    item.subTitle =   businessSettings.tempprimaryCategories ? businessSettings.tempprimaryCategories.category_name : "";
                    item.coverPhoto = getBannerImages(businessSettings);
                }else{
                    item.title = item.title ? item.title : locationName;
                    item.subTitle =  item.subTitle ?  item.subTitle : businessSettings.primaryCategories ? businessSettings.primaryCategories.category_name : "";
                    item.coverPhoto = item.coverPhoto ? item.coverPhoto : getBannerImages(businessSettings);
                }
                
            }else if(item.sectionType === "services"){
                if(businessSettings.selectedServices)
                    item.services =  businessSettings.selectedServices
            }else if(item.sectionType === "about-us"){
                item.title = item.title ? item.title :"About Us"

                if(updateBusiness){
                    const category =   businessSettings.tempprimaryCategories ? ` ${businessSettings.tempprimaryCategories.category_name}` : '';
                    const city = businessSettings.city ? businessSettings.city : ""
                    item.description =  `We provide the best${category} services in ${city}. \nContact us for more info.`;
                }else{
                    item.description =  item.description ? item.description : ""
                }
            }else if(item.sectionType === "gallery"){
                    item.photos = !updateBusiness && item.photos && item.photos.length  ? item.photos :businessPhotos
            }else if(item.sectionType === "reviews"){
                item.title = "REVIEWS"
                item.subText = "Our customers love us!"
                if(recentReviews && recentReviews.length)
                    // item.customerReviews = generalReviews(recentReviews)
                    item.customerReviews = recentReviews
            }else if(item.sectionType === "business-info"){
                item.phone =  businessSettings.phone
                item.address =  businessSettings.address
                item.addressLine1 =  businessSettings.address
                item.addressLine2 =  `${businessSettings.city},${businessSettings.state}`
                item.weekdayOperatingHrs =  getBusinessHours(businessSettings.hours,"weekday")
                item.saturdayOperatingHrs =  getBusinessHours(businessSettings.hours,"saturday")
                item.sundayOperatingHrs = getBusinessHours(businessSettings.hours,"sunday")
            }
        })
        // if(updateBusiness)
        //     samplejson.business  = getBusinessDeatils(samplejson.business,businessSettings)

    }else{
        samplejson.pages.home.sections.push({
            sectionType: "header",
            name:locationName,
            paymentLink,
            transparentBackground: false
        })
        samplejson.pages.home.sections.push({
            sectionType: "hero",
            title: locationName,
            subTitle:businessSettings.primaryCategories ? businessSettings.primaryCategories.category_name : "",
            coverPhoto:getBannerImages(businessSettings)
        })
        if(businessSettings.selectedServices)
            samplejson.pages.home.sections.push({
                sectionType: "services",
                title: "SERVICES",
                subText: "Tell us what you're great at! Add an intro so customers can get an overview of you can do.",
                services: businessSettings.selectedServices
            })
        samplejson.pages.home.sections.push({
            sectionType: "about-us",
            title: "About Us",
            description:"Tell us something about your company! How did you get started? What are your values? Let customers know why you’re special."
        })

        // if(businessSettings.photos && businessSettings.photos.length)    
        samplejson.pages.home.sections.push({
            sectionType: "gallery",
            title: "OUR WORK",
            text: "We're here to help when you need it most",
            // photos: businessSettings.photos.map(a => a.url)
            photos:businessPhotos
        })
        if(recentReviews && recentReviews.length)
            samplejson.pages.home.sections.push({
                sectionType: "reviews",
                title: "REVIEWS",
                subText: "Our customers love us!",
                // customerReviews: generalReviews(recentReviews)
                customerReviews: recentReviews
            })
        samplejson.pages.home.sections.push({
            sectionType: "business-info",
            phone :  businessSettings.phone,
            address :  businessSettings.address,
            addressLine1 :  businessSettings.address,
            addressLine2 :  `${businessSettings.city},${businessSettings.state}`,
            weekdayOperatingHrs :  getBusinessHours(businessSettings.hours,"weekday"),
            saturdayOperatingHrs :  getBusinessHours(businessSettings.hours,"saturday"),
            sundayOperatingHrs : getBusinessHours(businessSettings.hours,"sunday")
        })
        samplejson.pages.home.sections.push({
            sectionType: "footer",
            facebook: null,
            google: null,
            instagram: null,
            twitter: null,
            youtube: null
        })
        samplejson.pages.home.sections.push({
            sectionType: "navigation-bar",
            services: true,
            payment: true,
            chat: true
        })
    }
    if(samplejson.business && businessSettings)
        samplejson.business.services = businessSettings.selectedServices
    
    if(recentReviews && recentReviews.length)
        // samplejson.reviews =  { recent : generalReviews(recentReviews)}
        samplejson.reviews =  recentReviews

    return samplejson
}
export function  updateTemplates(samplejson = {},recentReviews = []) {
    if(samplejson.pages.home && samplejson.pages.home.sections && samplejson.pages.home.sections.length){
        samplejson.pages.home.sections.forEach(item=> {
            if(item.sectionType === "reviews"){
                item.title = "REVIEWS"
                item.subText = "Our customers love us!"
                if(recentReviews && recentReviews.length)
                    // item.customerReviews = generalReviews(recentReviews)
                    item.customerReviews = recentReviews
            }    
        })
    }
    if(recentReviews && recentReviews.length)
        // samplejson.reviews =  { recent : generalReviews(recentReviews)}
        samplejson.reviews =  recentReviews
    return samplejson
}
export function  generalReviews(reviewData) {
    let formtedData = []
    reviewData.forEach((item,index)=> {
        let obj =  {
            id: index +1 ,
            author_name: item.authorName,
            author_photo: "",
            author_url: "",
            rating: item.rating,
            text: item.content,
            time: item.reviewDate
        }
        formtedData.push(obj)
    })
    return formtedData

}
export function  getBusinessHours(hours,type) {
    let formattedHours = convertHoursToArray(hours)
    let hour = ""
    if(type === "weekday" && formattedHours  && formattedHours.monday){
        if(formattedHours.monday.open && formattedHours.monday.slots && formattedHours.monday.slots.length){
            let slot = formattedHours.monday.slots[0]
            hour = `${slot.time_start} - ${slot.time_end}`
        }else
            hour = "Closed" 
    }else if(type === "saturday" && formattedHours  && formattedHours.saturday){ 
        if(formattedHours.saturday.open && formattedHours.saturday.slots && formattedHours.saturday.slots.length){
            let slot = formattedHours.saturday.slots[0]
            hour = `${slot.time_start} - ${slot.time_end}`
        }else
            hour = "Closed" 
    } else if(type === "sunday" && formattedHours  && formattedHours.sunday){  
        if(formattedHours.sunday.open && formattedHours.sunday.slots && formattedHours.sunday.slots.length){
            let slot = formattedHours.sunday.slots[0]
            hour = `${slot.time_start} - ${slot.time_end}`
        }else
            hour = "Closed" 
    }     
    return hour    
}

export function  getBusinessDeatils(business,businessSettings = {}) {
   
    business.additional_details = businessSettings.additionalHoursText
    business.categories =  {
        primary: businessSettings.primaryCategories ? businessSettings.primaryCategories.category_name : businessSettings.tempprimaryCategories ? businessSettings.tempprimaryCategories.category_name : "",
        secondary: businessSettings.secondaryCategories
    }   
    business.description =  businessSettings.description 
    business.emails = businessSettings.emails
    business.languages = businessSettings.languages
    business.name = businessSettings.locationName
    business.payment_options = businessSettings.paymentOptions
    business.products = businessSettings.products
    business.services = businessSettings.services.map(a => a.service_name);
    business.social_media =  businessSettings.social_media
    business.website =  {
        display: businessSettings.displayWebsiteUrl,
        url: businessSettings.websiteUrl
    }
    business.year_established =  businessSettings.establishedYear
    business.hours = getHours(businessSettings.hours)
    business.location = getLocationData(businessSettings)

    const phoneNumbersData = getPhoneData(businessSettings);
    if (phoneNumbersData) business.phone_numbers = phoneNumbersData;

    console.log('getBusinessDeatils',business)
    return business
}

export function  getBannerImages(businessSettings){
    let image = "https://d1hz0qcu1muexe.cloudfront.net/upload/5a31787c7e8f9d4c1d4038f4/ff0ec4cc27b26f85cc8dc9d7851bd0edcroppedimage.jpg"
    if(businessSettings.photos && businessSettings.photos.length)
        image = businessSettings.photos[0].url
    return image
}

export async function getColorPaletts(){
    let colors = await axios(config.colorPaletteUrl).then((response) => response.data);
    return colors
}

/*  --------------------------------------------  business data formatting helpers -------------- ---------- */

/**
 * Creates a container for business hours in machine- and human-readable format.
 *
 * @param {object} location - Location model from database.
 * @returns {object|null} An object with available and display business hours.
 */
const getHours = (businesshours) => {
    const hours = {
        is_public: true
    };
    const regular = convertAvailableHours(businesshours);
    if (regular) {
        hours.regular = regular;
        hours.display = formatDisplayHours(regular);
    }
    
    return hours;
};

/**
 * Converts hours stored in database into JSON format.
 *
 * @param {string} hours - Hours from location model, for example: "2:9:00:17:00,3:9:00:17:00,4:9:00:17:00,5:9:00:17:00,6:9:00:17:00,7:CLOSED,1:CLOSED".
 * @returns {array|null} An array of business hour objects.
 */
export function convertAvailableHours (hours) {
    if (!hours) return null;
    const output = [];
    if (typeof hours === 'string') {
        const rePeriod = /(\d):(\d{1,2}:\d{2}):(\d{1,2}:\d{2})/;
        const splitHours = hours.split(',');
        for (const period of splitHours) {
            const times = period.match(rePeriod);
            if (times) {
                const period = {
                    open: {
                        day: parseInt(times[1]) - 1,
                        time: times[2].replace(':', '').padStart(4, '0'),
                    },
                };

                // do not add close if 24 hours
                if (times[2] !== '00:00' || times[3] !== '00:00') {
                    period.close = {
                        day: parseInt(times[1]) - 1,
                        time: times[3].replace(':', '').padStart(4, '0'),
                    };
                }

                output.push(period);
            }
        }
    }
    return output.length > 0 ? output.sort((a, b) => (a.open.day > b.open.day ? 1 : -1)) : null;
};

/**
 * Formats hours into more easily human-readable format.
 *
 * @param {array} hours - A list of hour objects.
 * @param {string} weekdayFormat - 'long' to return weekday names in full, 'short' to display weekdays names in 3-letter format.
 * @returns {array|null} A list of human-readable hours.
 */
 const formatDisplayHours = (hours, weekdayFormat = 'long') => {
    if (!hours || !Array.isArray(hours) || hours.length === 0) return null;
    const days = {
        0: { short: 'Sun', long: 'Sunday' },
        1: { short: 'Mon', long: 'Monday' },
        2: { short: 'Tue', long: 'Tuesday' },
        3: { short: 'Wed', long: 'Wednesday' },
        4: { short: 'Thu', long: 'Thursday' },
        5: { short: 'Fri', long: 'Friday' },
        6: { short: 'Sat', long: 'Saturday' },
    };

    // copy hours and sort with Sunday last
    const hoursCopy = [...hours];
    hoursCopy.sort((a, b) => (a.open.day === 0 ? 1 : a.open.day > b.open.day ? 1 : -1));

    // create an array for output
    const displayHours = [];
    for (const period of hoursCopy) {
        displayHours.push(
            period.open.time === '0000' && !period.close
                ? `${days[period.open.day][weekdayFormat]} 24 hours`
                : `${days[period.open.day][weekdayFormat]} ${formatTime(period.open.time)} - ${formatTime(
                      period.close.time
                  )}`
        );
    }
    return displayHours;
};

/**
 * Formats times from 24 hours (2130) to 12 hours (9:30pm).
 *
 * @param {string} time - Time in 24 hour format (2130).
 * @returns {string|null} Time in 12 hour format (9:30pm).
 */
 const formatTime = (time) => {
    if (!time) return null;
    time = time.toString().match(/^([01]\d|2[0-3])(:)?([0-5]\d)(:[0-5]\d)?$/) || [time];
    if (time.length > 1) {
        time = time.slice(1);
        time[5] = +time[0] < 12 ? 'am' : 'pm';
        time[0] = +time[0] % 12 || 12;
        time[1] = ':';
    }
    return time.join('');
};



/**
 * Creates a container for location information, such as address and geo-coordinates.
 *
 * @param {object} location - Location model from database.
 * @returns {object|null} Location data.
 */
 const getLocationData = (location) => {
    if (!location) return null;
    const output = {};
    if (location.suppressAddress !== undefined) output.address_is_public = !location.suppressAddress;
    else output.address_is_public = false;
    if (location.door_delivery !== undefined) output.service_at_customer_location = location.door_delivery;
    const addressData = getAddressData(location);
    if (addressData) output.address_components = addressData;
    const geoData = getGeoData(location);
    if (geoData) output.geo = geoData;
    output.display = formatDisplayAddress(output.address_components);
    return output;
};

/**
 * Creates a geo-coordinate object from Location model.
 *
 * @param {object} location - Location model from database.
 * @returns {object|null} Geo-coordinate (lat/lng) information.
 */
const getGeoData = (location) => {
    if (!location || !location.displayLat || !location.displayLng) return null;
    return {
        lat: location.displayLat,
        lng: location.displayLng,
    };
};

/**
 * Creates a location (address) object from a Location model.
 *
 * @param {object} location - Location model from database.
 * @returns {object|null} Location (address) information.
 */
const getAddressData = (location) => {
    if (!location) return null;
    const output = {};
    if (location.address) output.address1 = location.address;
    if (location.address2) output.address2 = location.address2;
    if (location.city) output.city = location.city;
    if (location.countryCode) output.country = location.countryCode;
    if (location.zip) output.postal_code = location.zip;
    if (location.state) output.region = location.state;
    return output;
};

/**
 * Formats a collection of address components into a human-readable list.
 *
 * @param {object} components - A container for address components.
 * @param {integer} address2Threshold - Length of address2 when it becomes its own line.
 * @returns {array|null} A list of human-readable address lines.
 */
const formatDisplayAddress = (components, address2Threshold = 8) => {
    if (!components) return null;
    const output = [];
    if (components.address1) {
        output.push(
            components.address1 +
                (components.address2 && components.address2.length < address2Threshold
                    ? ', ' + components.address2
                    : '')
        );
    }
    if (components.address2 && components.address2.length >= address2Threshold) output.push(components.address2);
    if (components.city || components.region || components.postal_code) {
        const cityLine = [];
        if (components.city) cityLine.push(components.city);
        if (components.city && components.region) cityLine.push(', ');
        if (components.region) cityLine.push(components.region);
        if (components.region && components.postal_code) cityLine.push(' ');
        if (components.postal_code) cityLine.push(components.postal_code);
        output.push(cityLine.join(''));
    }
    return output;
};


const getPhoneData = (location) => {
    if (!location || (!location.phone && !location.alternatePhone && !location.tollFreePhone)) return null;
    const numbers = {};
    if (location.phone) {
        numbers.phone1 = {
            international: convertPhone(location.phone),
            display: formatDisplayPhone(convertPhone(location.phone)),
        };
    }
    if (location.alternatePhone) {
        numbers.phone2 = {
            international: convertPhone(location.alternatePhone),
            display: formatDisplayPhone(convertPhone(location.alternatePhone)),
        };
    }
    if (location.tollFreePhone) {
        numbers.phone3 = {
            international: convertPhone(location.tollFreePhone),
            display: formatDisplayPhone(convertPhone(location.tollFreePhone)),
        };
    }
    return numbers;
};

/**
 * Converts phone number to US international with no formatting.
 *
 * @param {string} phone - Phone number.
 * @returns {string|null} International US phone number.
 */
 const convertPhone = (phone) => {
    if (!phone || !typeof phone === 'string') return null;
    const digitsPhone = phone.replace(/[^0-9]/g, '');
    return digitsPhone.length === 10 && !digitsPhone.startsWith('+1') ? '+1' + digitsPhone : digitsPhone;
};

/**
 * Formats a US international phone number to (XXX) XXX-XXXX
 *
 * @param {string} phone - Phone number,
 * @returns {string|null} Formatted phone number.
 */
const formatDisplayPhone = (phone) => {
    if (!phone || !typeof phone === 'string') return null;
    const rePhone = /\+1(\d{3})(\d{3})(\d{4})/;
    const phoneMatch = phone.match(rePhone);
    if (phoneMatch) {
        return `(${phoneMatch[1]}) ${phoneMatch[2]}-${phoneMatch[3]}`;
    }
    return phone;
};
/*  --------------------------------------------  business data formatting helpers -------------- ---------- */
