import React from 'react'
import PropTypes from 'prop-types'
import { addMethod, object } from 'yup'
import { CardNumberElement as StripeCardNumberElement } from '@stripe/react-stripe-js'

import { ElementState } from './ElementState'

const defaultStyle = {
  fontSize: '14px',
  paddingTop: '0.75rem',
  fontWeight: 400
}

const CardNumberElement = ({ form, field, className, ...props }) => {
  const { name, value } = field
  const { onBlur, onChange, setFieldValue, setTouched } = form

  const handleBlur = () => {
    onBlur && onBlur()
    setTouched({ ...form.touched, [name]: true })
    if (value.empty) setFieldValue(name, new ElementState())
  }

  const handleChange = (event) => {
    onChange && onChange(event)
    setFieldValue(name, new ElementState(event))
  }

  return (
    <StripeCardNumberElement
      {...props}
      options={{
        classes: {
          base: className
        },
        style: {
          base: defaultStyle
        }
      }}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

CardNumberElement.propTypes = {
  className: PropTypes.string,
  ...StripeCardNumberElement.propTypes
}

export { CardNumberElement }

function cardNumberElement (messages = {}) {
  return this.test({
    name: 'card-number-element',
    test: function (value) {
      if (!(value instanceof ElementState)) throw new Error('Object must be an instance of `ElementState`.')

      if (value.empty) {
        return this.createError({
          path: this.path,
          message: messages.empty || `Required`
        })
      }

      if (value.error === 'invalid_number') {
        return this.createError({
          path: this.path,
          message: messages.invalid || `Invalid`
        })
      }

      if (value.error === 'incomplete_number') {
        return this.createError({
          path: this.path,
          message: messages.incomplete || `Incomplete`
        })
      }

      return true
    }
  })
}

addMethod(object, 'cardNumberElement', cardNumberElement)
