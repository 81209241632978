import React from "react";
import asyncComponent from "@helpers/async-func"
import {EnterpriseRoute} from "@app/router/access-route"

export default [
    <EnterpriseRoute
        exact
        path={"/:userId/widgets/messenger"}
        key="multimessenger"
        layoutSettings={{page: "multimessenger",title:"Multi Messenger"}}
        component={asyncComponent(() => import("./multimessenger/list"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/widgets/messenger/create"}
        key="multimessenger"
        layoutSettings={{page: "multimessenger",title:"Multi Messenger",siteTitle:"Create General Messenger Widgets"}}
        component={asyncComponent(() => import("./multimessenger/edit"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/widgets/messenger/edit/:id"}
        key="multimessenger"
        layoutSettings={{page: "multimessenger",title:"Multi Messenger",siteTitle:"Edit General Messenger Widgets"}}
        component={asyncComponent(() => import("./multimessenger/edit"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/enterprise/widgets/settings"}
        key="multimessenger"
        layoutSettings={{page: "widgets",title:"Widgets",siteTitle:"Widgets Settings"}}
        component={asyncComponent(() => import("./widgets"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/enterprise/widgets/widget"}
        key="multiwidget"
        layoutSettings={{page: "multiwidget",title:"Multi Reviews",siteTitle:"Multi Widget"}}
        component={asyncComponent(() => import("./multiwidget/list"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/widgets/createwidget"}
        key="createwidget"
        layoutSettings={{page: "multiwidget",title:"Create Widget",siteTitle:"Create Review Widget"}}
        component={asyncComponent(() => import("./multiwidget/edit"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/widgets/editwidget/:widgetId"}
        key="editwidget"
        layoutSettings={{page: "multiwidget",title:"createwidget",siteTitle:"Edit Review Widget"}}
        component={asyncComponent(() => import("./multiwidget/edit"))}
    />,
   
]
