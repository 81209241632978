import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import * as service from './service'
import i18n from '@/i18n'

export default {
  state: {
    apiError: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onGetDefaultThemesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        defaultThemes: data
      }
    },
    onResetThemeSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onSaveThemeSuccess(state, data, msg) {
      if (msg) notification('success', msg)
      return {
        ...state,
        loading: false
      }
    },
    onBrandingReupdate(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onSaveBrandingSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    }
  },
  effects: {
    async getDefaultThemes(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getDefaultThemes(payload)
        this.onGetDefaultThemesSuccess(res)
        return res
      } catch (err) {
        this.onRequest(err)
      }
    },
    async resetTheme(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.resetTheme(payload)
        this.onResetThemeSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async saveTheme(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.saveTheme(payload)
        this.onSaveThemeSuccess(res, payload.generalSettings ? i18n.t('settings.settings-updated-successfully') : null)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async brandingReupdate(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.brandingReupdate(payload)
        this.onBrandingReupdate(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async saveBranding(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.saveBranding(payload)
        this.onSaveBrandingSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    }
  }
}
