import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="/messenger"
    path={'/:userId/messenger'}
    layoutSettings={{ page: 'messenger' }}
    component={asyncComponent(() => import('./index'))}
  />,
  <RestrictedRoute
    exact
    key="/messenger/trail"
    path={'/:userId/trial/messenger'}
    layoutSettings={{ page: 'messenger' }}
    component={asyncComponent(() => import('./preview/preview'))}
  />
]
