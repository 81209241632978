import React from 'react'
import { RestrictedRoute } from '@app/router/access-route'
import asyncComponent from '@helpers/async-func'

export default [
  <RestrictedRoute
    exact
    path="/:userId/estimates"
    key="estimates"
    layoutSettings={{ page: 'estimates' }}
    component={asyncComponent(() => import('./Estimates'))}
  />,
  <RestrictedRoute
    exact
    path="/:userId/estimates/:estimateId"
    key="estimates"
    layoutSettings={{ page: 'estimates' }}
    component={asyncComponent(() => import('./EstimateDetails'))}
  />
]
