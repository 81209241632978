import React, { useEffect } from "react";

const Tooltip = (props) => {
    useEffect(() => {
        try{
            if (window.$) {
                if (window.innerWidth >= 992) {
                    window.$("[data-toggle=\"tooltip\"]").on("click", function () {
                        window.$(this).tooltip("hide")
                    })
                }
                else {
                    if (props.type === "button") {
                        window.$("[data-toggle=\"tooltip\"]").on("click", function () {
                            window.$(this).tooltip("hide")
                        })
                    } else {
                        window.$("[data-toggle=\"tooltip\"]").on("click", function () {
                            window.$(this).tooltip("toggle")
                        })
                    }
                }
                window.$("[data-toggle=\"tooltip\"]").tooltip({ trigger: "hover" });
            }
        }catch(e){
            console.log(e)
        }
    }, []);
    return (
        <React.Fragment>
            {props.children ?
                (<div className={`d-inline-block btn-icon ${props.fullWidth ? "w-100" : ""}`} data-toggle="tooltip" data-placement={props.position} title={props.message} id={props.id}>
                    {props.children}
                </div>
                )
                : (
                    <i className={"d-inline-block btn-icon btn-icon--children"} data-toggle="tooltip" data-placement={props.position} title={props.message} id={props.id}>
                        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024" className="tooltip-icon-info" >
                            <title></title>
                            <g>
                            </g>
                            <path fill="" d="M432.64 58.88c-50.176 8.704-103.936 28.672-150.016 54.784-54.272 30.208-138.752 114.688-168.96 168.96-82.432 146.432-82.432 317.44 0 463.872 30.208 54.272 114.688 138.752 168.96 168.96 146.432 82.432 317.44 82.432 463.872 0 54.272-30.208 138.752-114.688 168.96-168.96 82.432-146.432 82.432-317.44 0-463.872-30.208-54.272-114.688-138.752-168.96-168.96-95.744-54.272-209.408-74.24-313.856-54.784zM618.496 143.36c130.048 36.352 230.4 137.728 268.288 269.824 15.872 56.32 15.872 146.432 0 202.752-37.888 133.12-137.728 232.96-270.848 270.848-56.32 15.872-146.432 15.872-202.752 0-133.12-37.888-232.96-137.728-270.848-270.848-15.872-56.32-15.872-146.432 0-202.752 41.984-147.968 158.208-251.904 312.32-280.064 37.376-7.168 121.856-1.536 163.84 10.24z"></path>
                            <path fill="" d="M493.056 289.28c-32.256 13.824-33.28 30.208-12.8 186.368 12.288 92.16 14.848 104.96 24.576 111.616 9.216 6.144 12.288 5.632 21.504-2.048 8.704-8.192 12.288-24.576 23.552-119.296 18.944-152.576 17.92-160.768-12.288-176.64-17.92-9.216-23.552-9.216-44.544 0z"></path>
                            <path fill="" d="M480.768 675.328c-19.456 19.968-19.968 40.96 0 62.976 11.264 12.8 18.432 16.384 33.792 16.384s22.528-3.584 33.792-16.384c19.968-22.016 19.456-43.008 0-62.976-20.992-20.48-46.592-20.48-67.584 0z"></path>
                        </svg>
                    </i>
                )}
        </React.Fragment>
    )
}
export default Tooltip;