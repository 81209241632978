import * as React from 'react'
import { useState } from 'react'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

interface PreviewButtonProps {
  id: string
  className: string
  onClick: React.MouseEventHandler<HTMLButtonElement>
  children: React.ReactNode
}
const PreviewButton = ({ id, className, onClick, children }: PreviewButtonProps) => {
  return (
    <button type="button" id={id} className={`btn manageBtn ripple-animate ${className || ''}`} onClick={onClick}>
      {children}
    </button>
  )
}

interface PreviewMessageProps {
  message: string
}
export const PreviewMessage = ({ message }: PreviewMessageProps) => {
  const { t } = useTranslation()
  const [showPreview, setShowPreview] = useState<boolean>(false)
  return (
    <div className={`preview-msg ${styles['review-preview-msg']}`}>
      {!showPreview ? (
        <PreviewButton
          id="btnReview_preview"
          className={`${styles['review-preview-btn']}`}
          onClick={() => setShowPreview(!showPreview)}>
          {t('reviews.preview-message')}
        </PreviewButton>
      ) : (
        <div>
          <div
            className={`message-block ${styles['review-msg-block']}`}
            dangerouslySetInnerHTML={{ __html: message }}
          />
          <PreviewButton
            id="btnReview_hide"
            onClick={() => setShowPreview(!showPreview)}
            className={`${styles['review-preview-btn']}`}>
            {t('reviews.hide-message')}
          </PreviewButton>
        </div>
      )}
    </div>
  )
}
