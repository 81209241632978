import * as React from 'react';
import Icon from '../icons';
import styles from './index.module.scss';

export interface TextInstructionProps {
  strongMessage: string;
  message: string;
  className?: string;
}

export const TextInstruction = ({ strongMessage, message, className = '' }: TextInstructionProps) => {
  console.log(styles);
  return (
    <div className={`${styles['first-time-tip']} ${className}`}>
      <div>
        <Icon name="tipPointer" className="stroke-icon d-block" />
      </div>
      <div>
        <strong>{strongMessage}</strong>
        {message}
      </div>
    </div>
  );
};
