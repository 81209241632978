import React, { useMemo, useState, useRef } from 'react'
import Popup from '../popups/basic-modal'
import * as Yup from 'yup'
import Icon from '@components/icons'
import DatePicker from 'react-datepicker'
import moment from 'moment'
import _ from 'lodash'
import Tooltip from '@components/tooltip'

import { phoneNumberFormat, formatUSDFromCent } from '@helpers/utility'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Box, ThemeProvider, theme } from '@gositeinc/ui'
import { ContactSelector } from '@components/ContactSelector'
import 'react-datepicker/dist/react-datepicker.css'
import AsyncCreatableSelect from 'react-select/async-creatable'
import style from './edit-estimate-popup.module.scss'
import { Line } from 'rc-progress'
import notification from '@components/notification'
import { useTranslation } from 'react-i18next'

const customOptionsService = ({ value, label, subLabel, index }, { context }) => {
  if (context === 'value') {
    if (label && !label.props) {
      return (
        <div key={index} className="d-flex flex-grow-0">
          <div className="mr-10"></div>
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div className="text-truncate max-170">
              <strong>{label || subLabel}</strong>
            </div>
            <Icon name="arrowDownThin" className="ml-4 mt-4" />
          </div>
        </div>
      )
    }
  }
  if (context === 'menu') {
    return (
      <React.Fragment>
        <div data-test="pick-invoice-service-item" id="contactOptionValue11" className="d-flex flex-grow-0">
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div>{label || subLabel}</div>
            <div>{label && subLabel ? `$${subLabel}` : ''}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export const EditEstimatePopup = ({
  open,
  title,
  confirmButtonText,
  onConfirm,
  onCancel,
  defaultEstimate,
  uploadingAttachments,
  uploadedAttachments,
  onCreateNewContact = () => Promise.resolve(),
  onSearchContact = () => Promise.resolve(),
  onSearchService = () => Promise.resolve(),
  onCancelUploadingEstimateAttachment = () => Promise.resolve(),
  onClearUploadingEstimateAttachments = () => Promise.resolve(),
  onClearUploadedEstimateAttachments = () => Promise.resolve(),
  onRemoveUploadedEstimateAttachment = () => Promise.resolve(),
  uploadEstimateAttachments = () => Promise.resolve(),
  onSetUploadedEstimateAttachments = () => Promise.resolve(),
  smsEnabled = true
}) => {
  const { t } = useTranslation()
  const [showSendViaDropdown, setShowSendViaDropDown] = useState(false)
  const [showSendScheduleDropDown, setShowSendScheduleDropDown] = useState(false)
  const [showSendScheduleDatePickerDropDown, setShowSendScheduleDatePickerDropDown] = useState(false)
  const [showValidThroughDatePickerDropDown, setShowValidThroughDatePickerDropDown] = useState(false)
  const [dragActive, setDragActive] = useState(false)
  const [editDescription, setEditDescription] = useState(false)
  const [currentIndex, setCurrentIndex] = useState(null)

  const inputRef = useRef(null)
  const fileMaxCount = 5

  console.log(uploadingAttachments, uploadedAttachments)

  const handleCancel = function () {
    onClearUploadingEstimateAttachments()
    onClearUploadedEstimateAttachments()
  }

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true)
    } else if (e.type === 'dragleave') {
      setDragActive(false)
    }
  }

  function formatBytes(inputBytes, decimalPlaces = 2) {
    if (!+inputBytes) return '0 Bytes'
    const numOfDecimalPlaces = decimalPlaces < 0 ? 0 : decimalPlaces
    const numOfBytes = Math.floor(Math.log(inputBytes) / Math.log(1024))
    return `${parseFloat((inputBytes / Math.pow(1024, numOfBytes)).toFixed(numOfDecimalPlaces))} ${
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][numOfBytes]
    }`
  }

  function formatDate(timeStamp) {
    return moment(timeStamp).format('D MMM, YYYY')
  }

  function cancelFileUpload(fileName) {
    onCancelUploadingEstimateAttachment(fileName)
  }

  function deleteFile(fileName) {
    onRemoveUploadedEstimateAttachment(fileName)
  }

  const editDescriptionBtnClick = (item, index) => {
    console.log('item ======', item)
    console.log('index ======', index)
    console.log('editDescription ======', editDescription)
    if (!item.service_description && editDescription && currentIndex === index) {
      setEditDescription(false)
    } else {
      setCurrentIndex(index)
      setEditDescription(true)
    }
    window.$('#serviceDescription_' + index).focus()
  }

  const handleDrop = async function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    const files = Array.from(e.dataTransfer.files || [])
    uploadFiles(files)
  }

  const handleChange = async function (e) {
    e.preventDefault()
    const files = Array.from(e.target.files || [])
    uploadFiles(files)
  }

  const removeSpecialChars = (files) => {
    return files.map((file) => renameFile(file, file.name.replace(/[^a-zA-Z0-9._ ]/g, '')))
  }

  const renameFile = (file, fileName) => new File([file], fileName, { type: file.type })

  const uploadFiles = async (filesProvided) => {
    const files = removeSpecialChars(filesProvided)
    if (files.length + uploadingAttachments.length + uploadedAttachments.length > fileMaxCount) {
      notification('error', t('estimates.maximum-5-files-are-allowed'))
      return
    }
    const dedupedFiles = files.filter(
      (file) =>
        !uploadingAttachments.some((uploadingFile) => uploadingFile.name === file.name) &&
        !uploadedAttachments.some((uploadedFile) => uploadedFile.name === file.name)
    )
    if (files.length !== dedupedFiles.length) {
      notification('error', t('estimates.duplicate-files-are-not-allowed'))
    }
    uploadEstimateAttachments({
      files: dedupedFiles
    })
  }

  const onButtonClick = () => {
    inputRef.current.click()
  }

  const resetDropdowns = () => {
    setShowSendViaDropDown(false)
    setShowSendScheduleDropDown(false)
    setShowSendScheduleDatePickerDropDown(false)
    setShowValidThroughDatePickerDropDown(false)
  }

  const schema = Yup.object()
    .shape({
      contact: Yup.object()
        .shape({
          name: Yup.string(),
          email: Yup.string().email(t('estimates.please-enter-valid-email')),
          phone: Yup.string().matches(
            /^$|^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/,
            t('estimates.only-us-phone-numbers-without-country-code-are-permitted-eg-500-555-0006')
          ),
          id: Yup.string()
        })
        .required(t('estimates.required')),
      sendSMS: Yup.boolean().required(),
      sendEmail: Yup.boolean().required(),
      sendSchedule: Yup.string().required(),
      sendScheduleDate: Yup.date().required(),
      validThroughDate: Yup.date(),
      items: Yup.array()
        .of(
          Yup.object().shape({
            name: Yup.string().min(1).required(),
            quantity: Yup.number().min(1).required(),
            rate: Yup.number().min(0.01).required(),
            new: Yup.boolean().optional(),
            serviceId: Yup.string().optional()
          })
        )
        .min(1, t('estimates.required'))
        .required(t('estimates.required'))
    })
    .test(
      'sendVia',
      smsEnabled
        ? t('estimates.please-select-email-or-sms-checkbox')
        : t('estimates.sms-service-is-not-enabled-in-your-account-try-with-an-email-instead'),
      function (value) {
        console.log('test', 'sendVia', value)
        return value.sendSMS || value.sendEmail
      }
    )

  const initialValues = useMemo(() => {
    const values = {
      contact: null,
      sendSMS: false,
      sendEmail: false,
      sendSchedule: 'Immediately',
      sendScheduleDate: moment().add(1, 'days').toDate(),
      validThroughDate: moment().add(1, 'week').toDate(),
      validThroughOptions: [
        { validThrough: '1 Week', validThroughDate: moment().add(1, 'week').toDate() },
        { validThrough: '2 Weeks', validThroughDate: moment().add(2, 'week').toDate() },
        { validThrough: '30 Days', validThroughDate: moment().add(30, 'day').toDate() },
        { validThrough: 'End of month', validThroughDate: moment().endOf('month').toDate() }
      ],
      items: []
    }

    if (defaultEstimate) {
      console.log('defaultEstimate', defaultEstimate)
      const { contact = null, items, expiredAt, sendVia = 'both', sendTime = 0, attachments = [] } = defaultEstimate

      onSetUploadedEstimateAttachments(attachments)

      return {
        ...values,
        ...(expiredAt != null ? { validThroughDate: moment(expiredAt).toDate() } : {}),
        ...(items != null
          ? {
              items: items.map((item) => ({
                ...item,
                rate: item.rate / 100
              }))
            }
          : {}),
        ...(contact != null
          ? {
              contact: {
                ...contact,
                ...(contact && contact.phone ? { phone: phoneNumberFormat(contact.phone) } : {})
              }
            }
          : {}),
        sendSMS: Boolean(_.get(contact, 'phone')) && smsEnabled && new Set(['both', 'sms']).has(sendVia),
        sendEmail: Boolean(_.get(contact, 'email')) && new Set(['both', 'email']).has(sendVia),
        sendSchedule: sendTime === 0 ? 'Immediately' : 'Scheduled',
        sendScheduleDate: sendTime === 0 ? moment().add(1, 'days').toDate() : moment(sendTime).toDate()
      }
    }
    return values
  }, [defaultEstimate, smsEnabled])

  return (
    <ThemeProvider theme={theme}>
      <Popup
        open={open}
        title={title}
        type="medium"
        overrideForm={true}
        id={'EditEstimatePopup'}
        modalBackdropClicked={() => onCancel()}>
        <Formik
          validateOnChange={false}
          validateOnBlur={true}
          enableReinitialize={true}
          initialValues={initialValues}
          isInitialValid={true}
          validationSchema={schema}
          onReset={() => {}}
          onSubmit={async (
            { contact, sendSMS, sendEmail, sendSchedule, sendScheduleDate, validThroughDate, items },
            { setSubmitting, resetForm }
          ) => {
            const removeTimeAndToMillisecond = (date) => {
              return moment(date).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).valueOf()
            }

            await onConfirm({
              contact: {
                ...contact,
                phone: contact.phone || undefined,
                email: contact.email || undefined
              },
              attachments: uploadedAttachments,
              sendTime: sendSchedule === 'Immediately' ? 0 : removeTimeAndToMillisecond(sendScheduleDate),
              sendVia: sendSMS && sendEmail ? 'both' : (sendEmail && 'email') || (sendSMS && 'sms'),
              expiredAt: removeTimeAndToMillisecond(validThroughDate),
              items: items.map((item) => ({
                name: item.name,
                rate: parseInt(parseFloat(item.rate, 10) * 100, 10), // forcefully remove decimal points
                quantity: parseInt(item.quantity),
                service_description: item.service_description,
                new: item.new,
                serviceId: item.serviceId
              }))
            })

            setSubmitting(false)
            resetForm()
            resetDropdowns()
            handleCancel()
          }}
          render={({ values, setFieldValue, isValid, isSubmitting, resetForm, errors, handleBlur, validateForm }) => {
            const {
              contact,
              sendSMS,
              sendEmail,
              sendSchedule,
              sendScheduleDate,
              validThroughDate,
              validThroughOptions,
              items
            } = values

            const selectedOption = validThroughOptions.find((option) =>
              moment(option.validThroughDate).isSame(validThroughDate, 'day')
            )
            const validThroughDisplayValue = selectedOption
              ? selectedOption.validThrough
              : moment(validThroughDate).format('L')

            const total = items.reduce((prev, curr) => prev + curr.rate * curr.quantity * 100, 0)
            const totalText = formatUSDFromCent(total)

            const revalidate = () => {
              setTimeout(() => validateForm(), 10)
            }

            const setContact = (newContact) => {
              console.log(newContact)
              if (!newContact) {
                setFieldValue('contact', null)
                setFieldValue('sendSMS', false)
                setFieldValue('sendEmail', false)
                revalidate()
                return
              }

              setFieldValue('contact', newContact)
              setFieldValue('sendSMS', false)
              setFieldValue('sendEmail', false)

              const shouldDisableSMS = !_.get(newContact, 'phone') || !smsEnabled
              const shouldDisableEmail = !_.get(newContact, 'email')

              if (newContact.phone && !shouldDisableSMS) {
                setFieldValue('sendSMS', true)
              }

              if (newContact.email && !shouldDisableEmail) {
                setFieldValue('sendEmail', true)
              }
              revalidate()
            }

            return (
              <Form className={`pt-20 custom-get-paid-form`}>
                <button
                  type="reset"
                  className="close custom-modal-close-button remove-modal-close"
                  onClick={() => {
                    resetForm()
                    resetDropdowns()
                    onCancel()
                    handleCancel()
                  }}>
                  <span aria-hidden="true">{'*'}</span>
                </button>
                <div className="row">
                  <div className="col-12 d-none">values: {JSON.stringify(values)}</div>
                  <div className="col-12 d-none">errors: {JSON.stringify(errors)}</div>
                  <div className="col-12 d-none">isValid: {JSON.stringify(isValid)}</div>
                  <div className="col-12 d-none">isSubmitting: {JSON.stringify(isSubmitting)}</div>
                  <div className="col-12">
                    <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                      <label className="label-text">
                        {t('estimates.bill-to')} <span className="star">*</span>
                        {typeof errors.contact === 'string' && (
                          <span className="form-error">{t('estimates.required')}</span>
                        )}
                        <ErrorMessage name="contact.name" component="span" className="form-error" />
                        <ErrorMessage name="contact.email" component="span" className="form-error" />
                        <ErrorMessage name="contact.phone" component="span" className="form-error" />
                      </label>
                      <ContactSelector
                        name="contact"
                        emailPlaceHolder={sendEmail ? t('estimates.enter-email') : t('estimates.optional')}
                        phonePlaceHolder={sendSMS ? t('estimates.enter-mobile') : t('estimates.optional')}
                        value={contact}
                        onChange={setContact}
                        onCreateNewContact={onCreateNewContact}
                        loadOptions={async (searchText) => {
                          const contacts = await onSearchContact(searchText)
                          if (contacts.length <= 0) {
                            return []
                          }

                          return [
                            {
                              options: contacts.map(
                                ({
                                  first_name: firstName = '',
                                  last_name: lastName = '',
                                  email,
                                  mobile,
                                  image,
                                  contact_id
                                }) => {
                                  return {
                                    label: `${firstName} ${lastName}`.trim(),
                                    subLabel: mobile ? phoneNumberFormat(mobile) : email,
                                    value: {
                                      name: `${firstName} ${lastName}`.trim(),
                                      email,
                                      phone: mobile,
                                      id: contact_id
                                    },
                                    type: 'contact',
                                    image
                                  }
                                }
                              )
                            }
                          ]
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                      <label className="label-text">
                        {t('estimates.estimate-details-0')}
                        {/* top level errors are undefined */}
                        {typeof errors.undefined === 'string' && <span className="form-error">{errors.undefined}</span>}
                      </label>
                      <div className="invoice-detail border rounded-sm">
                        <div className="row-send-via border-bottom d-flex align-items-center">
                          <div className="send-via px-15">{t('estimates.send-via')}</div>
                          <div className="send-via-value position-relative">
                            <div
                              className="send-via-value-item px-15 cursor-pointer d-flex align-items-center justify-content-between "
                              onClick={() => {
                                setShowSendScheduleDatePickerDropDown(false)
                                setShowSendScheduleDropDown(false)
                                setShowValidThroughDatePickerDropDown(false)
                                setShowSendViaDropDown(!showSendViaDropdown)
                              }}>
                              <span>
                                {[sendEmail && 'Email', sendSMS && 'SMS'].filter((via) => via).join(' & ') ||
                                  t('estimates.select')}
                              </span>
                              <div className="arrow-icon">
                                <Icon name="arrowDownThin" />
                              </div>
                            </div>
                            {showSendViaDropdown && (
                              <div className="dropdown-send-via-menu position-absolute right-0  bg-white">
                                <div className="dropdown-send-via-menu-item border-bottom px-15 py-10">
                                  <div className="form-group form-check d-flex m-0">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="sendSMS"
                                        className="h-cb"
                                        disabled={!_.get(contact, 'phone') || !smsEnabled}
                                        checked={sendSMS}
                                        onChange={() => {
                                          setFieldValue('sendSMS', !sendSMS)
                                          setShowSendViaDropDown(false)

                                          revalidate()
                                        }}
                                      />
                                      <span className="checkbox-icon mr-10"></span>
                                      SMS
                                    </label>
                                  </div>
                                </div>
                                <div className="dropdown-send-via-menu-item px-15 py-10">
                                  <div className="form-group form-check d-flex m-0">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        name="sendEmail"
                                        className="h-cb"
                                        disabled={!_.get(contact, 'email')}
                                        checked={sendEmail}
                                        onChange={() => {
                                          setFieldValue('sendEmail', !sendEmail)
                                          setShowSendViaDropDown(false)
                                          revalidate()
                                        }}
                                      />
                                      <span className="checkbox-icon mr-10"></span>
                                      {t('estimates.email')}
                                    </label>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row-send-immeediatly border-bottom d-flex align-items-center">
                          <div className="send-immeediatly px-15">{t('estimates.send')}</div>
                          <div className="send-immeediatly-value">
                            <div
                              className="px-15 send-immeediatly-value-date d-flex align-items-center justify-content-between"
                              onClick={() => {
                                setShowSendViaDropDown(false)
                                setShowValidThroughDatePickerDropDown(false)

                                setShowSendScheduleDropDown(!showSendScheduleDropDown)
                                if (showSendScheduleDatePickerDropDown) {
                                  setShowSendScheduleDatePickerDropDown(false)
                                  setShowSendScheduleDropDown(false)
                                }
                              }}>
                              <span>
                                {sendSchedule === 'Immediately'
                                  ? t('estimates.immediately')
                                  : moment(sendScheduleDate).format('L')}
                              </span>
                              <div className="arrow-icon">
                                <Icon name="arrowDownThin" />
                              </div>
                            </div>
                            {showSendScheduleDropDown && (
                              <div className="send-immeediatly-calendar react-datepicker-popper">
                                <div
                                  className="send-immeediatly-calendar-item border-bottom px-15 py-10"
                                  onClick={() => {
                                    setFieldValue('sendSchedule', 'Immediately')
                                    setFieldValue('sendScheduleDate', moment().add(1, 'days').toDate())

                                    setShowSendScheduleDropDown(false)
                                  }}>
                                  {t('estimates.immediately')}
                                </div>
                                <div
                                  className="send-immeediatly-calendar-item px-15 py-10"
                                  onClick={() => {
                                    setFieldValue('sendSchedule', 'Scheduled')
                                    setShowSendScheduleDropDown(false)
                                    setShowSendScheduleDatePickerDropDown(true)
                                  }}>
                                  {t('estimates.scheduled')}
                                </div>
                              </div>
                            )}
                            {showSendScheduleDatePickerDropDown && (
                              <div className="send-immeediatly-calendar react-datepicker-popper hide-outside-dates ">
                                <DatePicker
                                  inline
                                  selected={sendScheduleDate}
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control border-0 p-0 datepicker"
                                  name="sendScheduleDate"
                                  placeholderText={'placeholdeText'}
                                  minDate={moment().add(1, 'days').toDate()}
                                  onChange={(date) => {
                                    setFieldValue('sendScheduleDate', date)

                                    if (moment(date).isAfter(moment(validThroughDate))) {
                                      setFieldValue('validThroughDate', date)
                                    }

                                    setShowSendScheduleDatePickerDropDown(false)
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="row-due-date border-bottom d-flex align-items-center">
                          <div className="due-date px-15" onClick={() => {}}>
                            {t('estimates.valid-through')}
                          </div>
                          <div className="due-date-value">
                            <div
                              className="px-15 due-date-value-date d-flex align-items-center justify-content-between"
                              onClick={() => {
                                setShowSendScheduleDatePickerDropDown(false)
                                setShowSendScheduleDropDown(false)
                                setShowSendViaDropDown(false)
                                setShowValidThroughDatePickerDropDown(!showValidThroughDatePickerDropDown)
                              }}>
                              {validThroughDisplayValue}
                              <div className="arrow-icon">
                                <Icon name="arrowDownThin" />
                              </div>
                            </div>
                            {showValidThroughDatePickerDropDown && (
                              <div className="due-date-calendar react-datepicker-popper hide-outside-dates d-sm-flex pt-10 pl-10 pr-15 pb-0">
                                <DatePicker
                                  inline
                                  selected={validThroughDate}
                                  dateFormat="MM/dd/yyyy"
                                  className="form-control border-0 p-0 datepicker"
                                  name="dueDate"
                                  placeholderText={t('estimates.select-due-date')}
                                  minDate={moment.max(moment().add(1, 'days'), moment(sendScheduleDate)).toDate()}
                                  onChange={(date) => {
                                    setFieldValue('validThroughDate', date)
                                    setShowValidThroughDatePickerDropDown(false)
                                  }}
                                />
                                <div className="calendar-button-block pl-15 pt-5">
                                  {validThroughOptions.map(({ validThrough, validThroughDate }) => (
                                    <button
                                      disabled={moment(validThroughDate).isBefore(moment(sendScheduleDate))}
                                      key={validThrough}
                                      onClick={() => {
                                        setFieldValue('validThroughDate', validThroughDate)
                                        setFieldValue('validThrough', validThrough)
                                        setShowValidThroughDatePickerDropDown(false)
                                      }}
                                      className="btn btn-sm btn-outline-primary border text-nowrap mb-15 w-100 text-uppercase">
                                      {validThrough}
                                    </button>
                                  ))}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                      <label className="label-text">
                        {t('estimates.list-items')}
                        {typeof errors.items === 'string' && <span className="form-error">{errors.items}</span>}
                        {errors.items && typeof errors.items !== 'string' && (
                          <span className="form-error">{t('estimates.please-enter-valid-items')}</span>
                        )}
                      </label>
                      <div>
                        <table className="table table-invoice rounded-sm">
                          <thead>
                            <tr>
                              <th>{t('estimates.item')}</th>
                              <th className="text-right qty">QTY</th>
                              <th className="text-right rate">{t('estimates.rate')}</th>
                              <th className="text-right amount">{t('estimates.amount')}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index} id={index}>
                                <td className="td-item-label td-item-label-name position-relative">
                                  <div className="invoice-row-handle position-absolute d-flex align-items-center justify-content-center top-0 heigh-100">
                                    <Icon name="dots" />
                                  </div>
                                  <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1 pr-15 ">
                                      <div data-test="select-invoice-service-item" className="item-name">
                                        <AsyncCreatableSelect
                                          name="service"
                                          isClearable
                                          placeholder={t('estimates.enter-item')}
                                          className="dropdown-select no-arrow-down"
                                          classNamePrefix="dropdownSelect"
                                          defaultOptions
                                          cacheOptions
                                          value={{
                                            label: item.name,
                                            value: item.name
                                          }}
                                          loadOptions={async (searchText) => {
                                            const services = await onSearchService(searchText)
                                            if (services.length <= 0) {
                                              return []
                                            }

                                            return [
                                              {
                                                options: services.map(({ id, name, price }) => {
                                                  return {
                                                    label: name,
                                                    subLabel: `${price}`,
                                                    value: {
                                                      id,
                                                      name,
                                                      quantity: 1,
                                                      rate: price
                                                    },
                                                    type: 'service'
                                                  }
                                                })
                                              }
                                            ]
                                          }}
                                          formatOptionLabel={customOptionsService}
                                          noOptionsMessage={() => null}
                                          onChange={(event, { action }) => {
                                            if (action === 'create-option') {
                                              setFieldValue(`items.${index}.name`, event.label)
                                              setFieldValue(`items.${index}.quantity`, 1)
                                              setFieldValue(`items.${index}.new`, true)
                                              revalidate()
                                              return
                                            }

                                            console.log(event)

                                            setFieldValue(`items.${index}`, {
                                              serviceId: event.value.id,
                                              name: event.value.name,
                                              rate: event.value.rate,
                                              quantity: event.value.quantity,
                                              new: false
                                            })
                                            revalidate()
                                          }}
                                          formatCreateLabel={(userInput) => (
                                            <div className="new-custom-item">
                                              <span className="">{t('estimates.new-custom-item')}</span>{' '}
                                              {`"${userInput}"`}
                                            </div>
                                          )}
                                          isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                            return (selectOptions || []).length <= 0
                                          }}
                                        />
                                      </div>
                                      {(editDescription && currentIndex === index) || item.service_description ? (
                                        <div className="item-description">
                                          <div
                                            className="item-description-text d-none"
                                            onClick={(event) => {
                                              event.target.classList.add('d-none')
                                              event.target.classList.remove('d-block')
                                              event.target
                                                .closest('.item-description')
                                                .querySelector('input')
                                                .classList.add('d-block')
                                              event.target
                                                .closest('.item-description')
                                                .querySelector('input')
                                                .classList.remove('d-none')
                                              event.target.closest('.item-description').querySelector('input').focus()
                                            }}>
                                            {item.service_description ? item.service_description : 'Enter description'}
                                          </div>
                                          <Field
                                            type="text"
                                            name="description"
                                            id={'serviceDescription_' + index}
                                            className="form-control d-block"
                                            placeholder="Enter description"
                                            value={item.service_description}
                                            autoComplete="off"
                                            onChange={(event) => {
                                              setFieldValue(`items[${index}].service_description`, event.target.value)
                                            }}
                                            onBlur={(event) => {
                                              event.target.classList.add('d-none')
                                              event.target.classList.remove('d-block')
                                              event.target
                                                .closest('.item-description')
                                                .querySelector('.item-description-text')
                                                .classList.add('d-block')
                                              event.target
                                                .closest('.item-description')
                                                .querySelector('.item-description-text')
                                                .classList.remove('d-none')
                                            }}
                                          />
                                        </div>
                                      ) : null}
                                    </div>
                                    {!item.service_description && item.serviceId ? (
                                      <div className="add-note">
                                        <Tooltip position="top" message="Add service note">
                                          <Icon onClick={() => editDescriptionBtnClick(item, index)} name="note" />
                                        </Tooltip>
                                      </div>
                                    ) : (
                                      ''
                                    )}
                                  </div>
                                </td>
                                <td className="td-item-label td-item-label-qty text-right light-gray">
                                  <Field
                                    type="text"
                                    name="quantity"
                                    placeholder="--"
                                    className="form-control form-control-lg"
                                    value={item.quantity}
                                    onChange={(event) => {
                                      console.log('quantity', event.target.value, typeof event.target.value)

                                      const quantity = event.target.value
                                      const regex = /^[0-9]+$/
                                      if (regex.test(quantity)) {
                                        setFieldValue(`items.${index}.quantity`, quantity)
                                      }
                                    }}
                                    onBlur={() => revalidate()}
                                  />
                                </td>
                                <td className="td-item-label td-item-label-rate text-right light-gray">
                                  <Field
                                    type="text"
                                    name="rate"
                                    maxLength={12}
                                    placeholder="--"
                                    className="form-control form-control-lg"
                                    value={item.rate}
                                    onChange={(event) => {
                                      console.log('rate', event.target.value, typeof event.target.value)

                                      const rate = event.target.value
                                      const regex = /^(0*[1-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                      if (regex.test(rate)) {
                                        setFieldValue(`items.${index}.rate`, rate)
                                      }
                                    }}
                                    onBlur={() => revalidate()}
                                  />
                                </td>
                                <td className="text-right td-item-label-total">
                                  <div className="position-relative">
                                    {formatUSDFromCent(item.rate * item.quantity * 100)}
                                    <div className="position-absolute remove-item">
                                      <i
                                        onClick={(event) => {
                                          const newItems = [...items]
                                          newItems.splice(index, 1)
                                          setFieldValue('items', newItems)
                                          revalidate()
                                        }}
                                        className={'mx-10 top-minus-2'}>
                                        <Icon name="removeMinus" />
                                      </i>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                          <tbody>
                            <tr>
                              <td colSpan="4" className="td-add-item border-top">
                                <div
                                  data-test="add-invoice-item"
                                  className="text-primary cursor-pointer"
                                  onClick={() => {
                                    setFieldValue('items', [
                                      ...items,
                                      {
                                        name: '',
                                        quantity: '1',
                                        rate: '0'
                                      }
                                    ])
                                  }}>
                                  {t('estimates.add-item')}
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan="3" className="light-text-color td-total-calculation">
                                {t('estimates.subtotal')}
                              </td>
                              <td className="text-right primary-font-bold td-total-calculation">{totalText}</td>
                            </tr>
                            <tr>
                              <td colSpan="3" className="primary-font-bold td-total-calculation">
                                {t('estimates.total')}
                              </td>
                              <td className="text-right primary-font-bold td-total-calculation">{totalText}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                      <label className="label-text" style={{ 'margin-right': '1%' }}>
                        {t('estimates.attachments')}
                      </label>
                      <Tooltip position="right" message={t('estimates.you-can-add-upto-5-attachments')}>
                        <Icon name="informationButton" className={style['info-button']} />
                      </Tooltip>
                      {uploadingAttachments.length !== 0 || uploadedAttachments.length !== 0 ? (
                        <div
                          className="text-primary cursor-pointer"
                          onClick={onButtonClick}
                          style={{ display: 'inline-block' }}>
                          {t('estimates.choose-file')}
                          <span id="tooltip" className="ml-4">
                            <Tooltip position="bottom" message={t('estimates.maximum-5-files-can-be-selected')} />
                          </span>
                          <input
                            ref={inputRef}
                            type="file"
                            className={style['input-file-upload']}
                            multiple={true}
                            onChange={handleChange}
                            accept=".doc,.docx,application/pdf, image/*"
                          />
                        </div>
                      ) : null}

                      <div>
                        {uploadingAttachments.length === 0 && uploadedAttachments.length === 0 ? (
                          <form
                            className={style['full-form-file-upload']}
                            onDragEnter={handleDrag}
                            onSubmit={(e) => e.preventDefault()}>
                            <input
                              ref={inputRef}
                              type="file"
                              className={style['input-file-upload']}
                              multiple={true}
                              onChange={handleChange}
                              accept=".doc,.docx,application/pdf,image/*"
                            />
                            <label
                              className={
                                dragActive ? style['label-file-upload-active'] : style['label-file-upload-updated']
                              }
                              htmlFor={style['input-file-upload']}>
                              <div>
                                <Icon name="addPlus" />
                                <p className={style['drag-and-drop-text']}>
                                  {t('estimates.drag-and-drop-your-files-here')}
                                </p>
                                <p className={style['supported-file-formats']}>
                                  {t(
                                    'estimates.supported-file-formats-are-png-jpeg-doc-and-pdf-file-size-must-be-under-5-mb'
                                  )}
                                </p>
                                <div className={style['choose-file-description']} onClick={onButtonClick}>
                                  {t('estimates.choose-file')}
                                </div>
                              </div>
                            </label>
                            {dragActive && (
                              <div
                                className={style['drag-file-element']}
                                onDragEnter={handleDrag}
                                onDragLeave={handleDrag}
                                onDragOver={handleDrag}
                                onDrop={handleDrop}></div>
                            )}
                          </form>
                        ) : null}

                        {uploadedAttachments !== null ? (
                          <table className={style['table-files']}>
                            <thead></thead>
                            <tbody>
                              {uploadedAttachments.map((file, i) => (
                                <div className="border rounded-sm mb-15 w-100" key={i} id={i}>
                                  <tr className="w-100">
                                    <td style={{ width: '10%' }} rowSpan={3}>
                                      {file.s3Info.key.includes('.pdf') ? (
                                        <Icon name="extPdf" className={style['show-files']} />
                                      ) : file.s3Info.key.includes('.doc') ? (
                                        <Icon name="extDoc" className={style['show-files']} />
                                      ) : (
                                        <Icon name="photo" className={style['show-files']} />
                                      )}
                                    </td>
                                    <td style={{ width: '80%' }}>
                                      <Box className="text-secondary" sx={{ paddingLeft: '3%' }}>
                                        {file.name}{' '}
                                      </Box>
                                    </td>
                                    <td rowSpan={3}>
                                      <Icon
                                        name="remove"
                                        onClick={(e) => {
                                          deleteFile(file.name)
                                        }}
                                        className={style['file-icon']}
                                      />
                                    </td>
                                  </tr>
                                  <tr className="w-100">
                                    <td>
                                      {file.size > 5 * 1024 * 1024 ? (
                                        <Box sx={{ paddingLeft: '3%', color: 'red', fontSize: '13px' }}>
                                          {t('estimates.file-size-should-be-less-than-5-mb')}
                                        </Box>
                                      ) : (
                                        <Box sx={{ paddingLeft: '3%', fontSize: '13px' }}>
                                          {formatBytes(file.size)} . {formatDate(file.uploadedAt)}
                                        </Box>
                                      )}
                                    </td>
                                  </tr>
                                  <tr className="w-100">
                                    <td></td>
                                  </tr>
                                </div>
                              ))}
                            </tbody>
                          </table>
                        ) : null}

                        {uploadingAttachments.length > 0 ? (
                          <table className={style['table-files']}>
                            <thead></thead>
                            <tbody>
                              {uploadingAttachments.map((file, i) =>
                                file.progress < 100 && !file.cancelUpload ? (
                                  <div className="border rounded-sm mb-15 w-100" key={file.name}>
                                    <tr className="w-100">
                                      <td rowSpan={3} style={{ width: '10%' }}>
                                        {file.type === 'application/pdf' ? (
                                          <Icon name="extPdf" className={style['show-files']} />
                                        ) : file.type.includes('.doc') ? (
                                          <Icon name="extDoc" className={style['show-files']} />
                                        ) : (
                                          <Icon name="photo" className={style['show-files']} />
                                        )}
                                      </td>
                                      <td style={{ width: '80%' }}>
                                        <Box className="text-secondary" sx={{ paddingLeft: '3%' }}>
                                          {file.name}{' '}
                                        </Box>
                                      </td>
                                      <td rowSpan={3}>
                                        {file.progress && file.progress !== 100 ? (
                                          <Icon
                                            name="crossThin"
                                            onClick={(e) => {
                                              cancelFileUpload(file.name)
                                            }}
                                            className={style['file-icon']}
                                          />
                                        ) : (
                                          <Icon
                                            name="remove"
                                            onClick={(e) => {
                                              cancelFileUpload(file.name)
                                            }}
                                            className={style['file-icon']}
                                          />
                                        )}
                                      </td>
                                    </tr>
                                    <tr className="w-100">
                                      <td>
                                        {file.progress && file.progress !== 100 ? (
                                          <div>
                                            <Line
                                              percent={file.progress}
                                              className={'px-10 '}
                                              strokeWidth="1"
                                              strokeColor="#35a800"
                                            />
                                            <div>
                                              {file.progress} {t('estimates.completed')}{' '}
                                            </div>{' '}
                                          </div>
                                        ) : file.size > 5 * 1024 * 1024 ? (
                                          <div style={{ paddingLeft: '3%', color: 'red', fontSize: '13px' }}>
                                            {' '}
                                            {t('estimates.file-size-should-be-less-than-5-mb-0')}{' '}
                                          </div>
                                        ) : (
                                          <div>
                                            {formatBytes(file.size)} . {formatDate(file.lastModified)}
                                          </div>
                                        )}
                                      </td>
                                    </tr>
                                  </div>
                                ) : null
                              )}
                            </tbody>
                          </table>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="d-block d-sm-flex">
                  <button type="submit" className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 flex-fill'}>
                    {confirmButtonText}
                  </button>
                  <button
                    type="reset"
                    className={'btn btn-xl m-0 cancel-btn flex-fill'}
                    onClick={() => {
                      resetForm()
                      resetDropdowns()
                      onCancel()
                      handleCancel()
                    }}>
                    {t('estimates.cancel')}
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    </ThemeProvider>
  )
}
