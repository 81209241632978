import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WisetackDisclosure } from '@app/components/wisetack/WisetackDisclosure'
import { FinancingPromoMessagingResponse } from './types'
import { useFeatureVal } from '@app/helpers/hooks'
import { featureFlags } from '@app/helpers/configcat'
import { WISETACK_RAW_STATUS } from '@app/helpers/wisetack'

export const WisetackDisclosureContainer = ({ amountInCents }: { amountInCents: number }) => {
  const dispatch = useDispatch()
  const isFeatureEnabled = useFeatureVal(featureFlags.wisetack)

  const financingPromoMessaging = useSelector(
    (state: { wisetack: { financingPromoMessagingResponse: FinancingPromoMessagingResponse } }) =>
      state.wisetack.financingPromoMessagingResponse.data
  )

  const wisetackSignupStatus = useSelector<{ wisetack: { signUpStatus: string } }, string>(
    (state) => state.wisetack.signUpStatus
  )

  useEffect(() => {
    dispatch.wisetack.getSignUpStatus()
    dispatch.wisetack.getFinancingPromoMessaging({
      amountInCents: amountInCents
    })
  }, [amountInCents, dispatch])

  if (wisetackSignupStatus !== WISETACK_RAW_STATUS.APPLICATION_APPROVED || !isFeatureEnabled) {
    return <></>
  }

  const disclosure =
    financingPromoMessaging && financingPromoMessaging.disclosure ? financingPromoMessaging.disclosure : null

  return disclosure ? <WisetackDisclosure disclosure={disclosure} /> : null
}
