import { api, catchHandler } from '@helpers/axios'
import { objToUrlParams } from '@helpers/index'
import config from '@config/index'

import { FinancingPromoQuery } from './types'

export const postSignUpLink = () => {
  return api()
    .post(`${config.routes.postSignupLink}`)
    .then((res) => res.data as { singupLink: string; merchantId: string })
    .catch((err) => {
      catchHandler(err)
      return null
    })
}

export const getSignUpStatus = () => {
  return api()
    .get(`${config.routes.getSignupStatus}`)
    .then((res) => {
      return res.data as { status: string }
    })
    .catch((err) => {
      catchHandler(err)
      return null
    })
}

export const getPrequalLink = () => {
  return api()
    .get(`${config.routes.getPrequalLink}`)
    .then((res) => {
      return res.data as { prequalLink: string }
    })
    .catch((err) => {
      catchHandler(err)
      return null
    })
}

export function getFinancingPromoMessaging(query: FinancingPromoQuery) {
  return api()
    .get(`${config.routes.getFinancingPromoMessaging}?${objToUrlParams(query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
