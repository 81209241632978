import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { segmentEventTrack } from '@helpers/segment'
import config from '@config/index'

export default {
  state: {
    domainAvailable: true
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        siteLoading: true,
        apiError: ''
      }
    },
    onfetchSites(state, isSiteEnabled) {
      return {
        ...state,
        isSiteEnabled: isSiteEnabled
      }
    },
    onError (state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
            ? data.data.message
            : data && data.error
              ? data.error
              : data && data.data && data.data.error
                ? data.data.error
                : data && data.data
                  ? data.data
                  : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        siteLoading: false,
        apiError: err
      }
    },
    onSuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false
      }
    },
    handleDefault (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false
      }
    },
    ongetSiteDetailsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        siteDetail: data
      }
    },
    onBuilderRegisterSuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        builderRegisterResponse: data
      }
    },
    onGetThemeSelectionSuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        siteThemeDetail: data
      }
    },
    onGetCustomThemeSuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        customThemes: data.data.docs,
        customThemesTotal: data.data.total
      }
    },
    onGetSubDomainSuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        subDomainData: data
      }
    },
    onPublishSiteSuccess (state, data) {
      const oldsites = { ...state.mysites }
      const newsite = data && data.website

      const updatedList =
        oldsites.docs &&
        oldsites.docs.map((oldsite) => {
          if (oldsite._id === newsite._id) {
            return newsite
          } else return oldsite
        })
      oldsites.docs = updatedList

      if (newsite) {
        let addr
        if (newsite.domain) {
          addr = `http://${newsite.domain}`
        } else if (newsite.partnerDomain && newsite.partnerDomainUrl) {
          addr = newsite.partnerDomainUrl
        } else if (newsite && newsite.partnerSubDomain) {
          addr = `https://${newsite.partnerSubDomain}`
        } else {
          addr = `http://${newsite.subDomain}.${config.domain}`
        }
        if (addr) {
          window.open(addr, '_blank')
        }
      }

      return {
        ...state,
        loading: false,
        siteLoading: false,
        mysites: oldsites
      }
    },
    onGetSitesAnalytics (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        sitesanalytics: data
      }
    },
    onGetAllSites (state, data) {
      let siteCount = 0

      if (data && data.booking_site) {
        siteCount = siteCount + 1
      }
      if (data && data.payment_site) {
        siteCount = siteCount + 1
      }
      return {
        ...state,
        loading: false,
        siteLoading: false,
        mysites: data && data.data,
        siteCount: siteCount
      }
    },
    onfetchSubdomainAvailabilitySuccess (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        isDomainNameAvailable: data.isAvailable,
        domainUrl: data.url ? data.url : ''
      }
    },
    onfetchEditSiteSettingsSuccess (state, data) {
      notification('success', 'Website Updated')

      const oldsites = { ...state.mysites }
      const newsite = data

      const updatedList =
        oldsites.docs &&
        oldsites.docs.map((oldsite) => {
          if (oldsite._id === newsite._id) {
            return newsite
          } else return oldsite
        })
      oldsites.docs = updatedList

      return {
        ...state,
        loading: false,
        siteLoading: false,
        mysites: oldsites,
        siteSettingsData: newsite
      }
    },
    onMoveWebsiteToTrashSuccess (state, data) {
      notification('success', 'Website Removed')

      return {
        ...state,
        loading: false,
        siteLoading: false
      }
    },
    oncheckDomainAvailability (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        domainAvailable: data.isAvailable
      }
    },
    onGetDomainList (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        domainList: data.data
      }
    },
    onGetTrashedWebsites (state, data) {
      return {
        ...state,
        loading: false,
        siteLoading: false,
        trashedSites: data.data
      }
    },
    onRestoreSuccess (state, data) {
      // let oldtrashsites = [ ...state.trashedSites ]
      // let updatedList =  oldtrashsites.length && oldtrashsites.filter(oldsite => oldsite._id !== data._id)
      // oldtrashsites = updatedList
      notification('success', 'Website restored')
      return {
        ...state,
        loading: false,
        siteLoading: false
        // trashedSites:oldtrashsites
      }
    },
    onDeleteSuccess (state, data) {
      // let oldtrashsites = [ ...state.trashedSites ]
      // let updatedList =  oldtrashsites.length && oldtrashsites.filter(oldsite => oldsite._id !== data._id)
      // oldtrashsites = updatedList

      notification('success', 'Website removed')

      const trackData = {
        event: 'site_delete',
        properties: {
          total_sites: state.mysites.total
        }
      }
      segmentEventTrack(trackData)

      return {
        ...state,
        loading: false,
        siteLoading: false
        // trashedSites:oldtrashsites
      }
    },
    onCloneWebsiteSuccess (state, data) {
      const trackData = {
        event: 'site_duplicate',
        properties: {}
      }

      segmentEventTrack(trackData)
      return {
        ...state,
        loading: false,
        siteLoading: false,
        clonedWebsite: data
      }
    },
    oncCreatePreviewSuccess (state, data) {
      if (data && data.message) {
        notification('success', data.message)
      }
      const oldsites = { ...state.mysites }
      const newsite = data && data.data

      const updatedList =
        oldsites.docs &&
        oldsites.docs.map((oldsite) => {
          if (oldsite._id === newsite._id) {
            return newsite
          } else return oldsite
        })
      oldsites.docs = updatedList

      return {
        ...state,
        loading: false,
        siteLoading: false,
        mysites: oldsites
      }
    },
    onPartnerDomainAvailableSuccess (state, data) {
      return {
        ...state,
        loading: false,
        isPartnerDomainAvailable: data.isAvailable
      }
    },
    onGetBusinessTypeSuccess (state, data) {
      return {
        ...state,
        loading: false,
        businessType: data
      }
    },
    onDeleteThemeSuccess (state, data) {
      notification('success', 'Custom template deleted successfully')
      return {
        ...state,
        loading: false
      }
    },
    onRenameThemeSuccess (state, data) {
      notification('success', 'Custom template renamed successfully')
      return {
        ...state,
        loading: false
      }
    },
    onGenerateApiTokenSuccess (state, data) {
      return {
        ...state,
        loading: false,
        sessionData: data
      }
    },
  },
  effects: {
    async getSiteDetails (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSiteDetails(payload)
        this.ongetSiteDetailsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async publishSite (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.publishSite(payload)
        this.onPublishSiteSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSitesAnalytics (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSitesAnalytics(payload)
        this.onGetSitesAnalytics(res)
        return res
      } catch (e) {
        this.handleDefault()
      }
    },
    async getAllSites (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getAllSites(payload)
        this.onGetAllSites(res)
        return res
      } catch (e) {
        this.handleDefault()
      }
    },
    async getThemeSelection (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getThemeSelection(payload)
        this.onGetThemeSelectionSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSiteParent(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getSiteParent(payload)
        this.onfetchSites(res.isSiteEnabled)
        return res.isSiteEnabled
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchSubdomainAvailability (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchSubdomainAvailability(payload)
        this.onfetchSubdomainAvailabilitySuccess(res)

        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchSubdomainAvailabilityOnPAyment (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchSubdomainAvailabilityOnPAyment(payload)
        this.onfetchSubdomainAvailabilitySuccess(res)

        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async moveWebsiteToTrash (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.moveWebsiteToTrash(payload)
        this.onMoveWebsiteToTrashSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async editSiteSettings (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.editSiteSettings(payload)
        this.onfetchEditSiteSettingsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async checkDomainAvailability (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.checkDomainAvailability(payload)
        this.oncheckDomainAvailability(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getDomainList (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getDomainList(payload)
        this.onGetDomainList(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getTrashedWebsites (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getAllSites(payload)
        this.onGetTrashedWebsites(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async restoreWebsite (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.restoreWebsite(payload)
        this.onRestoreSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteWebsite (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteWebsite(payload)
        this.onDeleteSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async cloneWebsite (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.cloneWebsite(payload)
        this.onCloneWebsiteSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSubDomain (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSubDomain(payload)
        this.onGetSubDomainSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async builderRegister (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.builderRegister(payload)
        this.onBuilderRegisterSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createPreview (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createPreview(payload)
        this.oncCreatePreviewSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async partnerDomainAvailable (payload, rootState) {
      try {
        const res = await service.partnerDomainAvailable(payload)
        this.onPartnerDomainAvailableSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getCustomThemes (payload, rootState, viewpermission) {
      this.onRequest()
      try {
        const res = await service.getCustomThemes(payload, viewpermission)
        this.onGetCustomThemeSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBusinessType () {
      this.onRequest()
      try {
        const res = await service.getBusinessType()
        this.onGetBusinessTypeSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteCustomTheme (payload) {
      this.onRequest()
      try {
        const res = await service.deleteCustomTheme(payload)
        this.onDeleteThemeSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async renameCustomTheme (payload) {
      this.onRequest()
      try {
        const res = await service.renameCustomTheme(payload)
        this.onRenameThemeSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async generateApiToken (payload) {
      try {
        const res = await service.generateApiToken(payload)
        this.onGenerateApiTokenSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
  }
}
