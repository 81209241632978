import { useEffect } from 'react'
import { atom, useRecoilState } from 'recoil'

export const transitioningTabRedirectState = atom({
  key: 'transitionTabRedirect',
  default: ''
})

const tabRedirectState = atom({
  key: 'tabRedirect',
  default: ''
})

// Observe a shared redirection state
function RedirectionHandler ({ readyToRedirect, setSelectedTabValue }) {
  const [currentTab, setCurrentTab] = useRecoilState(tabRedirectState)

  useEffect(() => {
    if (readyToRedirect && currentTab) {
      console.log('Setting current tab to: ', currentTab)
      setSelectedTabValue(currentTab)
      setCurrentTab('') // Reset the state to observe the next redirect
    }
  }, [currentTab, readyToRedirect])

  return null
}

// Observes the tabRedirect state emitted from UNB
function NewButtonTabRedirection () {
  const [tabRedirect, setTabRedirect] = useRecoilState(transitioningTabRedirectState)
  const [, setCurrentTab] = useRecoilState(tabRedirectState)

  useEffect(() => {
    if (tabRedirect) {
      console.log('Setting tab to: ', tabRedirect)
      setCurrentTab(tabRedirect)
      setTabRedirect('') // Reset the state to observe the next redirect
    }
  }, [tabRedirect])

  return null
}

export { RedirectionHandler, NewButtonTabRedirection }
