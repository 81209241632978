import { api, apiV2, apiV2Uberall, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { getV2ConfigCatValue } from '@helpers/utility'

export function getSocialStatus(payload) {
  return api()
    .get(config.routes.socialStatus)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSocialPosts(payload) {
  return api()
    .get(`${config.routes.socialPosts}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPlacementList(payload) {
  const id = payload._id
  delete payload._id
  return api()
    .get(`${config.routes.getPlacementList}/${id}/scan?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getLocation(payload) {
  return api()
    .get(`${config.routes.getLocation}/${payload.customerId}/locations`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addressView(payload) {
  return api()
    .put(`${config.routes.getPlacementList}/${payload._id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPresenceLocation(payload) {
  return api()
    .get(`${config.routes.presenceLocation}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function syncSocialPost(payload) {
  return api()
    .post(config.routes.syncPosts, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deletePost(payload) {
  return api()
    .post(config.routes.deletePosts, payload, { params: { customers: payload._user_id } })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createPost(payload) {
  return api()
    .post(config.routes.createPosts, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchInsights(payload) {
  return api()
    .get(config.routes.insights, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function UpdateInsights(payload) {
  return api()
    .get(`${config.routes.insights}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function fetchQuestions(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .get(`${config.routes.fetchQuestionsV2}?${objToUrlParams(payload)}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .get(`${config.routes.fetchQuestions}?${objToUrlParams(payload)}`)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function getConfigFlag(payload) {
  return api()
    .post(`${config.routes.getConfigFlag}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function fetchAnswer(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .get(`${config.routes.fetchQuestionsV2}/${payload.id}/answers`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .get(`${config.routes.fetchQuestions}/${payload.id}/answers`)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function sendAnswer(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .post(`${config.routes.fetchQuestionsV2}/${payload.id}/answers`, payload.payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.fetchQuestions}/${payload.id}/answers`, payload.payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function UpdateAnswer(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .put(`${config.routes.fetchQuestionsV2}/${payload.questionId}/answers/${payload.answerId}`, payload.payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(`${config.routes.fetchQuestions}/${payload.questionId}/answers/${payload.answerId}`, payload.payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function deleteQuestion(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .delete(`${config.routes.fetchQuestionsV2}/${payload.id}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .delete(`${config.routes.fetchQuestions}/${payload.id}`)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function deleteAnswer(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .delete(`${config.routes.fetchQuestionsV2}/${payload.questionId}/answers/${payload.answerId}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .delete(`${config.routes.fetchQuestions}/${payload.questionId}/answers/${payload.answerId}`)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function addQuestion(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .post(config.routes.fetchQuestionsV2, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(config.routes.questions, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function updateQuestion(payload) {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .put(`${config.routes.fetchQuestionsV2}/${payload.id}`, payload.payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(`${config.routes.fetchQuestions}/${payload.id}`, payload.payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function getFilter(payload) {
  return api()
    .get(`${config.routes.filters}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchQuestionSynced() {
  return api()
    .get(`${config.routes.fetchQuestionLastUpdated}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function syncQuestionsNow() {
  const value = await getV2ConfigCatValue('isV2SunsetApi')
  if (value) {
    return apiV2()
      .get(`${config.routes.gmbDataSyncV2}/${config.routes.questionSync}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .get(`${config.routes.questionSync}`)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function uberAllCategory(payload, module) {
  const value = await getV2ConfigCatValue('uberallBanners')
  if (value) {
    return apiV2Uberall()
      .get(`${config.routes.uberAllCategories}/${payload}?module=${module}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return ''
  }
}
