import React, { useEffect, useRef } from 'react'
import { isEnabled, isDisabled, checkPermissionWOproduct } from '@helpers/utility'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export function GlobalPopup({
  showMore,
  sendMessagePopup,
  isReviewDeletePermitted,
  showReviewRequestPopup,
  isContactCreatePermitted,
  showAddContactPopup,
  isBookingPermitted,
  addBookingPopup,
  isMessengerSendPermitted,
  isPaymentPermitted,
  me,
  showLess,
  openInvoiceModal,
  showPaymentLinkPopup,
  isPaymentLinkPermitted,
  showAddMemberPopup,
  sendBookingLink,
  closeModal,
  products,
  user,
  isReadOnlyRole,
  disabledProducts,
  isContactEditPermitted,
  opencreategroupPopup,
  openAddTransactionModal,
  paymentApplnstatus,
  redirectToOutlookCalendar,
  redirectToConnectGoogleCalendar,
  importGoogleContacts,
  importOutlookContacts,
  importQuickBookContacts,
  importContacts,
  setupAutoResponse,
  syncFacebookMessenger,
  syncThumbtackMessages,
  createReviewWidget,
  redirectToSetupBusinessHours,
  redirectToCreateBookingWidget,
  redirectToCreateChatWidget,
  redirectToCreatePaymentWidget,
  openRecurringInvoiceModal,
  onBoardingData,
  redirectToPhotoOrVideos,
  redirectToSetupNotifications,
  showAddServicePopup,
  payment_v2,
  invoices_v2
}) {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef()
  const { t } = useTranslation()
  useOnClickOutside(ref, closeModal)

  const enableAddContact = Boolean(
    isContactCreatePermitted &&
      ((products && isEnabled('contact-manager', products)) ||
        (!isEnabled('contact-manager', products) &&
          !isDisabled('contact-manager', disabledProducts) &&
          user.role &&
          (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner)))
  )

  const enableSendMessage = Boolean(
    (products &&
      isEnabled('messenger', products) &&
      user.role &&
      (user.role !== 'partner' || user.isSMBPartner) &&
      user.role !== 'manager') ||
      (!isEnabled('messenger', products) &&
        !isDisabled('messenger', disabledProducts) &&
        user.role &&
        (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner))
  )

  const enableSendPaymentLinkWithoutStripe = Boolean(
    me &&
      me.account_settings &&
      me.account_settings.payment &&
      !['stripe'].includes(me.account_settings.payment_type) &&
      me.account_settings.payment.merchant_id
  )

  const enablePayments = Boolean(
    isPaymentPermitted &&
      !isDisabled('payment', disabledProducts) &&
      user.role &&
      (user.role === 'customer' ||
        user.role === 'trial' ||
        user.role === 'staff' ||
        isReadOnlyRole ||
        user.isSMBPartner)
  )

  const enableInvoiceBasedOnSidebarRoute = Boolean(
    me &&
      me.account_settings &&
      me.account_settings.payment &&
      ((me.account_settings.payment_type !== 'stripe' &&
        (me.account_settings.payment.merchant_id || me.account_settings.payment.isInvoiceUnlocked)) ||
        (onBoardingData && onBoardingData.status === 'tested') ||
        me.account_settings.payment.isInvoiceUnlocked)
  )

  // const enableSendPaymentLink = Boolean(
  //   isPaymentLinkPermitted &&
  //     !isDisabled('payment', disabledProducts) &&
  //     user.role &&
  //     (user.role === 'customer' ||
  //       user.role === 'trial' ||
  //       user.role === 'staff' ||
  //       isReadOnlyRole ||
  //       user.isSMBPartner) &&
  //     me &&
  //     me.account_settings &&
  //     me.account_settings.payment &&
  //     ['stripe'].includes(me.account_settings.payment_type) &&
  //     paymentApplnstatus &&
  //     ['tested'].includes(paymentApplnstatus)
  // )

  const enableShowPaymentLinkPopup = Boolean(
    disabledProducts &&
      !isDisabled('payment', disabledProducts) &&
      me &&
      me.account_settings &&
      me.account_settings.payment &&
      ['stripe'].includes(me.account_settings.payment_type) &&
      paymentApplnstatus &&
      ['tested'].includes(paymentApplnstatus)
  )

  const enableSendReviewLink = Boolean(
    isReviewDeletePermitted &&
      ((products &&
        isEnabled('review-manager', products) &&
        user.role &&
        (user.role === 'customer' || user.role === 'staff')) ||
        (!isEnabled('review-manager', products) &&
          checkPermissionWOproduct('Custom.Review.Overview.Show', me) &&
          (user.role === 'trial' || isReadOnlyRole || user.isSMBPartner)) ||
        (isEnabled('review-manager', products) && (user.role === 'trial' || isReadOnlyRole || user.isSMBPartner)) ||
        (!isEnabled('review-manager', products) &&
          (!isDisabled('review-manager', disabledProducts) || isEnabled('review-request', products)) &&
          (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner) &&
          !checkPermissionWOproduct('Custom.Review.Overview.Show', me)) ||
        (!isEnabled('review-manager', products) &&
          (!isDisabled('review-manager', disabledProducts) || isEnabled('review-request', products)) &&
          user.role === 'customer' &&
          checkPermissionWOproduct('Custom.Review.Overview.Show', me)))
  )

  const enableSendReviewRequest = Boolean(
    isReviewDeletePermitted &&
      ((products &&
        isEnabled('review-manager', products) &&
        user.role &&
        (user.role === 'customer' || user.role === 'staff')) ||
        (!isEnabled('review-manager', products) &&
          checkPermissionWOproduct('Custom.Review.Overview.Show', me) &&
          (user.role === 'trial' || isReadOnlyRole || user.isSMBPartner)) ||
        (isEnabled('review-manager', products) && (user.role === 'trial' || isReadOnlyRole || user.isSMBPartner)) ||
        (!isEnabled('review-manager', products) &&
          (!isDisabled('review-manager', disabledProducts) || isEnabled('review-request', products)) &&
          (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner) &&
          !checkPermissionWOproduct('Custom.Review.Overview.Show', me)) ||
        (!isEnabled('review-manager', products) &&
          (!isDisabled('review-manager', disabledProducts) || isEnabled('review-request', products)) &&
          user.role === 'customer' &&
          checkPermissionWOproduct('Custom.Review.Overview.Show', me)))
  )

  const enableBooking = Boolean(
    isBookingPermitted &&
      ((products && isEnabled('booking', products)) ||
        (!isEnabled('booking', products) &&
          !isDisabled('booking', disabledProducts) &&
          user.role &&
          (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner)))
  )

  const enableSendInvoice = Boolean(
    _.get(me, 'account_settings.payment.merchant_id') || _.get(me, 'account_settings.payment.isInvoiceUnlocked')
  )

  const isBusinessProfile = _.get(me, 'businessProfile', false)

  const enableInvoices = Boolean(
    !isDisabled('payment', disabledProducts) &&
      user.role &&
      (user.role === 'customer' ||
        user.role === 'trial' ||
        user.role === 'staff' ||
        isReadOnlyRole ||
        user.isSMBPartner)
  )

  const enableRecurringInvoice = Boolean(
    me &&
      me.account_settings &&
      me.account_settings.payment &&
      (me.account_settings.payment.merchant_id || me.account_settings.payment.isInvoiceUnlocked)
  )

  const enableContactHub = Boolean(
    (products && isEnabled('contact-manager', products)) ||
      (!isEnabled('contact-manager', products) &&
        !isDisabled('contact-manager', disabledProducts) &&
        user.role &&
        (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner))
  )

  const enableMessengerSection = Boolean(
    (products &&
      isEnabled('messenger', products) &&
      user.role &&
      (user.role !== 'partner' || user.isSMBPartner) &&
      user.role !== 'manager') ||
      (!isEnabled('messenger', products) &&
        !isDisabled('messenger', disabledProducts) &&
        user.role &&
        (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner))
  )

  return (
    <div
      ref={ref}
      className={`global-modal-root ${showMore ? 'global-modal-root-expanded' : 'global-modal-root-collapsed'}`}>
      <div className="global-modal">
        <div className="most-popular">
          <span className="popular-heading">{t('common.most-popular')}</span>
          {enableAddContact && isContactCreatePermitted && (
            <span className="feature-link" onClick={showAddContactPopup}>
              {t('common.add-contact')}
            </span>
          )}

          {isBookingPermitted && enableBooking && (
            <span className="feature-link" onClick={sendBookingLink}>
              {t('common.send-booking-link')}
            </span>
          )}

          {enableSendMessage && enableMessengerSection && (
            <span className="feature-link" onClick={sendMessagePopup}>
              {t('common.send-message')}
            </span>
          )}

          {enablePayments && (enableShowPaymentLinkPopup || enableSendPaymentLinkWithoutStripe) && (
            <span className="feature-link" onClick={showPaymentLinkPopup}>
              {t('common.send-payment-link')}
            </span>
          )}

          {enableSendReviewLink && (
            <span className="feature-link" onClick={showReviewRequestPopup}>
              {' '}
              {t('common.send-review-link')}{' '}
            </span>
          )}
        </div>

        {showMore && (
          <div className="products-list">
            <div>
              <div className={'feature-section'}>
                {enableContactHub && (
                  <div>
                    <span className="feature-title">{t('common.contact-hub')}</span>
                    {isContactCreatePermitted && (
                      <span className="feature-link" onClick={showAddContactPopup}>
                        {t('common.add-contact')}
                      </span>
                    )}
                    {isContactEditPermitted && (
                      <span className="feature-link" onClick={opencreategroupPopup}>
                        {t('common.add-contact-group')}
                      </span>
                    )}
                    <>
                      <span className="feature-link" onClick={importGoogleContacts}>
                        {t('common.sync-google-contacts')}{' '}
                      </span>
                      <span className="feature-link" onClick={importOutlookContacts}>
                        {t('common.sync-outlook-contacts')}
                      </span>
                      <span className="feature-link" onClick={importQuickBookContacts}>
                        {t('common.sync-quickbook-contacts')}
                      </span>
                      <span className="feature-link" onClick={importContacts}>
                        {t('common.upload-contacts-as-csv')}
                      </span>
                    </>
                  </div>
                )}
              </div>
            </div>
            <div>
              {isMessengerSendPermitted && (
                <div className={'feature-section'}>
                  {enableMessengerSection && (
                    <div>
                      <span className="feature-title">{t('common.messenger')}</span>
                      {isContactEditPermitted && (
                        <div>
                          <span className="feature-link" onClick={sendMessagePopup}>
                            {t('common.send-email')}
                          </span>
                          <span className="feature-link" onClick={sendMessagePopup}>
                            {t('common.send-sms')}
                          </span>
                        </div>
                      )}
                      <>
                        {' '}
                        {isEnabled('messenger', products) && checkPermissionWOproduct('Admin.Messenger.Manage', me) ? (
                          <span className="feature-link" onClick={setupAutoResponse}>
                            {t('common.setup-auto-response')}
                          </span>
                        ) : null}
                        <span className="feature-link" onClick={syncFacebookMessenger}>
                          {t('common.sync-facebook-messenger')}
                        </span>
                        {/* <span className="feature-link">Sync Google Business Chat</span> */}
                        <span className="feature-link" onClick={syncThumbtackMessages}>
                          {t('common.sync-thumbtack-messages')}
                        </span>
                      </>
                    </div>
                  )}
                </div>
              )}

              {enableBooking && (
                <div className={'feature-section'}>
                  <span className="feature-title">{t('settings.booking')}</span>
                  <span className="feature-link" onClick={addBookingPopup}>
                    {t('bookings.add-booking-0')}
                  </span>
                  <span className="feature-link" onClick={redirectToConnectGoogleCalendar}>
                    {t('common.connect-google-calendar')}
                  </span>
                  <>
                    <span className="feature-link" onClick={redirectToOutlookCalendar}>
                      {t('common.connect-outlook-calendar')}
                    </span>
                    <span className="feature-link" onClick={sendBookingLink}>
                      {t('common.send-booking-link')}
                    </span>
                  </>
                </div>
              )}
            </div>
            <div>
              {isPaymentPermitted &&
                onBoardingData &&
                enableInvoices &&
                enableInvoiceBasedOnSidebarRoute &&
                (enableSendInvoice || enableRecurringInvoice) && (
                  <div className="feature-section">
                    {enableSendInvoice && invoices_v2 ? (
                      <div>
                        <span className="feature-title">{t('sidebar.invoices')}</span>

                        {enableSendInvoice && (
                          <span className="feature-link" onClick={openInvoiceModal}>
                            {t('payments.send-invoice')}
                          </span>
                        )}
                        {enableRecurringInvoice && (
                          <span className="feature-link" onClick={openRecurringInvoiceModal}>
                            {t('common.setup-recurring-invoice')}
                          </span>
                        )}
                      </div>
                    ) : null}
                  </div>
                )}
              {enableSendReviewRequest && (
                <div className={'feature-section'}>
                  <span className="feature-title">{t('settings.reviews')}</span>
                  <span className="feature-link" onClick={showReviewRequestPopup}>
                    {' '}
                    {t('reviews.send-review-request')}{' '}
                  </span>
                </div>
              )}

              {enablePayments && (enableShowPaymentLinkPopup || enableSendPaymentLinkWithoutStripe) && payment_v2 && (
                <div className={'feature-section'}>
                  <div>
                    <span className="feature-title">{t('sidebar.payments')}</span>
                    {enableShowPaymentLinkPopup && (
                      <span className="feature-link" onClick={showPaymentLinkPopup}>
                        {t('common.send-payment-link')}
                      </span>
                    )}
                    {enableSendPaymentLinkWithoutStripe ? (
                      <span className="feature-link" onClick={showPaymentLinkPopup}>
                        {t('common.send-payment-link')}
                      </span>
                    ) : null}
                    {disabledProducts &&
                    !isDisabled('payment', disabledProducts) &&
                    me &&
                    me.account_settings &&
                    me.account_settings.payment &&
                    ['stripe'].includes(me.account_settings.payment_type) &&
                    paymentApplnstatus &&
                    ['tested'].includes(paymentApplnstatus) ? (
                      <span className="feature-link" onClick={openAddTransactionModal}>
                        {' '}
                        {t('common.capture-payment')}
                      </span>
                    ) : null}
                    {me &&
                    me.account_settings &&
                    me.account_settings.payment &&
                    !['stripe'].includes(me.account_settings.payment_type) &&
                    me.account_settings.payment.merchant_id ? (
                      <span className="feature-link" onClick={openAddTransactionModal}>
                        {t('common.capture-payment')}
                      </span>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div>
              <div className="feature-section">
                <span className="feature-title">{t('payments.widgets')}</span>
                <span className="feature-link" onClick={redirectToCreateBookingWidget}>
                  {t('common.create-booking-widget')}
                </span>
                <span className="feature-link" onClick={redirectToCreateChatWidget}>
                  {t('common.create-chat-widget')}
                </span>
                <span className="feature-link" onClick={redirectToCreatePaymentWidget}>
                  {t('common.create-payment-widget')}
                </span>
                <span className="feature-link" onClick={createReviewWidget}>
                  {t('common.create-review-widget')}
                </span>
              </div>
              <>
                <div className="feature-section">
                  <span className="feature-title">{t('common.settings')}</span>
                  <span className="feature-link" onClick={showAddServicePopup}>
                    {t('common.add-service')}
                  </span>
                  <span href=" " className="feature-link" onClick={showAddMemberPopup}>
                    {' '}
                    {t('common.add-employee')}{' '}
                  </span>
                  {/* <span href=" " className="feature-link">
                   {' '}
                   Add Location{' '}
                    </span> */}
                  <span className="feature-link" onClick={redirectToSetupBusinessHours}>
                    {t('common.setup-business-hours')}
                  </span>
                  <span className="feature-link" onClick={redirectToPhotoOrVideos}>
                    {t('common.import-photos-videos')}
                  </span>
                  {user &&
                    user.user_unique_id &&
                    user.role !== 'partner' &&
                    user.role !== 'manager' &&
                    !isBusinessProfile && (
                      <span className="feature-link" onClick={redirectToSetupNotifications}>
                        {t('common.setup-notifications')}
                      </span>
                    )}
                </div>
              </>
            </div>
          </div>
        )}
      </div>

      <div className={'show-less-more'}>
        <span className={'show-less-more-button'} onClick={showLess}>
          {showMore ? t('common.show-less') : t('common.show-more')}
        </span>
        {/* <span className="close" onClick={closeModal}>Close </span> */}
        {/* <button
          id="frmAddContact_btnCancel"
          type="reset"
          className="btn btn-xl flex-grow-0 cancel-btn d-block d-sm-inline-block" onClick={closeModal}>
                      CANCEL
        </button> */}
        <button
          type="reset"
          id="frmAddContact_btnCancel"
          onClick={closeModal}
          className="close custom-modal-close-button remove-modal-close">
          <span aria-hidden="true">×</span>
        </button>
      </div>
    </div>
  )
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}
