import React from 'react'
import { ReactComponent as close } from '@assets/svg-icons/close.svg'
import { ReactComponent as gositeLogo } from '@assets/svg-icons/gosite-logo.svg'
import { ReactComponent as swiper } from '@assets/svg-icons/swiper.svg'
import { ReactComponent as online } from '@assets/svg-icons/online.svg'
import { ReactComponent as edit } from '@assets/svg-icons/pencil.svg'
import { ReactComponent as editFill } from '@assets/svg-icons/pencil-fill.svg'
import { ReactComponent as upload } from '@assets/svg-icons/upload.svg'
import { ReactComponent as trash } from '@assets/svg-icons/trash.svg'
import { ReactComponent as paginationArrowRight } from '@assets/svg-icons/pagination-arrow-right.svg'
import { ReactComponent as google } from '@assets/svg-icons/google.svg'
import { ReactComponent as facebook } from '@assets/svg-icons/facebook.svg'
import { ReactComponent as plusRound } from '@assets/svg-icons/plus-round.svg'
import { ReactComponent as arrowDown } from '@assets/svg-icons/arrow-down.svg'
import { ReactComponent as blocked } from '@assets/svg-icons/blocked-Icon.svg'
import { ReactComponent as badgeTick } from '@assets/svg-icons/badge-tick.svg'
import { ReactComponent as photo } from '@assets/svg-icons/photo.svg'
import { ReactComponent as video } from '@assets/svg-icons/video.svg'
import { ReactComponent as plusThin } from '@assets/svg-icons/plus-thin-icon.svg'
import { ReactComponent as closeThin } from '@assets/svg-icons/close-thin-icon.svg'
import { ReactComponent as tickThin } from '@assets/svg-icons/tick-thin-icon.svg'
import { ReactComponent as tickThinNormal } from '@assets/svg-icons/tick-thin-normal.svg'
import { ReactComponent as eyeVisible } from '@assets/svg-icons/eye-visible.svg'
import { ReactComponent as eyeInvisible } from '@assets/svg-icons/eye-invisible.svg'
import { ReactComponent as exportIcon } from '@assets/svg-icons/export.svg'
import { ReactComponent as restore } from '@assets/svg-icons/restore.svg'
import { ReactComponent as notificationIcon } from '@assets/svg-icons/notification-icon.svg'
import { ReactComponent as minus } from '@assets/svg-icons/minus.svg'
import { ReactComponent as refresh } from '@assets/svg-icons/refresh.svg'
import { ReactComponent as recurring } from '@assets/svg-icons/recurring-green.svg'
import { ReactComponent as refresh2 } from '@assets/svg-icons/refresh2.svg'
import { ReactComponent as recurringGray } from '@assets/svg-icons/recurring-gray.svg'
import { ReactComponent as recurringRed } from '@assets/svg-icons/recurring-red.svg'
import { ReactComponent as importIcon } from '@assets/svg-icons/import.svg'
import { ReactComponent as importRounded } from '@assets/svg-icons/import-rounded.svg'
import { ReactComponent as spinner } from '@assets/svg-icons/spinner.svg'
import { ReactComponent as booking, ReactComponent as calender } from '@assets/svg-icons/booking.svg'
import { ReactComponent as thumbtackIcon } from '@assets/svg-icons/Subtract.svg'
import { ReactComponent as disconnectThumbtackIcon } from '@assets/svg-icons/disconnectThumbtack.svg'
import { ReactComponent as infoIcon } from '@assets/svg-icons/info-icon.svg'

import { ReactComponent as fillPlus } from '@assets/svg-icons/fill-plus.svg'
import { ReactComponent as greyTick } from '@assets/svg-icons/grey-tick.svg'
import { ReactComponent as email } from '@assets/svg-icons/email.svg'
import { ReactComponent as phone } from '@assets/svg-icons/phone.svg'
import { ReactComponent as schedule } from '@assets/svg-icons/schedule.svg'
import { ReactComponent as payment } from '@assets/svg-icons/payment.svg'
import { ReactComponent as paymentActive } from '@assets/svg-icons/payments-active.svg'
import { ReactComponent as postsActive } from '@assets/svg-icons/posts-active.svg'
import { ReactComponent as reviewMonitorActive } from '@assets/svg-icons/review-monitor-active.svg'
import { ReactComponent as reviewRequestActive } from '@assets/svg-icons/review-request-active.svg'
import { ReactComponent as created } from '@assets/svg-icons/created.svg'
import { ReactComponent as attachment } from '@assets/svg-icons/attachment.svg'
import { ReactComponent as coloredBooking } from '@assets/svg-icons/colored-booking.svg'
import { ReactComponent as fillArrowDown } from '@assets/svg-icons/fill-arrow-down.svg'
import { ReactComponent as refund } from '@assets/svg-icons/refund.svg'
import { ReactComponent as contact } from '@assets/svg-icons/contacts.svg'
import { ReactComponent as reviews } from '@assets/svg-icons/reviews.svg'
import { ReactComponent as engagement } from '@assets/svg-icons/engagement.svg'
import { ReactComponent as engagementActive } from '@assets/svg-icons/engagement-active.svg'
import { ReactComponent as payments } from '@assets/svg-icons/payments.svg'
import { ReactComponent as messenger } from '@assets/svg-icons/messenger.svg'
import { ReactComponent as messengerActive } from '@assets/svg-icons/messenger-active.svg'
import { ReactComponent as site } from '@assets/svg-icons/site.svg'
import { ReactComponent as placement } from '@assets/svg-icons/placement.svg'
import { ReactComponent as add } from '@assets/svg-icons/add-plus-icon.svg'
import { ReactComponent as statusTick } from '@assets/svg-icons/status-tick-icon.svg'
import { ReactComponent as statusClose } from '@assets/svg-icons/status-close-icon.svg'
import { ReactComponent as statusPending } from '@assets/svg-icons/status-pending-icon.svg'
import { ReactComponent as processing } from '@assets/svg-icons/processing.svg'
import { ReactComponent as statusSheduled } from '@assets/svg-icons/schedule-time.svg'
import { ReactComponent as download } from '@assets/svg-icons/download.svg'
import { ReactComponent as search } from '@assets/svg-icons/search.svg'
import { ReactComponent as mobile } from '@assets/svg-icons/mobile.svg'
import { ReactComponent as closeRed } from '@assets/svg-icons/close-red-icon.svg'
import { ReactComponent as errorRed } from '@assets/svg-icons/image2vector.svg'
import { ReactComponent as tickGreen } from '@assets/svg-icons/tick-green.svg'
import { ReactComponent as list } from '@assets/svg-icons/list.svg'
import { ReactComponent as listBordered } from '@assets/svg-icons/list-bordered.svg'
import { ReactComponent as plusIcon } from '@assets/svg-icons/plus-icon.svg'
import { ReactComponent as send } from '@assets/svg-icons/send-message-input.svg'
import { ReactComponent as general } from '@assets/svg-icons/general.svg'
import { ReactComponent as facebookFill } from '@assets/svg-icons/facebook-fill-icon.svg'
import { ReactComponent as syncArrowSearch } from '@assets/svg-icons/synchronize-arrows-search.svg'
import { ReactComponent as placementsViews } from '@assets/svg-icons/placement-views.svg'
import { ReactComponent as placementsSearches } from '@assets/svg-icons/placement-searches.svg'
import { ReactComponent as placementsActions } from '@assets/svg-icons/placement-actions.svg'
import { ReactComponent as placementsCalls } from '@assets/svg-icons/placement-calls.svg'
import { ReactComponent as code } from '@assets/svg-icons/code.svg'
import { ReactComponent as replicate } from '@assets/svg-icons/replicate.svg'
import { ReactComponent as star } from '@assets/svg-icons/review-star.svg'
import { ReactComponent as shareIconRight } from '@assets/svg-icons/share-icon-right.svg'
import { ReactComponent as shareIconLeft } from '@assets/svg-icons/share-icon-left.svg'
import { ReactComponent as starIcon } from '@assets/svg-icons/star-icon.svg'
import { ReactComponent as searchData } from '@assets/svg-icons/search-data.svg'
import { ReactComponent as achievement } from '@assets/svg-icons/achievement.svg'
import { ReactComponent as threeStar } from '@assets/svg-icons/three-star.svg'
import { ReactComponent as trophy } from '@assets/svg-icons/trophy.svg'
import { ReactComponent as linkIcon } from '@assets/svg-icons/link.svg'
import { ReactComponent as Info } from '@assets/svg-icons/info.svg'
import { ReactComponent as clock } from '@assets/svg-icons/data-time.svg'
import { ReactComponent as heartLine } from '@assets/svg-icons/heart-line.svg'
import { ReactComponent as heartFill } from '@assets/svg-icons/heart-fill.svg'
import { ReactComponent as shareableIcon } from '@assets/svg-icons/share-icon.svg'
import { ReactComponent as closeFill } from '@assets/svg-icons/close-fill.svg'
import { ReactComponent as tickRound } from '@assets/svg-icons/tick-round.svg'
import { ReactComponent as user } from '@assets/svg-icons/user.svg'
import { ReactComponent as shop } from '@assets/svg-icons/shop.svg'
import { ReactComponent as suite } from '@assets/svg-icons/suite.svg'
import { ReactComponent as arrowLeft } from '@assets/svg-icons/arrow-left.svg'
import { ReactComponent as helpLine } from '@assets/svg-icons/help-line.svg'
import { ReactComponent as bell } from '@assets/svg-icons/bell.svg'
import { ReactComponent as pcMonitor } from '@assets/svg-icons/pc-monitor.svg'
import { ReactComponent as zoom } from '@assets/svg-icons/zoom.svg'
import { ReactComponent as visit } from '@assets/svg-icons/visit.svg'
import { ReactComponent as resetToDefault } from '@assets/svg-icons/reset-to-default.svg'
import { ReactComponent as totalSession } from '@assets/svg-icons/total-sessions.svg'
import { ReactComponent as avgSession } from '@assets/svg-icons/avr-session-duration.svg'
import { ReactComponent as spinnerDark } from '@assets/svg-icons/spinner-dark.svg'
import { ReactComponent as notification } from '@assets/svg-icons/notification.svg'
import { ReactComponent as feedback } from '@assets/svg-icons/feedback.svg'
import { ReactComponent as getInTouch } from '@assets/svg-icons/getintouch.svg'
import { ReactComponent as hub } from '@assets/svg-icons/hub.svg'
import { ReactComponent as more } from '@assets/svg-icons/more.svg'
import { ReactComponent as assistance } from '@assets/svg-icons/editor.svg'
import { ReactComponent as dragDrop } from '@assets/svg-icons/drag-drop.svg'
import { ReactComponent as launch } from '@assets/svg-icons/launch.svg'
import { ReactComponent as narrowLeftArrow } from '@assets/svg-icons/narrow-left-arrow.svg'
import { ReactComponent as dataSearch } from '@assets/svg-icons/data-search.svg'
import { ReactComponent as arrowDownRoundFill } from '@assets/svg-icons/arrow-down-fill.svg'
import { ReactComponent as singleLine } from '@assets/svg-icons/single-line.svg'
import { ReactComponent as multiLine } from '@assets/svg-icons/multi-line.svg'
import { ReactComponent as paymentTrail } from '@assets/svg-icons/payment-trail.svg'
import { ReactComponent as integration } from '@assets/svg-icons/integration.svg'
import { ReactComponent as nextDay } from '@assets/svg-icons/next-day.svg'
import { ReactComponent as fraud } from '@assets/svg-icons/fraud.svg'
import { ReactComponent as chatFill } from '@assets/svg-icons/chat-fill.svg'
import { ReactComponent as chat } from '@assets/svg-icons/chat.svg'
import { ReactComponent as tick } from '@assets/svg-icons/tick.svg'
import { ReactComponent as listToggle } from '@assets/svg-icons/list-toggle.svg'
import { ReactComponent as sourceBooking } from '@assets/svg-icons/source-booking.svg'
import { ReactComponent as sourceDirect } from '@assets/svg-icons/source-direct.svg'
import { ReactComponent as sourceInvoice } from '@assets/svg-icons/source-invoice.svg'
import { ReactComponent as messengerInvoice } from '@assets/svg-icons/messenger-invoice.svg'
import { ReactComponent as sourceRecurringInvoice } from '@assets/svg-icons/recurring.svg'
import { ReactComponent as sourceWebsite } from '@assets/svg-icons/source-website.svg'
import { ReactComponent as sourceGetPaid } from '@assets/svg-icons/source-get-paid.svg'
import { ReactComponent as listLine } from '@assets/svg-icons/nav-line.svg'
import { ReactComponent as trialReviewSms } from '@assets/svg-icons/trial-sms-icon.svg'
import { ReactComponent as trialReviewhub } from '@assets/svg-icons/trial-reviewhub.svg'
import { ReactComponent as trialSynchronize } from '@assets/svg-icons/trial-synchronize-arrows.svg'
import { ReactComponent as trashThin } from '@assets/svg-icons/trash-thin.svg'
import { ReactComponent as trashEmpty } from '@assets/svg-icons/trash-empty.svg'
import { ReactComponent as calenderFill } from '@assets/svg-icons/appointments.svg'
import { ReactComponent as location } from '@assets/svg-icons/location-icon.svg'
import { ReactComponent as multilocation } from '@assets/svg-icons/multilocation-icon.svg'
import { ReactComponent as settings } from '@assets/svg-icons/settings.svg'
import { ReactComponent as globalPlusIcon } from '@assets/svg-icons/global-plus-icon.svg'
import { ReactComponent as card } from '@assets/svg-icons/card.svg'
import { ReactComponent as getPaidphone } from '@assets/svg-icons/smartphone.svg'
import { ReactComponent as settingsDark } from '@assets/svg-icons/settings-dark.svg'
import { ReactComponent as searchiconStroke } from '@assets/svg-icons/searchicon-stroke.svg'
import { ReactComponent as syncStroke } from '@assets/svg-icons/sync-stroke.svg'
import { ReactComponent as googleStroke } from '@assets/svg-icons/google-stroke.svg'
import { ReactComponent as facebookStroke } from '@assets/svg-icons/facebook-stroke.svg'
import { ReactComponent as textMessageStroke } from '@assets/svg-icons/text-message-stroke.svg'
import { ReactComponent as mobileMessageStroke } from '@assets/svg-icons/mobile-message-stroke.svg'
import { ReactComponent as instantMessageStroke } from '@assets/svg-icons/instant-message-stroke.svg'
import { ReactComponent as MessengerChatStroke } from '@assets/svg-icons/messenger-chat-stroke.svg'
import { ReactComponent as filter } from '@assets/svg-icons/filter.svg'
import { ReactComponent as archive } from '@assets/svg-icons/archive.svg'
import { ReactComponent as starStroke } from '@assets/svg-icons/star-stroke.svg'
import { ReactComponent as smsMessenger } from '@assets/svg-icons/sms-messenger.svg'
import { ReactComponent as chatConverted } from '@assets/svg-icons/chat-converted.svg'
import { ReactComponent as smsChat } from '@assets/svg-icons/sms-chat.svg'
import { ReactComponent as smsFacebook } from '@assets/svg-icons/sms-fb.svg'
import { ReactComponent as smsGmb } from '@assets/svg-icons/sms-gmb.svg'
import { ReactComponent as smsFacebookMessenger } from '@assets/svg-icons/fb_msg.svg'
import { ReactComponent as smsGoogleBusinessMessage } from '@assets/svg-icons/gbm_msg.svg'
import { ReactComponent as profileAvatar } from '@assets/svg-icons/profile-avatar.svg'
import { ReactComponent as extDoc } from '@assets/svg-icons/doc.svg'
import { ReactComponent as extEpub } from '@assets/svg-icons/epub.svg'
import { ReactComponent as extOdt } from '@assets/svg-icons/odt.svg'
import { ReactComponent as extPdf } from '@assets/svg-icons/pdf.svg'
import { ReactComponent as extXlsx } from '@assets/svg-icons/xlsx.svg'
import { ReactComponent as save } from '@assets/svg-icons/save.svg'
import { ReactComponent as playArrow } from '@assets/svg-icons/play-arrow.svg'
import { ReactComponent as playArrowOutline } from '@assets/svg-icons/play-arrow-outline.svg'
import { ReactComponent as playRounded } from '@assets/svg-icons/play-rounded.svg'
import { ReactComponent as targetLink } from '@assets/svg-icons/target-link.svg'
import { ReactComponent as contactCreate } from '@assets/svg-icons/contact-create.svg'
import { ReactComponent as notePencil } from '@assets/svg-icons/note-pencil.svg'
import { ReactComponent as unblock } from '@assets/svg-icons/unblock.svg'
import { ReactComponent as block } from '@assets/svg-icons/block.svg'
import { ReactComponent as contactPayment } from '@assets/svg-icons/icon-payments.svg'
import { ReactComponent as contactReview } from '@assets/svg-icons/icon-review.svg'
import { ReactComponent as contactUser } from '@assets/svg-icons/user-thin-icon.svg'
import { ReactComponent as contactEnvelope } from '@assets/svg-icons/envelope-icon.svg'
import { ReactComponent as contactChat } from '@assets/svg-icons/chat-icon.svg'
import { ReactComponent as contactGplus } from '@assets/svg-icons/gplus.svg'
import { ReactComponent as contactQuickook } from '@assets/svg-icons/quickbooks.svg'
import { ReactComponent as moneyBags } from '@assets/svg-icons/money-bags.svg'
import { ReactComponent as emailBlue } from '@assets/svg-icons/email-blue.svg'
import { ReactComponent as phoneBlue } from '@assets/svg-icons/phone-blue.svg'
import { ReactComponent as trialUsers } from '@assets/svg-icons/trial-users.svg'
import { ReactComponent as overdue } from '@assets/svg-icons/overdue.svg'
import { ReactComponent as due } from '@assets/svg-icons/due.svg'
import { ReactComponent as pushPin } from '@assets/svg-icons/pushpin.svg'
import { ReactComponent as websiteContactus } from '@assets/svg-icons/website_contact_us.svg'
import { ReactComponent as warning } from '@assets/svg-icons/warning.svg'
import { ReactComponent as bookings } from '@assets/svg-icons/bookings.svg'
import { ReactComponent as websiteSubscriber } from '@assets/svg-icons/website-subscriber.svg'
import { ReactComponent as reviewSms } from '@assets/svg-icons/review_sms.svg'
import { ReactComponent as button } from '@assets/svg-icons/button.svg'
import { ReactComponent as contactCreates } from '@assets/svg-icons/contact_create.svg'
import { ReactComponent as contactImport } from '@assets/svg-icons/contact_import.svg'
import { ReactComponent as websitePublish } from '@assets/svg-icons/website-publish.svg'
import { ReactComponent as websiteCreate } from '@assets/svg-icons/website-create.svg'
import { ReactComponent as scheduleReview } from '@assets/svg-icons/schedule-review.svg'
import { ReactComponent as employeeRemove } from '@assets/svg-icons/employee-remove.svg'
import { ReactComponent as fb } from '@assets/svg-icons/fb.svg'
import { ReactComponent as gmb } from '@assets/svg-icons/gmb.svg'
import { ReactComponent as text } from '@assets/svg-icons/text.svg'
import { ReactComponent as assigned } from '@assets/svg-icons/employee-add.svg'
import { ReactComponent as phoneReceiver } from '@assets/svg-icons/phone-receiver.svg'
import { ReactComponent as chatBox } from '@assets/svg-icons/chat-box.svg'
import { ReactComponent as arrowDownThin } from '@assets/svg-icons/arrow-down-thin.svg'
import { ReactComponent as arrowUpThin } from '@assets/svg-icons/arrow-up-thin.svg'
import { ReactComponent as note } from '@assets/svg-icons/note.svg'
import { ReactComponent as removeMinus } from '@assets/svg-icons/remove-minus.svg'
import { ReactComponent as folder } from '@assets/svg-icons/folder.svg'
import { ReactComponent as undo } from '@assets/svg-icons/undo.svg'
import { ReactComponent as mobilePhone } from '@assets/svg-icons/mobile-phone.svg'
import { ReactComponent as hierarchyFiles } from '@assets/svg-icons/hierarchy-files.svg'
import { ReactComponent as synchronizeArrows } from '@assets/svg-icons/synchronize-arrows.svg'
import { ReactComponent as cloudFlash } from '@assets/svg-icons/cloud-flash.svg'
import { ReactComponent as contactBlocked } from '@assets/svg-icons/contact-block.svg'
import { ReactComponent as notAllowed } from '@assets/svg-icons/not-allowed.svg'
import { ReactComponent as contactMessengerChat } from '@assets/svg-icons/chat-conversation.svg'
import { ReactComponent as socialProfile } from '@assets/svg-icons/social-profile-click.svg'
import { ReactComponent as webSlider } from '@assets/svg-icons/ui-webpage-slider.svg'
import { ReactComponent as multipleActions } from '@assets/svg-icons/multiple-actions-subtract.svg'
import { ReactComponent as arrowTop } from '@assets/svg-icons/arrow-top.svg'
import { ReactComponent as arrowRight } from '@assets/svg-icons/arrow-right.svg'
import { ReactComponent as chartUp } from '@assets/svg-icons/chart-up.svg'
import { ReactComponent as emailActionEdit } from '@assets/svg-icons/email-action-edit.svg'
import { ReactComponent as fileCodeEdit } from '@assets/svg-icons/file-code-edit.svg'
import { ReactComponent as clone } from '@assets/svg-icons/clone.svg'
import { ReactComponent as multiTickRound } from '@assets/svg-icons/multi-tick-round.svg'
import { ReactComponent as thumbtack } from '@assets/svg-icons/thumbtack.svg'
import { ReactComponent as caution } from '@assets/svg-icons/caution.svg'
import { ReactComponent as undoThin } from '@assets/svg-icons/undo-thin.svg'
import { ReactComponent as multiUsers } from '@assets/svg-icons/contact-status.svg'
import { ReactComponent as lastWeekStatus } from '@assets/svg-icons/last-week-status.svg'
import { ReactComponent as desktopSearch } from '@assets/svg-icons/desktop-search.svg'
import { ReactComponent as phoneSearch } from '@assets/svg-icons/phone-search.svg'
import { ReactComponent as salesMoney } from '@assets/svg-icons/paid-in-last-month.svg'
import { ReactComponent as dots } from '@assets/svg-icons/dots.svg'
import { ReactComponent as globe } from '@assets/svg-icons/globe-icon.svg'
import { ReactComponent as reviewMessage } from '@assets/svg-icons/reviews-count.svg'
import { ReactComponent as placementActive } from '@assets/svg-icons/placement-active.svg'
import { ReactComponent as lock } from '@assets/svg-icons/lock.svg'
import { ReactComponent as unlock } from '@assets/svg-icons/unlock.svg'
import { ReactComponent as ratingStarWinner } from '@assets/svg-icons/rating-star-winner.svg'
import { ReactComponent as editIcon } from '@assets/svg-icons/edit-icon-white.svg'
import { ReactComponent as deliveryTruck } from '@assets/svg-icons/delivery-truck.svg'
import { ReactComponent as placementMarker } from '@assets/svg-icons/placement-marker.svg'
import { ReactComponent as mail } from '@assets/svg-icons/mail.svg'
import { ReactComponent as personAdd } from '@assets/svg-icons/person-add.svg'
import { ReactComponent as bookAddress } from '@assets/svg-icons/book-address.svg'
import { ReactComponent as syncing } from '@assets/svg-icons/syncing.svg'
import { ReactComponent as payment2 } from '@assets/svg-icons/payment2.svg'
import { ReactComponent as employeeAddPrimary } from '@assets/svg-icons/employee-add-primary.svg'
import { ReactComponent as plug } from '@assets/svg-icons/plug.svg'
import { ReactComponent as quickBookDisconnect } from '@assets/svg-icons/quick-book-disconnect.svg'
import { ReactComponent as telephone } from '@assets/svg-icons/telephone.svg'
import { ReactComponent as reload } from '@assets/svg-icons/reload.svg'
import { ReactComponent as userBlock } from '@assets/svg-icons/user-block.svg'
import { ReactComponent as crossThin } from '@assets/svg-icons/cross-thin.svg'
import { ReactComponent as googleStrokeDark } from '@assets/svg-icons/google-stroke-dark.svg'
import { ReactComponent as paymentsActivePrimary } from '@assets/svg-icons/payments-active-primary.svg'
import { ReactComponent as phoneDevice } from '@assets/svg-icons/phone-device.svg'
import { ReactComponent as present } from '@assets/svg-icons/present.svg'
import { ReactComponent as food } from '@assets/svg-icons/food.svg'
import { ReactComponent as error } from '@assets/svg-icons/error.svg'
import { ReactComponent as checkTick } from '@assets/svg-icons/check-tick.svg'
import { ReactComponent as check } from '@assets/svg-icons/check.svg'
import { ReactComponent as closeCross } from '@assets/svg-icons/close-cross.svg'
import { ReactComponent as bookingNormal } from '@assets/svg-icons/sidemenu-icons/booking.svg'
import { ReactComponent as bookingActive } from '@assets/svg-icons/sidemenu-icons/booking-active.svg'
import { ReactComponent as contactNormal } from '@assets/svg-icons/sidemenu-icons/contact.svg'
import { ReactComponent as contactActive } from '@assets/svg-icons/sidemenu-icons/contact-active.svg'
import { ReactComponent as homeNormal } from '@assets/svg-icons/sidemenu-icons/home.svg'
import { ReactComponent as homeActive } from '@assets/svg-icons/sidemenu-icons/home-active.svg'
import { ReactComponent as menuMessengerNormal } from '@assets/svg-icons/sidemenu-icons/messenger.svg'
import { ReactComponent as menuMessengerActive } from '@assets/svg-icons/sidemenu-icons/messenger-active.svg'
import { ReactComponent as paymentsNormal } from '@assets/svg-icons/sidemenu-icons/payments.svg'
import { ReactComponent as paymentsActive } from '@assets/svg-icons/sidemenu-icons/payments-active.svg'
import { ReactComponent as menuPlacementNormal } from '@assets/svg-icons/sidemenu-icons/placement.svg'
import { ReactComponent as menuPlacementActive } from '@assets/svg-icons/sidemenu-icons/placement-active.svg'
import { ReactComponent as reviewNormal } from '@assets/svg-icons/sidemenu-icons/review.svg'
import { ReactComponent as reviewActive } from '@assets/svg-icons/sidemenu-icons/review-active.svg'
import { ReactComponent as sitesNormal } from '@assets/svg-icons/sidemenu-icons/sites.svg'
import { ReactComponent as sitesActive } from '@assets/svg-icons/sidemenu-icons/sites-active.svg'
import { ReactComponent as menuEngagementNormal } from '@assets/svg-icons/sidemenu-icons/engagement.svg'
import { ReactComponent as menuEngagementActive } from '@assets/svg-icons/sidemenu-icons/engagement-active.svg'
import { ReactComponent as businessInfo } from '@assets/svg-icons/business-info.svg'
import { ReactComponent as textPage } from '@assets/svg-icons/text-page.svg'
import { ReactComponent as key } from '@assets/svg-icons/key.svg'
import { ReactComponent as arrowLeft2 } from '@assets/svg-icons/arrow-left2.svg'
import { ReactComponent as locationPointer } from '@assets/svg-icons/location-pointer.svg'
import { ReactComponent as unit } from '@assets/svg-icons/unit.svg'
import { ReactComponent as calendar } from '@assets/svg-icons/calendar.svg'
import { ReactComponent as pen } from '@assets/svg-icons/pen.svg'
import { ReactComponent as spinnerBlue } from '@assets/svg-icons/spinner-blue.svg'
import { ReactComponent as tickRoundborder } from '@assets/svg-icons/tick-round-border.svg'
import { ReactComponent as greenCaret } from '@assets/svg-icons/green-arrow-up.svg'
import { ReactComponent as redCaret } from '@assets/svg-icons/red-arrow-down.svg'
import { ReactComponent as hamburgerMenu } from '@assets/svg-icons/hamburger-menu.svg'
import { ReactComponent as editPencil } from '@assets/svg-icons/edit-pencil.svg'
import { ReactComponent as removeBig } from '@assets/svg-icons/removeBig.svg'
import { ReactComponent as customFields } from '@assets/svg-icons/customFields.svg'
import { ReactComponent as dragIcon } from '@assets/svg-icons/drag-icon.svg'
import { ReactComponent as newUser } from '@assets/svg-icons/icon-user-new.svg'
import { ReactComponent as sites } from '@assets/svg-icons/mywebsites.svg'
import { ReactComponent as review } from '@assets/svg-icons/review.svg'
import { ReactComponent as transaction } from '@assets/svg-icons/transactions-ico.svg'
import { ReactComponent as engagementMsg } from '@assets/svg-icons/engagement_msg.svg'
import { ReactComponent as userLogin } from '@assets/svg-icons/user-login-icon.svg'
import { ReactComponent as foodPlate } from '@assets/svg-icons/food-plate.svg'
import { ReactComponent as dollar } from '@assets/svg-icons/dollar.svg'
import { ReactComponent as dollarThin } from '@assets/svg-icons/dollar-thin.svg'
import { ReactComponent as linkChain } from '@assets/svg-icons/link-chain.svg'
import { ReactComponent as building } from '@assets/svg-icons/building.svg'
import { ReactComponent as clock2 } from '@assets/svg-icons/clock.svg'
import { ReactComponent as quickbookWithGreenTick } from '@assets/svg-icons/quickbook-with-green-tick.svg'
import { ReactComponent as googleWithGreenTick } from '@assets/svg-icons/google-with-green-tick.svg'
import { ReactComponent as outlookWithGreenTick } from '@assets/svg-icons/outlook-with-green-tick.svg'
import { ReactComponent as appWindowSearch } from '@assets/svg-icons/app-window-search.svg'
import { ReactComponent as cursorDoubleClick } from '@assets/svg-icons/cursor-double-click.svg'
import { ReactComponent as multipleUsers } from '@assets/svg-icons/multiple-users.svg'
import { ReactComponent as phoneActionsRinging } from '@assets/svg-icons/phone-actions-ringing.svg'
import { ReactComponent as grid } from '@assets/svg-icons/grid.svg'
import { ReactComponent as bookingCreated } from '@assets/svg-icons/booking-created.svg'
import { ReactComponent as paymentReceived } from '@assets/svg-icons/payment-received.svg'
import { ReactComponent as bookingReschedule } from '@assets/svg-icons/booking_rescheduled.svg'
import { ReactComponent as bookingRejected } from '@assets/svg-icons/booking-rejected.svg'
import { ReactComponent as calendarDate } from '@assets/svg-icons/calendar-date.svg'
import { ReactComponent as confirmTick } from '@assets/svg-icons/confirm-tick.svg'
import { ReactComponent as mapLocation } from '@assets/svg-icons/map-location.svg'
import { ReactComponent as hoursGlass } from '@assets/svg-icons/hours-glass.svg'
import { ReactComponent as syncRightArrow } from '@assets/svg-icons/sync-right-arrow.svg'
import { ReactComponent as paymentCancel } from '@assets/svg-icons/payment-cancelled.svg'
import { ReactComponent as closeStroke } from '@assets/svg-icons/close-stroke.svg'
import { ReactComponent as dollarCoin } from '@assets/svg-icons/dollar-circle.svg'
import { ReactComponent as telephoneThin } from '@assets/svg-icons/telephone-thin.svg'
import { ReactComponent as arrowThinLeft } from '@assets/svg-icons/arrow-thin-left.svg'
import { ReactComponent as arrowThinRight } from '@assets/svg-icons/arrow-thin-right.svg'
import { ReactComponent as arrowExtraThinLeft } from '@assets/svg-icons/arrow-extra-thin-left.svg'
import { ReactComponent as arrowExtraThinRight } from '@assets/svg-icons/arrow-extra-thin-right.svg'
import { ReactComponent as filterStroke } from '@assets/svg-icons/filter-stroke.svg'
import { ReactComponent as calendarWithoutDate } from '@assets/svg-icons/calendar-without-date.svg'
import { ReactComponent as openLink } from '@assets/svg-icons/open-link.svg'
import { ReactComponent as bookingAssigned } from '@assets/svg-icons/booking-assigned.svg'
import { ReactComponent as paymentRequested } from '@assets/svg-icons/payment-request.svg'
import { ReactComponent as rocket } from '@assets/svg-icons/rocket.svg'
import { ReactComponent as palette } from '@assets/svg-icons/palette.svg'
import { ReactComponent as areaMap } from '@assets/svg-icons/area-map.svg'
import { ReactComponent as browser } from '@assets/svg-icons/browser.svg'
import { ReactComponent as primaryDone } from '@assets/svg-icons/primary-done.svg'
import { ReactComponent as progress } from '@assets/svg-icons/progress.svg'
import { ReactComponent as serviceBoard } from '@assets/svg-icons/service-board.svg'
import { ReactComponent as exit } from '@assets/svg-icons/exit.svg'
import { ReactComponent as hourGlass } from '@assets/svg-icons/hourglass.svg'
import { ReactComponent as glassTube } from '@assets/svg-icons/glass-tube.svg'
import { ReactComponent as hammer } from '@assets/svg-icons/hammer.svg'
import { ReactComponent as rocketFlying } from '@assets/svg-icons/rocket-flying.svg'
import { ReactComponent as photoList } from '@assets/svg-icons/photo-list.svg'
import { ReactComponent as heading } from '@assets/svg-icons/heading.svg'
import { ReactComponent as paragraph } from '@assets/svg-icons/paragraph.svg'
import { ReactComponent as rocketPrimary } from '@assets/svg-icons/rocket-primary.svg'
import { ReactComponent as yelp } from '@assets/svg-icons/yelp.svg'
import { ReactComponent as gosite } from '@assets/svg-icons/gosite.svg'
import { ReactComponent as tabletTouch } from '@assets/svg-icons/tablet-touch.svg'
import { ReactComponent as tabletTouch2 } from '@assets/svg-icons/tablet-touch-2.svg'
import { ReactComponent as tabletTouch3 } from '@assets/svg-icons/tablet-touch-3.svg'
import { ReactComponent as accountingInvoiceHand } from '@assets/svg-icons/accounting-invoice-hand.svg'
import { ReactComponent as tickBackgroundBlue } from '@assets/svg-icons/tick-background-blue.svg'
import { ReactComponent as textToPay } from '@assets/svg-icons/text-to-pay.svg'
import { ReactComponent as tickRoundBorderGreen } from '@assets/svg-icons/tick-round-border-green.svg'
import { ReactComponent as editPencilPaper } from '@assets/svg-icons/edit-pencil-paper.svg'
import { ReactComponent as editPencilPaperGray } from '@assets/svg-icons/edit-pencil-paper-gray.svg'
import { ReactComponent as suitcase } from '@assets/svg-icons/suitcase.svg'
import { ReactComponent as heartPrimary } from '@assets/svg-icons/heart.svg'
import { ReactComponent as tipPointer } from '@assets/svg-icons/tip-pointer.svg'
import { ReactComponent as questionMark } from '@assets/svg-icons/question-mark.svg'
import { ReactComponent as calendarFullDate } from '@assets/svg-icons/calendar-full-date.svg'
import { ReactComponent as thunder } from '@assets/svg-icons/thunder.svg'
import { ReactComponent as closePaymentError } from '@assets/svg-icons/close-payment-error.svg'
import { ReactComponent as shopCheck } from '@assets/svg-icons/shop-check.svg'
import { ReactComponent as error4 } from '@assets/svg-icons/error-4.svg'
import { ReactComponent as refundOutline } from '@assets/svg-icons/refund-outline.svg'
import { ReactComponent as toolsScrewdriver } from '@assets/svg-icons/tools-wench-screwdriver.svg'
import { ReactComponent as growthChart } from '@assets/svg-icons/growthChart.svg'
import { ReactComponent as phoneReceiverUpdated } from '@assets/svg-icons/phone-receiver-updated.svg'
import { ReactComponent as editSimple } from '@assets/svg-icons/edit.svg'
import { ReactComponent as calendarPartialDate } from '@assets/svg-icons/calendar-partial-date.svg'
import { ReactComponent as arrowCircleHollow } from '@assets/svg-icons/arrow-circle-hollow.svg'
import { ReactComponent as alarmClock } from '@assets/svg-icons/clock-2.svg'
import { ReactComponent as refresh3 } from '@assets/svg-icons/refresh3.svg'
import { ReactComponent as amountDueUnderscore } from '@assets/svg-icons/amount-due-underscore.svg'
import { ReactComponent as multiCalendar } from '@assets/svg-icons/multi-calendar.svg'
import { ReactComponent as connectToQuickbooks } from '@assets/svg-icons/quickbook_buttons/C2QB_green_btn_med_default.svg'
import { ReactComponent as alert } from '@assets/svg-icons/alert.svg'
import { ReactComponent as changeRequested } from '@assets/svg-icons/change-requested.svg'
import { ReactComponent as estimateAccepted } from '@assets/svg-icons/estimate-accepted.svg'
import { ReactComponent as dotDotDot } from '@assets/svg-icons/dotdotdot.svg'
import { ReactComponent as reply } from '@assets/svg-icons/reply.svg'
import { ReactComponent as copy } from '@assets/svg-icons/copy.svg'
import { ReactComponent as estimatesActive } from '@assets/svg-icons/sidemenu-icons/estimates-active.svg'
import { ReactComponent as estimates } from '@assets/svg-icons/sidemenu-icons/estimates.svg'
import { ReactComponent as remove } from '@assets/svg-icons/remove.svg'
import { ReactComponent as policyActive } from '@assets/svg-icons/policy-active.svg'
import { ReactComponent as policyExpired } from '@assets/svg-icons/policy-expired.svg'
import { ReactComponent as noActivePolicy } from '@assets/svg-icons/no-active-policy.svg'
import { ReactComponent as insuranceActive } from '@assets/svg-icons/insurance-active.svg'
import { ReactComponent as insuranceInactive } from '@assets/svg-icons/insurance-inactive.svg'
import { ReactComponent as addPlus } from '@assets/svg-icons/add-plus.svg'
import { ReactComponent as informationButton } from '@assets/svg-icons/information-button.svg'
import { ReactComponent as wisetackLogoPrimary } from '@assets/svg-icons/wisetack-logo-primary.svg'

const iconTypes = {
  close: close,
  gositeLogo: gositeLogo,
  swiper: swiper,
  food: food,
  online: online,
  edit: edit,
  upload: upload,
  trash: trash,
  paginationArrowRight: paginationArrowRight,
  thumbtackIcon: thumbtackIcon,
  disconnectThumbtackIcon: disconnectThumbtackIcon,
  infoIcon: infoIcon,
  google: google,
  facebook: facebook,
  plusRound: plusRound,
  arrowDown: arrowDown,
  badgeTick: badgeTick,
  photo: photo,
  video: video,
  plusThin: plusThin,
  closeThin: closeThin,
  tickThin: tickThin,
  tickThinNormal: tickThinNormal,
  eyeVisible: eyeVisible,
  eyeInvisible: eyeInvisible,
  blocked: blocked,
  export: exportIcon,
  notificationIcon: notificationIcon,
  minus: minus,
  refresh: refresh,
  restore: restore,
  booking: booking,
  fillPlus: fillPlus,
  greyTick: greyTick,
  import: importIcon,
  importRounded: importRounded,
  spinner: spinner,
  email: email,
  phone: phone,
  schedule: schedule,
  payment: payment,
  paymentActive: paymentActive,
  refund: refund,
  created: created,
  attachment: attachment,
  calender: calender,
  coloredBooking: coloredBooking,
  contact: contact,
  reviews: reviews,
  engagement: engagement,
  engagementActive: engagementActive,
  payments: payments,
  messenger: messenger,
  messengerActive: messengerActive,
  site: site,
  placement: placement,
  add: add,
  statusTick: statusTick,
  statusClose: statusClose,
  statusPending: statusPending,
  statusSheduled: statusSheduled,
  processing: processing,
  list: list,
  listBordered: listBordered,
  plusIcon: plusIcon,
  code: code,
  replicate: replicate,
  star: star,
  shareIconRight: shareIconRight,
  shareIconLeft: shareIconLeft,
  starIcon: starIcon,
  searchData: searchData,
  achievement: achievement,
  threeStar: threeStar,
  trophy: trophy,
  linkIcon: linkIcon,
  mobile: mobile,
  search: search,
  download: download,
  closeRed: closeRed,
  errorRed: errorRed,
  tickGreen: tickGreen,
  Info: Info,
  send: send,
  general: general,
  facebookFill: facebookFill,
  syncArrowSearch: syncArrowSearch,
  placementsViews: placementsViews,
  placementsSearches: placementsSearches,
  placementsActions: placementsActions,
  placementsCalls: placementsCalls,
  clock: clock,
  fillArrowDown: fillArrowDown,
  heartLine: heartLine,
  heartFill: heartFill,
  shareableIcon: shareableIcon,
  closeFill: closeFill,
  tickRound: tickRound,
  user: user,
  shop: shop,
  suite: suite,
  zoom: zoom,
  arrowLeft: arrowLeft,
  helpLine: helpLine,
  bell: bell,
  editFill: editFill,
  visit: visit,
  pcMonitor: pcMonitor,
  resetToDefault: resetToDefault,
  spinnerDark: spinnerDark,
  getInTouch: getInTouch,
  feedback: feedback,
  hub: hub,
  more: more,
  totalSession: totalSession,
  avgSession: avgSession,
  notification: notification,
  dragDrop: dragDrop,
  assistance: assistance,
  launch: launch,
  narrowLeftArrow: narrowLeftArrow,
  dataSearch: dataSearch,
  arrowDownRoundFill: arrowDownRoundFill,
  singleLine: singleLine,
  multiLine: multiLine,
  paymentTrail: paymentTrail,
  integration: integration,
  nextDay: nextDay,
  fraud: fraud,
  chat: chat,
  chatFill: chatFill,
  tick: tick,
  sourceBooking: sourceBooking,
  sourceDirect: sourceDirect,
  sourceInvoice: sourceInvoice,
  messengerInvoice: messengerInvoice,
  sourceRecurringInvoice: sourceRecurringInvoice,
  sourceWebsite: sourceWebsite,
  sourceGetPaid: sourceGetPaid,
  listLine: listLine,
  listToggle: listToggle,
  trialReviewSms: trialReviewSms,
  trialReviewhub: trialReviewhub,
  trialSynchronize: trialSynchronize,
  trashThin: trashThin,
  trashEmpty: trashEmpty,
  calenderFill: calenderFill,
  card: card,
  getPaidphone: getPaidphone,
  settingsDark: settingsDark,
  location: location,
  multilocation: multilocation,
  settings: settings,
  searchiconStroke: searchiconStroke,
  syncStroke: syncStroke,
  googleStroke: googleStroke,
  facebookStroke: facebookStroke,
  textMessageStroke: textMessageStroke,
  mobileMessageStroke: mobileMessageStroke,
  instantMessageStroke: instantMessageStroke,
  MessengerChatStroke: MessengerChatStroke,
  filter: filter,
  archive: archive,
  starStroke: starStroke,
  smsMessenger: smsMessenger,
  chatConverted: chatConverted,
  smsChat: smsChat,
  smsFacebook: smsFacebook,
  smsGmb: smsGmb,
  smsFacebookMessenger: smsFacebookMessenger,
  smsGoogleBusinessMessage: smsGoogleBusinessMessage,
  profileAvatar: profileAvatar,
  extDoc: extDoc,
  extEpub: extEpub,
  extOdt: extOdt,
  extPdf: extPdf,
  extXlsx: extXlsx,
  save: save,
  playArrow: playArrow,
  playArrowOutline: playArrowOutline,
  playRounded: playRounded,
  targetLink: targetLink,
  contactCreate: contactCreate,
  notePencil: notePencil,
  unblock: unblock,
  block: block,
  contactPayment: contactPayment,
  contactReview: contactReview,
  contactUser: contactUser,
  contactEnvelope: contactEnvelope,
  contactChat: contactChat,
  contactGplus: contactGplus,
  contactQuickook: contactQuickook,
  moneyBags: moneyBags,
  emailBlue: emailBlue,
  phoneBlue: phoneBlue,
  trialUsers: trialUsers,
  overdue: overdue,
  pushPin: pushPin,
  due: due,
  websiteContactus: websiteContactus,
  warning: warning,
  bookings: bookings,
  websiteSubscriber: websiteSubscriber,
  reviewSms: reviewSms,
  button: button,
  contactCreates: contactCreates,
  contactImport: contactImport,
  websitePublish: websitePublish,
  websiteCreate: websiteCreate,
  scheduleReview: scheduleReview,
  employeeRemove: employeeRemove,
  fb: fb,
  gmb: gmb,
  text: text,
  assigned: assigned,
  phoneReceiver: phoneReceiver,
  chatBox: chatBox,
  arrowDownThin: arrowDownThin,
  arrowUpThin: arrowUpThin,
  note: note,
  removeMinus: removeMinus,
  postsActive: postsActive,
  reviewMonitorActive: reviewMonitorActive,
  folder: folder,
  undo: undo,
  mobilePhone: mobilePhone,
  hierarchyFiles: hierarchyFiles,
  synchronizeArrows: synchronizeArrows,
  cloudFlash: cloudFlash,
  socialProfile: socialProfile,
  webSlider: webSlider,
  multipleActions: multipleActions,
  arrowTop: arrowTop,
  arrowRight: arrowRight,
  contactBlocked: contactBlocked,
  notAllowed: notAllowed,
  contactMessengerChat: contactMessengerChat,
  chartUp: chartUp,
  emailActionEdit: emailActionEdit,
  fileCodeEdit: fileCodeEdit,
  clone: clone,
  multiTickRound: multiTickRound,
  thumbtack: thumbtack,
  caution: caution,
  undoThin: undoThin,
  multiUsers: multiUsers,
  lastWeekStatus: lastWeekStatus,
  desktopSearch: desktopSearch,
  phoneSearch: phoneSearch,
  salesMoney: salesMoney,
  dots: dots,
  globe: globe,
  reviewMessage: reviewMessage,
  placementActive: placementActive,
  reviewRequestActive: reviewRequestActive,
  lock: lock,
  unlock: unlock,
  ratingStarWinner: ratingStarWinner,
  editIcon: editIcon,
  deliveryTruck: deliveryTruck,
  placementMarker: placementMarker,
  mail: mail,
  personAdd: personAdd,
  bookAddress: bookAddress,
  syncing: syncing,
  payment2: payment2,
  employeeAddPrimary: employeeAddPrimary,
  plug: plug,
  quickBookDisconnect: quickBookDisconnect,
  telephone: telephone,
  reload: reload,
  userBlock: userBlock,
  crossThin: crossThin,
  googleStrokeDark: googleStrokeDark,
  paymentsActivePrimary: paymentsActivePrimary,
  phoneDevice: phoneDevice,
  refresh2: refresh2,
  recurring: recurring,
  recurringGray: recurringGray,
  recurringRed: recurringRed,
  present: present,
  error: error,
  checkTick: checkTick,
  closeCross: closeCross,
  bookingNormal: bookingNormal,
  bookingActive: bookingActive,
  contactNormal: contactNormal,
  contactActive: contactActive,
  homeNormal: homeNormal,
  homeActive: homeActive,
  menuMessengerNormal: menuMessengerNormal,
  menuMessengerActive: menuMessengerActive,
  paymentsNormal: paymentsNormal,
  paymentsActive: paymentsActive,
  menuPlacementNormal: menuPlacementNormal,
  menuPlacementActive: menuPlacementActive,
  reviewNormal: reviewNormal,
  reviewActive: reviewActive,
  sitesNormal: sitesNormal,
  sitesActive: sitesActive,
  menuEngagementNormal: menuEngagementNormal,
  menuEngagementActive: menuEngagementActive,
  businessInfo: businessInfo,
  textPage: textPage,
  key: key,
  arrowLeft2: arrowLeft2,
  locationPointer: locationPointer,
  unit: unit,
  calendar: calendar,
  pen: pen,
  spinnerBlue: spinnerBlue,
  tickRoundborder: tickRoundborder,
  greenCaret: greenCaret,
  redCaret: redCaret,
  editPencil: editPencil,
  removeBig: removeBig,
  customFields: customFields,
  hamburgerMenu: hamburgerMenu,
  dragIcon: dragIcon,
  newUser: newUser,
  sites: sites,
  review: review,
  transaction: transaction,
  engagementMsg: engagementMsg,
  userLogin: userLogin,
  foodPlate: foodPlate,
  dollar: dollar,
  dollarThin: dollarThin,
  linkChain: linkChain,
  building: building,
  clock2: clock2,
  quickbookWithGreenTick: quickbookWithGreenTick,
  googleWithGreenTick: googleWithGreenTick,
  outlookWithGreenTick: outlookWithGreenTick,
  appWindowSearch: appWindowSearch,
  cursorDoubleClick: cursorDoubleClick,
  multipleUsers: multipleUsers,
  phoneActionsRinging: phoneActionsRinging,
  grid: grid,
  bookingCreated: bookingCreated,
  paymentReceived: paymentReceived,
  bookingReschedule: bookingReschedule,
  bookingRejected: bookingRejected,
  calendarDate: calendarDate,
  confirmTick: confirmTick,
  mapLocation: mapLocation,
  hoursGlass: hoursGlass,
  syncRightArrow: syncRightArrow,
  paymentCancel: paymentCancel,
  closeStroke: closeStroke,
  dollarCoin: dollarCoin,
  telephoneThin: telephoneThin,
  arrowThinLeft: arrowThinLeft,
  arrowThinRight: arrowThinRight,
  arrowExtraThinLeft: arrowExtraThinLeft,
  arrowExtraThinRight: arrowExtraThinRight,
  filterStroke: filterStroke,
  calendarWithoutDate: calendarWithoutDate,
  openLink: openLink,
  bookingAssigned: bookingAssigned,
  paymentRequested: paymentRequested,
  rocket: rocket,
  palette: palette,
  areaMap: areaMap,
  browser: browser,
  primaryDone: primaryDone,
  progress: progress,
  serviceBoard: serviceBoard,
  exit: exit,
  hourGlass: hourGlass,
  glassTube: glassTube,
  hammer: hammer,
  rocketFlying: rocketFlying,
  photoList: photoList,
  heading: heading,
  paragraph: paragraph,
  rocketPrimary: rocketPrimary,
  yelp: yelp,
  gosite: gosite,
  tabletTouch: tabletTouch,
  tabletTouch2: tabletTouch2,
  tabletTouch3: tabletTouch3,
  accountingInvoiceHand: accountingInvoiceHand,
  tickBackgroundBlue: tickBackgroundBlue,
  textToPay: textToPay,
  tickRoundBorderGreen: tickRoundBorderGreen,
  editPencilPaper: editPencilPaper,
  editPencilPaperGray: editPencilPaperGray,
  suitcase: suitcase,
  heartPrimary: heartPrimary,
  tipPointer: tipPointer,
  questionMark: questionMark,
  calendarFullDate: calendarFullDate,
  thunder: thunder,
  closePaymentError,
  shopCheck,
  error4,
  refundOutline,
  toolsScrewdriver,
  growthChart,
  ac_messenger: messenger,
  ac_sites: pcMonitor,
  ac_review_request: reviews,
  pg_invoice: card,
  ac_bookings: bookings,
  phoneReceiverUpdated: phoneReceiverUpdated,
  editSimple: editSimple,
  calendarPartialDate: calendarPartialDate,
  arrowCircleHollow: arrowCircleHollow,
  alarmClock: alarmClock,
  refresh3: refresh3,
  amountDueUnderscore: amountDueUnderscore,
  multiCalendar: multiCalendar,
  globalPlusIcon: globalPlusIcon,
  check: check,
  connectToQuickbooks: connectToQuickbooks,
  alert: alert,
  changeRequested: changeRequested,
  estimateAccepted: estimateAccepted,
  dotDotDot: dotDotDot,
  reply: reply,
  copy: copy,
  estimates: estimates,
  estimatesActive: estimatesActive,
  remove: remove,
  policyActive: policyActive,
  policyExpired: policyExpired,
  noActivePolicy: noActivePolicy,
  insuranceActive: insuranceActive,
  insuranceInactive: insuranceInactive,
  addPlus: addPlus,
  informationButton: informationButton,
  wisetackLogoPrimary
}

export type IconNames = keyof typeof iconTypes
export interface IconComponentProps {
  name: IconNames
  [prop: string]: unknown
}
const IconComponent = ({ name, ...props }: IconComponentProps) => {
  const Icon = iconTypes[name]

  if (!Icon) {
    return null
  }

  return <Icon {...props} data-test={`icon-${name}`} />
}

export default IconComponent
