import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'

interface ReactivateSubscriptionResponse {}

export const reactivateSubscription = (): Promise<ReactivateSubscriptionResponse> => {
  return api()
    .post(config.routes.chargebeeSubscriptionReactivate)
    .then((res) => res.data)
    .catch(catchHandler)
}
