import React, { Component } from 'react'
import Popup from '@components/popups/basic-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Icon from '@components/icons'
import style from '../index.module.scss'

import { formatFormData } from '@helpers/utility'
import i18next from 'i18next'
export default class CreateGroupPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      action: '',
      group_name: '',
      submitting: false,
      subject: '',
      txtGroupName: ''
    }
  }

  showPopup(action, item, location) {
    if (action === 'Rename') {
      this.setState({
        open: true,
        action: action,
        group_name: item.group_name,
        group_id: item.group_id,
        txtGroupName: item.txtGroupName
      })
    } else {
      this.setState({
        open: true,
        action: action,
        group_name: '',
        group_id: '',
        txtGroupName: ''
      })
    }

    if (this.props.setOpen) {
      this.props.setOpen(true)
    }

    if (location) {
      this.setState({ location })
    }
  }
  async handleSubmit(values, { resetForm }) {
    const { action } = this.state
    if (this.state.action === 'Rename') {
      values.group_id = this.state.group_id
    }
    this.setState({ submitting: true })
    const newkeys = {
      txtGroupName: 'group_name'
    }
    const payload = formatFormData(values, newkeys)

    await this.props.onSubmit({ values, resetForm, action, payload, location: this.state.location })
    this.setState({ submitting: false })
    this.reset(resetForm)
  }
  handleChange = (e) => {
    this.setState({
      txtGroupName: e.target.value
    })
  }
  handleCancel(values, { resetForm }) {
    this.closeModal(true, resetForm)
    if (this.state.action === 'UNB') {
      this.props.closeCreateGroupPopup()
    }

    if (this.props.setOpen) {
      this.props.setOpen(false)
    }
  }
  closeModal(reset, resetForm) {
    this.setState({ open: false })

    if (this.props.setOpen) {
      this.props.setOpen(false)
    }

    if (reset) {
      this.reset(resetForm)
    }
  }
  reset(resetForm) {
    resetForm({ group_name: '' })
  }
  render() {
    const t = i18next.t
    return (
      <Popup
        open={this.state.open || this.props.open}
        title={this.state.action === 'Rename' ? t('contacts_hub.rename-group') : t('contacts_hub.create-group-0')}
        type="small"
        hideFooter={true}
        overrideForm={true}
        modalBackdropClicked={this.closeModal.bind(this)}>
        <Formik
          initialValues={{
            group_name: this.state.group_name ? this.state.group_name : ''
          }}
          validationSchema={Yup.object().shape({
            group_name: Yup.string().required(t('contacts_hub.required'))
          })}
          enableReinitialize={true}
          onSubmit={this.handleSubmit.bind(this)}
          onReset={this.handleCancel.bind(this)}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form name={this.state.action === 'Rename' ? '#frmRenameGroup' : '#frmAddContactgroup'} className="pt-15">
                <div className="row">
                  <button type="reset" className={'close custom-modal-close-button remove-modal-close'}>
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="label-text pb-0 mb-10">
                        {t('contacts_hub.group-name-0')}
                        <i className="star text-danger">&nbsp;*</i>
                        <ErrorMessage name="group_name" component="span" className="form-error" />
                      </label>
                      <Field
                        name="group_name"
                        placeholder={t('contacts_hub.enter-group-name')}
                        className="form-control form-control-lg"
                        type="text"
                        id={
                          this.state.action === 'Rename' ? 'renameGroup_txtGroupName' : 'addContactgroup_txtGroupName'
                        }
                        value={values.group_name}
                        onChange={(event) => {
                          setFieldValue('group_name', event.target.value)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <footer className="d-block d-flex mt-10">
                  <button
                    type="submit"
                    disabled={this.state.submitting}
                    className={'btn btn-primary btn-xl border-0 flex-grow-1 d-block d-sm-inline-block mr-20'}
                    id={this.state.action === 'Rename' ? 'renameGroup_btnSave' : 'addContactgroup_btnAddGroup'}>
                    {this.state.action === 'Rename' ? t('contacts_hub.save') : t('contacts_hub.add-group')}
                    {this.state.submitting ? (
                      <i className={'btn-icon top-minus-2 ' + style.spinner}>
                        <Icon name="spinner" />
                      </i>
                    ) : null}
                  </button>
                  <button
                    type="reset"
                    id={this.state.action === 'Rename' ? 'renameGroup_btnCancel' : 'addContactgroup_btnCancel'}
                    className={'btn btn-xl flex-grow-0 cancel-btn d-block d-sm-inline-block'}>
                    {t('contacts_hub.cancel-0')}
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}
