import React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import Popup from '@components/popups/basic-modal'
import notification from '@components/notification'
import Icon from '@components/icons'
import styles from '@containers/reviews/index.module.scss'
import { components } from 'react-select'
import { isEnabled, validateEmail, validateMobile } from '@helpers/utility'
import OnClickOutside from '../outsideClickComponent'
import { getReviewShareableLink } from '@app/containers/reviews/service'
import { withTranslation } from 'react-i18next'

class ReviewRequestPopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      preview_msg: false,
      selectedContacts: [],
      required: false,
      reactSelectFocusFlag: false,
      submitSpinner: false,
      copied: false,
      prefillFlag: false,
      searchKey: '',
      selectedLocation: '',
      shareableURL: ''
    }
  }
  componentDidMount = () => {
    const getShareableLink = async () => {
      const { url } = await getReviewShareableLink()
      if (url !== '') {
        this.setState({ shareableURL: url })
      }
    }
    getShareableLink().catch(console.error)
  }

  showPopup(item) {
    this.setState({ open: true, copied: false })
    if (this.props.comment) {
      this.setState({ preview_message: this.props.comment })
    }
    if (item && item.user && item.user._id) {
      let selectedLocation = {}
      if (this.props.locations && this.props.locations.length) {
        this.props.locations.forEach((loc) => {
          if (loc._id === item.user._id) {
            selectedLocation = loc
          }
        })
      }
      this.setState({ selectedLocation: selectedLocation })
    }
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    }).catch((e) => {})
  }
  onCopy() {
    this.setState({ copied: true })
  }
  async handleSubmit() {
    if (this.state.selectedContacts.length !== 0) {
      if (this.state.selectedLocation.length !== 0) {
        await this.setState({ submitSpinner: true })
        let selectedContacts = []
        selectedContacts = this.state.selectedContacts.map((item, index) => {
          return item.label
        })
        this.resolve({ selectedContacts: selectedContacts, selectedLocation: this.state.selectedLocation })
        // await this.props.onReviewRequestSubmit();
      } else {
        notification('error', this.props.t('locations.invalid-location-selected'))
      }
    } else {
      this.setState({ required: true })
    }
  }
  handleCancel() {
    this.setState({
      required: false,
      selectedContacts: [],
      selectedLocation: '',
      submitSpinner: false,
      open: false,
      prefillFlag: false,
      preview_message: '',
      preview_msg: false
    })
    if (this.props.close) {
      this.props.close()
    }
  }

  autoSuggestContacts = async (input) => {
    const data = {
      nopaging: true,
      status: 'active',
      excludeNullGroup: true,
      platform: true,
      search: input || ''
    }
    this.props.products &&
      isEnabled('contact-manager', this.props.products) &&
      (await this.props.autosuggestContacts(data))
    return [
      {
        label: 'Group',
        options:
          this.props.contactGroupList && this.props.contactGroupList.length > 0
            ? this.props.contactGroupList.map((item, index) => this.removeDuplicate(item))
            : []
      },
      {
        label: 'Contacts',
        options: this.props.contactList && this.props.contactList.length > 0 ? this.props.contactList : []
      }
    ]
  }

  showPreview(val) {
    if (this.state.selectedLocation) {
      this.setState({
        preview_msg: val
      })
    } else {
      notification('error', 'Please select a location')
    }
  }
  selectChange = async (newValue, value, actionMeta) => {
    if (value.action === 'remove-value') {
      const filtArr = this.state.selectedContacts.filter((item, index) => index !== value.removedValue.index)
      await this.setState({ selectedContacts: filtArr })
    } else {
      let temp = this.state.selectedContacts ? this.state.selectedContacts : []
      if (newValue && newValue.length !== 0) {
        this.setState({ required: false })
        newValue.forEach((mainItem) => {
          if (mainItem.type === 'group') {
            if (mainItem.value.length !== 0) {
              mainItem.value.forEach((item) => {
                temp = [...temp, { label: item.valueData, value: item.value }]
              })
            }
          }
          if (mainItem.type === 'contact') {
            temp = [...temp, { label: mainItem.valueData, value: mainItem.value }]
          }
          if (mainItem.__isNew__) {
            const mobileTest = validateMobile(mainItem.value)
            const emailTest = validateEmail(mainItem.value)
            if (mainItem.value && !mobileTest && !emailTest) {
              const message = this.props.me.account_settings.twilio.length
                ? this.props.t('reviews.please-enter-a-valid-email-or-mobile')
                : this.props.t('reviews.please-enter-a-valid-email')
              notification('error', message)
            } else {
              temp = [...temp, { label: mainItem.value, value: mainItem.value }]
            }
          }
        })
      }
      this.setState({ selectedContacts: temp })
    }
  }
  async filterOptions(val) {
    const filtArr = this.state.selectedContacts.filter(
      (item, index) => index !== this.state.selectedContacts.indexOf(val)
    )
    await this.setState({ selectedContacts: filtArr })
  }
  customOptions = ({ value, label, subLabel, index }, { context }) => {
    if (context === 'value') {
      return <div key={index}>{value}</div>
    }
    if (context === 'menu') {
      return (
        <React.Fragment>
          <div>{label}</div>
          <div>{subLabel ? `${subLabel}` : ''}</div>
        </React.Fragment>
      )
    }
  }
  removeDuplicate(arr) {
    let tempArr = []
    if (arr.type === 'group') {
      if (arr.value.length > 0) {
        arr.value.forEach((item, index) => {
          if (!tempArr.includes(item)) {
            tempArr = [...tempArr, item]
          }
        })
      }
      arr.value = tempArr
      return arr
    }
  }
  ValueContainer = ({ children, ...props }) => {
    const { getValue } = props
    const length = getValue().length
    const maxToShow = 2
    const displayLength = length - maxToShow
    return (
      <components.ValueContainer {...props}>
        {children}
        <div className="badge-collapse">{`${
          !this.state.reactSelectFocusFlag && displayLength >= 1 ? `${displayLength} more` : ''
        }`}</div>
      </components.ValueContainer>
    )
  }

  focusFunction(val) {
    this.setState({ reactSelectFocusFlag: val })
  }

  createNewVisible(array) {
    if (array.length > 0) {
      if (array[0] && array[0].options.length === 0 && array[1] && array[1].options.length === 0) {
        return true
      } else return false
    } else return false
  }
  componentDidUpdate(prevprops) {
    if (
      this.props.defaultContact &&
      this.props.defaultContact.valueData &&
      this.props.defaultContact.value &&
      !this.state.prefillFlag &&
      this.state.open
    ) {
      this.setState({
        selectedContacts: [{ label: this.props.defaultContact.valueData, value: this.props.defaultContact.value }],
        prefillFlag: true
      })
    }
  }
  async handleSelectedLocation(loc) {
    this.setState({ selectedLocation: loc, copied: false })
    await this.props.fetchEnterpriseReviewSettings(loc._id)
    const { url } = await getReviewShareableLink(loc._id)
    if (url) {
      this.setState({ shareableURL: url })
    }
    this.props.getUserData({ _id: loc._id })
    if (this.props.locationReviewSettings && this.props.locationReviewSettings.comment) {
      this.setState({ preview_message: this.props.locationReviewSettings.comment })
    }
  }
  listitems() {
    // eslint-disable-next-line
    return this.props.locations && this.props.locations.length
      ? this.props.locations
          .filter((data) => {
            if (this.state.searchKey === null) {
              return true
            } else {
              return data.name.toLowerCase().includes(this.state.searchKey.toLowerCase())
            }
          })
          .map((loc, index) => {
            return (
              <li
                onClick={(e) => {
                  e.stopPropagation()
                  this.handleSelectedLocation(loc)
                  this.setState({ showLocationList: false })
                }}
                className="px-15 py-5"
                key={index}>
                <div className="form-group mb-0">
                  <label>
                    <span className="checkbox-icon"></span>
                    {loc.name}
                  </label>
                </div>
              </li>
            )
          })
      : null
  }
  render() {
    const { t } = this.props
    const ValueContainer = this.ValueContainer
    return (
      <Popup
        id="reviews_modalSendReviewRequest"
        open={this.state.open}
        title={t('reviews.new-review-request')}
        type="small"
        overrideForm={true}
        modalBackdropClicked={this.handleCancel.bind(this)}>
        <Formik
          enableReinitialize
          initialValues={{
            mobile: this.state.message,
            tinyUrl: this.state.tinyUrl
          }}
          validationSchema={Yup.object().shape({
            mobile: Yup.string().required('Required')
          })}
          onReset={this.handleCancel.bind(this)}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form>
                <div className="sent-review-request-form-wrapper mt-15">
                  <button
                    type="reset"
                    id="closeIcon"
                    className="close custom-modal-close-button remove-modal-close custom-react-search-select-wrapper">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="form-group review-send custom-form-group-dropdown mb-20">
                    <div className="auto-search-wrapper mb-20">
                      <label className="label-text">
                        {t('reviews.send-to')}
                        {this.state.required ? (
                          <span id="frmSendReviewRequest_errorMessage" className="form-error">
                            {t('common.required')}
                          </span>
                        ) : null}
                      </label>
                      <AsyncCreatableSelect
                        id="frmReviewRequest_recieverContact"
                        isClearable={false}
                        isMulti
                        className={`dropdown-select ${
                          this.state.reactSelectFocusFlag === true ? 'is-focus' : 'is-not-focus'
                        }`}
                        classNamePrefix="dropdownSelect"
                        name="mobile"
                        placeholder={
                          this.props.me &&
                          this.props.me.account_settings.twilio &&
                          this.props.me.account_settings.twilio.length
                            ? t('reviews.search-by-name-email-mobile-number')
                            : t('reviews.search-by-name-email')
                        }
                        onChange={this.selectChange.bind(this)}
                        loadOptions={this.autoSuggestContacts}
                        formatOptionLabel={this.customOptions}
                        value={
                          this.state.selectedContacts
                            ? this.state.selectedContacts.map((item, index) => {
                                return { label: item.label, value: item.value, index: index }
                              })
                            : []
                        }
                        openMenuOnClick={false}
                        onFocus={(e) => this.focusFunction(true)}
                        onBlur={(e) => this.focusFunction(false)}
                        components={{ ValueContainer }}
                        formatCreateLabel={(userInput) => `${userInput}(new)`}
                        isValidNewOption={(inputValue, selectValue, selectOptions) =>
                          this.createNewVisible(selectOptions)
                        }
                      />
                    </div>
                    <div className="auto-search-wrapper mb-20">
                      <label className="label-text">{t('locations.location-uppercase')}</label>
                      <div
                        onClick={() => this.setState({ showLocationList: true })}
                        className={'dropdown select-tag b-manage-dropdown b-location-filter w-100'}>
                        <div
                          className={
                            'dropdown-toggle text-truncate btn btn-md border dropdown-toggle-manage d-flex align-items-center justify-content-between'
                          }
                          data-toggle="dropdown">
                          {this.props.locations && this.props.locations.length ? (
                            <span className="font-family-base text-truncate w-100 text-left">{`${
                              this.state.selectedLocation && this.state.selectedLocation.name
                                ? this.state.selectedLocation.name
                                : t('locations.select-a-location')
                            }`}</span>
                          ) : (
                            <span className="font-family-base text-truncate w-100 text-left">
                              {t('locations.no-locations')}
                            </span>
                          )}
                          <i className="btn-icon ml-10 manage-icon-arrow">
                            <Icon name="arrowDown" />
                          </i>
                        </div>
                        {this.props.locations && this.props.locations.length ? (
                          <>
                            {this.state.showLocationList ? (
                              <OnClickOutside handleClickOutside={() => this.setState({ showLocationList: false })}>
                                <ul
                                  className={`dropdown-menu show height-200 dropdown-menu-manage overflow-y-scrollbar ${
                                    !this.props.page === 'bulkUpdateSelectAccountModal' ? 'dropdown-menu-right' : ''
                                  }`}>
                                  <li className=" p-0 d-flex align-items-center position-relative">
                                    <button
                                      type="button"
                                      className={
                                        'btn btn-icon btn-sm position-absolute d-flex align-items-center right-0 pr-15 top-minus-1'
                                      }>
                                      <Icon name="search" />
                                    </button>
                                    <input
                                      className="form-control border-0 px-15"
                                      type="text"
                                      placeholder={t('common.search-lowercase')}
                                      onChange={(e) => this.setState({ searchKey: e.target.value })}
                                    />
                                  </li>
                                  {this.listitems()}
                                </ul>
                              </OnClickOutside>
                            ) : null}
                          </>
                        ) : null}
                      </div>
                    </div>
                    <div className={`preview-msg mb-20 ${styles['review-preview-msg']}`}>
                      <label className="label-text d-block">{t('common.message-uppercase')}</label>
                      {!this.state.preview_msg && (
                        <button
                          type="button"
                          id="btnReview_preview"
                          className={`btn manageBtn ripple-animate mt-0 ${styles['review-preview-btn']}`}
                          onClick={this.showPreview.bind(this, true)}>
                          {t('reviews.preview-message')}
                        </button>
                      )}
                      {this.state.preview_msg && (
                        <div>
                          <div
                            className={`message-block mt-0 mb-20 ${styles['review-msg-block']}`}
                            dangerouslySetInnerHTML={{ __html: this.state.preview_message }}></div>
                          <button
                            type="button"
                            id="btnReview_hide"
                            onClick={this.showPreview.bind(this, false)}
                            className={`btn manageBtn ripple-animate mt-0 ${styles['review-preview-btn']}`}>
                            {t('reviews.hide-message')}
                          </button>
                        </div>
                      )}
                    </div>

                    <div>
                      {this.props.me ? (
                        this.state.selectedLocation ? (
                          <CopyToClipboard
                            onCopy={this.onCopy.bind(this)}
                            text={this.state.shareableURL !== '' && this.state.shareableURL}>
                            <div className="custom-tooltip d-inline-block">
                              <button
                                id="btnReview_copyLink"
                                type="button"
                                style={{ padding: 0 }}
                                className={`btn mt-0 d-flex ${styles['review-msg-link review-text-link']}`}>
                                <i className="btn-icon mr-5">
                                  <Icon name="linkIcon" />
                                </i>
                                <p className="shareable-link-text" style={{ fontFamily: 'Open Sans Demi Bold' }}>
                                  {this.state.shareableURL}
                                </p>
                                <div className={'custom-tooltip-active pl-30 ml-30 pl-md-0 ml-md-0'}>
                                  <div className="content-block p-0">
                                    <div className="tooltip-description">
                                      {this.state.copied ? (
                                        <p className="font-weight-light">{t('reviews.copied')}</p>
                                      ) : (
                                        <p className="font-weight-light">{t('reviews.copy-link')}</p>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </button>
                            </div>
                          </CopyToClipboard>
                        ) : (
                          <div
                            onClick={() => notification('error', 'Please select a location')}
                            className="custom-tooltip d-inline-block">
                            <button
                              id="btnReview_copyLink"
                              type="button"
                              style={{ padding: 0 }}
                              className={`btn mt-0 d-flex ${styles['review-msg-link review-text-link']}`}>
                              <i className="btn-icon mr-5">
                                <Icon name="linkIcon" />
                              </i>
                              <p className="shareable-link-text" style={{ fontFamily: 'Open Sans Demi Bold' }}>
                                {this.state.shareableURL}
                              </p>
                              <div className={'custom-tooltip-active pl-30 ml-30 pl-md-0 ml-md-0'}>
                                <div className="content-block p-0">
                                  <div className="tooltip-description">
                                    {<p className="font-weight-light">{t('reviews.copy-link')}</p>}
                                  </div>
                                </div>
                              </div>
                            </button>
                          </div>
                        )
                      ) : null}
                    </div>
                  </div>
                  <footer className="d-block d-sm-flex">
                    <button
                      onClick={() => this.handleSubmit()}
                      className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block'}
                      id="frmSendReviewRequest_Send"
                      type="button"
                      disabled={this.state.submitSpinner}>
                      {t('reviews.send-review-request-popup')}
                      {this.state.submitSpinner && (
                        <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                          <Icon name="spinner" />
                        </i>
                      )}
                    </button>
                    <button
                      type="reset"
                      id="rbulkeviews_btnSendReviewRequestClose"
                      className={'btn btn-xl m-0 btn-outline custom-btn-width'}>
                      {t('common.cancel-uppercase')}
                    </button>
                  </footer>
                </div>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}

export default withTranslation(null, { withRef: true })(ReviewRequestPopup)
