import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CirclePicker } from 'react-color'
import Popup from '@components/popups/basic-modal'
import styles from '../index.module.scss'
import Tooltip from '@components/tooltip'
import MediaLibraryPopup from '@containers/media-library/media/media-library'
import InputMask from 'react-input-mask'
import Icons from '@components/icons'
import notification from '@components/notification'
import { slotConversionFunction, generateImageUrl, convertHoursToArray } from '@helpers/utility'
import moment from 'moment'
import OnClickOutside from '@components/outsideClickComponent'
import BusinessHoursModal from './businessHoursModal'
import { segmentEventTrack } from '@helpers/segment'
import config from '@config/index'
import { withTranslation } from 'react-i18next'
import i18n from '@/i18n'

const arrayOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday']

const defaultHours = {
  monday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  },
  tuesday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  },
  wednesday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  },
  thursday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  },
  friday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  },
  saturday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  },
  sunday: {
    slots: [
      {
        time_start: '00:00 AM',
        time_end: '00:00 AM'
      }
    ],
    open: true,
    open_allday: true
  }
}

class TimeInput extends React.Component {
  onChange = (event) => {
    const { field } = this.props
    this.props.handleChange(event.target.value, field)
  }
  render() {
    const { time, disabled, placeholder } = this.props
    let startsWithOne, startsOne
    if (time) {
      startsWithOne = time[0] === '2'
      startsOne = time[0] === '2' && time[1] === '4'
    }
    const mask = [
      /[0-2]/,
      startsWithOne ? /[0-4]/ : /[0-9]/,
      ':',
      startsOne ? /[0-0]/ : /[0-5]/,
      startsOne ? /[0-0]/ : /[0-9]/
    ]
    return (
      <InputMask
        id={this.props.id}
        maskPlaceholder="0"
        disabled={disabled}
        className={`form-control form-control-lg border-0 px-0 text-secondary ${styles['add-reminder-time-field']}`}
        placeholder={placeholder}
        mask={mask}
        value={time}
        onChange={this.onChange}
      />
    )
  }
}

const getPrepTimes = () => {
  const prepTimes = [
    { value: 0, label: i18n.t('common.none') },
    { value: 5, label: i18n.t('common.n-minutes', { count: 5 }) },
    { value: 10, label: i18n.t('common.n-minutes', { count: 10 }) },
    { value: 15, label: i18n.t('common.n-minutes', { count: 15 }) },
    { value: 20, label: i18n.t('common.n-minutes', { count: 20 }) },
    { value: 25, label: i18n.t('common.n-minutes', { count: 25 }) },
    { value: 30, label: i18n.t('common.n-minutes', { count: 30 }) },
    { value: 45, label: i18n.t('common.n-minutes', { count: 45 }) },
    { value: 60, label: i18n.t('common.n-minutes', { count: 60 }) }
  ]

  return prepTimes
}

const getDateRanges = () => {
  const dateRanges = [
    { value: 15, label: i18n.t('common.n-days-dynamic', { count: 15 }) },
    { value: 30, label: i18n.t('common.n-days-dynamic', { count: 30 }) },
    { value: 60, label: i18n.t('common.n-days-dynamic', { count: 60 }) },
    { value: 90, label: i18n.t('common.n-days-dynamic', { count: 90 }) },
    { value: 100, label: i18n.t('common.n-days-dynamic', { count: 100 }) }
  ]

  return dateRanges
}

const getStartTimeIncrements = () => {
  const startTimeIncrements = [
    { value: 15, label: i18n.t('common.n-minutes', { count: 15 }) },
    { value: 30, label: i18n.t('common.n-minutes', { count: 30 }) },
    { value: 60, label: i18n.t('common.n-minutes', { count: 60 }) }
  ]

  return startTimeIncrements
}

const getAmountDueTypes = () => {
  const amountDueTypes = [
    { value: 'full', label: i18n.t('services.full-payment') },
    { value: 'partial', label: i18n.t('services.deposit-partial-payment') }
  ]

  return amountDueTypes
}
const amountTypes = [
  { value: 'amount', label: '$' },
  { value: 'percentage', label: '%' }
]

class AddServicePopUp extends React.Component {
  t = this.props.t
  serviceLocations = [
    { label: this.props.t('services.my-business-location'), value: 'business_address', id: 'business_address' },
    { label: this.props.t('services.customers-address'), value: 'customer_address', id: 'customer_address' }
  ]
  constructor(props) {
    super(props)
    this.state = {
      popUPOpen: false,
      imageModalOpen: false,
      service_image: '',
      reminder_image: '',
      businessHours: {},
      selectedItem: {},
      serviceUser: '',
      type: '',
      submitting: false,
      slotError: '',
      reenitialiseStatus: true,
      showColorPicker: false,
      bookingStatus: false,
      enlargeImage: false,
      textAreaInitialHeight: '44px',
      searchKey: '',
      selectedLocation: ''
    }
    this.sortArrayFunction = this.sortArrayFunction.bind(this)
  }
  sortArrayFunction(bussHrs) {
    const newObj = {}
    arrayOrder.forEach((item) => (newObj[item] = bussHrs[item]))
    return JSON.parse(JSON.stringify(newObj))
  }
  async showPopup(item, type) {
    this.setState({ popUPOpen: true, type: type })
    if (item) {
      await this.setState({ dataFetching: true })
      await this.props.getServiceUserFunction(item._id)
      this.setState({
        serviceUser: this.props.serviceUSer && this.props.serviceUSer.user_id,
        service_image: item.service_image ? item.service_image : '',
        reminder_image: item.reminder_image ? item.reminder_image : '',
        dataFetching: false
      })
      let currentLocation
      if (this.props.enterpriselocations && this.props.editService) {
        this.props.enterpriselocations.forEach((loc) => {
          if (loc.customerData._id === this.props.editService.owner._id) {
            currentLocation = loc
          }
        })
      }
      this.setState({ bookingStatus: false, paymentStatus: false })
      currentLocation &&
        currentLocation.products &&
        currentLocation.products.length &&
        currentLocation.products.forEach((item) => {
          if (item === 'booking') {
            this.setState({ bookingStatus: true })
          }
          if (item === 'payment') {
            this.setState({ paymentStatus: true })
          }
        })
      if (currentLocation) {
        if (currentLocation.location_identifier && currentLocation.city) {
          currentLocation.name = currentLocation.location_identifier + ' ' + currentLocation.city
        } else if (currentLocation.city) currentLocation.name = currentLocation.city
        else if (currentLocation.location_identifier) currentLocation.name = currentLocation.location_identifier
        else if (currentLocation.locationName) currentLocation.name = currentLocation.locationName
        else currentLocation.name = currentLocation.displayname
        const locat = []
        locat.push({
          _id: currentLocation.customer,
          name: currentLocation.name,
          products: currentLocation.products,
          customerData: currentLocation.customerData
        })
        await this.setState({ selectedLocation: locat[0] })
      }
      await this.props.getPartnerStaff(currentLocation.customer)
      await this.props.getPartnerAccountSettings(currentLocation.customer)
      await this.setState({
        selectedItem: this.props.editService,
        businessHours: slotConversionFunction(this.sortArrayFunction(this.props.editService.business_hours))
      })
    }
  }
  async closeModal(reset, resetForm) {
    await this.setState({ popUPOpen: false, submitting: false })
    await this.props.changeshowAddService()
    if (reset) this.reset(resetForm)
  }
  async stopSpinner() {
    await this.setState({ submitting: false })
  }
  reset(resetForm) {
    resetForm({
      title: '',
      duration: '',
      descripton: '',
      block_off: false,
      block_off_time: '',
      appointment_reminder_flag: true,
      appointment_reminder_time_new: [{ hour: '00', min: '00' }],
      appointment_calendar_color: '',
      notify_owner: true,
      notify_owner_email: true,
      notify_owner_sms: false,
      price: '',
      minimum_payment_type: '',
      minimum_payment_amount: '',
      minimum_payment_percentage: '',
      free_consultation: false,
      is_business_hours: true,
      business_hours:
        this.props.partnerBusiness && this.props.partnerBusiness.hours
          ? convertHoursToArray(this.props.partnerBusiness.hours)
          : defaultHours,
      user_id: '',
      payment: true
    })
    this.setState({
      service_image: '',
      reminder_image: '',
      selectedItem: {},
      slotError: '',
      serviceUser: '',
      reenitialiseStatus: true
    })
  }
  bussinessHoursFormattingFN(busshr) {
    for (const key in busshr) {
      busshr[key].slots &&
        // eslint-disable-next-line
        busshr[key].slots.forEach((slotItem, index) => {
          if (slotItem.time_start1) {
            slotItem.time_start = slotItem.time_start1 + ' ' + slotItem.time_start2
          } else {
            slotItem.time_start = 'AM'
          }
          if (slotItem.time_end1) {
            slotItem.time_end = slotItem.time_end1 + ' ' + slotItem.time_end2
          } else {
            slotItem.time_end = 'PM'
          }
          if (moment(slotItem.time_start, 'HH:mm A').isAfter(moment(slotItem.time_end, 'HH:mm A'), 'minutes')) {
            this.setState({ slotError: this.t('settings.invalide-time-slot') })
          }
          if (
            !busshr[key].open_allday &&
            busshr[key].open &&
            (slotItem.time_start === 'AM' ||
              slotItem.time_end === 'PM' ||
              slotItem.time_start1 === '00:00' ||
              slotItem.time_end1 === '00:00')
          ) {
            this.setState({ slotError: this.t('settings.invalide-time-slot') })
          }
        })
    }
    console.log(this.state.slotError, 'entered')
    return busshr
  }
  handleCancel(values, { resetForm }) {
    this.closeModal(true, resetForm)
  }
  async handleSubmit(values, { resetForm }) {
    if (!this.state.selectedLocation) {
      notification('error', this.t('services.invalid-location-selected'))
      return
    }
    await this.setState({ submitting: true, slotError: '' })
    let errorArr = []
    if (values.appointment_reminder_flag) {
      errorArr = values.appointment_reminder_time_new.map((item) => {
        return !(item.hour === '00' && item.min === '00')

        // reminderRegex.test(item.hour)
      })
    } else {
      values.appointment_reminder_time_new = []
    }
    if (errorArr.includes(false)) {
      notification('error', this.t('bookings.invalid-time-slots-found-in-reminder-time'))
      await this.setState({ submitting: false })
    } else {
      let payload = { ...values }
      if (payload.price === '') {
        payload.price = '0'
      }
      let newArr = []
      if (payload.payment) {
        if (
          this.props &&
          this.props.partnerAccountSettings &&
          this.props.partnerAccountSettings.payment &&
          this.props.partnerAccountSettings.payment.merchant_id &&
          this.state.paymentStatus
        ) {
          payload.payment = true
        } else {
          payload.payment = false
        }
      }
      payload.service_image = this.state.service_image ? this.state.service_image : ''
      payload.reminder_image = this.state.service_image ? this.state.reminder_image : ''
      if (this.state.bookingStatus) {
        if (values.appointment_reminder_time_new && values.appointment_reminder_time_new.length !== 0) {
          newArr = values.appointment_reminder_time_new.map((item) => {
            return { ...item, value: Number(item.hour) * 60 + Number(item.min) }
          })
        }
        payload.appointment_reminder_time_new = newArr
        if (payload.minimum_payment_amount || payload.minimum_payment_percentage) {
          payload.allow_partial_payment = true
        } else {
          payload.allow_partial_payment = false
        }
        payload.block_off_time = payload.block_off_time ? payload.block_off_time : '0'
        payload.block_off = true
        payload.notify_owner = payload.notify_owner_email || payload.notify_owner_sms
      }
      if (this.state.type === 'add') {
        if (!this.state.slotError) {
          await this.props.createService(payload, resetForm, this.state.selectedLocation._id)

          const segmentData = {
            event: 'add_service',
            properties: {
              user_id: this.props.me && this.props.me._id,
              role:
                this.props.me &&
                this.props.me.role_id &&
                this.props.me.role_id.role_name &&
                this.props.me.role_id.role_name &&
                this.props.me.role_id.role_name === 'trial'
                  ? 'free-trial'
                  : this.props.me.role_id.role_name,
              location: payload.service_occurs_at,
              collect_payment: payload.payment,
              bookable: payload.isBooking
            }
          }
          segmentEventTrack(segmentData)
        } else {
          notification('error', this.t('settings.invalid-booking-slots'))
          await this.setState({ submitting: false })
        }
      }
      if (this.state.type === 'edit') {
        if (!this.state.slotError) {
          payload = { ...this.state.selectedItem, ...payload, ...{ __v: 0 } }
          payload.created_by = payload.created_by._id
          payload.old_user_id = this.props.serviceUSer.user_id
          payload.selectUser = this.state.selectUser

          await this.props.UpdateService({ _id: payload._id, data: payload }, resetForm)
        } else {
          notification('error', this.t('settings.invalid-booking-slots'))
          await this.setState({ submitting: false })
        }
      }
    }
  }
  durationToMinutes(duration) {
    const arr = duration.split(':')
    const hr = Number(arr[0])
    const min = Number(arr[1])
    return hr * 60 + min
  }
  DurationtoString(item) {
    let hr = Math.floor(item / 60)
    let mn = item % 60
    if (hr.toString().length === 1) {
      hr = `0${hr}`
    }
    if (mn.toString().length === 1) {
      mn = `0${mn}`
    }
    return `${hr}:${mn}`
  }
  handleImageModal(item) {
    if (item === 'SERVICE IMAGE') {
      this.setState({ openMediaLibraryServiceImage: true })
    } else if (item === 'REMINDER IMAGE') {
      this.setState({ openMediaLibraryReminderImage: true })
    }
  }

  async onServiceImageSuccess(mediaitem) {
    this.setState({
      service_image: mediaitem[0].fileUrl,
      openMediaLibraryServiceImage: false
    })
  }
  async onReminderImageSuccess(mediaitem) {
    this.setState({
      reminder_image: mediaitem[0].fileUrl,
      openMediaLibraryReminderImage: false
    })
  }
  async handleHoursChange(businessHours) {
    await this.setState({ reenitialiseStatus: false, businessHours: businessHours })
  }
  async handleTimeChange(setFieldValue, data, field) {
    const newdata = this.durationToMinutes(data)
    const selectedItem = { ...this.state.selectedItem }
    selectedItem.duration = newdata
    await this.setState({ selectedItem: selectedItem })
  }
  async onRemoveReminderTime(setFieldValue, values, index) {
    const keyArray = [...values.appointment_reminder_time_new]
    keyArray.splice(index, 1)
    if (keyArray.length === 0) {
      setFieldValue('appointment_reminder_flag', false)
      const selectedItem = { ...this.state.selectedItem }
      selectedItem.appointment_reminder_time_new = [{ hour: '00', min: '00', value: '00' }]
      selectedItem.appointment_reminder_flag = false
      await this.setState({ selectedItem: selectedItem })
    } else {
      const selectedItem = { ...this.state.selectedItem }
      selectedItem.appointment_reminder_time_new = keyArray
      selectedItem.appointment_reminder_flag = true
      await this.setState({ selectedItem: selectedItem })
    }
  }
  showCustomHoursModal() {
    this.setState({ showBookingHoursModal: true })
  }
  async removeBusinessHours() {
    const selectedItem = { ...this.state.selectedItem }
    if (this.props.partnerBusiness && this.props.partnerBusiness.hours) {
      selectedItem.business_hours = convertHoursToArray(this.props.partnerBusiness.hours)
    } else {
      selectedItem.business_hours = defaultHours
    }
    selectedItem.is_business_hours = true
    await this.setState({ selectedItem: selectedItem })
  }
  closeCustomHoursModal() {
    this.setState({ showBookingHoursModal: false })
  }
  async getBusinessHours(businessHours) {
    const selectedItem = { ...this.state.selectedItem }
    selectedItem.business_hours = await this.bussinessHoursFormattingFN(businessHours)
    selectedItem.is_business_hours = false
    this.setState({ selectedItem: selectedItem, businessHours: businessHours })
  }
  selectValue(setFieldValue, field, value) {
    setFieldValue(field, value)
    const selectedItem = { ...this.state.selectedItem }
    selectedItem[field] = value
    this.setState({ selectedItem: selectedItem })
  }
  handleReminderTimeChange(setFieldValue, fields, index, value) {
    const arr = value.split(':')
    const hr = Number(arr[0])
    const min = Number(arr[1])
    const newdata = { hour: hr.toString(), min: min.toString(), value: this.durationToMinutes(value) }
    const allReminderTimes = fields
    allReminderTimes[index] = newdata
    const selectedItem = { ...this.state.selectedItem }
    selectedItem.appointment_reminder_time_new = allReminderTimes
    this.setState({ selectedItem: selectedItem })
  }
  async setPaymentDueType(setFieldValue, value) {
    const selectedItem = { ...this.state.selectedItem }
    selectedItem.allow_partial_payment = value === 'partial'
    await this.setState({ selectedItem: selectedItem })
  }
  getAssignedUser(userId, optArray) {
    const user = optArray.filter((item) => item.value === userId)
    if (user.length && user[0].label) {
      return user[0].label
    } else {
      return this.t('services.choose-a-user')
    }
  }
  getUserAvatar(userid, optArray) {
    const user = optArray.filter((item) => item.value === userid)
    if (user.length && user[0].profile_picture) {
      return user[0].profile_picture
    } else {
      return 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
    }
  }
  onSelectUser(user) {
    this.setState({ selectUser: user, serviceUser: user.value })
  }
  async selectMinimumAmountType(value) {
    let type = ''
    if (value === 'percentage') {
      type = 'percentage'
    } else if (value === 'amount') {
      type = 'amount'
    }
    const selectedItem = { ...this.state.selectedItem }
    selectedItem.minimum_payment_type = type
    await this.setState({ selectedItem: selectedItem })
  }
  getUserNotifyStatus(values) {
    if (values && values.notify_owner_email && values.notify_owner_sms) {
      return 'Email & SMS'
    } else if (values && values.notify_owner_email && !values.notify_owner_sms) {
      return 'Email'
    } else if (values && !values.notify_owner_email && values.notify_owner_sms) {
      return 'SMS'
    } else if (values && !values.notify_owner_email && !values.notify_owner_sms) {
      return 'No'
    }
    return 'No'
  }
  updateField(field, value) {
    const selectedItem = { ...this.state.selectedItem }
    selectedItem[field] = value
    this.setState({ selectedItem: selectedItem })
  }
  resizeTextArea(e) {
    e.target.style.height = this.state.textAreaInitialHeight
    // Get the computed styles for the element
    const computed = window.getComputedStyle(e.target)

    // Calculate the height
    const height =
      parseInt(computed.getPropertyValue('border-top-width'), 10) +
      e.target.scrollHeight +
      parseInt(computed.getPropertyValue('border-bottom-width'), 10)

    e.target.style.height = `${height}px`
    height > 100 ? this.setState({ textAreaHeightExceed: true }) : this.setState({ textAreaHeightExceed: false })
  }
  async handleSelectedLocation(loc) {
    this.setState({ selectedLocation: loc, dataFetching: true })
    this.setState({ bookingStatus: false, paymentStatus: false })
    ;(await loc) &&
      loc.products &&
      loc.products.length &&
      loc.products.forEach((item) => {
        if (item === 'booking') {
          this.setState({ bookingStatus: true })
        } else if (item === 'payment') {
          this.setState({ paymentStatus: true })
        }
      })
    await this.props.getPartnerStaff(loc._id)
    if (this.state.bookingStatus) {
      await this.props.getPartnerBusiness(loc._id)
      await this.props.getPartnerAccountSettings(loc._id)
    }
    await this.setState({ dataFetching: false })
  }
  listitems() {
    return this.props.locations && this.props.locations.length
      ? this.props.locations
          // eslint-disable-next-line
          .filter((data) => {
            if (this.state.searchKey === null) {
              return data
            } else if (data.name.toLowerCase().includes(this.state.searchKey.toLowerCase())) {
              return data
            }
          })
          .map((loc, index) => {
            return (
              <li
                onClick={(e) => {
                  e.stopPropagation()
                  this.handleSelectedLocation(loc)
                  this.setState({ showLocationList: false })
                }}
                key={loc.name}
                className="px-15 py-5">
                <div className="form-group mb-0">
                  <label>
                    <span className="checkbox-icon"></span>
                    {loc.name}
                  </label>
                </div>
              </li>
            )
          })
      : null
  }
  render() {
    const t = this.t
    const prepTimes = getPrepTimes()
    const dateRanges = getDateRanges()
    const startTimeIncrements = getStartTimeIncrements()
    const amountDueTypes = getAmountDueTypes()
    return (
      <Popup
        open={this.state.popUPOpen}
        title={this.state.type === 'add' ? t('common.add-service') : t('services.edit-service')}
        hideFooter={true}
        type="default"
        overrideForm={true}
        id="modalAddService"
        modalBackdropClicked={this.closeModal.bind(this)}>
        {this.state.openMediaLibraryServiceImage ? (
          <MediaLibraryPopup
            open={this.state.openMediaLibraryServiceImage}
            onSubmit={this.onServiceImageSuccess.bind(this)}
            onCancel={() => this.setState({ openMediaLibraryServiceImage: !this.state.openMediaLibraryServiceImage })}
            id="modalServiceImageMediaLibrary"
            multiple={false}
            type={['image']}
            showShapes={true}
            hideImages={true}
            crop={true}
            fromEnterprise={true}
          />
        ) : null}
        {this.state.openMediaLibraryReminderImage ? (
          <MediaLibraryPopup
            open={this.state.openMediaLibraryReminderImage}
            onSubmit={this.onReminderImageSuccess.bind(this)}
            onCancel={() => this.setState({ openMediaLibraryReminderImage: !this.state.openMediaLibraryReminderImage })}
            id="modalReminderImageMediaLibrary"
            multiple={false}
            type={['image']}
            showShapes={true}
            hideImages={true}
            crop={true}
            fromEnterprise={true}
          />
        ) : null}
        {this.state.bookingStatus ? (
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={true}
            initialValues={{
              title:
                this.state.selectedItem && Object.keys(this.state.selectedItem) !== 0 && this.state.selectedItem.title
                  ? this.state.selectedItem.title
                  : '',
              duration:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.duration
                  ? this.state.selectedItem.duration
                  : '60',
              descripton:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.descripton
                  ? this.state.selectedItem.descripton
                  : '',
              block_off_time:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.block_off_time
                  ? this.state.selectedItem.block_off_time
                  : '0',
              dateRange:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.dateRange
                  ? this.state.selectedItem.dateRange
                  : 30,
              startTimeInc:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.startTimeInc
                  ? this.state.selectedItem.startTimeInc
                  : 15,
              missedFee:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.missedFee
                  ? this.state.selectedItem.missedFee
                  : '',
              // appointment_reminder_flag: this.state.selectedItem && Object.keys(this.state.selectedItem) !== 0 && this.state.selectedItem.appointment_reminder_flag !== (null || undefined) ? this.state.selectedItem.appointment_reminder_flag : false,
              // appointment_reminder_time_new: this.state.selectedItem && Object.keys(this.state.selectedItem) !== 0 && this.state.selectedItem.appointment_reminder_time_new && this.state.selectedItem.appointment_reminder_time_new.length ? this.state.selectedItem.appointment_reminder_time_new : [{ hour: "00", min: "00", value: "00" }],
              appointment_calendar_color:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.appointment_calendar_color
                  ? this.state.selectedItem.appointment_calendar_color
                  : '#cccccc',
              notify_owner:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.notify_owner !== (null || undefined)
                  ? this.state.selectedItem.notify_owner
                  : true,
              notify_owner_email:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.notify_owner_email !== (null || undefined)
                  ? this.state.selectedItem.notify_owner_email
                  : true,
              notify_owner_sms:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.notify_owner_sms !== (null || undefined)
                  ? this.state.selectedItem.notify_owner_sms
                  : false,
              price:
                this.state.selectedItem && Object.keys(this.state.selectedItem) !== 0 && this.state.selectedItem.price
                  ? this.state.selectedItem.price
                  : '',
              minimum_payment_type:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.minimum_payment_type
                  ? this.state.selectedItem.minimum_payment_type
                  : 'percentage',
              minimum_payment_amount:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.minimum_payment_amount
                  ? this.state.selectedItem.minimum_payment_amount
                  : '',
              minimum_payment_percentage:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.minimum_payment_percentage
                  ? this.state.selectedItem.minimum_payment_percentage
                  : '',
              free_consultation:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.free_consultation !== (null || undefined)
                  ? this.state.selectedItem.free_consultation
                  : false,
              is_business_hours:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.is_business_hours !== (null || undefined)
                  ? this.state.selectedItem.is_business_hours
                  : true,
              business_hours:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.business_hours &&
                Object.keys(this.state.selectedItem.business_hours).length !== 0
                  ? this.sortArrayFunction(this.state.selectedItem.business_hours)
                  : this.props.partnerBusiness && this.props.partnerBusiness.hours
                  ? convertHoursToArray(this.props.partnerBusiness.hours)
                  : defaultHours,
              user_id: this.state.serviceUser
                ? this.state.serviceUser
                : this.state.selectedLocation && this.state.selectedLocation._id
                ? this.state.selectedLocation._id
                : '',
              allow_partial_payment:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.allow_partial_payment !== (null || undefined)
                  ? this.state.selectedItem.allow_partial_payment
                  : false,
              payment:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.payment !== (null || undefined)
                  ? this.state.selectedItem.payment
                  : true,
              isBooking:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.isBooking !== (null || undefined)
                  ? this.state.selectedItem.isBooking
                  : false,
              service_occurs_at:
                this.state.selectedItem &&
                Object.keys(this.state.selectedItem) !== 0 &&
                this.state.selectedItem.service_occurs_at
                  ? this.state.selectedItem.service_occurs_at
                  : this.serviceLocations[0].value
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(t('bookings.required')),
              duration: Yup.string()
                .test('duration', t('bookings.required'), (value) => value !== '00:00')
                .required(t('bookings.required'))
                .nullable(),
              descripton: Yup.string().required(t('bookings.required')),
              user_id: Yup.string().when('isBooking', {
                is: true,
                then: Yup.string().required(t('bookings.required'))
              }),
              block_off_time: Yup.string().required(t('bookings.required')),
              // price:Yup.string().required("Required"),
              minimum_payment_percentage: Yup.string().test(
                'minimum_payment_percentage',
                t('bookings.required'),
                function (value) {
                  const minimumPaymentType = this.parent.minimum_payment_type
                  const allowPartialPayment = this.parent.allow_partial_payment
                  if (allowPartialPayment && minimumPaymentType === 'percentage') {
                    return value
                  }
                  return true
                }
              ),
              minimum_payment_amount: Yup.string().test(
                'minimum_payment_amount',
                t('bookings.required'),
                function (value) {
                  const minimumPaymentType = this.parent.minimum_payment_type
                  const allowPartialPayment = this.parent.allow_partial_payment
                  if (allowPartialPayment && minimumPaymentType === 'amount') {
                    return value
                  }
                  return true
                }
              )
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue, resetForm }) => {
              values.minimum_payment_amount = values.price ? values.minimum_payment_amount : ''
              const opt1 =
                this.state.selectedLocation && this.state.selectedLocation.customerData
                  ? {
                      value: this.state.selectedLocation.customerData._id,
                      label: this.state.selectedLocation.customerData.full_name,
                      email: this.state.selectedLocation.customerData.user_email
                    }
                  : {}
              const optArray = this.props.assignedUser
                ? [
                    opt1,
                    ...this.props.assignedUser.map((item, index) => {
                      let name = item.first_name ? item.first_name : ''
                      name += item.last_name ? ` ${item.last_name}` : ''
                      return {
                        value: item._id,
                        label: name,
                        email: item.user_email,
                        profile_picture: item.profile_picture ? item.profile_picture : ''
                      }
                    })
                  ]
                : []
              return (
                <Form id="frm_addService" className={`common-add-service-modal mt-10 ${styles['add-service-modal']}`}>
                  <button type="reset" id="closeIcon" className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true"></span>
                  </button>
                  {this.state.dataFetching ? (
                    <div
                      className={`position-absolute top-0 right-0 z-index-2 bottom-0 left-0 d-flex align-items-center justify-content-center ${styles['form-loader']}`}>
                      <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="loader" />
                    </div>
                  ) : null}
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="form-group">
                        {/* Customer facing details */}
                        <label className="label-text text-uppercase">{t('services.customer-facing-details')}</label>
                        <div className="border rounded mb-15">
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.service-name')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  id="lbl_serviceNameError"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <Field
                                id="frm_addService_txtServiceName"
                                type="text"
                                className={`form-control form-control-lg border-0 text-truncate ${styles['custom-placeholder-color']}`}
                                placeholder={t('services.enter-service-name')}
                                name="title"
                                value={values.title}
                                onChange={(e) => {
                                  const { value } = e.target
                                  setFieldValue('title', value)
                                }}
                                onBlur={(e) => {
                                  const { value } = e.target
                                  this.updateField('title', value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.price')}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <Field
                                id="frm_addService_txtPayemntAmount"
                                type="text"
                                className={'form-control form-control-lg border-0 text-secondary'}
                                placeholder={t('services.service-charge')}
                                name="price"
                                maxLength={config.amountFieldLength}
                                onChange={(e) => {
                                  const { value } = e.target
                                  const regex = /^(0*[0-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                  if (regex.test(value.toString()) || value.length === 0) {
                                    this.updateField('price', value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.location')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="location"
                                  component="span"
                                  id="lbl_locationError"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <div
                                onClick={() => this.setState({ showLocationList: true })}
                                className={'dropdown select-tag b-manage-dropdown b-location-filter w-100'}>
                                <div
                                  className={`dropdown-toggle text-truncate btn btn-md dropdown-toggle-manage d-flex align-items-center justify-content-between ${
                                    this.state.type === 'edit' ? styles['disabled-dropdown'] : ''
                                  }`}
                                  data-toggle="dropdown">
                                  <span className="font-family-base text-truncate w-100 text-left">
                                    {this.props.locations &&
                                    this.props.locations.length &&
                                    this.state.selectedLocation &&
                                    this.state.selectedLocation.name
                                      ? this.state.selectedLocation.name
                                      : this.props.locations &&
                                        this.props.locations.length &&
                                        !this.state.selectedLocation
                                      ? t('services.select-a-location')
                                      : t('settings.no-locations')}
                                  </span>
                                  {this.state.type === 'add' ? (
                                    <i className="btn-icon ml-10 manage-icon-arrow">
                                      <Icons name="arrowDown" />
                                    </i>
                                  ) : null}
                                </div>
                                {this.props.locations && this.props.locations.length ? (
                                  <>
                                    {this.state.showLocationList ? (
                                      <OnClickOutside
                                        handleClickOutside={() => this.setState({ showLocationList: false })}>
                                        <ul
                                          className={`dropdown-menu show height-200 dropdown-menu-manage overflow-y-scrollbar ${
                                            !this.props.page === 'bulkUpdateSelectAccountModal'
                                              ? 'dropdown-menu-right'
                                              : ''
                                          }`}>
                                          <li className=" p-0 d-flex align-items-center position-relative">
                                            <button
                                              type="button"
                                              className={
                                                'btn btn-icon btn-sm position-absolute d-flex align-items-center right-0 pr-15 top-minus-1'
                                              }>
                                              <Icons name="search" />
                                            </button>
                                            <input
                                              className="form-control border-0 px-15"
                                              type="text"
                                              placeholder="Search.."
                                              onChange={(e) => this.setState({ searchKey: e.target.value })}
                                            />
                                          </li>
                                          {this.listitems()}
                                        </ul>
                                      </OnClickOutside>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('activities.description')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="descripton"
                                  id="lbl_serviceDescriptionError"
                                  component="span"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <textarea
                                id="frm_addService_txtServiceDescription"
                                // component="textarea"
                                className={`form-control form-control-lg border-0 pt-10 ${styles['is-resize-none']} ${styles['custom-placeholder-color']}`}
                                placeholder={t('services.enter-service-description')}
                                name="descripton"
                                value={values.descripton}
                                style={{ maxHeight: 100 }}
                                onKeyUp={this.resizeTextArea.bind(this)}
                                onChange={(e) => {
                                  const { value } = e.target
                                  setFieldValue('descripton', value)
                                }}
                                onBlur={(e) => {
                                  const { value } = e.target
                                  this.updateField('descripton', value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.duration')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="duration"
                                  id="lbl_serviceDurationError"
                                  component="span"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']} px-15`}>
                              <TimeInput
                                time={this.DurationtoString(values.duration)}
                                handleChange={this.handleTimeChange.bind(this, setFieldValue)}
                                field="duration"
                                placeholder="00:00"
                                id="frm_addService_txtServiceDuration"
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="mr-10 line-height-normal">{t('services.missed-appt-fee')}</span>
                                <div className="cursor-pointer">
                                  <Tooltip
                                    className="cursor-pointer"
                                    position="top"
                                    message={t('services.set-the-fee-you-charge-for-missed-appointment')}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <Field
                                id="frm_addService_txtMissedAppFee"
                                type="number"
                                className={'form-control form-control-lg border-0 text-secondary'}
                                placeholder={t('services.enter-missed-appt-fee')}
                                name="missedFee"
                                value={values.missedFee}
                                onChange={(e) => {
                                  const { value } = e.target
                                  const regex = /^(0*[1-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                  if (regex.test(value.toString()) || value.length === 0) {
                                    this.updateField('missedFee', value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="line-height-normal mr-10">{t('services.service-occurs-at')}</span>
                              </div>
                            </div>
                            <div
                              className={`${styles['b-card-item-large']} d-flex pl-15 align-items-center justify-content-between position-relative`}>
                              <span className="line-height-normal">
                                {values.service_occurs_at && values.service_occurs_at === 'business_address'
                                  ? t('services.my-business-location')
                                  : values.service_occurs_at === 'customer_address'
                                  ? t('services.customers-address')
                                  : ''}
                              </span>
                              <div
                                onClick={() => this.setState({ showServiceLocationList: true })}
                                className={
                                  'dropdown btn border-0 b-manage-dropdown right-0 left-0 cursor-pointer position-absolute'
                                }
                                id="">
                                <div
                                  className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                  data-toggle="dropdown">
                                  <i className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                    <Icons name="arrowDownThin" />
                                  </i>
                                </div>
                                {this.state.showServiceLocationList ? (
                                  <OnClickOutside
                                    handleClickOutside={() => this.setState({ showServiceLocationList: false })}>
                                    <ul className="dropdown-menu show width-200 dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar">
                                      {this.serviceLocations.map((item, index) => (
                                        <li
                                          key={index}
                                          className="primary-font px-15"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            this.selectValue(setFieldValue, 'service_occurs_at', item.value)
                                            this.setState({ showServiceLocationList: false })
                                          }}>
                                          <span>{item.label}</span>
                                        </li>
                                      ))}
                                    </ul>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center position-relative">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="line-height-normal">{t('services.service-image')}</span>
                              </div>
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between px-15 ${styles['b-card-item-large']}`}>
                              {!this.state.service_image ? (
                                <span className="upload-info">{t('services.none')}</span>
                              ) : (
                                <div className={'d-flex align-items-center '}>
                                  <div className={`d-flex align-items-center ${styles['b-profile-image']}`}>
                                    <div className={'d-flex align-items-center position-relative'}>
                                      <div className={`${styles['service-image']}`}>
                                        <figure className={`rounded-circle ${styles['image-figure']}`}>
                                          <img
                                            className={`rounded-circle ${styles['image-cover']}`}
                                            src={`${
                                              this.state.service_image
                                                ? generateImageUrl(this.state.service_image, 'micro')
                                                : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                            }`}
                                            alt=""
                                          />
                                        </figure>
                                      </div>
                                      <button
                                        type="button"
                                        className={`btn rounded-circle p-0 d-flex align-items-center justify-content-center position-absolute ${styles['btn-trash']}`}>
                                        <i
                                          onClick={() => this.setState({ service_image: '' })}
                                          className={`btn-icon d-flex mt-1 ${styles['image-trash']}`}>
                                          <Icons name="trash" />
                                        </i>
                                      </button>
                                    </div>
                                  </div>
                                  <div className={` ${styles['b-profile-image']}`}>
                                    <div className={`ml-10 ${styles['b-enlarge-image']}`}>
                                      <span type="button" className={'fz-12 primary-font cursor-pointer'}>
                                        {t('services.enlarge-image')}
                                      </span>
                                      <div
                                        className={`position-absolute bg-white shadow-lg rounded ${styles['service-image']} ${styles['enlarge-image']}`}>
                                        <figure className={` ${styles['image-figure']}`}>
                                          <img
                                            className={` ${styles['image-cover']}`}
                                            src={`${
                                              this.state.service_image
                                                ? generateImageUrl(this.state.service_image, 'profile')
                                                : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                            }`}
                                            alt=""
                                          />
                                        </figure>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                              <button
                                className={`btn btn-lg pr-0 border-0 ${styles['image-button-border']}`}
                                id="frm_addService_btnServiceImage"
                                type="button"
                                onClick={() => this.handleImageModal('SERVICE IMAGE')}>
                                <i className={`btn-icon d-flex ${styles['icon-export']}`}>
                                  <Icons name="export" />
                                </i>
                              </button>
                            </div>
                          </div>
                        </div>
                        {/* end Customer facing details */}
                        {/* Bookable by Customers */}
                        <div id="accordion" role="tablist" className="mb-15">
                          <div className="card">
                            <div
                              className={`card-header d-flex align-items-center justify-content-between px-15 py-3 border-0 ${styles['accordion-card-header']}`}
                              role="tab"
                              id="headingBookable">
                              <div className="d-flex align-items-center">
                                <h4 className="mb-0 fz-14 mr-10">{t('services.bookable-by-customers')}</h4>
                                <div className="cursor-pointer position-relative top-minus-1">
                                  <Tooltip
                                    position="top"
                                    message={t('services.allow-customers-to-book-this-service-via-booking')}
                                  />
                                </div>
                              </div>
                              <div className="form-switch form-group mb-0">
                                <button
                                  className="btn pr-0"
                                  type="button"
                                  data-toggle="collapse"
                                  data-target="#collapseBookableByCustomers"
                                  aria-expanded="false"
                                  aria-controls="collapseBookableByCustomers">
                                  <label>
                                    <Field
                                      type="checkbox"
                                      className="form-control form-control-lg"
                                      checked={values.isBooking}
                                      name="isBooking"
                                      onChange={(event) => {
                                        this.updateField('isBooking', event.target.checked)
                                      }}
                                    />
                                    <span className="checkbox-icon "></span>
                                  </label>
                                </button>
                              </div>
                            </div>
                            <div
                              id="collapseBookableByCustomers"
                              className={`collapse  ${values.isBooking ? 'show' : ''}`}
                              role="tabpanel"
                              aria-labelledby="headingBookable">
                              <div className="card-body p-0">
                                <div className="d-flex align-items-center border-top py-5 py-sm-0 position-relative">
                                  <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                    <div className="d-flex align-items-center h-100 px-15">
                                      <span className="line-height-normal">{t('services.prep-time')}</span>
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                                    <span className="line-height-normal">
                                      {values.block_off_time !== '0'
                                        ? `${values.block_off_time} ${t('common.minutes_dynamic', {
                                            count: values.block_off_time
                                          })}`
                                        : t('services.none')}
                                    </span>
                                    <div
                                      onClick={() => this.setState({ showPrepList: true })}
                                      className={
                                        'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                      }
                                      id="">
                                      <div
                                        className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                        data-toggle="dropdown">
                                        <i
                                          className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                          <Icons name="arrowDownThin" />
                                        </i>
                                      </div>
                                      {this.state.showPrepList ? (
                                        <OnClickOutside
                                          handleClickOutside={() => this.setState({ showPrepList: false })}>
                                          <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar">
                                            {prepTimes.map((time, index) => (
                                              <li
                                                key={index}
                                                className="primary-font px-15"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  this.selectValue(setFieldValue, 'block_off_time', time.value)
                                                  this.setState({ showPrepList: false })
                                                }}>
                                                <span>{time.label}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        </OnClickOutside>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center border-top py-5 py-sm-0">
                                  <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                    <div className="d-flex align-items-center h-100 px-15">
                                      <span className="line-height-normal mr-10">{t('services.date-range')}</span>
                                      <div className="cursor-pointer position-relative top-minus-1">
                                        <Tooltip
                                          position="top"
                                          message={t('services.set-number-of-days-a-booking-can-be-made-in-the-future')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center justify-content-between px-15 w-50 position-relative ${styles['card-row-item-height']}`}>
                                    <span className="line-height-normal">
                                      {values.dateRange} {t('common.days_dynamic', { count: values.dateRange })}
                                    </span>
                                    <div
                                      onClick={() => this.setState({ showDateList: true })}
                                      className={
                                        'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                      }
                                      id="">
                                      <div
                                        className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                        data-toggle="dropdown">
                                        <i
                                          className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                          <Icons name="arrowDownThin" />
                                        </i>
                                      </div>
                                      {this.state.showDateList ? (
                                        <OnClickOutside
                                          handleClickOutside={() => this.setState({ showDateList: false })}>
                                          <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar">
                                            {dateRanges.map((date, index) => (
                                              <li
                                                key={index}
                                                className="primary-font px-15"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  this.selectValue(setFieldValue, 'dateRange', date.value)
                                                  this.setState({ showDateList: false })
                                                }}>
                                                <span>{date.label}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        </OnClickOutside>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center border-top py-5 py-sm-0">
                                  <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                    <div className="d-flex align-items-center h-100 px-15">
                                      <span className="line-height-normal mr-10">
                                        {t('services.start-time-increments')}
                                      </span>
                                      <div className="cursor-pointer position-relative top-minus-1">
                                        <Tooltip
                                          position="top"
                                          message={t('services.show-available-start-times-in-specific-increments')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center justify-content-between px-15 w-50 position-relative ${styles['card-row-item-height']}`}>
                                    <span className="line-height-normal">
                                      {values.startTimeInc}{' '}
                                      {t('common.minutes_dynamic', {
                                        count: values.startTimeInc
                                      })}
                                    </span>
                                    <div
                                      onClick={() => this.setState({ showIncrementList: true })}
                                      className={
                                        'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                      }
                                      id="">
                                      <div
                                        className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                        data-toggle="dropdown">
                                        <i
                                          className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                          <Icons name="arrowDownThin" />
                                        </i>
                                      </div>
                                      {this.state.showIncrementList ? (
                                        <OnClickOutside
                                          handleClickOutside={() => this.setState({ showIncrementList: false })}>
                                          <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar">
                                            {startTimeIncrements.map((item, index) => (
                                              <li
                                                key={index}
                                                className="primary-font px-15"
                                                onClick={(e) => {
                                                  e.stopPropagation()
                                                  this.selectValue(setFieldValue, 'startTimeInc', item.value)
                                                  this.setState({ showIncrementList: false })
                                                }}>
                                                <span>{item.label}</span>
                                              </li>
                                            ))}
                                          </ul>
                                        </OnClickOutside>
                                      ) : null}
                                    </div>
                                  </div>
                                </div>
                                <div className="d-flex align-items-center border-top py-5 py-sm-0">
                                  <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                    <div className="d-flex align-items-center h-100 px-15">
                                      <span className="line-height-normal mr-10">
                                        {t('services.custom-booking-hours')}
                                      </span>
                                      <div className="cursor-pointer position-relative top-minus-1">
                                        <Tooltip
                                          position="top"
                                          message={t('services.set-custom-schedule-for-service-availability')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                                    <button
                                      type="button"
                                      className="border-0 bg-white link-color px-0 line-height-normal text-left"
                                      onClick={this.showCustomHoursModal.bind(this)}>
                                      {values.is_business_hours
                                        ? t('services.add-custom-hours')
                                        : t('services.view-edit-custom-hours')}
                                    </button>
                                    {!values.is_business_hours ? (
                                      <Tooltip
                                        type="button"
                                        position="top"
                                        message={t('services.remove-custom-booking-hours')}>
                                        <i
                                          className={`btn-icon d-flex top-minus-1 ml-10 cursor-pointer ${styles['button-remove']}`}
                                          onClick={this.removeBusinessHours.bind(this)}>
                                          <Icons name="trashEmpty" />
                                        </i>
                                      </Tooltip>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <div className="d-flex align-items-start border-top py-5 py-sm-0">
                                                                    <div className={`w-50 d-flex align-items-center ${styles["card-row-item-height"]}`}>
                                                                        <div className="d-flex align-items-center h-100 px-15">
                                                                            <span className="line-height-normal mr-10">Customer Reminder Time</span>
                                                                            <div className="cursor-pointer position-relative top-minus-1">
                                                                                <Tooltip position="top" message="Reminder time" />
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                {/* <div className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles["card-row-item-height"]}`}>
                                                                        {!values.appointment_reminder_flag ?
                                                                            <button type="button" className="border-0 bg-white link-color px-0 line-height-normal text-left"
                                                                                onClick={() => this.updateField("appointment_reminder_flag", true)} >Add reminder time
                                                                            </button>
                                                                            : null}
                                                                        {values.appointment_reminder_flag ?
                                                                            <div className="w-100">
                                                                                {values.appointment_reminder_time_new.length !== 0 ? values.appointment_reminder_time_new.map((item, index) => (
                                                                                    <div key={index} className={`d-flex align-items-center justify-content-between ${styles["b-add-reminder-time"]}`}>
                                                                                        <div className="mr-10 d-flex align-items-center">
                                                                                            <TimeInput
                                                                                                time={this.DurationtoString(item.value)}
                                                                                                handleChange={this.handleReminderTimeChange.bind(this, setFieldValue, values.appointment_reminder_time_new, index)}
                                                                                                placeholder="00:00"
                                                                                                id={`frm_addService_txtReminder_${index}`}
                                                                                            />
                                                                                            <span className="line-height-normal">{index === 0 ? "before appt" : ""}</span>
                                                                                        </div>
                                                                                        <div className={`d-flex align-items-center h-100 ${styles["icon-styles"]}`}  >
                                                                                            <i onClick={(event) => { this.onRemoveReminderTime(setFieldValue, values, index) }} className={`btn-icon d-flex top-minus-1 mr-10 ${styles["button-remove"]}`}>
                                                                                                <Icons name="trashEmpty" />
                                                                                            </i>
                                                                                            {index === values.appointment_reminder_time_new.length - 1 ?
                                                                                                <div className="ml-auto">
                                                                                                    <Tooltip position="bottom" type="button" message="Add appoinment reminder time">
                                                                                                        <i onClick={() => {
                                                                                                            let value = [...values.appointment_reminder_time_new, { hour: "00", min: "00", value: "00" }]
                                                                                                            setFieldValue("appointment_reminder_time_new", (value))
                                                                                                            this.updateField("appointment_reminder_time_new", value)
                                                                                                        }} className={`btn-icon d-flex top-2 ${styles["button-add"]}`}>
                                                                                                            <Icons name="plusThin" />
                                                                                                        </i>
                                                                                                    </Tooltip>
                                                                                                </div> : null}
                                                                                        </div>
                                                                                    </div>
                                                                                )) : null}
                                                                            </div>
                                                                            : null}
                                                                    </div> */}
                                {/* </div> */}
                                <div className="d-flex align-items-center border-top position-relative py-5 py-sm-0">
                                  <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                    <div className="d-flex align-items-center h-100 px-15">
                                      <span className="mr-10 line-height-normal">{t('services.business-logo')}</span>
                                      <div className="cursor-pointer position-relative top-minus-1">
                                        <Tooltip
                                          position="top"
                                          message={t('services.display-your-logo-on-the-email-and-sms-reminders')}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                                    {!this.state.reminder_image ? (
                                      <span className="upload-info">{t('services.none')}</span>
                                    ) : (
                                      <div className={'d-flex align-items-center'}>
                                        <div
                                          className={`d-flex align-items-center position-relative ${styles['b-profile-image']}`}>
                                          <div className={`${styles['service-image']}`}>
                                            <figure className={`rounded-circle ${styles['image-figure']}`}>
                                              <img
                                                className={`rounded-circle ${styles['image-cover']}`}
                                                src={`${
                                                  this.state.reminder_image
                                                    ? generateImageUrl(this.state.reminder_image, 'micro')
                                                    : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                                }`}
                                                alt=""
                                              />
                                            </figure>
                                          </div>
                                          <button
                                            type="button"
                                            className={`btn rounded-circle p-0 d-flex align-items-center justify-content-center position-absolute ${styles['btn-trash']}`}>
                                            <i
                                              onClick={() => this.setState({ reminder_image: '' })}
                                              className={`btn-icon d-flex mt-1 ${styles['image-trash']}`}>
                                              <Icons name="trash" />
                                            </i>
                                          </button>
                                        </div>

                                        <div className={` ${styles['b-profile-image']}`}>
                                          <div className={`ml-10 ${styles['b-enlarge-image']}`}>
                                            <span type="button" className={'fz-12 primary-font cursor-pointer'}>
                                              {t('services.enlarge-image')}
                                            </span>
                                            <div
                                              className={`position-absolute bg-white shadow-lg rounded ${styles['service-image']} ${styles['enlarge-image']}`}>
                                              <figure className={` ${styles['image-figure']}`}>
                                                <img
                                                  className={` ${styles['image-cover']}`}
                                                  src={`${
                                                    this.state.reminder_image
                                                      ? generateImageUrl(this.state.reminder_image, 'profile')
                                                      : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                                  }`}
                                                  alt=""
                                                />
                                              </figure>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    <button
                                      className={`btn btn-lg pr-0 border-0 ${styles['image-button-border']}`}
                                      id="frm_addService_btnReminderImage"
                                      type="button"
                                      onClick={() => this.handleImageModal('REMINDER IMAGE')}>
                                      <i className={`btn-icon ${styles['icon-export']}`}>
                                        <Icons name="export" />
                                      </i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end Bookable by Customers */}
                        {/* Collect Payment at Checkout */}
                        {this.props &&
                        this.props.partnerAccountSettings &&
                        this.props.partnerAccountSettings.payment &&
                        this.props.partnerAccountSettings.payment.merchant_id &&
                        this.state.paymentStatus ? (
                          <div id="collectPaymentAccordion" role="tablist" className="mb-15">
                            <div className="card">
                              <div
                                className={`card-header d-flex align-items-center justify-content-between px-15 py-3 border-0 ${styles['accordion-card-header']}`}
                                role="tab"
                                id="headingCollectPayment">
                                <div className="d-flex align-items-center">
                                  <h4 className="mb-0 fz-14 mr-10">{t('services.collect-payment-at-checkout')}</h4>
                                </div>
                                <div className="form-switch form-group mb-0">
                                  <button
                                    className="btn pr-0"
                                    type="button"
                                    disabled={values.price === '' || (values.price && parseFloat(values.price) === 0)}
                                    data-toggle="collapse"
                                    data-target="#collapseCollectPayment"
                                    aria-expanded="false"
                                    aria-controls="collapseCollectPayment">
                                    <label>
                                      <Field
                                        type="checkbox"
                                        className={'form-control form-control-lg'}
                                        checked={
                                          values.payment &&
                                          values.price &&
                                          parseFloat(values.price) > 0 &&
                                          values.price !== ''
                                        }
                                        name="payment"
                                        onChange={(event) => {
                                          this.updateField('payment', event.target.checked)
                                        }}
                                      />
                                      <span
                                        className={`checkbox-icon ${
                                          values.price === '' || (values.price && parseFloat(values.price) === 0)
                                            ? 'cursor-not-allowed'
                                            : ''
                                        }`}></span>
                                    </label>
                                  </button>
                                </div>
                              </div>
                              <div
                                id="collapseCollectPayment"
                                className={`collapse ${
                                  values.payment &&
                                  values.price &&
                                  parseFloat(values.price) !== 0 &&
                                  values.price !== ''
                                    ? 'show'
                                    : ''
                                }`}
                                role="tabpanel"
                                aria-labelledby="headingCollectPayment">
                                <div className="card-body p-0">
                                  <div className="d-flex align-items-center border-top py-5 py-sm-0 position-relative">
                                    <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                      <div className="d-flex align-items-center h-100 px-15">
                                        <span className="line-height-normal">{t('services.amount-due')}</span>
                                      </div>
                                    </div>
                                    <div
                                      className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                                      <span className="line-height-normal">
                                        {values.allow_partial_payment
                                          ? t('services.deposit-partial-payment')
                                          : t('bookings.full-payment')}
                                      </span>
                                      <div
                                        onClick={() => this.setState({ showDueList: true })}
                                        className={
                                          'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                        }
                                        id="">
                                        <div
                                          className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                          data-toggle="dropdown">
                                          <i
                                            className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                            <Icons name="arrowDownThin" />
                                          </i>
                                        </div>
                                        {this.state.showDueList ? (
                                          <OnClickOutside
                                            handleClickOutside={() => this.setState({ showDueList: false })}>
                                            <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 width-200 overflow-y-scrollbar">
                                              {amountDueTypes.map((type, index) => (
                                                <li
                                                  key={index}
                                                  className="primary-font px-15"
                                                  onClick={(e) => {
                                                    e.stopPropagation()
                                                    this.setPaymentDueType(setFieldValue, type.value)
                                                    this.setState({ showDueList: false })
                                                  }}>
                                                  <span>{type.label}</span>
                                                </li>
                                              ))}
                                            </ul>
                                          </OnClickOutside>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                  {values.allow_partial_payment ? (
                                    <div className="d-flex align-items-center border-top py-5 py-sm-0">
                                      <div
                                        className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                                        <div className="d-block h-100 px-15">
                                          <div className="d-flex align-items-center">
                                            <span className="line-height-normal">
                                              {values.allow_partial_payment
                                                ? t('services.deposit-partial-payment')
                                                : ''}
                                            </span>
                                            <span className="star text-danger position-relative mr-10">*</span>
                                            <div className="cursor-pointer position-relative top-minus-1">
                                              <Tooltip
                                                position="top"
                                                message={
                                                  values.minimum_payment_type === 'percentage' ||
                                                  values.minimum_payment_type === ''
                                                    ? t('services.enter-the-minimum-payment-in-percentage')
                                                    : t(
                                                        'services.allow-your-customers-to-partially-pay-for-a-service-up-front-by-entering-the-amount-or-percentage-here'
                                                      )
                                                }
                                              />
                                            </div>
                                          </div>
                                          <ErrorMessage
                                            name={
                                              values.minimum_payment_type === 'percentage'
                                                ? 'minimum_payment_percentage'
                                                : 'minimum_payment_amount'
                                            }
                                            component="span"
                                            id="lbl_serviceAssignedUser"
                                            className="form-error mt-3 mb-5 ml-0"
                                          />
                                        </div>
                                      </div>
                                      <div
                                        className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                                        {values.minimum_payment_type === 'percentage' ? (
                                          <Field
                                            disabled={!(values.price && values.price > 0)}
                                            id="frm_addService_txtPartialPayemnt"
                                            type="number"
                                            className={`form-control form-control-lg px-0 border-0 bg-white ${styles['field-normal-width']}`}
                                            placeholder="0"
                                            value={values.minimum_payment_percentage}
                                            name="minimum_payment_percentage"
                                            onChange={(e) => {
                                              if (
                                                e.target.value === '' ||
                                                (e.target.value > 0 && e.target.value <= 100)
                                              ) {
                                                this.updateField('minimum_payment_percentage', e.target.value)
                                              }
                                            }}
                                          />
                                        ) : (
                                          <Field
                                            disabled={!(values.price && values.price > 0)}
                                            id="frm_addService_txtPartialPayemnt"
                                            type="number"
                                            className={`form-control form-control-lg px-0 border-0 bg-white ${styles['field-normal-width']}`}
                                            placeholder="0"
                                            value={values.minimum_payment_amount}
                                            name="minimum_payment_amount"
                                            onChange={(e) => {
                                              if (
                                                e.target.value === '' ||
                                                (e.target.value > 0 &&
                                                  parseFloat(e.target.value) <= parseFloat(values.price))
                                              ) {
                                                this.updateField('minimum_payment_amount', e.target.value)
                                              }
                                            }}
                                          />
                                        )}
                                        <div className={'d-flex align-items-center'}>
                                          <span>
                                            {values.minimum_payment_type === 'amount'
                                              ? '$'
                                              : values.minimum_payment_type === 'percentage'
                                              ? '%'
                                              : '$'}
                                          </span>
                                          <div
                                            onClick={() => this.setState({ showPaymentTypeList: true })}
                                            className={'dropdown btn border-0 b-manage-dropdown pr-0 pl-0'}
                                            id="">
                                            <div
                                              className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent"
                                              data-toggle="dropdown">
                                              <i
                                                className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                                <Icons name="arrowDownThin" />
                                              </i>
                                            </div>
                                            {this.state.showPaymentTypeList ? (
                                              <OnClickOutside
                                                handleClickOutside={() =>
                                                  this.setState({ showPaymentTypeList: false })
                                                }>
                                                <ul
                                                  className={`dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar width-50 text-center ${styles['small-dropdown']}`}>
                                                  {amountTypes.map((type, index) => (
                                                    <li
                                                      key={index}
                                                      className="primary-font px-15"
                                                      onClick={(e) => {
                                                        e.stopPropagation()
                                                        this.selectMinimumAmountType(type.value)
                                                        this.setState({ showPaymentTypeList: false })
                                                      }}>
                                                      <span>{type.label}</span>
                                                    </li>
                                                  ))}
                                                </ul>
                                              </OnClickOutside>
                                            ) : null}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="label-text text-uppercase">{t('services.management')}</label>
                          <label className={`label-text text-uppercase fz-12 ${styles['private-label-text']}`}>
                            {t('services.private-to-you')}
                          </label>
                        </div>
                        <div className="border rounded mb-15">
                          <div className="d-flex align-items-center py-5 py-sm-0 position-relative">
                            <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="line-height-normal">{t('services.notify-business-owner')}</span>
                              </div>
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                              <span className="line-height-normal">{this.getUserNotifyStatus(values)}</span>
                              <div
                                onClick={() => this.setState({ showNotifyList: true })}
                                className={
                                  'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                }
                                id="">
                                <div
                                  className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                  data-toggle="dropdown">
                                  <i className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                    <Icons name="arrowDownThin" />
                                  </i>
                                </div>
                                {this.state.showNotifyList ? (
                                  <OnClickOutside handleClickOutside={() => this.setState({ showNotifyList: false })}>
                                    <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar">
                                      <li className="primary-font px-15">
                                        <div className="form-group form-check d-flex m-0">
                                          <label>
                                            <Field
                                              type="checkbox"
                                              className="h-cb"
                                              checked={values.notify_owner_email}
                                              name="notify_owner_email"
                                              onChange={(event) => {
                                                this.updateField('notify_owner_email', event.target.checked)
                                              }}
                                            />
                                            <span id="frm_addService_chkEmail" className="checkbox-icon mr-10"></span>
                                            <span>Email</span>
                                          </label>
                                        </div>
                                      </li>
                                      <li className="primary-font px-15">
                                        <div className="form-group form-check d-flex m-0">
                                          <label>
                                            <Field
                                              type="checkbox"
                                              className="h-cb"
                                              checked={values.notify_owner_sms}
                                              name="notify_owner_sms"
                                              onChange={(event) => {
                                                this.updateField('notify_owner_sms', event.target.checked)
                                              }}
                                            />
                                            <span id="frm_addService_chkSms" className="checkbox-icon mr-10"></span>
                                            <span>SMS</span>
                                          </label>
                                        </div>
                                      </li>
                                    </ul>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-top py-5 py-sm-0 position-relative">
                            <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.assigned-user')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="user_id"
                                  component="span"
                                  id="lbl_serviceAssignedUser"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                              <div className={`d-flex align-items-center ${styles['b-profile-image']}`}>
                                <div className={`mr-10 ${styles['service-image']}`}>
                                  <figure className={`rounded-circle ${styles['image-figure']}`}>
                                    <img
                                      className={`rounded-circle ${styles['image-cover']}`}
                                      src={`${
                                        this.state.selectUser && this.state.selectUser.profile_picture
                                          ? this.state.selectUser.profile_picture
                                          : values.user_id
                                          ? this.getUserAvatar(values.user_id, optArray)
                                          : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                      }`}
                                      alt=""
                                    />
                                  </figure>
                                </div>
                                <span className="line-height-normal">
                                  {this.getAssignedUser(values.user_id, optArray)}
                                </span>
                              </div>
                              <div
                                onClick={() => this.setState({ showStaffList: true })}
                                className={
                                  'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                }
                                id="">
                                <div
                                  className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                  data-toggle="dropdown">
                                  <i className={`btn-icon ml-10 manage-icon-arrow ${styles['icon-dropdown-arrow']}`}>
                                    <Icons name="arrowDownThin" />
                                  </i>
                                </div>
                                {this.state.showStaffList ? (
                                  <OnClickOutside handleClickOutside={() => this.setState({ showStaffList: false })}>
                                    <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 max-width-200 overflow-y-scrollbar">
                                      {optArray.map((user, index) => (
                                        <li
                                          key={index}
                                          className={`primary-font px-15 ${styles['user-dropdown-list-item']}`}
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            this.onSelectUser(user)
                                            this.setState({ showStaffList: false })
                                          }}>
                                          <div className={`d-flex align-items-center ${styles['b-profile-image']}`}>
                                            <div className={`mr-10 ${styles['service-image']}`}>
                                              <figure
                                                className={`rounded-circle d-flex align-items-center ${styles['image-figure']}`}>
                                                <img
                                                  className={`rounded-circle ${styles['image-cover']}`}
                                                  src={`${
                                                    user.profile_picture
                                                      ? user.profile_picture
                                                      : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                                                  }`}
                                                  alt=""
                                                />
                                              </figure>
                                            </div>
                                            <span className={`text-truncate ${styles['list-item-text']}`}>
                                              {user.label}
                                            </span>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-top py-5 py-sm-0">
                            <div className={`w-50 d-flex align-items-center ${styles['card-row-item-height']}`}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="line-height-normal">{t('services.calendar-color')}</span>
                              </div>
                            </div>
                            <div
                              className={`d-flex align-items-center justify-content-between px-15 w-50 ${styles['card-row-item-height']}`}>
                              <div
                                className={`position-relative w-100 ${styles['widget-color-settings']}`}
                                id="frm_addService_sectionCalender">
                                <div
                                  className="d-flex align-items-center"
                                  onClick={() => this.setState({ showColorPicker: true })}>
                                  <div
                                    className={`mr-10 ${styles['widget-color-picker']}`}
                                    style={{ backgroundColor: values.appointment_calendar_color }}></div>
                                  <label className={styles['color-picker-label']}>{t('services.choose-color')}</label>
                                </div>
                                {this.state.showColorPicker ? (
                                  <OnClickOutside handleClickOutside={() => this.setState({ showColorPicker: false })}>
                                    <div className={`position-absolute ${styles['color-picker-popover']}`}>
                                      {/* <div className="position-fixed custom-page-overlay" onClick={() => this.setState({showColorPicker:false})}></div> */}
                                      <div className={`p-10 ${styles['cover']}`}>
                                        <CirclePicker
                                          color={values.appointment_calendar_color}
                                          onChange={(color) => {
                                            this.updateField('appointment_calendar_color', color.hex)
                                          }}
                                        />
                                      </div>
                                    </div>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* end management */}
                      </div>
                    </div>
                  </div>
                  <footer className="d-block d-sm-flex mt-30">
                    <button
                      type="submit"
                      id="frm_addService_btnAdd"
                      className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
                      disabled={!!this.state.submitting}>
                      {`${this.state.type === 'add' ? t('services.add-service') : t('services.update-service')}`}

                      {this.state.submitting ? (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      ) : null}
                    </button>
                    <button
                      type="reset"
                      id="frm_addService_btnCancel"
                      className="btn btn-xl btn-outline m-0 cancel-btn custom-btn-width">
                      {'CANCEL'}
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        ) : (
          <Formik
            validateOnChange={true}
            validateOnBlur={false}
            enableReinitialize={this.state.reenitialiseStatus}
            initialValues={{
              title:
                this.state.selectedItem && Object.keys(this.state.selectedItem) !== 0 && this.state.selectedItem.title
                  ? this.state.selectedItem.title
                  : '',
              price:
                this.state.selectedItem && Object.keys(this.state.selectedItem) !== 0 && this.state.selectedItem.price
                  ? this.state.selectedItem.price
                  : '',
              user_id:
                this.state.selectedLocation && this.state.selectedLocation._id ? this.state.selectedLocation._id : ''
            }}
            validationSchema={Yup.object().shape({
              title: Yup.string().required(t('bookings.required'))
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue, resetForm }) => {
              values.minimum_payment_amount = values.price ? values.minimum_payment_amount : ''
              return (
                <Form id="frm_addService" className={`common-add-service-modal mt-10 ${styles['add-service-modal']}`}>
                  <button type="reset" id="closeIcon" className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true"></span>
                  </button>
                  {this.state.dataFetching ? (
                    <div
                      className={`position-absolute top-0 right-0 z-index-2 bottom-0 left-0 d-flex align-items-center justify-content-center ${styles['form-loader']}`}>
                      <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="loader" />
                    </div>
                  ) : null}
                  <div className="row no-gutters">
                    <div className="col-12">
                      <div className="form-group">
                        {/* Customer facing details */}
                        <label className="label-text text-uppercase">{t('services.customer-facing-details')}</label>
                        <div className="border rounded mb-15">
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 pl-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.service-name')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="title"
                                  component="span"
                                  id="lbl_serviceNameError"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <Field
                                id="frm_addService_txtServiceName"
                                type="text"
                                className="form-control form-control-lg border-0 text-truncate"
                                placeholder={t('services.enter-service-name')}
                                name="title"
                                value={values.title}
                                onChange={(e) => {
                                  const { value } = e.target
                                  setFieldValue('title', value)
                                }}
                                onBlur={(e) => {
                                  const { value } = e.target
                                  this.updateField('title', value)
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('services.price')}</span>
                                </div>
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <Field
                                id="frm_addService_txtPayemntAmount"
                                type="number"
                                className={'form-control form-control-lg border-0'}
                                placeholder={t('services.service-charge')}
                                name="price"
                                onChange={(e) => {
                                  const { value } = e.target
                                  const regex = /^(0*[0-9][0-9]*(\.[0-9]{0,2})?|0*\.[0-9]{0,2})$/
                                  if (regex.test(value.toString()) || value.length === 0) {
                                    this.updateField('price', value)
                                  }
                                }}
                              />
                            </div>
                          </div>
                          <div className="d-flex align-items-center border-bottom">
                            <div className={`${styles['b-card-item-small']}`}>
                              <div className="d-block h-100 px-15">
                                <div className="d-flex align-items-center">
                                  <span className="line-height-normal">{t('settings.location')}</span>
                                  <span className="star text-danger position-relative">*</span>
                                </div>
                                <ErrorMessage
                                  name="location"
                                  component="span"
                                  id="lbl_locationError"
                                  className="form-error mt-3 mb-5 ml-0"
                                />
                              </div>
                            </div>
                            <div className={`${styles['b-card-item-large']}`}>
                              <div className={'dropdown select-tag b-manage-dropdown b-location-filter w-100'}>
                                <div
                                  onClick={() => this.setState({ showLocationList: true })}
                                  className={`dropdown-toggle text-truncate btn btn-md dropdown-toggle-manage d-flex align-items-center justify-content-between ${
                                    this.state.type === 'edit' ? styles['disabled-dropdown'] : ''
                                  }`}
                                  data-toggle="dropdown">
                                  {this.props.locations && this.props.locations.length > 0 ? (
                                    <span className="font-family-base text-truncate w-100 text-left">
                                      {this.props.locations &&
                                      this.props.locations.length &&
                                      this.state.selectedLocation &&
                                      this.state.selectedLocation.name
                                        ? this.state.selectedLocation.name
                                        : t('services.select-a-location')}
                                    </span>
                                  ) : (
                                    <span className="font-family-base text-truncate w-100 text-left">
                                      {t('settings.no-locations')}
                                    </span>
                                  )}
                                  {this.state.type === 'add' ? (
                                    <i className="btn-icon ml-10 manage-icon-arrow">
                                      <Icons name="arrowDown" />
                                    </i>
                                  ) : null}
                                </div>
                                {this.props.locations && this.props.locations.length ? (
                                  <>
                                    {this.state.showLocationList ? (
                                      <OnClickOutside
                                        handleClickOutside={() => this.setState({ showLocationList: false })}>
                                        <ul
                                          className={`dropdown-menu show height-200 dropdown-menu-manage overflow-y-scrollbar ${
                                            !this.props.page === 'bulkUpdateSelectAccountModal'
                                              ? 'dropdown-menu-right'
                                              : ''
                                          }`}>
                                          <li className=" p-0 d-flex align-items-center position-relative">
                                            <button
                                              type="button"
                                              className={
                                                'btn btn-icon btn-sm position-absolute d-flex align-items-center right-0 pr-15 top-minus-1'
                                              }>
                                              <Icons name="search" />
                                            </button>
                                            <input
                                              className="form-control border-0 px-15"
                                              type="text"
                                              placeholder="Search.."
                                              onChange={(e) => this.setState({ searchKey: e.target.value })}
                                            />
                                          </li>
                                          {this.listitems()}
                                        </ul>
                                      </OnClickOutside>
                                    ) : null}
                                  </>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <footer className="d-block d-sm-flex mt-10">
                    <button
                      type="submit"
                      id="frm_addService_btnAdd"
                      className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
                      disabled={!!this.state.submitting}>
                      {`${this.state.type === 'add' ? t('services.add-service') : t('services.update-service')}`}

                      {this.state.submitting ? (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      ) : null}
                    </button>
                    <button
                      type="reset"
                      id="frm_addService_btnCancel"
                      className="btn btn-xl m-0 cancel-btn custom-btn-width">
                      {'CANCEL'}
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        )}
        {this.state.showBookingHoursModal ? (
          <BusinessHoursModal
            open={true}
            title={t('services.custom-booking-hours-0')}
            close={this.closeCustomHoursModal.bind(this)}
            business_hours={
              this.state.selectedItem &&
              Object.keys(this.state.selectedItem) !== 0 &&
              this.state.selectedItem.business_hours &&
              Object.keys(this.state.selectedItem.business_hours).length !== 0
                ? this.sortArrayFunction(this.state.selectedItem.business_hours)
                : this.props.partnerBusiness && this.props.partnerBusiness.hours
                ? convertHoursToArray(this.props.partnerBusiness.hours)
                : JSON.parse(JSON.stringify(defaultHours))
            }
            submitHours={this.getBusinessHours.bind(this)}
          />
        ) : null}
      </Popup>
    )
  }
}
export default withTranslation(null, { withRef: true })(AddServicePopUp)
