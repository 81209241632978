import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

type WisetackPromotionalMessageProps = {
  monthlyAmount: number
  isSingleLineLayout?: boolean
  apr: number
  termLength: number
  totalAmount: number
}

export const WisetackPromotionalMessage = ({
  monthlyAmount,
  isSingleLineLayout = false,
  apr,
  termLength,
  totalAmount
}: WisetackPromotionalMessageProps): JSX.Element => {
  const disclosureLink = `https://www.wisetack.com/disclosure?monthlyPayment=${monthlyAmount}&apr=${apr}&termLength=${termLength}&totalAmount=${totalAmount}`

  return (
    <Box flexGrow={0} flexShrink={0} flexBasis="100%">
      <Box display="flex" justifyContent="flex-end" mt={isSingleLineLayout ? 0 : 1}>
        <Box display="flex" flexDirection={isSingleLineLayout ? 'row' : 'column'}>
          <Typography component="div" variant="caption">
            Or as low as{' '}
            <Typography component="span" fontWeight="bold" variant="caption">
              ${monthlyAmount}/month {isSingleLineLayout && 'with Wisetack*'}
            </Typography>
          </Typography>
          <Typography component="div" variant="caption">
            {!isSingleLineLayout && 'with Wisetack*'}
            <a href={disclosureLink} target="_blank" rel="noopener noreferrer">
              <Box
                sx={{
                  color: '#0357FF',
                  cursor: 'pointer',
                  textDecoration: 'underline'
                }}
                className="td-label">
                Learn More
              </Box>
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}
