import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="/allservices"
    path={'/:userId/services'}
    layoutSettings={{ page: 'services' }}
    component={asyncComponent(() => import('./allservices/services'))}
  />
]
