import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import i18n from '@/i18n'

export default {
  state: {
    apiError: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    getPartnerServicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        partnerServices: data.data
      }
    },
    onCreatePartnerServiceSuccess(state, data) {
      notification('success', i18n.t('services.service-created-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    onUpdateServiceSuccess(state, data) {
      notification('success', i18n.t('services.service-update-successful'))
      return {
        ...state,
        loading: false
      }
    },
    getPartnerStaffSuccess(state, data) {
      return {
        ...state,
        loading: false,
        partnerStaffs: data
      }
    },
    onSuccessEnterpriseWithMessage(state, data, message) {
      notification('success', message)
      return {
        ...state,
        loading: false
      }
    },
    onGetBookingServiceCountSuccess(state, data) {
      return {
        ...state,
        loading: false,
        bookingServiceCount: data && data.data
      }
    },
    onUpdateServiceLocation(state, data) {
      notification('success', 'Service location updated successfully')
      return {
        ...state,
        loading: false
      }
    }
  },

  effects: {
    async getPartnerServices(payload, rootState, customers) {
      this.onRequest()
      try {
        const res = await service.getPartnerServices(payload, customers)
        await this.getPartnerServicesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createPartnerService(payload, rootState, cid) {
      this.onRequest()
      try {
        const res = await service.createPartnerService(payload, cid)
        await this.onCreatePartnerServiceSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async UpdateService(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.UpdateService(payload)
        await this.onUpdateServiceSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async getPartnerStaff(data) {
      this.onRequest()
      try {
        const res = await service.getPartnerStaff(data)
        this.getPartnerStaffSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendEnterpriseMessageToBooking(payload, rootState, cid) {
      this.onRequest()
      try {
        const res = await service.sendEnterpriseMessageToBooking(payload, cid)
        this.onSuccessEnterpriseWithMessage(res, 'Message sent successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getEnterpriseBookingServiceCount(payload) {
      this.onRequest()
      try {
        const res = await service.getBookingServiceCount(payload)
        await this.onGetBookingServiceCountSuccess(res)
        return res
      } catch (e) {
        console.log('e', e)
      }
    },
    async updateEnterpriseBookingServiceLocation(payload) {
      this.onRequest()
      try {
        const res = await service.updateBookingServiceLocation(payload)
        await this.onUpdateServiceLocation(res)
        return res
      } catch (e) {
        console.log('e', e)
      }
    }
  }
}
