export class ElementState {
  empty
  complete
  error

  constructor (event) {
    this.empty = event ? event.empty : true
    this.complete = event ? event.complete : false
    this.error = event && event.error ? event.error.code : ''
  }
}
