import config from "@config/index";
import { api, catchHandler } from "@helpers/axios";

export function getDefaultThemes(payload) {
    return api()
        .get(config.routes.defaultThemes, { params: payload })
        .then(res => res.data)
        .catch(catchHandler)
}

export function resetTheme(payload) {
    return api()
        .put(config.routes.resetTheme, payload)
        .then(res => res.data)
        .catch(catchHandler)
}

export function saveTheme(payload) {
    return api()
        .patch(config.routes.themeSettings, payload)
        .then(res => res.data)
        .catch(catchHandler)
}

export function brandingReupdate(payload) {
    return api()
        .put(config.routes.brandingReupdate, payload)
        .then(res => res.data)
        .catch(catchHandler)
}

export function saveBranding(payload) {
    return api()
        .put(config.routes.saveBranding, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
