import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Button } from '@gositeinc/ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { StepText } from './StepText'
import { StepTitle } from './StepTitle'
import { SearchDropdown } from './SearchDropdown'
import { FormLabel } from './FormLabel'
import Select from 'react-select'
//import { components } from 'react-select'
import * as _ from 'lodash'
import { segmentEventTrack } from '@helpers/segment'
import Icon from '@components/icons'
import { decodeCookies, getCookie, getCookieByKey, sessionStorageUtil, setCookiesV2 } from '@helpers/utility'
import { LOGIN_SERVICE_PROVIDERS, LOGIN_PROVIDER, COOKIES_KEY } from '@helpers/constants'
import QuickbookConfirmPopup from '@containers/payment/utility/quickbook-confirm-popup'

const { INTUIT } = LOGIN_SERVICE_PROVIDERS

export const BusinessDetails = ({ step, onSubmitted }) => {
  const dispatch = useDispatch()

  const [searchValue, setSearchValue] = useState('')
  const [showQuickbookPopup, setShowQuickbookPopup] = useState(false)
  const [quickbookPopupLoading, setQuickbookPopupLoading] = useState(false)

  const me = useSelector((state) => state.auth.me)
  const bussinessList = useSelector((state) => state.home.bussinessList)
  const boardingCategories = useSelector((state) => state.manage_business.boardingCategories)
  const boardingCategoriesOther = useSelector((state) => state.manage_business.boardingCategoriesOther)
  const selectInputRef = useRef()

  const [showOther, setShowOther] = useState(false)
  const [isOpen, setisOpen] = useState()
  const [industryOtherCheck, setIndustryOtherCheck] = useState(false)

  // const SelectMenuButton = (props) => {
  //   return (
  //     <components.MenuList {...props}>
  //       {props.children}
  //       {
  //         <div
  //           className="px-15 py-5 text-primary"
  //           onClick={props.selectProps.changeOptionsData}
  //           style={{ cursor: 'pointer' }}>
  //           <strong>Other</strong>
  //         </div>
  //       }
  //     </components.MenuList>
  //   )
  // }

  const isIntuitLogin = () => {
    const provider = getCookieByKey(LOGIN_PROVIDER) || ''
    return provider === INTUIT.name
  }

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }
    dispatch.manage_business.getOnboardingCategories({
      showDuringOnboarding: 1,
      nopaging: true,
      asc: 1,
      sort: 'category_name'
    })
    dispatch.manage_business.getOnboardingCategoriesOther({
      showDuringOnboardingOther: 1,
      nopaging: true,
      asc: 1,
      sort: 'category_name'
    })
    dispatch.firstExperience.getPrescreenOptionsList()

    segmentEventTrack({
      event: 'Onboarding_Business_Details_Load',
      properties: {
        Onboarding_Version: 'v4'
      }
    })

    if (isIntuitLogin()) {
      setShowQuickbookPopup(true)
    }
  }, [])

  useEffect(() => {
    setSearchValue(me.locationName)
  }, [me.locationName, setSearchValue])

  const schema = Yup.object().shape({
    business: Yup.object().required('Business is a required field'),
    industry: Yup.object().required('Industry is a required field')
  })
  const initialValues = {
    business: undefined,
    industry:
      me.boardingCategories && me.boardingCategories._id
        ? { value: me.boardingCategories._id, label: me.boardingCategories.category_name }
        : undefined
  }
  if (me.locationName) {
    initialValues.business = {
      locationName: me.locationName
    }
  }

  return (
    <Container maxWidth="xs">
      <StepText mt="79px">{step}</StepText>
      <StepTitle mt="4px">Business Details</StepTitle>

      <Formik
        validateOnBlur
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        isInitialValid={schema.isValidSync(initialValues)}
        validationSchema={schema}
        onSubmit={async (values, { setSubmitting }) => {
          const payload = {
            ...values.business,
            websiteUrl: '',
            hours: ''
          }

          // logic copy from first-experience/pages/business-name.tsx
          if (values.business.place_id) {
            payload.countryCode = 'US'
            payload.isMappable = true
          }

          if (values && values.industry) {
            payload.primaryCategories = values.industry && values.industry.value
          }

          if (values && values.industry && values.industry.label === 'Other') {
            payload.primaryCategories = values.industryOther && values.industryOther.value
          }

          if (me.location_id) {
            await dispatch.manage_business
              .updateBusinessSettings({
                throwBackErr: true,
                _id: me.location_id,
                ...payload
              })
              .then(async () => {
                onSubmitted()
              })
              .catch((err) => {
                console.log('updateBusinessSettings error', err)
              })
              .finally(() => {
                setSubmitting(false)
              })
          } else {
            await dispatch.accountsettings
              .upadteBusinessName({
                throwBackErr: true,
                ...payload
              })
              .then(async () => {
                await dispatch.auth.getMe()
                segmentEventTrack({
                  event: 'Onboarding_Business_Details_Completed',
                  properties: {
                    Onboarding_Version: 'v4'
                  }
                })
                onSubmitted()
              })
              .catch((err) => {
                console.log('upadteBusinessName error', err)
              })
              .finally(() => {
                setSubmitting(false)
              })
          }
        }}
        render={({ isValid, errors, isSubmitting, values, setFieldValue }) => {
          return (
            <Form className="w-100">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  mt: '39px'
                }}>
                <Box sx={{ width: '100%' }}>
                  <FormLabel mb="10px">
                    BUSINESS NAME<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>
                  <SearchDropdown
                    name="business"
                    placeholder="Your business name"
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    onSearchInputChange={async (searchString) => {
                      if (searchString) {
                        setFieldValue('business', {
                          locationName: searchString,
                          googlePlacesID: '',
                          address: '',
                          address2: '',
                          city: '',
                          state: '',
                          zip: '',
                          emails: [],
                          phone: ''
                        })
                      } else {
                        setFieldValue('business', undefined)
                      }
                      await dispatch.home.fetchBussiness({ search: searchString })
                    }}
                    onKeyDown={() => {}}
                    notFoundOptionText="Use this as my business name"
                    notListedText="None of these are my business"
                    onNotListedClick={(searchValue) => {
                      setFieldValue('business', {
                        locationName: searchValue,
                        googlePlacesID: '',
                        address: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: '',
                        emails: [],
                        phone: ''
                      })
                    }}
                    onNotFoundOptionClick={(searchValue) => {
                      setFieldValue('business', {
                        locationName: searchValue,
                        googlePlacesID: '',
                        address: '',
                        address2: '',
                        city: '',
                        state: '',
                        zip: '',
                        emails: [],
                        phone: ''
                      })
                    }}
                    optionList={bussinessList}
                    renderOption={(option, setSearchValue) => (
                      <li
                        className="px-15 py-5"
                        onClick={async () => {
                          setSearchValue(option.business_name)
                          setFieldValue('business', option)

                          const { place_id, business_name } = option
                          if (!place_id) {
                            setFieldValue('business', {
                              locationName: business_name,
                              googlePlacesID: place_id,
                              address: '',
                              address2: '',
                              city: '',
                              state: '',
                              zip: '',
                              emails: [],
                              phone: ''
                            })
                            return
                          }

                          const details = await dispatch.onboarding.businessAddressDetails({ place_id })
                          const address = _.get(details, 'address', undefined)
                          if (!address) {
                            return
                          }

                          setFieldValue('business', {
                            locationName: business_name,
                            googlePlacesID: place_id,
                            address: details.address ? details.address : '',
                            address2: '',
                            city: details.city ? details.city : '',
                            state: details.state ? details.state : '',
                            zip: details.zip ? details.zip : '',
                            emails: [],
                            phone: ''
                          })
                        }}>
                        {option.business_name} <br></br>
                        <span>
                          {option.display_address.includes(' EE. UU.')
                            ? option.display_address.replace(', EE. UU.', '')
                            : option.display_address.includes('USA')
                            ? option.display_address.replace(', USA', '')
                            : option.display_address}
                        </span>
                      </li>
                    )}
                  />
                  <FormLabel mt="31px" mb="10px">
                    INDUSTRY<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>
                  <Select
                    isSearchable
                    isClearable={true}
                    className="dropdown-select bg-white"
                    classNamePrefix="dropdownSelect"
                    name="industry"
                    placeholder="Select Industry"
                    onInputChange={(o) => {
                      setisOpen(undefined)
                    }}
                    value={values.industry}
                    changeOptionsData={() => {
                      setShowOther(!showOther)
                      const other = { value: '1', label: 'Other' }
                      setFieldValue('industry', other)
                      setisOpen(false)
                      setIndustryOtherCheck(true)
                    }}
                    menuIsOpen={isOpen}
                    //components={{ MenuList: SelectMenuButton }}
                    options={
                      boardingCategories &&
                      boardingCategories.map((item, index) => ({
                        value: item._id,
                        label: item.category_name
                      }))
                    }
                    onChange={(event) => {
                      setShowOther(false)
                      setFieldValue('industry', event && event)
                      if (selectInputRef.current) {
                        selectInputRef.current.select.clearValue()
                      }
                    }}
                  />
                  {showOther ? (
                    <Select
                      ref={selectInputRef}
                      isSearchable
                      isClearable={true}
                      className="dropdown-select bg-white mt-15"
                      classNamePrefix="dropdownSelect"
                      name="industryOther"
                      placeholder="Select Other Industry"
                      value={values.industryOther}
                      options={
                        boardingCategoriesOther &&
                        boardingCategoriesOther.map((item, index) => ({
                          value: item._id,
                          label: item.category_name
                        }))
                      }
                      onChange={(event) => {
                        setFieldValue('industryOther', event && event)
                        setIndustryOtherCheck(false)

                        if (event === null) {
                          setIndustryOtherCheck(true)
                        }
                      }}
                    />
                  ) : null}
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '20px',
                  gap: '16px'
                }}>
                <Button
                  variant="contained"
                  type="submit"
                  disabled={!isValid || isSubmitting || (showOther && industryOtherCheck)}
                  className="px-30">
                  CONTINUE
                  {isSubmitting && (
                    <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                      <Icon name="spinnerDark" />
                    </i>
                  )}
                </Button>
              </Box>
            </Form>
          )
        }}
      />
      {showQuickbookPopup && (
        <QuickbookConfirmPopup
          open={showQuickbookPopup}
          qbSyncDataLoading={quickbookPopupLoading}
          me={me}
          ftuUser={true}
          closeModal={() => {
            setShowQuickbookPopup(false)
            segmentEventTrack({
              event: 'Onboarding_Social_Skipped',
              properties: {
                Onboarding_Version: 'v4',
                Social_Account: 'Intuit'
              }
            })
          }}
          handleSubmit={async (data) => {
            setQuickbookPopupLoading(true)

            // logic copied from business-name.tsx
            const cookie = getCookie()
            const token = cookie && cookie.token
            const decodeData = decodeCookies(token)
            const intuitToken = decodeData['custom:ID_TOKEN_NEW']
            const quickbookToken = await dispatch.accountsettings.getQuickBookTokens({
              refresh_token: decodeData['custom:REFRESH_TOKEN']
            })

            const tokenData = _.get(quickbookToken, 'data', undefined)
            if (!tokenData) {
              console.error('tokenData is falsy')
              return
            }

            const payload = {
              access_token: tokenData.access_token,
              company_id: decodeCookies(intuitToken)['realmid'],
              refresh_token: tokenData.refresh_token
            }

            setCookiesV2(COOKIES_KEY.QUICKBOOK_ACCESS_TOKEN, quickbookToken.data.access_token)

            const qb_sync_preference = {
              sync_invoice_to_qb: data.sync_invoice_to_qb,
              sync_qb_contacts: data.sync_qb_contacts,
              sync_transaction_to_qb: data.sync_transaction_to_qb
            }

            sessionStorageUtil.setString('qb_sync_preference', JSON.stringify(qb_sync_preference))

            const quickbookInfo = await dispatch.accountsettings.getQuickBookInfo(payload)

            const email = _.get(quickbookInfo, 'data.Email.Addr', undefined) || _.get(me, 'user_email', undefined)

            const updateBusinessPayload = {
              locationName: _.get(quickbookInfo, 'data.CompanyName', ''),
              address: _.get(quickbookInfo, 'data.CompanyAddr.Line1', ''),
              city: _.get(quickbookInfo, 'data.CompanyAddr.City', ''),
              state: _.get(quickbookInfo, 'data.CompanyAddr.CountrySubDivisionCode', ''),
              zip: _.get(quickbookInfo, 'data.CompanyAddr.PostalCode', ''),
              phone: _.get(quickbookInfo, 'data.PrimaryPhone.FreeFormNumber', ''),
              emails: email ? [email] : []
            }

            if (me.location_id) {
              await dispatch.manageBusiness.updateBusinessSettings({
                _id: me.location_id,
                ...updateBusinessPayload
              })
            } else {
              // if we need to create the location db resource
              await dispatch.accountsettings.upadteBusinessName({
                ...updateBusinessPayload
              })
            }

            segmentEventTrack({
              event: 'Onboarding_Social_Synced',
              properties: {
                Onboarding_Version: 'v4',
                Social_Account: 'Intuit'
              }
            })

            setQuickbookPopupLoading(false)
            setShowQuickbookPopup(false)

            onSubmitted()
          }}
        />
      )}
    </Container>
  )
}
