import * as React from 'react';
import styles from './index.module.scss';
import { CopyToClipboard } from 'react-copy-to-clipboard';

interface CopyToClipboardButtonProps {
  textToCopy: string;
  onCopy: (text: string, result: boolean) => void;
  isCopied: boolean;
  hasCopiedMsg: string;
  hasNotCopiedMsg: string;
  children: React.ReactNode;
}
export const CopyToClipboardButton = ({
  children,
  textToCopy,
  onCopy,
  isCopied,
  hasCopiedMsg,
  hasNotCopiedMsg,
}: CopyToClipboardButtonProps) => {
  return (
    <CopyToClipboard onCopy={onCopy} text={textToCopy}>
      <button id="btnReview_copyLink" type="button" className={`btn ${styles['review-msg-link']}`}>
        <div className="d-flex custom-tooltip">
          {children}
          <div className={'custom-tooltip-active'}>
            <div className="content-block p-0">
              <div className="tooltip-description">
                {isCopied ? (
                  <p className="font-weight-light">{hasCopiedMsg}</p>
                ) : (
                  <p className="font-weight-light">{hasNotCopiedMsg}</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </button>
    </CopyToClipboard>
  );
};