import { api, builderApi, adminApi, catchHandler } from '@helpers/axios'
import { objToUrlParams } from '@helpers/index'
import config from '@config/index'

export function getSiteDetails (payload) {
  return api()
    .get(`${config.routes.siteDetails}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function publishSite (payload) {
  return builderApi()
    .post(`${config.routes.websites}/${payload}/publish`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSitesAnalytics (payload) {
  return api()
    .get(`${config.routes.siteDetails}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getAllSites (payload) {
  return api()
    .get(`${config.routes.websites}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getThemeSelection (payload) {
  return api()
    .get(`${config.routes.themeSelection}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSubDomain (payload) {
  return builderApi()
    .get(`${config.routes.domainAvailability}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSiteParent (payload) {
  return api()
    .post(`${config.routes.getSiteParent}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function builderRegister (payload) {
  const id = payload.theme
  return builderApi()
    .post(`${config.routes.websites}/${id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchSubdomainAvailability (payload) {
  return builderApi()
    .get(`${config.routes.domainAvailability}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchSubdomainAvailabilityOnPAyment (payload) {
  return builderApi()
    .get(`${config.routes.domainAvailability}?booking=true&subDomain=${payload.subDomian}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function editSiteSettings (payload) {
  return builderApi()
    .put(`${config.routes.websites}/${payload._id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function moveWebsiteToTrash (payload) {
  return builderApi()
    .delete(`${config.routes.websites}/${payload}/trash`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function checkDomainAvailability (payload) {
  return builderApi()
    .get(`${config.routes.domain}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getDomainList (payload) {
  return api()
    .get(`${config.routes.customDomain}/list`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function restoreWebsite (id) {
  return builderApi()
    .delete(`${config.routes.websites}/${id}/restore`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteWebsite (id) {
  return builderApi()
    .delete(`${config.routes.websites}/${id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function cloneWebsite (payload) {
  return builderApi()
    .post(`${config.routes.websites}/${payload.clonedFrom}/clone`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createPreview (id) {
  return api()
    .post(`${config.routes.websites}/${id}/generate-preview`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function partnerDomainAvailable (payload) {
  return api()
    .get(`${config.routes.partnerDomain}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCustomThemes (payload, viewpermission) {
  const handleApi = viewpermission ? (viewpermission === 'admin' ? adminApi : api) : adminApi
  return handleApi()
    .get(`${config.routes.customThemeList}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBusinessType () {
  return api()
    .get(`${config.routes.businessType}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteCustomTheme (payload) {
  const id = payload._id
  return adminApi()
    .put(`${config.routes.customThemeList}/${id}/delete`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function renameCustomTheme (payload) {
  const id = payload.themeId
  delete payload.themeId
  return adminApi()
    .put(`${config.routes.customThemeList}/${id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function generateApiToken (payload) {
  return api()
    .post(`${config.routes.generateApiToken}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}