import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { Link } from 'react-router-dom'
import RightHeader from './right-header'
import { ActionCenterConfetti } from '@containers/home/actionCenter/components/confetti'
import { getCookie, isMultiLocation } from '@helpers/utility'
import _ from 'lodash'
import { WarningCard } from './warnig-card'
import moment from 'moment-timezone'
import style from './index.module.scss'
import { featureFlags, getFeatureVal } from '@helpers/configcat'
import withPermissionHookHOC from '@helpers/WithPermissionHookHOC'
import config from '@config/index'
import { history } from '@app/store'
import { UserSource } from '@helpers/constants'

const mapDispatchToProps = ({ auth, myaccount }) => {
  return {
    ...auth,
    ...myaccount
  }
}
const mapStateToProps = ({ auth, myaccount }) => {
  return {
    ...auth,
    ...myaccount
  }
}
class Header extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: true,
      headerLogo: '',
      gositeLogo: 'https://dufzo4epsnvlh.cloudfront.net/image/icon-new/logo-loader.svg',
      locationName: '',
      errored: false,
      showTrialWarning: false,
      showSubscriptionWarning: false,
      upgradeToProMultiLocation: false,
      showTrialWarningAfterTimeout: false
    }

    this.handleResize = this.handleResize.bind(this)
    this.redirectToProductBilling = this.redirectToProductBilling.bind(this)
    // if( window.innerWidth > 1499){
    if (window.innerWidth > 1024) {
      document.body.classList.add('side-menu-open')
    }
  }
  redirectToProductBilling () {
    if (this.props.user && this.props.user.user_unique_id) {
      history.push(`/${this.props.user.user_unique_id}/${this.props.user.isMultiLocation ? 'myaccount' : 'account-settings'}?redirect=product`)
    }
  }
  async componentDidMount () {
    // document.body.classList.add('is-enterprise-user');

    const showTrialWarning = await getFeatureVal(featureFlags.trialWarningCard, this.props.me)
    const showSubscriptionWarning = await getFeatureVal(featureFlags.subscriptionWarningCard, this.props.me)

    const isAccountFromHubSpot = this.props.me.source === UserSource.HubSpot

    this.setState({
      showSubscriptionWarning,
      showTrialWarning: showTrialWarning && !isAccountFromHubSpot
    })

    if (!this.props.me && this.props.user) {
      await this.props.getMe()
      await this.props.getSubscription()
    }

    if (!this.props.domainSettings) {
      await this.props.validateDomain()
    }

    if (
      this.props.isPartner ||
      (this.props.me &&
        this.props.me.role_id &&
        this.props.me.role_id.role_name &&
        ['staff', 'partner'].indexOf(this.props.me.role_id.role_name) > -1 &&
        !this.props.me.is_main)
    ) {
      document.body.classList.add('is-enterprise-user')
      if (this.props.me && this.props.me.company_settings && this.props.me.company_settings.logoUrl) {
        this.setState({ headerLogo: this.props.me.company_settings.logoUrl })
      }
    }
    if (this.props.me && this.props.me.locationName) {
      this.setState({ locationName: this.props.me.locationName })
    }

    const asyncMount = async () => {
      let innerMe = this.props.me
      if (!this.props.me) innerMe = await this.props.getMe()
      const flagStatus = await getFeatureVal(featureFlags.upgradeToProMultiLocation, innerMe)

      this.setState({
        upgradeToProMultiLocation: flagStatus
      })
    }
    asyncMount()
    window.addEventListener('resize', this.handleResize)

    setTimeout(() => {
      this.setState({ showTrialWarningAfterTimeout: true })
    }, 10000)
  }

  componentWillUnmount () {
    window.addEventListener('resize', null)
  }

  handleResize () {
    if (window.innerWidth > 1024) {
      document.body.classList.add('side-menu-open')
    } else {
      document.body.classList.remove('side-menu-open')
    }
  }

  sideMenuButton () {
    document.body.classList.toggle('side-menu-open')
  }

  onLogoError (ev) {
    if (!this.state.errored) {
      ev.target.onerror = null
      // commented by Ajith to avoid the logo issue
      // ev.target.style.display = 'none'
      this.setState({ errored: true })
    }
  }

  subscriptionWarning (upgradeToProMultiLocation = this.state.upgradeToProMultiLocation) {
    const { showSubscriptionWarning, showTrialWarning, showTrialWarningAfterTimeout } = this.state
    const { me, permissions: { isReadOnlyRole } = {} } = this.props
    const subscriptionData = _.get(me, 'subscriptionData', null)
    const status = _.get(subscriptionData, 'status', '')
    const isMultiLocation = _.get(me, 'isMultiLocation', false)
    const cookies = getCookie()

    const shouldExclude = (me) => {
      if (cookies.businessProfile || isMultiLocation) {
        if (this.state.upgradeToProMultiLocation) {
          return false
        } else {
          return true
        }
      } else {
        // offline payment
        const isOfflinePayment = _.get(me, 'offline_payment', true)
        // enterprise user
        const platformId = _.get(me, 'platform_id', config.platformID)
        const isPartnerUser = platformId !== config.platformID
        return isOfflinePayment || isPartnerUser
      }
    }

    // Don't show any warning if the current account subscription status is active
    if (status === 'active') {
      return null
    }

    if (shouldExclude(me)) {
      return null
    }

    if (showSubscriptionWarning && subscriptionData) {
      const { current_term_end: termEnd } = subscriptionData
      const termEndString = moment(termEnd * 1000)
        .format('MMM DD YYYY')
        .toUpperCase()
      if (status === 'non_renewing') {
        return (
          <>
            <WarningCard
              iconName="calendarPartialDate"
              message={`Your account will be deactivated on ${termEndString}`}
              showHelperLink="true"
            />
            <div className="px-10 d-none d-lg-block">
              <button
                type="button"
                className={`btn border-0 btn-primary ${style['resume-subscription-btn']}`}
                onClick={() => this.redirectToProductBilling()}>
                RESUME SUBSCRIPTION
              </button>
            </div>
          </>
        )
      }

      if (status === 'cancelled') {
        return (
          <WarningCard iconName="calendarPartialDate" message={`Your account was deactivated on ${termEndString}`} />
        )
      }
    }

    // Checks if the account is part of a free trial experience
    const roleName = _.get(me, 'role_id.role_name', undefined)
    const isTrialRole = roleName === 'trial'
    const isTrial = _.get(me, 'is_trial', false)
    const trialExpirationDateString = _.get(me, 'trial_ends_at', null)
    const chargeBeeData = _.get(me, 'chargeBeeData', null)
    let isValid = false

    let areChargeeBeeIdsPresent = false
    if (chargeBeeData) {
      if ((chargeBeeData.chargebee_account_id && chargeBeeData.chargebee_subscription_id) || chargeBeeData.chargebee_not_required) {
        areChargeeBeeIdsPresent = true
      }
    }

    /** Can we show for multi location user ? */
    if (isMultiLocation || cookies.businessProfile) {
      if (upgradeToProMultiLocation) {
        isValid = !areChargeeBeeIdsPresent && isTrial && trialExpirationDateString
      }
    } else {
      isValid = !areChargeeBeeIdsPresent && isTrialRole && isTrial && trialExpirationDateString
    }

    if (showTrialWarning && (isValid || isReadOnlyRole) && showTrialWarningAfterTimeout) {
      const now = moment().tz('America/Los_Angeles')
      const trialEndsAt = moment(trialExpirationDateString)
        .tz('America/Los_Angeles')
        .set('hour', 23)
        .set('minute', 59)
        .set('second', 59)
      const daysLeft = trialEndsAt.diff(now, 'days')
      const secondsLeft = trialEndsAt.diff(now, 'seconds')
      const trialExpirationDiffStr = `${daysLeft}`.padStart(2, '0')
      const trialExpiredAlready = secondsLeft < 0

      if (trialExpiredAlready || isReadOnlyRole) {
        return <WarningCard iconName="calendarPartialDate" message="Your trial has expired" />
      }

      const warningClass = daysLeft > 7 ? style['green-warning'] : style['yellow-warning']
      const message =
        daysLeft === 0
          ? 'Your trial ends tomorrow!'
          : `${trialExpirationDiffStr} day${daysLeft > 1 ? 's' : ''} left in your trial`

      return (
        <WarningCard
          customClass={daysLeft === 0 ? '' : warningClass}
          iconName="calendarPartialDate"
          message={message}
        />
      )
    }

    return null
  }

  render () {
    const { subscriptionData } = this.props
    const { showSubscriptionWarning } = this.state
    const status = _.get(subscriptionData, 'status')
    return (
      <header
        className={`main-header enterprise-main-header header-top ${
          this.props.showUpgradeTrial
            ? this.props.me && this.props.me.isMultiLocation
              ? ''
              : 'header-height-up pt-15 pt-sm-0'
            : ''
        }`}>
        <ActionCenterConfetti />
        <div className="container-fluid h-100">
          <div
            className={`d-flex pl-xl-15 pr-xl-10 align-items-center custom-header-inner-height ${
              this.props.showUpgradeTrial ? 'mb-20 mb-sm-0' : ''
            }`}>
            <button
              className="btn position-relative p-0 sidemenu-button  d-inline-block mr-10 mr-md-20 left-minus-2"
              onClick={() => this.sideMenuButton()}>
              <span className="h-line d-block rounded mb-4"></span>
              <span className="h-line d-block rounded mb-4"></span>
              <span className="h-line d-block rounded "></span>
            </button>
            <div className="d-flex align-items-center ">
              {!isMultiLocation() && this.state.gositeLogo ? (
                <Link className="logo-block d-flex" to={`/${this.props.user.user_unique_id}/home`}>
                  <img
                    className="w-100 image-logo"
                    alt=""
                    src={this.state.gositeLogo}
                    onError={this.onLogoError.bind(this)}
                  />
                </Link>
              ) : null}
            </div>
            <div className={`d-flex b-custom-logo align-items-center ${showSubscriptionWarning && subscriptionData && (status === 'non_renewing') && 'header-title'}`}>
              {!isMultiLocation() && this.state.headerLogo ? (
                <Link className="logo-block d-flex" to={`/${this.props.user.user_unique_id}/home`}>
                  <img
                    className="w-100 image-logo"
                    alt=""
                    src={this.state.headerLogo}
                    onError={this.onLogoError.bind(this)}
                  />
                </Link>
              ) : null}
              {/* {this.props.isPartner || (this.props.me && this.props.me.role_id && this.props.me.role_id.role_name && ["staff","customer"].indexOf(this.props.me.role_id.role_name) > -1 && !this.props.me.is_main) ? */}
              {this.props.isPartner ||
              (this.props.me &&
                this.props.me.role_id &&
                this.props.me.role_id.role_name &&
                ['staff', 'partner'].indexOf(this.props.me.role_id.role_name) > -1 &&
                !this.props.me.is_main) ? (
                  <span className="ml-10 text-secondary logo-text">
                    {isMultiLocation() ? (
                      <span className="text-capitalize">{isMultiLocation()}</span>
                    ) : (
                      this.props.me.company_settings &&
                      this.props.me.company_settings.generalSettings &&
                      this.props.me.company_settings.generalSettings.site_title ? this.props.me.company_settings.generalSettings.site_title : ''

                    )}
                    {this.props.me && this.props.me.location_identifier && (
                      <span> - {this.props.me.location_identifier}</span>
                    )}
                  </span>
                ) : null}
            </div>
            <RightHeader
              showUpgradeTrial={this.props.showUpgradeTrial}
              getNotificationCount={this.props.getNotificationCount}
              subscriptionWarningFunc={this.subscriptionWarning.bind(this)}
            />
          </div>
          {/* <div className="d-block d-sm-none text-sm-center text-left">{this.subscriptionWarning()}</div> */}
        </div>
      </header>
    )
  }
}
export default withPermissionHookHOC(compose(connect(mapStateToProps, mapDispatchToProps))(Header))
