import notification from '@components/notification'
import * as service from './service'
import { capitalize } from '@helpers/index'
import { getPlans } from './getplans'

function fetchName (name, planid) {
  let planName = false
  if (planid) {
    if (planid === 'gosite-professional-(annual)') {
      planName = true
    } else if (planid === 'gosite-professional-(semi-annual)') {
      planName = true
    } else if (planid === 'gosite-professional-(quarterly)') {
      planName = true
    } else if (planid === 'gosite-professional-(monthly)') {
      planName = true
    }
    return planName
  }
}

export default {
  state: {
    selfServiceError: false,
    planDetails: []
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        selfServiceError: false
      }
    },
    onError (state, data) {
      const err = data && data.message
        ? data.message
        : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
            ? data.error
            : data && data.data && data.data.error
              ? data.data.error
              : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        selfServiceError: true
      }
    },
    oncreatePandocError (state, data) {
      const err = data && data.data
        ? data.data
        : 'Please try again later'
      notification('error', err)
      return {
        ...state,
        loading: false,
        selfServiceError: true
      }
    },
    onSuccess (state, data, message) {
      if (message) {
        notification('success', message)
      }
      return {
        ...state,
        loading: false
      }
    },
    onfetchHostedPageSuccess (state, data) {
      return {
        ...state,
        loading: false,
        hostedPageData: data
      }
    },
    onfetchCompanyDetailsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        companyDetails: data
      }
    },

    onfetchsubscriptionPlanSuccess (state, data) {
      let planDetails = []
      if (data && data.plan) { planDetails = getPlans(data.plan.id) }
      return {
        ...state,
        loading: false,
        subscriptionPlan: data && data.plan,
        planDetails,
        showWidget: data && data.plan && data.plan.name ? fetchName(data.plan.name, data.plan.id) : false
      }
    },
    onfetchCouponDataSuccess (state, data) {
      return {
        ...state,
        loading: false,
        couponData: data && data.coupon
      }
    },
    onfetchMonthlysubscriptionPlanSuccess (state, data) {
      return {
        ...state,
        loading: false,
        monthlySubcriptionPlan: data && data.plan
      }
    },
    oncreatePandocPlanSuccess (state, data) {
      return {
        ...state,
        loading: false,
        pandocDetails: data
      }
    },
    onfetchPandocStatusSuccess (state, data) {
      return {
        ...state,
        loading: false,
        pandocStatus: data
      }
    },
    onchargeBeeredirectionCheckSuccess (state, data) {
      return {
        ...state,
        loading: false,
        payementCustomerDetails: data
      }
    },
    isSegmentIdentifiedSuccess (state, data) {
      return {
        ...state,
        loading: false,
        isSegmentIdentifieds: true
      }
    }
  },
  effects: {
    async reportAdmins () {
      this.onRequest()
      try {
        const res = await service.reportAdmins({ from: 'CRM' })
        this.onSuccess(res, 'Successfully submitted your feedback')
        return res
      } catch (e) {
        this.onError(e)
      }
    },

    async fetchHostedPage (payload) {
      this.onRequest()
      try {
        const res = await service.fetchHostedPage(payload)
        this.onfetchHostedPageSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchCompanyDetails (payload) {
      this.onRequest()
      try {
        const res = await service.fetchCompanyDetails(payload)
        this.onfetchCompanyDetailsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchsubscriptionPlan (payload) {
      this.onRequest()
      try {
        const res = await service.fetchsubscriptionPlan(payload)
        this.onfetchsubscriptionPlanSuccess(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async fetchCouponData (payload) {
      this.onRequest()
      try {
        const res = await service.fetchCouponData(payload)
        this.onfetchCouponDataSuccess(res)
        return res
      } catch (e) {
        console.log(e)
        // this.onError(e)
      }
    },
    async fetchMonthlysubscriptionPlan (payload) {
      this.onRequest()
      try {
        const res = await service.fetchsubscriptionPlan(payload)
        this.onfetchMonthlysubscriptionPlanSuccess(res)
        return res
      } catch (e) {
        //  this.onError(e)
      }
    },
    async createPandoc (payload) {
      this.onRequest()
      try {
        const res = await service.createPandoc(payload)
        this.oncreatePandocPlanSuccess(res.data)
        return res
      } catch (e) {
        this.oncreatePandocError(e.data)
      }
    },
    async fetchPandocUrl (payload) {
      this.onRequest()
      try {
        const res = await service.fetchPandocUrl(payload)
        this.oncreatePandocPlanSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchPandocStatus (payload) {
      this.onRequest()
      try {
        const res = await service.fetchPandocStatus(payload)
        this.onfetchPandocStatusSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateCompanyDetails (payload) {
      this.onRequest()
      try {
        const res = await service.updateCompanyDetails(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async chargeBeeredirectionCheck (payload) {
      this.onRequest()
      try {
        const res = await service.chargeBeeredirectionCheck(payload)
        this.onchargeBeeredirectionCheckSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async isSegmentIdentified (payload) {
      this.isSegmentIdentifiedSuccess(payload)
      return payload
    },
    async resubscribeEmail (payload) {
      this.onRequest()
      try {
        const res = await service.resubscribeEmail(payload)
        this.onSuccess(res, 'Successfully resubscribed!!')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async restartTrial (payload) {
      this.onRequest()
      try {
        const res = await service.restartTrial(payload)
        this.onSuccess(res, 'Trial period successfully updated')
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
