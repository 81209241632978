import React from "react";
import Icon from "./icons";

class CalendarInput extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value,
        }
    }
    clearField(e) {
        e.stopPropagation()
        this.props.clearDate()
    }
    render() {
        return (
            <div className="calendar-input-wrapper">
                <button type="button" className="btn btn-lg border date-picker-button px-15 w-100 text-left position-relative" onClick={this.props.onClick}>
                    <span>{this.props.value ? this.props.value : this.props.message ? this.props.message : ""}</span>
                    <div className="d-flex position-absolute b-icons align-items-center">
                        {this.props.value ?
                            <span onClick={(e) => this.clearField(e)} className="btn-icon d-flex icon-close mr-10">&times;</span>
                            : null}
                        <i className="btn-icon d-flex icon-calendar"><Icon name="calender" /></i>
                    </div>
                </button>
            </div>
        );
    }
}

export default CalendarInput;