import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function fetchHomeLayout (payload) {
  const requestURl = `${config.routes.homeLayout}?${objToUrlParams(payload)}`
  return api()
    .get(requestURl)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateHomeLayout (payload) {
  const requestURl = `${config.routes.homeLayout}`
  return api()
    .post(requestURl, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getReviewsRatings (payload) {
  return api()
    .get(`${config.routes.enterpriseReviewAnalytics}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
