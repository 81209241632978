import React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

export const WisetackDisclosure = ({ disclosure }: { disclosure: string }): JSX.Element => {
  return (
    <Box mt={2} display="flex" justifyContent="flex-center">
      <Typography variant="caption" align="justify" dangerouslySetInnerHTML={{ __html: disclosure }}></Typography>
    </Box>
  )
}
