import React from 'react'
import { Formik, Form } from 'formik'
import Popup from '@components/popups/basic-modal'
import 'react-datepicker/dist/react-datepicker.css'
import listStyle from '../index.module.scss'
import { withTranslation } from 'react-i18next'
class CancelBooking extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }
  showPopup() {
    this.setState({ open: true })
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    }).catch((e) => {})
  }
  handleSubmit(values, { resetForm }) {
    this.resolve(values)
    this.setState({ open: false })
  }
  handleCancel(values, { resetForm }) {
    this.setState({ open: false })
    this.reject()
  }
  closeModal(reset, resetForm) {
    this.setState({ open: false })
    if (reset) this.reset(resetForm)
  }
  render() {
    const t = this.props.t
    return (
      <Popup
        open={this.state.open}
        title={t('bookings.cancel-appointment')}
        okText={t('bookings.yes-cancel-appointment')}
        cancelText="NO"
        onCancel={() => this.setState({ open: false })}
        type="small"
        overrideForm={true}
        modalBackdropClicked={this.closeModal.bind(this)}>
        <Formik
          onReset={this.handleCancel.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form name="frmBookingDetailsCancel" id="frmBookingDetailsCancel">
                <button
                  name="btnCloseBookingCancelModal"
                  type="reset"
                  className="close custom-modal-close-button remove-modal-close">
                  <span aria-hidden="true">×</span>
                </button>
                <div className="mb-30 mt-10" id="cancel-booking-popup">
                  <p id="modalRescheduleCancel_lblMessage">{t('bookings.do-you-want-to-cancel-this-appointment-0')}</p>
                </div>
                <footer className="d-block d-sm-flex">
                  <button
                    name="btnDoneBookingCancelModal"
                    type="submit"
                    id="frmCancelAppointment_btnYes"
                    className={
                      'btn border-0 btn-xl mb-10 mb-sm-0 mr-sm-20 btn-block btn-danger ' + listStyle['remove-btn']
                    }>
                    {t('bookings.yes-cancel-appointment')}
                  </button>
                  <button
                    name="btnCancelBookingCancelModal"
                    type="reset"
                    id="CancelAppointment_btnNoCancel"
                    className="btn btn-xl m-0 cancel-btn custom-btn-cancel">
                    {'NO'}
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}
export default withTranslation(null, { withRef: true })(CancelBooking)
