import React, { useEffect } from 'react'
import WithGoogleLoginHOC from '@app/helpers/WithGoogleLoginHOC'

const GoogleContactButton = ({ onClickGoogleLogin, googleResponse, error, onSuccess, onFailure, showLabel } : any) => {
  useEffect(() => {
    if (googleResponse && googleResponse.code) {
      onSuccess(googleResponse)
    }
    if (error) {
      onFailure(error)
    }
  }, [googleResponse, error])

  return (
    <button onClick={onClickGoogleLogin} className="btn d-inline-block" title='Google Contacts'>
      <img
        src="https://dufzo4epsnvlh.cloudfront.net/image/contact/google-icon.svg"
        alt="G"
        data-toogle="tooltip"
        data-original-title="Google"
      />
      {showLabel && <span className='pl-4'>Google</span>}
    </button>
  )
}

export default WithGoogleLoginHOC(GoogleContactButton)
