import { isEnabled } from './utility'
import { getFeatureVal, featureFlags } from './configcat'

/**
 * Response from `/api/users/me` a.k.a. getMe()
 */
 interface MeData {
  _id: string
  user_email: string
}

/**
 * get V2 flag
 * @param payment_type
 * @param flag specifies what flag to return. Its values can be 'payment_v2' or 'invoices_v2'
 * @param products
 * @param me
 * @returns 
 */
export async function readSegregateV2Flag (payment_type:String, flag:String, products : String[], me?: MeData) {
  let v2Flag = true
  if(payment_type === 'stripe') {
    if(await getFeatureVal(featureFlags.segregate_invoice_payments, me)) {
      //Set the values of the flags only if the user is the tripe user and the segregate flag is enabled
      v2Flag =  products && isEnabled(flag, products)
    } 
  }
  return v2Flag
}

