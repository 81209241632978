import React, { useEffect } from 'react'
import Popup from './basic-modal'
import styles from './dcl-grace-period-popup.module.scss'
import { useSelector } from 'react-redux'
import { Me } from '@containers/first-experience/types'
import { history } from '@app/store'
import { ClosePopupButton } from './components/closePopupButton'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import notification from '@components/notification'
import { setPopupAlreadyOpenedInSession } from '@helpers/utility'
import { segmentEventTrack } from '@helpers/segment'

interface DCLGracePeriodPopupProps {
  open: boolean;
  closePopup?: () => any;
}

export const DCLGracePeriodPopup = ({ open, closePopup = () => {} }: DCLGracePeriodPopupProps) => {
  const me = useSelector<any, Me>((state) => state.auth.me)
  const userObject = {
    ...me
  }
  useEffect(() => {
    if (!open) {
      return
    }

    segmentEventTrack({
      event: 'dcl_grace_period_popup_shown',
      properties: {}
    })
  }, [open])

  return (
    <Popup
      id="dcl-grace-period-popup"
      type="default"
      hideHeader={true}
      open={open}
      modalBackdropClicked={() => {}}
      center={true}
      customClass={`${styles['model']}`}>
      <ClosePopupButton
        id="closeIcon"
        onClick={() => {
          closePopup()
        }}
        className={styles['move-down-close-btn']}
      />
      <div className={`${styles['container']}`}>
        <div className={`${styles['left-section']}`}>
          <div>
            <div className={`${styles['header-container']}`}>
              <h2 className={`${styles['header']}`}>This will only take a minute.</h2>
            </div>
            <p className={`${styles['subtitle']}`}>
              Your card was declined. To avoid losing access to GoSite, please update your payment method.
            </p>

            <button
              onClick={async () => {
                segmentEventTrack({
                  event: 'dcl_grace_period_popup_update_payment_method_clicked',
                  properties: {}
                })
                setPopupAlreadyOpenedInSession()
                /** EVE-2684 */
                let key = 'account-settings'
                if (userObject.isMultiLocation && userObject.total_accounts > 1) {
                  key = 'myaccount'
                }
                history.push(`/${me.user_unique_id}/${key}?redirect=product`)
                closePopup()
                /** EVE-2684 */
              }}
              className={`btn btn-primary border-0 px-sm-20 ${styles['upgrade-btn']}`}>
              UPDATE PAYMENT METHOD
            </button>
          </div>
          <div>
            <div className={`${styles['subtitle2']}`}>
              <span>
                Have questions?&nbsp;
                <CopyToClipboard
                  text={'help@gosite.com'}
                  onCopy={() => {
                    notification('success', 'Copied: help@gosite.com')
                  }}>
                  <span className={`${styles['talk-to-sales']}`}>Email our Support team</span>
                </CopyToClipboard>
                &nbsp;or call us at (858)-295-7995.
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className={`${styles['right-section']}`}>
            <img src="/images/promotional/trial-expired.png" alt="expired" />
          </div>
        </div>
      </div>
    </Popup>
  )
}
