import React from 'react'
import Modal from 'react-bootstrap4-modal'

class Popup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  modalBackdropClicked () {
    if (this.props.modalBackdropClicked) {
      this.props.modalBackdropClicked()
    }
  }

  getTypeClass () {
    if (this.props.type === 'small') return 'modal-custom-sm'
    // 440px
    else if (this.props.type === 'default') return 'modal-custom-md'
    // 540px
    else if (this.props.type === 'medium') return 'modal-custom-lg'
    // 620px
    else if (this.props.type === 'large') return 'modal-custom-xl'
    // 760px
    else if (this.props.type === 'xlarge') return 'modal-custom-xxl'
    // 820px
    else if (this.props.type === 'xxlarge') return 'modal-custom-xxxl'
    // 1000px
    else if (this.props.type === 'media-library-popup') return 'media-library-popup'
    else return ''
  }

  escFunction (event) {
    if (event.keyCode === 27) {
      this.modalBackdropClicked()
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.escFunction.bind(this), false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escFunction.bind(this), false)
  }

  render () {
    const { open, title, center, dark, padding } = this.props
    const centerClass = center ? 'modal-dialog-centered' : ''
    const darkClass = dark ? 'bg-dark bg-gradient-dark' : ''
    const paddingClass = padding ? 'p-0' : ''
    return (
      <Modal
        id={this.props.id}
        visible={open}
        onClickBackdrop={this.modalBackdropClicked.bind(this)}
        dialogClassName={`${this.getTypeClass()} ${centerClass}`}
        className={this.props.customClass}>
        {!this.props.hideHeader ? (
          <div className="modal-header border-0 position-relative pb-0">
            <h5 className="modal-title">{title || 'Title'}</h5>
          </div>
        ) : null}

        <div className={`modal-body ${darkClass} ${paddingClass}`}>{this.props.children ? this.props.children : null}</div>
      </Modal>
    )
  }
}
export default Popup
