import notification from "@components/notification";
import * as service from "./service";
import { capitalize } from "@helpers/index"

export default {
    state: {
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state, data) {
            let err = data && data.message ?
                data.message
                : data && data.data && data.data.message ?
                    data.data.message
                    : data && data.error ?
                        data.error
                        : data && data.data && data.data.error ?
                            data.data.error
                            : "Please try again later";
            notification("error", capitalize(err));
            return {
                ...state,
                loading: false
            };
        },
        onSuccess(state,data,message){
            if(message){
                notification("success", message);
            }
            return {
                ...state,
                loading: false
            };
        },
        onPredefinedSuccess(state,data){
            var temp = [];
            if(data && data.length)
                temp = data;
            return {
                ...state,
                predefinedTemplates: temp,
                loading:false
            };
        },
        onGetWebsiteSubscription(state, data) {
            return {
                ...state,
                loading: false,
                websiteSubscription:data.data
            };
        },
        onGetWebsiteLead(state,data){
            return {
                ...state,
                loading: false,
                appointmentData:data.data
            };
        },
        onGetWebsiteAnalytics(state,data){
            return {
                ...state,
                loading: false,
                wbAnalyticsData:data.data
            };
        },
        onActivateProductSuccess(state,data,message){
            if(message){
                notification("success", message);
            }
            return {
                ...state,
                loading: false,
            };
        },
        onGetEmailTemplateSuccess(state,data){
            return {
                ...state,
                loading: false,
                emailTemplate:data
            };
        }

    },
    effects: {
        async reportAdmin() {
            this.onRequest();
            try {
                let res = await service.reportAdmin({from:"CRM"})
                this.onSuccess(res,"Successfully submitted your feedback")
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async getPredefinedTemplate(payload){
            this.onRequest();
            try {
                let res = await service.getPredefinedTemplate(payload);
                this.onPredefinedSuccess(res.data);
            }
            catch(e) {
                this.onError(e)
            }
        },
        async getWebsiteSubscriptionData(payload){
            this.onRequest();
            try {
                let res = await service.getWebsiteSubscriptionData(payload);
                this.onGetWebsiteSubscription(res);
            }
            catch(e) {
                this.onError(e)
            }
        },
        async getWebsiteLeadData(payload){
            this.onRequest();
            try {
                let res = await service.getWebsiteLeadData(payload);
                this.onGetWebsiteLead(res);
            }
            catch(e) {
                // this.onError(e)
                console.log("error",e)
            }
        },
        async getWebsiteAnalyticsSummary(payload){
            this.onRequest();
            try {
                let res = await service.getWebsiteAnalyticsSummary(payload);
                this.onGetWebsiteAnalytics(res);
            }
            catch(e) {
                this.onError(e)
            }
        },
        async activateProduct(payload){
            this.onRequest();
            try {
                let res = await service.activateProduct(payload);
                this.onActivateProductSuccess(res,"Request send successfully");
            }
            catch(e) {
                this.onError(e)
            }
        },
        async getEmailTemplate(payload){
            this.onRequest();
            try {
                let res = await service.getEmailTemplate(payload);
                this.onGetEmailTemplateSuccess(res);
            }
            catch(e) {
                // this.onError(e)
            }
        },
    }
};
