import { Typography, styled } from '@gositeinc/ui'

export const StepTitle = styled(Typography)(
  () => `
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 140%;
  text-align: center;
  color: #082244;
`
)

export const StepTitle2 = styled(Typography)(
  () => `
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 140%;
  text-align: center;
  color: #082244;
`
)
