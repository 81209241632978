import * as React from 'react'
import { Field, ErrorMessage } from 'formik'
import InputMask from 'react-input-mask'

interface FormGroupProps {
  children: React.ReactNode;
  label: string;
  required?: boolean;
  name: string;
  className?: string;
}
const FormGroup = ({ className, name, children, label, required }: FormGroupProps) => {
  return (
    <div className={className}>
      <label htmlFor={name}>{label}</label>
      {required && <p>*</p>}
      {children}
    </div>
  )
}

interface FormCheckboxProps {
  name: string;
  children: string;
}
export const FormCheckbox = ({ name, children }: FormCheckboxProps) => {
  return (
    <FormGroup label={children} name={name}>
      <Field type="checkbox" name={name} />
      <ErrorMessage name={name} component="span" className="form-error" />
    </FormGroup>
  )
}

interface FormInputMaskProps {
  children: string;
  name: string;
  value: string;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  required?: boolean;
  placeholder?: string;
  maxLength?: number;
  mask: string | (string | RegExp)[];
}
export const FormInputMask = ({
  placeholder,
  required,
  name,
  children,
  onChange,
  onBlur,
  value,
  mask,
  maxLength
}: FormInputMaskProps) => {
  return (
    <FormGroup label={children} name={name} required={required}>
      <InputMask
        id={name}
        value={value}
        mask={mask}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        maxLength={maxLength}
      />
      <ErrorMessage name={name} component="span" className="form-error" />
    </FormGroup>
  )
}

interface FormInputProps {
  children: string;
  name: string;
  required?: boolean;
  placeholder?: string;
  maxLength?: number;
  type?: string;
}
export const FormInput = ({ children, name, required, placeholder, maxLength, type }: FormInputProps) => {
  return (
    <FormGroup label={children} name={name} required={required}>
      <Field id={name} name={name} type={type || 'text'} placeholder={placeholder} maxLength={maxLength} />
      <ErrorMessage name={name} component="span" className="form-error" />
    </FormGroup>
  )
}
