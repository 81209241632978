import React, { PureComponent } from 'react'
import Popup from '@components/popups/basic-modal'
import InvoicePreview from './invoicePreview'
import ConfirmInvoice from './confirmInvoice'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withTranslation } from 'react-i18next'

let isFormChanged = false

class SendInvoiceConfirmPopup extends PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired,
    invoiceData: PropTypes.object.isRequired,
    saveInvoiceData: PropTypes.func.isRequired,
    me: PropTypes.object.isRequired,
    invoiceSaveLoader: PropTypes.bool,
    invoiceEdit: PropTypes.func,
    updateContact: PropTypes.func
  }

  constructor(props) {
    super(props)
    this.state = {
      type: ''
    }
  }

  componentDidMount() {
    if (this.props.invoiceData && this.props.invoiceData.send_email && this.props.invoiceData.send_sms) {
      this.setState({ type: 'email_sms', title: this.props.t('payments.send-via-email-and-sms') })
    } else if (this.props.invoiceData && !this.props.invoiceData.send_email && this.props.invoiceData.send_sms) {
      this.setState({ type: 'sms', title: this.props.t('payments.send-via-sms-0') })
    } else if (this.props.invoiceData && this.props.invoiceData.send_email && !this.props.invoiceData.send_sms) {
      this.setState({ type: 'email', title: this.props.t('payments.send-via-email') })
    }
  }

  setIsFormChanged(value) {
    isFormChanged = value
  }

  closeModal() {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }

  handleCancel(values, { resetForm }) {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }

  async handleSubmit(values, { resetForm }) {
    const invoiceData = Object.assign(this.props.invoiceData)
    if (values.invoice_title) {
      invoiceData.invoice_title = values.invoice_title
    }
    if (values.invoice_title) {
      invoiceData.invoice_description = values.invoice_description
    }
    if (invoiceData.scheduled_date) {
      invoiceData.scheduled_date = moment(invoiceData.scheduled_date).utc()
    }
    if (invoiceData.invoice_due) {
      invoiceData.invoice_due = moment(invoiceData.invoice_due).endOf('day').utc()
    }
    invoiceData.review_feedback = values.review_feedback
    if (values.saveContact) {
      const res = {
        contact_id: invoiceData.customer_id,
        email: invoiceData.customer_email,
        mobile: invoiceData.customer_phone ? invoiceData.customer_phone : '',
        first_name: invoiceData.customer_first_name,
        last_name: invoiceData.customer_last_name
      }
      this.props.updateContact(res)
    }
    await this.props.saveInvoiceData(invoiceData)
  }

  render() {
    return (
      <React.Fragment>
        <Popup
          open={this.props.open}
          title={this.state.title || ''}
          onCancel={() => this.closeModal.bind(this)}
          type="default"
          modalBackdropClicked={() => console.log(null)}
          id="modalSendInvoiceTypeLink">
          <ConfirmInvoice
            invoiceData={this.props.invoiceData}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            invoiceSaveLoader={this.props.invoiceSaveLoader}
            typeOfInvoice={this.state.type}
            setIsFormChanged={this.setIsFormChanged}
            me={this.props.me}
            paymentFeedbackStatus={this.props.paymentFeedbackStatus}
          />
          <InvoicePreview
            invoiceData={this.props.invoiceData}
            me={this.props.me}
            invoiceEdit={this.props.invoiceEdit}
          />
        </Popup>
      </React.Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(SendInvoiceConfirmPopup)
