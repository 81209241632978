import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { facebookUnSync } from '@containers/manage-my-business/service'
import { gmbUnSyncEnterprise } from '@containers/enterprise/location/service'
import { COLLECT_UNPAID_INVOICES_SUCCESS_MSG } from '@helpers/constants'
import i18n from '@/i18n'

export default {
  state: {
    apiError: null,
    timeZones: [],
    invoices: [],
    collectUnpaidInvoicesError: false,
    subscriptionData: {}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        myAccountLoading: true,
        apiError: null
      }
    },
    onError(state, data) {
      const err =
        data && data.err_msg
          ? data.err_msg
          : data && data.message
          ? data.message
          : data && data.data && data.data.err_msg
          ? data.data.err_msg
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : i18n.t('common.please-try-again-later')
      notification('error', capitalize(err))
      console.log('err---------------------', err)
      return {
        ...state,
        myAccountLoading: false,
        apiError: err
      }
    },
    onSuccess(state, data, msg) {
      if (!msg) msg = data && data.message ? data.message : ''
      if (msg) {
        notification('success', capitalize(msg))
      }
      return {
        ...state,
        myAccountLoading: false
      }
    },
    async updateLocale(state) {
      console.log('STATE:', state)
      await service.patchLocale(localStorage.getItem('language'))
    },
    onGetTimeZones(state, data) {
      return {
        ...state,
        myAccountLoading: false,
        timeZones: data.data
      }
    },
    onGetUserApiData(state, data) {
      return {
        ...state,
        myAccountLoading: false,
        userApiData: data.data
      }
    },
    onRevokeApiKeySuccess(state, data) {
      console.log('state.userApiData ========', state.userApiData)
      console.log('data.data ========', data.data)
      const apiData = state.userApiData
      if (apiData) {
        apiData.api_enabled = data.data && data.data.api_enabled
        apiData.uuid = data.data && data.data.uuid
        apiData.key_viewed = false
      }
      return {
        ...state,
        myAccountLoading: false,
        userApiData: apiData || data.data
      }
    },
    onCreatePredefinedSuccess(state, data) {
      notification('success', i18n.t('myprofile.template-updated-successfully'))
      return {
        ...state,
        predefinedTemplates: data.data,
        myAccountLoading: false
      }
    },
    onConnectFacebookSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onConnectGoogleSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onConnectThumbtackSuccess(state, data) {
      return {
        ...state,
        myAccountLoading: false,
        me: { ...state.me, ...data.data }
      }
    },
    onUpdateUserSuccess(state, data, show) {
      if (show) notification('success', i18n.t('myprofile.profile-details-has-been-updated-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    onUnlockInvoicesSuccess(state, data) {
      return {
        ...state
      }
    },
    onUnsyncSuccess(state, data) {
      notification('success', i18n.t('myprofile.the-social-login-link-has-been-unlinked-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    ongetChargeBeePaymentMethodsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        hostedPages: data
      }
    },
    ongetCardListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        chargBeeCardList: data.cards,
        primaryCard: data.primary_payment_source_id
      }
    },
    ongetChargebeeSubscriptionSuccess(state, data) {
      return {
        ...state,
        loading: false,
        chargeBeeData: data && data.chargeBeeData
      }
    },
    onchangePasswordSelfServiceSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onlistActiveSubscriptionsSuccess(state, data) {
      let ongoingSubscriptions = []
      let totalSubscriptionAmount = 0
      let currentTotalSubscription = 0

      if (data && data.data && data.data.docs && data.data.docs.length > 0) {
        ongoingSubscriptions = data.data.docs
        currentTotalSubscription = data.data.total
        if (ongoingSubscriptions && ongoingSubscriptions.length) {
          ongoingSubscriptions.forEach((item) => {
            totalSubscriptionAmount += item.related_orders[0].amount
          })
        }
      }
      return {
        ...state,
        loading: false,
        currentTotalSubscription: currentTotalSubscription,
        ongoingSubscriptions: ongoingSubscriptions,
        totalSubscriptionAmount: totalSubscriptionAmount
      }
    },
    ongetSubscriptionSuccess(state, data) {
      let subscriptionData = {}
      let planDetails = {}
      let hasSubscription = false
      let invoiceEstimate = {}

      if (data.data) {
        subscriptionData = data.data
        planDetails = data.planDetails
        invoiceEstimate = data.estimate ? data.estimate.invoice_estimate : {}
        hasSubscription = true
      } else {
        hasSubscription = false
      }

      console.log(data)

      return {
        ...state,
        loading: false,
        subscriptionData: subscriptionData,
        planDetails: planDetails,
        invoiceEstimate: invoiceEstimate,
        hasSubscription: hasSubscription
      }
    },
    ontriggerContactSalesSuccess(state, data) {
      return {
        ...state,
        contactSalesStatus: data
      }
    },
    ontriggerContactSalesError(state, data) {
      return {
        ...state,
        contactSalesStatus: data
      }
    },
    ongetDclStatusSuccess(state, data) {
      return {
        ...state,
        loading: false,
        dclStatusData: data
      }
    },
    oncollectUnpaidInvoicesSuccess(state, data) {
      notification('success', COLLECT_UNPAID_INVOICES_SUCCESS_MSG)
      return {
        ...state,
        invoiceData: data,
        collectUnpaidInvoicesError: false
      }
    },
    onClearCollectUnpaidInvoicesError(state, data) {
      return {
        ...state,
        collectUnpaidInvoicesError: false
      }
    },
    oncollectUnpaidInvoicesError(state, data) {
      return {
        ...state,
        collectUnpaidInvoicesError: true
      }
    },
    onsetPrimaryCardSuccess(state, data, card) {
      notification('success', i18n.t('home.updated-successfully'))
      return {
        ...state,
        loading: false,
        primaryCard: card.pm_id
      }
    },
    onremoveCardSuccess(state, data, card) {
      notification('success', i18n.t('myprofile.card-removed-successfully'))
      const allCards = { ...state.chargBeeCardList }
      if (allCards && allCards.list) {
        for (let i = 0; i < allCards.list.length; i++) {
          if (card.pm_id === allCards.list[i].payment_source.id) {
            allCards.list.splice(i, 1)
          }
        }
      }
      return {
        ...state,
        loading: false,
        chargBeeCardList: allCards
      }
    },
    onLatestInvoiceSuccess(state, invoices) {
      return {
        ...state,
        latestInvoice: invoices[0]
      }
    }
  },
  effects: {
    async updateProfilePic(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateProfilePic(payload)
        this.onSuccess(res, i18n.t('myprofile.profile-photo-has-been-updated-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateProfileDetails(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateProfileDetails(payload)
        this.onUpdateUserSuccess(res, !(payload && payload.showalert === 'false'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },

    async updateNotification(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateNotification(payload)
        this.onSuccess(res, i18n.t('myprofile.notifications-settings-has-been-updated-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async changePassword(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.changePassword(payload)
        this.onSuccess(res)
        return res
      } catch (error) {
        const errorMsg = error.data && error.data.message
        this.onError({ message: errorMsg })
      }
    },
    async changePasswordSelfService(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.changePassword(payload)
        this.onchangePasswordSelfServiceSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getTimeZones(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getTimeZones(payload)
        this.onGetTimeZones(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUserApiData(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getUserApiData(payload)
        this.onGetUserApiData(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createPredefinedTemplate(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createPredefinedTemplate(payload)
        this.onCreatePredefinedSuccess(res)
        // return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateUserAccountSettings(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateUserAccountSettings(payload)
        this.onSuccess(res, i18n.t('settings.account-settings-has-been-updated-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async connectFacebook(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.connectFacebook(payload)
        this.onConnectFacebookSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async connectGoogle(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.connectGoogle(payload)
        this.onConnectGoogleSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async connectThumbtack(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.connectThumbtack(payload)
        this.onConnectThumbtackSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async disconnectThumbtack(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.disconnectThumbtack(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async verifyMobile(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.verifyMobile(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async onRequestOtpOnCall(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.onRequestOtpOnCall(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async verifyOtp(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.verifyOtp(payload)
        notification('success', i18n.t('settings.phone-number-has-been-verified-successfully'))
        this.onSuccess(res, '')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async unSyncSocialPlatform(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.unSyncSocialPlatform(payload)
        this.onUnsyncSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async unlockInvoices(payload) {
      this.onRequest()
      try {
        payload.data = {
          payment: {
            isInvoiceUnlocked: true
          }
        }
        const res = await service.unlockInvoicesForUser(payload)
        this.onUnlockInvoicesSuccess(res)
      } catch (e) {
        await this.onError(e)
      }
    },
    async userfacebookUnSync(payload, rootState) {
      try {
        const res = await facebookUnSync(payload)
        this.onUnsyncSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async userGmbUnSyncEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await gmbUnSyncEnterprise(payload)
        this.onSuccess(res, i18n.t('locations.google-account-unsynced-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async revokeApiKey(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.revokeApiKey(payload)
        this.onRevokeApiKeySuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async enableApiKey(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.enableApiKey(payload)
        await this.getUserApiData()
        // this.onRevokeApiKeySuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async disableApiKey(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.disableApiKey(payload)
        await this.getUserApiData()
        // this.onRevokeApiKeySuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async updateTwoWay(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateTwoWay(payload)
        let msg = i18n.t('myprofile.two-way-authentication-has-been-removed-successfully')
        if (payload.datas.two_factor_auth) {
          msg = i18n.t('myprofile.two-way-authentication-has-been-added-successfully')
        }
        this.onSuccess(res, msg)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async enableMFACognito(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.enableMFACognito(payload)
        const msg = i18n.t('myprofile.two-way-authentication-has-been-added-successfully')
        this.onSuccess(res, msg)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async disableMFACognito(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.disableMFACognito(payload)
        const msg = i18n.t('myprofile.two-way-authentication-has-been-removed-successfully')
        this.onSuccess(res, msg)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async removeProfilePic(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeProfilePic(payload)
        this.onSuccess(res, i18n.t('myprofile.profile-photo-removed-successfully'))
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async enableRestApi(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.enableRestApi()
        this.onSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getChargeBeePaymentMethods(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getChargeBeePaymentMethods()
        this.ongetChargeBeePaymentMethodsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getCardList(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getCardList()
        this.ongetCardListSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getChargebeeSubscription(payload) {
      this.onRequest()
      try {
        const res = await service.getChargebeeSubscription()
        this.ongetChargebeeSubscriptionSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getSubscription(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSubscription(payload)
        this.ongetSubscriptionSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getDclStatus(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getDclStatus(payload)
        if (res && res.isSubscribedUser) {
          this.ongetDclStatusSuccess(res)
        } else {
          this.ongetDclStatusSuccess({})
        }
        return res
      } catch (e) {
        console.log(e)
        this.ongetDclStatusSuccess({})
      }
    },
    async collectUnpaidInvoices(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.collectUnpaidInvoices(payload)
        this.oncollectUnpaidInvoicesSuccess(res)
        return res
      } catch (e) {
        console.log(e)
        this.oncollectUnpaidInvoicesError(e)
      }
    },
    clearCollectUnpaidInvoicesError(payload, rootState) {
      this.onClearCollectUnpaidInvoicesError()
    },
    async listActiveSubscriptions(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.listActiveSubscriptions(payload)
        this.onlistActiveSubscriptionsSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async triggerContactSales(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.triggerContactSales(payload)
        this.ontriggerContactSalesSuccess(res, payload)
        return res
      } catch (e) {
        console.log(e)
        this.ontriggerContactSalesError(e)
      }
    },
    async setPrimaryCard(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.setPrimaryCard(payload)
        this.onsetPrimaryCardSuccess(res, payload.data)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async removeCard(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeCard(payload)
        this.onremoveCardSuccess(res, payload.data)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getLatestInvoice(payload, rootState) {
      try {
        const res = await service.listInvoices({
          limit: 1,
          'sort_by[desc]': 'date'
        })
        this.onLatestInvoiceSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async getManagePaymentSourceHostedPage(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getCardList(payload)
        this.onsetPrimaryCardSuccess(res, payload.data)
        return res
      } catch (e) {
        console.log(e)
      }
    }
  }
}
