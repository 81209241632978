import React from 'react'
import './globelCreate.css'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import { history } from '@app/store'
// import { SendMessagePopup } from '@components/popups/sendMessagePopupBox'
import SendInvoicePopup from '@containers/payment/utility/send-invoice-popup'
import SendInvoiceConfirmPopup from '@containers/payment/utility/send-invoice-confirm-popup'
import SendInvoiceSuccessPopup from '@containers/payment/utility/send-invoice-success-popup'
import SendRecurringInvoicePopup from '@containers/payment/utility/send-recurring-invoice-popup'
import SendRecurringInvoiceConfirmPopup from '@containers/payment/utility/send-recurring-invoice-confirm-popup'
import SendPaymentLinkPopup from '@containers/payment/utility/send-payment-link-popup'
import AddTransactionStripePopUp from '@containers/payment/utility/addTransactionStripePopUp'
import AddTransactionPopUp from '@containers/payment/utility/addTransactionPopUp'
import AddBookingPopup from '@containers/booking/utility/add-booking-popup'
import AddContactPopup from '@containers/contacts-hub/utility/add-contact-popup'
import ContactPopup from '@containers/payment/utility/add-contact-popup'
import { withElements } from '@containers/payment/utility/stripe'

import { PAYMENT_STATUS } from '@containers/payment/constants'
import _ from 'lodash'
import * as service from '@containers/payment/service'
import { phoneNumberFormat, isDisabled, getFormatedToday, convertHoursToArray } from '@helpers/utility'
import { trackUNBEvent } from '@helpers/segmentEvents/unb'
import {
  segmentEventTrack,
  trackPaymentRequestSent,
  trackQuickPayRequestSent,
  trackInvoiceRequestSent,
  trackPaymentRequestComplete,
  trackManualTransactionComplete,
  trackRecurringInvoiceRequestSent
} from '@helpers/segment'
import SendEmailSmsPopup from '@components/popups/send-email-sms-popup'
import MessagePopup from '@components/popups/send-message-popup'
import notification from '@components/notification'
import CancelBookingPopup from '@containers/booking/utility/cancel-booking-popup'
import ConfirmBookingPopup from '@containers/booking/utility/confirm-booking-popup'
import { bookingActivity } from '@config/constants'
import BookingPaymentPopupContainer from '@components/popups/booking-payment-popup-container'
import SharableLinkPopup from '@containers/reviews/utility/shareableUrlPopup'
import { ReviewRequestPopup } from '@components/popups/reviewRequestPopup'
import { SendMessagePopup } from './popups/sendMessagePopupBox'
import { GlobalPopup } from './GlobalPopup'
import CreateTeamPopup from '@containers/manage-my-business/utility/createTeamPopup'
import SendBookingLinkPopup from '@components/popups/sendBookingLinkPopup'
import CreateGroupPopup from '@containers/contacts-hub/utility/create-group-popup'
import { parseName } from '@helpers/index'
import config from '@config/index'
import { ImportContactsMenuPopup } from '@components/popups/importContactsMenuPopup'
import { POPUP_TYPES } from '@containers/payment/utility/invoice-popup-helpers'
import AddServicePopup from '@containers/services/utility/add-service-popup'
import RemoveServicePopup from '@containers/services/utility/remove-service-popup'
import ConfirmModal from '@components/popups/basic-modal'
import Modal from 'react-bootstrap4-modal'

const FETCH_PAYMENTS_STATUS_QUERY = Object.values(PAYMENT_STATUS)
  .filter((status) => status !== PAYMENT_STATUS.DRAFT)
  .join(',')
const mapDispatchToProps = ({
  auth,
  payment,
  common,
  booking,
  contact,
  services,
  manageBusiness,
  accountsettings,
  paymentsGrowth,
  messenger,
  review
}) => {
  return {
    ...auth,
    ...payment,
    ...common,
    ...booking,
    ...contact,
    ...services,
    ...manageBusiness,
    ...accountsettings,
    getPaymentsStatusCard: paymentsGrowth.getPaymentsStatusCard,
    getPaymentsFirstTimeCards: paymentsGrowth.getPaymentsFirstTimeCards,
    ...messenger,
    ...review
  }
}
const mapStateToProps = ({
  auth,
  payment,
  common,
  booking,
  contact,
  services,
  manageBusiness,
  accountsettings,
  paymentsGrowth,
  messenger,
  review
}) => {
  return {
    ...auth,
    ...payment,
    ...common,
    ...booking,
    ...contact,
    ...services,
    ...accountsettings,
    ...manageBusiness,
    firstTimeActionCards: paymentsGrowth.actions,
    statusCard: paymentsGrowth.statusCard,
    ...messenger,
    ...review
  }
}
const appointmentFilter = [
  {
    label: 'All Booking',
    value: 'all'
  },
  {
    label: 'Pending Booking',
    value: 'pending'
  },
  {
    label: 'Canceled Booking',
    value: 'canceled'
  },
  {
    label: 'Incomplete Booking',
    value: 'incomplete'
  }
]

class GlobalCreatePopup extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      currentPage: 1,
      open: false,
      showMore: false,
      showSendSmsPopup: false,
      showInvoiceModal: false,
      invoiceItem: '',
      showGlobalPopup: true,
      openBookingModal: false,
      appointmentFilter: appointmentFilter,
      bookingType: 'new',
      showAddContact: false,
      openReviewRequestPopup: false,
      me: '',
      amount: '',
      statusType: '',
      servicesList: '',
      invoiceSaveLoader: false,
      trackedFirstTransactions: false,
      openPaymentLinkPopup: false,
      paymentLinkLoader: false,
      contactInfo: '',
      showAddNewUserPopup: false,
      openSendBookingLinkModal: false,
      submitting: false,
      showImportMenu: false,
      showRecurringInvoiceModal: false,
      showAddService: false,
      selectUser: {},
      selectedServicePrice: '',
      selectedServiceTitle: ''
    }
    this.limit = this.props.domainSettings && this.props.domainSettings.generalSettings
      ? Number(this.props.domainSettings.generalSettings.frontend_list_count)
      : config.frontendPaginationLimit
    if (window.innerWidth > 1024) {
      document.body.classList.add('side-menu-open')
    }
    this.invoiceEdit = this.invoiceEdit.bind(this)
    this.recurringInvoiceEditBeforeSubmit = this.recurringInvoiceEditBeforeSubmit.bind(this)
  }

  componentDidMount () {
    document.addEventListener('keyup', (e) => {
      if (e.keyCode === 27) {
        this.setState({
          showInvoiceModal: false,
          openPaymentLinkPopup: false,
          showTransactionModal: false,
          showRecurringInvoiceModal: false
        })
      }
    })
    this.init()
    console.log('this.props.getServices', this.props.getServices)
    console.log('this.props.permissions', this.props.permissions)
    console.log('this.limit', this.limit)
  }

  static getDerivedStateFromProps (props, state) {
    if (JSON.stringify(props.leadMessages) !== JSON.stringify(state.leadMessages)) {
      return {
        leadMessages: props.leadMessages
      }
    }
    return null
  }

  modalBackdropClicked () {
    if (this.props.modalBackdropClicked) {
      this.props.modalBackdropClicked()
    }
  }

  escFunction (event) {
    if (event.keyCode === 27) {
      this.modalBackdropClicked()
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.escFunction.bind(this), false)
  }

  showLess () {
    this.setState({ showMore: !this.state.showMore })
  }

  closeGetPaidModal () {
    this.setState({ showGetPaidModal: false })
  }

  openInvoiceModal () {
    trackUNBEvent(this.props.me, 'UNB_SendInvoice')
    this.setState({ showInvoiceModal: true, invoiceItem: '', showGlobalPopup: false })
    let data = {}
    data = {
      event: 'invoices_create',
      properties: {
        user_id: this.props.me._id,
        role: this.props.me.role_id.role_name,
        step: 'NewInvoice-Emptystate'
      }
    }
    if (this.props.me.primaryCategories && this.props.me.primaryCategories.category_name) {
      data.properties.business_category = this.props.me.primaryCategories.category_name
    }
    // segmentEventTrack(data)
  }

  async savePrivateContact (payload) {
    await this.props.savePrivateContact(payload)
    if (this.props.privateContact && this.props.privateContact.data) {
      this.props.segmentConatctTrack({ contactIds: [this.props.privateContact.data._id] })
    }
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_added',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
    return this.props.privateContact
  }

  async autoSuggestServices (payload) {
    await this.props.getServices(payload)
    let servicesList = []
    if (this.props.services) {
      this.props.services.forEach((element) => {
        const data = {
          label: element.title ? element.title : '',
          subLabel: element.price ? element.price : '',
          value: element._id
        }
        servicesList = [...servicesList, data]
      })
      await this.setState({ servicesList: servicesList })
    }
  }

  async confirmInvoice (payload) {
    this.closeInvoiceModal()
    this.setState({ showInvoiceSendTypeModal: true, invoiceData: payload, showGlobalPopup: false })
  }

  openGlobalPopupList () {
    this.setState({ showGlobalPopup: true })
  }

  closeInvoiceTypeModal (status = false) {
    if (status) this.trackAbandentPayments('abandon_payment_invoice')
    this.setState({ showInvoiceSendTypeModal: false, showGlobalPopup: true })
  }

  trackAbandentPayments (event) {
    let os = 'desktop'
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
      /Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform)
    ) {
      os = 'ios'
      if (/Android/.test(navigator.userAgent)) os = 'android'
    }
    const data = {
      event: event,
      properties: {
        user_id: this.props.me && this.props.me._id,
        customer_id: this.props.me && this.props.me.company_id,
        role:
          this.props.me &&
          this.props.me.role_id &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name === 'trial'
            ? 'free-trial'
            : this.props.me.role_id.role_name,
        business_category:
          this.props.me && this.props.me.primaryCategories && this.props.me.primaryCategories.category_name,
        platform: os,
        screen: 'payments'
      }
    }
    segmentEventTrack(data)
  }

  invoiceEdit (invoiceData = {}) {
    this.setState({
      showInvoiceSendTypeModal: false,
      invoiceItem: invoiceData,
      showInvoiceModal: true
    })
  }

  closeInvoiceSuccessModal () {
    this.setState({ showInvoiceSuccessModal: false, showGlobalPopup: true })
  }

  getTrackingProps () {
    const { _id = '' } = this.props.me
    const userUniqueId = _.get(this.props.me, 'user_unique_id', '')
    const companyId = _.get(this.props.me, 'company_id', '')
    const businessName = _.get(this.props.me, 'bussiness_name', '')
    const createdAt = _.get(this.props.me, 'created_at', '')
    const userEmail = _.get(this.props.me, 'user_email', '')
    const role = _.get(this.props.me, 'role_id.role_name', '')
    const businessCategory = _.get(this.props.me, 'primaryCategories.category_name', '')
    const mccCode = this.props.mccCodes

    const trackingProps = {
      user_id: _id,
      user_unique_id: userUniqueId,
      company_id: companyId,
      business_name: businessName,
      role,
      created_at: createdAt,
      mcc_code: mccCode,
      business_category: businessCategory,
      business_email_address: userEmail
    }
    return trackingProps
  }

  saveInvoiceDetails (payload) {
    return new Promise((resolve) => {
      this.props.saveInvoiceData(payload).then(function (res) {
        return resolve(res)
      })
    })
  }

  async saveInvoiceData (payload) {
    this.setState({ invoiceSaveLoader: true })
    // pass the extra properties for tracking transactions
    const trackingProps = this.getTrackingProps()
    var res = await this.props.saveInvoiceData(payload)
    if (!res) {
      res = await this.props.returnInvoiceData(payload)
    }
    console.log('res =====----- >>>>>>>>', res)
    if (!res) {
      this.setState({ invoiceSaveLoader: false })
      return this.closeInvoiceTypeModal()
    }
    this.closeInvoiceTypeModal()
    this.setState({ invoiceSaveLoader: false, showInvoiceSuccessModal: true, showGlobalPopup: false })

    const { data = {} } = res
    const { tip = 0 } = data
    const parentUser = _.get(data, 'parent_user', '')
    const invoiceStatus = _.get(data, 'invoice_status', '')
    const invoiceAmount = _.get(data, 'invoice_amount', 0)
    const invoiceDue = _.get(data, 'invoice_due', '')
    const collectPayments = _.get(data, 'collect_payments', false)
    const { reminder = false } = payload
    const customerPhone = _.get(payload, 'customer_phone', '')
    const customerEmail = _.get(payload, 'customer_email', '')
    const invoiceItems = _.get(payload, 'invoice_items', [])
    const sendType = _.get(payload, 'send_type', '')
    const platform = _.get(data, 'metadata.device')
    const sendSchedule = sendType === 'schedule' ? 'scheduled' : 'immediately'
    await service.trackTransactionStatusChange({
      userId: parentUser,
      status: invoiceStatus,
      amount: invoiceAmount,
      tip
    })

    /**
         * only track payments actions, booking is not included
            1) check if items contain custom_service
            2) aggregate all services and its quantity to "line_items_listed"
            */
    let customServiceStatus = false
    const lineItemsListed = {}
    invoiceItems.forEach((item) => {
      if (!item['service_id']) {
        customServiceStatus = true
      }
      const service = item['service_name']
      const quantity = parseInt(item['service_quantity'])
      lineItemsListed[service] = quantity
    })

    const paymentSentTrackingData = {
      amount: invoiceAmount,
      total: invoiceAmount,
      payment_request_type: 'invoice',
      platform,
      ...trackingProps
    }

    const invoiceSentTrackingData = {
      amount: invoiceAmount,
      send_to_contacthub: true,
      end_user_phone: customerPhone,
      end_user_email_address: customerEmail,
      send_schedule: sendSchedule,
      smart_reminder_status: reminder,
      due_date: invoiceDue,
      line_items_listed: lineItemsListed,
      line_items_count: invoiceItems.length,
      custom_service_status: customServiceStatus,
      platform,
      collect_payments: collectPayments,
      ...trackingProps
    }

    trackPaymentRequestSent(paymentSentTrackingData)
    trackInvoiceRequestSent(invoiceSentTrackingData)
    if (!payload.invoice_id) {
      let data = {}
      data = {
        event: 'invoices_sent',
        properties: {
          user_id: this.props.me._id,
          role: this.props.me.role_id.role_name,
          step: 'NewInvoice-ItemsAdded',
          id: this.props.privateContact.data._id,
          total: payload.invoice_amount,
          items: payload.invoice_items.length,
          send_type: payload.send_type
        }
      }
      if (this.props.me.primaryCategories && this.props.me.primaryCategories.category_name) {
        data.properties.business_category = this.props.me.primaryCategories.category_name
      }
      segmentEventTrack(data)
      this.firstTimePaymentTracking('invoice', payload.invoice_amount)
    }
  }

  async firstTimePaymentTracking (type, amount) {
    if (!this.state.trackedFirstTransactions) {
      await this.props.fetchPreviousTransCount({
        start: 0,
        limit: this.limit,
        source: 'invoice,getpaid,direct',
        need_invoice_item: true,
        status: this.state.statusType || FETCH_PAYMENTS_STATUS_QUERY,
        dateOnly: true
      })
      if (this.props.previousTransCount <= 1) {
        const trackData = {
          event: 'first_time_payment_requested',
          properties: {
            type: type,
            total: parseFloat(amount),
            role: this.props.me.role_id.role_name
          }
        }
        segmentEventTrack(trackData) // TRACK FIRST TRANSACTION
        this.setState({ trackedFirstTransactions: true })
      } else {
        this.setState({ trackedFirstTransactions: true })
      }
    }
  }

  closeInvoiceModal (status = false) {
    if (status && !this.state.showInvoiceSendTypeModal) this.trackAbandentPayments('abandon_payment_invoice')
    this.setState({ showInvoiceModal: false })
    this.openGlobalPopupList()
  }

  openRecurringInvoiceModal () {
    trackUNBEvent(this.props.me, 'UNB_RecurringInvoice')
    this.setState({
      showGlobalPopup: false,
      showRecurringInvoiceModal: true,
      recurringInvoicePopupType: POPUP_TYPES.CREATE,
      invoiceItem: ''
    })
  }

  async updateContact (payload) {
    await this.props.updateContact(payload)
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_updated',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
  }

  async autoSuggestContacts (payload) {
    await this.props.autosuggestContacts(payload)
    let contactList = []
    if (this.props.searchRes) {
      this.props.searchRes.forEach((element) => {
        if (element.type === 'contact') {
          const data = {
            label: element.first_name ? `${element.first_name} ${element.last_name ? element.last_name : ''}` : '',
            subLabel: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            value: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            type: 'contact',
            image: element.image ? element.image : '',
            contactObj: element
          }
          contactList = [...contactList, data]
        }
      })
      this.setState({ contactList: contactList })
    }
    return contactList
  }

  addBookingPopup () {
    trackUNBEvent(this.props.me, 'UNB_AddBooking')
    this.setState({ showGlobalPopup: false })
    this.setState({ openBookingModal: true })
  }

  async openBookingConfirmPopup (values) {
    this.setState({ bookingDetails: values })
    const { bookingType } = this.state
    if (bookingType === 'new' || (bookingType === 'edit' && values.status === 'incomplete')) {
      if (
        values.is_payment &&
        this.props.disabledProducts &&
        !isDisabled('payment', this.props.disabledProducts) &&
        this.props.me &&
        this.props.me.account_settings &&
        this.props.me.account_settings.payment &&
        this.props.me.account_settings.payment.merchant_id &&
        values.service_rate &&
        parseFloat(values.service_rate) > 0
      ) {
        this.setState({ bookingloading: true })
        if (bookingType === 'edit' && values.booking_id) {
          values.isEdit = true
          this.setState({ isEdit: true })
        }
        if (values.status === '') {
          delete values.status
        }
        await this.props.addBooking(values)
        this.setState({ bookingloading: false })
        if (this.props.bookingresponse && !this.props.bookingApiError) {
          this.setState({ bookingresponse: this.props.bookingresponse, bookingDetails: this.props.bookingresponse })
          this.closeAddBooking()
          this.setState({ showPaymentPopup: true, showGlobalPopup: false })
        }
      } else {
        this.closeAddBooking()
        this.setState({ showSendEmailSmsPopup: true })
        this.setState({ showGlobalPopup: false })
      }
    } else if (this.state.bookingType === 'edit') {
      const payload = { bookingId: this.state.editBookingId, data: { ...values } }
      this.setState({ bookingloading: true })
      await this.props.editBooking(payload)
      this.setState({ bookingloading: false })
      this.closeAddBooking()
    }
  }

  closeAddBooking () {
    this.setState({ openBookingModal: false, editBookingDetails: null })
    this.setState({ showGlobalPopup: true })
  }

  async cancelIncompleteBooking () {
    const { bookingresponse, editBookingDetails } = this.state
    if (bookingresponse) {
      const payload = { booking_id: bookingresponse._id }
      await this.props.cancelIncompleteBooking(payload)
    } else if (editBookingDetails) {
      const payload = { booking_id: editBookingDetails._id }
      await this.props.cancelIncompleteBooking(payload)
    }
    this.setState({
      bookingType: 'new',
      bookingresponse: null,
      editBookingDetails: null,
      showGlobalPopup: true
    })
    this.getBooking()
  }

  async getServiceDateSlots (val) {
    await this.props.getDateSlots(val.value)
    return this.props.dateSlots
  }

  async getServicesBySource() {
    await this.setState({ loading: true })
    var obj = {
      nopaging: true,
      search: this.state.searchKey ? this.state.searchKey : undefined,
      sort: this.state.itemKey ? this.state.itemKey : 'sort_order',
      source: 'direct'
    }
    if (this.props.me && this.props.me.role_id && this.props.me.role_id.role_name !== 'staff') {
      await this.props.getServices(obj)
    } else {
      await this.props.getStaffServices(obj)
    }
    await this.setState({ loading: false })
  }
  
  async createService (data) {
    const newSerVice = await this.props.createService(data)
    if (!this.props.AddServiceError && newSerVice) {
      const paylod = { userType: this.props.me && this.props.me.role_id && this.props.me.role_id.role_name }
      await this.props.getBookingServicesOnly(paylod)
      return newSerVice
    }
  }

  async getConfirmedBooking () {
    await this.props.fetchBookingData({
      start: (this.state.currentPage - 1) * this.limit,
      status: 'confirmed',
      limit: this.limit,
      nogroup: true,
      lead_type: 'appointment'
    })
  }

  getButtonText () {
    let name = 'CONTINUE'
    if (this.state.bookingDetails.collect_via === 'invoice') name = 'SEND  INVOICE'
    else if (this.state.bookingDetails.collect_via === 'getpaid') name = 'SEND  PAYMENT LINK'
    return name
  }

  closeBookingPayment () {
    this.setState({ showPaymentPopup: false, showGlobalPopup: true })
  }

  onBookingEdit () {
    const editBookingDetails = this.state.bookingresponse
      ? this.state.bookingresponse
      : this.state.editBookingDetails
        ? this.state.editBookingDetails
        : {}
    this.setState({ bookingType: 'edit', editBookingDetails })
    this.closeBookingPayment()
    this.addBooking('edit')
  }
  async addBookingSubmit (values) {
    this.setState({ bookingloading: true })

    const { bookingresponse } = this.state

    values.admin_offline = true
    delete values.isMobileChanged
    delete values.isEmailChanged
    delete values.isAddressChanged
    delete values.photo
    if (bookingresponse) {
      values.booking_id = bookingresponse._id
    }
    // if(values.booking_id){
    //     values.isEdit = true
    // }
    await this.props.addBooking(values)
    this.closeBookingPayment()

    if (!this.props.apiError) {
      this.setState({ bookingloading: false, bookingType: 'new', bookingresponse: null, editBookingDetails: null })
      notification('success', 'Appointment created successfully')
      if (!this.props.bookingApiError && this.props.bookingresponse && this.props.bookingresponse._id) {
        // this.openQuickView(this.props.bookingresponse)
        this.getBooking(false)
        setTimeout(() => {
          this.loadBookingDetails()
        }, 3000)
      }
    }
  }

  async loadBookingDetails () {
    if (this.props.bookingresponse && this.props.bookingresponse._id) {
      await Promise.all([
        new Promise(async (resolve, reject) => {
          await this.props.getBookingDetails(this.props.bookingresponse._id)
          resolve()
        }),
        new Promise(async (resolve, reject) => {
          await this.props.getBookingHistory(this.props.bookingresponse._id)
          resolve()
        })
      ])
    }
  }

  async init () {
    await this.props.fetchAccountSettings({ _id: this.props.me._id })
    if (this.props.accountsettings) {
      this.setState({
        userTimeZone: this.props.accountsettings.userTimeZone
      })
    }
    this.getAllBookingCount()
    this.getConfirmedBooking()
    if (!this.props.isActivity) {
      this.props.getBookingActivity({ limit: 1, start: 0, activity: 'all', activity_type: bookingActivity.toString() })
    }

    if (this.props.accountsettings && this.props.accountsettings.default_booking_filter) {
      appointmentFilter.forEach((item) => {
        if (item.value === this.props.accountsettings.default_booking_filter) {
          this.setState({ booking_status: item, selectedStatus: this.props.accountsettings.default_booking_filter })
        }
      })
    }
    if (this.props.accountsettings && this.props.accountsettings.booking_auto_confirm) {
      const filters = appointmentFilter.filter((item) => item.value !== 'pending')
      this.setState({ appointmentFilter: filters })
    } else {
      this.setState({ appointmentFilter: appointmentFilter })
    }
    const paylod = { userType: this.props.me && this.props.me.role_id && this.props.me.role_id.role_name }
    await this.props.getBookingServicesOnly(paylod)
    if (!this.props.businessSettings) {
      await this.props.fetchBusinessSettings()
    }
    await this.getBooking()
  }

  async getBooking (loader = true) {
    if (loader) this.setState({ loading: true })
    const payload = {
      start: (this.state.currentPage - 1) * this.limit,
      status: this.state.selectedStatus
        ? this.state.selectedStatus
        : this.props.accountsettings && this.props.accountsettings.default_booking_filter,
      limit: this.limit,
      nogroup: true,
      lead_type: 'appointment',
      sort: this.state.sortField,
      date: this.state.bookingDate,
      service: this.state.selectedService,
      asc: this.state.sortOrder === 'ASC' ? 1 : undefined
    }
    if (payload.status === 'upcoming') payload.start_datetime = getFormatedToday()
    await this.props.fetchBookingData(payload)
    this.setState({ loading: false })
  }

  async getAllBookingCount () {
    await this.props.getAllBookingCount({
      nogroup: true,
      lead_type: 'appointment',
      sort: 'all'
    })
  }

  async addBookingFromMessagepopup (values) {
    values.admin_offline = true
    delete values.isMobileChanged
    delete values.isEmailChanged
    delete values.isAddressChanged
    delete values.photo
    this.setState({ bookingloading: true })
    await this.props.addBooking(values)
    if (!this.props.apiError) {
      this.setState({ bookingloading: false, bookingType: 'new', bookingresponse: null, editBookingDetails: null })
      notification('success', 'Appointment created successfully')
      this.closeSendEmailSms()
      if (!this.props.bookingApiError && this.props.bookingresponse && this.props.bookingresponse._id) {
        // this.openQuickView(this.props.bookingresponse)
        this.setState({ showGlobalPopup: true })
        this.getBooking(false)
      }
    }
    this.setState({ bookingloading: false })
  }

  closeSendEmailSms () {
    this.setState({ showSendEmailSmsPopup: false })
  }

  async sendMessageToBooking ({ values, contactId, recipient, type, resetForm, payload }) {
    await this.props.sendMessageToContactMessenger({
      id: contactId,
      contact_type: type,
      recipient: recipient,
      ...values,
      media: payload.media ? payload.media : ''
    })

    if (!this.props.apiError) {
      this.messagePopup.closeModal(true, resetForm)
    }
  }

  async handleSubmitOtp ({ payload, resetForm }) {
    payload.enableTwilio = true
    await this.verifyPhoneOTP(payload)

    if (!this.props.apiError) {
      // await this.setState({
      //   showSendSmsPopup: false
      // })
      const me = this.props.me
      me.phone_verified = 1
      me.contact_phone = payload.contact_phone
      await this.props.onMeSuccess(me)
      await this.verifyGetMe()
      if (this.isTwilioEnable(me)) {
        await this.setState({ twilioEnabled: true, showSendSmsPopup: true })
      }
      return true
    } else {
      return false
    }
  }

  verifyPhoneOTP (payload) {
    return new Promise((resolve) => {
      this.props.smsVerifyOtp(payload).then(function (response) {
        resolve(response)
      })
    })
  }

  isTwilioEnable (me) {
    if (
      this.props.me &&
      this.props.me.account_settings &&
      this.props.me.account_settings.twilio &&
      this.props.me.account_settings.twilio.length
    ) {
      return true
    } else {
      return false
    }
  }

  verifyGetMe () {
    return new Promise((resolve) => {
      this.props.getMe().then(function (response) {
        resolve(response)
      })
    })
  }

  toggleShowSendSmsPopup () {
    this.setState({
      showSendSmsPopup: false
    })
  }

  showAddContactPopup () {
    trackUNBEvent(this.props.me, 'UNB_AddContact')
    this.setState({ showGlobalPopup: false })
    this.setState({ showAddContact: true })
  }

  async saveGroup ({ values, resetForm }) {
    await this.props.createGroups(values)
    this.props.fetchGroupsWithoutPaging()
    this.resolve({ group: this.props.createdGroup })
    this.createGroupPopup.closeModal(true, resetForm)
  }

  async openGroupCreatePopup () {
    await this.createGroupPopup.showPopup()
    await this.setState({ showGlobalPopup: false })

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  closeAddContact () {
    this.setState({ showAddContact: false, showGlobalPopup: true })
  }

  async addContact ({ values, resetForm }) {
    this.setState({ loading: true })
    await this.props.addContact(values)

    if (!this.props.apiError) {
      this.setState({ showAddContact: false })
      this.closeAddContact()
      const data = {
        event: 'contact_added',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!values.email,
          has_phone: !!values.mobile,
          has_address: !!(values.address || values.address2)
        }
      }
      segmentEventTrack(data)
      await this.props.getContactAnalytics()
      setTimeout(async () => {
        await this.getContacts()
        this.setState({ loading: false })
        this.setState({ showGlobalPopup: true })
      }, 1000)
    }
  }

  async getContacts () {
    this.setState({ loading: true })
    if (this.state.totalCount && this.state.totalCount > 0) {
      const minData = (this.state.currentPage - 1) * this.limit + 1
      if (minData > this.state.totalCount) {
        await this.setState({ currentPage: this.state.currentPage - 1 })
      }
    }
    await this.props.fetchContacts({
      start: (this.state.currentPage - 1) * this.limit,
      status: 'active',
      type: 'contact',
      limit: this.limit,
      search: this.state.search,
      group_id: this.state.group_id,
      sort: this.state.sortField,
      asc: this.state.sortOrder === 'ASC' ? 1 : undefined,
      source: this.state.source
    })
    await this.setState({ loading: false, totalCount: this.props.contacts && this.props.contacts.total })
  }
  openShareableUrlPopup () {
    this.shareableUrlPopup.showPopup({}, this.props.accountsettings, this.props.predefinedTemplates)
    this.setState({ showGlobalPopup: false })
  }

  async getContactList (payload) {
    await this.props.getContactListFromES(payload)
    if (this.props.contactList) {
      const contactList = this.props.contactList.data.map((item, index) => {
        return {
          label: `${item._source.full_name ? item._source.first_name : ''}`,
          subLabel: item._source.mobile,
          value: item._source.mobile
        }
      })
      this.setState({ contactList: contactList })
    }
  }

  async sendSms (payload, resetForm) {
    await this.props.sendSms(payload)
    this.shareableUrlPopup.closeModal(true, resetForm)
    if (this.props.tab && this.props.tab === 'review-requests') {
      await this.props.getReviewRequests({
        start: (this.state.currentPage - 1) * this.limit,
        limit: this.limit,
        status: 'all',
        sort: this.state.sortField,
        asc: this.state.sortOrder === 'ASC' ? 1 : undefined,
        search: this.state.search_key
      })
    }
  }

  showReviewRequestPopup () {
    trackUNBEvent(this.props.me, 'UNB_SendReviewRequest')
    this.setState({ showGlobalPopup: false })
    this.setState({ openReviewRequestPopup: true })
  }
  sendMessagePopup () {
    trackUNBEvent(this.props.me, 'UNB_Email_Sms') // sms
    this.setState({ showSendSmsPopup: true, showGlobalPopup: false })
  }

  showPaymentLinkPopup () {
    trackUNBEvent(this.props.me, 'UNB_Payment_Link')
    this.setState({ showGlobalPopup: false, openPaymentLinkPopup: true })
  }

  closePaymentLinkModal () {
    this.setState({ openPaymentLinkPopup: false, showGlobalPopup: true })
  }

  async sendGetPaidLink (payload) {
    this.setState({ paymentLinkLoader: true })

    try {
      const trackingProps = this.getTrackingProps()
      const res = await this.props.sendGetPaidLink({ payload: payload })
      this.closePaymentLinkModal()
      this.setState({ showGlobalPopup: true })
      const { data = {} } = res
      const { user = '', status = '', amount = 0, tip = 0 } = data
      await service.trackTransactionStatusChange({ userId: user, status, amount, tip })

      /**
       * sendGetPaidLink
       * 1) with a new created contact -> contact_id = "",
       * 2) with an existing contact -> contact_id = "*****"
       * 3) with a NOT saved contact -> no contact_id in payload
       */

      const contactId = _.get(payload, 'contact_id', null)
      const sendToContacthub = contactId !== null
      const customerPhone = _.get(data, 'customer_phone', '')
      const customerEmail = _.get(data, 'customer_email', '')
      const platform = _.get(data, 'meta_data.device')

      const paymentSentTrackingData = {
        amount,
        total: amount,
        payment_request_type: 'quick_pay',
        platform,
        ...trackingProps
      }
      const quickpaySentTrackingData = {
        amount,
        send_to_contacthub: sendToContacthub,
        end_user_phone: customerPhone,
        end_user_email_address: customerEmail,
        platform,
        ...trackingProps
      }

      trackPaymentRequestSent(paymentSentTrackingData)
      trackQuickPayRequestSent(quickpaySentTrackingData)
      this.firstTimePaymentTracking('quick-pay', payload.requested_amount)
      this.closePaymentLinkModal()
      this.firstTimePaymentTracking('quick-pay', payload.requested_amount)
      this.setState({ paymentLinkLoader: false, showAddContactModal: false })
    } catch (error) {
      this.setState({ paymentLinkLoader: false })
      console.log('error', error)
    }
  }

  closeAddContactModal () {
    this.setState({ showAddContactModal: false, openPaymentLinkPopup: true })
  }

  async showContactAddPopup (type, value, amount) {
    await this.setState({ contactType: type, contactVal: value, amount: amount || '' })
    this.setState({ showAddContactModal: true, showGlobalPopup: false })
    // this.closePaymentLinkModal()
  }

  async saveContactInfo (values) {
    this.props.addContact(values)
    this.setState({ showPaymentLinkPopup: true })

    const segmentData = {
      event: 'contact_added',
      properties: {
        role:
          this.props.me &&
          this.props.me.role_id &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name &&
          this.props.me.role_id.role_name === 'trial'
            ? 'free-trial'
            : this.props.me.role_id.role_name,
        has_email: !!values.email,
        has_phone: !!values.mobile,
        has_address: !!(values.address || values.address2)
      }
    }
    segmentEventTrack(segmentData)
    this.setState({ contactLoader: true })
    await this.setState({ contactInfo: values })
    await this.setState({ showPaymentLinkModal: true, contactLoader: false, showAddContactModal: false })
  }

  closeContactSyncModal () {
    this.setState({ showConatctSyncModal: false })
  }

  async showAddNewUserPopup (user) {
    trackUNBEvent(this.props.me, 'UNB_AddEmployee')
    await this.setState({ showAddNewUserPopup: true, showCreate: true, showGlobalPopup: false })

    if (user && user._id) {
      this.setState({ userData: user, type: 'edit' })
      await this.props.getUser({ _id: user._id })
      await this.fetchUserPermissions(user._id)
      await this.props.getCalendarData({ _id: user._id })
    } else {
      this.setState({ type: 'new' })
    }
  }

  async fetchUserPermissions (id) {
    await this.props.fetchUserRolePermissions({ user_id: id })
    if (this.props.teamUserPermissions) {
      let permissions = this.props.teamUserPermissions.permissions
      permissions = permissions.length && permissions.filter((item) => item.permissions && item.permissions.length)
      if (permissions.length) {
        permissions.forEach((item) => {
          if (item.permissions && item.permissions.length) {
            item.permissions.forEach((innerItem) => {
              if (this.props.teamUserPermissions.user_permissions.indexOf(innerItem._id) > -1) {
                innerItem.isSelected = true
              }
            })
          }
        })
        permissions.sort(function (a, b) {
          if (a.product_slug < b.product_slug) {
            return -1
          }
          if (a.product_slug > b.product_slug) {
            return 1
          }
          return 0
        })
        this.setState({ permissions: permissions })
      }
    }
  }

  hideCreatePopup () {
    this.setState({ showCreate: false, userData: null, showGlobalPopup: true })
  }

  async handleSubmitAddNewUser (values) {
    values.status = 'active'
    await this.props.createStaff({
      user_email: values.userEmail,
      display_name: values.displayName,
      first_name: values.firstName,
      last_name: values.lastName,
      qev: values.qev
    })
    if (!this.props.apiError) {
      this.setState({ stepValue: 2 })
      await this.props.getUser({ _id: this.props.newStaffId })
      if (this.props.teamUserData) {
        this.setState({ userData: this.props.teamUserData })
      }
      this.getStaffUsers()
      await this.fetchUserPermissions(this.props.newStaffId)
      await this.props.getCalendarData({ _id: this.props.newStaffId })
      this.setState({ type: 'edit' })
    }
  }

  async updateStaffUser (data) {
    await this.props.updateStaffUser(data)
    await this.getStaffUsers()
  }

  async userRolePermissions (payload) {
    payload.user_id = this.props.teamUserData._id
    await this.props.userRolePermissions(payload)
  }

  async sendWelcomeMessage (payload) {
    payload._id = this.props.teamUserData._id
    await this.props.sendWelcomeMessage(payload)
  }

  async openUnsyncPopup (type) {
    this.hideCreatePopup()
    this.setState({ unSyncType: type })
    const res = await this.unsyncPopup.showPopup()
    if (res) {
      const payload = { _id: this.props.teamUserData._id, data: { user_id: this.props.teamUserData._id } }
      await this.props.unLinkCalendar(payload)
    }
  }

  async getStaffUsers (status) {
    await this.props.getStaffUsers({
      start: 0,
      limit: 50,
      role: 'staff',
      status: status || 'all',
      source: 'all'
    })
  }

  // sendBooking Link
  async addBooking (data) {
    trackUNBEvent(this.props.me, 'UNB_AddBooking')
    const leadDetails = this.state.leadMessages && this.state.leadMessages.data ? this.state.leadMessages.data : ''
    await this.setState({ leadUser: { ...data, avatar: this.getChatImage(leadDetails) } })
    this.setState({ openSendBookingLinkModal: true, showGlobalPopup: false })
  }

  async sendBookingLink (data) {
    await this.props.sendBookingLink(data)
  }

  async onContactUpdated (isUpdate) {
    const leadMessages = { ...this.state.leadMessages }
    leadMessages.data.contact = isUpdate ? this.props.updatedContactDetails : this.props.addedContact
    await this.setState({ leadMessages: leadMessages })
  }

  async closeCreateGroupPopup () {
    await this.setState({ showGlobalPopup: true })
  }

  async openCreateGroupPopup () {
    trackUNBEvent(this.props.me, 'UNB_AddContactGroup')
    await this.createGroupPopup.showPopup('UNB')
    await this.setState({ showGlobalPopup: false })

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  async saveCreateGroupPopup ({ values, resetForm }) {
    await this.props.createGroups(values)
    this.props.fetchGroupsWithoutPaging()
    this.resolve({ group: this.props.createdGroup })
    this.createGroupPopup.closeModal(true, resetForm)
    await this.setState({ showGlobalPopup: true })
  }

  getChatImage (leadDetails) {
    if (leadDetails) {
      if (leadDetails && leadDetails.contact && (leadDetails.contact.avatar || leadDetails.contact.image)) {
        return leadDetails.contact.image || leadDetails.contact.avatar
      } else if (leadDetails && leadDetails.enduser_profile_pic) {
        return leadDetails.enduser_profile_pic
      } else {
        return 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
      }
    } else {
      return 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
    }
  }

  // Capture Payment
  openAddTransactionModal () {
    trackUNBEvent(this.props.me, 'UNB_CapturePayment')
    this.setState({ showGlobalPopup: false, showTransactionModal: true })
  }

  closeTransactionModal (status = false) {
    if (status) this.trackAbandentPayments('abandon_payment_make_manual')
    this.setState({ showGlobalPopup: true, showTransactionModal: false })
  }

  async serviceFetch () {
    const payload = { userType: this.props.me && this.props.me.role_id && this.props.me.role_id.role_name }
    await this.props.getBookingServicesOnly(payload)
  }

  async fetchServiceslot (id) {
    this.props.fetchServiceSlot({ id: id })
  }

  async addTransaction ({ payload, resetForm }) {
    this.setState({ showTransactionSummarryModal: true, invoiceData: null })
    if (payload && payload.card_holder) {
      const parsedName = parseName(payload.card_holder)
      payload.first_name = parsedName.first
      payload.last_name = parsedName.last
    }

    this.setState({ submitting: true })
    if (payload.isBooking) {
      await this.props.createBooking(payload)
    } else {
      try {
        let res
        if (payload.isStripe) {
          const { payment: { merchant_id: merchantId } = {} } = this.props.me.account_settings || {}
          payload.merchantId = merchantId
          res = await this.props.createStripeTransaction(payload)
          if (res && res.items && res.items.data) {
            if (res.items.data.status === 'paid') {
              res.items.data.status = 'accepted'
            }
          }
        } else {
          res = await this.props.createTransaction(payload)
        }

        notification('success', 'Transaction created successfully')

        this.closeTransactionModal()

        // For some reason the response above returns undefined, so we close the modal early
        const trackingProps = this.getTrackingProps()
        const { data = {} } = res.items
        const { user = '', status = '', amount = 0, tip = 0 } = data
        await service.trackTransactionStatusChange({ userId: user, status, amount, tip })

        const platform = _.get(data, 'meta_data.device')

        const paymentCompleteTrackingData = {
          enduser_payment_button_clicked: null,
          end_user_id: null,
          amount,
          total: amount,
          tip: 0,
          payment_request_type: 'business_owner_manual_entry',
          payment_collection_method: 'business_owner_manual_entry',
          platform,
          ...trackingProps
        }
        const manualTransactionCompleteTrackingData = {
          amount,
          total: amount,
          payment_collection_method: 'business_owner_manual_entry',
          platform,
          ...trackingProps
        }
        trackPaymentRequestComplete(paymentCompleteTrackingData)
        trackManualTransactionComplete(manualTransactionCompleteTrackingData)
      } catch (error) {
        console.log('error', error)
      }
    }
    if (!this.props.apiError && this.props.paymentData) {
      this.setState({ transactionPayload: payload })
      this.setState({ submitting: false })
      this.trackSegment(payload.amount)
      this.firstTimePaymentTracking('manual', payload.amount)
    } else {
      this.setState({ showTransactionSummarryModal: false, submitting: false })
    }
  }

  trackSegment (value) {
    let data = {}
    data = {
      event: 'payment_make_manual',
      properties: {
        customer_id: this.props.me.company_id,
        role: this.props.me.role_id.role_name,
        total: parseFloat(value)
      }
    }
    if (this.props.me.primaryCategories && this.props.me.primaryCategories.category_name) {
      data.properties.business_category = this.props.me.primaryCategories.category_name
    }
    segmentEventTrack(data)
  }

  // import contacts
  async importGoogleContacts () {
    trackUNBEvent(this.props.me, 'UNB_SyncGoogleContacts')
    this.setState({ showImportMenu: true, showGlobalPopup: false })
  }

  async importOutlookContacts () {
    trackUNBEvent(this.props.me, 'UNB_SyncPhoneContacts')
    this.setState({ showImportMenu: true, showGlobalPopup: false })
  }

  async importQuickBookContacts () {
    trackUNBEvent(this.props.me, 'UNB_SyncQuickBookContacts')
    this.setState({ showImportMenu: true, showGlobalPopup: false })
  }

  async importContacts () {
    trackUNBEvent(this.props.me, 'UNB_SyncContacts')
    this.setState({ showImportMenu: true, showGlobalPopup: false })
  }

  redirectToSetupAutoResponse = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SetupAutoResponse')
    history.push(`/${this.props.user.user_unique_id}/business-settings`)
    this.props.setTabRedirect('Messenger')
  }

  redirectToOutlookCalendar = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_ConnectOutlookCalendar')
    history.push(`/${this.props.user.user_unique_id}/appointment/settings`)
  }

  redirectToConnectGoogleCalendar = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_ConnectGoogleCalendar')
    history.push(`/${this.props.user.user_unique_id}/appointment/settings`)
  }

  redirectToSyncFacebookMessenger = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SyncFacebookMessenger')
    history.push(`/${this.props.user.user_unique_id}/business-settings`)
    this.props.setTabRedirect('General Information')
  }

  redirectToSyncThumbtackMessages = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SyncThumbtackMessages')
    history.push(`/${this.props.user.user_unique_id}/business-settings`)
    this.props.setTabRedirect('Integrations')
  }

  redirectToCreateReviewWidget = () => {
    trackUNBEvent(this.props.me, 'UNB_CreateReviewWidget')
    history.push(`/${this.props.user.user_unique_id}/reviews/widgets`)
  }

  redirectToSetupBusinessHours = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SetupBusinessHours')
    history.push(`/${this.props.user.user_unique_id}/business-settings`)
    this.props.setTabRedirect('Business Hours')
  }

  redirectToPhotoOrVideos = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_ImportPhotosVideos')
    history.push(`/${this.props.user.user_unique_id}/business-settings`)
    this.props.setTabRedirect('Media')
  }

  redirectToSetupNotifications = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SetupNotifications')
    history.push(`/${this.props.user.user_unique_id}/account-settings`)
    this.props.setTabRedirect('Notifications')
  }

  redirectToCreateBookingWidget = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_CreateBookingWidget')
    history.push(`/${this.props.user.user_unique_id}/appointment/settings`)
  }

  redirectToCreateChatWidget = () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_CreateChatWidget')
    history.push(`/${this.props.user.user_unique_id}/business-settings/message`)
    this.props.setTabRedirect('Messenger')
  }

  redirectToCreatePaymentWidget = () => {
    trackUNBEvent(this.props.me, 'UNB_CreatePaymentWidget')
    history.push(`/${this.props.user.user_unique_id}/transactions/widgets`)
  }

  closeRecurringInvoiceModal (status = false) {
    this.setState({ showRecurringInvoiceModal: false, recurringInvoicePopupType: '', showGlobalPopup: true })
  }

  async confirmRecurringInvoice (payload) {
    this.closeRecurringInvoiceModal()
    this.setState({ showRecurringInvoiceSendTypeModal: true, recurringInvoiceData: payload, showGlobalPopup: false })
  }

  closeRecurringInvoiceTypeModal () {
    this.setState({ showRecurringInvoiceSendTypeModal: false, showGlobalPopup: true })
  }

  recurringInvoiceEditBeforeSubmit (invoiceData = {}) {
    this.setState({
      showRecurringInvoiceSendTypeModal: false,
      invoiceItem: invoiceData,
      recurringInvoicePopupType: POPUP_TYPES.EDIT_PREVIEW,
      showRecurringInvoiceModal: true,
      showGlobalPopup: false
    })
  }

  async saveRecurringInvoiceData (payload) {
    this.setState({ recurringInvoiceSaveLoader: true })
    const res = await this.props.saveRecurringInvoiceData(payload)
    const trackingProps = this.getTrackingProps()

    const { immediateInvoice = {}, recurringSeries = {} } = res
    const invoiceAmount = _.get(immediateInvoice, 'invoice_amount', 0)
    const invoiceDue = _.get(immediateInvoice, 'invoice_due', '')
    const collectPayments = _.get(immediateInvoice, 'collect_payments', false)
    const { reminder = false } = payload
    const customerPhone = _.get(payload, 'customer_phone', '')
    const customerEmail = _.get(payload, 'customer_email', '')
    const invoiceItems = _.get(payload, 'invoice_items', [])
    const sendType = _.get(payload, 'send_type', '')
    const platform = _.get(immediateInvoice, 'metadata.device')
    const sendSchedule = sendType === 'schedule' ? 'scheduled' : 'immediately'
    const startDate = _.get(recurringSeries, 'created_at')
    const endDate = _.get(payload, 'end_date')
    const endDateSelected = _.get(payload, 'end_type')
    const dueDateSelected = _.get(recurringSeries, 'due_date')
    const repeatSchedule = this.getRepeatScheduleDetails(recurringSeries)
    let customServiceStatus = false
    const lineItemsListed = {}
    invoiceItems.forEach((item) => {
      if (!item['service_id']) {
        customServiceStatus = true
      }
      const service = item['service_name']
      const quantity = parseInt(item['service_quantity'])
      lineItemsListed[service] = quantity
    })

    const recurringInvoiceSentTrackingData = {
      amount: invoiceAmount,
      send_to_contacthub: true,
      end_user_phone: customerPhone,
      end_user_email_address: customerEmail,
      send_schedule: sendSchedule,
      smart_reminder_status: reminder,
      due_date: invoiceDue,
      line_items_listed: lineItemsListed,
      line_items_count: invoiceItems.length,
      custom_service_status: customServiceStatus,
      platform,
      collect_payments: collectPayments,
      repeat_schedule: repeatSchedule,
      start_date: startDate,
      end_date_selected: endDateSelected,
      end_date: endDate,
      due_date_selected: dueDateSelected,
      ...trackingProps
    }
    this.setState({ showRecurringInvoiceSendTypeModal: false, recurringInvoiceSaveLoader: false })
    if (res && !('error' in res)) {
      this.setState({ showRecurringInvoiceSuccessModal: true })
      trackRecurringInvoiceRequestSent(recurringInvoiceSentTrackingData)
    }
  }

  closeRecurringInvoiceSuccessModal () {
    this.setState({ showRecurringInvoiceSuccessModal: false, showGlobalPopup: true })
  }

  getRepeatScheduleDetails (recurringSeries = {}) {
    const frequency = _.get(recurringSeries, 'recurring_frequency')
    const recurringDay = _.get(recurringSeries, 'recurring_day')
    if (!['first', 'fifteenth'].includes(recurringDay)) {
      return frequency.replace(' ', '_') + '_on_' + recurringDay + 's'
    } else if (frequency === 'every month') {
      if (recurringDay === 'first') {
        return 'every_1st_of_the_month'
      } else if (recurringDay === 'fifteenth') {
        return 'every_15th_of_the_month'
      }
    }
    return ''
  }

  // Add Service
  async openAddservicePopup () {
    trackUNBEvent(this.props.me, 'UNB_AddService')
    await this.setState({ showGlobalPopup: false, showAddService: true })
    await this.AddServicePopUp.showPopup('', 'add')
  }

  async UpdateService (data, resetForm) {
    console.log('UpdateService ', data)
    this.setState({ updatedServiceObj: data, resetForm: resetForm })
    if (
      parseFloat(data.data.price) !== this.state.selectedServicePrice ||
      data.data.title !== this.state.selectedServiceTitle
    ) {
      await this.props.getRecurrenceInvoiceList(data.data._id)
      if (this.props.recurrenceInvoiceList && this.props.recurrenceInvoiceList > 0) {
        this.setState({ showPriceConfirmModal: true })
      } else {
        this.updateSelectedService(data, resetForm)
      }
    } else {
      this.updateSelectedService(data, resetForm)
    }
  }

  async getServiceUserFunction (data) {
    await this.props.getServiceUser({ _id: data })
  }

  async changeshowAddService () {
    this.setState({
      showAddService: false
    })
  }

  async updateSelectedService (data, resetForm) {
    if (data.data && data.data.old_user_id === data.data.user_id) {
      await this.props.UpdateService(data)
      if (!this.props.AddServiceError) {
        if (this.props.me && this.props.me.role_id && this.props.me.role_id.role_name !== 'staff') {
          await await this.props.getServices({ nopaging: true, sort: 'sort_order', asc: true })
        } else {
          await this.props.getStaffServices({ nopaging: true, sort: 'sort_order', asc: true })
        }
        this.AddServicePopUp.closeModal(true, resetForm)
      } else {
        this.AddServicePopUp.stopSpinner()
      }
    } else {
      console.log('assigned user changed')
      this.setState({ selectUser: data.data && data.data.selectUser ? data.data.selectUser : {} })
      this.setState({ updateData: data, resetForm })
      this.setState({ showLocationUpdateModal: true })
    }
  }

  async AddService (data, resetForm) {
    await this.props.createService(data)
    if (!this.props.AddServiceError) {
      if (this.props.me && this.props.me.role_id && this.props.me.role_id.role_name !== 'staff') {
        await this.props.getServices({ nopaging: true, sort: 'sort_order', asc: true })
      } else {
        await this.props.getStaffServices({ nopaging: true, sort: 'sort_order', asc: true })
      }
      this.AddServicePopUp.closeModal(true, resetForm)
      this.setState({ showGlobalPopup: true })
    } else {
      this.AddServicePopUp.stopSpinner()
      this.setState({ showGlobalPopup: true })
    }
  }

  async handleMangeClick (selected, value) {
    if (value.name === 'edit') {
      await this.setState({ showAddService: true })
      if (selected && selected.price) {
        this.setState({ selectedServicePrice: selected.price, selectedServiceTitle: selected.title })
      }
      await this.AddServicePopUp.showPopup(selected, 'edit')
    } else if (value.name === 'remove') {
      const res = await this.deletePopup.showPopup()
      if (res) {
        await this.props.deleteServices(selected._id)
        this.getServices()
      }
    }
  }

  async closePriceUpdateModal () {
    this.setState({ showPriceConfirmModal: false })
    this.AddServicePopUp.stopSpinner()
  }

  closeLocationUpdateModal () {
    if (this.AddServicePopUp) {
      this.AddServicePopUp.closeModal(true, this.state.resetForm)
    }
    this.setState({ showLocationUpdateModal: false })
  }

  async updateServiceLocations (flag) {
    this.setState({ showLocationUpdateModal: false })
    const payload = this.state.updateData
    if (flag) {
      payload.data.migrate_appointments = true
    } else {
      payload.data.cancel_appointments = true
    }
    await this.props.UpdateService(payload)
    if (!this.props.AddServiceError) {
      if (this.props.me && this.props.me.role_id && this.props.me.role_id.role_name !== 'staff') {
        await await this.props.getServices({ nopaging: true, sort: 'sort_order', asc: true })
      } else {
        await this.props.getStaffServices({ nopaging: true, sort: 'sort_order', asc: true })
      }
      this.AddServicePopUp.closeModal(true, this.state.resetForm)
    } else {
      this.AddServicePopUp.stopSpinner()
    }
  }

  async cancelPriceUpdateModal () {
    this.setState({ showPriceConfirmModal: false })
    this.updateSelectedService(this.state.updatedServiceObj, this.state.resetForm)
  }

  async submitPriceUpdateModal () {
    this.setState({ showPriceConfirmModal: false })
    this.state.updatedServiceObj.data.updateInvoices = true
    this.updateSelectedService(this.state.updatedServiceObj, this.state.resetForm)
  }

  render () {
    const { onBoardingData, me = {} } = this.props
    const { showGlobalPopup } = this.state
    const twilioEnabled = Boolean(
      me && me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
    )
    const hasMid = !!(
      me &&
      me.account_settings &&
      me.account_settings.payment &&
      me.account_settings.payment.merchant_id
    )

    return (
      <>
        {Boolean(this.state.showInvoiceModal) && (
          <SendInvoicePopup
            open={this.state.showInvoiceModal}
            closeModal={this.closeInvoiceModal.bind(this)}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            contactList={this.state.contactList}
            me={this.props.me}
            savePrivateContact={this.savePrivateContact.bind(this)}
            privateContact={this.props.privateContact}
            confirmInvoice={this.confirmInvoice.bind(this)}
            products={this.props.products}
            autoSuggestServices={this.autoSuggestServices.bind(this)}
            servicesList={this.state.servicesList}
            invoiceItem={this.state.invoiceItem}
            paymentStatus={onBoardingData.status}
            hasMid={hasMid}
            showInstruction={'create_invoice' in this.props.firstTimeActionCards}
            permissions={this.props.permissions}
          />
        )}
        {Boolean(this.state.showInvoiceSendTypeModal) && (
          <SendInvoiceConfirmPopup
            open={this.state.showInvoiceSendTypeModal}
            closeModal={this.closeInvoiceTypeModal.bind(this)}
            invoiceData={this.state.invoiceData}
            me={this.props.me}
            invoiceEdit={this.invoiceEdit}
            history={this.props.history}
            saveInvoiceData={this.saveInvoiceData.bind(this)}
            invoiceSaveLoader={this.state.invoiceSaveLoader}
            // updateContact={this.props.updateContact}
            updateContact={this.updateContact.bind(this)}
            paymentFeedbackStatus={
              this.props.accountsettings && this.props.accountsettings.payment_feedback
                ? this.props.accountsettings.payment_feedback
                : false
            }
          />
        )}
        {showGlobalPopup ? (
          <GlobalPopup
            me={this.props.me}
            isReadOnlyRole={this.props.isReadOnlyRole}
            products={this.props.products}
            disabledProducts={this.props.disabledProducts}
            user={this.props.user}
            showMore={this.state.showMore}
            sendMessagePopup={this.sendMessagePopup.bind(this)}
            isReviewDeletePermitted={this.props.permissions.isReviewDeletePermitted}
            showReviewRequestPopup={this.showReviewRequestPopup.bind(this)}
            isContactCreatePermitted={this.props.permissions.isContactCreatePermitted}
            showAddContactPopup={this.showAddContactPopup.bind(this)}
            isBookingPermitted={this.props.permissions.isBookingPermitted}
            addBookingPopup={this.addBookingPopup.bind(this)}
            twilioEnabled={twilioEnabled}
            isPaymentPermitted={this.props.permissions.isPaymentPermitted}
            isMessengerSendPermitted={this.props.permissions.isMessengerSendPermitted}
            isInvoiceUnlocked={
              this.props.permissions.isPaymentPermitted &&
              _.get(this.props.me, 'account_settings.payment.isInvoiceUnlocked', false)
            }
            openInvoiceModal={this.openInvoiceModal.bind(this)}
            isPaymentLinkPermitted={
              this.props.permissions.isPaymentPermitted &&
              Boolean(_.get(this.props.me, 'account_settings.payment.merchant_id'))
            }
            showPaymentLinkPopup={this.showPaymentLinkPopup.bind(this)}
            closeModal={this.props.closeModal.bind(this)}
            showLess={this.showLess.bind(this)}
            showAddMemberPopup={this.showAddNewUserPopup.bind(this)}
            sendBookingLink={this.addBooking.bind(this)}
            isContactEditPermitted={this.props.permissions.isContactEditPermitted}
            opencreategroupPopup={this.openCreateGroupPopup.bind(this)}
            paymentApplnstatus={onBoardingData.status}
            openAddTransactionModal={this.openAddTransactionModal.bind(this)}
            redirectToOutlookCalendar={this.redirectToOutlookCalendar.bind(this)}
            redirectToConnectGoogleCalendar={this.redirectToConnectGoogleCalendar.bind(this)}
            importGoogleContacts={this.importGoogleContacts.bind(this)}
            importOutlookContacts={this.importOutlookContacts.bind(this)}
            importQuickBookContacts={this.importQuickBookContacts.bind(this)}
            importContacts={this.importContacts.bind(this)}
            setupAutoResponse={this.redirectToSetupAutoResponse.bind(this)}
            syncFacebookMessenger={this.redirectToSyncFacebookMessenger.bind(this)}
            syncThumbtackMessages={this.redirectToSyncThumbtackMessages.bind(this)}
            createReviewWidget={this.redirectToCreateReviewWidget}
            redirectToSetupBusinessHours={this.redirectToSetupBusinessHours.bind(this)}
            redirectToPhotoOrVideos={this.redirectToPhotoOrVideos.bind(this)}
            redirectToSetupNotifications={this.redirectToSetupNotifications.bind(this)}
            redirectToCreateBookingWidget={this.redirectToCreateBookingWidget.bind(this)}
            redirectToCreateChatWidget={this.redirectToCreateChatWidget.bind(this)}
            redirectToCreatePaymentWidget={this.redirectToCreatePaymentWidget.bind(this)}
            openRecurringInvoiceModal={this.openRecurringInvoiceModal.bind(this)}
            onBoardingData={this.props.onBoardingData}
            showAddServicePopup={this.openAddservicePopup.bind(this)}
            payment_v2={this.props.payment_v2}
            invoices_v2={this.props.invoices_v2}
          />
        ) : (
          ''
        )}
        {Boolean(this.state.openPaymentLinkPopup) && (
          <SendPaymentLinkPopup
            open={this.state.openPaymentLinkPopup}
            closeModal={this.closePaymentLinkModal.bind(this)}
            searchRes={this.state.contactList}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            sendGetPaidLink={this.sendGetPaidLink.bind(this)}
            contactList={this.state.contactList}
            me={me}
            products={this.props.products}
            showContactAddPopup={this.showContactAddPopup.bind(this)}
            // amount={this.state.amount}
            // contactInfo={this.state.contactInfo}
            paymentLinkLoader={this.state.paymentLinkLoader}
            showInstruction={'text_pay' in this.props.firstTimeActionCards}
          />
        )}

        {Boolean(this.state.showAddContactModal) && (
          <ContactPopup
            open={this.state.showAddContactModal}
            closeContactModal={this.closeAddContactModal.bind(this)}
            saveContactInfo={this.saveContactInfo.bind(this)}
            contactVal={this.state.contactVal}
            contactType={this.state.contactType}
            sendGetPaidLink={this.sendGetPaidLink.bind(this)}
            amount={this.state.amount}
            me={me}
            contactLoader={this.state.contactLoader}
            paymentLinkLoader={this.state.paymentLinkLoader}
          />
        )}

        {this.state.showInvoiceSuccessModal ? (
          <SendInvoiceSuccessPopup
            invoiceData={this.state.invoiceData}
            close={this.closeInvoiceSuccessModal.bind(this)}
            me={me}
            // isMessenger={true}
            showAdBanner={false}
            transactionId={this.state.transactionId}
          />
        ) : (
          ''
        )}
        {this.state.openBookingModal ? (
          <AddBookingPopup
            disabledProducts={this.props.disabledProducts}
            ref={(ref) => (this.bookingPopup = ref)}
            open={this.state.openBookingModal}
            serviceList={this.props.serviceList}
            services={this.props.services}
            getServices={this.getServicesBySource.bind(this)}
            predefinedTemplates={this.props.predefinedTemplates}
            getServiceDateSlots={this.getServiceDateSlots.bind(this)}
            processbooking={this.state.bookingloading}
            onSubmit={this.openBookingConfirmPopup.bind(this)}
            close={this.closeAddBooking.bind(this)}
            getUnAvailableDateSlots={this.props.getUnAvailableDateSlots}
            unAvailabledateSlots={this.props.unAvailabledateSlots}
            products={this.props.products}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            contactList={this.state.contactList}
            savePrivateContact={this.savePrivateContact.bind(this)}
            privateContact={this.props.privateContact}
            me={this.props.me}
            accountsettings={this.props.accountsettings}
            editBookingDetails={this.state.editBookingDetails}
            bookingType={this.state.bookingType}
            businessSettings={this.props.businessSettings}
            user={this.props.user}
            userTimeZone={this.state.userTimeZone}
            cancelIncompleteBooking={this.cancelIncompleteBooking.bind(this)}
            createService={this.createService.bind(this)}
          />
        ) : null}
        {this.state.showSendEmailSmsPopup ? (
          <SendEmailSmsPopup
            open={this.state.showSendEmailSmsPopup}
            module="Booking"
            payloadData={this.state.bookingDetails}
            submitText={'ADD BOOKING'}
            closeModal={this.closeSendEmailSms.bind(this)}
            submitData={this.addBookingFromMessagepopup.bind(this)}
            loader={this.state.bookingloading}
            // updateContact={this.props.updateContact}
            updateContact={this.updateContact.bind(this)}
            predefinedTemplates={this.props.predefinedTemplates}
          />
        ) : null}
        {/* <MessagePopup
            ref={(ref) => (this.messagePopup = ref)}
            id={'messagePopUp'}
            onSubmit={this.sendMessageToBooking.bind(this)}
          /> */}
        {this.state.showSendSmsPopup ? (
          <MessagePopup
            ref={(ref) => (this.messagePopup = ref)}
            onSubmit={this.sendMessageToBooking.bind(this)}
            verifyMobile={this.props.verifyMobile}
            onRequestOtpOnCall={this.props.onRequestOtpOnCall}
            verifyOtp={this.props.smsVerifyOtp}
            handleSubmitOtp={this.handleSubmitOtp.bind(this)}
            me={this.props.me}
            close={this.toggleShowSendSmsPopup.bind(this)}
            twilioEnabled={this.state.twilioEnabled}
          />
        ) : (
          ''
        )}

        <CancelBookingPopup ref={(ref) => (this.CancelBookingPopup = ref)} />
        <ConfirmBookingPopup ref={(ref) => (this.ConfirmBookingPopup = ref)} />
        {this.state.showPaymentPopup ? (
          <BookingPaymentPopupContainer
            open={this.state.showPaymentPopup}
            module="Booking"
            me={this.props.me}
            isEdit={this.state.isEdit}
            payloadData={this.state.bookingDetails}
            submitText={this.getButtonText()}
            submitData={this.addBookingSubmit.bind(this)}
            closeModal={this.closeBookingPayment.bind(this)}
            predefinedTemplates={this.props.predefinedTemplates}
            cancelIncompleteBooking={this.cancelIncompleteBooking.bind(this)}
            onBookingEdit={this.onBookingEdit.bind(this)}
            loader={this.state.bookingloading}
          />
        ) : null}
        {this.state.showAddContact ? (
          <AddContactPopup
            ref={(ref) => (this.addContactPopup = ref)}
            groups={this.props.allGroups}
            open={this.state.showAddContact}
            addGroups={this.saveGroup.bind(this)}
            newGroup={this.props.createdGroup}
            openCreatePopUp={this.openGroupCreatePopup.bind(this)}
            close={this.closeAddContact.bind(this)}
            onSubmit={this.addContact.bind(this)}
            id={'modalAddContact'}
          />
        ) : null}
        <SharableLinkPopup
          ref={(ref) => {
            this.shareableUrlPopup = ref
          }}
          getContactList={this.getContactList.bind(this)}
          contactList={this.state.contactList}
          sendSms={this.sendSms.bind(this)}
          me={this.props.me}
          products={this.props.products}
          reportAdmin={this.props.reportAdmin}
          openGlobalPopupList={this.openGlobalPopupList.bind(this)}
        />
        {this.state.openReviewRequestPopup ? (
          <ReviewRequestPopup
            open={this.state.openReviewRequestPopup}
            onClose={() => this.setState({ openReviewRequestPopup: false, showGlobalPopup: true })}
          />
        ) : (
          ''
        )}
        {this.state.showSendSmsPopup ? (
          <SendMessagePopup
            open={this.state.showSendSmsPopup}
            onClose={() => this.setState({ showSendSmsPopup: false, showGlobalPopup: true })}
            onSendMsgSuccess={(threadId) => {
              this.setState({ showSendSmsPopup: false, isNodata: false })
            }}
            onRequestOtp={this.props.verifyMobile}
            meData={this.props.me}
            apiError={this.props.apiError ? this.props.apiError : ''}
            verifyOtp={this.handleSubmitOtp.bind(this)}
            onRequestOtpOnCall={this.props.onRequestOtpOnCall}
            securityText={
              'For security reasons, you’ll need to set up 2-factor-authentication to use GoSite’s SMS capabilities.'
            }
            title={'First, Verify your account'}
          />
        ) : (
          ''
        )}
        {this.state.showCreate ? (
          <CreateTeamPopup
            open={this.state.showCreate}
            close={this.hideCreatePopup.bind(this)}
            handleSubmitAddNewUser={this.handleSubmitAddNewUser.bind(this)}
            updateStaffUser={this.updateStaffUser.bind(this)}
            userRolePermissions={this.userRolePermissions.bind(this)}
            sendWelcomeMessage={this.sendWelcomeMessage.bind(this)}
            apiError={this.props.apiError}
            userData={this.props.user}
            permissions={this.state.permissions}
            teamUserCalendar={this.props.teamUserCalendar}
            type={this.state.type}
            openUnsyncPopup={this.openUnsyncPopup.bind(this)}
            me={this.props.me}
          />
        ) : null}
        <CreateGroupPopup
          ref={(ref) => (this.createGroupPopup = ref)}
          closeCreateGroupPopup={this.closeCreateGroupPopup.bind(this)}
          onSubmit={this.saveCreateGroupPopup.bind(this)}
        />

        {/* sendBookingLink */}
        <SendBookingLinkPopup
          open={this.state.openSendBookingLinkModal}
          contactDetails={this.state.leadUser}
          onClose={() => {
            this.setState({ openSendBookingLinkModal: false, showGlobalPopup: true })
          }}
          onSendBookingLink={this.sendBookingLink.bind(this)}
          disableContactsImport
          // onContactUpdated={this.onContactUpdated.bind(this)}
        />

        {/* Capture Payment */}
        {Boolean(this.state.showTransactionModal) &&
          (_.get(this.props.me, 'account_settings.payment_type') === 'stripe' ? (
            <AddTransactionStripePopUp
              me={this.props.me}
              close={this.closeTransactionModal.bind(this)}
              submitting={this.state.submitting}
              products={this.props.products}
              serviceFetch={this.serviceFetch.bind(this)}
              serviceList={this.props.serviceList ? this.props.serviceList : []}
              fetchServiceslot={this.fetchServiceslot.bind(this)}
              serviceTimeSlots={this.props.serviceTimeSlots ? this.props.serviceTimeSlots : []}
              predefinedTemplates={this.props.predefinedTemplates}
              onSubmit={this.addTransaction.bind(this)}
              invoiceData={this.state.invoiceData}
            />
          ) : (
            <AddTransactionPopUp
              me={this.state.me}
              close={this.closeTransactionModal.bind(this)}
              submitting={this.state.submitting}
              products={this.props.products}
              ref={(ref) => (this.AddTransactionPopUp = ref)}
              serviceFetch={this.serviceFetch.bind(this)}
              serviceList={this.props.serviceList ? this.props.serviceList : []}
              fetchServiceslot={this.fetchServiceslot.bind(this)}
              serviceTimeSlots={this.props.serviceTimeSlots ? this.props.serviceTimeSlots : []}
              predefinedTemplates={this.props.predefinedTemplates}
              onSubmit={this.addTransaction.bind(this)}
              invoiceData={this.state.invoiceData}
            />
          ))}
        <ImportContactsMenuPopup
          open={this.state.showImportMenu}
          onClose={() => {
            this.setState({ showImportMenu: false, showGlobalPopup: true })
          }}
        />

        {Boolean(this.state.showRecurringInvoiceModal) && (
          <SendRecurringInvoicePopup
            open={this.state.showRecurringInvoiceModal}
            closeModal={this.closeRecurringInvoiceModal.bind(this)}
            invoiceItem={this.state.invoiceItem}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            products={this.props.products}
            contactList={this.state.contactList}
            openInvoiceModal={this.openInvoiceModal.bind(this)}
            me={this.props.me}
            savePrivateContact={this.savePrivateContact.bind(this)}
            privateContact={this.props.privateContact}
            autoSuggestServices={this.autoSuggestServices.bind(this)}
            servicesList={this.state.servicesList}
            hasMid={hasMid}
            confirmInvoice={this.confirmRecurringInvoice.bind(this)}
            paymentStatus={onBoardingData.status}
            popupType={this.state.recurringInvoicePopupType}
          />
        )}
        {Boolean(this.state.showRecurringInvoiceSendTypeModal) && (
          <SendRecurringInvoiceConfirmPopup
            open={this.state.showRecurringInvoiceSendTypeModal}
            invoiceData={this.state.recurringInvoiceData}
            closeModal={this.closeRecurringInvoiceTypeModal.bind(this)}
            me={this.props.me}
            recurringInvoiceEdit={this.recurringInvoiceEditBeforeSubmit}
            saveInvoiceData={this.saveRecurringInvoiceData.bind(this)}
            invoiceSaveLoader={this.state.recurringInvoiceSaveLoader}
            updateContact={this.updateContact.bind(this)}
            paymentFeedbackStatus={
              this.props.accountsettings && this.props.accountsettings.payment_feedback
                ? this.props.accountsettings.payment_feedback
                : false
            }
          />
        )}
        {Boolean(this.state.showRecurringInvoiceSuccessModal) && (
          <SendInvoiceSuccessPopup
            invoiceData={this.state.recurringInvoiceData}
            close={this.closeRecurringInvoiceSuccessModal.bind(this)}
            me={this.state.me}
            showAdBanner={!hasMid && onBoardingData && (!onBoardingData.status || onBoardingData.status === 'draft')}
          />
        )}
        {/* Add Service  */}
        {Boolean(this.state.showAddService) && (
          <AddServicePopup
            serviceUSer={this.props.serviceUser}
            getServiceUserFunction={this.getServiceUserFunction.bind(this)}
            createService={this.AddService.bind(this)}
            UpdateService={this.UpdateService.bind(this)}
            ref={(ref) => (this.AddServicePopUp = ref)}
            businessHours={convertHoursToArray(this.props.businessSettings && this.props.businessSettings.hours)}
            me={this.props.me}
            products={this.props.products}
            disabledProducts={this.props.disabledProducts}
            assignedUser={this.props.staffs}
            changeshowAddService={this.changeshowAddService.bind(this)}
            editService={this.props.editService}
            businessSettings={this.props.businessSettings}
            showGlobalModel={this.openGlobalPopupList.bind(this)}
          />
        )}
        <RemoveServicePopup ref={(ref) => (this.deletePopup = ref)} />
        <UpdateServiceAssigneChangeModal
          open={this.state.showLocationUpdateModal}
          onCancel={this.closeLocationUpdateModal.bind(this)}
          handleSubmit={this.updateServiceLocations.bind(this)}
          selectUser={this.state.selectUser}
        />
        <PriceConfirmModal
          open={this.state.showPriceConfirmModal}
          title={`Are you sure you want to update the price/name for ${
            this.props.recurrenceInvoiceList
          } active recurring invoice${this.props.recurrenceInvoiceList > 1 ? 's' : ''}?`}
          okText="YES"
          cancelText="NO"
          onCancel={this.cancelPriceUpdateModal.bind(this)}
          type="delete"
          onOk={this.submitPriceUpdateModal.bind(this)}
          modalBackdropClicked={this.closePriceUpdateModal.bind(this)}
        />
      </>
    )
  }
}

// export default GlobalCreatePopup
export default compose(
  withElements({
    key: config.stripe_pk
  }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GlobalCreatePopup)

const UpdateServiceAssigneChangeModal = (props) => {
  return (
    <ConfirmModal
      open={props.open}
      title={'Reassign User'}
      onCancel={props.handleSubmit.bind(this, false)}
      onOk={props.handleSubmit.bind(this, true)}
      type="medium"
      modalBackdropClicked={props.onCancel.bind(this)}>
      <button type="button" className="close custom-modal-close-button" onClick={props.onCancel.bind(this)}>
        <span aria-hidden="true">&times;</span>
      </button>
      <p id="popupFbUnSyncMessage" className="mt-10">
        {`Do you want to reassign this service to ${props.selectUser.label} ? All the upcoming confirmed booking will be reassigned to ${props.selectUser.label}`}
      </p>
      <footer className="d-flex mt-10">
        <button
          id="popupFbUnSyncOkBtn"
          onClick={props.handleSubmit.bind(this, true)}
          className="btn btn-xl border-0 btn-primary  flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20">
          YES, REASSIGN
        </button>
        <button
          onClick={props.handleSubmit.bind(this, false)}
          id="popupFbUnSynCancelBtn"
          className="btn btn-xl flex-grow-0 cancel-btn d-block d-sm-inline-block">
          CANCEL UPCOMING BOOKING
        </button>
      </footer>
    </ConfirmModal>
  )
}

const PriceConfirmModal = (props) => {
  return (
    <Modal
      visible={props.open}
      onClickBackdrop={props.modalBackdropClicked.bind(this)}
      dialogClassName={'modal-custom-sm remove-modal'}>
      <div className="modal-header border-0 position-relative pb-0">
        <h5 className="modal-title">{props.title}</h5>
        <button
          type="button"
          className="close custom-modal-close-button"
          onClick={props.modalBackdropClicked.bind(this)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-footer border-0 px-15 pb-15 pt-0 px-sm-30 pb-sm-30 d-block d-sm-flex modal-custom-footer">
        <button
          type="button"
          className="btn btn-xl border-0 btn-danger mx-0 mb-10 mb-sm-0 mr-sm-20 btn-block"
          onClick={props.onOk.bind(this)}>
          {props.okText || 'YES'}
        </button>
        <button
          type="button"
          className={`btn btn-xl btn-outline mx-0 mb-10 mb-sm-0 custom-btn-width ${
            props.cancelText === 'NO' ? 'custom-btn-width-sm' : ''
          }`}
          onClick={props.onCancel.bind(this)}>
          {props.cancelText || 'NO'}
        </button>
      </div>
    </Modal>
  )
}
