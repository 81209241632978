import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'

export function listManagers (payload) {
  return api()
    .get(config.routes.user, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createManager (payload) {
  return api()
    .post(config.routes.createManager, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function manageUsers (payload) {
  return api()
    .post(`${config.routes.user}/${payload.id}/manage-users`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getManagerData (payload) {
  return api()
    .get(`${config.routes.user}/${payload.id}/manage-users`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function editManager (payload) {
  return api()
    .put(`${config.routes.user}/updateadmin/${payload.id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function triggerContactSales (payload) {
  return api()
    .post(config.routes.triggerContactSales)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function deleteManager (payload) {
  return api()
    .delete(`${config.routes.user}/${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
