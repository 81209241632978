import config from "@config/index";
import { api, catchHandler } from "@helpers/axios";
import { objToUrlParams } from "@helpers/index";

export function getEntContacts(payload) {
    return api()
        .get(config.routes.getEntContacts, { params: payload })
        .then(res => res.data)
        .catch(catchHandler)
}

export function getLocationGroups (payload) {
    return api()
        .get(config.routes.groups, { params: payload })
        .then(res => res.data)
        .catch(catchHandler)
}
export function addGroup (payload) {
    return api()
        .post(`${config.routes.createGroup}?customers=${payload.customer_id}`, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function groupDetails (payload) {
    return api()
        .get(`${config.routes.groups}/${payload}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function addContact (payload) {
    return api()
        .post(`${config.routes.addcontacts}`, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function removeContact (payload) {
    return api()
        .post(`${config.routes.removecontacts}`, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function updateGroup(payload) {
    return api()
        .put(`${config.routes.groups}/update`,payload)
        .then(res => res.data)
        .catch(catchHandler);
}
export function sendMessageToContactsEnterprise(payload) {
    return api()
        .post(`${config.routes.contacts}/${payload.id}/contact?${objToUrlParams(payload.customer)}`, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function savePrivateContact(payload) {
    let url = ""
    if(payload && payload.query){
        url = `${config.routes.contactCreate}?${objToUrlParams(payload.query)}`
        delete payload.query
    }else{
        url = `${config.routes.contactCreate}?exclude_group=1`
    }
    return api()
        .post(url,payload)
        .then(res => res.data)
        .catch(catchHandler)
}