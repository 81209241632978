import React, { useEffect, useState } from 'react'
import { Container, ThemeProvider, theme } from '@gositeinc/ui'
import { useDispatch, useSelector } from 'react-redux'
import { history } from '@app/store'
import { Header } from './Header'
import { CreateAccount } from './CreateAccount'
import { BusinessDetails } from './BusinessDetails'
import { FacebookBusinessDetails } from './FacebookBusinessDetails'
import { GMBBusinessDetails } from './GMBBusinessDetails'
import { PhoneVerification } from './PhoneVerification'
import { segmentEventIdentify, segmentEventTrack } from '@helpers/segment'
import { getCookieByKey, deleteASingleCookies } from '@helpers/utility'
import { setGositeHeaderTitle } from '@helpers/index'
import { LOGIN_SERVICE_PROVIDERS } from '@helpers/constants'

const { GOOGLE, FACEBOOK } = LOGIN_SERVICE_PROVIDERS

export const OnboardingV4 = () => {
  const dispatch = useDispatch()

  const [step, setStep] = useState(0)
  const [gmbSkipped, setGmbSkipped] = useState(false)

  const me = useSelector((state) => state.auth.me)
  const feStatus = useSelector((state) => state.firstExperience.status)

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }
    sessionStorage.setItem('isSignUpFlow', 'true')

    dispatch.firstExperience.getFirstExperienceStatus()

    if (step === 0) {
      setGositeHeaderTitle(steps[0])
    } else if (step === 1) {
      setGositeHeaderTitle(steps[1])
    } else if (step === 2) {
      setGositeHeaderTitle(steps[2])
    }
  }, [])

  useEffect(() => {
    if (!me) {
      return
    }

    const initial_email = getCookieByKey('initial_email')
    if (initial_email) {
      dispatch.myaccount.updateProfileDetails({
        id: me._id,
        initial_email
      })
      deleteASingleCookies('initial_email')
    }

    segmentEventIdentify(me._id, {
      Onboarding_Version: 'v4',
      SignUp_Method: getCookieByKey('loginProvider') || 'Manual'
    })
  }, [me])

  useEffect(() => {
    if (feStatus === 'landing' || feStatus === 'how_can_we_help') {
      setStep(0)
    }
    if (feStatus === 'create_free_account') {
      setStep(1)
    }
    if (feStatus === 'secure_your_account') {
      setStep(2)
    }
    if (feStatus === 'complete') {
      history.push('/')
    }
  }, [feStatus])

  useEffect(() => {
    if (step === 0) {
      setGositeHeaderTitle(steps[0])
    } else if (step === 1) {
      setGositeHeaderTitle(steps[1])
    } else if (step === 2) {
      setGositeHeaderTitle(steps[2])
    }
  }, [step])

  const steps = ['Business Details', 'Account Details', 'Verify Phone']
  const totalSteps = steps.length

  const renderBusinessDetails = () => {
    const provider = getCookieByKey('loginProvider')
    if ((provider !== GOOGLE.name && provider !== FACEBOOK.name) || gmbSkipped) {
      return (
        <BusinessDetails
          step={`STEP 1/${totalSteps}`}
          onSubmitted={async () => {
            await dispatch.firstExperience.postFirstExperienceStatus('create_free_account')
            setStep(1)
            setGmbSkipped(false)
          }}
        />
      )
    }

    if (provider === GOOGLE.name) {
      return (
        <GMBBusinessDetails
          step={`STEP 1/${totalSteps}`}
          onSkipped={() => {
            console.log('onskipped')
            setGmbSkipped(true)
          }}
          onSubmitted={async () => {
            await dispatch.firstExperience.postFirstExperienceStatus('create_free_account')
            setStep(1)
            setGmbSkipped(false)
          }}
        />
      )
    }

    if (provider === FACEBOOK.name) {
      return (
        <FacebookBusinessDetails
          step={`STEP 1/${totalSteps}`}
          onSkipped={() => {
            console.log('onskipped')
            setGmbSkipped(true)
          }}
          onSubmitted={async () => {
            await dispatch.firstExperience.postFirstExperienceStatus('create_free_account')
            setStep(1)
            setGmbSkipped(false)
          }}
        />
      )
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="xl">
        <Header currentStep={step} steps={steps} />

        {step === 0 && renderBusinessDetails()}
        {step === 1 && (
          <CreateAccount
            step={`STEP 2/${totalSteps}`}
            onBack={() => {
              setStep(0)
            }}
            onSubmitted={async () => {
              await dispatch.firstExperience.postFirstExperienceStatus('secure_your_account')
              setStep(2)
            }}
          />
        )}
        {step === 2 && (
          <PhoneVerification
            step={`STEP 3/${totalSteps}`}
            onBack={() => {
              setStep(1)
            }}
            onSubmitted={async () => {
              setGositeHeaderTitle()
              await dispatch.firstExperience.postFirstExperienceStatus('complete')
              await dispatch.selfservice.restartTrial({
                _id: me._id
              })
              await dispatch.services.createService({
                user_id: me._id,
                isDefault: true
              })
              segmentEventTrack({
                event: 'Onboarding_Completed',
                properties: {
                  Onboarding_Version: 'v4'
                }
              })
              history.push('/')
            }}
          />
        )}
      </Container>
    </ThemeProvider>
  )
}
