import React from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import Popup from '@components/popups/basic-modal'
import { numberMask } from '@helpers/inputmask'
import 'react-datepicker/dist/react-datepicker.css'
import { formatFormData, textCapitalize } from '@helpers/utility'
import MediaLibraryPopup from '@containers/media-library/media/media-library'
import Icons from '@components/icons'
import { states } from '@config/constants'
import listStyle from '../index.module.scss'
import notification from '@components/notification'
import Tooltip from '@components/tooltip'
import OnClickOutside from '@components/outsideClickComponent'
import { withTranslation } from 'react-i18next'

class AddContactModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      logo: '',
      submitSpinner: false,
      showStatesList: false,
      isLoadingGroups: false
    }
  }
  showPopup() {
    this.setState({ open: true })
  }

  findStateFromArray = (arr, value) => {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].abbreviation === value) return arr[i].name
    }
  }

  handleImageChange(image) {
    if (image && image.length) {
      this.setState({ logo: image[0].fileUrl, openMediaLibrary: false })
    }
    this.MediaLibraryPopup()
  }
  MediaLibraryPopup() {
    this.setState({ openMediaLibrary: !this.state.openMediaLibrary })
  }
  async handleSocialMediaChange(data) {
    data._id = this.state._id
    await this.props.updateBusinessSettings(data)
  }

  async handleSubmit(values, { resetForm }) {
    if (values.txtMobile.length || values.txtEmail.length) {
      this.setState({ submitSpinner: true })
      values.imgProfileImage = this.state.logo ? this.state.logo : ''
      const newkeys = {
        txtFirstName: 'first_name',
        txtLastName: 'last_name',
        txtEmail: 'email',
        txtMobile: 'mobile',
        selBirthday: 'birthday',
        txtAddress: 'address',
        txtAddress2: 'address2',
        selGroups: 'groups',
        txtCountry: 'country',
        txtExt: 'extension',
        txtState: 'state',
        txtCity: 'city',
        imgProfileImage: 'image'
      }
      const payload = formatFormData(values, newkeys)
      if (values && values.groups.length) {
        values.groups = values.groups.map((item) => item.value)
      }
      if (this.props.contactId) {
        values.contact_id = this.props.contactId
      }
      await this.props.onSubmit({ values, resetForm, payload })
      this.setState({ submitSpinner: false, open: false })
    } else {
      notification('error', 'Either email or mobile is required')
    }
  }
  handleCancel(values, { resetForm }) {
    this.closeModal(true, resetForm)
  }
  closeModal(reset, resetForm) {
    this.setState({ submitSpinner: false })
    if (reset) this.reset(resetForm)
    this.setState({ open: false })
    if (this.props.close) {
      this.props.close()
    }
  }
  removeLoader() {
    this.setState({ submitSpinner: false })
  }

  reset(resetForm) {
    resetForm({
      txtFirstName: '',
      txtLastName: '',
      txtEmail: '',
      txtMobile: '',
      selBirthday: '',
      txtAddress: '',
      txtAddress2: '',
      selGroups: [],
      txtCountry: 'US',
      txtExt: '',
      txtState: '',
      txtCity: '',
      imgProfileImage: '',
      location: {}
    })
    this.setState({ logo: null })
  }

  async createGroup(values, setFieldValue) {
    await this.props.openCreatePopUp(values.location)
    if (this.props.newGroup) {
      // eslint-disable-next-line no-var
      var obj = {
        label: this.props.newGroup.group_name,
        value: this.props.newGroup._id
      }
    }
    if (values.selGroups && values.selGroups.length) {
      values.selGroups.push(obj)
    } else {
      values.selGroups = []
      values.selGroups.push(obj)
    }
    setFieldValue('selGroups', values.selGroups)
  }

  getMessage(id, values) {
    let message = this.props.t('locations.please-select-location')
    if (values.location && values.location.value) message = ''
    window.$(`#${id}`).tooltip('hide').attr('data-original-title', message)

    return message
  }

  render() {
    const { t } = this.props
    const options =
      !this.state.isLoadingGroups &&
      this.props.groups &&
      this.props.groups.length &&
      this.props.groups
        .filter((item) => !item.system_group)
        .map((item, index) => {
          return { value: item._id, label: textCapitalize(item.group_name) }
        })
    // options = options && options.filter(item => item !== undefined);
    const CustomInput = (props) => {
      return (
        <input
          className="form-control form-control-lg datepicker"
          onClick={props.onClick}
          value={props.value}
          type="text"
          readOnly={true}
          name="selBirthday"
          placeholder={t('contacts_hub.select-birthday')}
        />
      )
    }
    return (
      <Popup
        open={this.state.open}
        title={t('contacts_hub.add-contact')}
        okText={t('contacts_hub.add-contact')}
        cancelText={t('common.cancel')}
        onCancel={() => this.setState({ open: false })}
        type="large"
        overrideForm={true}
        id={this.props.id}
        hideFooter={true}
        modalBackdropClicked={this.closeModal.bind(this)}>
        <Formik
          initialValues={{
            txtFirstName:
              this.props.contactDetails && this.props.contactDetails.first_name
                ? this.props.contactDetails.first_name
                : '',
            txtLastName:
              this.props.contactDetails && this.props.contactDetails.last_name
                ? this.props.contactDetails.last_name
                : '',
            txtEmail:
              this.props.contactDetails && this.props.contactDetails.email ? this.props.contactDetails.email : '',
            txtMobile:
              this.props.contactDetails && this.props.contactDetails.customer_phone
                ? numberMask(this.props.contactDetails.customer_phone.replace('+1', ''))
                : '',
            selBirthday: '',
            txtAddress: '',
            txtAddress2: '',
            selGroups: [],
            txtCountry: 'US',
            txtExt: '',
            txtState: '',
            txtCity: '',
            imgProfileImage: '',
            location: {}
          }}
          validationSchema={Yup.object().shape({
            txtLastName: Yup.string(),
            txtEmail: Yup.string().email(t('contacts_hub.please-enter-valid-email-0')),
            txtMobile: Yup.string().matches(
              /^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/,
              t('contacts_hub.only-us-phone-numbers-without-country-code-are-permitted-eg-500-555-0006')
            ),
            selBirthday: Yup.date(),
            txtAddress: Yup.string(),
            location: Yup.object().shape({
              label: Yup.string(),
              value: Yup.string().required(t('contacts_hub.required'))
            })
          })}
          validateOnChange={false}
          validateOnBlur={false}
          onReset={this.handleCancel.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ values, setFieldValue, resetForm, errors, touched }) => {
            return (
              <Form id="frmAddContact" name="frmAddContact">
                <div className={`custom-section-add-contact-modal ${listStyle['section-add-contact-modal']}`}>
                  <button type="reset" className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row mt-30">
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('contacts_hub.first-name')}</label>
                        <Field
                          id="addToContacts_txtFirstName"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder={t('contacts_hub.enter-first-name')}
                          name="txtFirstName"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('contacts_hub.last-name')}</label>
                        <Field
                          id="addToContacts_txtLastName"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder={t('contacts_hub.enter-last-name')}
                          name="txtLastName"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">
                          EMAIL
                          <ErrorMessage name="txtEmail" component="span" className="form-error" />
                        </label>
                        <Field
                          id="addToContacts_txtEmail"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder={t('contacts_hub.enter-email-address')}
                          name="txtEmail"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-4">
                      <div className="form-group">
                        <label className="label-text">
                          {t('contacts_hub.mobile-uppercase')}
                          <ErrorMessage name="txtMobile" component="span" className="form-error" />
                        </label>
                        <Field
                          id="addToContacts_txtMobile"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder={t('contacts_hub.enter-mobile-number')}
                          name="txtMobile"
                          maxLength={14}
                          onChange={(event) => {
                            setFieldValue('txtMobile', numberMask(event.target.value))
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-2">
                      <div className="form-group">
                        <label className="label-text">{t('contacts_hub.extension')}</label>
                        <Field
                          id="addToContacts_txtExt"
                          className="form-control form-control-lg"
                          type="text"
                          placeholder={t('contacts_hub.enter-ext')}
                          name="txtExt"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group custom-form-group-dropdown">
                        <label className="label-text d-block">{t('contacts_hub.birthday')}</label>
                        <DatePicker
                          popperPlacement="bottom-start"
                          popperModifiers={{
                            flip: {
                              enabled: false
                            },
                            preventOverflow: {
                              enabled: true,
                              escapeWithReference: false
                            }
                          }}
                          showMonthDropdown
                          showYearDropdown
                          useShortMonthInDropdown
                          id="addToContacts_txtBirthdayDatePicker"
                          selected={values.selBirthday}
                          dateFormat="MM/dd/yyyy"
                          onChange={(date) => setFieldValue('selBirthday', date)}
                          maxDate={new Date()}
                          customInput={<CustomInput />}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('settings.address-uppercase')}</label>
                        <Field
                          id="addToContacts_txtAddress"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Enter address"
                          name="txtAddress"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('settings.address-uppercase')} 2</label>
                        <Field
                          id="addToContacts_txtAddress2"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder="Enter address 2"
                          name="txtAddress2"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('contacts_hub.country')}</label>
                        <Field
                          id="addToContacts_txtCountry"
                          className="form-control form-control-lg bg-white"
                          type="textarea"
                          placeholder="US"
                          readOnly
                          name="txtCountry"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('settings.state-upper')}</label>

                        <div
                          onClick={() => this.setState({ showStatesList: true })}
                          id="addToContacts_txtState"
                          className="dropdown w-100">
                          <div
                            className="dropdown-toggle btn btn-lg border text-left w-100 d-flex align-items-center"
                            data-toggle="dropdown">
                            <span className={`${listStyle['value-block']}`}>
                              {values.txtState
                                ? this.findStateFromArray(states, values.txtState)
                                : t('contacts_hub.select-state')}
                            </span>
                            <i className="btn-icon ml-auto">
                              <Icons name="fillArrowDown" />
                            </i>
                          </div>
                          {this.state.showStatesList ? (
                            <OnClickOutside handleClickOutside={() => this.setState({ showStatesList: false })}>
                              <ul
                                className={` dropdown-menu w-100 overflow-y-scrollbar max-height-200 show ${listStyle['state-dropdown']}`}>
                                {states.map((item, index) => (
                                  <li
                                    className="all-sources px-15 py-5"
                                    key={index}
                                    onClick={(e) => {
                                      setFieldValue('txtState', item.abbreviation)
                                      e.stopPropagation()
                                      this.setState({ showStatesList: false })
                                    }}>
                                    <span>{item.name}</span>
                                  </li>
                                ))}
                              </ul>
                            </OnClickOutside>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12 col-md-6">
                      <div className="form-group">
                        <label className="label-text">{t('settings.city')}</label>
                        <Field
                          id="addToContacts_txtCity"
                          className="form-control form-control-lg"
                          type="textarea"
                          placeholder={t('contacts_hub.enter-city')}
                          name="txtCity"
                        />
                      </div>
                    </div>

                    <div className="col-12 col-sm-12">
                      <div className="form-group">
                        <label className="label-text">{t('contacts_hub.photo')}</label>
                        <div id="addToContacts_profileImage" className="d-flex align-items-center">
                          {this.state.openMediaLibrary ? (
                            <MediaLibraryPopup
                              open={this.state.openMediaLibrary}
                              onSubmit={this.handleImageChange.bind(this)}
                              onCancel={this.MediaLibraryPopup.bind(this)}
                              multiple={false}
                              fromEnterprise={true}
                              type={['image']}
                              showShapes={true}
                              crop={true}
                            />
                          ) : null}
                          <figure
                            className={`d-inline-block align-middle rounded mb-0 mr-15 position-relative ${listStyle['b-profile']}`}>
                            <img
                              id="imgLogo"
                              name="imgProfileImage"
                              className={`image rounded-circle w-100 h-100 ${listStyle['profile-image']}`}
                              src={
                                this.state.logo
                                  ? this.state.logo
                                  : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                              }
                              alt=""
                            />
                            {this.state.logo ? (
                              <i
                                className={`btn-icon mr-5 top-minus-2 position-absolute d-flex align-items-center justify-content-center bg-danger rounded-circle ${listStyle['profile-icon']}`}
                                onClick={() => this.setState({ logo: '' })}>
                                <Icons name="trash" />
                              </i>
                            ) : (
                              ''
                            )}
                          </figure>
                          <button
                            type="button"
                            id="btnLogoUpload"
                            onClick={this.MediaLibraryPopup.bind(this)}
                            className={'btn btn-md btn-outline d-flex line-height-normal align-items-center'}>
                            <i className="btn-icon mr-10 top-minus-2">
                              <Icons name="export" />
                            </i>
                            <span>{t('contacts_hub.upload-photo')}</span>
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown">
                        <label className="label-text lowercase-text">
                          {t('locations.location')}
                          <i className="star text-danger">&nbsp;*</i>
                        </label>
                        {errors.location && touched.location ? (
                          <span className="form-error">{errors.location.value}</span>
                        ) : null}
                        <Select
                          id="addToContacts_selContactToLocation"
                          className="dropdown-select "
                          classNamePrefix="dropdownSelect"
                          // closeMenuOnSelect={false}
                          options={
                            this.props.locations &&
                            this.props.locations.map((item) => ({ value: item._id, label: item.name, ...item }))
                          }
                          isMulti={false}
                          placeholder={t('locations.search-locations')}
                          name="location"
                          noOptionsMessage={() => t('locations.no-locations')}
                          value={values.location && values.location.value ? values.location : null}
                          onChange={async (value) => {
                            setFieldValue('location', value)
                            setFieldValue('selGroups', [])
                            try {
                              this.setState({ isLoadingGroups: true })
                              await this.props.getLocationGroups({
                                nopaging: true,
                                status: 'active',
                                customers: value.value
                              })
                            } catch (e) {
                              console.error('Error getting location:', e)
                            } finally {
                              this.setState({ isLoadingGroups: false })
                            }
                          }}
                        />
                      </div>
                    </div>

                    <div className="col-12">
                      <div className="form-group custom-react-search-select-wrapper custom-group-select">
                        <label className="label-text lowercase-text">{t('contacts_hub.add-contact-to-group-s')}</label>
                        <div className="d-block custom-add-groups-dropdown">
                          <Tooltip
                            fullWidth={true}
                            message={this.getMessage('addtogrouptooltip', values)}
                            id="addtogrouptooltip">
                            <Select
                              id="addToContacts_selContactToGroup"
                              className="search-select h-100"
                              classNamePrefix="globalSelect"
                              closeMenuOnSelect={false}
                              options={options || []}
                              isMulti={true}
                              placeholder={t('contacts_hub.search-groups')}
                              name="selGroups"
                              value={values.selGroups}
                              noOptionsMessage={() => t('contacts_hub.no-groups')}
                              onChange={(event) => setFieldValue('selGroups', event)}
                              isDisabled={!values.location || !values.location.value}
                              isLoading={this.state.isLoadingGroups}
                              loadingMessage={t('contacts_hub.fetching-groups')}
                            />
                          </Tooltip>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 col-sm-12">
                      <Tooltip message={this.getMessage('createGroupTootip', values)} id="createGroupTootip">
                        <div className="form-group no-margin mt-10">
                          <button
                            disabled={!values.location || !values.location.value}
                            id="addToContacts_btnCreateGroup"
                            name="btnCreateGroup"
                            onClick={this.createGroup.bind(this, values, setFieldValue)}
                            type="button"
                            className="btn btn-sm border btn-outline">
                            <i className={`btn-icon mr-5 top-minus-2 ${listStyle['icon-create']}`}>
                              <Icons name="plusThin" />
                            </i>
                            {t('contacts_hub.create-group')}
                          </button>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                  <footer className="d-block d-flex mt-10">
                    <button
                      type="submit"
                      className="btn btn-primary btn-xl border-0 flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20"
                      id="frmAddContact_btnAdd"
                      disabled={this.state.submitSpinner}
                      name="btnAddContact">
                      {t('contacts_hub.add-contact-0')}
                      {this.state.submitSpinner && (
                        <i className={'btn-icon top-minus-2 ml-5 spinner'}>
                          <Icons name="spinner" />
                        </i>
                      )}
                    </button>
                    <button
                      id="frmAddContact_btnCancel"
                      type="reset"
                      className="btn btn-xl flex-grow-0 cancel-btn d-block d-sm-inline-block">
                      {t('common.cancel-uppercase')}
                    </button>
                  </footer>
                </div>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}

export default withTranslation(null, { withRef: true })(AddContactModal)
