import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { checkAvatar } from '@helpers/utility'
import _ from 'lodash'

export default {

  state: {

    apiError: false,
    featuredReviews: {
      positive: [],
      negative: []
    },
    featuredPositiveIndex: 0,
    featuredNegativeIndex: 0,
    showLocationReviewGraphs: false,
    locationReviewSettings: {}
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess (state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError (state, data) {
      const err =
                data && data.message
                  ? data.message
                  : data && data.data && data.data.message
                    ? data.data.message
                    : data && data.error
                      ? data.error
                      : data && data.data && data.data.error
                        ? data.data.error
                        : 'Please try again later'
      if (err) { notification('error', capitalize(err)) }
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onGetReviewAnalytics (state, data) {
      const reviewAnalyticsMetrics = data.data
      reviewAnalyticsMetrics.star_width = (reviewAnalyticsMetrics.avg_rating * 100) / 5
      return {
        ...state,
        loading: false,
        apiError: false,
        reviewAnalyticsMetrics: reviewAnalyticsMetrics

      }
    },
    onGetReviewsSourceMapFilter (state, data) {
      const reviewAnalyticsMetrics = state.reviewAnalyticsMetrics
      reviewAnalyticsMetrics.reviews = data && data.data.reviews
      return {
        ...state,
        loading: false,
        apiError: false,
        reviewAnalyticsMetrics: reviewAnalyticsMetrics

      }
    },
    onGetReviewLocations (state, data) {
      let showLocationReviewGraphs = false
      if (data && data.data) {
        for (var i = 0; i < data.data.length; i++) {
          if (data.data[i].total_review > 0) {
            showLocationReviewGraphs = true
            break
          }
        }
      }

      return {
        ...state,
        loading: false,
        apiError: false,
        totalReviewLocations: data.data.length,
        reviewLocations: data.data,
        showLocationReviewGraphs: showLocationReviewGraphs
      }
    },
    onGetLeaderBoard (state, data) {
      const reviewLeaderBoardData = data.data
      Object.keys(reviewLeaderBoardData).forEach(function (key) {
        if (!(_.isEmpty(reviewLeaderBoardData[key]))) {
          reviewLeaderBoardData[key].star_width = (reviewLeaderBoardData[key].averate_rating * 100) / 5
        }
      })

      return {
        ...state,
        loading: false,
        apiError: false,
        reviewLeaderBoardData: reviewLeaderBoardData
      }
    },
    onGetTotalReviewsByFilter (state, data) {
      return {
        ...state,
        loading: false,
        totalReviewAnalytics: data
      }
    },
    onGetAvgReviewsByFilter (state, data) {
      return {
        ...state,
        loading: false,
        avgReviewAnalytics: data
      }
    },
    fetchEnterpriseReviewSettingsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        locationReviewSettings: data.data
      }
    },
    onGetFeaturedReviewsSuccess (state, data) {
      let featuredReviews = {}
      if (data && data.result) {
        featuredReviews = {
          positive: data.result.positive.docs,
          negative: data.result.negative.docs
        }
      }
      if (featuredReviews && featuredReviews.positive && featuredReviews.positive.length) {
        featuredReviews.positive.forEach(item => {
          if (item.authorEmail) {
            item.authorImage = checkAvatar(item.authorEmail)
          }
        })
      }
      if (featuredReviews && featuredReviews.negative && featuredReviews.negative.length) {
        featuredReviews.negative.forEach(item => {
          if (item.authorEmail) {
            item.authorImage = checkAvatar(item.authorEmail)
          }
        })
      }

      return {
        ...state,
        loading: false,
        featuredReviews: featuredReviews,
        positiveTotal: featuredReviews.positive.length,
        negativeTotal: featuredReviews.negative.length
      }
    },
    updateFeaturedReviewIndex (state, key) {
      let featuredPositiveIndex = state.featuredPositiveIndex
      let featuredNegativeIndex = state.featuredNegativeIndex
      const positiveTotal = state.positiveTotal
      const negativeTotal = state.negativeTotal
      if (key === 'negative') {
        featuredNegativeIndex = (featuredNegativeIndex < (negativeTotal - 1)) ? (featuredNegativeIndex + 1) : 0
      } else if (key === 'positive') {
        featuredPositiveIndex = (featuredPositiveIndex < (positiveTotal - 1)) ? (featuredPositiveIndex + 1) : 0
      }
      return {
        ...state,
        loading: false,
        featuredNegativeIndex: featuredNegativeIndex,
        featuredPositiveIndex: featuredPositiveIndex
      }
    },
    onGetUnaddressedReviewsSuccess (state, data) {
      const unaddressedReviews = data.reviews
      unaddressedReviews.forEach(item => {
        if (item.authorEmail) {
          item.authorImage = checkAvatar(item.authorEmail)
        } else {
          item.authorImage = 'https://dufzo4epsnvlh.cloudfront.net/image/presence/large/icon-' + item.siteId.toLowerCase() + '.svg'
          item.hideInnerImage = true
        }
      })
      const filteredunaddressedReviews = unaddressedReviews.slice(0, 5)
      return {
        ...state,
        loading: false,
        unaddressedReviews: unaddressedReviews,
        filteredunaddressedReviews: filteredunaddressedReviews
      }
    },
    updateReviewResponseFilter (state, data) {
      const unaddressedReviews = state.unaddressedReviews && state.unaddressedReviews.length ? state.unaddressedReviews.slice(0, 5) : []
      let sortedArray = []
      if (data && data.length) {
        sortedArray = unaddressedReviews.filter(a => data.indexOf(a.rating.toString()) !== -1)
      } else {
        sortedArray = unaddressedReviews
      }
      return {
        ...state,
        loading: false,
        filteredunaddressedReviews: sortedArray
      }
    },
    onFeeedBackReply (state, data) {
      notification('success', 'Feedback reply has been sent successfully')
      return {
        ...state,
        loading: false,
        feedbackData: data
      }
    },
    sendReviewSuccess (state, data, payload) {
      if (data.recipient_contacts.length < 10) {
        notification('success', 'Your review request has been added to the queue. You can track the status of each review request from the Review Requests tab. ')
      } else {
        notification('success', 'Review request have been scheduled')
      }
      return {
        ...state,
        reviewloading: false
      }
    },
    getReviewsAnalyticsEnterpriseSuccess (state, data) {
      return {
        ...state,
        loading: false,
        enterpriseMessengerReviewAnalytics: data.data
      }
    },
    setReviewId (state, id) {
      return {
        ...state,
        reviewIdToshow: id
      }
    },
    feedbackCountSuccess (state, data) {
      return {
        ...state,
        loading: false,
        feedbackCounts: data
      }
    }
  },

  effects: {
    async getReviewsAnalytics (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewsAnalytics(payload)
        this.onGetReviewAnalytics(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewsAnalyticsEnterprise (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewsAnalyticsEnterprise(payload)
        this.getReviewsAnalyticsEnterpriseSuccess(res)
      } catch (e) {
        console.log(e)
      }
    },
    async reviewsSourceMapFilter (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewsAnalytics(payload)
        this.onGetReviewsSourceMapFilter(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewLocations (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewLocations(payload)
        this.onGetReviewLocations(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getLeaderBoard (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewLocations(payload)
        this.onGetLeaderBoard(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getTotalReviewsByFilter (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.reviewsGraphData(payload)
        this.onGetTotalReviewsByFilter(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getAvgReviewsByFilter (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.reviewsGraphData(payload)
        this.onGetAvgReviewsByFilter(res)
      } catch (e) {
        console.log(e)
      }
    },
    async getFeaturedReviews (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getFeaturedReviews(payload)
        this.onGetFeaturedReviewsSuccess(res)
      } catch (e) {
        // this.onError(e)
        console.log(e)
      }
    },
    async updateFeaturedIndex (payload) {
      try {
        this.updateFeaturedReviewIndex(payload)
      } catch (e) {
        // this.onError(e)
        console.log(e)
      }
    },
    async getUnaddressedReviews (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getUnaddressedReviews(payload)
        this.onGetUnaddressedReviewsSuccess(res)
      } catch (e) {
        // this.onError(e)
        console.log(e)
      }
    },
    async reviewResponseFilter (payload) {
      try {
        this.updateReviewResponseFilter(payload)
      } catch (e) {
        // this.onError(e)
        console.log(e)
      }
    },
    async feedbackReply (payload, rootState) {
      try {
        const res = await service.feedbackReply(payload)
        this.onFeeedBackReply(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async sendLocationReview (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendLocationReview(payload)
        this.sendReviewSuccess(res, payload)
        return res.data
      } catch (e) {
        this.onError(e.errors)
      }
    },
    async fetchEnterpriseReviewSettings (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchEnterpriseReviewSettings(payload)
        this.fetchEnterpriseReviewSettingsSuccess(res, payload)
        return res.data
      } catch (e) {
        this.onError(e.errors)
      }
    },
    async setReviewDetailsId (payload) {
      try {
        this.setReviewId(payload)
      } catch (e) {
        console.log(e)
      }
    },
    async getFeedbackCount (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getFeedbackCount(payload)
        this.feedbackCountSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e.errors)
      }
    },
  }
}
