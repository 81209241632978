import { ContactSource, SearchResultSource, ContactData, ContactGroupData } from './types';
import {phoneNumberFormat} from '@helpers/utility';

// `source` is the response from 'dispatch.contact.autosuggestContact()'
// a.k.a. API route: /contacts/es/auto-suggest',
export function parseContactSearchResults(source: SearchResultSource[] = []) {
  // helper function
  const parseContactSource = (source: ContactSource, contactOnly: boolean = false): string => {
    const { first_name, email, mobile } = source;
    return first_name && !contactOnly ? first_name : email ? email : phoneNumberFormat(mobile) || '';
  };

  const contactList: ContactData[] = [];
  const contactGroupList: ContactGroupData[] = [];
  for (let contact of source) {
    if (contact.type === 'contact') {
      contactList.push({
        label: contact.first_name || '',
        subLabel: parseContactSource(contact, true),
        value: parseContactSource(contact),
        valueData: parseContactSource(contact, true),
        type: 'contact',
      });
    }

    if (contact.type === 'group') {
      contactGroupList.push({
        label: contact.group_name || '',
        value: contact.contacts
          ? contact.contacts.map((item: any) => {
              return {
                value: parseContactSource(item),
                valueData: parseContactSource(item, true),
              };
            })
          : [],
        type: 'group',
      });
    }
  }
  return { contactList, contactGroupList };
}
