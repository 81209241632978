import * as React from 'react'
import Icon from '../icons'
import { segmentEventTrack } from '@helpers/segment'
import { useTrialExpired } from '@app/router/hooks'
import { goToUpgradePlan } from '@helpers/utility'
import { getUpgradeToProUrl } from '@app/containers/account-settings/service'

interface UpgradeButtonProps {
  uuid: string
  className?: string
  isMultiLocation: boolean
  me: Record<string, any>
}

export const UpgradeButton = ({ uuid, className, isMultiLocation, me }: UpgradeButtonProps): JSX.Element => {
  const trialExpired = useTrialExpired()

  const handleUpgradeClick = () => {
    segmentEventTrack({
      event: 'upgrade_to_pro_started',
      properties: {
        trial_expired: trialExpired,
        isMultilocation: isMultiLocation
      }
    })

    getUpgradeToProUrl({ uuid })
      .then((res) => {
        const { upgradeUrl: url } = res
        console.log('Upgrade URL:', url)
        if (url) {
          window.open(url, '_blank')
        } else {
          goToUpgradePlan(me)
        }
      })
      .catch((error) => {
        console.error('Error getting upgrade URL:', error)
        // Fallback to the default upgrade plan in case of an error
        goToUpgradePlan(me)
      })
  }

  return (
    <button className={`btn btn-primary border-0 px-sm-20 ${className}`} onClick={handleUpgradeClick}>
      <i className="btn-icon mr-10 top-minus-2">
        <Icon name="thunder" />
      </i>
      UPGRADE TO PRO
    </button>
  )
}
