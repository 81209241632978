import React, { Fragment, useEffect, useRef, useState } from 'react'
import GoogleContacts from '../../googleButton/contact'
import Icon from '../../icons'
import ReactLoginMS from 'react-ms-login'
import { isEnabled } from '@helpers/utility'
import listStyle from '@containers/contacts-hub/index.module.scss'
import notification from '../../notification'
import config from '@config/index'
import { dummyCsv } from '@containers/contacts-hub/utility/constants'
import { useSelector, useDispatch } from 'react-redux'
import ImportContactsPopup from '@containers/contacts-hub/utility/select-contacts-to-import-popup'
import QuickbookConfirmPopup from '@containers/payment/utility/quickbook-confirm-popup'
import openExternalPopupWindow from '@containers/payment/utility/openExternalPopup'
import * as service from '@containers/auth/service'
import Popup from '../basic-modal'
import { ContactType } from '@helpers/segmentEvents/types'
import { trackContactImported } from '@helpers/segmentEvents/contact'
import { ClosePopupButton } from '../components/closePopupButton'
import styles from './index.module.scss'
import { useTranslation } from 'react-i18next'

// this function copied throughout the codebase
function download(filename: string) {
  const element = document.createElement('a')
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(dummyCsv))
  element.setAttribute('download', filename)
  element.style.display = 'none'
  document.body.appendChild(element)
  element.click()
  document.body.removeChild(element)
}

interface ImportContactsMenuPopupProps {
  open: boolean
  onClose: () => void
  hideCsv?: boolean
  onImportSuccess?: (source: ContactType, numOfContactsImported: number) => void
}

export const ImportContactsMenuPopup = ({ open, onClose, onImportSuccess, hideCsv }: ImportContactsMenuPopupProps) => {
  const { t } = useTranslation()
  const importContactsPopupRef = useRef<ImportContactsPopup>(null)
  const [showQuickBookConfirmModal, setShowQuickBookConfirmModal] = useState<boolean>(false)

  const contact: any = useSelector<any>((state) => state.contact)
  const auth: any = useSelector<any>((state) => state.auth)
  const accountSettings: any = useSelector<any>((state) => state.accountsettings)

  const dispatch = useDispatch<any>()

  // get anything we're missing in the store
  useEffect(() => {
    const getState = async () => {
      if (!auth.me) await dispatch.auth.getMe()

      if (!accountSettings.accountsettings) await dispatch.accountsettings.fetchAccountSettings()
    }
    getState()
  }, [])

  const isQuickbookConnected = () => {
    let status = false
    if (
      accountSettings.accountsettings &&
      accountSettings.accountsettings.links &&
      accountSettings.accountsettings.links.length &&
      accountSettings.accountsettings.isQuickbooksExpiredTokenNotified !== true
    ) {
      const obj = accountSettings.accountsettings.links.find((x: any) => x.site === 'quickbook')
      if (obj) status = true
    }

    return status
  }

  const handleSuccess = (type: ContactType) => {
    const contactTotal = (contact.contacts && contact.contacts.total) || 0
    trackContactImported(type, contactTotal)
    if (onImportSuccess) onImportSuccess(type, contactTotal)
  }

  const outlookImportSuccess = async (data: any) => {
    if (data && data.code) {
      await dispatch.contact.outlookContact({
        code: data.code,
        clientId: config.outlook_key,
        redirectUri: `${window.location.origin}/socialconnect`
      })
      if (contact.importedContacts && contact.importedContacts.length) {
        importContactsPopupRef.current && importContactsPopupRef.current.showPopup(contact.importedContacts)
      }

      handleSuccess('Outlook')
    } else {
      notification('error', t('contacts_hub.failed-to-import-outlook-contacts'))
    }
    onClose()
  }

  const handleGoogleContact = async (data: any) => {
    if (data && data.code) {
      await dispatch.contact.importGoogleContacts({
        clientId: config.google_login_key,
        code: data.code,
        redirectUri: 'postmessage'
      })
      if (contact.importedContacts && contact.importedContacts.length) {
        importContactsPopupRef.current && importContactsPopupRef.current.showPopup(contact.importedContacts)
      }

      handleSuccess('Google')
    } else {
      notification('error', t('contacts_hub.failed-to-import-google-contacts'))
    }
    onClose()
  }

  const handleGoogleContactFailure = (err: any) => {
    if (err && err.error !== 'idpiframe_initialization_failed')
      notification('error', t('contacts_hub.failed-to-import-google-contacts'))
  }

  const uploadCsv: React.ChangeEventHandler<HTMLInputElement> = async (event) => {
    event.preventDefault()
    const file = event.target.files && event.target.files[0]
    if (file && file.type && (file.type === 'text/csv' || file.type === 'application/vnd.ms-excel')) {
      const formdata = new FormData()
      formdata.append('contact_csv', file)
      await dispatch.contact.importContactsFromCSV(formdata)

      handleSuccess('direct')
    } else {
      notification('error', t('contacts_hub.please-upload-a-valid-csv-file'))
    }
    onClose()
  }

  const handleQuickBookSubmit = async (values: any) => {
    dispatch.auth.quickBooksettings(values)
    setShowQuickBookConfirmModal(false)
    try {
      const authUri = await service.quickBookAuthUri()
      openExternalPopupWindow({ url: authUri.uri, centered: true, windowName: 'QuickBooks Contact Sync' }, () =>
        handleSuccess('QuickBooks')
      )
    } catch (error) {
      notification('error', t('contacts_hub.failed-to-connect-to-quickbooks'))
    }
    onClose()
  }

  const selectedContacts = async (data: any) => {
    const payload = {
      exportData: { contact_data: JSON.stringify(data), source: contact.source, status: 'new', user_id: auth.me._id }
    }
    await dispatch.contact.socialContactsImport(payload)
  }

  return (
    <Popup
      open={open}
      type="medium"
      overrideForm={true}
      id="modalImportContacts"
      modalBackdropClicked={() => onClose()}
      hideHeader>
      <div className={`${hideCsv ? styles['modal-spacing-nocsv'] : styles['modal-spacing']} text-center`}>
        <ClosePopupButton id="closeIcon" className={styles['move-down-close-btn']} onClick={() => onClose()} />
        {!hideCsv && (
          <div className={'mb-5 border-bottom ' + listStyle['import-from-contacts']}>
            <label
              className={
                'btn pl-10 pr-10  btn-sm border border-primary text-primary fz-12 d-flex align-items-center justify-content-center  mb-20 ' +
                listStyle['btn-import-contact']
              }
              style={{ width: '40%', margin: 'auto', minWidth: 160 }}>
              <Icon name="export" className="mr-5 position-relative top-minus-1 " />
              {t('contacts_hub.upload-csv-file')}
              <input
                type="file"
                onChange={uploadCsv}
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                style={{ display: 'none' }}
              />
            </label>
            <span
              className={'mb-20 d-block cursor-pointer fz-12  text-primary '}
              onClick={() => download('sample.csv')}>
              {t('contacts_hub.download-sample-file')}
            </span>
          </div>
        )}
        <div className="d-block">
          <span
            className={`d-block ${hideCsv ? 'mb-10' : 'mb-15'} justify-content-between ${
              listStyle['import-from-text']
            }`}>
            {t('contacts_hub.import-from')}
          </span>
          <div
            className={'d-flex justify-content-around ' + listStyle['import-from-others-btns']}
            style={{ width: '100%', margin: 'auto' }}>
            {auth.products &&
              isEnabled('quickbooks-contact-hub', auth.products) &&
              isQuickbookConnected() &&
              auth.me &&
              (auth.me.role_id.role_name !== 'partner' || auth.me.isMultiLocation) && (
                <button className={'btn position-relative d-inline-block qb-cnt '} title="QuickBooks">
                  <Icon name="quickBookDisconnect" className="qb-icon" style={{ width: '30px' }} /> QuickBooks
                </button>
              )}

            {auth.products &&
              isEnabled('quickbooks-contact-hub', auth.products) &&
              !isQuickbookConnected() &&
              auth.me &&
              (auth.me.role_id.role_name !== 'partner' || auth.me.isMultiLocation) && (
                <button
                  className={'btn position-relative d-inline-block qb-cnt '}
                  onClick={() => {
                    if (!isQuickbookConnected()) {
                      setShowQuickBookConfirmModal(true)
                    }
                  }}
                  title="QuickBooks">
                  <Icon name="quickBookDisconnect" className="qb-icon" style={{ width: '30px' }} /> QuickBooks
                </button>
              )}

            <GoogleContacts onSuccess={handleGoogleContact} onFailure={handleGoogleContactFailure} showLabel={true} />
            <ReactLoginMS
              className="btn d-inline-block"
              clientId={config.outlook_key}
              scopes={[
                'openid',
                'offline_access',
                'profile',
                'https://outlook.office.com/mail.readwrite',
                'https://outlook.office.com/mail.readwrite.shared',
                'https://outlook.office.com/mail.send',
                'https://outlook.office.com/mail.send.shared',
                'https://outlook.office.com/calendars.readwrite',
                'https://outlook.office.com/calendars.readwrite.shared',
                'https://outlook.office.com/contacts.readwrite',
                'https://outlook.office.com/contacts.readwrite.shared',
                'https://outlook.office.com/tasks.readwrite',
                'https://outlook.office.com/tasks.readwrite.shared',
                'https://outlook.office.com/mailboxsettings.readwrite',
                'https://outlook.office.com/people.read',
                'https://outlook.office.com/user.readbasic.all'
              ]}
              redirectUri={`${window.location.origin}/socialconnect`}
              handleLogin={(data: any) => outlookImportSuccess(data)}
              responseType="code"
              cssClass="btn d-inline-block"
              btnContent={
                <Fragment>
                  {/* <Icon name="outlookWithGreenTick" style={{minWidth:"36px"}} /> */}
                  <img
                    src="https://dufzo4epsnvlh.cloudfront.net/image/contact/Outlook-icon.svg"
                    alt="O"
                    data-toggle="Outlook"
                    data-original-title="Outlook"
                    title="Outlook"
                  />{' '}
                  Outlook
                </Fragment>
              }
            />
          </div>
          <ImportContactsPopup
            ref={importContactsPopupRef}
            defaultKeys={contact.defaultKeys}
            onSubmit={selectedContacts}
          />
          {showQuickBookConfirmModal && (
            <QuickbookConfirmPopup
              open={showQuickBookConfirmModal}
              me={auth.me}
              screen="Contact Hub"
              disabledProducts={auth.disabledProducts}
              products={auth.products}
              closeModal={() => setShowQuickBookConfirmModal(false)}
              handleSubmit={handleQuickBookSubmit}
            />
          )}
        </div>
      </div>
    </Popup>
  )
}
