import * as React from 'react'
import Popup from '../basic-modal'
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Formik, Form, Field, ErrorMessage } from 'formik'
// formik validation
import * as Yup from 'yup'
import { numberMask } from '@helpers/inputmask'
import { parseName } from '@helpers/index'
import ContactInfoWidget from '../../contactInfoWidget'
import PredefinedTemplate from '../../predefined-templates'

interface contactType {
  email: string
  mobile: string
  full_name: string
  _id: string
  status: string
  is_public: boolean
}
interface contactDetailsType {
  contact?: contactType
  avatar?: string
}
interface SendBookingLinkProps {
  open: boolean
  onClose: () => void
  enterprise?: boolean
  contactDetails: contactDetailsType
  tinyUrl:string
  onSendBookingLink: (bookingLinkData: object) => void
  onContactUpdated: (updated: boolean) => void
  disableContactsImport:boolean
}
interface templateType {
  message: string
}
const SendBookingLinkPopup = ({
  open,
  onClose,
  onSendBookingLink,
  onContactUpdated,
  enterprise,
  contactDetails,
  tinyUrl,
  disableContactsImport
}: SendBookingLinkProps) => {
  const [isopen, setOpenModal] = useState(false)
  const [contact, setContact] = useState({ email: '', mobile: '', name: '' })
  const [oldcontact, setOrginalData] = useState({ email: '', mobile: '', name: '' })
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(false)
  const accountSettings: any = useSelector<any>((state) => state.accountsettings)
  const common: any = useSelector<any>((state) => state.common)

  const dispatch = useDispatch()

  function clearContact(field: string) {
    if (field === 'all') {
      setContact({ email: '', mobile: '', name: '' })
    } else if (field === 'email') {
      setContact({ ...contact, email: oldcontact.email })
    } else if (field === 'mobile') {
      setContact({ ...contact, mobile: oldcontact.mobile })
    } else if (field === 'name') {
      setContact({ ...contact, name: '' })
    }
  }
  function isFieldChanged(field: string) {
    if (field === 'email') {
      return contact.email.length && contact.email !== oldcontact.email ? true : false
    } else if (field === 'mobile') {
      return contact.mobile.length && contact.mobile !== oldcontact.mobile ? true : false
    }
  }
  function selectTemplate(data: templateType) {
    if (data && data.message) {
      setMessage(data.message)
    }
  }
  useEffect(() => {
    
    setOpenModal(open)
    let useData = {
      email:
        contactDetails && contactDetails.contact && contactDetails.contact.email ? contactDetails.contact.email : '',
      mobile:
        contactDetails && contactDetails.contact && contactDetails.contact.mobile
          ? numberMask(contactDetails.contact.mobile.replace('+1', ''))
          : '',
      name:
        contactDetails && contactDetails.contact && contactDetails.contact.full_name
          ? contactDetails.contact.full_name
          : ''
    }
    let msg = `We'd love to invite you to book a service with us. Please follow this link to book an available day/time. ${
      accountSettings && accountSettings.accountsettings && accountSettings.accountsettings.booking_tinyurl
        ? accountSettings.accountsettings.booking_tinyurl
        : (tinyUrl ? tinyUrl : '')
    }`
    setContact(useData)
    setOrginalData(useData)
    setMessage(msg)
    return () => {
      // cleanup
    }
  }, [open])

  const handleReset = () => {
    onClose()
  }
  const isContactUpdated = () => {
    let res =
      contact.email !== oldcontact.email || contact.name !== oldcontact.name || contact.mobile !== oldcontact.mobile
    return res
  }
  return (
    <Popup
      open={isopen}
      title={'Send Booking Link'}
      type="small"
      overrideForm={true}
      id={'modalSendBookingLink'}
      modalBackdropClicked={() => handleReset()}>
      {isopen ? (
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          enableReinitialize
          initialValues={{
            email: contact && contact.email,
            mobile: contact && contact.mobile,
            name: contact && contact.name,
            emailOrPhone: (contact && contact.email) || (contact && contact.mobile),
            message: message
          }}
          validationSchema={Yup.object().shape({
            emailOrPhone: Yup.string().required('Email or Phone number required')
          })}
          onReset={() => {
            onClose()
          }}
          onSubmit={async (values: any) => {
            setLoading(true)
            let nameData = await parseName(values.name)
            const contact = contactDetails && contactDetails.contact ? contactDetails.contact : null

            let contactData = {
              contact_id: contact && contact._id,
              first_name: nameData.first,
              last_name: nameData.last,
              email: values.email,
              mobile: values.mobile,
              image: contactDetails && contactDetails.avatar
            }
            await Promise.all([
              new Promise(async (resolve, reject) => {
                await onSendBookingLink({
                  contact_id: contact && contact._id,
                  recipient: values.email || values.mobile, // Accepts both email and phone numbers
                  message: values.message
                })
                resolve(true)
              }),
              new Promise(async (resolve, reject) => {
                if (isContactUpdated() && !disableContactsImport) {
                  if ((contact && contact.status === 'trashed') || (contact && !contact.is_public)) {
                    await dispatch.contact.addContact(contactData)
                    onContactUpdated(false)
                  } else {
                    await dispatch.contact.updateContact(contactData)
                    onContactUpdated(true)
                  }
                }
                resolve(true)
              })
              
            ])
            setLoading(false)
            onClose()
          }}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form>
                <button type="reset" className="close custom-modal-close-button ">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="row">
                  <div className="col-12 mb-20 pt-5">
                    <div className="form-group mb-0">
                      <ErrorMessage name="emailOrPhone" component="span" className="form-error ml-0" />
                    </div>
                    <ContactInfoWidget
                      name={values.name}
                      email={values.email}
                      mobile={values.mobile}
                      avatar={contactDetails && contactDetails.avatar}
                      onDataChange={(data: { field: string; value: string }) => {
                        if (data.field === 'mobile') {
                          setContact({ ...contact, [data.field]: numberMask(data.value) })
                        } else {
                          setContact({ ...contact, [data.field]: data.value })
                        }
                      }}
                      clear={clearContact}
                      resetEmail={isFieldChanged('email')}
                      resetMobile={isFieldChanged('mobile')}
                      isEditable={true}
                    />
                  </div>

                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="label-text">MESSAGE</label>
                      <div className="mb-10">
                        <PredefinedTemplate
                          templates={common.predefinedTemplates}
                          selectTemplateFn={(data: templateType) => selectTemplate(data)}
                        />
                      </div>
                      <Field
                        component="textarea"
                        id="frmContactListGetPaid_txtMessage"
                        className="form-control"
                        type="text"
                        placeholder="Enter message"
                        name="message"
                        rows="3"
                      />
                    </div>
                  </div>
                </div>
                <footer className="d-block d-flex">
                  <button
                    type="submit"
                    className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block'}
                    disabled={loading}>
                    SEND
                    {loading ? (
                      <i className="btn-icon top-minus-2">
                        <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
                      </i>
                    ) : null}
                  </button>
                  <button
                    type="reset"
                    id="frmGetPaid_btnCancel"
                    className={'btn btn-xl m-0 cancel-btn custom-btn-width'}>
                    {' '}
                    CANCEL
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      ) : null}
    </Popup>
  )
}
export default SendBookingLinkPopup
