import * as service from './service';
import notification from '@components/notification';
import { capitalize } from '@helpers/index';
import { updateTemplates } from '@helpers/instant-sites-utility';
import * as reviewService from '../reviews/service';
import { getShutterStockMedia, getHqShutterstockImage, saveShutterstock } from '../media-library/service';

import config from '@config/index';

export default {
  state: {
    siteImages: [],
    hasStockImages: false,
    hasGalleyImages: false,
    galleryStockImagesIds: [],
    domainIntentType: null
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: false,
      };
    },

    onError(state, data) {
      let err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : 'Please try again later';
      notification('error', capitalize(err));
      return {
        ...state,
        loading: false,
        apiError: true,
      };
    },
    onListBusinessSuccess(state, data) {
      return {
        ...state,
        loading: false,
        businessData: data.results,
      };
    },
    onGetBusinessSuccess(state, data) {
      return {
        ...state,
        loading: false,
        businessDetails: data.business,
      };
    },
    savePlaceIDSuccess(state, data) {
      return {
        ...state,
        loading: false,
        place_id: data.place_id,
        location_name: data.location_name ? data.location_name : null,
        saved_business: data.saved_business ? data.saved_business : null,
        isBackToInfo: data.isBackToInfo ? data.isBackToInfo : false,
        isCustomBusiness: data.isCustomBusiness ? data.isCustomBusiness : false,
      };
    },
    saveOldCategorySuccess(state, data) {
      return {
        ...state,
        loading: false,
        oldCategory: data.oldCategory
      };
    },
    onsendRocketFuelBusinessDataSuccess(state, data) {
      return {
        ...state,
        loading: false,
      };
    },
    onGetRocketFuelBusinessDataSuccess(state, data) {
      return {
        ...state,
        loading: false,
        rocketBusinessData: data.businessData,
      };
    },
    onUpdateSitePreviewData(state, data) {
      let previewData = { ...data };
      // previewData.account_settings = {
      //     timezone: "America/Los_Angeles"
      // }
      let paymentLink = '';
      //variable to save published status
      let instantSitePublishedStatus = null;
      if (previewData.business && previewData.business.name)
        paymentLink = `${config.paymentSite}/${previewData.business.name
          .toLowerCase()
          .replace(/[^a-z0-9]/g, '-')}/payment`;

      previewData.payment = { url: paymentLink };
      if (state.recentReviews && state.recentReviews.length)
        previewData = updateTemplates(previewData, state.recentReviews); // review data not saved with website details

      if (previewData && previewData.id) localStorage.setItem('instantSiteId', previewData.id);
      if (previewData && previewData.published_status) instantSitePublishedStatus = previewData.published_status;
      return {
        ...state,
        loading: false,
        previewData,
        instantSitePublishedStatus,
      };
    },
    onListWebsiteSuccess(state, data) {
      return {
        ...state,
        loading: false,
        instantSites: data,
      };
    },
    onDeleteWebsiteSuccess(state, data) {
      notification('success', 'Website deleted');
      return {
        ...state,
        loading: false,
        previewData: null,
      };
    },
    onpublishWebsite(state, data) {
      return {
        ...state,
        loading: false,
        publishedStatus: data && data.published_status,
      };
    },
    onGetpublishWebsiteStatus(state, data) {
      return {
        ...state,
        loading: false,
        publishedSiteDetails: data,
      };
    },
    onClearSiteData(state, data) {
      localStorage.removeItem('instantSiteId');
      return {
        ...state,
        loading: false,
        previewData: null,
        siteImages: [],
        hasStockImages: false,
        siteGalleryImages: [],
        hasGalleyImages: false,
        businessDetails: {},
        place_id: null,
        location_name: null,
        saved_business: null,
        isBackToInfo: false,
        isCustomBusiness: false,
        galleryStockImagesIds: [],
        instantSitePublishedStatus: null,
        progress: null,
        oldCategory: null,
        domainIntentType: null,
      };
    },
    onGetSuggestedServices(state, data) {
      let services = [];
      if (data && data.services) {
        if (data.services.static && data.services.static.length) {
          services = [...data.services.static];
        } else if (data.services.dynamic && data.services.dynamic.length) {
          services = [...data.services.dynamic];
        } else if (data.services.generic && data.services.generic.length) {
          services = [...data.services.generic];
        }
      }
      return {
        ...state,
        loading: false,
        suggestedServices: services,
      };
    },
    onGetRecentReview(state, data) {
      console.log(data);
      let recentReviews = data.reviews ? data.reviews : [];
      return {
        ...state,
        loading: false,
        recentReviews: recentReviews,
      };
    },
    onGetHqShutterstockSuccess(state, data) {
      return {
        ...state,
        shutterstockHqImg: data,
      };
    },
    onGetMediaforSiteSuccess(state, data) {
      return {
        ...state,
        loading: false,
        siteImages: data,
      };
    },
    onGetGallerMediaforSiteSuccess(state, data) {
      return {
        ...state,
        loading: false,
        siteGalleryImages: data,
      };
    },
    onSetStockImage(state, data) {
      return {
        ...state,
        loading: false,
        hasStockImages: true,
        siteImages: [],
      };
    },
    onSetDomainIntent(state, data) {
      return {
        ...state,
        domainIntentType: data,
      };
    },
    onSetGalleryImage(state, data) {
      return {
        ...state,
        loading: false,
        hasGalleyImages: true,
        siteGalleryImages: [],
      };
    },
    onUpdateProgressData(state, data) {
      return {
        ...state,
        progress: data,
      };
    },
    onSetGalleryImagesId(state, data) {
      return {
        ...state,
        galleryStockImagesIds: data,
      };
    },
  },
  effects: {
    async listBusinessDetails(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listBusinessDetails(payload);
        this.onListBusinessSuccess(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async getBusinessDetails(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getBusinessDetails(payload);
        this.onGetBusinessSuccess(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async savePlaceID(payload, rootState) {
      try {
        this.savePlaceIDSuccess(payload);
      } catch (e) {
        // this.onError(e)
      }
    },
    async saveOldCategory(payload) {
      try {
        this.saveOldCategorySuccess(payload);
      } catch (e) {
        console.log(e);
      }
    },
    async sendRocketFuelBusinessData(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.sendRocketFuelBusinessData(payload);
        this.onsendRocketFuelBusinessDataSuccess(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async getRocketFuelBusinessData(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getRocketFuelBusinessData(payload);
        this.onGetRocketFuelBusinessDataSuccess(res.data);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async updateSitePreviewData(payload, rootState) {
      try {
        this.onUpdateSitePreviewData(payload);
        return payload;
      } catch (e) {
        // this.onError(e)
      }
    },
    async createWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createWebsite(payload);
        this.onUpdateSitePreviewData(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async getWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getWebsite(payload);
        this.onUpdateSitePreviewData(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async updateWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.updateWebsite(payload);
        this.onUpdateSitePreviewData(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async listWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.listWebsite(payload);
        this.onListWebsiteSuccess(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async deleteWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.deleteWebsite(payload);
        this.onDeleteWebsiteSuccess(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async publishWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.publishWebsite(payload);
        this.onpublishWebsite(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async publishWebsiteStatus(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.publishWebsiteStatus(payload);
        this.onpublishWebsite(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async updatePublishedWebsite(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.updatePublishedWebsite(payload);
        this.onpublishWebsite(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async clearSiteData(payload, rootState) {
      try {
        this.onClearSiteData(payload);
      } catch (e) {
        // this.onError(e)
      }
    },
    async getSuggestedServices(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getSuggestedServices(payload);
        this.onGetSuggestedServices(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async getRecentReview(payload, rootState) {
      this.onRequest();
      try {
        let res = await reviewService.ReviewList(payload);
        this.onGetRecentReview(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async getHqShutterstockImage(payload, data, url) {
      try {
        let res = await getHqShutterstockImage(payload, url);
        this.onGetHqShutterstockSuccess(res);
        return res;
      } catch (e) {
        // this.onError(e);
      }
    },
    async getStockMediaforInstantSite(payload, rootState, hasBusinessPhotos) {
      let images = [];
      try {
        let res = await getShutterStockMedia(payload);
        //if 0 or <= 5 stock images found then split the query string and use each work as new query
        if (res.data.length <= 5) {
          let query = payload.query;
          let queryData = query.split(' ');
          if (queryData.length) {
            for (let i = 0; i < queryData.length; i++) {
              let newQuery = queryData[i];
              payload.query = newQuery;
              res = await getShutterStockMedia(payload);
              if (res.data && res.data.length) {
                break;
              }
            }
          }
        }
        if (res.data.length) {
          let mediaIds = [];
          let randomImageIndex = Math.floor(Math.random() * 20);
          let index = randomImageIndex <= res.data.length ? randomImageIndex : 0;
          mediaIds.push(res.data[index].id);
          res.data.splice(index, 1);
          // res.data.forEach((media) => mediaIds.push(media.id));
          var data = {
            images: [...mediaIds],
          };
          var url = {
            orientation: 'horizontal',
          };
          let imagesRes = await saveShutterstock(data, url);
          // let imagesRes = await this.getHqShutterstockImage(data, url);
          if (imagesRes && imagesRes.length) {
            // imagesRes.forEach((img) => images.push({ id: img.sst_id, url: img.fileUrl }));
            imagesRes.forEach((img) => images.push({ url: img.fileUrl }));
          }
          this.onGetMediaforSiteSuccess(images);
          //if no business photos available then download 5 stock images and save in s3
          if (res.data.length && !hasBusinessPhotos) {
            let galleryPhotos = res.data.splice(0, 5);
            let d = {
              images: [...galleryPhotos.map((i) => i.id)],
            };
            let galleryPics = await saveShutterstock(d, url);
            let photos = [];
            if (galleryPics && galleryPics.length) {
              galleryPics.forEach((img) => photos.push({ url: img.fileUrl }));
              this.onGetGallerMediaforSiteSuccess(photos);
              this.onSetGalleryImagesId([...galleryPhotos.map((i) => i.id)]);
            }
          }
        }

        return res;
      } catch (e) {
        this.onError(e);
      }
    },
    async setStockImage(payload, rootState) {
      try {
        this.onSetStockImage(payload);
      } catch (e) {
        // this.onError(e)
      }
    },
    async setGalleryImage(payload, rootState) {
      try {
        this.onSetGalleryImage(payload);
      } catch (e) {
        // this.onError(e)
      }
    },
    async createProgress(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.createProgress(payload);
        this.onUpdateProgressData(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async getProgress(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.getProgress(payload);
        this.onUpdateProgressData(res);
        return res;
      } catch (e) {
        // this.onError(e)
        console.log(e);
      }
    },
    async progressFunction(payload, rootState) {
      let state = rootState.instantSites;
      let user = rootState.auth.user;

      // Only save the progress if we have a siteId, don't want a null project
      let siteID = window.localStorage.getItem('instantSiteId');

      if (state.instantSitePublishedStatus === 'unpublished' && siteID) {
        //check if progress is already created
        if (state.progress) {
          let payloadData = state.progress;
          payloadData.position = payload;
          this.updateProgress(payloadData);
        } else {
          //else create new progress
          let payloadData = {
            user: user.user_unique_id,
            project: `instant_sites_${window.localStorage.getItem('instantSiteId')}`,
            position: payload,
          };
          this.createProgress(payloadData);
        }
      }
    },
    async updateProgress(payload, rootState) {
      this.onRequest();
      try {
        let res = await service.updateProgress(payload);
        this.onUpdateProgressData(res);
        return res;
      } catch (e) {
        // this.onError(e)
      }
    },
    async domainIntent(payload, rootState,website_id) {
      this.onRequest();
      try {
        let res = await service.domainIntent(payload,website_id);
        return res;
      } catch (e) {
        this.onError(e)
      }
    },
    async setDomainIntent(payload, rootState) {
      try {
        this.onSetDomainIntent(payload);
      } catch (e) {
        // this.onError(e)
      }
    },
  },
};
