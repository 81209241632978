import { usePrevious } from '@helpers/hooks'
import { useSelector } from 'react-redux'
import { ActionCenterUserStatus } from './types'
import { useEffect, useState } from 'react'

export function usePreviousACStatus () {
  const storeStatus: ActionCenterUserStatus = useSelector<any, ActionCenterUserStatus>(
    (state) => state.actionCenter.status
  )
  const [status, setStatus] = useState<ActionCenterUserStatus>(storeStatus)
  const previousStatus = usePrevious<ActionCenterUserStatus>(status)

  useEffect(() => {
    setStatus(storeStatus)
  }, [storeStatus])

  return { status, previousStatus }
}
