import { api, apiV2, apiV2Uberall, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { getV2ConfigCatValue } from '@helpers/utility'

export function getReviewRequests(payload) {
  return api()
    .get(`${config.routes.review_requests}?${objToUrlParams(payload)}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function ReviewList(payload) {
  return api()
    .get(`${config.routes.reviews}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getFeedback(payload) {
  return api()
    .get(`${config.routes.feedback}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteScheduleRequest(payload) {
  return api()
    .put(config.routes.delete_sheduled_review_requests, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resetScheduleRequest(payload) {
  return api()
    .post(config.routes.reset_sheduled_review_requests, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendNow(payload) {
  return api()
    .post(config.routes.change_review_status, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function reviewsAnalytics(payload) {
  return api()
    .get(config.routes.review_analytics)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getFeedbackDetails(feedback_id) {
  return api()
    .get(`${config.routes.feedback}/` + feedback_id)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendReply(feedback_id, data) {
  return api()
    .post(`${config.routes.feedback}/` + feedback_id, data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendReplyFB(data) {
  return api()
    .post(`${config.routes.feedbackFB}${data.user_id ? `?customers=${data.user_id}` : ''}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendReplyGoogle(data) {
  return api()
    .post(`${config.routes.feedbackGoogle}?customers=${data.user_id}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendReplyYelp(data) {
  return api()
    .post(`${config.routes.feedbackYelp}?customers=${data.user_id}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function sendreplyReview(payload) {
  return api()
    .post(`${config.routes.reviewReply}?customers=${payload.user_id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function editreplyReview(payload) {
  const id = payload.review_id
  const message = { comment: payload.comment }
  return api()
    .put(`${config.routes.reviewReply}/${id}?customers=${payload.user_id}`, message)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function editreplyYelp(payload) {
  const id = payload.comment_id
  const message = { comment: payload.comment, review_id: payload.review_id }
  return api()
    .put(`${config.routes.feedbackYelp}/${id}?customers=${payload.user_id}`, message)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteReview(payload) {
  return api()
    .put(config.routes.deleteReview, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteReviewComment(payload, id) {
  return api()
    .delete(`${config.routes.reviewReply}/${payload}${id ? `?customers=${id}` : ''}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteGMBReply(payload, id) {
  const message = { review_id: payload }
  return api()
    .post(`${config.routes.gmbReplyDelete}${id ? `?customers=${id}` : ''}`, message)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteYelpReply(payload) {
  return api()
    .delete(`${config.routes.feedbackYelp}/${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getReviewById(reviewId) {
  return api()
    .get(`${config.routes.reviewManager}/` + reviewId)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getContactList(payload) {
  return api()
    .get(`${config.routes.contactList}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getContactListFromES(payload) {
  return api()
    .get(`${config.routes.contactListFromES}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPredefinedTemplate(payload) {
  return api()
    .get(`${config.routes.predefinedTemplate}${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendSmsRequest(payload) {
  return api()
    .post(config.routes.reviewSendSms, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function reviewrequestAnalytics(payload) {
  return api()
    .get(config.routes.reviewEmailAnalytics, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getReviewWidgets(payload) {
  return api()
    .get(`${config.routes.reviewWidgets}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function removeReviewWidget(payload) {
  return api()
    .put(config.routes.removeReviewWidget, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function changeFavouriteWidget(payload) {
  return api()
    .post(`${config.routes.reviewWidgets}/${payload.id}/favourite`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendSms(payload) {
  return api()
    .post(config.routes.reviewSendSms, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendReview(payload, query) {
  return api()
    .post(`${config.routes.sendReview}${query ? `?${objToUrlParams(query.customer)}` : ''}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchWidgetDetails(id) {
  return api()
    .get(`${config.routes.fetchWidgetDetails}/${id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function syncReviews() {
  const value = await getV2ConfigCatValue('FB')
  if (value) {
    return apiV2()
      .get(config.routes.syncReviewsV2)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .get(config.routes.syncReviews)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function getReviewLastSyncTime() {
  return api()
    .get(config.routes.lastSyncTime)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function uberAllCategory(payload, module) {
  const value = await getV2ConfigCatValue('uberallBanners')
  if (value) {
    return apiV2Uberall()
      .get(`${config.routes.uberAllCategories}/${payload}?module=${module}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return ''
  }
}
export function getReviewShareableLink(payload) {
  if (payload) {
    return api()
      .get(`${config.routes.reviews}/${payload}/review-shareable-url`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .get(config.routes.reviewShareableLink)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
