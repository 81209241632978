import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'

export function getApplications(payload) {
  return api()
    .get(config.routes.getApplications + '/' + payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getPolicies(payload) {
  return api()
    .get(config.routes.getPolicies + '/' + payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function coverdashCreateBusiness(payload) {
  return api()
    .post(config.routes.createCoverdashBusinessID, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function coverdashSaveBusinessID(payload) {
  return api()
    .post(`${config.routes.user}/${payload.id}${config.routes.saveCoverdashBusinessID}`, payload)
    .then((res) => res.data.message)
    .catch(catchHandler)
}

export function createApplicationSubmission() {
  return api()
    .get(config.routes.createApplicationSubmission)
    .then((res) => res.data)
    .catch(catchHandler)
}
