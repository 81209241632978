import * as service from './service'
import * as paymentService from '../payment/service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { fetchSubdomainAvailability } from '../sites/service'
import { segmentEventTrack } from '@helpers/segment'
import moment from 'moment'
import { getActivityList } from '../home/service'
import { trackTransactionStatusChange } from '../payment/service'
import i18n from '@/i18n'

function segmentTrackingBooking(state, val, number, confirmed, amount, fullpay) {
  let data = {}
  const today = new Date()
  const time = moment(today).format('LT')
  const date = moment(today).format('L')
  if (val === 'booking-added') {
    data = {
      event: 'new_booking_added',
      properties: {
        'new_booking-added': number,
        total_bookings: state.bookingTotalCount + number,
        amount: amount,
        Payment: !!(amount && amount.length),
        date: date,
        time: time
      }
    }
    if (fullpay) {
      data.properties.full_payment = true
    } else if (amount) {
      data.properties.partial_pay = true
    }
  } else if (val === 'booking_requests_confirmed') {
    data = {
      event: 'booking_requests_confirmed',
      properties: {
        booking_requests_confirmed: confirmed + 1,
        total_bookings: state.bookingTotalCount + number,
        date: date,
        time: time
      }
    }
  }
  segmentEventTrack(data)
}

export default {
  state: {
    isActivity: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: '',
        bookingApiError: ''
      }
    },
    onError(state, data) {
      console.log('error data===>', data)
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.data && data.data.error
          ? data.data.error
          : i18n.t('common.please-try-again-later')
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: err,
        bookingApiError: err
      }
    },
    onSuccessWithMessage(state, data, message) {
      notification('success', message)
      return {
        ...state,
        loading: false
      }
    },
    onBookingSuccess(state, data, message) {
      // notification("success", message)
      return {
        ...state,
        loading: false,
        bookingresponse: data && data.data,
        confirmedBookingCount: state.confirmedBookingCount + 1
      }
    },
    onConfirmSuccess(state, data, message) {
      notification('success', message)
      return {
        ...state,
        loading: false,
        confirmedBookingCount: state.confirmedBookingCount + 1
      }
    },
    ongetBookingCustomFields(state, data) {
      return {
        ...state,
        loading: false,
        customFields: data.customFields
      }
    },
    ongetBookingServices(state, data) {
      return {
        ...state,
        loading: false,
        serviceList: data.data
      }
    },
    ongetDateSlots(state, data) {
      return {
        ...state,
        loading: false,
        dateSlots: data.data.dates
      }
    },
    ongetCalendarDataSuccess(state, data) {
      return {
        ...state,
        loading: false,
        calanderData: data.data
      }
    },
    onfetchBookingData(state, data) {
      return {
        ...state,
        loading: false,
        bookinglist: data.data
      }
    },
    onfetchConfirmedBookingData(state, data) {
      return {
        ...state,
        confirmedBookingCount: data.data.total
      }
    },
    onfetchBookingCount(state, data) {
      return {
        ...state,
        loading: false,
        bookingTotalCount: data && data.data && data.data.total
      }
    },
    onRescheduleSuccess(state, data, message) {
      notification('success', message)
      return {
        ...state,
        loading: false,
        bookingresponse: data && data.data
      }
    },
    onfetchServiceSlotSuccess(state, data) {
      return {
        ...state,
        serviceTimeSlots: data,
        loading: false
      }
    },
    onBookingDetails(state, data) {
      return {
        ...state,
        loading: false,
        bookingData: data.data
      }
    },
    onWebsitesTotalCount(state, data) {
      return {
        ...state,
        loading: false,
        websiteCount: data.data
      }
    },
    onBookingHistory(state, data) {
      return {
        ...state,
        loading: false,
        bookingHistory: data.data
      }
    },
    connectGoogleCalendarSuccess(state, data) {
      if (data && data.success) {
        notification('success', data.success)
      }
      return {
        ...state,
        loading: false,
        googleCalendarSync: data.data
      }
    },
    onDomainAvailable(state, data) {
      return {
        ...state,
        loading: false,
        availableDomain: data && data.url
      }
    },
    onGetInvoiceNumberSuccess(state, data) {
      return {
        ...state,
        loading: false,
        next_invoice: data.next_invoice
      }
    },
    onGetUnAvailableDateSlotsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        unAvailabledateSlots: data && data.data.unavailable_slots
      }
    },
    editBookingSuccess(state, data) {
      notification('success', i18n.t('bookings.appointment-updated-successfully'))
      return {
        ...state,
        loading: false,
        bookingresponse: data && data.data
      }
    },
    bookingActivitySuccess(state, data) {
      let showBookingAlert = false
      if (data && data.docs && data.docs.length) {
        const [first] = data.docs
        if (first && first.created_at && moment().diff(first.created_at, 'months') < 6) {
          showBookingAlert = true
        }
      }
      return {
        ...state,
        loading: false,
        isActivity: true,
        showBookingAlert
      }
    },
    onLocalBookingDetails(state, data) {
      return {
        ...state,
        loading: false,
        bookingData: data.data,
        bookingHistory: null
      }
    },
    onCancelIncompleteBooking(state, data) {
      if (data && data.message) {
        notification('success', data.message)
      }
      return {
        ...state,
        loading: false
      }
    },
    onBookingPaymentSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    }
  },
  effects: {
    async addBooking(payload, rootState) {
      this.onRequest()
      const {
        payment_type: paymentType,
        payment: { merchant_id: merchantId }
      } = (rootState.auth.me || {}).account_settings || {}
      try {
        if (
          payload.booking_id &&
          payload.booking_id !== '' &&
          payload.collect_via === 'direct' &&
          paymentType === 'stripe'
        ) {
          const { stripeObj: stripe, paymentMethod } = payload
          delete payload.stripeObj
          delete payload.paymentMethod
          delete payload.card_token
          const intentPayload = {
            inputSource: 'booking',
            name: payload.card_holder,
            postal: payload.postal,
            firstName: payload.first_name,
            lastName: payload.last_name,
            amount: payload.payment_amount,
            bookingId: payload.booking_id,
            tip: '',
            merchantId
          }
          const clientSecret = await paymentService.getPaymentIntent(intentPayload)
          try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret.items.clientSecret, {
              payment_method: paymentMethod
            })
            if (error) {
              console.log('**** Error from Stripe Confirmation call ****  ', error)
              payload.paymentIntentId = error.payment_intent.id
              this.onError(error)
            } else {
              payload.paymentIntentId = paymentIntent.id
            }
          } catch (e) {
            console.log('**** Error from Stripe Confirmation call ****  ', e)
            this.onError(e)
          }
        }
        const res = await service.addBooking(payload)
        segmentTrackingBooking(rootState.booking, 'booking-added', 1, rootState.booking.confirmedBookingCount, 0, false)
        segmentTrackingBooking(
          rootState.booking,
          'booking_requests_confirmed',
          1,
          rootState.booking.confirmedBookingCount
        )
        this.onBookingSuccess(res, i18n.t('bookings.appointment-created-successfully'))
        return res
      } catch (e) {
        console.log('******** INSIDE ADD BOOKING ******* ', e)
        this.onError(e)
      }
    },
    async getBookingServices() {
      this.onRequest()
      try {
        const res = await service.getBookingServices()
        await this.ongetBookingServices(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getCustomFieldsProd(payload) {
      this.onRequest()
      try {
        const res = await service.getCustomFieldsProd(payload)
        await this.ongetBookingCustomFields(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBookingServicesOnly(payload) {
      this.onRequest()
      try {
        let res
        if (payload && payload.userType === 'staff') {
          res = await service.getStaffBookingService()
        } else {
          res = await service.getBookingServicesOnly()
        }
        await this.ongetBookingServices(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getDateSlots(val) {
      try {
        const res = await service.getDateSlots(val)
        await this.ongetDateSlots(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBookingCalendarData(data) {
      this.onRequest()
      try {
        const res = await service.getCalendarData(data)
        await this.ongetCalendarDataSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchBookingData(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchBookingData(payload)
        if (payload.status === 'confirmed') {
          this.onfetchConfirmedBookingData(res)
        } else {
          this.onfetchBookingData(res)
        }
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getAllBookingCount(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchBookingData(payload)
        this.onfetchBookingCount(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendMessageToBooking(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendMessageToBooking(payload)
        //  SegmentTrack("message-sent",1,false,true)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.message-sent-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async rescheduleBooking(data, rootState) {
      this.onRequest()
      try {
        const res = await service.rescheduleBooking(data)
        this.onRescheduleSuccess(res, i18n.t('bookings.appointment-rescheduled-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async cancelBooking(data, rootState) {
      this.onRequest()
      try {
        const res = await service.cancelBooking(data)
        this.onSuccessWithMessage(res, i18n.t('bookings.appointment-canceled-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async confirmBooking(data, rootState) {
      this.onRequest()
      try {
        const res = await service.confirmBooking(data)
        segmentTrackingBooking(
          rootState.booking,
          'booking_requests_confirmed',
          1,
          rootState.booking.confirmedBookingCount
        )
        this.onConfirmSuccess(res, i18n.t('bookings.appointment-approved-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchServiceSlot(data, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchServiceSlot(data)
        this.onfetchServiceSlotSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBookingDetails(data) {
      this.onRequest()
      try {
        const res = await service.getBookingDetails(data)
        this.onBookingDetails(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getWebsitesTotalCount() {
      this.onRequest()
      try {
        const res = await service.getWebsitesTotalCount()
        this.onWebsitesTotalCount(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBookingHistory(data) {
      this.onRequest()
      try {
        const res = await service.getBookingHistory(data)
        this.onBookingHistory(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async connectGoogleCalendar(data) {
      this.onRequest()
      try {
        const res = await service.connectGoogleCalendar(data)
        this.connectGoogleCalendarSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async bookingCreateButtonWIdget(data) {
      this.onRequest()
      try {
        const res = await service.bookingCreateButtonWidget(data)
        this.onSuccessWithMessage(res, i18n.t('bookings.booking-button-successfully-updated'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async connectOutlookCalendar(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.connectOutlookCalendar(payload)
        this.onSuccessWithMessage(
          res,
          res.message ? res.message : i18n.t('bookings.you-have-successfully-synced-calendar')
        )
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchSubdomainAvailability(payload, service) {
      this.onRequest()
      try {
        const res = await fetchSubdomainAvailability(payload)
        this.onDomainAvailable(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async bookingSiteAndCreateButtonWIdget(data) {
      this.onRequest()
      try {
        const res = await service.bookingCreateButtonWidget(data)
        this.onSuccessWithMessage(res, i18n.t('bookings.booking-site-created-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getInvoiceNumber(payload) {
      try {
        const res = await service.getInvoiceNumber(payload)
        this.onGetInvoiceNumberSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getUnAvailableDateSlots(payload) {
      try {
        const res = await service.getUnAvailableDateSlots(payload)
        this.onGetUnAvailableDateSlotsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async editBooking(payload) {
      try {
        const res = await service.editBooking(payload)
        this.editBookingSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBookingActivity(payload) {
      try {
        const res = await getActivityList(payload)
        this.bookingActivitySuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async localUpdateBookingDetails(data) {
      try {
        this.onLocalBookingDetails(data)
        return data
      } catch (e) {
        this.onError(e)
      }
    },
    async cancelIncompleteBooking(payload) {
      try {
        const res = await service.cancelIncompleteBooking(payload)
        this.onCancelIncompleteBooking(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async collectBookingDuePayment(payload) {
      try {
        const res = await service.collectBookingPayment(payload)
        notification('success', i18n.t('contacts_hub.transaction-request-has-been-sent-successfully'))
        this.onBookingPaymentSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async collectBookingDueManualPayment(payload, rootState) {
      try {
        const {
          payment_type: paymentType,
          payment: { merchant_id: merchantId }
        } = (rootState.auth.me || {}).account_settings || {}
        if (
          payload.booking_id &&
          payload.booking_id !== '' &&
          payload.collect_via === 'direct' &&
          paymentType === 'stripe'
        ) {
          const { stripeObj: stripe, paymentMethod } = payload
          delete payload.stripeObj
          delete payload.paymentMethod
          delete payload.card_token
          const intentPayload = {
            inputSource: 'booking',
            name: payload.card_holder,
            postal: payload.postal,
            firstName: payload.first_name,
            lastName: payload.last_name,
            amount: payload.payment_amount,
            bookingId: payload.booking_id,
            tip: '',
            merchantId
          }
          const clientSecret = await paymentService.getPaymentIntent(intentPayload)
          try {
            const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret.items.clientSecret, {
              payment_method: paymentMethod
            })
            if (error) {
              console.log('**** Error from Stripe Confirmation call ****  ', error)
              // payload.paymentIntentId = error.payment_intent.id
              this.onError(error)
              payload.paymentIntentId = error.payment_intent.id
            } else {
              payload.paymentIntentId = paymentIntent.id
            }
          } catch (error) {
            this.onError(error)
            throw error
          }
        }
        const res = await service.collectBookingManualPayment(payload)
        notification('success', i18n.t('bookings.transaction-completed-successfully'))
        this.onBookingPaymentSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async sendBookingGetPaidLink(payload) {
      try {
        const res = await service.sendBookingGetPaidLink(payload)
        notification('success', i18n.t('contacts_hub.transaction-request-has-been-sent-successfully'))
        this.onBookingPaymentSuccess(res)
        const { data = {} } = res
        const { user = '', status = '', amount = 0, tip = 0 } = data
        await trackTransactionStatusChange({ userId: user, status, amount, tip })
        return res
      } catch (e) {
        console.log(e)
      }
    }
  }
}
