import React, { Component } from 'react'
import Popup from '@components/popups/basic-modal'
import i18next from 'i18next'

export default class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      submitting: false
    }
  }

  showPopup(item) {
    this.setState({ open: true, item })
  }
  closeModal() {
    this.setState({ open: false })
  }
  async handleVoid() {
    await this.setState({ submitting: true })
    await this.props.handleResendRequset(this.state.item)
    await this.setState({ submitting: false })
    this.closeModal()
  }

  render() {
    const t = i18next.t
    return (
      <React.Fragment>
        <Popup
          open={this.state.open}
          title={
            this.state.item && this.state.item.source === 'invoice' ? t('payments.resend-invoice') : this.props.title
          }
          hideFooter={true}
          onCancel={() => this.setState({ open: false })}
          type="small"
          modalBackdropClicked={() => console.log(null)}
          id="transResendModal">
          <button
            id="transResendModalClose"
            onClick={() => this.setState({ open: false })}
            className="close custom-modal-close-button remove-modal-close">
            <span aria-hidden="true">×</span>
          </button>
          <div className="popup-content mt-10 mb-30">
            <p className="mb-0">
              {this.state.item && this.state.item.source === 'invoice'
                ? t('payments.do-you-want-to-resend-this-invoice')
                : this.props.message}
            </p>
          </div>
          <footer className="d-block d-sm-flex mt-10">
            <button
              disabled={this.state.submitting}
              onClick={this.handleVoid.bind(this)}
              className="btn btn-primary btn-xl border-0 flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20 px-0">
              {this.state.item && this.state.item.source === 'invoice'
                ? t('payments.yes-resend-invoice')
                : this.props.okText}
              {this.state.submitting ? (
                <i className="btn-icon top-minus-2 ml-5">
                  <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
                </i>
              ) : null}
            </button>
            <button
              onClick={() => this.setState({ open: false })}
              id="btnVoidClose"
              className="btn btn-xl m-0 cancel-btn custom-btn-width">
              NO
            </button>
          </footer>
        </Popup>
      </React.Fragment>
    )
  }
}
