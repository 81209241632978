import React from 'react'
import { RestrictedRoute, EnterpriseRoute } from '@app/router/access-route'
import asyncComponent from '@helpers/async-func'

export default [
  <RestrictedRoute
    exact
    path="/:userId/business-insurance"
    key="business-insurance"
    layoutSettings={{ page: 'business-insurance' }}
    component={asyncComponent(() => import('./Insurances'))}
  />,
  <EnterpriseRoute
    exact
    key="/enterprise/business-insurance"
    path={'/:userId/enterprise/business-insurance'}
    layoutSettings={{ page: 'business-insurance', title: 'Business Insurance' }}
    component={asyncComponent(() => import('./Insurances'))}
  />
]
