import React from 'react'
import { history } from '@app/store'
import Icon from '@components/icons'
import Modal from 'react-bootstrap4-modal'
import './send-invoice-success-popup.scss'
import moment from 'moment'
import { paymentChangers } from '@components/ConstUtil'
import GetRouteToPaymentMethodButton from '@containers/payment/getRouteToPaymentMethodButton'
import { useTranslation } from 'react-i18next'
const CUSTOM_STYLE = { background: '#fff', color: '#0258FF' }

const InvoiceSucesss = (props) => {
  const { t } = useTranslation()
  const { me, invoiceData, isMessenger = false } = props

  const redirectToPaymentDetail = () => {
    history.push(`/${me.user_unique_id}/transaction-details/${props.transactionId}`)
  }

  const customer =
    invoiceData.customer_first_name && invoiceData.customer_last_name
      ? `${invoiceData.customer_first_name} ${invoiceData.customer_last_name}`
      : invoiceData.customer_email
      ? invoiceData.customer_email
      : invoiceData.customer_phone

  const message = invoiceData.collect_payments
    ? t('payments.once-youve-been-paid-the-invoice-status-will-update-automatically-in-your-gosite-dashboard')
    : t('payments.once-youve-been-paid-login-to-the-gosite-dashboard-to-reconcile-your-invoice')

  const isScheduled =
    invoiceData.status === 'schedule' || (invoiceData.send_type && invoiceData.send_type === 'schedule')
  const isEdit = !!invoiceData.id

  const successMessage = isEdit
    ? t('payments.invoice-updated')
    : isScheduled
    ? t('payments.invoice-scheduled')
    : t('payments.invoice-sent')

  const isRecurring = !!invoiceData.recurring_occurrences

  const additionalMessage = isScheduled
    ? t('payments.your-customer-customer-will-receive-the-isrecurring-invoice-on-date', {
        0: customer,
        1: isRecurring ? 'next' : '',
        2: moment(invoiceData.scheduled_date).format('MMM DD, YYYY')
      })
    : t('payments.your-customer-0-should-receive-the-invoice-shortly', {
        0: customer
      })
  return (
    <Modal
      visible={true}
      dialogClassName={props.showAdBanner ? 'send-invoice-success-popup' : 'send-invoice-success-popup-without-ad'}>
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="text-center" style={{ padding: '100px 65px 65px' }}>
              <div data-test="invoice-success-content" className="sucess-content">
                <i className="mb-30 d-inline-block" fill="#ff00ff">
                  <Icon width="85px" height="85px" name="tickRoundBorderGreen" />
                </i>
                <h1 className="mb-30">
                  {successMessage}
                  <br />
                  {t('payments.successfully')}
                </h1>
                <p className="additional-info">{additionalMessage}</p>
                <p className="additional-info">{message}</p>
              </div>
              <div data-test="back-to-dashboard-btn" className="back-to-dashboard-btn">
                <button onClick={props.close} className="btn btn-xl mb-15 mt-15 btn-primary">
                  {isMessenger ? t('payments.back-to-messenger') : t('payments.back')}
                  {/* {`BACK`} */}
                </button>
              </div>

              {isMessenger && (
                <div data-test="back-to-dashboard-btn" className="back-to-dashboard-btn">
                  <button onClick={redirectToPaymentDetail} className="btn btn-xl mb-15 mt-15 cancel-btn">
                    {t('payments.view-invoice')}
                  </button>
                </div>
              )}
            </div>
          </div>
          {props.showAdBanner && (
            <div className="col" style={{ backgroundColor: '#0258FF', borderRadius: '0 6px 6px 0' }}>
              <div className="text-center p-30">
                <div
                  data-test="invoice-success-ad-banner"
                  className="payment-ad-banner-content"
                  style={{ marginBottom: '1.5rem' }}>
                  <img
                    style={{ marginBottom: '-1.5rem' }}
                    src="https://dufzo4epsnvlh.cloudfront.net/image/invoice-success-popup.png"
                    alt=""></img>
                  <h1 className="mb-10" style={{ color: '#fff' }}>
                    {t('payments.bring-it-full-circle')}
                    <br />
                    {t('payments.by-getting-paid')}
                    <br />
                    {t('payments.through-gosite')}
                  </h1>
                  <p className="additional-info" style={{ color: '#fff' }}>
                    {t(
                      'payments.a-complete-set-of-modern-payment-options-get-paid-when-customers-book-services-directly-from-your-website-or-in-person-when-you-and-apos-re-on-the-job-site'
                    )}
                  </p>
                </div>
                <div
                  data-test="invoice-success-ad-banner-btn"
                  className="payment-onboarding-btn"
                  style={{ marginBottom: '30px' }}>
                  <GetRouteToPaymentMethodButton
                    className={'btn btn-xl mb-15 w-100'}
                    customStyle={CUSTOM_STYLE}
                    me={me}
                    trackEvent={'payment_request_confirmationpage'}
                  />
                </div>
                <p style={{ color: '#fff' }}>
                  <h4 style={{ color: '#fff' }}>{t('payments.just') + paymentChangers()}</h4>
                  {t('payments.per-transaction')}
                </p>
              </div>
            </div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default InvoiceSucesss
