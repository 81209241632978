import React from 'react';
import asyncComponent from '@helpers/async-func';
import { RestrictedRoute } from '@app/router/access-route';

export default [
  <RestrictedRoute
    exact
    key="/instant-sites"
    path={'/:userId/instant-sites'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./InstantSites'))}
  />,
  <RestrictedRoute
    exact
    key="/find-business"
    path={'/:userId/instant-sites/find-business'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./business/FindBusiness'))}
  />,
  <RestrictedRoute
    exact
    key="/business-info"
    path={'/:userId/instant-sites/business-info'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./business/business-info'))}
  />,
  <RestrictedRoute
    exact
    key="/business-hours"
    path={'/:userId/instant-sites/business-hours'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./business/BusinessHours'))}
  />,
  <RestrictedRoute
    exact
    key="/business-category"
    path={'/:userId/instant-sites/business-category'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./business/BusinessCategory'))}
  />,
  <RestrictedRoute
    exact
    key="/business-photos"
    path={'/:userId/instant-sites/business-photos'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./business/BusinessPhotos'))}
  />,
  <RestrictedRoute
    exact
    key="/business-services"
    path={'/:userId/instant-sites/business-services'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./business/business-services'))}
  />,
  <RestrictedRoute
    exact
    key="/website-preview"
    path={'/:userId/instant-sites/website-preview'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./preview/PreviewLoading'))}
  />,
  <RestrictedRoute
    exact
    key="/website-preview"
    path={'/:userId/instant-sites/:siteId/website-preview'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./preview/PreviewLoading'))}
  />,
  <RestrictedRoute
    exact
    key="/preview"
    path={'/:userId/instant-sites/:siteId/preview'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./preview/site-preview/preview'))}
  />,
  <RestrictedRoute
    exact
    key="/site-preview"
    path={'/:userId/instant-sites/:siteId/site-preview'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./preview/site-preview'))}
  />,
  <RestrictedRoute
    exact
    key="/final-preview"
    path={'/:userId/instant-sites/:siteId/final-preview'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./preview/FinalPreview'))}
  />,
  <RestrictedRoute
    exact
    key="/launch-site"
    path={'/:userId/instant-sites/:siteId/launch-site'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./publish/Launch'))}
  />,
  <RestrictedRoute
    exact
    key="/choose-domain"
    path={'/:userId/instant-sites/:siteId/choose-domain'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./publish/Domain'))}
  />,
  <RestrictedRoute
    exact
    key="/publish"
    path={'/:userId/instant-sites/:siteId/publish'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./publish/Publish'))}
  />,

  <RestrictedRoute
    exact
    key="/final-preview"
    path={'/:userId/instant-sites/:siteId/final-preview'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: true }}
    component={asyncComponent(() => import('./preview/FinalPreview'))}
  />,
  <RestrictedRoute
    exact
    key="/site"
    path={'/:userId/instant-sites/site'}
    layoutSettings={{ page: 'instant-sites', title: 'Instant Sites', hideLayout: false }}
    component={asyncComponent(() => import('./preview/list-site'))}
  />,
];
