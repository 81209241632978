import React, { Component } from 'react'
import { Provider } from 'react-redux'
import { RecoilRoot } from 'recoil'
import { store, history } from '@app/store'
import Routes from '@app/router'
import '@assets/scss/style.scss'

class App extends Component {
  render () {
    return (
      <RecoilRoot>
        <Provider store={store}>
          <Routes history={history} />
        </Provider>
      </RecoilRoot>
    )
  }
}

export default App
