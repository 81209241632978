import React from 'react'

export default () => {
  return (
      <div className="center-progress loader-icon">
          <div>
              <img src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/logo-loader.svg" alt="" />
          </div>
      </div>
  );
};