import React from 'react'
import Popup from '../popups/basic-modal'
import CoverdashQuotes from '@coverdash/quotes'
import config from '@config/index'
import { Box } from '@gositeinc/ui'

export const CreatePolicyPopup = ({ applicationSubmissionId, open, setOpen }) => {
  const onBoundPolicy = (bound) => {
    console.log('onBoundPolicy', bound)
    document.dispatchEvent(new Event('gosite-fetchPolicies'))
    document.dispatchEvent(new Event('gosite-fetchApplications'))
    setOpen(false)
  }

  const onApplicationSubmitted = (applicationId) => {
    console.log('onApplicationSubmitted', applicationId)
    document.dispatchEvent(new Event('gosite-fetchApplications'))
  }

  return (
    <Popup
      open={open}
      center
      type="media-library-popup"
      title={' '}
      hideFooter={true}
      overrideForm={true}
      modalBackdropClicked={() => setOpen(false)}>
      <Box>
        <CoverdashQuotes
          license={config.coverdash.license}
          env={config.coverdash.env}
          styles={{
            minHeight: '50rem'
          }}
          applicationSubmissionId={applicationSubmissionId}
          hybrid={true}
          allowScroll={true}
          autoResize={true}
          onBoundPolicy={onBoundPolicy}
          onApplicationSubmitted={onApplicationSubmitted}
        />
      </Box>
    </Popup>
  )
}
