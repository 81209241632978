// eslint-diasabled @typescript-eslint/no-unused-vars
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

// types
import {
  SearchResult,
  ContactDetails,
  MessagePayload,
  PrivateContact,
  PrivateContactPayload,
  MediaData,
  PredefinedTemplateData,
  SelectedContact
} from './types'
import { ActionMeta, OptionsType, GroupTypeBase } from 'react-select'

// Library components
import { Formik, Form, Field, ErrorMessage } from 'formik'
// formik validation
import * as Yup from 'yup'

// components in containers
import MediaLibraryPopup from '@containers/media-library/media/media-library'
// components
import Popup from '../basic-modal'
import Icons from '../../icons'
import PredefinedTemplate from '../../predefined-templates'
import notification from '../../notification'
// popup components
import { ClosePopupButton } from '../components/closePopupButton'
import { LoadingSubmitButton, CancelButton } from '../components/buttons'
// this message popup components
import { LocationList } from './locationList'
import { MessageCreatableSelect, MessageSelectOption } from './messageCreatableSelect'

// helpers
import {
  isEnabled,
  phoneNumberFormat,
  validateEmail,
  validateMobile,
  encryptMessageUsingRSAPub
} from '@helpers/utility'
import { trackSendMessage } from '@containers/messenger/tracking'
import { useTranslation } from 'react-i18next'
/**
 * For `onSendMsgSuccess(threadId)`, you can push onto the history stack something like:
 * ```
 * history.push(
 * `/${user_unique_id}/messenger?threadId=${threadId}`
 * );
 * ```
 * */
interface SendMessagePopupProps {
  open: boolean
  onClose: () => void
  enterprise?: boolean
  bulkUpdate?: boolean
  onSendMsgSuccess: (threadId: string, message: string, messageType: string) => void
}
export const SendMessagePopup = ({
  open,
  onClose,
  enterprise,
  bulkUpdate,
  onSendMsgSuccess
}: SendMessagePopupProps) => {
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState<boolean>(false)
  const [contactDetails, setContactDetails] = useState<ContactDetails | undefined>()
  const [selectedContacts, setSelectedContacts] = useState<SelectedContact | undefined>()
  // media popup state
  const [mediaUrl, setMediaUrl] = useState<string>('')
  const [mediaDisplayName, setMediaDisplayName] = useState<string>('')
  const [openMediaPopup, setOpenMediaPopup] = useState<boolean>(false)

  // rematch
  const me: any = useSelector<any>((state) => state.auth.me)
  const accountSettings: any = useSelector<any>((state) => state.accountsettings.accountsettings)
  const products: any = useSelector<any>((state) => state.auth.products)
  const formattedLocations: any = useSelector<any>((state) => state.auth.formatedLocations)
  // const formattedLocations = [{name: 'first location', customer: 'first customer'}, {name: '2nd location name', customer: '2nd customer'}]
  const msgApiError: any = useSelector<any>((state) => state.messenger.msgApiError)
  const predefinedTemplates: any = useSelector<any>((state) => state.common.predefinedTemplates)
  const enterpriseData: any = useSelector<any>((state) => state.enterpriseReviews.locationReviewSettings)
  const dispatch = useDispatch()

  // get anything we're missing in the store
  useEffect(() => {
    const getState = async () => {
      if (!me) await dispatch.auth.getMe()

      if (!accountSettings) dispatch.accountsettings.fetchAccountSettings()
      if (enterprise && !formattedLocations) dispatch.auth.getEnterpriseLocations()
      if (!predefinedTemplates) dispatch.common.getPredefinedTemplate({ id: me._id })
    }
    getState()
  }, [])

  const twilioEnabled = Boolean(
    (me && me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length) ||
      (enterprise && enterpriseData && enterpriseData.twilio && enterpriseData.twilio.length)
  )

  const handleReset = () => {
    setContactDetails(undefined)
    setMediaUrl('')
    setMediaDisplayName('')
    setOpenMediaPopup(false)
    setSelectedContacts(undefined)
    setSubmitting(false)
    onClose()
  }

  const handleSubmit = async (values: { contact: string; message: string; customer: string }): Promise<void> => {
    setSubmitting(true)
    if (contactDetails) {
      const encryptedMessage = await encryptMessageUsingRSAPub(values.message)
      // create the base payload
      const payload: MessagePayload = {
        id: contactDetails.contact_id || null,
        contact_type: contactDetails.mobile ? 'sms' : 'email',
        recipient: values.contact,
        first_name: contactDetails.first_name || null,
        message: encryptedMessage
      }
      if (mediaUrl) payload.media = mediaUrl
      if (enterprise) payload.query = { customers: values.customer }

      const isMobile = contactDetails.mobile ? true : false

      // if there is no id, create a new private contact
      if (!payload.id) {
        const contactPayload: PrivateContactPayload = {
          email: !isMobile ? payload.recipient : '',
          mobile: isMobile ? payload.recipient : '',
          country: 'US'
        }
        if (enterprise) {
          contactPayload.query = { customers: values.customer }
        }

        const privateContact: PrivateContact = await dispatch.payment.savePrivateContact(contactPayload)
        if (privateContact && privateContact.data) {
          payload.id = privateContact.data._id
          if (!isMobile) {
            if (me && me.bussiness_name)
              payload.subject = t('messenger.you-have-an-email-from-0', {
                0: me.bussiness_name
              })
            else payload.subject = t('messenger.you-have-an-email')
          }
        }
      }

      const res = await dispatch.contact.sendMessageToContactMessenger(payload)
      const messengerContacts = res
      if (!msgApiError && messengerContacts && messengerContacts.messenger && messengerContacts.messenger.lead) {
        onSendMsgSuccess(messengerContacts.messenger.lead, payload.message, payload.contact_type)
      }
      trackSendMessage({
        user_id: me && me._id,
        contact_id: payload.id,
        contact_type: payload.contact_type,
        recipient: payload.recipient
      })
    }
    setSubmitting(false)
    handleReset()
  }

  const onMediaSuccess = (media: MediaData[]) => {
    setOpenMediaPopup(false)
    if (media.length && media[0] && media[0].type) {
      if (media[0].type === 'doc') {
        const fileExtension = media[0].fileUrl.split('.').pop()

        if (fileExtension && fileExtension.toLowerCase() !== 'pdf') {
          notification('error', t('messenger.sms-supports-only-pdf-documents'))
          return
        }
      } else if (media[0].type === 'video' && media[0].file_size && media[0].file_size > 5000) {
        notification('error', t('messenger.sms-video-limit-is-5mb'))
        return
      }
    }
    setMediaUrl(media[0].fileUrl)
    setMediaDisplayName(media[0].displayName)
  }

  const searchContacts = async (
    inputValue: string
  ): Promise<readonly (MessageSelectOption | GroupTypeBase<MessageSelectOption>)[]> => {
    if (products && isEnabled('contact-manager', products)) {
      const searchRes: SearchResult[] = await dispatch.contact.autosuggestContacts({ search: inputValue })
      if (searchRes) {
        const contactList = []
        let i = 0
        for (const result of searchRes) {
          // format the result
          if (result.type === 'contact') {
            const contact: MessageSelectOption = {
              label: '',
              subLabel: '',
              value: '',
              image: '',
              index: i,
              contactObj: result
            }
            // label
            if (result.first_name) {
              if (result.last_name) contact.label = `${result.first_name} ${result.last_name}`
              else contact.label = `${result.first_name}`
            }
            // subLabel
            if (result.mobile) {
              const formattedMobile = phoneNumberFormat(result.mobile)
              if (formattedMobile) {
                contact.value = formattedMobile
                contact.subLabel = formattedMobile
              }
            } else if (result.email) {
              contact.value = result.email
              contact.subLabel = result.email
            }
            // image
            if (result.image) contact.image = result.image

            contactList.push(contact)
            i += 1
          }
        }
        if (contactList.length > 0) return contactList
      }
    }
    return []
  }

  const handleSelectChange = (
    value: MessageSelectOption | OptionsType<MessageSelectOption> | null,
    action: ActionMeta<MessageSelectOption>
  ): string => {
    // if the user wants to clear the creatable select
    if (action.action === 'clear') {
      setContactDetails(undefined)
      setSelectedContacts(undefined)
      return ''
    } else if (value && !('length' in value) && value.value) {
      // if the user is creating a new option
      if (
        action.action === 'create-option' ||
        (action.action === 'select-option' && !value.contactObj && value.value)
      ) {
        if (action.action === 'select-option' && !value.contactObj && value.value) {
          value.label = value.value
        }
        const validMobile = validateMobile(value.value)
        const validEmail = validateEmail(value.value)
        if (!validMobile && !validEmail) {
          notification('error', t('bookings.please-enter-a-valid-email-or-mobile'))
          setContactDetails(undefined)
          setSelectedContacts(undefined)
          return ''
        }
        if (validMobile) {
          setContactDetails({ mobile: value.value })
          setSelectedContacts(value)
          return value.value
        }
        if (validEmail) {
          setContactDetails({ email: value.value })
          setSelectedContacts(value)
          return value.value
        }
      }
      // if the user clicked on an option
      if (action.action === 'select-option' && value.contactObj) {
        setContactDetails(value.contactObj)
        setSelectedContacts(value)
        return value.value
      }
    }
    return ''
  }

  return (
    <Popup
      open={open}
      title={t('common.send-message')}
      type="small"
      overrideForm={true}
      id={'modalSendMessage'}
      modalBackdropClicked={() => handleReset()}>
      {!twilioEnabled ? (
        <div className="form-group mt-10">
          <ClosePopupButton name="btnCloseBookingSendMsgModal" onClick={() => handleReset()} />
          <p className="mb-0 mt-20">
            {' '}
            {t(
              'payments.to-enable-sms-capabilities-you-must-first-verify-your-account-get-started-by-clicking-send-message-in-messenger'
            )}{' '}
          </p>
        </div>
      ) : (
        <React.Fragment>
          <div className="message-modal">
            <div className="form-group"></div>
            {open ? (
              <Formik
                initialValues={{
                  contact: '',
                  message: '',
                  customer: ''
                }}
                validationSchema={
                  !enterprise
                    ? Yup.object().shape({
                        contact: Yup.string().required(t('bookings.required')),
                        message: Yup.string().required(t('bookings.required'))
                      })
                    : Yup.object().shape({
                        contact: Yup.string().required(t('bookings.required')),
                        message: Yup.string().required(t('bookings.required')),
                        customer: Yup.string().required(t('bookings.required'))
                      })
                }
                onReset={(values, actions) => {
                  actions.resetForm({
                    contact: '',
                    message: '',
                    customer: ''
                  })
                  handleReset()
                }}
                onSubmit={(values) => {
                  handleSubmit(values)
                }}
                render={({ setFieldValue }) => {
                  return (
                    <Form name="frmBookingDetailsSendMsg" id="frmBookingDetailsSendMsg">
                      <ClosePopupButton name="btnCloseBookingSendMsgModal" onClick={() => handleReset()} />
                      <div>
                        <div className="form-group">
                          <label className="label-text">
                            {t('common.contact-uppercase')} <i className="star">*</i>
                            <ErrorMessage name="contact" component="span" className="form-error" />
                          </label>
                          <MessageCreatableSelect
                            id="modalSendMessage_txtSearchNumber"
                            name="txtPhone"
                            placeholder={t('messenger.search-contact')}
                            isValidNewOption={(inputValue, selectValue, selectOptions) => {
                              if (inputValue) {
                                if (selectOptions.length > 0) return false
                                else return true
                              }
                              return false
                            }}
                            value={selectedContacts ? selectedContacts : []}
                            loadOptions={searchContacts}
                            onChange={(value, action) => {
                              const contact = handleSelectChange(value, action)
                              setFieldValue('contact', contact)
                            }}
                          />
                        </div>
                        {enterprise && (
                          <div className="auto-search-wrapper mb-20">
                            <div className="form-group">
                              <label className="label-text">
                                {t('common.location')} <i className="star">*</i>
                                <ErrorMessage name="customer" component="span" className="form-error" />
                              </label>
                              <LocationList
                                bulkUpdate={bulkUpdate}
                                formattedLocations={formattedLocations}
                                onListItemClick={(value) => setFieldValue('customer', value)}
                              />
                            </div>
                          </div>
                        )}
                        <div className="form-group">
                          <label className="label-text">
                            {' '}
                            {t('common.message-uppercase')} <i className="star">*</i>
                            <ErrorMessage name="message" component="span" className="form-error" />
                          </label>
                          {open && !enterprise && (
                            <div id="container_frmBookingDetailsSentMessageTemplate" className="mb-20">
                              <PredefinedTemplate
                                templates={predefinedTemplates}
                                setFieldValue={setFieldValue}
                                selectTemplateFn={(selectedItem: PredefinedTemplateData) => {
                                  setFieldValue('message', selectedItem.message)
                                }}
                              />
                            </div>
                          )}
                          <Field
                            id="frmSent_txtMessage"
                            component="textarea"
                            className="form-control form-control-textarea"
                            type="text"
                            placeholder={t('settings.enter-message')}
                            name="message"
                            data-test="text-message-field"
                          />
                        </div>
                        {mediaUrl !== '' ? (
                          <div className="d-flex align-items-center mb-30">
                            <i className="btn-icon icon-attch-custom">
                              <Icons name="attachment" />
                            </i>
                            <strong className="mx-10 text-truncate">{mediaDisplayName}</strong>
                            <button
                              name="btnCloseAttachedFile"
                              type="button"
                              className="btn-close-custom px-0 bg-white d-flex justify-content-center align-items-center"
                              onClick={() => setMediaUrl('')}>
                              <i className="btn-icon icon-close-custom d-flex">
                                <Icons name="close" />
                              </i>
                            </button>
                          </div>
                        ) : (
                          <div className="form-group mb-30">
                            <button
                              name="btnAttachSendMsgModal"
                              className="btn btn-sm text-secondary is-loader btn-attchment rounded d-flex align-items-center text-uppercase"
                              onClick={() => setOpenMediaPopup(true)}
                              type="button"
                              data-test="attach-files-btn">
                              <i className="btn-icon mr-10 top-minus-2">
                                <Icons name="attachment"></Icons>
                              </i>
                              {t('messenger.attach-files')}
                            </button>
                          </div>
                        )}
                      </div>
                      <footer className="d-block d-flex">
                        <LoadingSubmitButton
                          type="submit"
                          name="btnSendMessage11"
                          id="btn_send"
                          isSubmitting={submitting}>
                          {t('payments.send-0')}
                        </LoadingSubmitButton>
                        <CancelButton
                          name="btnCancelMessage"
                          type="reset"
                          id="btn_cancel"
                          data-test="modal-cancel-message-btn">
                          {t('settings.cancel-0')}
                        </CancelButton>
                      </footer>
                    </Form>
                  )
                }}
              />
            ) : null}
            {openMediaPopup && (
              <MediaLibraryPopup
                open={openMediaPopup}
                onSubmit={onMediaSuccess}
                onCancel={() => setOpenMediaPopup(false)}
                id="media-library-popup"
                multiple={false}
                type={['image', 'video', 'doc', 'icon']}
                showShapes={true}
                hideImages={true}
              />
            )}
          </div>
        </React.Fragment>
      )}
    </Popup>
  )
}
