import { init, RematchDispatch, RematchRootState } from '@rematch/core'
import { models, RootModel } from './models'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'react-router-redux'
import loadingPlugin, { ExtraModelsFromLoading } from '@rematch/loading'

export const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)

const middlewares = [routeMiddleware]

type FullModel = ExtraModelsFromLoading<RootModel>;

export const store = init<RootModel, FullModel>({
  redux: {
    middlewares: middlewares
  },
  plugins: [loadingPlugin()],
  models
})

export type Store = typeof store;
export type Dispatch = RematchDispatch<RootModel>;
// Models are missing some initial states so `RootState` does not actually have all fields
export type RootState = RematchRootState<RootModel, FullModel>;
