import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function getPartnerServices (payload, customers) {
  return api()
    .get(`${config.routes.enterpriseServices}?${objToUrlParams(payload)}&customers=${customers}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createPartnerService (payload, cid) {
  return api()
    .post(`${config.routes.BookingServices}/create?customer_id=${cid}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function UpdateService (payload) {
  return api()
    .put(`${config.routes.BookingServices}/update/${payload._id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPartnerStaff (data) {
  return api()
    .get(`${config.routes.getcustomers}?${objToUrlParams(data)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendEnterpriseMessageToBooking (payload, cid) {
  var newData = {
    contact_type: payload.contact_type,
    message: payload.message,
    recipient: payload.recipient,
    subject: payload.subject
  }
  if (payload.media) newData.media = payload.media

  return api()
    .put(`${config.routes.bookingSendmessage}/${payload.id}/contact?customers=${cid}`, newData)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBookingServiceCount (payload) {
  return api()
    .get(`${config.routes.getServiceCount}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateBookingServiceLocation (payload) {
  return api()
    .post(`${config.routes.updateServiceLocation}?${objToUrlParams(payload.query)}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
