import { Typography, styled } from '@gositeinc/ui'

export const FormLabel = styled(Typography)(
  () => `
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #082344;
`
)
