import * as React from 'react';
import { OptionsType, ActionMeta, GroupTypeBase } from 'react-select/src/types';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { SearchResult } from './types';
import * as _ from 'lodash';

export type MessageSelectOption = {
  value: string;
  label: string;
  subLabel: string;
  image: string;
  contactObj: SearchResult;
  index: number;
};

interface MessageCreatableSelectProps {
  id?: string;
  name?: string;
  value?: MessageSelectOption | readonly MessageSelectOption[] | null | undefined
  placeholder?: string;
  isValidNewOption: (
    inputValue: string,
    value: MessageSelectOption | OptionsType<MessageSelectOption> | null,
    options: readonly (MessageSelectOption | GroupTypeBase<MessageSelectOption>)[]
  ) => boolean;
  loadOptions: (inputValue: string) => Promise<readonly (MessageSelectOption | GroupTypeBase<MessageSelectOption>)[]>;
  onChange: (
    value: MessageSelectOption | OptionsType<MessageSelectOption> | null,
    action: ActionMeta<MessageSelectOption>
  ) => void;
}
interface MessageCreatableSelectState {}
export class MessageCreatableSelect extends React.Component<MessageCreatableSelectProps, MessageCreatableSelectState> {
  state: MessageCreatableSelectState = {};

  throttleLoadOptions = _.throttle(this.props.loadOptions, 600);

  customOptions = (
    { value, label, subLabel, image, contactObj, index }: MessageSelectOption,
    { context }: { context: 'value' | 'menu' }
  ) => {
    if (context === 'value') {
      return (
        <div key={index} className="d-flex flex-grow-0">
          <div className="d-flex flex-grow-1 justify-content-between align-items-center">
            <div>{label ? label : subLabel}</div>
          </div>
        </div>
      );
    }
    if (context === 'menu') {
      return (
        <React.Fragment>
          <div id="contactOptionValue" className="d-flex flex-grow-0" data-test="select-message-contact-option-value">
            <div className="d-flex align-items-center flex-grow-1 justify-content-between">
              <div className="text-truncate max-w">{label ? label : subLabel}</div>
              <div className="text-truncate max-w">{label && subLabel ? `${subLabel}` : ''}</div>
            </div>
          </div>
        </React.Fragment>
      );
    }
  };

  render() {
    return (
      <AsyncCreatableSelect
        id={this.props.id}
        name={this.props.name}
        isClearable
        placeholder={this.props.placeholder}
        className="dropdown-select"
        classNamePrefix="dropdownSelect"
        value={this.props.value}
        isValidNewOption={this.props.isValidNewOption}
        formatOptionLabel={this.customOptions}
        loadOptions={this.throttleLoadOptions}
        noOptionsMessage={() => null}
        onChange={this.props.onChange}
      />
    );
  }
}
