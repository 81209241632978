import * as React from 'react'
import { useState } from 'react'
import OnClickOutside from '@components/outsideClickComponent'

export const SearchDropdown = ({
  name,
  placeholder,
  onSearchInputChange,
  onKeyDown,
  searchValue,
  optionList = [],
  setSearchValue = (value) => {},
  renderOption = () => <></>,
  notFoundOptionText = undefined,
  onNotFoundOptionClick = (searchValue) => {},
  notListedText = undefined,
  onNotListedClick = (searchValue) => {}
}) => {
  const [showDropdown, setShowDropdown] = useState(false)

  return (
    <OnClickOutside handleClickOutside={() => setShowDropdown(false)}>
      <div className="position-relative">
        <div className="dropdown position-relative">
          <input
            className="form-control form-control-lg w-100"
            name={name}
            type="text"
            value={searchValue || ''}
            placeholder={placeholder}
            onChange={(e) => {
              setSearchValue(e.target.value)
              onSearchInputChange(e.target.value)

              if (searchValue === '') {
                return
              }

              setShowDropdown(true)
            }}
            onKeyDown={onKeyDown}
            autoComplete="off"
          />
          {showDropdown && (
            <ul className="dropdown-menu show w-100">
              {optionList.map((option, idx) => (
                <React.Fragment key={idx}>
                  {renderOption(option, (value) => {
                    setSearchValue(value)
                    setShowDropdown(false)
                  })}
                </React.Fragment>
              ))}
              {searchValue !== '' && optionList.length === 0 && (
                <li
                  className="px-15 py-5 text-primary"
                  onClick={() => {
                    onNotFoundOptionClick(searchValue)
                    setShowDropdown(false)
                  }}>
                  <strong>{notFoundOptionText}</strong>
                </li>
              )}
              {searchValue !== '' && optionList.length !== 0 && (
                <li
                  className="px-15 py-5 text-primary"
                  onClick={() => {
                    onNotListedClick(searchValue)
                    setShowDropdown(false)
                  }}>
                  <strong>{notListedText}</strong>
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
    </OnClickOutside>
  )
}
