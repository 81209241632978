import { api, catchHandler } from "@helpers/axios";
import config from "@config/index"
import {objToUrlParams} from "@helpers/index"


export function getServices(payload) {
    return api()
        .get(`${config.routes.BookingServices}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function reviewsEmailAnalytics(payload) {
    return api()
        .get(`${config.routes.emailAnalytics}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function analyticsForGraph(payload) {
    return api()
        .get(`${config.routes.emailAnalyticsGraph}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function emailCountList(payload) {
    return api()
        .get(`${config.routes.emailCount}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function emailList(payload) {
    return api()
        .get(`${config.routes.emailList}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function removeEmailCampaign(payload) {
    return api()
        .put(`${config.routes.removeEmailCampaign}`, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getEmailCampaign(payload) {
    return api()
        .get(`${config.routes.emailCampaign}/${payload.params}?${objToUrlParams(payload.query)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function emailCampaignActivity(payload) {
    return api()
        .get(`${config.routes.emailCampaign}/${payload.params}/activity?${objToUrlParams(payload.query)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function cloneCampaign(payload) {
    return api()
        .post(`${config.routes.campaignsClone}`, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function generateApiToken (payload) {
    return api()
      .post(`${config.routes.generateApiToken}`, payload)
      .then(res => res.data)
      .catch(catchHandler)
}