import { JsonObject, JsonProperty, JsonConvert } from 'json2typescript'
import { UsCurrencyFormatter } from '@helpers/utility'

@JsonObject('Invoice')
export class Invoice {
  @JsonProperty('id', String)
  id: string = ''

  @JsonProperty('customer_id', String)
  customerId: string = ''

  @JsonProperty('subscription_id', String)
  subscriptionId: string = ''

  @JsonProperty('status', String)
  status: 'paid' | 'posted' | 'payment_due' | 'not_paid' | 'voided' | 'pending' = 'pending'

  @JsonProperty('total', Number, true)
  total?: number = undefined

  @JsonProperty('amount_paid', Number, true)
  amountPaid?: number = undefined

  @JsonProperty('generated_at', Number, true)
  generatedAt?: number = undefined

  @JsonProperty('recurring', Boolean)
  recurring: boolean = true

  @JsonProperty('sub_total', Number)
  subTotal: number = 0

  constructor(obj?: Partial<Invoice>) {
    if (obj) {
      Object.assign(this, obj)
    }
  }

  static fromJson(jsonObject: any) {
    return new JsonConvert().deserializeObject(jsonObject, Invoice)
  }

  get price() {
    return [this.total, this.amountPaid, this.subTotal].find((p: number | undefined) => p !== undefined)
  }
}

export class InvoiceList {
  static fromJson(jsonObject: { list: { invoice: Partial<Invoice> }[] }): Invoice[] {
    if (!jsonObject) {
      throw new Error(`InvoiceList.fromJson expects an JSON object, but got ${jsonObject}`)
    }

    if (!jsonObject.list) {
      throw new Error(`InvoiceList.fromJson expects an JSON object with list, but got ${jsonObject}`)
    }

    return jsonObject.list.map(({ invoice }) => Invoice.fromJson(invoice))
  }
}
