/* eslint-disable */

import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { autosuggestContacts } from '../contacts-hub/service'
import * as contactservice from '../contacts-hub/service'
import { paymentPaginationName } from './constants'
import _ from 'lodash'

function getPagination() {
  let pagination = sessionStorage.getItem(paymentPaginationName)
  if (pagination && pagination.length) {
    return JSON.parse(pagination)
  } else {
    return undefined
  }
}

function cleanObject(obj) {
  for (var propName in obj) {
    if (obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}

export default {
  state: {
    apiError: null,
    paymentData: null,
    paymentPagination: getPagination(),
    paymentRedirectionLoader: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        paymentLoading: true,
        apiError: ''
        // paymentData:null
      }
    },
    onNoLoadingRequest(state) {
      return {
        ...state
      }
    },
    onPaymnetRequest(state) {
      return {
        ...state,
        syncedConatcs: null
      }
    },
    onTrnsactionRequest(state) {
      return {
        ...state,
        paymentLoading: true,
        paymentData: null,
        apiError: ''
      }
    },
    onError(state, data) {
      let err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : _.has(data, 'data.message.message')
          ? data.data.message.message
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        paymentLoading: false,
        apiError: err
      }
    },
    onTransactionError(state, data) {
      let err = {}
      if (data && data.status == 400) {
        data.message = data.errors[0].message
      } else if (data && data.status == 402 && data != null && data.decline_code != null && data.message != null) {
        switch (data.decline_code) {
          case 'stolen_card' || 'lost_card':
            data.message = 'Your card has been declined.'
            break
          case 'fraudulent':
            data.message = 'Your card was declined. Please contact your issuer.'
            break
          case 'generic_decline' ||
            'authentication_required' ||
            'approve_with_id' ||
            'call_issuer' ||
            'card_not_supported' ||
            'card_velocity_exceeded' ||
            'currency_not_supported' ||
            'do_not_honor' ||
            'do_not_try_again' ||
            'duplicate_transaction' ||
            'incorrect_pin' ||
            'invalid_amount' ||
            'invalid_expiry_month' ||
            'invalid_expiry_year' ||
            'invalid_number' ||
            'invalid_pin' ||
            'issuer_not_available' ||
            'merchant_blacklist' ||
            'new_account_information_available' ||
            'no_action_taken' ||
            'not_permitted' ||
            'offline_pin_required' ||
            'online_or_offline_pin_required' ||
            'pickup_card' ||
            'pin_try_exceeded' ||
            'reenter_transaction' ||
            'restricted_card' ||
            'revocation_of_all_authorizations' ||
            'revocation_of_authorization' ||
            'security_violation' ||
            'service_not_allowed' ||
            'stop_payment_order' ||
            'testmode_decline' ||
            'transaction_not_allowed' ||
            'try_again_later' ||
            'withdrawal_count_limit_exceeded':
            data.message = 'Your card was declined. \n Please try a different card.'
            break
          case 'processing_error':
            data.message =
              'An error occurred while processing your payment. Try again later or with a different payment method.'
            break
          case 'invalid_cvc':
            data.message = "Your card's security code is incorrect."
            break
          case 'incorrect_zip':
            data.message = 'Your card number and postal code do not match.'
            break
          case 'incorrect_number':
            data.message = 'Your card number is invalid.'
            break
          case 'expired_card':
            data.message = 'Your card has expired.'
            break
          case 'insufficient_funds':
            data.messagge = 'Your card has insufficient funds.'
            break
          default:
            data.message
            break
        }
      }
      err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : _.has(data, 'data.message.message')
          ? data.data.message.message
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        paymentLoading: false,
        apiError: err
      }
    },
    onCustomError(state, data) {
      return {
        ...state
      }
    },
    onPayNowError(state, data) {
      return {
        ...state,
        paynowError: data.data && data.data.error ? data.data.error : ''
      }
    },

    onfetchPaymentsSuccess(state, data) {
      return {
        ...state,
        paymentList: data.data,
        paymentLoading: false
      }
    },
    onfetchMountAnalyticsSuccess(state, data) {
      return {
        ...state,
        amountAnalytics: data.data,
        paymentLoading: false
      }
    },
    onGetWidgetsSuccess(state, data) {
      let widgets = {}
      if (data && data.data) {
        widgets = data.data
      }
      return {
        ...state,
        widgets: widgets,
        paymentLoading: false
      }
    },
    onRequestSuccess(state, data) {
      let { show, message, res, updatePaymentListData } = data
      if (show) notification('success', message ? message : '')
      let paymentList = state.paymentList
      if (updatePaymentListData && res && res.data && res.data._id) {
        let { docs = [] } = paymentList
        let index = docs.findIndex((item) => item._id === res.data._id)
        if (index > -1) {
          docs[index] = res.data
        }
        paymentList.docs = docs
      }
      return {
        ...state,
        paymentLoading: false,
        paymentList
      }
    },
    onCreateWidgetSuccess(state, data) {
      if (data && data.message) {
        notification('success', data.message)
      }
      return {
        ...state,
        paymentLoading: false,
        newWidget: data && data.res && data.res.data
      }
    },
    onfetchPaymentDetailSuccess(state, data) {
      return {
        ...state,
        paymentLoading: false,
        paymentDetail: data.data,
        balanceDetail: data.balance_payment
      }
    },
    onfetchRecurringSeriesDetailSuccess(state, data) {
      return {
        ...state,
        recurringSeriesDetail: data.recurringSeries
      }
    },
    onfetchRecurringPaymentsContinueSuccess(state, data) {
      return {
        ...state,
        totalRecurringPaymentsCount: data.data.total
      }
    },
    onfetchRecurringPaymentsInitSuccess(state, data) {
      return {
        ...state,
        totalRecurringPaymentsCount: data.data.total
      }
    },
    onClearRecurringDataSuccess(state, data) {
      return {
        ...state,
        recurringSeriesDetail: null,
        totalRecurringPaymentsCount: 0
      }
    },
    onGetTrashWidgetsSuccess(state, data) {
      let widgets = {}
      if (data && data.data) {
        widgets = data.data
      }
      return {
        ...state,
        trashWidgets: widgets,
        paymentLoading: false
      }
    },
    onGetCompanySettings(state, data) {
      return {
        ...state,
        GetCompanySettings: data,
        paymentLoading: false
      }
    },
    ofetchInquireSuccess(state, data) {
      return {
        ...state,
        inquiredData: data.data,
        paymentLoading: false
      }
    },
    onfetchRecieptInvoice(state, data) {
      return {
        ...state,
        recieptData: data.data,
        paymentLoading: false
      }
    },
    onfetchOnboardingSuccess(state, data) {
      return {
        ...state,
        onBoardingData: data,
        paymentLoading: false
      }
    },
    onfetchCustomPAymentFormDataSuccess(state, data) {
      return {
        ...state,
        customFormData: data,
        paymentLoading: false
      }
    },
    onpayNowSuccess(state, data) {
      return {
        ...state,
        payNowData: data
      }
    },
    onsendPaymentFeedbackSuccess(state, data) {
      return {
        ...state
      }
    },
    onAutosuggestContactsSuccess(state, data) {
      let contacts = data.map(function (item) {
        return item._source
      })
      return {
        ...state,
        paymentLoading: false,
        searchRes: contacts
      }
    },
    onsendGetPaidLinksSuccess(state, data, payload) {
      notification('success', 'Transaction request has been sent successfully')
      let paymentList = state.paymentList
      let postData = payload.payload
      // data.customer_name = postData.customer_name ? postData.customer_name :""
      data.customer_phone = postData.customer_number ? postData.customer_number : ''
      data.customer_email = postData.customer_email ? postData.customer_email : ''
      paymentList.docs.unshift(data)
      return {
        ...state,
        paymentLoading: false,
        paymentList: paymentList
      }
    },
    onManualTransactionSuccess(state, data) {
      let { show, message, res } = data
      let paymentData = {}
      if (show) notification('success', message ? message : '')
      let paymentList = state.paymentList
      if (res.payment_data) {
        paymentList.docs.unshift(res.payment_data)
        paymentData = res.payment_data
      } else if (res.data && res.data.invoice_number) {
        let { docs = [] } = paymentList
        let index = docs.findIndex((item) => item.invoice_number === res.data.invoice_number)
        if (index > -1) {
          docs[index] = res.data
        }
        paymentList.docs = docs
        paymentData = res.data
      } else {
        paymentList.docs.unshift(res.data)
        paymentData = res.data
      }
      return {
        ...state,
        paymentLoading: false,
        paymentList: paymentList,
        paymentData
      }
    },
    onPaymentRequestupdateSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      } else if (data && data.message) {
        notification('success', data.message)
      }
      return {
        ...state,
        paymentLoading: false
      }
    },
    onCancelRecurringInvoiceSuccess(state, data) {
      const msg = _.get(data, 'message')
      if (msg) {
        notification('success', msg)
      }

      return {
        ...state,
        cancelledInvoice: data.cancelledInvoice,
        nextInvoice: data.nextScheduledInvoice,
        recurringSeriesDetail: data.updatedRecurringSeries
      }
    },
    onSavePrivateContactSuccess(state, data) {
      return {
        ...state,
        paymentLoading: false,
        privateContact: data
      }
    },
    onSaveInvoiceDataSuccess(state, data, msg) {
      notification('success', msg)
      let paymentList = state.paymentList
      if (data && data.data) {
        let d = data.data
        d.customer_name = `${d.customer_first_name ? d.customer_first_name : ''} ${
          d.customer_last_name ? d.customer_last_name : ''
        }`
        d.status = d.invoice_status
        d.amount = d.invoice_amount
        d.source = 'invoice'
        d.invoiceFlag = true

        // only update paymentList when on payment page or places that loads paymentList
        if (paymentList) {
          let payments = paymentList.docs && paymentList.docs.length ? paymentList.docs.slice(0, 11) : []
          paymentList.docs = [d, ...payments]
          paymentList.total += 1
        }
      }
      return {
        ...state,
        paymentLoading: false,
        privateContact: data,
        paymentList
      }
    },
    onCancelRecurringSeriesSuccess(state, data, msg) {
      notification('success', msg)
      return {
        ...state,
        recurringSeriesDetail: data.canceledRecurringSeries
      }
    },
    onUpdateRecurringSeriesSuccess(state, msg) {
      notification('success', msg)
      return {
        ...state
      }
    },
    onUpdateNextInvoiceInRecurringSeries(state, msg) {
      notification('success', msg)
      return {
        ...state
      }
    },
    onGetInvoiceDataSuccess(state, data) {
      return {
        ...state,
        invoice: data.data
      }
    },
    onMarkasPaidUpdateSuccess(state, data, payload) {
      let message = ''
      if (payload && payload.source === 'invoice') message = 'Invoice updated successfully'
      else message = 'Payment request updated successfully'
      notification('success', message)
      return {
        ...state
      }
    },
    onDownloadInvoiceSuccess(state, data) {
      return {
        ...state,
        invoiceDownloadData: data
      }
    },
    onConnectConatctPaymentseSuccess(state, data) {
      return {
        ...state,
        conncectedPayments: data
      }
    },
    onaddPaymentSyncConatctSuccess(state, data) {
      let paymentList = state.paymentList
      if (state.paymentList && state.paymentList.docs && state.paymentList.docs.length)
        paymentList.docs[0].customer_name = `${data.first_name} ${data.last_name}`

      return {
        ...state,
        paymentList,
        syncedConatcs: data
      }
    },
    updatePaymentList(state, data) {
      return {
        ...state,
        paymentList: data
      }
    },
    onMccCodeSuccess(state, data) {
      return {
        ...state,
        mccCodes: data
      }
    },
    onfetchPaymentsForTrackingSuccess(state, data) {
      return {
        ...state,
        previousTransCount: data && data.data.total
      }
    },
    onSetPaymentPagination(state, data) {
      sessionStorage.setItem(paymentPaginationName, JSON.stringify({ ...state.paymentPagination, ...data }))
      return {
        ...state,
        paymentPagination: {
          ...state.paymentPagination,
          ...data
        }
      }
    },
    onClearPaymentPagination(state, data) {
      sessionStorage.removeItem(paymentPaginationName)
      return {
        ...state,
        paymentPagination: null
      }
    },
    onClearInvoiceDataSuccess(state, data) {
      return {
        ...state,
        invoice: null
      }
    },
    onUnlockInvoicesSuccess(state, data) {
      return {
        ...state
      }
    },
    onCollectPaymentSuccess(state, data) {
      let { show, message, res } = data
      if (show) notification('success', message ? message : '')
      return {
        ...state
      }
    },
    onfetchConfigFlag(state, achPaymentFeatureFlag) {
      return {
        ...state,
        achPaymentFeatureFlag: achPaymentFeatureFlag
      }
    },
    onfetchSites(state, isSiteEnabled) {
      return {
        ...state,
        isSiteEnabled: isSiteEnabled
      }
    },
    changePaymentRedirectionLoaderStatus(state, data) {
      return {
        ...state,
        paymentRedirectionLoader: data
      }
    }
  },
  effects: (dispatch) => ({
    setPaymentRedirectionLoaderStatus(flag) {
      this.changePaymentRedirectionLoaderStatus(flag)
    },
    async fetchPayments(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchPayments(payload)
        this.onfetchPaymentsSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchMountAnalytics(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchMountAnalytics(payload)
        this.onfetchMountAnalyticsSuccess(res)
        return res
      } catch (e) {
        // await this.onError(e)
      }
    },
    async getWidgets(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getWidgets(payload)
        this.onGetWidgetsSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async amountRefund(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.amountRefund(payload)
        this.onRequestSuccess({
          res: res,
          message: 'Amount refunded successfully',
          show: true,
          updatePaymentListData: true
        })
        const { data = {} } = res
        const { user = '', status = '', amount = 0, tip = 0 } = data
        await service.trackTransactionStatusChange({ userId: user, status, amount, tip })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },

    async getTrashWidgets(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getWidgets(payload)
        this.onGetTrashWidgetsSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchPaymentDetail(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchPaymentDetail(payload)
        await this.onfetchPaymentDetailSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchRecurringSeriesDetail(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchRecurringSeriesDetail(payload)
        await this.onfetchRecurringSeriesDetailSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchRecurringPaymentsContinue(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchRecurringPayments(payload)
        await this.onfetchRecurringPaymentsContinueSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchRecurringPaymentsInit(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchRecurringPayments(payload)
        await this.onfetchRecurringPaymentsInitSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async clearRecurringData(payload, rootState) {
      this.onClearRecurringDataSuccess()
    },
    async sendMessagePayment(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.sendMessagePayment(payload)
        await this.onRequestSuccess({ res: res, show: true, message: '' })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async deleteWidget(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.deleteWidget(payload)
        this.onRequestSuccess({ res: res, message: 'Widget has been moved to trash successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async restoreWidget(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.restoreWidget(payload)
        this.onRequestSuccess({ res: res, message: 'Widget has been restored successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async deleteWidgetPermanently(payload) {
      this.onRequest()
      try {
        let res = await service.deleteWidgetPermanently(payload)
        this.onRequestSuccess({ res: res, message: 'Widget has been removed successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async createWidget(payload) {
      this.onRequest()
      try {
        let res = await service.createWidget(payload)
        this.onCreateWidgetSuccess({ res: res, message: 'Widget created successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async editWidget(payload) {
      this.onRequest()
      try {
        let res = await service.editWidget(payload)
        this.onRequestSuccess({ res: res, message: 'Widget updated successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async createTransaction(payload) {
      this.onTrnsactionRequest()
      try {
        let res = await service.createTransaction(payload)
        this.onManualTransactionSuccess({ res: res, message: 'Transaction completed Successfully', show: false })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async createStripeTransaction(payload, rootState) {
      this.onTrnsactionRequest()
      try {
        const { stripeObj: stripe, stripePaymentMethod: paymentMethod } = payload
        delete payload.stripeObj
        delete payload.stripePaymentMethod
        delete payload.card_token
        let res = {}
        payload.inputSource = 'smbDashboard'
        payload.cardHolder = payload.card_holder
        payload.firstName = payload.first_name
        payload.lastName = payload.last_name
        payload.invoiceId = payload.invoice_number
        delete payload.first_name
        delete payload.last_name
        delete payload.card_holder
        let clientSecret = await service.getPaymentIntent(payload)
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret.items.clientSecret, {
          payment_method: paymentMethod.id
        })
        if (error) {
          let postPayload = {
            paymentIntentId: error.payment_intent.id,
            paymentMethodId: error.payment_method.id,
            merchantId: payload.merchantId,
            clientSecret: clientSecret.items.clientSecret,
            publishableKey: clientSecret.items.publishableKey,
            inputSource: 'smbDashboard'
          }
          try {
            await service.proceedPostStripePayment(postPayload)
          } catch {}
          throw error
        }
        let postPayload = {
          paymentIntentId: paymentIntent.id,
          paymentMethodId: paymentIntent.payment_method,
          merchantId: payload.merchantId,
          clientSecret: clientSecret.items.clientSecret,
          publishableKey: clientSecret.items.publishableKey,
          inputSource: 'smbDashboard'
        }
        res = await service.proceedPostStripePayment(postPayload)
        this.onManualTransactionSuccess({ res: res.items, message: 'Transaction completed Successfully', show: false })
        return res
      } catch (e) {
        // this.onError(e)
        this.onTransactionError(e)
      }
    },
    async createBooking(payload) {
      this.onRequest()
      try {
        let res = await service.createBooking(payload)
        this.onManualTransactionSuccess({ res: res, message: 'Transaction completed Successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async sendRequest(payload, rootState) {
      this.onNoLoadingRequest()
      try {
        let res = await service.sendRequest(payload)
        let show = true
        if (payload.hideNotication) show = false
        this.onRequestSuccess({ res: res, message: 'Receipt sent', show })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async sendMessageToContact(payload, rootState) {
      this.onRequest()
      try {
        let res = await contactservice.sendMessageToContactMessenger(payload)
        this.onRequestSuccess({ res: res, message: 'Message sent successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },

    async paymentCardConnect(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.paymentCardConnect(payload)
        this.onRequestSuccess({ res: res, message: 'Card connected successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async revokeMerchantId(payload) {
      try {
        let res = await service.revokeMerchantId(payload)
        this.onRequestSuccess({ res: res })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async getCompanySettings() {
      this.onRequest()
      try {
        let res = await service.getCompanySettings()
        this.onGetCompanySettings(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async handleCancelTransaction(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.handleCancelTransaction(payload)
        this.onRequestSuccess({
          res: res,
          message: 'Transaction voided successfully',
          show: true,
          updatePaymentListData: true
        })
        const { data = {} } = res
        const { user = '', status = '', amount = 0, tip = 0 } = data
        await service.trackTransactionStatusChange({ userId: user, status, amount, tip })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchInquire(payload, rootState) {
      this.onNoLoadingRequest()
      try {
        let res = await service.fetchInquire(payload)
        this.ofetchInquireSuccess(res)
        const { data = {} } = res
        const { user = '', status = '', amount = 0, tip = 0 } = data
        await service.trackTransactionStatusChange({ userId: user, status, amount, tip })
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async fetchRecieptInvoice(payload, rootState) {
      this.onNoLoadingRequest()
      try {
        let res = await service.fetchRecieptInvoice(payload)
        this.onfetchRecieptInvoice(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async getConfigFlag(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getConfigFlag(payload)
        this.onfetchConfigFlag(res.achPaymentFeatureFlag)
        return res.achPaymentFeatureFlag
      } catch (e) {
        await this.onError(e)
      }
    },
    async getSiteParent(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.getSiteParent(payload)
        this.onfetchSites(res.isSiteEnabled)
        return res.isSiteEnabled
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchOnboarding(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchOnboarding(payload)
        this.onfetchOnboardingSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async unlockInvoices(payload) {
      this.onRequest()
      try {
        payload.data = {
          payment: {
            isInvoiceUnlocked: true
          }
        }
        let res = await service.unlockInvoicesForUser(payload)
        this.onUnlockInvoicesSuccess(res)
        dispatch.auth.markInvoiceUnlocked()
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchCustomPAymentFormData(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.fetchCustomPAymentFormData(payload)
        this.onfetchCustomPAymentFormDataSuccess(res)
        return res
      } catch (e) {
        this.onCustomError(e)
      }
    },
    async payNow(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.payNow(payload)
        this.onpayNowSuccess(res)
        return res
      } catch (e) {
        this.onPayNowError(e)
      }
    },
    async createStripeWidgetTransaction(payload, rootState) {
      try {
        const { stripeObj: stripe, stripePaymentMethod: paymentMethod } = payload
        delete payload.stripeObj
        delete payload.stripePaymentMethod
        delete payload.card_token
        let res = {}
        payload.inputSource = 'Widget'
        payload.cardHolder = payload.card_holder
        var name = payload.card_holder.split(' ')
        payload.firstName = name[0]
        payload.lastName = name[1]
        payload.invoiceId = payload.invoice_number
        delete payload.first_name
        delete payload.last_name
        delete payload.card_holder
        let clientSecret = await service.paymentIntentWidget(payload)
        const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret.items.clientSecret, {
          payment_method: paymentMethod.id
        })
        if (error) {
          let postPayload = {
            paymentIntentId: error.payment_intent.id,
            paymentMethodId: error.payment_method.id,
            merchantId: payload.merchantId,
            clientSecret: clientSecret.items.clientSecret,
            publishableKey: clientSecret.items.publishableKey,
            inputSource: 'Widget',
            additionalData: payload.additionalData,
            form_uuid: payload.form_uuid,
            card_token: payload.card_token,
            expiry: payload.expiry,
            cvv: payload.cvv,
            postal: payload.postal,
            city: payload.city,
            tip: payload.tip,
            address: payload.address,
            name: payload.name
          }
          try {
            res = await service.proceedPostStripeWidgetPayment(postPayload)
            payload.payment_response = res.items.payment_response
            res = await service.paymentPostStripeWidget(payload)
            this.onPayNowError(res)
            return res
          } catch (e) {
            console.log('error = ' + e.message)
            this.onPayNowError(e)
            return e
          }
        }
        let postPayload = {
          paymentIntentId: paymentIntent.id,
          paymentMethodId: paymentIntent.payment_method,
          merchantId: payload.merchantId,
          clientSecret: clientSecret.items.clientSecret,
          publishableKey: clientSecret.items.publishableKey,
          inputSource: 'Widget',
          additionalData: payload.additionalData,
          form_uuid: payload.form_uuid,
          card_token: payload.card_token,
          expiry: payload.expiry,
          cvv: payload.cvv,
          postal: payload.postal,
          city: payload.city,
          tip: payload.tip,
          address: payload.address,
          name: payload.name
        }
        res = await service.proceedPostStripeWidgetPayment(postPayload)
        payload.payment_response = res.items.payment_response

        res = await service.paymentPostStripeWidget(payload)
        this.onpayNowSuccess(res)
        return res
      } catch (e) {
        console.log('error = ' + e)
        this.onPayNowError(e)
      }
    },
    async sendPaymentFeedback(payload, rootState) {
      this.onRequest()
      try {
        let res = await service.sendPaymentFeedback(payload)
        this.onsendPaymentFeedbackSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async autosuggestContacts(payload) {
      try {
        let res = await autosuggestContacts(payload)
        this.onAutosuggestContactsSuccess(res.data)
      } catch (e) {
        console.log(e)
      }
    },
    async sendGetPaidLink(payload) {
      this.onRequest()
      try {
        const res = await service.sendGetPaidLink(payload.payload)
        this.onsendGetPaidLinksSuccess(res.data, payload)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async cancelPaymentRequest(payload) {
      this.onRequest()
      try {
        const { id, data, trackingData } = payload
        let res = await service.cancelPaymentRequest({ id, data })
        let msg = ''
        if (payload.data && payload.data.invoice_type === 'invoice') {
          msg = 'Invoice'
        } else {
          msg = 'Payment request'
        }
        this.onPaymentRequestupdateSuccess(res, `${msg} canceled successfully`)
        await service.trackTransactionStatusChange(trackingData)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async cancelRecurringInvoice(payload) {
      this.onRequest()
      try {
        const { id, recurring_invoice_id, data, trackingData } = payload
        let res = await service.cancelRecurringInvoice({ id, recurring_invoice_id, data })
        this.onCancelRecurringInvoiceSuccess(res)
        await service.trackTransactionStatusChange(trackingData)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async deletePaymentRequest(payload) {
      this.onRequest()
      try {
        let res = await service.deletePaymentRequest(payload)
        let msg = ''
        if (payload.data && payload.data.invoice_type === 'invoice') {
          msg = 'Invoice'
        } else {
          msg = 'Payment request'
        }
        this.onPaymentRequestupdateSuccess(res, `${msg} deleted successfully`)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async resendInvoice(payload) {
      this.onRequest()
      try {
        let msg = ''
        if (payload.params.invoice_type === 'invoice') {
          msg = 'Invoice re-sent successfully'
        } else {
          msg = 'Payment request re-sent successfully'
        }
        let res = await service.resendInvoice(payload)
        this.onPaymentRequestupdateSuccess(res, msg)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async savePrivateContact(payload) {
      this.onRequest()
      try {
        let res = await service.savePrivateContact(payload)
        this.onSavePrivateContactSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async saveInvoiceData(payload) {
      this.onRequest()
      // TODO :
      try {
        let res = await service.saveInvoiceData(payload)
        let msg = ''
        if (payload.invoice_number) {
          msg = 'Invoice updated successfully'
        } else if (payload.send_type === 'schedule' && !payload.invoice_number) {
          msg = 'Invoice scheduled successfully'
        } else {
          msg = 'Invoice sent successfully'
        }
        await this.onSaveInvoiceDataSuccess(res, msg)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async returnInvoiceData(payload) {
      this.onRequest()
      // TODO :
      try {
        let res = await service.saveInvoiceData(payload)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async getInvoiceData(payload) {
      this.onRequest()
      try {
        let res = await service.getInvoiceData(payload)
        this.onGetInvoiceDataSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async saveRecurringInvoiceData(payload) {
      this.onRequest()
      try {
        let res = await service.saveRecurringInvoiceData(payload)
        if (res && res.message) {
          this.onSaveInvoiceDataSuccess(res, res.message)
          return res
        }
      } catch (e) {
        await this.onError(e)
      }
    },
    async cancelRecurringSeries(payload) {
      this.onRequest()
      try {
        let res = await service.cancelRecurringSeries(payload)
        if (res.message) {
          this.onCancelRecurringSeriesSuccess(res, res.message)
        }

        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async updateRecurringSeries(payload) {
      this.onRequest()
      try {
        let res = await service.updateRecurringSeries(payload)
        if (res.message) {
          this.onUpdateRecurringSeriesSuccess(res.message)
        }

        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async updateNextInvoiceInRecurringSeries(payload) {
      this.onRequest()
      try {
        let res = await service.updateNextInvoiceInRecurringSeries(payload)
        if (res.message) {
          this.onUpdateNextInvoiceInRecurringSeries(res, res.message)
        }

        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async markasPaidUpdate(payload) {
      try {
        let res = await service.markasPaidUpdate(payload)
        this.onMarkasPaidUpdateSuccess(res, payload)
        const { data = {} } = res
        const { parent_user = '', invoice_status = '', invoice_amount = 0, tip = 0 } = data
        await service.trackTransactionStatusChange({
          userId: parent_user,
          status: invoice_status,
          amount: invoice_amount,
          tip
        })
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async downloadInvoice(payload) {
      try {
        let res = await service.downloadInvoice(payload)
        this.onDownloadInvoiceSuccess(res.data)
      } catch (e) {
        await this.onError(e)
      }
    },
    async connectConatctPayments(payload) {
      try {
        let res = await service.connectConatctPayments(payload)
        this.onConnectConatctPaymentseSuccess(res.data)
      } catch (e) {
        await this.onError(e)
      }
    },
    async addPaymentSyncConatct(payload) {
      this.onPaymnetRequest()
      try {
        let res = await contactservice.addContact(payload)
        this.onaddPaymentSyncConatctSuccess(res.data)
      } catch (e) {
        await this.onError(e)
      }
    },
    async getMccCode(payload) {
      try {
        let res = await service.getMccCode(payload)
        this.onMccCodeSuccess(res.data)
      } catch (e) {
        await this.onError(e)
      }
    },
    async fetchPreviousTransCount(payload) {
      this.onRequest()
      try {
        let res = await service.fetchPayments(payload)
        this.onfetchPaymentsForTrackingSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async setPaymentPagination(payload) {
      this.onSetPaymentPagination(cleanObject(payload))
    },
    async clearPaymentPagination() {
      this.onClearPaymentPagination()
    },
    async clearInvoiceData() {
      this.onClearInvoiceDataSuccess()
    },
    async collectPayment(payload) {
      this.onRequest()
      try {
        let res = await service.createTransaction(payload)
        this.onCollectPaymentSuccess({ res: res, message: 'Transaction completed Successfully', show: true })
        return res
      } catch (e) {
        await this.onError(e)
      }
    }
  })
}
