export const notificationActivityTypes = [
    "user_import_success",
    "user_import_failed",
    "mid_missing",
    "cardconnect_settings_updated",
    "cardconnect_settings_update_failed",
    "scrape_process_complete",
    "twilio_number_request",
    "hubspot_crm_status",
    "account_gmb_connect",
    "account_gmb_disconnect",
    "account_gmb_expired"
]

export const notifiEnterpriseActTypes = [
    "new_contact_fail",
    "google_sync_failed",
    "google_sync_success",
    "user_import_success",
    "user_import_failed",
    "new_qa",
    "account_gmb_connect",
    "account_gmb_disconnect",
    "account_gmb_expired",
    "admin_push"
]