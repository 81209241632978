import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
// import { sortByKey } from "@helpers/utility"

export default {
  state: {
    entHomeError: false
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        entHomeError: false
      }
    },
    onSuccess (state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        entHomeError: false
      }
    },
    onError (state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
            ? data.data.message
            : data && data.error
              ? data.error
              : data && data.data && data.data.error
                ? data.data.error
                : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        entHomeError: true
      }
    },
    onfetchHomeLAyoutSuccess (state, data) {
      return {
        ...state,
        loading: false,
        homeLayoutData: data
      }
    },
    onUpdateHomeLAyoutSuccess (state, data) {
      return {
        ...state,
        loading: false
        // homeLayoutData: data
      }
    },
    onReviewsRatingsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        homeReviewsRatingData: data
      }
    }
  },

  effects: {
    async fetchHomeLayout (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchHomeLayout(payload)
        this.onfetchHomeLAyoutSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateHomeLayout (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateHomeLayout(payload)
        this.onUpdateHomeLAyoutSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewsRatings (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getReviewsRatings(payload)
        this.onReviewsRatingsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
