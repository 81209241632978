import React from "react";
import asyncComponent from "@helpers/async-func"
import {EnterpriseRoute} from "@app/router/access-route"

export default [
    <EnterpriseRoute
        exact
        path={"/:userId/enterprise/contacts/mycontacts"}
        key="contact-list"
        layoutSettings={{page: "contacts",title:"Contacts"}}
        component={asyncComponent(() => import("./list"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/enterprise/contacts/edit/:id"}
        key="contact-edit"
        layoutSettings={{page: "contacts",title:"Contacts Details"}}
        component={asyncComponent(() => import("./edit"))}
    />,
    <EnterpriseRoute
        exact
        path={"/:userId/enterprise/contacts/groups"}
        key="contact-list"
        layoutSettings={{page: "contacts",title:"Groups"}}
        component={asyncComponent(() => import("./groups"))}
    />,
   
]
