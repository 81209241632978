import moment from 'moment'

const monthsCapitalized =
    'Enero_Febrero_Marzo_Abril_Mayo_Junio_Julio_Agosto_Septiembre_Octubre_Noviembre_Diciembre'.split('_'),
  monthsShortCapitalized = 'Ene_Feb_Mar_Abr_May_Jun_Jul_Ago_Sep_Oct_Nov_Dic'.split('_'),
  monthsShortDotCapitalized = 'Ene._Feb._Mar._Abr._May._Jun._Jul._Ago._Sep._Oct._Nov._Dic.'.split('_'),
  weekdaysCapitalized = 'Domingo_Lunes_Martes_Miércoles_Jueves_Viernes_Sábado'.split('_'),
  weekdaysShortCapitalized = 'Dom._Lun._Mar._Mié._Jue._Vie._Sáb.'.split('_'),
  weekdaysMinCapitalized = 'Do_Lu_Ma_Mi_Ju_Vi_Sá'.split('_')

const monthsParse = [
  /^ene/i,
  /^feb/i,
  /^mar/i,
  /^abr/i,
  /^may/i,
  /^jun/i,
  /^jul/i,
  /^ago/i,
  /^sep/i,
  /^oct/i,
  /^nov/i,
  /^dic/i
]

const monthsRegex =
  /^(Enero|Febrero|Marzo|Abril|Mayo|Junio|Julio|Agosto|Septiembre|Octubre|Noviembre|Diciembre|Ene\.?|Feb\.?|Mar\.?|Abr\.?|May\.?|Jun\.?|Jul\.?|Ago\.?|Sep\.?|Oct\.?|Nov\.?|Dic\.?)/i

export default moment.defineLocale('es-cap', {
  months: monthsCapitalized,
  monthsShort: function (m, format) {
    if (!m) {
      return monthsShortDotCapitalized
    } else if (/-MMM-/.test(format)) {
      return monthsShortCapitalized[m.month()]
    } else {
      return monthsShortDotCapitalized[m.month()]
    }
  },
  monthsRegex: monthsRegex,
  monthsShortRegex: monthsRegex,
  monthsStrictRegex: /^(Enero|Febrero|Marzo|Abril|Mayo|Junio|Julio|Agosto|Septiembre|Octubre|Noviembre|Diciembre)/i,
  monthsShortStrictRegex: /^(Ene\.?|Feb\.?|Mar\.?|Abr\.?|May\.?|Jun\.?|Jul\.?|Ago\.?|Sep\.?|Oct\.?|Nov\.?|Dic\.?)/i,
  monthsParse: monthsParse,
  longMonthsParse: monthsParse,
  shortMonthsParse: monthsParse,
  weekdays: weekdaysCapitalized,
  weekdaysShort: weekdaysShortCapitalized,
  weekdaysMin: weekdaysMinCapitalized,
  weekdaysParseExact: true,
  longDateFormat: {
    LT: 'h:mm A',
    LTS: 'h:mm:ss A',
    L: 'DD/MM/YYYY',
    LL: 'D [de] MMMM [de] YYYY',
    LLL: 'D [de] MMMM [de] YYYY h:mm A',
    LLLL: 'dddd, D [de] MMMM [de] YYYY h:mm A'
  },
  calendar: {
    sameDay: function () {
      return '[Hoy a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    nextDay: function () {
      return '[Mañana a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    nextWeek: function () {
      return 'dddd [a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    lastDay: function () {
      return '[Ayer a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    lastWeek: function () {
      return '[El] dddd [pasado a la' + (this.hours() !== 1 ? 's' : '') + '] LT'
    },
    sameElse: 'L'
  },
  relativeTime: {
    future: 'en %s',
    past: 'hace %s',
    s: 'unos segundos',
    ss: '%d segundos',
    m: 'un minuto',
    mm: '%d minutos',
    h: 'una hora',
    hh: '%d horas',
    d: 'un día',
    dd: '%d días',
    w: 'una semana',
    ww: '%d semanas',
    M: 'un mes',
    MM: '%d meses',
    y: 'un año',
    yy: '%d años'
  },
  dayOfMonthOrdinalParse: /\d{1,2}º/,
  ordinal: '%dº',
  week: {
    dow: 1,
    doy: 4
  },
  invalidDate: 'Fecha inválida',
  meridiemParse: /[ap](\.?m\.?)?/i
})
