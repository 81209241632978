import * as React from 'react';
import style from './index.module.scss';

interface InstantStepTabProps {
  children: React.ReactNode;
  highlightConnector?: boolean;
}
export const InstantStepTab = ({ children, highlightConnector }: InstantStepTabProps) => {
  return (
    <div
      className={`d-flex align-items-center mx-auto position-relative ${style['instant-step-tab']}`}
      data-test="instant-step-tab"
    >
      {React.Children.map(children, (child, childIdx) =>
        React.cloneElement(
          child as React.ReactElement<InstantStepItemProps>,
          {
            first: childIdx === 0,
            highlightConnector: React.isValidElement(child) && child.props.highlight && highlightConnector,
          },
          React.isValidElement(child) ? child.props.children : null
        )
      )}
    </div>
  );
};

interface InstantStepItemProps {
  children: string;
  screen?: string;
  highlight?: boolean;
  highlightConnector?: boolean;
  first?: boolean;
}
export const InstantStepItem = ({ children, highlight, highlightConnector, first }: InstantStepItemProps) => {
  return (
    <span
      className="d-flex flex-row align-items-center"
      data-test={`instant-step-item${highlight ? '-highlight' : ''}`}
    >
      {/* {!first && <div className={`${style['connector-line']} ${highlightConnector ? style['active'] : ''}`} />} */}
      {!first && <div className={`${style['connector-line']} `} />}
      <div className={`py-5 px-10 fz-12 px-15 ${style['instant-step-item']} ${highlight ? style['active'] : ''}`}>
        {children}
      </div>
    </span>
  );
};
