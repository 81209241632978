import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Button } from '@gositeinc/ui'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { StepText } from './StepText'
import { StepTitle } from './StepTitle'
import { FormLabel } from './FormLabel'
import { numberMask } from '@helpers/inputmask'
import { segmentEventTrack } from '@helpers/segment'
import _ from 'lodash'
import Icon from '@components/icons'

export const CreateAccount = ({ step, onBack, onSubmitted }) => {
  const dispatch = useDispatch()

  const me = useSelector((state) => state.auth.me)

  const schema = Yup.object().shape({
    first_name: Yup.string().required('First name is required field'),
    last_name: Yup.string().required('Last name is required field'),
    contact_phone: Yup.string()
      .matches(/^\(?(\d{3})\)?[ .-]?(\d{3})[ .-]?(\d{4})$/, 'Phone number is not valid')
      .required('Phone is a required field')
  })

  const initialValues = {
    first_name: _.get(me, 'first_name', ''),
    last_name: _.get(me, 'last_name', ''),
    contact_phone: numberMask(_.get(me, 'contact_phone', '').replace('+1', ''))
  }

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }

    dispatch.manageBusiness.fetchBusinessSettings()

    segmentEventTrack({
      event: 'Onboarding_Create_Account_Load',
      properties: {
        Onboarding_Version: 'v4'
      }
    })
  }, [])

  return (
    <Container maxWidth="xs">
      <StepText mt="79px">{step}</StepText>
      <StepTitle mt="4px">Create your free account</StepTitle>
      <Formik
        validateOnBlur
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        isInitialValid={schema.isValidSync(initialValues)}
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          const res = await dispatch.myaccount.updateProfileDetails({
            id: me._id,
            first_name: values.first_name,
            last_name: values.last_name,
            contact_phone: values.contact_phone
          })

          if (!res) {
            resetForm(values)
            return
          }

          await dispatch.auth.getMe()

          segmentEventTrack({
            event: 'Onboarding_Create_Account_Completed',
            properties: {
              Onboarding_Version: 'v4'
            }
          })

          onSubmitted()
        }}
        render={({ isSubmitting, isValid, setFieldValue }) => {
          return (
            <Form className="w-100">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  mt: '39px'
                }}>
                <Box sx={{ width: '100%' }}>
                  <FormLabel mt="31px" mb="10px">
                    First Name<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>

                  <div
                    className={`d-flex align-items-start align-items-center border rounded`}
                    style={{ padding: '7px 10px' }}>
                    <Field
                      type="text"
                      name="first_name"
                      data-lpignore="true"
                      className={`form-control bg-white flex-fill fz-14 bg-white border-sm-0 rounded-0 border-0`}
                      placeholder="First Name"
                    />
                  </div>

                  <FormLabel mt="31px" mb="10px">
                    Last Name<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>

                  <div
                    className={`d-flex align-items-start align-items-center border rounded`}
                    style={{ padding: '7px 10px' }}>
                    <Field
                      type="text"
                      name="last_name"
                      data-lpignore="true"
                      className={`form-control bg-white flex-fill fz-14 bg-white border-sm-0 rounded-0 border-0`}
                      placeholder="Last Name"
                    />
                  </div>

                  <FormLabel mt="31px" mb="10px">
                    Phone<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>

                  <div
                    className={`d-flex align-items-start align-items-center border rounded`}
                    style={{ padding: '7px 10px' }}>
                    <Field
                      type="text"
                      name="contact_phone"
                      data-lpignore="true"
                      maxLength={14}
                      className={`form-control bg-white flex-fill fz-14 bg-white border-sm-0 rounded-0 border-0`}
                      placeholder="Your phone number"
                      onChange={(event) => {
                        setFieldValue('contact_phone', numberMask(event.target.value))
                      }}
                    />
                  </div>
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '56px',
                  gap: '16px'
                }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onBack()
                  }}>
                  BACK
                </Button>
                <Button variant="contained" type="submit" disabled={!isValid || isSubmitting}>
                  CONTINUE
                  {isSubmitting && <Icon style={{ height: '20px', width: '20px', marginLeft: '10px' }} name='spinnerDark' />}
                </Button>
              </Box>
            </Form>
          )
        }}
      />
    </Container>
  )
}
