import queryString from 'qs'
import moment from 'moment'
import humanparser from 'humanparser'

export const groupBy = (xs, key) => {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)
    return rv
  }, {})
}

export const objToUrlParams = (obj) => {
  return queryString.stringify(cleanObject(obj))
}
export const urlParamsToObj = (obj) => {
  return JSON.parse('{"' + decodeURI(obj).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

export const generateRandomKey = (obj) => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
}

export const generateUuid = (obj) => {
  return Math.random().toString(36).substring(3)
}
export const capitalize = (str) => {
  if (typeof str === 'string' && str.charAt(0)) {
    return str.charAt(0).toUpperCase() + str.slice(1)
  }
}
export const titleCase = (str) => {
  const string = str.toLowerCase()
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export const titleCaseEachWord = (str) => {
  return str
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
}

export const getDate = (date) => {
  return moment(date).format('DD MMMM  YYYY')
}

export function cleanObject(obj) {
  for (const propName in obj) {
    if (obj[propName] === null || obj[propName] === undefined) {
      delete obj[propName]
    }
  }
  return obj
}
export function orderCode(sorter) {
  let order = {}
  if (sorter && sorter.field) {
    order = {
      [sorter.field]: [sorter.order]
    }
  }
  return order
}

export function orderMap(order) {
  const orderKeys = { descend: 'DESC', ascend: 'ASC' }
  return Object.keys(order).map((key) => [key, orderKeys[order[key]]])
}

export function getChatName(currentUser) {
  let name = ''
  if (currentUser.full_name) name = currentUser.full_name
  else if (currentUser.first_name && currentUser.last_name) name = `${currentUser.first_name} ${currentUser.last_name}`
  else if (currentUser.first_name) name = currentUser.first_name
  else if (currentUser.last_name) name = currentUser.last_name

  return name.replace(/[^a-zA-Z ]/g, '')
}

export function findWithAttr(array, attr, value) {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i][attr] === value) {
      return i
    }
  }
}

export function formatTime(item) {
  const parts = item.split(':')
  let hrs, mins, format, hrs1, mins1, format1, time
  if (parts.length === 5) {
    hrs = parseInt(parts[1])
    mins = parseInt(parts[2])
    format = 'AM'
    hrs1 = parseInt(parts[3])
    mins1 = parseInt(parts[4])
    format1 = 'AM'

    if (hrs === 0 && mins === 0 && hrs1 === 0 && mins1 === 0) {
      time = '24 Hours'
    } else {
      if (hrs >= 12) {
        format = 'PM'
        if (hrs > 12) hrs = hrs - 12
      } else if (hrs === 0) {
        hrs = 12
        format = 'AM'
      }

      if (hrs1 >= 12) {
        format1 = 'PM'
        if (hrs1 > 12) hrs1 = hrs1 - 12
      } else if (hrs1 === 0) {
        hrs1 = 12
        format1 = 'AM'
      }
      time = hrs
      if (mins) {
        time += ':' + mins
      }
      time += format + ' to ' + hrs1
      if (mins1) {
        time += ':' + mins1
      }
      time += format1
    }
  }
  return time || 'Closed'
}

export function getFormattedPhone(object) {
  const value = String(object)

  if (value.indexOf('@') === -1) {
    const number = value.replace(/[^0-9]+/g, '')
    return number
  } else return object
}

export function formatSpecialHour(elem) {
  return elem.isClosed
    ? 'Closed'
    : moment(elem.openTime, 'HH:mm').format('hh:mm A') + ' - ' + moment(elem.closeTime, 'HH:mm').format('hh:mm A')
}
export function setHeader(domainSettings, title) {
  if (domainSettings && domainSettings.generalSettings && domainSettings.generalSettings.site_title) {
    window.document.title = `${domainSettings.generalSettings.site_title} : ${title}`
  }
}

export function setGositeHeaderTitle(title) {
  if (window && title) {
    window.document.title = `Gosite : ${title}`
  } else {
    window.document.title = `Gosite`
  }
}
export function dateFormater(date) {
  return date
}
export function getMessages(type) {
  let message = ''

  if (type === 'special') message = 'Business special hours updated successfully'
  else if (type === 'businesshour') message = 'Business hours updated successfully'
  else if (type === 'video') message = 'Video added successfully'
  return message
}
export function setFavicon(domainSettings) {
  if (domainSettings && domainSettings.favIcon) {
    const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
    link.type = 'image/x-icon'
    link.rel = 'shortcut icon'
    link.href = domainSettings.favIcon
    document.getElementsByTagName('head')[0].appendChild(link)
  }
}
export function parseName(fullName = '') {
  const form = {}
  const parseName = humanparser.parseName(fullName)
  form.first = `${parseName.firstName}`
  if (parseName.middleName) form.first = `${parseName.firstName} ${parseName.middleName}`
  form.last = parseName.lastName

  return form
}

export function formatSiteSettings(values, generalSettings, userProducts) {
  generalSettings.site_title = values.site_title ? values.site_title : ''
  generalSettings.list_count = values.list_count ? values.list_count : ''
  generalSettings.frontend_list_count = values.frontend_list_count ? values.frontend_list_count : ''
  generalSettings.site_language = values.site_language ? values.site_language : ''
  generalSettings.default_role = values.default_role ? values.default_role : ''
  generalSettings.date_format = values.date_format ? values.date_format : ''
  generalSettings.email = values.email ? values.email : ''
  generalSettings.feedback_email = values.feedback_email ? values.feedback_email : ''
  generalSettings.email_branding = values.hasOwnProperty('email_branding') ? values.email_branding : false
  if (values.email_branding_link) generalSettings.email_branding_link = values.email_branding_link
  if (values.email_branding_logo) generalSettings.email_branding_logo = values.email_branding_logo

  generalSettings.enable_feedback = values.hasOwnProperty('enable_feedback') ? values.enable_feedback : false
  generalSettings.email_verified = values.hasOwnProperty('email_verified') ? values.email_verified : false
  generalSettings.can_register = values.hasOwnProperty('can_register') ? values.can_register : false
  generalSettings.skip_email = values.hasOwnProperty('skip_email') ? values.skip_email : false
  generalSettings.show_limit_notification = values.hasOwnProperty('show_limit_notification')
    ? values.show_limit_notification
    : false
  generalSettings.preview_mode = values.hasOwnProperty('preview_mode') ? values.preview_mode : false
  generalSettings.email_browser_view = values.hasOwnProperty('email_browser_view') ? values.email_browser_view : false

  if (values.preview_mode) {
    const previewModeProducts = []
    userProducts.forEach((product) => {
      if (product.selected) previewModeProducts.push(product._id)
    })

    generalSettings.preview_mode_products = previewModeProducts
  }

  generalSettings.showRecaptcha = values.hasOwnProperty('showRecaptcha') ? values.showRecaptcha : false
  generalSettings.blockIp = values.hasOwnProperty('blockIp') ? values.blockIp : false

  if (generalSettings && generalSettings.social_sync && generalSettings.social_sync.services) {
    generalSettings.social_sync.services.yext = values.hasOwnProperty('yext') ? values.yext : false
    generalSettings.social_sync.services.google = values.hasOwnProperty('google') ? values.google : false
  } else {
    const data = { services: { google: false, yext: false } }
    data.services.yext = values.hasOwnProperty('yext') ? values.yext : false
    data.services.google = values.hasOwnProperty('google') ? values.google : false
    generalSettings.social_sync = data
  }
  generalSettings.email_helpemail = values.email_helpemail ? values.email_helpemail : ''
  generalSettings.email_reply_to = values.email_reply_to ? values.email_reply_to : ''
  generalSettings.email_hideapp = values.email_hideapp ? values.email_hideapp : false
  generalSettings.email_footer_address = values.email_footer_address ? values.email_footer_address : ''

  generalSettings.swiper_transaction_fee = values.swiper_transaction_fee ? values.swiper_transaction_fee : ''
  generalSettings.online_transaction_fee = values.online_transaction_fee ? values.online_transaction_fee : ''
  generalSettings.swiper_funding_time = values.swiper_funding_time ? values.swiper_funding_time : ''
  generalSettings.online_funding_time = values.online_funding_time ? values.online_funding_time : ''
  generalSettings.payment_credentials_verification = values.hasOwnProperty('payment_credentials_verification')
    ? values.payment_credentials_verification
    : false
  generalSettings.location_notification_preference = values.hasOwnProperty('location_notification_preference')
    ? values.location_notification_preference
    : 'managed-location'
  return generalSettings
}
export function durationToMinutes(duration) {
  const arr = duration.split(':')
  const hr = Number(arr[0])
  const min = Number(arr[1])
  return hr * 60 + min
}
export function durationtoString(item) {
  let hr = Math.floor(item / 60)
  let mn = item % 60
  if (hr.toString().length === 1) {
    hr = `0${hr}`
  }
  if (mn.toString().length === 1) {
    mn = `0${mn}`
  }
  return `${hr}:${mn}`
}
export function dateZoneFormater(date) {
  return date.slice(0, 19)
}

// Pings Intercom upon page/layout changes
export function intercomUpdate(user) {
  if (window && window.Intercom) {
    window.Intercom('update', {
      email: user.user_email,
      user_id: user._id,
      id: user._id,
      name: user.full_name,
      user_hash: user.userIntercomHash,
      last_request_at: parseInt(new Date().getTime() / 1000)
    })
  }
}

export function makeHyperlinksInsideText(text) {
  const res = [...text.disclosure.matchAll(/https?:\/\/[^\s]+/g)]
  res.forEach(([hyper]) => {
    const link = hyper.split('')
    if (link[link.length - 1] === '.') {
      link.splice(link.length - 1, 1)
    }
    const textToReplace = `<a href="${link.join('')}" target="_blank">${hyper}</a>`
    text.disclosure = text.disclosure.replace(hyper, textToReplace)
  })
  return text
}
