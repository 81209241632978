import React from 'react'
import Modal from '@components/popups/basic-modal'
import ManagerForm from './managerForm'
import { withTranslation } from 'react-i18next'

class AddManagerModal extends React.Component {
  state = {
    visible: false
  }

  showModal() {
    this.setState({ visible: true })
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  closeModal() {
    this.setState({ visible: false })
    this.reject()
    this.form.resetForm()
    if (this.props.fromUNB) {
      this.props.onClose()
    }
  }

  async onSubmit(values, action) {
    this.setState({ submiting: true })
    await this.props.createManager({
      first_name: values.firstname,
      last_name: values.lastname,
      user_email: values.email
    })
    if (this.props.createdManager) {
      await this.props.manageUsers({
        users: values.locations.map((item) => item._id),
        tags: values.categories.map((item) => ({ color_code: item.color_code, tag_name: item.label })),
        id: this.props.createdManager._id
      })
      this.resolve()
      this.setState({ visible: false })
      action.resetForm()
      if (this.props.fromUNB) {
        this.props.onClose(true)
      }
    } else {
      if (this.props.fromUNB) {
        this.props.onClose()
      }
    }

    this.setState({ submiting: false })
  }

  render() {
    const { t } = this.props
    return (
      <Modal open={this.state.visible} modalBackdropClicked={this.closeModal.bind(this)} hideHeader={true}>
        {/* {!this.state.visible ? null : */}

        <div name="frmEditColumn" id="frmEditColumn" className="position-relative">
          <button
            type="reset"
            id="closeIcon"
            className="close custom-modal-close-button remove-modal-close top-minus-10 right-minus-10"
            onClick={this.closeModal.bind(this)}>
            <span aria-hidden="true"></span>
          </button>
          <header className="mt-sm-20 pr-30 pr-sm-5 mb-20">
            <h5 className="modal-title">{t('services.add-manager')}</h5>
          </header>
          <ManagerForm
            onSubmit={this.onSubmit.bind(this)}
            closeModal={this.closeModal.bind(this)}
            locations={this.props.locations}
            categories={this.props.categories}
            button_text={t('services.add-manager-upper')}
            submiting={this.state.submiting}
            ref={(ref) => (this.form = ref)}
            tagColors={this.props.tagColors}
          />
        </div>
        {/* } */}
      </Modal>
    )
  }
}

export default withTranslation(null, { withRef: true })(AddManagerModal)
