import * as React from 'react'
import { RestrictedRoute } from '@app/router/access-route'
import { OnboardingV4 } from '../onboarding-v4'

export const routes = [
  <RestrictedRoute
    exact
    path="/:userId/enrolling"
    key={'/:userId/enrolling'.replace('/:userId/', '')}
    layoutSettings={{ page: true, hideLayout: true }}
    component={() => <OnboardingV4 />}
  />
]
