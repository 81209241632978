import React from 'react'
import PropTypes from 'prop-types'
import { addMethod, object } from 'yup'
import { CardExpiryElement as StripeCardExpiryElement } from '@stripe/react-stripe-js'

import { ElementState } from './ElementState'

const defaultStyle = {
  fontSize: '14px',
  paddingTop: '0.75rem',
  fontWeight: 400
}

const CardExpiryElement = ({ form, field, className, ...props }) => {
  const { name, value } = field
  const { onBlur, onChange, setFieldValue, setTouched } = form

  const handleBlur = () => {
    onBlur && onBlur()
    setTouched({ ...form.touched, [name]: true })
    if (value.empty) setFieldValue(name, new ElementState())
  }

  const handleChange = (event) => {
    onChange && onChange(event)
    setFieldValue(name, new ElementState(event))
  }

  return (
    <StripeCardExpiryElement
      {...props}
      options={{
        classes: {
          base: className
        },
        style: {
          base: defaultStyle
        }
      }}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

CardExpiryElement.propTypes = {
  name: PropTypes.string,
  ...CardExpiryElement.propTypes
}

function cardExpiryElement (
  // this,
  messages = {}
) {
  return this.test({
    name: 'card-number-element',
    test: function (value) {
      if (!(value instanceof ElementState)) throw new Error('Object must be an instance of `ElementState`.')

      if (value.empty) {
        return this.createError({
          path: this.path,
          message: messages.empty || `Required`
        })
      }

      if (value.error === 'invalid_expiry_month') {
        return this.createError({
          path: this.path,
          message: messages.invalid_month || `Invalid`
        })
      }

      if (value.error === 'invalid_expiry_year') {
        return this.createError({
          path: this.path,
          message: messages.invalid_year || `Invalid`
        })
      }

      if (value.error === 'invalid_expiry_year_past') {
        return this.createError({
          path: this.path,
          message: messages.invalid_year || `Invalid`
        })
      }

      if (value.error === 'incomplete_expiry') {
        return this.createError({
          path: this.path,
          message: messages.incomplete || `Incomplete`
        })
      }

      return true
    }
  })
}

export { CardExpiryElement }

addMethod(object, 'cardExpiryElement', cardExpiryElement)
