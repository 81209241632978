import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import i18next from 'i18next'
const t = i18next.t
export default {
  state: {
    AddServiceError: false
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        AddServiceError: false
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : t('services.please-try-again-later')
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        AddServiceError: true
      }
    },

    getServicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        services: data.data,
        defaultServices: data.defaultServices ? data.defaultServices : []
      }
    },
    deleteSuccess(state, data) {
      notification('success', t('services.service-deleted-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    oncreateServiceSuccess(state, data, hideNotification) {
      if (!hideNotification) {
        notification('success', t('services.service-created-successfully'))
      }
      return {
        ...state,
        loading: false
      }
    },
    onUpdateServiceSuccess(state, data) {
      notification('success', t('services.service-update-successful'))
      return {
        ...state,
        loading: false
      }
    },
    ongetServiceUserSuccess(state, data) {
      return {
        ...state,
        loading: false,
        editService: data.services,
        serviceUser: data.servicesuser
      }
    },
    onsortServicesSuccess(state, data) {
      notification('success', t('services.sort-order-update-successful'))
      return {
        ...state,
        loading: false
      }
    },
    onGetBookingServiceCountSuccess(state, data) {
      return {
        ...state,
        loading: false,
        bookingServiceCount: data && data.data
      }
    },
    onUpdateServiceLocation(state, data) {
      notification('success', t('services.service-location-updated-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    onGetRecurrenceInvoiceList(state, data) {
      return {
        ...state,
        recurrenceInvoiceList: data && data.recurringInvoices ? data.recurringInvoices.length : 0
      }
    }
  },
  effects: {
    async getServices(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getServices(payload)
        await this.getServicesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getStaffServices(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getStaffServices(payload)
        await this.getServicesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteServices(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteServices(payload)
        await this.deleteSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createService(payload, rootState, hideNotification) {
      this.onRequest()
      try {
        const res = await service.createService(payload)
        await this.oncreateServiceSuccess(res, hideNotification)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getServiceUser(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getServiceUser(payload)
        await this.ongetServiceUserSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async UpdateService(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.UpdateService(payload)
        await this.onUpdateServiceSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async sortServices(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sortServices(payload)
        await this.onsortServicesSuccess(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    },
    async getBookingServiceCount(payload) {
      this.onRequest()
      try {
        const res = await service.getBookingServiceCount(payload)
        await this.onGetBookingServiceCountSuccess(res)
        return res
      } catch (e) {
        console.log('e', e)
      }
    },
    async updateBookingServiceLocation(payload) {
      this.onRequest()
      try {
        const res = await service.updateBookingServiceLocation(payload)
        await this.onUpdateServiceLocation(res)
        return res
      } catch (e) {
        console.log('e', e)
      }
    },
    async getRecurrenceInvoiceList(serviceId) {
      this.onRequest()
      try {
        const res = await service.getRecurrenceInvoiceList(serviceId)
        await this.onGetRecurrenceInvoiceList(res)
        return res
      } catch (e) {
        await this.onError(e)
      }
    }
  }
}
