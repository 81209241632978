import React, { useEffect } from 'react'
import Popup from './basic-modal'
import styles from './dcl-popup.module.scss'
import { useSelector } from 'react-redux'
import { Me } from '@containers/first-experience/types'
import { history } from '@app/store'
import Icon from '../icons'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import notification from '@components/notification'
import { segmentEventTrack } from '@helpers/segment'

interface DCLPopupProps {
  open: boolean
}

export const DCLPopup = ({ open }: DCLPopupProps) => {
  const me = useSelector<any, Me>((state) => state.auth.me)

  useEffect(() => {
    if (!open) {
      return
    }

    segmentEventTrack({
      event: 'dcl_popup_shown',
      properties: {}
    })
  }, [open])

  return (
    <Popup
      id="dcl-popup"
      type="default"
      hideHeader={true}
      open={open}
      modalBackdropClicked={() => {}}
      center={true}
      customClass={`${styles['model']}`}>
      <div className={`${styles['container']}`}>
        <div className={`${styles['left-section']}`}>
          <div>
            <div className={`${styles['header-container']}`}>
              <h2 className={`${styles['header']}`}>
                Past due:
                <Icon name="amountDueUnderscore" />
              </h2>
            </div>
            <h2 className={`${styles['amount-due']}`}>$0</h2>
            <p className={`${styles['subtitle']}`}>
              Your card was declined. But, we understand that these are challenging times for small businesses. So,
              GoSite is <strong>waiving</strong> all your unpaid invoices!
            </p>
            <p className={`${styles['subtitle']}`}>
              To keep using GoSite, please update your card info. You will only be charged from today onwards.
            </p>

            <button
              onClick={async () => {
                segmentEventTrack({
                  event: 'dcl_popup_update_payment_method_clicked',
                  properties: {}
                })

                history.push(`/${me.user_unique_id}/reactivate-subscription`)
              }}
              className={`btn btn-primary border-0 px-sm-20 ${styles['upgrade-btn']}`}>
              UPDATE PAYMENT METHOD
            </button>
          </div>
          <div>
            <div className={`${styles['subtitle2']}`}>
              <span>
                Have questions?&nbsp;
                <CopyToClipboard
                  text={'help@gosite.com'}
                  onCopy={() => {
                    notification('success', 'Copied: help@gosite.com')
                  }}>
                  <span className={`${styles['talk-to-sales']}`}>Email our Support team</span>
                </CopyToClipboard>
                &nbsp;or call us at (858)-295-7995.
              </span>
            </div>
          </div>
        </div>
        <div>
          <div className={`${styles['right-section']}`}>
            <img src="/images/promotional/trial-expired.png" alt="expired" />
          </div>
        </div>
      </div>
    </Popup>
  )
}
