import React, { useState } from 'react'
import { useGoogleLogin, googleLogout } from '@react-oauth/google'

const WithGoogleLoginHOC = (Component) => {
  return (props) => {
    const [googleResponse, setGoogleResponse] = useState('')
    const [error, setError] = useState('')

    const onClickGoogleLogin = useGoogleLogin({
      onSuccess: async response => {
        setGoogleResponse(response)
      },
      onError: async error => {
        console.log('Google login Failed', error)
        setError(error)
      },
      scope: 'email profile https://www.googleapis.com/auth/plus.business.manage https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/businesscommunications https://www.googleapis.com/auth/contacts.readonly https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.events',
      prompt: 'consent',
      flow: 'auth-code',
      cancel_on_tap_outside: true
    })
    return <Component
      onClickGoogleLogin={onClickGoogleLogin}
      error={error}
      logout={googleLogout()}
      googleResponse={googleResponse}
      {...props} />
  }
}

export default WithGoogleLoginHOC
