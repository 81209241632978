import * as React from 'react'
import { history } from '@app/store'
import Icon, { IconNames } from '../icons'
import styles from './warning-card.module.scss'

export interface WardingCardProps {
  iconName: IconNames
  message: string
  customIconClass?: string
  customMessageClass?: string
  customClass?: string
  showHelperLink: boolean
}

export const WarningCard = ({
  iconName,
  message,
  customIconClass = '',
  customMessageClass = '',
  customClass = '',
  showHelperLink = false
}: WardingCardProps) => {
  return (
    <div className={`${styles['warning']} ${customClass}`}>
      <Icon name={iconName} className={`${customIconClass}`} />
      <span className={`${customMessageClass}`}>{message}</span>
    </div>
  )
}
