import React from 'react'
import Popup from './basic-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import PredefinedTemplate from '../predefined-templates'
import MediaLibraryPopup from '@containers/media-library/media/media-library'
import Icons from '../icons'
import { formatFormData, phoneNumberAutomasking, encryptMessageUsingRSAPub } from '@helpers/utility'
import Tooltip from '../tooltip'
import notification from '../notification'
import { VerifyMobilePopup } from '@containers/myaccount/utility/VerifyMobilePopup'
import { trackSendMessage } from '@containers/messenger/tracking'
import { withTranslation } from 'react-i18next'

const sendMessagePopup = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      subject: '',
      message: '',
      openMediaLibrary: false,
      mediaUrl: '',
      displayName: '',
      spinner: false
    }
  }
  async showPopup(
    recipient,
    contactId,
    type,
    me,
    reportAdmin,
    predefinedTemplates,
    first_name = '',
    last_name = '',
    customers
  ) {
    let twilioEnabled = false
    if (me && me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length) {
      twilioEnabled = true
    }
    const subject =
      me && me.bussiness_name
        ? this.props.t('common.you-have-an-email-from-me-bussiness_name', [me.bussiness_name])
        : this.props.t('common.you-have-an-email')
    await this.setState({
      open: true,
      recipient,
      contactId,
      type,
      twilioEnabled,
      reportAdmin,
      predefinedTemplates,
      displayName: '',
      mediaUrl: '',
      first_name,
      last_name,
      customers,
      subject
    })
  }
  async componentDidUpdate(prevProps) {
    const { twilioEnabled } = this.props
    if (twilioEnabled && twilioEnabled !== this.state.twilioEnabled) {
      await this.setState({
        open: true,
        twilioEnabled
      })
    }
  }

  async openPopup(shouldOpen, twilioEnabled) {
    await this.setState({
      open: shouldOpen,
      twilioEnabled: twilioEnabled
    })
  }

  async handleSubmit(values, { resetForm }) {
    await this.setState({ spinner: true })
    const { contactId, type, recipient } = this.state
    const newkeys = {
      txtSubject: 'subject',
      txtMessage: 'message'
    }
    const payload = formatFormData(values, newkeys)
    if (this.state.mediaUrl) {
      payload.media = this.state.mediaUrl
    }
    payload.first_name = this.state.first_name
    payload.last_name = this.state.last_name
    if (this.state.customers) {
      payload.query = {
        customers: this.state.customers
      }
    }
    // add encrypted message to payload to send database
    const dataEnc = { ...values }
    dataEnc.message = await encryptMessageUsingRSAPub(dataEnc.message)
    values.message = dataEnc.message
    await this.props.onSubmit({
      values,
      contactId,
      recipient,
      type,
      resetForm,
      payload,
      customers: this.state.customers,
      twilioEnabled: this.state.twilioEnabled
    })
    trackSendMessage({
      user_id: this.props.me && this.props.me._id,
      contact_id: contactId,
      contact_type: type,
      recipient: recipient
    })
  }

  handleCancel(values, { resetForm }) {
    this.closeModal(true, resetForm)
  }
  closeModal(reset, resetForm) {
    this.setState({ open: false, spinner: false })
    if (reset) this.reset(resetForm)
  }
  removeLoader() {
    this.setState({ spinner: false })
  }
  reset(resetForm) {
    resetForm({ open: false, txtSubject: this.state.subject, txtMessage: '' })
  }

  handleReportAdmin(reportAdmin) {
    reportAdmin()
    this.setState({ open: false })
  }
  selectTemplateFn(selectedItem, setFieldValue) {
    setFieldValue('txtMessage', selectedItem.message)
  }
  mediaLibraryPopup() {
    this.setState({ openMediaLibrary: true })
  }

  cancelSelectedMedia() {
    this.setState({ mediaUrl: false })
  }
  handleMediaLibrary() {
    this.setState({ openMediaLibrary: false })
  }
  onMediaSuccess(media) {
    if (media.length && media[0] && media[0].type) {
      if ((this.props.messageType && this.props.messageType === 'SMS') || this.state.type === 'sms') {
        const exten = media[0].fileUrl.split('.').pop()
        if (media[0].type === 'doc') {
          if (!['pdf', 'PDF'].includes(exten)) {
            notification('error', this.props.t('common.sms-supports-only-pdf-documents'))
            return
          }
        } else if (media[0].type === 'video' && media[0].file_size && media[0].file_size > 5000) {
          notification('error', this.props.t('common.sms-video-limit-is-5mb'))
          return
        }
      }
    }
    this.setState({ mediaUrl: media[0].fileUrl, displayName: media[0].displayName })

    this.setState({ openMediaLibrary: false })
  }
  async handleResendFunction() {
    await this.setState({ spinner: true })
    const data = {}
    data.orderid = this.props.page.orderId
    data.type = this.state.type
    if (this.state.type === 'sms') {
      data.contacts = [this.state.recipient]
    } else if (this.state.type === 'email') {
      data.emails = [this.state.recipient]
    }
    await this.props.sendRequest(data)
    await this.setState({ spinner: false })
  }

  closePopup() {
    this.setState({ open: false })
    this.props.close()
  }

  render() {
    return (
      <>
        {!this.state.twilioEnabled && this.state.type === 'sms' ? (
          <VerifyMobilePopup
            open={this.state.open}
            onClose={this.closePopup.bind(this)}
            apiError={this.props.apiError || ''}
            verifyOtp={this.props.handleSubmitOtp}
            onRequestOtp={this.props.verifyMobile}
            onRequestOtpOnCall={this.props.onRequestOtpOnCall}
            securityText={this.props.t(
              'common.for-security-reasons-youll-need-to-set-up-2-factor-authentication-to-use-gosites-sms-capabilities'
            )}
            title={this.props.t('common.first-verify-your-account')}
          />
        ) : (
          ''
        )}
        {this.state.type === 'email' || this.state.twilioEnabled ? (
          <Popup
            open={this.state.open}
            title={this.state.type === 'email' ? this.props.t('common.send-email') : this.props.t('common.send-sms')}
            okText={this.props.t('common.send-uppercase')}
            onCancel={() => this.setState({ open: false })}
            type="small"
            overrideForm={true}
            id={this.state.type === 'email' ? 'modalSendEmail' : 'modalSendSMS'}
            modalBackdropClicked={() => console.log(null)}>
            <React.Fragment>
              <div className="message-modal mt-10">
                <div className="form-group">
                  <div
                    className="d-flex align-items-center"
                    id={`${this.state.type === 'email' ? 'frmSentEmail_txtEmail' : 'frmSentMessage_txtEmail'}`}>
                    <div className="mr-10 message-icon d-flex">
                      <Icons name={`${this.state.type === 'email' ? 'email' : 'phone'}`}></Icons>
                    </div>
                    {this.state.recipient && this.state.recipient.length > 30 ? (
                      <Tooltip position="bottom" message={this.state.recipient}>
                        <span id={this.state.type === 'email' ? 'emailRecipient' : 'SMSRecipient'}>{`${
                          this.state.type === 'email'
                            ? this.state.recipient
                              ? `${this.state.recipient.substring(0, 30)} ...`
                              : this.state.recipient
                            : this.state.recipient
                            ? phoneNumberAutomasking(this.state.recipient.replace('+1', ''))
                            : ''
                        }`}</span>
                      </Tooltip>
                    ) : (
                      <span id={this.state.type === 'email' ? 'emailRecipient' : 'SMSRecipient'}>{`${
                        this.state.type === 'email'
                          ? this.state.recipient
                          : this.state.recipient
                          ? phoneNumberAutomasking(this.state.recipient.replace('+1', ''))
                          : ''
                      }`}</span>
                    )}

                    {this.props.page && this.props.page.page === 'payment Details' && (
                      <Tooltip position="bottom" message={this.props.t('common.resend-reciept')}>
                        <i onClick={() => this.handleResendFunction()} className={'btn-icon ml-10 restore_icon '}>
                          <Icons name="restore" />
                        </i>
                      </Tooltip>
                    )}
                  </div>
                </div>
                <Formik
                  enableReinitialize={true}
                  initialValues={{
                    txtSubject: this.state.subject,
                    txtMessage: this.state.message
                  }}
                  validationSchema={Yup.object().shape({
                    txtSubject: Yup.string().required(this.props.t('common.required')),
                    txtMessage: Yup.string().required(this.props.t('common.required'))
                  })}
                  onReset={this.handleCancel.bind(this)}
                  onSubmit={this.handleSubmit.bind(this)}
                  render={({ values, resetForm, setFieldValue }) => {
                    return (
                      <Form
                        name="frmBookingDetailsSendMsg"
                        id={`${
                          this.state.type === 'email' ? 'frmBookingDetailsSendEmail' : 'frmBookingDetailsSendMsg'
                        }`}>
                        <button
                          name="btnCloseBookingSendMsgModal"
                          type="reset"
                          className="close custom-modal-close-button remove-modal-close">
                          <span aria-hidden="true"></span>
                        </button>
                        <div className="" id="send-message">
                          {this.state.type === 'email' && (
                            <div className="form-group">
                              <label className="label-text">
                                {this.props.t('common.subject-uppercase')} <i className="star">*</i>
                                <ErrorMessage name="txtSubject" component="span" className="form-error" />
                              </label>
                              <Field
                                id={`${this.state.type === 'email' ? 'frmSentEmail_txtSubject' : 'frmSent_txtMessage'}`}
                                className="form-control form-control-lg"
                                type="text"
                                placeholder={this.props.t('common.enter-subject')}
                                name="txtSubject"
                              />
                            </div>
                          )}
                          <div className="form-group">
                            <label className="label-text">
                              {' '}
                              {this.props.t('common.message-uppercase')} <i className="star">*</i>
                              <ErrorMessage
                                name="txtMessage"
                                component="span"
                                className="form-error"
                                id="messageError"
                              />
                            </label>

                            {this.props.fromEnterprise &&
                            this.state.predefinedTemplates &&
                            this.state.predefinedTemplates.length === 0 ? null : (
                              <div id="container_frmBookingDetailsSentMessageTemplate" className="mb-20">
                                <PredefinedTemplate
                                  fromEnterprise={this.props.fromEnterprise}
                                  templates={this.state.predefinedTemplates}
                                  setFieldValue={setFieldValue}
                                  selectTemplateFn={this.selectTemplateFn.bind(this)}
                                />
                              </div>
                            )}
                            <Field
                              id="frmSent_txtMessage"
                              component="textarea"
                              className="form-control form-control-textarea"
                              type="text"
                              placeholder={this.props.t('common.enter-message')}
                              name="txtMessage"
                            />
                          </div>
                          {this.state.mediaUrl ? (
                            <div className="d-flex align-items-center mb-30">
                              <i className="btn-icon icon-attch-custom">
                                <Icons name="attachment" />
                              </i>
                              <strong className="mx-10 text-truncate">{this.state.displayName}</strong>
                              <button
                                name="btnCloseAttachedFile"
                                type="button"
                                className="btn-close-custom px-0 bg-white d-flex justify-content-center align-items-center"
                                onClick={this.cancelSelectedMedia.bind(this)}>
                                <i className="btn-icon icon-close-custom d-flex">
                                  <Icons name="close" />
                                </i>
                              </button>
                            </div>
                          ) : (
                            <div className="form-group mb-30">
                              <button
                                name="btnAttachBookingSendMsgModal"
                                className="btn btn-sm text-secondary is-loader btn-attchment rounded d-flex align-items-center text-uppercase"
                                onClick={this.mediaLibraryPopup.bind(this)}
                                type="button">
                                <i className="btn-icon mr-10 top-minus-2">
                                  <Icons name="attachment"></Icons>
                                </i>
                                {this.props.t('common.attach-files')}
                              </button>
                            </div>
                          )}
                        </div>
                        <footer className="d-block d-flex">
                          <button
                            type="submit"
                            name="btnSendBookingSendMsgModal"
                            className="btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block"
                            id="btn_send"
                            data-test="modal-send-message-btn"
                            disabled={this.state.spinner}>
                            {this.state.spinner ? (
                              <i className="btn-icon mr-5 top-minus-2 btn-loader">
                                <Icons name="spinner" />
                              </i>
                            ) : null}
                            {this.props.t('common.send-uppercase')}
                          </button>
                          <button
                            name="btnCancelBookingSendMsgModal"
                            type="reset"
                            className="btn btn-xl m-0 cancel-btn custom-btn-width "
                            id="btn_cancel">
                            {this.props.t('common.cancel-uppercase')}
                          </button>
                        </footer>
                      </Form>
                    )
                  }}
                />
                {this.state.openMediaLibrary ? (
                  <MediaLibraryPopup
                    open={this.state.openMediaLibrary}
                    onSubmit={this.onMediaSuccess.bind(this)}
                    onCancel={this.handleMediaLibrary.bind(this)}
                    id="media-library-popup"
                    multiple={false}
                    type={['image', 'video', 'doc', 'icon']}
                    showShapes={true}
                    hideImages={true}
                  />
                ) : null}
              </div>
            </React.Fragment>
          </Popup>
        ) : (
          ''
        )}
      </>
    )
  }
}

export default withTranslation(null, { withRef: true })(sendMessagePopup)
