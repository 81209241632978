import React from 'react'
import Popup from '../popups/basic-modal'
import Icon from '@components/icons'

import { Box } from '@gositeinc/ui'

export const EstimateErrorPopup = ({ open, setOpen, title, confirmButtonText, onConfirm, cancelButtonText, onCancel }) => {
  return (
    <Popup
      open={open}
      center
      type="small"
      title={' '}
      hideFooter={true}
      overrideForm={true}
      modalBackdropClicked={() => setOpen(false)}>
      <Box sx={{ padding: '6px' }}>
        <div className="row">
          <div className="col-12 col-sm-12 col-md-12">
            <Box
              className="warning-box"
              sx={{
                '*': {
                  fill: '#DE3121'
                },
                svg: {
                  width: '82.5px',
                  height: '82.5px'
                }
              }}>
              <Icon className="svg-center-align" name="warning" />
            </Box>
            <div className="warning-box-title">{title}</div>
          </div>
        </div>
        <Box className="d-flex mt-10" sx={{ gap: '17px' }}>
          <button
            type="button"
            className={'btn btn-danger btn-xl border-0 flex-grow-1 d-block d-sm-inline-block'}
            onClick={() => onConfirm()}>
            {confirmButtonText}
          </button>
          <button
            type="reset"
            className={'btn btn-xl m-0 cancel-btn flex-fill'}
            onClick={() => onCancel()}>
            {cancelButtonText}
          </button>
        </Box>
      </Box>
    </Popup>
  )
}
