import React, { Component } from 'react'
import ConfirmPopup from '@components/popups/confirm-modal'

export default class removeServicePopup extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false
    }
  }

  showPopup () {
    this.setState({ open: true })
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  async handleSubmit () {
    await this.setState({ open: false })
    await this.resolve('ok')
  }

  modalBackdropClicked () {
    this.setState({ open: false })
  }
  render () {
    return (
      <ConfirmPopup
        open={this.state.open}
        title="Delete Service"
        okText="YES, DELETE SERVICE"
        cancelText="NO"
        onCancel={() => this.setState({ open: false })}
        onOk={this.handleSubmit.bind(this)}
        type="delete"
        overrideForm={false}
        modalBackdropClicked={this.modalBackdropClicked.bind(this)}
        okID="btnRemoveServiceOk"
        cancelID="btnRemoveServiceCancel">
        <React.Fragment>
          <p id="modalRemoveService">Do you want to delete this service?</p>
        </React.Fragment>
      </ConfirmPopup>
    )
  }
}
