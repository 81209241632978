import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'

export default {
  state: {
    loading: false,
    apiError: false,
    businessAddressData: undefined
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        apiError: false,
        errorValue: false,
        onBoardingapiError: false
      }
    },
    onError (state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
            ? data.data.message
            : data && data.error
              ? data.error
              : data && data.data && data.data.error
                ? data.data.error
                : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onbusinessAddressDetailsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        businessAddressData: data
      }
    }
  },
  effects: {
    async businessAddressDetails (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.businessAddressDetails(payload)
        this.onbusinessAddressDetailsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
