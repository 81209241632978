import { useState, useEffect } from 'react'

interface Conditions {
  showReCaptcha: boolean,
  noOfRetries: number,
  capthcaValue: string
}

interface UseCaptchaVerificationParams {
  conditions: Conditions,
  timer: number,
  showReCaptcha: boolean,
  isResendCodeDisabled: boolean,
  capthcaValue: string,
  noOfRetries: number,
  onChangeRecaptche: (val: string)=> void,
  setConditions: Function,
  setIsResendCodeDisabled: Function,
  setTimer: Function,
  resetTimer: ()=> void,
  incrementNumberOfRetries: ()=> void
}

export const useCaptchaVerification = (): UseCaptchaVerificationParams => {
  const [timer, setTimer] = useState(45)
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(true)
  const [conditions, setConditions] = useState({
    showReCaptcha: false,
    noOfRetries: 0,
    capthcaValue: ''
  })
  const { showReCaptcha, capthcaValue, noOfRetries } = conditions

  useEffect(() => {
    const totalSeconds = 45000 // 45 seconds delay to display Resend code feature
    setTimeout(() => {
      if (timer === 0) setIsResendCodeDisabled(false)
    }, totalSeconds)
  }, [isResendCodeDisabled])

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer(timer - 1), 1000)
      return () => clearInterval(interval)
    }
  }, [timer])

  useEffect(() => {
    if (noOfRetries >= 2) {
      setConditions({
        ...conditions,
        showReCaptcha: true
      })
    }
    if (noOfRetries >= 3) {
      setConditions({
        ...conditions
      })
    }
  }, [noOfRetries])

  const resetTimer = () => {
    setTimer(45)
  }

  const incrementNumberOfRetries = () => {
    setConditions({
      ...conditions,
      noOfRetries: conditions.noOfRetries + 1
    })
  }

  const onChangeRecaptche = (val: string) => {
    setConditions({
      ...conditions,
      capthcaValue: val
    })
  }

  return {
    conditions,
    timer,
    showReCaptcha,
    isResendCodeDisabled,
    capthcaValue,
    noOfRetries,
    onChangeRecaptche,
    setConditions,
    setIsResendCodeDisabled,
    setTimer,
    resetTimer,
    incrementNumberOfRetries
  }
}
