import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute, EnterpriseRoute } from '@app/router/access-route'
import { getCookie } from '@helpers/utility'
import { ENTERPRISE_ROLES } from '@helpers/constants'

const { PARTNER, MANAGER } = ENTERPRISE_ROLES
const enterpriseRoles = [PARTNER, MANAGER]
const routes = [
  <RestrictedRoute
    exact
    key="/account-settings"
    path={'/:userId/account-settings'}
    component={asyncComponent(() => import('../myaccount'))}
  />
]
const cookie = getCookie()
const isEnterpriseUser = cookie && cookie.token && enterpriseRoles.includes(cookie.role)
const deepLinkRoute = isEnterpriseUser
  ? <EnterpriseRoute
    exact
    key="/account-settings"
    path={'/account-settings'}
    layoutSettings={{ page: 'myaccount', title: 'My Account' }}
    component={asyncComponent(() => import('../enterprise/myaccount/index'))}
  /> : <RestrictedRoute
    exact
    key="/account-settings"
    path={'/account-settings'}
    component={asyncComponent(() => import('../myaccount'))}
  />
routes.push(deepLinkRoute)
export default routes
