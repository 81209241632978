import config from '@config/index'
import { getChatName } from '@helpers/index'

let ws
export function connectWS (currentUser) {
  return new Promise((resolve, reject) => {
    let globalId = ''
    let userId = currentUser._id
    if (currentUser && currentUser.role_id && currentUser.role_id.role_name === 'staff') {
      globalId = currentUser.created_by._id
    } else {
      globalId = currentUser._id
    }
    if (currentUser && currentUser.role_id &&
      currentUser.role_id.role_name !== 'staff' &&
      !currentUser.businessProfile && currentUser.account_settings &&
      currentUser.account_settings.user_id) {
      userId = currentUser.account_settings.user_id
      globalId = currentUser.account_settings.user_id
    }
    const payload = {
      userId,
      globalId: globalId,
      websiteId: globalId,
      display_name: getChatName(currentUser)
    }
    const payload1 = JSON.stringify(payload)
    const payloadUri = encodeURI(payload1)

    if (ws && [0, 1].indexOf(ws.readyState) > -1) {
      console.log('already connected', ws, new Date().toLocaleString())
      ws.onopen = () => {
        resolve(ws)
      }
    } else {
      console.log('new connection')
      ws = new WebSocket(`${config.websocket_url}?payload=${payloadUri}`)
      ws.onopen = () => {
        console.log('connected at:', new Date().toLocaleString())
        resolve(ws)
      }
      ws.onclose = (e) => {
        if (e.reason === 'Going away') {
          console.log(e.reason, new Date().toLocaleString())
          localStorage.setItem('wsDisconnected', 'true')
          localStorage.setItem('wsSubcriptionEnded', 'true')
          ws = null
        }
      }
      ws.onerror = (error) => reject(error)
    }
  })
}
export function connectWsEnterpriseLocation (payload) {
  return new Promise((resolve, reject) => {
    const payload1 = JSON.stringify(payload)
    const payloadUri = encodeURI(payload1)

    if (ws && ws.readyState === 3) {
      console.log('already connected', ws)
      ws = new WebSocket(`${config.websocket_url}?payload=${payloadUri}`)
      ws.onopen = () => {
        console.log('connected at:', new Date().toLocaleString())
        resolve(ws)
      }
    } else {
      ws.onerror = (error) => reject(error)
    }
  })
}
