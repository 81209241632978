import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@gositeinc/ui'

const Cell = ({ value, onChange, focus, onPaste, onBlur, onFocus }) => {
  const ref = useRef()

  useEffect(() => {
    if (focus) {
      ref.current.focus()
    }
  }, [focus])

  return (
    <div className={`d-flex align-items-start align-items-center border rounded`} style={{ padding: '7px 10px' }}>
      <input
        ref={ref}
        type="text"
        maxLength={1}
        className={`form-control bg-white flex-fill fz-24 bg-white border-sm-0 rounded-0 border-0 text-center font-weight-bold`}
        value={value}
        onChange={onChange}
        onPaste={onPaste}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </div>
  )
}

export const VerificationCodeInput = ({ size = 4, value, onChange }) => {
  const [focusIdx, setFocusIdx] = useState(0)

  const splited = value.split('')
  const codes = Array.from({ length: size }, (v, i) => splited[i] || '')
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: '16px'
      }}>
      {codes.map((code, idx) => (
        <Cell
          key={idx}
          value={code}
          focus={focusIdx === idx}
          onBlur={() => {
            if (focusIdx === idx) {
              setFocusIdx(-1)
            }
          }}
          onFocus={() => {
            setFocusIdx(idx)
          }}
          onChange={(event) => {
            const tmp = [...codes]
            tmp[idx] = event.target.value

            if (event.target.value) {
              console.log('setFocusId')
              setFocusIdx(Math.min(idx + 1, codes.length - 1))
            }

            onChange(tmp.join(''))
          }}
          onPaste={(event) => {
            const copiedText = (event.clipboardData.getData('Text') || '').substring(0, 4)
            onChange(copiedText)
            setFocusIdx(Math.min(copiedText.length - 1, size - 1))
            event.preventDefault()
          }}
        />
      ))}
    </Box>
  )
}
