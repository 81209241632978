import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import { Box, Container, Button, useTheme } from '@gositeinc/ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { StepText } from './StepText'
import { StepTitle, StepTitle2 } from './StepTitle'
import { FormLabel } from './FormLabel'
import _ from 'lodash'
import { segmentEventTrack } from '@helpers/segment'
import { numberMask } from '@helpers/inputmask'
import { VerificationCodeInput } from '@components/inputs/VerificationCodeInput'
import Icon from '@components/icons'
import config from '@config/index'
import notification from '@app/components/notification'

export const PhoneVerification = ({ step, onBack, onSubmitted }) => {
  const dispatch = useDispatch()
  const theme = useTheme()
  const [conditions, setConditions] = useState({
    showReCaptcha: false,
    noOfRetries: 0,
    isBackButtonEnabled: true,
    capthcaValue: ''
  })
  const [isResendCodeDisabled, setIsResendCodeDisabled] = useState(true)
  const [timer, setTimer] = useState(45)
  const me = useSelector((state) => state.auth.me)
  // const apiError = useSelector((state) => state.auth.apiError)
  const phone = _.get(me, 'contact_phone', '')
  const phoneNumber = phone.replace('+1', '')

  const { showReCaptcha, noOfRetries, isBackButtonEnabled, capthcaValue } = conditions

  // useEffect(() => {
  //   if (apiError !== null) {
  //     setConditions({
  //       ...conditions,
  //       noOfRetries: conditions.noOfRetries + 1
  //     })
  //   }
  // }, [apiError])

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }

    segmentEventTrack({
      event: 'Onboarding_Phone_Verification_Load',
      properties: {
        Onboarding_Version: 'v4'
      }
    })
  }, [])

  useEffect(() => {
    const totalSeconds = 45000 // 45 seconds delay to display Resend code feature
    setTimeout(() => {
      if (timer === 0) setIsResendCodeDisabled(false)
    }, totalSeconds)
  }, [isResendCodeDisabled])

  useEffect(() => {
    if (timer > 0) {
      const interval = setInterval(() => setTimer(timer - 1), 1000)
      return () => clearInterval(interval)
    }
  }, [timer])

  useEffect(() => {
    if (noOfRetries >= 2) {
      setConditions({
        ...conditions,
        showReCaptcha: true
      })
    }
    if (noOfRetries >= 3) {
      setConditions({
        ...conditions,
        isBackButtonEnabled: false
      })
    }
  }, [noOfRetries])

  useEffect(() => {
    if (!me.contact_phone) {
      return
    }
    dispatch.messenger.verifyMobile({ contact_phone: me.contact_phone, quickFadeout: true })
  }, [me.contact_phone])

  const schema = Yup.object().shape({
    code: Yup.string()
      .max(4, 'Verification code is a 4-digit code')
      .min(4, 'Verification code is a 4-digit code')
      .required('Verification code is a required field')
  })
  const initialValues = {
    code: ''
  }

  const onChangeRecaptche = (val) => {
    setConditions({
      ...conditions,
      capthcaValue: val
    })
  }

  return (
    <Container maxWidth="xs">
      <StepText mt="79px">{step}</StepText>
      <StepTitle mt="4px">Verify your phone number</StepTitle>
      <StepTitle2 mt="4px">Verification allows you to send review requests, messages and more!</StepTitle2>

      <Box className="text-center" mt="32px">
        Enter the 4-digit code we just sent to <strong>{numberMask(phoneNumber)}</strong>
      </Box>

      <Formik
        validateOnBlur
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        isInitialValid={schema.isValidSync(initialValues)}
        validationSchema={schema}
        onSubmit={async (values, { resetForm }) => {
          const res = await dispatch.auth.smsVerifyOtp({
            contact_phone: phone,
            otp: values.code,
            enableTwilio: true
          })

          if (noOfRetries >= 2 && !capthcaValue) {
            notification('error', 'Please complete the reCaptcha')
            return
          }

          if (!res) {
            resetForm()
            return
          }

          segmentEventTrack({
            event: 'Onboarding_Phone_Verification_Completed',
            properties: {
              Onboarding_Version: 'v4'
            }
          })

          onSubmitted()
        }}
        render={({ isValid, isSubmitting, values, setFieldValue }) => {
          return (
            <Form className="w-100">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  mt: '16px'
                }}>
                <Box sx={{ width: '100%' }}>
                  <FormLabel mb="10px">
                    VERIFICATION CODE<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>
                  <VerificationCodeInput
                    value={values.code}
                    onChange={(value) => {
                      setFieldValue('code', value)
                    }}
                  />
                </Box>
              </Box>
              {showReCaptcha
                ? <div className="col-12 pt-20">
                  <ReCAPTCHA
                    sitekey={config.googleReCAPTCHAKey}
                    onChange={(val) => onChangeRecaptche(val)}
                  />
                </div>
                : null
              }
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'flex-start',
                  mt: '56px',
                  gap: '16px'
                }}>
                {isBackButtonEnabled ? <Button
                  variant="outlined"
                  onClick={() => {
                    onBack()
                  }}>
                  BACK
                </Button> : <Box style={{ visibility: 'hidden' }} />}
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '8px'
                  }}
                >
                  <Button variant="contained" type="submit" disabled={!isValid || isSubmitting || (showReCaptcha && !capthcaValue)}>
                    CONTINUE
                    {isSubmitting && <Icon style={{ height: '20px', width: '20px', marginLeft: '10px' }} name='spinnerDark' />}
                  </Button>

                  <Button
                    variant="text"
                    sx={{
                      fontWeight: 'normal',
                      padding: '1px !important',
                      textTransform: 'none',
                      letterSpacing: 0
                    }}
                    onClick={() => {
                      segmentEventTrack({
                        event: 'Onboarding_Phone_Verification_Skipped',
                        properties: {
                          Onboarding_Version: 'v4'
                        }
                      })

                      onSubmitted()
                    }}>
                    I'll do it later!
                  </Button>
                </Box>
              </Box>
              {isBackButtonEnabled &&
                  (<Box
                    sx={{
                      display: 'flex',
                      mt: '10px',
                      alignItems: 'center',
                      gap: '4px',
                      'svg path': {
                        fill: theme.palette.primary.main
                      }
                    }}>
                    <Icon
                      name="undo"
                      style={{
                        width: '13px',
                        height: '13px'
                      }}
                    />
                    <div>Resend code{timer > 0 ? ` in ${timer} seconds.` : '?'}</div>
                    <Button
                      disabled={isResendCodeDisabled && timer !== 0}
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        fontWeight: 'normal',
                        padding: '1px !important'
                      }}
                      onClick={() => {
                        dispatch.messenger.verifyMobile({ contact_phone: phone })
                        setIsResendCodeDisabled(true)
                        setTimer(45)
                        setConditions({
                          ...conditions,
                          noOfRetries: conditions.noOfRetries + 1
                        })
                      }}>
                  Text Me
                    </Button>
                    <Button
                      disabled={isResendCodeDisabled && timer !== 0}
                      variant="text"
                      sx={{
                        textTransform: 'none',
                        fontWeight: 'normal',
                        padding: '1px !important'
                      }}
                      onClick={() => {
                        dispatch.messenger.onRequestOtpOnCall({ contact_phone: phone })
                        setIsResendCodeDisabled(true)
                        setTimer(45)
                        setConditions({
                          ...conditions,
                          noOfRetries: conditions.noOfRetries + 1
                        })
                      }}>
                  Call Me
                    </Button>
                  </Box>)
              }
            </Form>
          )
        }}
      />
    </Container>
  )
}
