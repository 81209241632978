import { Typography, styled } from '@gositeinc/ui'

export const StepText = styled(Typography)(
  () => `
  font-family: 'Open Sans Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 140%;
  text-align: center;
  color: #0357FF;
`
)
