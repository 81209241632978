import React, { Component } from 'react'
import Popup from '@components/popups/basic-modal'
import style from '../index.module.scss'
import Table from '@components/table'
import Icons from '@components/icons'
import notification from '@components/notification'
import i18next from 'i18next'

export default class extends Component {
  t = i18next.t
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      contacts: [],
      selectedContacts: [],
      group: '',
      search: ''
    }
  }
  async showPopup(contacts) {
    this.setState({ open: true, contacts: contacts, selectedContacts: contacts })

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
  columns = [
    {
      title: this.t('contacts_hub.name'),
      key: 'name',
      dataIndex: 'firstname',
      render: (item) => (
        <div className="table-link td-content">
          <div className="no-table-th">{this.t('contacts_hub.name')}</div>
          <div className="td-label">
            {item.firstname} {item.lastname}
          </div>
        </div>
      )
    },
    {
      title: this.t('contacts_hub.email'),
      key: 'email',
      dataIndex: 'email',
      render: (item) => (
        <div className="table-link td-content">
          <div className="no-table-th">{this.t('contacts_hub.email')}</div>
          <div className="td-label">{item.email}</div>
        </div>
      )
    },
    {
      title: this.t('contacts_hub.phone'),
      key: 'mobile',
      dataIndex: 'mobile',
      render: (item) => (
        <div className="table-link td-content">
          <div className="no-table-th">{this.t('contacts_hub.phone')}</div>
          <div className="td-label">{item.mobile ? item.mobile : '-'}</div>
        </div>
      )
    }
  ]

  handleClose() {
    if (this.resolve) {
      this.resolve(this.state.selected)
    }
    this.setState({ open: false, selectedContacts: [] })
  }
  async onItemSelect(selected) {
    const checkedItems = []
    selected.forEach((item) => {
      const obj = this.state.contacts.find((x) => x[this.props.defaultKeys] === item)
      if (obj) {
        checkedItems.push(obj)
      }
    })
    this.setState({ checkedItems })
  }
  searchData(value) {
    const selectedContacts = this.state.contacts.filter((opt) => {
      return (
        (opt.firstname && opt.firstname.toLowerCase().includes(value.toLowerCase())) ||
        (opt.lastname && opt.lastname.toLowerCase().includes(value.toLowerCase())) ||
        (opt.email && opt.email.toLowerCase().includes(value.toLowerCase()))
      )
    })
    this.setState({ selectedContacts })
  }
  handleSubmit() {
    if (this.state.checkedItems && this.state.checkedItems.length) {
      this.props.onSubmit(this.state.checkedItems)
      this.setState({ open: false, selectedContacts: [] })
    } else {
      notification('error', this.t('contacts_hub.please-select-at-least-one-contact'))
    }
  }
  render() {
    const t = this.t
    return (
      <Popup
        open={this.state.open}
        title={t('contacts_hub.select-contacts-to-import')}
        onCancel={this.handleClose.bind(this)}
        type="large"
        hideFooter={true}
        overrideForm={true}
        modalBackdropClicked={this.handleClose.bind(this)}>
        <div>
          <button
            onClick={this.handleClose.bind(this)}
            className={'close custom-modal-close-button remove-modal-close'}
            id="contactTogroup_btnClose">
            <span aria-hidden="true">×</span>
          </button>
          {this.state.contacts && this.state.contacts.length ? (
            <div className="form-group mt-30 mb-30">
              <div className={`b-search-field mb-lg-0 ${style['b-modal-search-field']} `}>
                <input
                  type="text"
                  id="contactTogroup_selSearch"
                  onChange={(e) => this.searchData(e.target.value)}
                  className="form-control"
                  name="txtSearchName"
                  placeholder={t('contacts_hub.search-contact')}
                />
                <div className={`b-search-field mb-lg-0 ${style['search-icon']}`}>
                  <img src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/search-icon.svg" alt="" />
                </div>
              </div>
            </div>
          ) : null}
          {this.state.selectedContacts && !this.state.selectedContacts.length ? (
            <div>
              <label>{t('contacts_hub.no-contacts-found')}</label>
            </div>
          ) : null}

          {this.state.selectedContacts && this.state.selectedContacts.length ? (
            <div className="imported-contacts-wrapper-popup mb-30" id="sectionContactSelectBoxes">
              <Table
                ref={(ref) => (this.table = ref)}
                columns={this.columns}
                dataSource={this.state.selectedContacts}
                onSelect={this.onItemSelect.bind(this)}
                loading={this.props.loading}
                className="contact-list-table"
                tableId="tableContactImport"
                defaultKeys={this.props.defaultKeys}
              />
            </div>
          ) : null}
          <footer className="d-block d-flex mt-10">
            <button
              type="submit"
              onClick={this.handleSubmit.bind(this)}
              className="btn btn-primary btn-xl border-0 flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20"
              id="frmAddContact_btnAdd"
              name="btnAddContact">
              {t('contacts_hub.import')}
              {this.state.submitSpinner && (
                <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                  <Icons name="spinner" />
                </i>
              )}
            </button>
            <button
              id="frmAddContact_btnCancel"
              type="reset"
              onClick={this.handleClose.bind(this)}
              className="btn btn-xl flex-grow-0 cancel-btn">
              {t('contacts_hub.cancel-0')}
            </button>
          </footer>
        </div>
      </Popup>
    )
  }
}
