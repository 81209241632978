import React, { PureComponent } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import Popup from '@components/popups/basic-modal'
import * as Yup from 'yup'
import Icon from '@components/icons'
import style from '../index.module.scss'
import { nFormatter } from '@helpers/utility'
import notification from '@components/notification'
import { withTranslation } from 'react-i18next'

class AddContact extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      skipStatus: false
    }
  }
  closeModal() {
    this.props.closeContactModal()
  }
  sendPaymentLink() {
    const res = {
      message: '',
      requested_amount: this.props.amount,
      requested_through: {
        email: false,
        sms: false
      }
    }
    if (this.props.contactType && this.props.contactType === 'mobile') {
      res.customer_number = this.props.contactVal
      res.requested_through.sms = true
    }
    if (this.props.contactType && this.props.contactType === 'email') {
      res.customer_email = this.props.contactVal
      res.requested_through.email = true
    }
    if (!res.requested_through.email && res.requested_through.sms) {
      const me = this.props.me || {}
      const hasTwilioAssigned = me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length
      const isTrialUser = me.role_id && me.role_id.role_name === 'trial'
      const twilioEnabled = hasTwilioAssigned || isTrialUser

      if (!twilioEnabled) {
        notification('error', this.props.t('payments.twilio-number-not-configured'))
        return
      }
    }
    this.props.sendGetPaidLink(res)
  }
  skipSaveContact() {
    this.setState({ skipStatus: true })
  }
  handleCancel(values, { resetForm }) {
    this.props.closeContactModal()
  }
  async handleSubmit(values, { resetForm }) {
    if (this.props.contactType && this.props.contactType === 'email') {
      values.email = this.props.contactVal
    } else if (this.props.contactType && this.props.contactType === 'mobile') {
      values.mobile = this.props.contactVal
    }
    await this.props.saveContactInfo(values)
    await this.props.closeContactModal()
  }
  render() {
    const t = this.props.t
    return (
      <Popup
        open={this.props.open}
        title={
          !this.state.skipStatus
            ? t('payments.save-this-contact-information-for-future-use')
            : t('payments.send-a-payment-link-for') +
              nFormatter(parseFloat(this.props.amount)) +
              t('payments.to') +
              this.props.contactVal +
              '?'
        }
        hideFooter={false}
        type="basic"
        modalBackdropClicked={() => console.log(null)}>
        {!this.state.skipStatus ? (
          <Formik
            validateOnChange={false}
            validateOnBlur={false}
            initialValues={{
              first_name: '',
              last_name: ''
            }}
            validationSchema={Yup.object().shape({
              first_name: Yup.string().required(t('payments.required')),
              last_name: Yup.string().required(t('payments.required'))
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form name="frmSentPayemntLink" className={`pt-20 ${style['get-paid-form']}`}>
                  <button
                    name="btngetPaidCloseModal"
                    type="reset"
                    className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown">
                        <label className="label-text">
                          {t('payments.first-name')} <span className="star">*</span>
                        </label>
                        <ErrorMessage name="first_name" component="span" className="form-error" />
                        <Field
                          className="form-control form-control-lg mb-20"
                          type="text"
                          placeholder={t('payments.enter-first-name')}
                          name="first_name"
                          value={values.first_name}
                        />
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown">
                        <label className="label-text">
                          {t('payments.last-name')} <span className="star">*</span>
                        </label>
                        <ErrorMessage name="last_name" component="span" className="form-error" />
                        <Field
                          className="form-control form-control-lg mb-20"
                          type="text"
                          placeholder={t('payments.enter-last-name')}
                          name="last_name"
                          value={values.last_name}
                        />
                      </div>
                    </div>
                  </div>
                  <footer className="d-block d-sm-flex">
                    <button
                      className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block'}
                      type="submit"
                      disabled={this.props.contactLoader}>
                      {t('payments.save-contact')}
                      {this.state.contactLoader && (
                        <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                          <Icon name="spinner" />
                        </i>
                      )}
                    </button>
                    <button
                      type="reset"
                      onClick={this.skipSaveContact.bind(this)}
                      className={'btn btn-xl m-0 cancel-btn custom-btn-width'}>
                      SKIP
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        ) : (
          <div className="mt-30">
            <footer className="d-block d-sm-flex">
              <button
                className={'btn btn-primary border-0 btn-xl mb-10 mb-sm-0 mr-20 btn-block'}
                id="frmBtnSendPaymentLink"
                onClick={this.sendPaymentLink.bind(this)}
                disabled={this.props.paymentLinkLoader}
                type="submit">
                {t('payments.send-payment-link-0')}
                {this.props.paymentLinkLoader && (
                  <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                    <Icon name="spinner" />
                  </i>
                )}
              </button>
              <button
                type="reset"
                onClick={this.closeModal.bind(this)}
                className={'btn btn-xl m-0 cancel-btn custom-btn-width'}>
                {t('payments.go-back')}
              </button>
            </footer>
          </div>
        )}
      </Popup>
    )
  }
}

export default withTranslation(null, { withRef: true })(AddContact)
