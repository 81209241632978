
import React,{Component} from "react";
import Popup from "./basic-modal";
import config from "@config/index";
import "./referralrock.scss"

export default class extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        };
    }

    closeModal() {
        this.props.onCancel();
    }
    getSource(){
        return  `https://gosite.referralrock.com/access/?programidentifier=${config.referralrock}&view=iframe&firstname=${this.props.me.first_name}&lastname=${this.props.me.last_name}&email=${this.props.me.user_email}`
    }
    hideSpinner(){
        this.setState({ loading: false  });
    }
    render() {
        return (
            <Popup
                id="referralrock"
                title={"Give $100, Get $100"}
                open={this.props.open}
                hideHeader={false}
                hideFooter={true}
                type="medium"
                onCancel={() => this.setState({ open: false })}
                modalBackdropClicked={this.closeModal.bind(this)}
            >
                <button type="reset" onClick={this.closeModal.bind(this)} className="close d-flex justify-content-center custom-modal-close-button ">
                    <span aria-hidden="true"></span>
                </button>
                <div className="border d-flex align-items-center justify-content-center flex-column position-relative mt-20">
                    <iframe title="GoSite-Referralrock"   onLoad={this.hideSpinner.bind(this)} src={this.getSource()}  data-hj-allow-iframe=''></iframe>
                    {this.state.loading ?  <img className="iframe-loader" src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="" /> : null }
                </div>
            </Popup>
        )
    }
}

