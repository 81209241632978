import React from 'react'
import { useSelector } from 'react-redux'
import config from '@config/index'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

export function withElements (options) {
  const { key, stripe, ...elementsOptions } = options || {}

  return function (WrappedComponent) {
    const displayName = WrappedComponent.displayName || WrappedComponent.name || 'Component'

    const ComponentWithElements = (props) => {
      const accountSettings = useSelector((state) => {
        return ((state.auth || {}).me || {}).account_settings || {}
      })
      const { payment: {
        merchant_id: merchantId
      } = {} } = accountSettings || {}

      const [stripeObject, setStripeObject] = React.useState(null)

      // This function will re-run if the merchantId prop changes.
      React.useEffect(() => {
        const fetchStripeObject = async () => {
          // If there is no merchantId, do not run the loadStripe function.
          if (merchantId) {
            const apiKey = config.stripe_pk
            const res = await loadStripe(
              apiKey,
              {
                stripeAccount: merchantId
              }
            )
            // When we have got the Stripe object, pass it into our useState.
            setStripeObject(res)
          } else {
            setStripeObject(null)
          }
        }
        fetchStripeObject()
      }, [accountSettings])

      return (
        <Elements key={merchantId} stripe={stripeObject} options={elementsOptions}>
          <WrappedComponent {...props} />
        </Elements>
      )
    }

    ComponentWithElements.displayName = `withStripeElements(${displayName})`

    return ComponentWithElements
  }
}
