import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import i18n from '@/i18n'

export default {
  state: {},
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onSuccessWithMessage(state, data, message) {
      const msg = message || (data.message ? data.message : '')
      notification('success', capitalize(msg))
      return {
        ...state,
        loading: false
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : i18n.t('common.please-try-again-later')
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onGetEntContactsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        entContacts: data
      }
    },
    onGetLocationGroupsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        locationGroups: data
      }
    },
    onGroupDetailsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        groupDetail: data
      }
    },
    hasentFilterApplied(state, data) {
      let contactenSearch = false
      if (data.group_id !== undefined || data.source !== undefined) {
        contactenSearch = true
      }
      return {
        ...state,
        loading: false,
        entfilter: data,
        contactenSearch
      }
    },
    onSavePrivateContactSuccess(state, data) {
      return {
        ...state,
        paymentLoading: false,
        privateContact: data
      }
    }
  },
  effects: {
    async getEntContacts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getEntContacts(payload)
        this.hasentFilterApplied({ group_id: payload.group_id, source: payload.source })
        this.onGetEntContactsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getLocationGroups(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getLocationGroups(payload)
        this.onGetLocationGroupsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async addGroup(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.addGroup(payload)
        this.onSuccess(res, i18n.t('common.group-created-successfully'))
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async groupDetails(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.groupDetails(payload)
        this.onGroupDetailsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async addContactToGroup(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.addContact(payload)
        this.onSuccess(res, i18n.t('contacts_hub.contact-added-to-group-successfully'))
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async removeContact(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeContact(payload)
        this.onSuccessWithMessage(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async updateGroup(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateGroup(payload)
        this.onSuccess(res, i18n.t('contacts_hub.group-has-been-updated-successfully'))
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async sendMessageToContactsEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendMessageToContactsEnterprise(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.message-sent-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async savePrivateContact(payload) {
      this.onRequest()
      try {
        const res = await service.savePrivateContact(payload)
        this.onSavePrivateContactSuccess(res)
        return res
      } catch (e) {
        console.log(e)
      }
    }
  }
}
