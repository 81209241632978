import React from 'react'
import { Box } from '@gositeinc/ui'
import { InstantStepItem, InstantStepTab } from '@components/instantStepTab'

export const Header = ({ steps, currentStep }) => {
  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        padding: '16px 30px',
        justifyContent: 'space-between'
      }}>
      <Box
        component="img"
        alt="gosite-logo"
        src="https://d1hz0qcu1muexe.cloudfront.net/uploads/58db9ace0736dc11398ff005/original/ace9526e-f557-4d46-b6aa-2a6b3bc1e1c0.png"
        sx={{
          width: '96px',
          height: '30px',
          objectFit: 'contain'
        }}
      />

      <Box>
        <InstantStepTab highlightConnector>
          {steps.map((step, idx) => (
            <InstantStepItem key={idx} highlight={currentStep >= idx}>
              {step}
            </InstantStepItem>
          ))}
        </InstantStepTab>
      </Box>

      <Box
        component="img"
        alt="gosite-logo"
        src="https://d1hz0qcu1muexe.cloudfront.net/uploads/58db9ace0736dc11398ff005/original/ace9526e-f557-4d46-b6aa-2a6b3bc1e1c0.png"
        sx={{
          opacity: 0,
          width: '96px',
          height: '30px',
          objectFit: 'contain'
        }}
      />
    </Box>
  )
}
