import { createModel } from '@rematch/core'
import { RootModel } from '../../models'
import { getFirstExperience, postFirstExperience, getPrescreenOptions, setPrescreenOptions } from './service'
import { FirstExperienceData, Status, preScreenData } from './types'

export const firstExperience = createModel<RootModel>()({
  state: { status: 'uninitialized' } as FirstExperienceData,
  reducers: {
    setFirstExperienceStatus (state, payload: FirstExperienceData) {
      return { ...state, ...payload }
    }
  },
  effects: (dispatch) => ({
    getFirstExperienceStatus: async (_, rootState) => {
      try {
        const data = await getFirstExperience()
        dispatch.firstExperience.setFirstExperienceStatus(data)
        return data
      } catch (error) {
        console.error(error)
      }
    },
    postFirstExperienceStatus: async (status: Status) => {
      try {
        const data = await postFirstExperience(status)
        dispatch.firstExperience.setFirstExperienceStatus(data)
      } catch (error) {
        console.error(error)
      }
    },
    getPrescreenOptionsList: async () => {
      try {
        const data = await getPrescreenOptions()
        dispatch.firstExperience.setFirstExperienceStatus(data)
        return data
      } catch (error) {
        console.log(error)
      }
    },
    setPrescreenOptionsList: async (payoad: preScreenData) => {
      try {
        const data = await setPrescreenOptions(payoad)
        dispatch.firstExperience.setFirstExperienceStatus(data)
      } catch (error) {
        console.log(error)
      }
    }
  })
})
