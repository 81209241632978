import * as React from 'react'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { ActionMeta, OptionTypeBase, OptionsType, GroupTypeBase } from 'react-select/src/types.js'
import { components, FormatOptionLabelMeta, ValueContainerProps } from 'react-select'
import * as _ from 'lodash'

interface ContactCreatableSelectProps {
  id: string
  placeholder: string
  onChange: (value: OptionsType<any>, action: ActionMeta<OptionTypeBase>) => void
  loadOptions: (inputValue: string) => Promise<readonly (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[]>
  value: OptionTypeBase | readonly OptionTypeBase[] | null
  isValidNewOption: (
    inputValue: string,
    value: OptionsType<OptionTypeBase>,
    options: readonly (OptionTypeBase | GroupTypeBase<OptionTypeBase>)[]
  ) => boolean
}

export class ContactCreatableSelect extends React.Component<ContactCreatableSelectProps> {
  throttleLoadOptions = _.throttle(this.props.loadOptions, 600)

  customOptions = (option: OptionTypeBase, labelMeta: FormatOptionLabelMeta<OptionTypeBase, true>) => {
    if (labelMeta.context === 'value') {
      return <div key={option.index}>{option.value}</div>
    }
    if (labelMeta.context === 'menu') {
      return (
        <>
          <div>{option.label}</div>
          <div>{option.subLabel || ''}</div>
        </>
      )
    }
  }

  ValueContainer = ({
    children,
    ...props
  }: ValueContainerProps<OptionTypeBase, true, GroupTypeBase<OptionTypeBase>>) => {
    return <components.ValueContainer {...props}>{children}</components.ValueContainer>
  }

  render() {
    return (
      <AsyncCreatableSelect
        id={this.props.id}
        isClearable={false}
        isMulti
        className="dropdown-select"
        classNamePrefix="dropdownSelect"
        openMenuOnClick={false}
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        loadOptions={this.throttleLoadOptions}
        formatOptionLabel={this.customOptions}
        value={this.props.value}
        components={{ ValueContainer: this.ValueContainer }}
        formatCreateLabel={(userInput) => `${userInput}(new)`}
        isValidNewOption={this.props.isValidNewOption}
      />
    )
  }
}
