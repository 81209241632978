import { api, apiV2, catchHandler, meApiCatchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { notificationActivityTypes, notifiEnterpriseActTypes } from '@config/query-attributes'

export function login(payload) {
  return api()
    .post(config.routes.login, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function googleAuth(payload) {
  return api(true)
    .post(config.routes.googleAuth, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function facebookAuth(payload) {
  return api(true)
    .post(config.routes.facebookAuth, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getSavedFilters(payload) {
  return api()
    .get(`${config.routes.getFilters}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createManager(payload) {
  return api()
    .post(config.routes.createManager, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function manageUsers(payload) {
  return api()
    .post(`${config.routes.user}/${payload.id}/manage-users`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function locale() {
  return (
    api()
      .get('/locale')
      // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
      .then((res) => res.data)
      .catch(meApiCatchHandler)
  )
}
export function patchLocale(locale) {
  return (
    api()
      .put('/locale', {
        locale
      })
      // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
      .then((res) => res.data)
      .catch(catchHandler)
  )
}
export function me() {
  return (
    api()
      .get(config.routes.me)
      // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
      .then((res) => res.data)
      .catch(meApiCatchHandler)
  )
}
export function validateDomain() {
  return (
    api()
      .get(config.routes.validateDomain)
      // .get(`${config.amazonGatewayUrl}${config.routes.validateDomain}`)
      .then((res) => res.data)
      .catch(catchHandler)
  )
}
export function signup(payload) {
  return api()
    .post(config.routes.signup, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getNotifications(payload) {
  return api()
    .get(`${config.routes.notifications}?${objToUrlParams(payload)}&activity_type=${notificationActivityTypes}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function readNotification(payload) {
  let url = ''
  if (payload && payload.id && payload.activity) {
    url = `${config.routes.notifications}/${payload.id}/read?activity_type=${payload.activity.join(',')}`
  } else if (payload && payload.id) {
    url = `${config.routes.notifications}/${payload.id}/read`
  } else {
    url = `${config.routes.notifications}/read`
  }

  return api()
    .post(url)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getNotificationCount(payload) {
  let url = ''
  if (payload && payload.activity && payload.role && payload.role === 'partner') {
    url = `${config.routes.notifications}/count/partner?activity_type=${payload.activity.join(',')}`
  } else if (payload && payload.activity) {
    url = `${config.routes.notifications}/count?activity_type=activity_type=${payload.activity.join(',')}`
  } else {
    url = `${config.routes.notifications}/count`
  }
  return api()
    .get(url)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function logout() {
  return api()
    .get(config.routes.logout)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendFeedback(payload) {
  return api()
    .post(config.routes.sendFeedback, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function quickBookAuth(payload) {
  return api(true)
    .post(config.routes.quickBookAuth, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function quickBookAuthUri(payload) {
  return api(true)
    .get(config.routes.quickBookAuthUri, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function thumbtackAuth(payload) {
  return apiV2()
    .get(`${config.routes.thumbtackAuthV2}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function thumbtackAuthUri(payload) {
  return apiV2()
    .get(config.routes.thumbtackAuthUriV2, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function thumbtackConnect(payload) {
  return api()
    .get(`${config.routes.thumbtackConnect}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function magicLogin(payload) {
  return api()
    .post(config.routes.magicLogin, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onResendtOtp(payload) {
  let url = config.routes.resendOtp
  if (payload.query) {
    url = `${config.routes.resendOtp}?${objToUrlParams(payload.query)}`
  }
  return api()
    .post(url, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function verifyOtp(payload) {
  return api()
    .post(`${config.routes.verifyOtp}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function smsOTPVerification(payload) {
  return api()
    .post(`${config.routes.otpVerify}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function resetPassword(payload) {
  return api()
    .post(`${config.routes.resetUserAccount}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function regenerateOtp(payload) {
  return api()
    .post(`${config.routes.regenerateOtp}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function regenerateOtpCall(payload) {
  return api()
    .post(`${config.routes.regenerateOtpCall}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendOtp(payload) {
  return api()
    .post(`${config.routes.sendOtp}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getEnterpriseLocations(payload) {
  return api()
    .get(config.routes.location)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getIsExistingYextCustomer() {
  return api()
    .get(config.routes.isExistingYextCustomer)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resetPasswordStatus(payload) {
  return api()
    .post(`${config.routes.resetPasswordStatus}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resetPasswords(payload) {
  return api()
    .post(`${config.routes.resetPasswords}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function verifyEmail(payload) {
  return api()
    .post(`${config.routes.verifyEmail}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resendMagicLink(payload) {
  return api()
    .post(`${config.routes.resendMagicLink}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getFeedbackDetails(id) {
  return api()
    .get(`${config.routes.getFeedbackDetails}?pid=${id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendPaymentFeedback(payload) {
  return api()
    .get(`${config.routes.sendPaymentFeedback}?pid=${payload.pid}&rating=${payload.rate}&ismail=false`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getFeedbackSettings(id) {
  return api()
    .get(`${config.routes.getFeedbackSettings}?pid=${id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function trackPaymenturl(trackid) {
  return api()
    .post(`${config.routes.trackPaymenturl}/${trackid}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendFeedbackComment(payload) {
  return api()
    .post(`${config.routes.sendFeedbackComment}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getFeedbackStatus(payload) {
  return api()
    .post(`${config.routes.feedbackStatus}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCommonFeedbackStatus(payload) {
  return api()
    .post(`${config.routes.commonFeedbackStatus}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getReviewSettings(payload) {
  return api()
    .get(`${config.routes.reviewSettings}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendReviewFeedback(payload) {
  return api()
    .post(`${config.routes.sendReviewFeedback}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getThemeSettings(payload) {
  return api()
    .get(config.routes.themeSettings)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getEnterpriseNotifications(payload) {
  return api()
    .get(`${config.routes.notifications}/partner?${objToUrlParams(payload)}&activity_type=${notifiEnterpriseActTypes}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendPaymentSuccessFeedback(payload) {
  return api()
    .post(`${config.routes.sendPaymentFeedback}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getReferralAppProperties() {
  return api()
    .get(config.routes.referralApps)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getTT_Token() {
  return api()
    .get(config.routes.referralApps)
    .then((res) => res.config.headers)
    .catch(catchHandler)
}
export function getSettingsData(payload) {
  return api()
    .get(`${config.routes.getsettings}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getChargebeeSubscription(payload) {
  return api()
    .get(`users/${config.routes.chargeBeesubscription}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSubscription(payload) {
  return api()
    .get(config.routes.chargeBeesubscription)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPricingLimits() {
  return api()
    .get(config.routes.pricingLimits)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createHubspotContact(payload) {
  console.log('createHubspotContact', payload, config.routes.hubspotContatCreation)
  return api()
    .post(`${config.routes.hubspotContatCreation}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateHubspotContact(payload) {
  return api()
    .post(`${config.routes.hubspotContatUpdate}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
