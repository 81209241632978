import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function getEntPlacementLeaderboard(payload) {
  return api()
    .get(config.routes.enterpricePlacementLeaderboard, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getInsightsForSearch(payload) {
  return api()
    .get(config.routes.insights, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getInsightsForViews(payload) {
  return api()
    .get(config.routes.insights, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getInsightsForActions(payload) {
  return api()
    .get(config.routes.insights, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getInsightsForPhones(payload) {
  return api()
    .get(config.routes.insights, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function listPresence(payload) {
  return api()
    .get(config.routes.listPresence, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchEnterpriseQuestions(payload) {
  return api()
    .get(`${config.routes.fetchQuestions}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendAnswerEnterprise(payload) {
  return api()
    .post(
      `${config.routes.fetchQuestions}/${payload.id}/answers?${objToUrlParams(payload.queryParams)}`,
      payload.payload
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function UpdateAnswer(payload) {
  return api()
    .put(
      `${config.routes.fetchQuestions}/${payload.questionId}/answers/${payload.answerId}?${objToUrlParams(
        payload.queryParams
      )}`,
      payload.payload
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteAnswerEnterprise(payload) {
  return api()
    .delete(
      `${config.routes.fetchQuestions}/${payload.questionId}/answers/${payload.answerId}?${objToUrlParams(
        payload.query
      )}`
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteQuestionEnterprise(payload) {
  return api()
    .delete(`${config.routes.fetchQuestions}/${payload.id}?${objToUrlParams(payload.query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function listAdminPosts(payload) {
  return api()
    .get(config.routes.listAdminPosts, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createAdminPost(payload) {
  return api()
    .post(config.routes.createAdminPost, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getAdminPostDetails(payload) {
  return api()
    .get(`${config.routes.adminPostDetails}/${payload.id}`, { params: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addQuestionEnterprise(payload) {
  return api()
    .post(`${config.routes.questions}?${objToUrlParams(payload.query)}`, payload.payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateQuestionEnterprise(payload) {
  return api()
    .put(`${config.routes.fetchQuestions}/${payload.id}?${objToUrlParams(payload.query)}`, payload.payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getConfigFlag(payload) {
  return api()
    .post(`${config.routes.getConfigFlag}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deletePost(payload) {
  return api()
    .post(config.routes.deletePostsAdmin, payload, { params: { customers: payload._user_id } })
    .then((res) => res.data)
    .catch(catchHandler)
}
