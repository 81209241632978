/* eslint-disable camelcase */

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import config from '@config/index'
import { getCookie, isCognitoUser } from '@helpers/utility'
import { objToUrlParams } from '@helpers/index'
import { segmentEventTrack } from '@helpers/segment'
import { useTranslation } from 'react-i18next'

// const CARD_CONNECT = 'cardconnect'
const STRIPE = 'stripe'
const PAYMENT_PRODUCT_LANDING_PAGE = 'payments_product_landingpage'
const INVOICE_TRANSACTION_LIST_BANNER = 'invoice_transaction_list_banner'

function getRouteToPaymentMethodButton({ className = '', me = '', trackEvent = '', customStyle = {} }) {
  const { t } = useTranslation()
  const accountSettings = useSelector((state) => state.accountsettings)
  const auth = useSelector((state) => state.auth)
  const loaderStatus = useSelector((state) => state.payment.paymentRedirectionLoader)
  const paymentType =
    accountSettings && accountSettings.accountsettings ? accountSettings.accountsettings.payment_type : ''
  const user_id = accountSettings && accountSettings.accountsettings ? accountSettings.accountsettings.user_id : ''
  const active_accounts = (auth && auth.user && auth.user.total_accounts) || '1'

  const dispatch = useDispatch()

  const redirectToPayment = async () => {
    changeLoaderStatus(true)
    if (paymentType) {
      trackActivatePayments(paymentType)
      // need for future use as of now by default we are redirecting to strip depend upon configcat setting
      // if (paymentType === STRIPE && isStripePocLaunchOn) {
      if (paymentType === STRIPE) {
        redirectToStripe()
      } else {
        redirectToCardConnect() // (paymentType === CARD_CONNECT)
      }
    }
  }
  const redirectToStripe = async () => {
    const category = me && me.primaryCategories && me.primaryCategories.category_name
    const cookie = await getCookie()
    const url = config.paymentAppUrl
    const obj = {
      t: cookie.token,
      s: cookie.status,
      r: cookie.role,
      uuid: cookie.user_unique_id,
      source: 'home',
      category,
      coming_from: 'stripe',
      user_id: user_id,
      active_accounts: active_accounts,
      isMultiLocation: cookie.isMultiLocation,
      total_accounts: active_accounts
    }
    if (isCognitoUser()) {
      obj.isAwsCognitoUser = true
    }
    changeLoaderStatus(false)
    window.location = `${url}/redirect/?${objToUrlParams(obj)}`
  }

  const redirectToCardConnect = async () => {
    const category = me && me.primaryCategories && me.primaryCategories.category_name
    // if (category) {
    const cookie = await getCookie()
    const url = config.paymentAppUrl
    const obj = {
      t: cookie.token,
      s: cookie.status,
      r: cookie.role,
      uuid: cookie.user_unique_id,
      source: 'home',
      category,
      coming_from: 'cc',
      user_id: user_id,
      active_accounts: active_accounts,
      isMultiLocation: cookie.isMultiLocation,
      total_accounts: active_accounts
    }
    if (isCognitoUser()) {
      obj.isAwsCognitoUser = true
    }
    changeLoaderStatus(false)
    window.location = `${url}/redirect/?${objToUrlParams(obj)}`
    // }
  }
  const changeLoaderStatus = (flag) => {
    setTimeout(() => {
      dispatch.payment.setPaymentRedirectionLoaderStatus(flag)
    }, 1000)
  }
  const trackActivatePayments = (paymentType) => {
    if (me && trackEvent) {
      const { _id, user_unique_id, company_id, bussiness_name, role_id, created_at, primaryCategories, user_email } = me
      const data = {
        event: `gosite_${trackEvent}_activate`,
        properties: {
          user_id: _id,
          user_unique_id: user_unique_id.toString(),
          company_id: company_id,
          business_name: bussiness_name,
          role: role_id.role_name,
          account_creation_date: created_at,
          business_category: primaryCategories && primaryCategories.category_name,
          business_email_address: user_email,
          platform: 'Web',
          payment_type: paymentType
        }
      }
      if (trackEvent === PAYMENT_PRODUCT_LANDING_PAGE) {
        data.properties.page_section = INVOICE_TRANSACTION_LIST_BANNER
      }
      segmentEventTrack(data)
    }
  }
  return (
    <>
      {loaderStatus && (
        <div>
          <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="" />
        </div>
      )}
      <button
        type="button"
        className={className}
        style={customStyle}
        onClick={redirectToPayment}
        disabled={loaderStatus}>
        {t('payments.activate-payments')}
      </button>
    </>
  )
}
export default getRouteToPaymentMethodButton
