import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { getSocialConnections } from '../manage-my-business/service'
// import moment from "moment"

// client recomended sorting order

export default {
  state: {
    socialPosts: {}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        placementLoading: true,
        apiError: false,
        apiStatus: ''
      }
    },
    onRequestAnswer(state) {
      return {
        ...state
      }
    },
    onfetchConfigFlag(state, achPaymentFeatureFlag) {
      return {
        ...state,
        achPaymentFeatureFlag: achPaymentFeatureFlag
      }
    },
    onError(state, data) {
      const err =
        data && data.data && data.data.status && data.data.status.messageDescription
          ? data.data.status.messageDescription
          : data && data.status && data.status.messageDescription
          ? data.status.messageDescription
          : data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : 'Please try again later'
      notification('error', capitalize(err))
      const apiStatus = data && data.status ? data.status : ''
      return {
        ...state,
        placementLoading: false,
        apiError: true,
        apiStatus: apiStatus
      }
    },
    onLocationError(state, data) {
      return {
        ...state,
        placementLoading: false,
        apiError: true
      }
    },
    onGetSocialStatusSuccess(state, data) {
      return {
        ...state,
        facebookStatus: data && data.facebook,
        googleStatus: data && data.google,
        socialStatus: data,
        placementLoading: false
      }
    },
    getPlacementListSuccess(state, data) {
      return {
        ...state,
        placementList: data && data.data,
        placementLoading: false
      }
    },
    getLocationSuccess(state, data) {
      return {
        ...state,
        locations: data[0],
        placementLoading: false
      }
    },
    onGetPresenceLocationSuccess(state, data) {
      let locationDetails
      let presenceStatuses
      if (data && data.location) {
        locationDetails = data.location
      }
      if (data.statuses && data.statuses.length) {
        presenceStatuses = data.statuses
      }
      return {
        ...state,
        presenceLocation: data,
        locationDetails: locationDetails,
        presenceStatuses: presenceStatuses,
        placementLoading: false
      }
    },
    addressViewSuccess(state, data, suppressAddress) {
      // let { show ,message}=data
      //  if(show)
      //     notification('success',message ? message:'')
      notification(
        'success',
        suppressAddress === 'true' ? 'Business settings updated successfully' : 'Business settings updated successfully'
      )
      return {
        ...state,
        placementLoading: false
      }
    },
    onGetSocialPostSuccess(state, data) {
      const socialPosts = data.docs.sort((a, b) => {
        return new Date(b.createTime) - new Date(a.createTime)
      })
      return {
        ...state,
        socialPosts: socialPosts,
        placementLoading: false,
        last_sync_time: data && data.social_post_last_sync ? data.social_post_last_sync : ''
      }
    },
    getPlacementList(state, data) {
      return {
        ...state,
        getPlacementList: data,
        placementLoading: false
      }
    },
    onfetchInsightsSuccess(state, data) {
      return {
        ...state,
        inSightData: data.data,
        placementLoading: false,
        gmbConnectedFlag: data.message === 'No GMB account connected' ? false : true
      }
    },
    onUpdateInsightsSuccess(state, data) {
      return {
        ...state,
        UpdatedInsightData: data
        // placementLoading:false
      }
    },
    onfetchQuestionsSuccess(state, data) {
      return {
        ...state,
        questionsList: data,
        placementLoading: false
      }
    },
    onfetchAnswerSuccess(state, data) {
      return {
        ...state,
        answerList: data
      }
    },

    getLocation(state, data) {
      return {
        ...state,
        getPlacementList: data,
        placementLoading: false
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        placementLoading: false
      }
    },
    onaddQuestionSuccess(state, data) {
      notification('success', 'Successfully created question')
      return {
        ...state,
        placementLoading: false
      }
    },
    onupdateQuestionSuccess(state, data) {
      notification('success', 'Successfully updated question')
      return {
        ...state,
        placementLoading: false
      }
    },
    onGetSocialConnectionsSuccess(state, data) {
      return {
        ...state,
        placementLoading: false,
        socialConnection: data && data.data
      }
    },
    onGetFilterSuccess(state, data) {
      return {
        ...state,
        placementLoading: false,
        savedFilters: data && data.data
      }
    },
    onfetchQuestionSyncedSuccess(state, data) {
      return {
        ...state,
        questionLastUpdated: data.last_updated,
        placementLoading: false
      }
    },
    onsyncQuestionsNowSuccess(state, data) {
      notification('success', 'Questions will be synced shortly')
      return {
        ...state,
        questionLastUpdated: new Date().valueOf()
        // placementLoading:false
      }
    },
    onSyncSuccess(state, data) {
      if (data.message) {
        notification('success', capitalize(data.message))
      }
      return {
        ...state,
        last_sync_time: data && data.sync_time,
        placementLoading: false
      }
    },
    onDeleteQuestionSuccess(state, data) {
      notification('success', 'Successfully deleted question')

      return {
        ...state,
        placementLoading: false
      }
    },
    ondeleteAnswerSuccess(state, data) {
      notification('success', 'Successfully deleted answer')

      return {
        ...state,
        placementLoading: false
      }
    },
    onsendAnswerSuccess(state, data) {
      notification('success', 'Successfully added answer')

      return {
        ...state,
        placementLoading: false
      }
    },
    onUpdateAnswerSuccess(state, data) {
      notification('success', 'Successfully updated answer')

      return {
        ...state,
        placementLoading: false
      }
    },
    oncreatePostSuccess(state, data) {
      let errorMessage = ''
      if (data && data.error_gmb && data.error_gmb.message) {
        errorMessage = data.error_gmb.message
      } else if (data && data.error_fb) {
        errorMessage = data.error_fb
      }
      notification(errorMessage ? 'error' : 'success', errorMessage ? errorMessage : 'Post created successfully')

      return {
        ...state,
        placementLoading: false
      }
    }
  },
  effects: {
    async getSocialStatus(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSocialStatus(payload)
        this.onGetSocialStatusSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getConfigFlag(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getConfigFlag(payload)
        this.onfetchConfigFlag(res.achPaymentFeatureFlag)
        return res.achPaymentFeatureFlag
      } catch (e) {
        await this.onError(e)
      }
    },
    async getSocialPosts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getSocialPosts(payload)
        this.onGetSocialPostSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getPlacementList(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getPlacementList(payload)
        this.getPlacementListSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getLocation(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getLocation(payload)
        this.getLocationSuccess(res)
        return res
      } catch (e) {
        this.onLocationError(e)
      }
    },
    async addressView(payload) {
      this.onRequest()
      try {
        const res = await service.addressView(payload)
        this.addressViewSuccess(res.data, payload.suppressAddress)
        // this.addressViewSuccess({res:res,message:"Business Settings Updated successfully",show:true})
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getPresenceLocation(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getPresenceLocation(payload)
        this.onGetPresenceLocationSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async syncSocialPost(payload, rootState) {
      // this.onRequest()
      try {
        const res = await service.syncSocialPost(payload)
        this.onSyncSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deletePost(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deletePost(payload)
        this.onSuccess(res, 'Post deleted successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createPost(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createPost(payload)
        // this.onSuccess(res,"Post created successfully")
        this.oncreatePostSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchInsights(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchInsights(payload)
        this.onfetchInsightsSuccess(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async UpdateInsights(payload, rootState) {
      this.onRequestAnswer()
      try {
        const res = await service.UpdateInsights(payload)
        this.onUpdateInsightsSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchQuestions(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchQuestions(payload)
        this.onfetchQuestionsSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchAnswer(payload, rootState) {
      this.onRequestAnswer()
      try {
        const res = await service.fetchAnswer(payload)
        this.onfetchAnswerSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendAnswer(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendAnswer(payload)
        this.onsendAnswerSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async UpdateAnswer(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.UpdateAnswer(payload)
        this.onUpdateAnswerSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteQuestion(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteQuestion(payload)
        this.onDeleteQuestionSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteAnswer(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteAnswer(payload)
        this.ondeleteAnswerSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addQuestion(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.addQuestion(payload)
        this.onaddQuestionSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateQuestion(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateQuestion(payload)
        this.onupdateQuestionSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getSocialConnections(payload, rootState) {
      this.onRequest()
      try {
        const res = await getSocialConnections(payload)
        this.onGetSocialConnectionsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getFilter(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getFilter(payload)
        this.onGetFilterSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchQuestionSynced() {
      this.onRequest()
      try {
        const res = await service.fetchQuestionSynced()
        this.onfetchQuestionSyncedSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async syncQuestionsNow() {
      this.onRequestAnswer()
      try {
        const res = await service.syncQuestionsNow()
        this.onsyncQuestionsNowSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async uberAllCategory(payload, rootState, module) {
      this.onRequest()
      try {
        const res = await service.uberAllCategory(payload, module)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
