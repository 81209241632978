
let plans ={
    "basic-monthly":[{name:"Sites",icon:"integration",style:"site" },{name:"Payments",icon:"paymentTrail",style:"payment" },{name:"Contact Hub",icon:"contact",style:"site" },{name:"Messenger",icon:"messenger",style:"messenger" }  ],
    "basic-annual":[{name:"Sites",icon:"integration",style:"site" },{name:"Payments",icon:"paymentTrail",style:"payment" },{name:"Contact Hub",icon:"contact",style:"site" },{name:"Messenger",icon:"messenger",style:"messenger" }  ], 
    "grow-monthly":[{name:"Sites",icon:"integration",style:"site" },{name:"Payments",icon:"paymentTrail",style:"payment" },{name:"Contact Hub",icon:"contact",style:"site" },{name:"Messenger",icon:"messenger",style:"messenger" },{name:"Placement",icon:"placement",style:"placement" },{name:"Reviews",icon:"reviews",style:"reviews" }   ],
    "grow-annual":[{name:"Sites",icon:"integration",style:"site" },{name:"Payments",icon:"paymentTrail",style:"payment" },{name:"Contact Hub",icon:"contact",style:"site" },{name:"Messenger",icon:"messenger",style:"messenger" },{name:"Placement",icon:"placement",style:"placement" },{name:"Reviews",icon:"reviews",style:"reviews" }  ],
    "optimize-monthly":[{name:"Sites",icon:"integration",style:"site" },{name:"Payments",icon:"paymentTrail",style:"payment" },{name:"Contact Hub",icon:"contact",style:"site" },{name:"Messenger",icon:"messenger",style:"messenger" },{name:"Placement",icon:"placement",style:"placement" },{name:"Reviews",icon:"reviews",style:"reviews" },{name:"Booking",icon:"bookings",style:"bookings" },{name:"Engagement",icon:"menuEngagementNormal",style:"engagement" }   ],
    "optimize-annual":[{name:"Sites",icon:"integration",style:"site" },{name:"Payments",icon:"paymentTrail",style:"payment" },{name:"Contact Hub",icon:"contact",style:"site" },{name:"Messenger",icon:"messenger",style:"messenger" },{name:"Placement",icon:"placement",style:"placement" },{name:"Reviews",icon:"reviews",style:"reviews" },{name:"Booking",icon:"bookings",style:"bookings" },{name:"Engagement",icon:"menuEngagementNormal",style:"engagement" }   ],
}
export function  getPlans(planName) {
    return plans[planName]
}


export function  getName(planName,isMobile) {
    let name;
    if(planName === "Grow Monthly" || planName === "Grow Annual"){
        if(isMobile)
            name = "Grow Plan"
        else 
            name = "Grow Plan Details"       
    }else if(planName === "Basic Monthly" || planName === "Basic Annual"){
        if(isMobile)
            name = "Basic Plan"
        else 
            name = "Basic Plan Details"       
    }else if(planName === "Optimize Monthly" || planName === "Optimize Annual"){
        if(isMobile)
            name = "Optimize Plan"
        else 
            name = "Optimize Plan Details"       
    }
    return name
}