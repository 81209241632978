import { ClickActions } from '@containers/home/actionCenter/service';
import { segmentEventTrack } from '../segment';
import { SegmentEventData, ContactType } from './types';

export function trackACActionClick(actionName: ClickActions) {
  const data: SegmentEventData = {
    event: 'ac_action_click',
    properties: {
      action: actionName,
    },
  };
  segmentEventTrack(data);
}

export function trackACDidSeeActionCenterFirstTime() {
  const data: SegmentEventData = {
    event: 'ac_did_see_action_center_first_time',
    properties: {},
  };
  segmentEventTrack(data);
}

export function trackACCompleted() {
  const data: SegmentEventData = {
    event: 'ac_completed',
    properties: {},
  };
  segmentEventTrack(data);
}

export function trackACFinishReviewRequest() {
  const data: SegmentEventData = {
    event: 'ac_finish_review_request',
    properties: {},
  };
  segmentEventTrack(data);
}

type MessageType = 'SMS' | 'Facebook' | 'Email' | 'Thumbtack'; // probably more things, but that's all I can find rn.
export function trackACSendAMessage(message: string, messageType: MessageType) {
  const data: SegmentEventData = {
    event: 'ac_send_a_message',
    properties: {
      message: message,
      message_type: messageType,
    },
  };
  segmentEventTrack(data);
}

// we can't tell how many contacts are imported from quickbooks and csv_import
export function trackACUploadContacts(sourceOfContacts: ContactType, numOfContacts: number | 'unknown') {
  const data: SegmentEventData = {
    event: 'ac_upload_contacts',
    properties: {
      number_of_contacts: numOfContacts,
      source_of_contacts: sourceOfContacts,
    },
  };
  segmentEventTrack(data);
}

export function trackACExpired() {
  const data: SegmentEventData = {
    event: 'ac_expired',
    properties: {},
  };
  segmentEventTrack(data);
}
