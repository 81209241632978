import * as service from "./service";
import notification from "@components/notification"
import {capitalize} from "@helpers/index"

export default {
    state: {
    },
    reducers: {
        onRequest(state) {
            return {
                ...state,
                loading: true
            };
        },
        onError(state, data) {
            let err = data && data.message ? data.message : data && data.data && data.data.message ? data.data.message :  "Please try again later"  ;
            notification("error", capitalize(err));
            return {
                ...state,
                loading: false
            };
        },
        onfetchMediaSuccess(state, data) {
            for(var i=0;i<data.medias.length;i++){
                if(data.medias[i].type === "video"){
                    data.medias[i].previewUrl = data.medias[i].video_thumb_url?data.medias[i].video_thumb_url:"https://dufzo4epsnvlh.cloudfront.net/image/nophoto_video_thumb_normal.png";
                }else{
                    data.medias[i].previewUrl = data.medias[i].fileUrl;
                }
            }
            return {
                ...state,
                loading: false,
                media: data
            }
        },
        onfetchFoldersSuccess(state, data) {
            return {
                ...state,
                loading: false,
                folders: data
            }
        },
        onImageUploadSuccess(state, data) {
            return {
                ...state,
                loading: false,
                uploadedData: data
            }
        },
        onfetchIconsSuccess(state, data) {
            return {
                ...state,
                loading: false,
                icons: data
            }
        },
        oneditUploadSuccess(state, data) {
            return {
                ...state,
                loading: false,
                editedurl: data
            }
        },
        onGetShutterStockMediaSuccess(state, data) {
            return {
                ...state,
                loading: false,
                shutterstockMedia: data
            }
        },
        onSaveShutterstockSuccess(state,data){
            return {
                ...state,
                shutterstockSavedMedia: data
            }  
        },
        // onGetHqShutterstockSuccess(state,data){
        //     return {
        //         ...state,
        //         shutterstockHqImg: data
        //     }  
        // },
        // ondownloadSuccess(state,data){
        //     return {
        //         ...state,
        //         savedImageData: data
        //     } 
        // }
        // onGetMediaforSiteSuccess(state,data){
        //     return {
        //         ...state,
        //         loading: false,
        //         siteImages : data
        //     }
        // }
    },
    effects: {
        async fetchMedia(payload) {
            if(payload.start === 0){
                this.onRequest()
            }
            try {
                let res = await service.fetchMedia(payload)
                this.onfetchMediaSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async fetchFolders(){
            this.onRequest()
            try {
                let res = await service.fetchFolders()
                this.onfetchFoldersSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async deleteMedia(payload) {
            try {
                let res = await service.deleteMedia(payload)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async onImageUpload(payload,data,obj) {
            try {
                let res = await service.onImageUpload(payload,obj)
                this.onImageUploadSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async fetchIcons() {
            this.onRequest()
            try {
                let res = await service.fetchIcons()
                this.onfetchIconsSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async getGooglePhotos(data) {
            try {
                let res = await service.getGooglePhotos(data)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async getFbPhotos(data) {
            try {
                let res = await service.getFbPhotos(data)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async editUpload(payload, rootState) {
            try {
                let res = await service.editUpload(payload)
                this.oneditUploadSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async getShutterStockMedia(payload) {
            if(payload.page === 1){
                this.onRequest()
            }
            try {
                let res = await service.getShutterStockMedia(payload)
                this.onGetShutterStockMediaSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        async saveShutterstock(payload,data,url) {
            try {
                let res = await service.saveShutterstock(payload,url)
                this.onSaveShutterstockSuccess(res)
                return res
            }
            catch (e) {
                this.onError(e)
            }
        },
        // async getHqShutterstockImage(payload,data,url) {
        //     try {
        //         let res = await service.getHqShutterstockImage(payload,url)
        //         this.onGetHqShutterstockSuccess(res)
        //         return res
        //     }
        //     catch (e) {
        //         this.onError(e)
        //     }
        // },
        // async downloadShutterstockImage(payload,data,url) {
        //     try {
        //         let res = await service.downloadShutterstockImage(payload,url)
        //         this.ondownloadSuccess(res)
        //         return res
        //     }
        //     catch (e) {
        //         this.onError(e)
        //     }
        // },
        // async getStockMediaforInstantSite(payload) {
        //     let images = [] 
        //     try {
        //         let res = await service.getShutterStockMedia(payload)
        //         if(res.data){
        //             let mediaIds= []
        //             res.data.forEach(media => mediaIds.push(media.id))
        //             var data = {
        //                 images: [...mediaIds],
        //              }
        //             var url = {
        //                 orientation: "horizontal",
        //                 multiple : true
        //             }
        //             let imagesRes = await this.getHqShutterstockImage(data, url)
        //             if(imagesRes && imagesRes.length){
        //                 imagesRes.forEach(img=>images.push({id:img.sst_id,url:img.fileUrl}))
        //             }
        //         }
        //         this.onGetMediaforSiteSuccess(images)
        //         return res
        //     }
        //     catch (e) {
        //         this.onError(e)
        //     }
        // },
    }
};