export const routes = {
  // auth
  validateDomain: '/users/valid-domain',
  restartTrial: '/users/restart/trial',
  login: '/users/login',
  user: '/users',
  deleteUserData: '/users/delete-user-data',
  mobileVerify: '/users/generateotpwithtoken',
  addProfilePic: '/add-profile-picture',
  changePassword: '/users/change-password',
  cognitoChangePassword: '/users/changePassword',
  me: '/users/me',
  logout: '/users/logout',
  activities: '/activities',
  signup: '/users/public-signup',
  googleAuth: '/auth/google',
  msAuth: '/auth/microsoftcontacts',
  facebookAuth: '/auth/facebook',
  gmbData: '/auth/googlemybusiness',
  notifications: 'notifications',
  msAccount: '/auth/microsoft',
  quickBookAuth: '/auth/quickbook',
  quickBookAuthUri: '/authuri/quickbook',
  thumbtackAuthUri: '/thumbtack/redirect',
  thumbtackConnect: '/gd/integrations',
  thumbtackAuthUriV2: '/thumbtack/auth',
  thumbtackAuthV2: '/thumbtack/access-token',
  thumbtackAuthUpdateThumbtackSettingsV2: '/thumbtack/accountssettings',
  getApiData: '/users/get-api-data',
  revokeApiKey: '/users/update-api-uuid',
  disableApiKey: '/users/disable-api',
  enableApiKey: '/users/enable-api',
  otpVerify: '/users/editcontact-otp-verify',
  updateTwoWay: '/users/update-two-way',
  enableMFACognito: '/users/enableMfa',
  disableMFACognito: '/users/disableMfa',
  removeProfilePic: '/remove-profile-picture',
  unsyncSocialConnect: '/auth/unsync',
  magicLogin: '/users/magic-login',
  regenerateOtp: '/users/regenerate-otp',
  regenerateOtpCall: '/users/generate-otp-call',
  sendOtp: 'users/reset-password-otp',
  resendOtp: '/users/resendotp',
  verifyOtp: '/users/veryfiyotp',
  resetPasswords: '/users/reset-password',
  resetPasswordStatus: '/users/reset-password-status',
  viewEmail: '/gd/view-email',
  verifyEmail: '/users/verify-email',
  resendMagicLink: 'users/prospect/magic-login-generate',
  getFeedbackDetails: '/product-payments/messenger/get-feedback/settings',
  sendPaymentFeedback: '/product-payments/messenger/feedback',
  getFeedbackSettings: 'product-payments/messenger/feedback-response/settings',
  trackPaymenturl: '/gd/track-social-link',
  sendFeedbackComment: '/product-payments/messenger/feedback-response/comments',
  isClosedNotificationBlock: '/accountsettings/isClosedNotificationBlock',
  themeSettings: '/company-settings/theme-settings',
  referralApps: '/users/appstores',
  pricingLimits: '/users/pricing-limits',
  hubspotContatCreation: '/create-new-hubspot-contact',
  hubspotContatUpdate: '/hubspot-contact',
  triggerContactSales: '/users/trigger-contact-sales-event',

  // reviews
  reviews: '/reviews',
  review_requests: '/reviewmanager/reviews/scheduled-reviews',
  feedback: 'reviewmanager/private-feedback',
  feedbackFB: 'reviewmanager/facebook_reply',
  feedbackGoogle: 'reviewmanager/gmb_reply',
  gmbReplyDelete: 'reviewmanager/gmb_reply_delete',
  feedbackYelp: 'reviewmanager/yext_reply',
  delete_sheduled_review_requests: 'reviewmanager/clear-sheduled-reviews',
  reset_sheduled_review_requests: 'reviewmanager/force-reset-schedule',
  change_review_status: 'reviewmanager/reviews/change-status',
  review_analytics: 'reviews/analytics',
  reviewReply: 'reviewmanager/review_reply',
  deleteReview: 'reviewmanager/reviews/delete',
  reviewManager: '/reviewmanager/reviews',
  reviewEmailAnalytics: '/reviewmanager/email/analytics',
  reviewWidgets: '/reviewmanager/widgets',
  removeReviewWidget: '/reviewmanager/widgets/delete',
  createReviewWidget: '/reviewmanager/widgets/create',
  updatereviewWidget: '/reviewmanager/widgets/update',
  sendReview: '/reviewmanager/email/create',
  fetchWidgetDetails: '/reviewmanager/widgets',
  syncReviews: 'reviews/sync-now',
  syncReviewsV2: 'fb/sync-now',
  lastSyncTime: 'reviews/last-sync-time',
  reviewShareableLink: 'reviews/review-shareable-url',

  // contacts
  contacts: '/contacts',
  contactsAutosuggest: '/contacts/es/auto-suggest',
  groups: '/groups',
  addcontacts: '/groups/addcontacts',
  removecontacts: '/groups/removecontacts',
  createGroup: '/groups/create',
  deleteGroup: '/groups/delete',
  contactDelete: '/contacts/delete',
  contactCreate: '/contacts/create',
  importContact: '/contacts/import',
  updateContact: '/contacts/update',
  importGoogleContact: '/auth/googlecontacts',
  contactAnalytics: '/contacts/es/analytics',
  contactList: 'contacts/list',
  contactListFromES: 'contacts/es',
  quickBookContact: 'auth/quickbookscontacts',
  outlookContacts: 'auth/microsoftcontacts',
  socialcontactsimportsome: 'auth/socialcontactsimportsome',
  verifyContactEmail: '/contacts/verify-all',

  // account settings
  accountSettings: '/accountsettings',
  UpdateCustomLink: 'accountsettings/updatecustomlinks',
  UpdateSocialCustomLink: 'accountsettings/updatesocialmediatick',
  updateSesEmail: 'accountsettings/updatesesemail',
  getAccountSettings: '/accountsettings/getaccountsettings/',
  predefinedTemplate: '/accountsettings/predefined_templates/',
  reviewSendSms: 'reviewmanager/reviews/sms-request',
  updateGositeAccount: '/accountsettings/updategositeaccount',
  unLinkCalendar: '/accountsettings/unlinksocialaccount',
  unLinkCalendarV2: 'gmb/unlinksocialaccount',
  quickbookSettings: '/accountsettings/updateQBsettings',
  quickBookInfo: '/accountsettings/quickbook-info',
  quickbookSettingsV2: 'quickbook/updateQbSettings',
  quickbookTokens: `/accountsettings/updateQuickbookSettingsToken`,
  quickbookIgnore: '/accountsettings/updateQBalert',
  deleteLinks: '/accountsettings/deletelinks',
  quickbookDeleteLinksV2: 'quickbook/deletelinks',
  updateAccountSettings: '/accountsettings/updateaccountsettings',

  // UpdateCustomLink:"accountsettings/updatecustomlinks",
  timeZones: '/settings/timezone',
  enableRestApi: 'users/enable-api-connect-request',
  locationLogo: '/locations/logo',

  paymentCardConnect: 'accountsettings/cardconnect/settings',
  revokeMerchantId: 'accountsettings/deletemerchantid',
  companySettings: 'company-settings',

  // Media library
  onUploadMedia: 'medias/upload',
  fetchMedia: 'medias',
  fetchIcons: 'medias?type=icon&nopaging=true',
  editMedia: '/medias/edit',
  reportAdmin: '/twilio/number-request',
  googlePhoto: '/social-media/google',
  facebookPhoto: '/social-media/facebook',
  shutterstockSearch: '/medias/shutterstock/search',
  saveShutterstock: '/medias/shutterstock/gallerry/image',
  shutterstockImage: '/medias/shutterstock/image',
  shutterstockImageDownload: '/medias/shutterstock/download',

  BookingServices: '/booking-services',
  bookingServicesStaff: '/booking-services-staff',
  bookingCreate: '/leads/booking',
  getBookingServiceOnly: '/booking-services?nopaging=true&sort=sort_order&asc=true',
  getBookingService: '/booking-services?nopaging=true&sort=sort_order&asc=true',
  getStaffBookingService: '/booking-services-staff?nopaging=true&sort=sort_order&asc=true&isBooking=true',
  getDateSlots: '/leads/booking/getserviceslots',
  calanderLeads: '/leads/list',
  bookinglist: '/leads/appointment/list',
  bookingSendmessage: '/leads/appointment',
  bookingClaendarSync: '/auth/connectgoogleaccount',
  bookingClaendarSyncV2: 'gmb/connectgoogleaccount',
  bookingButtonWidget: '/websites/booking',
  hostedPAges: 'chargebee/payment-method',
  chargeBeeCardList: 'chargebee/list-cards',
  chargeBeesubscriptions: 'chargebee/subscriptions',
  chargeBeesubscription: 'chargebee/subscription',
  chargeBeeDclStatus: 'chargebee/dcl/status',
  collectUnpaidInvoices: 'chargebee/dcl/collect-unpaid-invoices',
  chargeBeeInvoices: 'chargebee/invoice',
  chargebeeSubscriptionCancel: 'chargebee/subscription/cancel',
  chargebeeSubscriptionRemoveCancel: 'chargebee/subscription/remove-cancel',
  chargebeeSubscriptionReactivate: 'chargebee/subscription/reactivate',
  chargebeeSubscriptionPlan: 'chargebee/subscription/plan',
  chargeBeeSetDefultCard: 'chargebee/set-as-primary',
  chargeBeeSetRemoveCard: 'chargebee/remove-cards',
  getServiceCount: '/booking-services-count',
  updateServiceLocation: '/booking-services/update-location',
  cancelIncompleteBooking: '/leads/incomplete/cancel',
  bookingPayment: '/invoices/collect-payment',
  bookingManualPayment: '/leads/collect-payment/manual',

  // payment
  paymentRequest: 'product-payments/messenger/payment-requests',
  paymentPostStripeWidget: '/product-payments/payWidget',
  paymentIntentWidget: '/v2/api/stripe/processing/paymentWidget',
  postPaymentWidget: '/v2/api/stripe/processing/postpayWidget',
  paymentStripeMerchantID: '/product-payments/getMerchantID',
  paymentList: '/product-payments',
  paymentRecurring: '/invoices/recurring',
  paymentAnalytics: 'product-payments/amount-analytics',
  getConfigFlag: 'stripe/getAch-conf',
  getSiteParent: 'stripe/getParentSiteProd',
  widgetsList: '/custom-forms',
  rescheduleBooking: '/leads/appointment/',
  bookingDetails: '/leads/appointment',
  websiteCount: '/websites',
  createTransaction: '/product-payments/pay',
  paymentIntent: '/v2/api/stripe/processing/payment',
  merchantOnboarding: '/v2/stripe/api/merchant-onboarding/',
  postPayment: '/v2/api/stripe/processing/postpay',
  resendSesEmail: 'accountsettings/resendsesemail',
  questions: '/questions',
  onboarding: '/payment-site/onboarding',
  paymentCustomForm: '/custom-form/payment',
  paymentCustomFormFeedback: '/product-payments/messenger/feedback',
  createBooking: 'leads/booking/pay',
  sendGetPaidLink: '/product-payments/messenger/payment-requests',
  downloadInvoice: '/invoices/pdf',
  connectConatctPayments: '/product-payments/connect-contact-payment',
  mccCode: '/payment-site/get-mcc-code',
  unlockInvoices: 'accountsettings/updateaccountsettings/',

  // business settings
  businessSettings: '/business-settings',
  businessSettingsAddGMB: '/business-settings/create-gmb-location',
  facebookDataSyncV2: 'fb',
  gmbDataSyncV2: 'gmb',
  uberAllCategories: 'yext-user',
  getBookingServices: '/business-settings',
  getcustomers: '/users/getcustomers',
  getCustomFields: '/customfields',
  createCustomField: '/customfields',
  deleteCustomField: '/customfields',
  getCustomFieldsProd: '/customfields/product',
  updateStaffStatus: '/users/update-staff-status',
  updateStaff: '/users/update-staff',
  getUser: '/users/getuser',
  settings: '/settings',
  resetUserAccount: '/users/forgot-password',
  quickLogin: '/users/staff-magic-login-generate',
  createStaff: '/users/create-staff',
  gmbLocations: '/gmblocations',
  gmbLocationsDetails: '/googlemybusiness-locations',
  userRolePermissions: '/rolepermissions',
  menuLocation: 'business-settings/location-menus',
  sendWelcome: '/users/staff-welcome-email',
  getCalendarData: '/accountsettings/getcalendardata',
  addMenuSection: '/business-settings/menu-sections',
  menuLabels: '/business-settings/location-menus/menu-options',
  menuItems: 'business-settings/menu-items',
  activeInactiveDelete: '/business-settings/activate-inactivate-delete/location',
  getUpgradeToProUrl: '/users/upgrade-pro-url',

  // Placement
  insights: '/gmb/insights',
  socialStatus: '/reviewmanager/social_status',
  socialPosts: '/reviewmanager/list_social_post',
  getPlacementList: '/business-settings',
  getLocation: '/customers',
  presenceLocation: '/presence',
  syncPosts: '/reviewmanager/sync_social_post',
  deletePosts: '/reviewmanager/delete_social_post',
  deletePostsAdmin: '/reviewmanager/delete_admin_post',
  createPosts: '/reviewmanager/social_post',
  fetchQuestions: '/questions',
  filters: '/filters',
  fetchQuestionsV2: '/gmb/api/questions',
  fetchQuestionLastUpdated: 'questions/last-sync-time',
  questionSync: 'questions/sync',

  // Sites
  siteDetails: '/website-analytics/activitysummary',
  websites: '/websites',
  themeSelection: '/themes',
  customThemeList: '/custom-themes',
  domainAvailability: '/domain-availability',
  domain: '/domain-availability',
  customDomain: '/custom-domains',
  partnerDomain: '/partner-domain-availability',

  //  From notification Component
  websiteSubscription: '/newsletter',
  websiteLead: '/leads',
  websiteAnalytics: '/website-analytics/activity-summary',

  // trial activate
  trialActivate: '/instant/upgrade',

  // feedback
  sendFeedback: 'feedbacks/sendfeedback',

  // messenger
  messenger: '/messenger',
  listMessenger: '/messenger/list',
  unreadMessages: '/messenger/unread-messages',
  leadMessages: '/messenger/leads',
  companyUsers: '/users/company-users',
  readat: 'readat',
  leads: '/leads',
  segmentTrack: '/segment/track',
  segmentConatctTrack: 'segment/indirect-activity/track',
  lastAttended: '/leads/lastAttended',
  messageNote: '/messages/note',
  assignStaff: '/assign-staff',
  chatSearch: '/chat/bulk/search',
  allRead: '/messages/allread',
  chatNewMessage: '/chats/messenger/new-message',
  blockUnblock: '/messenger/block-unblock',
  messengerEmbedcode: '/messenger/embedcode',
  messageReceivedTrack: '/messenger/track-received',
  bookingLink: '/booking-services/booking-link',
  allocateTwilioNumber: '/users/allocate-twilio-number',
  verifyAndAssignTwilioNumber: '/users/verify-assign-twilio-number',
  whitelistHostnames: '/settings/whitelist-hostnames',

  // invoice
  saveInvoice: '/invoices',
  saveRecurringInvoice: '/invoices/recurring',
  composeSms: '/twilio/compose-sms',
  messengerViewTime: 'users/messenger-view',
  markasPaid: '/invoices/mark-as-paid',
  trackTransactionStatusChange: '/transactions/track-single-user-payment-status-change',
  recurringInvoiceCount: '/invoices/service',
  // Thumbtack
  connectTumbtack: '/thumbstack/connect',
  removeThumtackService: '/thumbstack/service/remove',
  removeThumtackServiceV2: '/thumbtack/disconnect-partner',

  // Engagement
  emailAnalytics: '/campaigns/email/analytics',
  emailAnalyticsGraph: '/campaigns/email/analytics-for-graph',
  emailCount: '/campaigns/email/count',
  emailList: '/campaigns/email/list',
  removeEmailCampaign: '/campaigns/email/change-status',
  emailCampaign: '/campaigns/email',
  campaignsClone: '/campaigns/email/clone',

  // dashboard
  fetchBussiness: 'instant/find-business',
  fetchBussinessDetails: 'instant/find-business-short',
  resetPassword: 'users/change-wo-old',
  skipPassword: 'users/activate',

  // self service
  fetchHostedPage: '/chargebee/generate-checkout-new-url',
  fetchSubscriptionDetail: '/chargebee/plan',
  fetchCompanyDetail: '/companies',
  createPandoc: '/pandadoc/create',
  pandoc: '/pandadoc',
  prospectUser: '/users/prospect',
  fetchCoupon: '/chargebee/coupon',
  getsettings: 'accountsettings/getsettings',
  resubscribe: '/gd/resubscribe',
  // enterprise - locations
  leaderboard: '/reviews/leaderboard',
  tableformat: '/tablelayout?platform=web',
  editColumnUpdate: '/tablelayout',
  businessType: '/businesstypes',
  products: '/products',
  magicLoginGenerate: '/users/magic-login-generate',
  deleteLocation: '/users/location',
  platformUrl: '/users/platform-url',
  loginWithEmail: '/users/login-with-email',
  generateApiToken: '/users/generate-api-token',
  republishWebsites: '/websites/republish-websites',
  importLocation: '/users/import',
  batchUpdate: 'accountsettings/batch-update',
  enterPriseEnableApiConnect: 'users/enable-api-connect',
  userBsSettings: '/business-settings/locations/users',
  usStates: '/website-analytics/country/states/us',
  subscription: '/users/subscription',
  locationCategories: 'business-settings/categories',
  smsNumber: '/users/twilio/areacodes',
  exportActivity: '/users/export-activity',
  sendPushNotification: '/notifications/pushnotification',
  businessSettingsBatchUpdate: '/business-settings/business_batches_update',
  syncYext: '/business-settings/sync-yext',
  yextLocationTypes: '/business-settings/yext/location-types',
  unsyncYext: '/business-settings/unsync-yext',
  presenceOptOut: '/presence/optout',
  presenceOptIn: '/presence/optin',
  getFilters: '/filters',
  gositeGmb: '/auth/googlemybusiness-acounts',
  moveLocation: '/users/move-customer',
  resubmitYext: '/users/yext-resubmit',
  getSavedCard: '/accountsettings/cardconnect/list-cards',
  getPartners: '/users/getpartners',

  // ENTERPRISE REVIEWS
  location: '/users/enterprise/location',
  isExistingYextCustomer: '/users/enterprise/existing-yext-customer',
  leaderBoard: 'reviews/leaderboard',
  enterpriseReviewAnalytics: '/site/analytics/reviews',
  listPresence: '/presence/list/paging',

  // Review feedback link
  feedbackStatus: '/reviewmanager/reviews/feedback-status',
  commonFeedbackStatus: '/reviewmanager/reviews/common-feedback-status',
  reviewSettings: '/reviewmanager/reviews/get-settings',
  sendReviewFeedback: '/reviewmanager/email/feedback',
  feedbackreply: '/reviewmanager/',

  // ENTERPRISE placement
  enterpricePlacementLeaderboard: '/gmb/insights/leaderboard',
  listAdminPosts: '/reviewmanager/list_admin_posts',
  createAdminPost: '/reviewmanager/admin_social_post',
  adminPostDetails: '/reviewmanager/list_admin_posts_details',

  // ENTERPRISE Service
  enterpriseServices: '/booking-services-partner',

  // ENTERPRISE managers
  createManager: '/users/create-manager',

  // ENTERPRISE CONTACTS
  getEntContacts: 'contacts/es',

  // getWidgets:"/widgets/list",

  // Enterprise home
  homeLayout: '/homelayout',

  getWidgets: '/widgets',
  customerProducts: '/users/getcustomers-product',

  // settings (enterprise)
  defaultThemes: '/company-settings/theme-settings/default-theme',
  resetTheme: '/company-settings/theme-settings/reset',
  brandingReupdate: '/company-settings/theme-settings/reupdate',
  saveBranding: '/company-settings/theme-settings/savebranding',

  // instant sites
  businessData: '/external-business-data',
  rocketDataBussdata: '/business-settings/rocket-fuel/business-data',
  websiteV3: '/v3/websites',
  suggestedServices: '/services',
  progress: '/progress',

  // action center
  actionCenter: '/action-center/v2/browser',
  actionCenterClick: '/action-center/v1/click',

  // payments-growth
  paymentsGrowth: '/payments-growth/v1',
  paymentsGrowthStatus: '/payments-growth/v1/status',

  // first-experience onboarding
  firstExperience: '/first-experience/v2',
  ftuPrescreen: 'prescreening/v1',

  // UPGRADE-TO-PRO
  upgradeToPro: '/upgrade/subscription',

  // create subscription for upgrade to pro/buy now
  createSubscription: '/chargebee/upgrade/subscription',
  createSubscriptionV2: '/chargebee/upgrade/v2/subscription',
  estimateSubscription: '/chargebee/upgrade/estimate-subscription',
  estimateV2Subscription: '/chargebee/upgrade/v2/estimate-subscription',
  chargebeePublishableKey: '/chargebee/publishablekey',
  chargbeeUpgradePlans: '/chargebee/upgrade/plans',
  chargbeePromoCode: '/chargebee/promo-code',
  estimateUpdateSubscription: '/chargebee/subscription/estimate-update-subscription',
  updateSubscription: '/chargebee/subscription/plan',
  getPlan: '/chargebee/plan',

  /** Migrate User */
  migrateUser: '/users/migrate-to-multi-location-user',

  // estimates
  getEstimates: '/estimates',
  postEstimate: '/estimates',
  uploadEstimateAttachments: '/estimates/attachments',

  // insurances
  getApplications: '/insurances/applications/business',
  getPolicies: '/insurances/policies/business',
  getCoverdashAuthToken: '/oauth/token',
  createCoverdashBusinessID: '/insurances/partnerSubmission/createBusiness',
  saveCoverdashBusinessID: '/add-coverdash-businessID',
  createApplicationSubmission: '/insurances/partnerSubmission/createApplicationSubmission',

  // wisetack
  postSignupLink: '/wisetack/signup-link',
  getSignupStatus: '/wisetack/signup-status',
  getPrequalLink: '/wisetack/prequal-link',
  getFinancingPromoMessaging: '/wisetack/financing-promo-messaging',

  // simvoly
  mapSimvolyWebsite: '/users/map-simvoly-website',
  deleteSimvolyWebsite: '/users/delete-simvoly-website',
  simvolyWebsites: '/users/simvoly-websites'
}
