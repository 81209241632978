export const ROLE_NAME_PATH = 'role_id.role_name'
export const USER_ROLES = {
  CUSTOMER: 'customer',
  TRIAL: 'trial',
  MULTILOCATION: 'multi_location',
  STAFF: 'staff',
  READ_ONLY: 'Read Only',
  PROSPECT: 'prospect'
}

export const ENTERPRISE_ROLES = {
  PARTNER: 'partner',
  MANAGER: 'manager'
}
export const LOGIN_SERVICE_PROVIDERS = {
  FACEBOOK: {
    name: 'Facebook'
  },
  GOOGLE: {
    name: 'Google'
  },
  INTUIT: {
    name: 'Intuit'
  }
}
export const FEDERATED_LOGIN_PROVIDERS = [
  LOGIN_SERVICE_PROVIDERS.FACEBOOK.name,
  LOGIN_SERVICE_PROVIDERS.GOOGLE.name,
  LOGIN_SERVICE_PROVIDERS.INTUIT.name
]
export const LOGIN_PROVIDER = 'loginProvider'
export const REFRESH_TOKEN = 'refreshToken'
export const IS_INTUIT_ONBOARDING = 'isIntuiteOnboarding'
export const IS_THUMBTACK_ONBOARDING = 'isThumbtackOnboarding'

export const REFERRAL_APP_SOURCES = {
  QUICKBOOKS: 'quickbooks',
  GMB: 'google my business',
  FACEBOOK: 'gosite social media'
}

export const INTEGRATION_REFFERAL_APPS = [
  REFERRAL_APP_SOURCES.QUICKBOOKS,
  REFERRAL_APP_SOURCES.GMB,
  REFERRAL_APP_SOURCES.FACEBOOK
]

export const REFERRAL_APP_SOURCE_COOKIE_KEY = 'referralAppSource'

export const REFERRAL_APP_SOURCE_PROVIDER_MAP = {
  quickbooks: 'intuit',
  'google my business': 'google'
}
export const COOKIES_KEY = {
  LOGIN_PROVIDER: 'loginProvider',
  REFRESH_TOKEN: 'refreshToken',
  GOOGLE_REFRESH_TOKEN: 'googleRefreshToken',
  GOOGLE_ACCESS_TOKEN: 'googleAccessToken',
  QUICKBOOK_ACCESS_TOKEN: 'quickbookAccessToken'
}

export const ROLES_SEGMENT_TRACK = [
  USER_ROLES.CUSTOMER,
  USER_ROLES.STAFF,
  USER_ROLES.TRIAL,
  ENTERPRISE_ROLES.PARTNER,
  ENTERPRISE_ROLES.MANAGER,
  USER_ROLES.READ_ONLY
]

export const DCL_USER_GRACE_PERIOD_BUCKET = 2

export const COLLECT_UNPAID_INVOICES_SUCCESS_MSG = 'Payment Successful!'

export enum UserSource {
  Direct = 'direct',
  HubSpot = 'hubspot',
  Admin = 'admin',
  Apple = 'apple',
  Google = 'google',
  Facebook = 'facebook'
}
export const ACCOUNTS_HOST = 'accounts.gosite'

export const GOOGLE_CONNECTED_ALREADY_ERROR_MSG = 'Google already connected with another user.'

export const ID_TOKEN = 'id_token'

export const ID_TOKEN_ADMIN = 'id_token_admin'

export const COOKIES_KEYS = {
  ADMIN_ORIGIN_REQ: 'admin_origin_req',
  ADMIN_UUID: 'admin_uuid',
  THUMBTACK_TOKEN: 'tt_token'
}

export const SOCIAL_SYNC_FLOW_TYPES = {
  FTU: 'ftu',
  MANGAGE_MY_BUSINESS: 'managemybusiness'
}

export const SIGN_UP_SOURCES = {
  DIRECT: 'direct',
  GOOGLE: 'google',
  FACEBOOK: 'facebook',
  INTUIT: 'intuit'
}

export const MODAL_TYPES = {
  perfectPlan: {
    title: `<h2>Let's find you the perfect <svg width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> plan!</h2>`,
    description1: `<p>To update your features, users, locations or billing, please call us at <a href="tel:8582957995" style={{ color: '#0DB2FA' }}>(858) 295-7995</a>.</p>`,
    description2: `<p>If you would like GoSite to call you, click here, and someone will contact you shortly.</p>`,
    buttonTitle: 'REQUEST CALL FROM GOSITE',
    imgSrc: '/images/promotional/update-plan.png',
    footer: `<p></p>`,
    className: 'perfectPlan'
  },
  perfectPlanSafari: {
    title: `<h2>Let's find you the perfect <svg style="top:90;left:75"width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> plan!</h2>`,
    description1: `<p>To update your features, users, locations or billing, please call us at <a href="tel:8582957995" style={{ color: '#0DB2FA' }}>(858) 295-7995</a>.</p>`,
    description2: `<p>If you would like GoSite to call you, click here, and someone will contact you shortly.</p>`,
    buttonTitle: 'REQUEST CALL FROM GOSITE',
    imgSrc: '/images/promotional/update-plan.png',
    footer: `<p></p>`,
    className: 'perfectPlan'
  },
  userLimit: {
    title: `<h2>You have reached <svg width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> your user limit</h2>`,
    description1: `<p>Your current limit is $(limit) user(s). To upgrade your plan, please call us at <a href="tel:8582957995" style={{ color: '#0DB2FA' }}>(858) 295-7995</a>.</p>`,
    description2: `<p>If you would like GoSite to call you, click here, and someone will contact you shortly.</p>`,
    buttonTitle: 'REQUEST CALL FROM GOSITE',
    imgSrc: '/images/promotional/user-limit.png',
    footer: `<p><b>Note:</b> Only active users count towards your limit.</p>`,
    className: 'userLimit'
  },
  userLimitSelf: {
    title: `<h2>You have reached <svg width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> your user limit</h2>`,
    description1: `<p>Your current limit is $(limit) user(s). Click here to upgrade your plan and increase your limit.</p>`,
    buttonTitle: 'UPDATE PLAN',
    imgSrc: '/images/promotional/user-limit.png',
    footer: `<p><b>Note:</b> Only active users count towards your limit.</p>`,
    className: 'userLimit'
  },
  locationLimit: {
    title: `<h2>You have reached <svg width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> your location limit</h2>`,
    description1: `<p>You current limit is $(limit) location(s). To upgrade your plan, please call us at <a href="tel:8582957995" style={{ color: '#0DB2FA' }}>(858) 295-7995</a>.</p>`,
    description2: `<p>If you would like GoSite to call you, click here, and someone will contact you shortly.</p>`,
    buttonTitle: 'REQUEST CALL FROM GOSITE',
    imgSrc: '/images/promotional/location-limit.png',
    footer: `<p><b>Note:</b> Only active locations count towards your limit.</p>`,
    className: 'locationLimit'
  },
  locationLimitSelf: {
    title: `<h2>You have reached <svg width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> your location limit</h2>`,
    description1: `<p>You current limit is $(limit) location(s). Click here to upgrade your plan and increase your limit.</p>`,
    buttonTitle: 'UPDATE PLAN',
    imgSrc: '/images/promotional/location-limit.png',
    footer: `<p><b>Note:</b> Only active locations count towards your limit.</p>`,
    className: 'locationLimit'
  },
  locationLimitTo1: {
    title: `<h2>Let's find you the perfect <svg  style="top: calc(100% - 10px) !important; left: 1px !important; width: 53% !important; }} width="238" height="13" viewBox="0 0 238 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.99987 7.99972C34.1332 4.16194 127.273 -1.37229 236.041 5.23123" stroke="#0357FF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/>
    </svg> plan!</h2>`,
    description1: `<p>All plans are limited to 1 location. To add more locations, call us at <a href="tel:8582957995" style={{ color: '#0DB2FA' }}>(858) 295-7995</a>.</p>`,
    description2: `<p>If you would like GoSite to call you, click here, and someone will contact you shortly.</p>`,
    buttonTitle: 'REQUEST CALL FROM GOSITE',
    imgSrc: '/images/promotional/location-limit.png',
    footer: `<p><b>Note:</b> Only active locations count towards your limit.</p>`,
    className: 'locationLimit'
  }
}

export const DEFAULT_ZIP_CODE_GMAP_MARK = '20001'

export const ESTIMATE_STATES = {
  CREATED: 'created',
  SENT: 'sent',
  ACCEPTED: 'accepted',
  UPDATED: 'updated',
  RESENT: 'resent',
  EXPIRED: 'expired',
  RENEWED: 'renewed',
  CHANGE_REQUESTED: 'change_requested',
  CONVERTED_TO_INVOICE: 'converted_to_invoice'
}
