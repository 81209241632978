import React from 'react'
import PropTypes from 'prop-types'
// import { useField } from "formik";
import { addMethod, object } from 'yup'
import { CardCvcElement as StripeCardCvcElement } from '@stripe/react-stripe-js'
import { Field } from 'formik'

import { ElementState } from './ElementState'

const defaultStyle = {
  fontSize: '14px',
  paddingTop: '0.75rem',
  fontWeight: 400
}

const CardCvcElement = ({ form, field, className, ...props }) => {
  const { name, value } = field
  const { onBlur, onChange, setFieldValue, setTouched } = form

  const handleBlur = () => {
    onBlur && onBlur()
    setTouched({ ...form.touched, [name]: true })
    if (value.empty) setFieldValue(name, new ElementState())
  }

  const handleChange = (event) => {
    onChange && onChange(event)
    setFieldValue(name, new ElementState(event))
  }

  return (
    <StripeCardCvcElement
      {...props}
      options={{
        classes: {
          base: className
        },
        style: {
          base: defaultStyle
        }
      }}
      onBlur={handleBlur}
      onChange={handleChange}
    />
  )
}

CardCvcElement.propTypes = {
  className: PropTypes.string,
  ...Field.propTypes,
  ...CardCvcElement.propTypes
}

export { CardCvcElement }

function cardCvcElement (messages = {}) {
  return this.test({
    name: 'card-number-element',
    test: function (value) {
      if (!(value instanceof ElementState)) throw new Error('Object must be an instance of `ElementState`.')

      if (value.empty) {
        return this.createError({
          path: this.path,
          message: messages.empty || `Required`
        })
      }

      if (value.error === 'invalid_cvc') {
        return this.createError({
          path: this.path,
          message: messages.invalid || `Invalid`
        })
      }

      if (value.error === 'incorrect_cvc') {
        return this.createError({
          path: this.path,
          message: messages.incorrect || `Incorrect`
        })
      }

      if (value.error === 'incomplete_cvc') {
        return this.createError({
          path: this.path,
          message: messages.incomplete || `Incomplete`
        })
      }

      return true
    }
  })
}

addMethod(object, 'cardCvcElement', cardCvcElement)
