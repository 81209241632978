import { api, invoiceApi, catchHandler, paymentApi, onboardingApi, catchHandlerForPaymentIntent } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function fetchPayments (payload) {
  return invoiceApi()
    .get(`transactions?${objToUrlParams(payload)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function getStripPaymentURL () {
  return onboardingApi()
    .post(config.routes.merchantOnboarding)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchMountAnalytics (payload) {
  return api()
    .get(config.routes.paymentAnalytics)
    .then(res => res.data)
    .catch(catchHandler)
}

export function getWidgets (payload) {
  return api()
    .get(`${config.routes.widgetsList}?${objToUrlParams(payload)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function amountRefund (payload) {
  return api()
    .put(`${config.routes.paymentList}/${payload.id}/refund`, { amount: payload.amount })
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchPaymentDetail (payload) {
  return api()
    .get(`${config.routes.paymentList}/${payload.id}?${payload.query ? objToUrlParams(payload.query) : ''}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchRecurringSeriesDetail (payload) {
  return invoiceApi()
    .get(`${config.routes.paymentRecurring}/${payload.id}`)
    .then(res => {
      return res.data
    })
    .catch(catchHandler)
}
export function fetchRecurringPayments (payload) {
  return invoiceApi()
    .get(`transactions?${objToUrlParams(payload.query)}`)
    .then(res => {
      return res.data
    })
    .catch(catchHandler)
}
export function sendMessagePayment (payload) {
  return api()
    .put(`${config.routes.paymentList}/${payload.id}/contact`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteWidget (payload) {
  return api()
    .post(`${config.routes.widgetsList}/trash/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function restoreWidget (payload) {
  return api()
    .post(`${config.routes.widgetsList}/restore/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deleteWidgetPermanently (payload) {
  return api()
    .delete(`${config.routes.widgetsList}/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createWidget (payload) {
  return api()
    .post(config.routes.widgetsList, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function editWidget (payload) {
  return api()
    .put(`${config.routes.widgetsList}/${payload._id}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createTransaction (payload) {
  return api()
    .post(config.routes.createTransaction, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function sendRequest (payload) {
  return api()
    .post(`${config.routes.paymentList}/${payload.orderid}/send-receipt`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export function getPaymentIntent (payload) {
  return paymentApi().post(
    `${config.routes.paymentIntent}`,
    payload
  )
    .then(result => result.data)
    .catch(error => catchHandlerForPaymentIntent(error.response.data))
}

export function paymentIntentWidget (payload) {
  return paymentApi().post(
    `${config.routes.paymentIntentWidget}`,
    payload
  )
    .then(result => result.data)
    .catch(error => catchHandlerForPaymentIntent(error.response.data))
}
export function getStripeMerchantID (payload) {
  return api()
    .post(`${config.routes.paymentStripeMerchantID}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export function proceedPostStripeWidgetPayment (payload) {
  return paymentApi().post(config.routes.postPaymentWidget, payload)
    .then(response => response.data)
    .catch(error => catchHandlerForPaymentIntent(error.response.data))
}

export function paymentPostStripeWidget (payload) {
  return api()
    .post(`${config.routes.paymentPostStripeWidget}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export function proceedPostStripePayment (payload) {
  return paymentApi().post(config.routes.postPayment, payload)
    .then(response => response.data)
    .catch(error => catchHandlerForPaymentIntent(error.response.data))
}

export function paymentCardConnect (payload) {
  return api()
    .post(`${config.routes.paymentCardConnect}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getCompanySettings () {
  return api()
    .get(`${config.routes.companySettings}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function revokeMerchantId (payload) {
  return api()
    .delete(`${config.routes.revokeMerchantId}/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function handleCancelTransaction (payload) {
  return api()
    .put(`${config.routes.paymentList}/${payload._id}/cancel`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchInquire (payload) {
  return api()
    .put(`${config.routes.paymentList}/${payload}/inquire`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchRecieptInvoice (payload) {
  return api()
    .get(`${config.routes.paymentList}/${payload}/download-receipt`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchOnboarding (payload) {
  return api()
    .get(`${config.routes.onboarding}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getConfigFlag (payload) {
  return api()
    .post(`${config.routes.getConfigFlag}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getSiteParent (payload) {
  return api()
    .post(`${config.routes.getSiteParent}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchCustomPAymentFormData (payload) {
  return api()
    .get(`${config.routes.paymentCustomForm}/${payload}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function payNow (payload) {
  return api()
    .post(`${config.routes.paymentList}/paynow`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function sendPaymentFeedback (payload) {
  return api()
    .get(`${config.routes.paymentCustomFormFeedback}?${objToUrlParams(payload)}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function createBooking (payload) {
  return api()
    .post(config.routes.createBooking, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function sendGetPaidLink (payload) {
  return api()
    .post(config.routes.sendGetPaidLink, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function cancelPaymentRequest (payload) {
  return invoiceApi()
    .put(`transactions/${payload.id}/cancel?${objToUrlParams(payload.data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function cancelRecurringInvoice (payload) {
  return invoiceApi()
    .delete(`invoices/recurring/${payload.recurring_invoice_id}/payment_id/${payload.id}?${objToUrlParams(payload.data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function deletePaymentRequest (payload) {
  return invoiceApi()
    .put(`transactions/${payload.id}/delete?${objToUrlParams(payload.data)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function resendPaymentRequest (payload) {
  return invoiceApi()
    .post(`transactions/${payload.id}/resend?${objToUrlParams(payload.params)}`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function resendInvoice (payload) {
  return invoiceApi()
    .post(`transactions/${payload.id}/resend?${objToUrlParams(payload.params)}`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function savePrivateContact (payload) {
  let url = ''
  if (payload && payload.query) {
    url = `${config.routes.contactCreate}?${objToUrlParams(payload.query)}`
    delete payload.query
  } else {
    url = `${config.routes.contactCreate}?exclude_group=1`
  }
  return api()
    .post(url, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function saveInvoiceData (payload) {
  return invoiceApi()
    .post(`${config.routes.saveInvoice}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getInvoiceData (payload) {
  return invoiceApi()
    .get(`${config.routes.saveInvoice}/${payload}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function saveRecurringInvoiceData (payload) {
  return invoiceApi()
    .post(`${config.routes.saveRecurringInvoice}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function cancelRecurringSeries (payload) {
  return invoiceApi()
    .delete(`${config.routes.paymentRecurring}/${payload.id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateRecurringSeries (payload) {
  return invoiceApi()
    .put(`${config.routes.paymentRecurring}/${payload.id}`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateNextInvoiceInRecurringSeries (payload) {
  return invoiceApi()
    .put(`${config.routes.paymentRecurring}/${payload.recurring_invoice_id}/scheduled_invoice/${payload.invoice_id}`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function markasPaidUpdate (payload) {
  return invoiceApi()
    .post(`${config.routes.markasPaid}${payload.query ? `?${objToUrlParams(payload.query)}` : ''}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function downloadInvoice (payload) {
  return invoiceApi()
    .get(`${config.routes.downloadInvoice}/${payload.invoice_id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function connectConatctPayments (payload) {
  return api()
    .post(`${config.routes.connectConatctPayments}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function getMccCode () {
  return api()
    .get(`${config.routes.mccCode}`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function trackTransactionStatusChange (payload) {
  return invoiceApi()
    .post(config.routes.trackTransactionStatusChange, payload)
    .then(res => res.data)
    .catch(catchHandler)
}

export function unlockInvoicesForUser (payload) {
  return api()
    .put(`${config.routes.unlockInvoices}${payload.user}`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
