import {
  getPromoCodeDetail,
  estimateSubscription,
  createSubscription,
  createSubscriptionV2,
  fetchUpgradeToProPlan,
  UpgradeToProPlanDetail,
  migrateUser
} from './service'

import _ from 'lodash'

export default {
  state: {
    paymentError: null,
    selectedPlan: undefined,
    proPlans: [],
    appliedPromoCodeDetail: null,
    promoCodeMessage: {
      message: '',
      success: true
    },
    createdSubscription: undefined
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        processing: true
      }
    },
    onError (state, paymentError) {
      return {
        ...state,
        processing: false,
        paymentError
      }
    },
    onTokenGenerated (state, chargebeeToken) {
      return {
        ...state,
        chargebeeToken
      }
    },
    onUpgradeSuccess (state, { chargebeeSubscription }) {
      console.log(`onUpgradeSuccess`, chargebeeSubscription)
      return {
        ...state,
        processing: false,
        createdSubscription: chargebeeSubscription
      }
    },
    onUpgradeSuccessV2 (state, { chargebeeSubscription }) {
      console.log(`onUpgradeSuccessV2`, chargebeeSubscription)
      return {
        ...state,
        processing: false,
        createdSubscription: chargebeeSubscription
      }
    },
    onPlanSelected (state, selectedPlanId) {
      console.log(state.proPlans.find((p) => p.id === selectedPlanId))
      return {
        ...state,
        selectedPlan: state.proPlans.find((p) => p.id === selectedPlanId)
      }
    },
    onFetchProPlansSuccess (state, proPlans) {
      return {
        ...state,
        proPlans,
        selectedPlan: proPlans.length > 0 ? proPlans[0] : undefined
      }
    },
    setPromoCodeMessage (state, message) {
      return {
        ...state,
        promoCodeMessage: message
      }
    },
    onPromoCodeApplied (state, { plansAfterPromoApplied, promoCodeDetail }) {
      console.log(plansAfterPromoApplied)
      return {
        ...state,
        proPlans: plansAfterPromoApplied,
        selectedPlan: plansAfterPromoApplied.find((p) => p.id === state.selectedPlan.id),
        appliedPromoCodeDetail: promoCodeDetail,
        promoCodeMessage: { success: true, message: 'Promo Code Successfully applied!' }
      }
    }
  },
  effects: {
    async tokenizeCardInfo ({ cardRef, firstName, lastName, street1, street2, state, city, zip }) {
      try {
        const { token } = await cardRef.current.tokenize({
          firstName,
          lastName,
          billingAddr1: street1,
          billingAddr2: street2,
          billingCity: city,
          billingStateCode: state,
          billingZip: zip,
          billingCountry: 'US'
        })
        this.onTokenGenerated(token)
        return token
      } catch (e) {
        this.onError({
          errorType: 'chargebee_token'
        })
      }
    },
    clearPaymentError () {
      this.onError(null)
    },
    async upgradeSubscription (payload) {
      this.clearPaymentError()
      this.onRequest()
      try {
        const res = await createSubscription(payload)
        await this.onUpgradeSuccess(res)
        return res
      } catch (e) {
        this.onError(e.data)
      }
    },
    async upgradeSubscriptionV2 (payload) {
      this.clearPaymentError()
      this.onRequest()
      try {
        const res = await createSubscriptionV2(payload)
        await this.onUpgradeSuccessV2(res)
        return res
      } catch (e) {
        this.onError(e && e.data)
      }
    },
    async fetchUpgradeToProPlan () {
      const proPlans = await fetchUpgradeToProPlan()
      this.onFetchProPlansSuccess(proPlans)
    },
    async setSelectedPlan (plan) {
      this.onPlanSelected(plan)
    },
    async applyPromoCode (promoCode, { upgradePlan }) {
      this.setPromoCodeMessage({
        message: '',
        success: true
      })

      try {
        const promoCodeDetail = await getPromoCodeDetail(promoCode)

        const estimates = []
        for (const plan of upgradePlan.proPlans) {
          try {
            estimates.push(
              await estimateSubscription({
                promoCode,
                planId: plan.id
              })
            )
          } catch (err) {
            console.error(err)
            estimates.push({})
          }
        }

        const isAllUndefined = !estimates.some(({ estimatedAmount }) => estimatedAmount !== undefined)
        if (isAllUndefined) {
          throw new Error('Promo code not applicable to both plans')
        }

        const plansAfterPromoApplied = _.zip(upgradePlan.proPlans, estimates).map(
          ([plan, estimate]) =>
            new UpgradeToProPlanDetail({
              ...plan,
              priceAfterPromo: estimate.estimatedAmount === undefined ? plan.price : estimate.estimatedAmount,
              promoCode: estimate.estimatedAmount === undefined ? '' : promoCodeDetail.id
            })
        )

        console.log(plansAfterPromoApplied, promoCodeDetail)
        this.onPromoCodeApplied({ plansAfterPromoApplied, promoCodeDetail })
      } catch (e) {
        console.error(`error`, e)

        if (e.data) {
          if (e.data.err) {
            const error = e.data.err
            if (error.http_status_code === 404) {
              // promo code not found
              return this.setPromoCodeMessage({
                message: `Sorry, this promo code does not exist`,
                success: false
              })
            }
          }

          if (e.data.code === 'coupon_expired') {
            // expired
            return this.setPromoCodeMessage({
              message: `Sorry, this promo code has expired`,
              success: false
            })
          }
        }

        // all other error
        return this.setPromoCodeMessage({
          message: `Sorry, this promo code is not applicable`,
          success: false
        })
      }
    },
    async migrateUser () {
      this.clearPaymentError()
      this.onRequest()
      try {
        const res = await migrateUser()
        return res.data
      } catch (e) {
        console.log('Error while migrating user => ', e)
        this.onError('Error during payment, please try again later')
      }
    }

  }
}
