import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";


const mapDispatchToProps = ({ auth }) => {
    return {
        ...auth
    };
};
const mapStateToProps = ({ auth }) => {
    return {
        ...auth
    };
};
class OuterLayout extends React.Component {
    async componentDidMount() {
        if (!this.props.domainSettings)
            await this.props.validateDomain()
    }
    render() {
        const { children,domainSettings } = this.props
        return (
            <div>
                {domainSettings && domainSettings._id ?
                    <main className="outer-layout-main-wrapper">
                        {children}
                    </main> : null }
            </div>
        )
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(OuterLayout);