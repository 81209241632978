import React from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import Loader from "../loader";
import { Link } from 'react-router-dom';

const mapDispatchToProps = ({ auth }) => {
    return {
        ...auth,
    };
};
const mapStateToProps = ({ auth }) => {
    return {
        ...auth,
    };
};
export class UpgradeLayout extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerLogo: "",
            pageLoading: true
        }
    }

    componentDidMount() {
        this.init()
    }

    async init() {
        if (!this.props.domainSettings) {
            await this.props.validateDomain()
        }
        if (this.props.domainSettings && this.props.domainSettings.logoUrl) {
            this.setState({ headerLogo: this.props.domainSettings.logoUrl });
        }
        if (!this.props.me && this.props.user)
            await this.props.getMe();
        this.setState({ pageLoading: false })
    }
    onLogoError(ev) {
        if (!this.state.errored) {
            ev.target.onerror = null
            ev.target.style.display = "none"
            this.setState({ errored: true });
        }
    }
    render() {
        const { children } = this.props
        const { headerLogo } = this.state

        return (
            <div className={"app-body overlay-loader-outer "} >
                {this.props.me && this.props.me._id ?
                    <main className="main update-main-layout mw-100 overflow-hidden h-100">
                        <header className="main-header upgrade-main-header fixed-top">
                            <div className={"container-fluid d-flex align-items-center justify-content-center h-100"}>
                                {headerLogo ?
                                    <Link to={`/${this.props.user.user_unique_id}/home`} className="d-flex b-custom-logo align-items-center ">
                                        <img className="image-logo" alt="" src={headerLogo} onError={this.onLogoError.bind(this)} />
                                    </Link> : null}
                            </div>
                        </header>
                        <div className="page-upgrade-wrapper d-xl-flex align-items-xl-center">
                            {children}
                        </div>
                    </main>
                    :
                    <div className="full-page-loader">
                        <Loader />
                    </div>
                }
            </div>
        )
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(UpgradeLayout);