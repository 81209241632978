import { estimateUpdateSubscription, updateSubscription } from '../service'

export default {
  state: {
    updateSusbcriptionEstimatedResult: undefined,
    updatedResult: undefined,
    error: undefined
  },
  reducers: {
    onClearUpdateSusbcriptionEstimatedResult (state) {
      return {
        ...state,
        updateSusbcriptionEstimatedResult: undefined
      }
    },
    onClearUpdateResult (state) {
      return {
        ...state,
        error: undefined,
        updatedResult: undefined
      }
    },
    onUpdateSubscriptionEsitmated (state, updateSusbcriptionEstimatedResult) {
      return {
        ...state,
        updateSusbcriptionEstimatedResult
      }
    },
    onSubscriptionUpdated (state, updatedResult) {
      return {
        ...state,
        updatedResult
      }
    },
    onError (state, error) {
      return {
        ...state,
        error
      }
    }
  },
  effects: {
    clearResult () {
      this.onClearUpdateSusbcriptionEstimatedResult()
      this.onClearUpdateResult()
    },
    async estimateUpdateSubscription ({ plan_id, addons }) {
      this.onClearUpdateSusbcriptionEstimatedResult()
      const updateSusbcriptionEstimatedResult = await estimateUpdateSubscription({
        plan_id,
        addons
      })
      this.onUpdateSubscriptionEsitmated(updateSusbcriptionEstimatedResult)
    },
    async updateSubscription ({ planId, productAddons, limitAddons }) {
      this.onClearUpdateResult()
      try {
        const res = await updateSubscription({ planId, productAddons, limitAddons })
        console.log(`res`, res)
        this.onSubscriptionUpdated(res)
        return res
      } catch (e) {
        this.onError(e)
        console.error(e)
      }
    }
  }
}
