import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import i18n from '@/i18n'

export default {
  state: {
    AddServiceError: false,
    emailAnalytics: {},
    emailActivity: {},
    emailDetails: {}
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        AddServiceError: false
      }
    },
    onError(state, data) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        AddServiceError: true
      }
    },

    getServicesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        services: data.data
      }
    },
    deleteSuccess(state, data) {
      notification('success', i18n.t('services.service-deleted-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    oncreateServiceSuccess(state, data) {
      notification('success', i18n.t('services.service-created-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    onUpdateServiceSuccess(state, data) {
      notification('success', i18n.t('services.service-updated-successfully'))
      return {
        ...state,
        loading: false
      }
    },
    ongetServiceUserSuccess(state, data) {
      return {
        ...state,
        loading: false,
        serviceUser: data.servicesuser
      }
    },
    onsortServicesSuccess(state, data) {
      notification('success', 'Services reordered successfully')
      return {
        ...state,
        loading: false
      }
    },
    reviewsEmailAnalyticsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        emailAnalytics: data
      }
    },
    analyticsForGraphSuccess(state, data) {
      return {
        ...state,
        loading: false,
        analyticsGraph: data
      }
    },
    emailCountSuccess(state, data) {
      return {
        ...state,
        loading: false,
        emailCount: data
      }
    },
    emailListSuccess(state, data) {
      return {
        ...state,
        loading: false,
        emailListDetails: data
      }
    },
    getEmailCampaignSuccess(state, data) {
      return {
        ...state,
        loading: false,
        emailDetails: data
      }
    },
    emailCampaignActivitySuccess(state, data) {
      return {
        ...state,
        loading: false,
        emailActivity: data
      }
    },
    cloneCampaignSuccess(state, data) {
      return {
        ...state,
        loading: false,
        cloneData: data
      }
    },
    removeEmailCampaignSuccess(state, data) {
      let campaign_status = null
      campaign_status = data.campaign_status
      notification(
        'success',
        campaign_status === 'archive'
          ? 'The Engagement archived successfully'
          : 'Engagement has been removed successfully'
      )
      return {
        ...state,
        loading: false
      }
    },
    onGenerateApiTokenSuccess(state, data) {
      return {
        ...state,
        loading: false,
        sessionData: data
      }
    }
  },
  effects: {
    async getServices(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getServices(payload)
        await this.getServicesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async reviewsEmailAnalytics(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.reviewsEmailAnalytics(payload)
        await this.reviewsEmailAnalyticsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async analyticsForGraph(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.analyticsForGraph(payload)
        await this.analyticsForGraphSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async emailCountList(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.emailCountList(payload)
        await this.emailCountSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async emailList(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.emailList(payload)
        await this.emailListSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async removeEmailCampaign(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeEmailCampaign(payload)
        res.campaign_status = payload.campaign_status
        await this.removeEmailCampaignSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getEmailCampaign(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getEmailCampaign(payload)
        await this.getEmailCampaignSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async emailCampaignActivity(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.emailCampaignActivity(payload)
        await this.emailCampaignActivitySuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async cloneCampaign(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.cloneCampaign(payload)
        await this.cloneCampaignSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async generateApiToken(payload) {
      try {
        const res = await service.generateApiToken(payload)
        this.onGenerateApiTokenSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
