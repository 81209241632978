import React, { Component } from 'react'
import Popup from '@components/popups/basic-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import styles from '../index.module.scss'
import { withTranslation } from 'react-i18next'

class ResetPwdPopup extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false
    }
  }

  handleSubmit(values) {
    this.props.handleSubmitFunction(values)
  }
  handleCancel() {
    //  this.props.closeModal()
  }
  render() {
    const t = this.props.t
    return (
      <Popup
        open={this.props.open}
        title={t('home.change-password')}
        type="small"
        overrideForm={true}
        modalBackdropClicked={this.handleCancel.bind(this)}>
        <Formik
          initialValues={{
            new_password: '',
            confirm_password: ''
          }}
          validationSchema={Yup.object().shape({
            new_password: Yup.string().required(t('home.required')),
            confirm_password: Yup.string()
              .required(t('home.required'))
              .when('new_password', {
                is: (val) => !!(val && val.length > 0),
                then: Yup.string().oneOf([Yup.ref('new_password')], t('home.passwords-do-not-match'))
              })
          })}
          enableReinitialize={true}
          onSubmit={this.handleSubmit.bind(this)}
          // onReset={this.handleCancel.bind(this)}
          render={({ values, setFieldValue, resetForm }) => {
            return (
              <Form className="mt-sm-20">
                <div className="row">
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="label-text">
                        PASSWORD <i className="star text-danger">&nbsp;*</i>
                        <ErrorMessage name="new_password" component="span" className="form-error mb-0" />
                      </label>
                      <Field
                        className="form-control form-control-lg"
                        placeholder={t('home.enter-password')}
                        name="new_password"
                        type={'password'}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12">
                    <div className="form-group">
                      <label className="label-text">
                        {' '}
                        {t('home.confirm-password')}
                        <i className="star text-danger">&nbsp;*</i>
                        <ErrorMessage name="confirm_password" component="span" className="form-error mb-0" />
                      </label>
                      <Field
                        className="form-control form-control-lg "
                        placeholder={t('home.confirm-password-0')}
                        name="confirm_password"
                        type={'password'}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-sm-12 col-md-12 information-text d-block pt-10 pb-30">
                    <p className="d-block mb-0 light-text-color" style={{ fontSize: '0.750rem' }}>
                      {t('home.by-clicking-change-password-you-agree-to-the')}{' '}
                      <a
                        style={{ fontFamily: 'Open Sans Bold,sans-serif' }}
                        className={styles['link']}
                        title={t('home.terms-of-service')}
                        href="https://www.gosite.com/terms-of-service"
                        target="_blank"
                        rel="noopener noreferrer">
                        {t('home.terms-and-conditions')}
                      </a>
                    </p>
                  </div>
                </div>
                <footer className="d-block d-sm-flex mt-10">
                  <button
                    type="submit"
                    disabled={this.props.loading}
                    className={
                      'btn btn-primary btn-xl border-0 flex-grow-1 add-btn d-block d-sm-inline-block mb-20 mb-sm-0 mr-20'
                    }>
                    {t('home.change-password-0')}
                    {this.props.loading ? (
                      <img
                        className="spinner ml-10 position-relative top-minus-2"
                        src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                        alt=""
                      />
                    ) : null}
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}
export default withTranslation(null, { withRef: true })(ResetPwdPopup)
