interface PopupParams {
  height?: number;
  width?: number;
  centered: boolean;
  url: string;
  windowName?: string;
}
const openExternalPopupWindow = (wind: PopupParams, cb?: () => void): Window | null => {
  var parameters = 'location=1';
  if (!wind.height) wind.height = 650;
  if (!wind.width) wind.width = 800;
  parameters += ',width=' + wind.width + ',height=' + wind.height;
  if (wind.centered) {
    parameters +=
      ',left=' + (window.screen.width - wind.width) / 2 + ',top=' + (window.screen.height - wind.height) / 2;
  }
  const popup = window.open(wind.url, wind.windowName || 'ippPopupWindow', parameters);
  if (cb && popup) onPopupClose(popup, cb);
  return popup;
};

// https://stackoverflow.com/questions/3291712/is-it-possible-to-open-a-popup-with-javascript-and-then-detect-when-the-user-clo
export const onPopupClose = (popup: Window, cb: () => void, pollInterval = 200) => {
  const polltimer = setInterval(() => {
    if (popup.closed !== false) {
      clearInterval(polltimer);
      cb();
    }
  }, pollInterval);
};

export default openExternalPopupWindow;
