import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EditEstimatePopup } from '@components/estimates/EditEstimatePopup'
import { EstimateSuccessPopup } from '@components/estimates/EstimateSuccessPopup'
import { history } from '@app/store'

import { Box } from '@gositeinc/ui'

import moment from 'moment'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'

export const RenewEstimatePopupContainer = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const me = useSelector((state) => state.auth.me)
  const uploadingAttachments = useSelector((state) => state.estimates.uploadingAttachments)
  const uploadedAttachments = useSelector((state) => state.estimates.uploadedAttachments)

  const [successPopupOpen, setSuccessPopupOpen] = useState(false)
  const [estimate, setEstimate] = useState(undefined)
  const [updatedEstimate, setUpdatedEstimate] = useState(undefined)

  const showCreateEstimateDialog = (event) => {
    setEstimate({
      ...event.detail.estimate,
      expiredAt: moment().add(1, 'week').toDate(),
      items: event.detail.estimate.items
    })
  }

  useEffect(() => {
    document.addEventListener('gosite-showRenewEstimatePopup', showCreateEstimateDialog)

    return () => {
      document.removeEventListener('gosite-showRenewEstimatePopup', showCreateEstimateDialog)
    }
  }, [])

  return (
    <>
      {estimate && (
        <EditEstimatePopup
          smsEnabled={
            _.get(me, 'account_settings.twilio.length', 0) > 0 || _.get(me, 'role_id.role_name', undefined) === 'trial'
          }
          open={estimate !== undefined}
          setOpen={() => setEstimate(undefined)}
          title={t('estimates.renew-estimate-0', {
            0: estimate.displayId
          })}
          defaultEstimate={estimate}
          uploadingAttachments={uploadingAttachments}
          uploadedAttachments={uploadedAttachments}
          onCancelUploadingEstimateAttachment={(fileName) => {
            dispatch.estimates.onCancelUploadingEstimateAttachment(fileName)
          }}
          onClearUploadingEstimateAttachments={() => {
            dispatch.estimates.onClearUploadingEstimateAttachments()
          }}
          onClearUploadedEstimateAttachments={() => {
            dispatch.estimates.onClearUploadedEstimateAttachments()
          }}
          onRemoveUploadedEstimateAttachment={(fileName) => {
            dispatch.estimates.onRemoveUploadedEstimateAttachment(fileName)
          }}
          uploadEstimateAttachments={(files) => {
            dispatch.estimates.uploadEstimateAttachments(files)
          }}
          onSetUploadedEstimateAttachments={(attachments) => {
            dispatch.estimates.onSetUploadedEstimateAttachments(attachments)
          }}
          confirmButtonText={t('estimates.renew-estimate')}
          onSearchContact={async (searchText) => {
            const contacts = await dispatch.contact.autosuggestContacts({
              nopaging: true,
              status: 'active',
              excludeNullGroup: true,
              search: searchText || ''
            })

            return contacts || []
          }}
          onCreateNewContact={async ({ phone, email, name }) => {
            const { data: contact } = await dispatch.payment.savePrivateContact({
              email,
              mobile: phone,
              from_messenger: false,
              groupId: 0,
              is_public: true,
              query: {
                exclude_group: 1
              }
            })

            return contact
          }}
          onSearchService={async (searchText) => {
            const res = await dispatch.services.getServices({
              nopaging: true,
              search: searchText || ''
            })

            if (!res) {
              return []
            }

            const { data: services } = res
            return services.map(({ _id, title, price }) => ({ name: title, price, id: _id }))
          }}
          onCancel={() => {
            setEstimate(undefined)
          }}
          onConfirm={async ({ contact, sendTime, sendVia, expiredAt, items, attachments }) => {
            const res = await dispatch.estimates.renewEstimate({
              estimateId: estimate._id,
              body: {
                contact,
                sendTime,
                sendVia,
                expiredAt,
                items,
                message: estimate.message,
                attachments
              }
            })

            if (!res) {
              return
            }

            setUpdatedEstimate({
              ...estimate,
              contact,
              sendTime,
              sendVia,
              expiredAt,
              items,
              attachments
            })

            setEstimate(undefined)
            setSuccessPopupOpen(true)
          }}
        />
      )}
      <EstimateSuccessPopup
        open={successPopupOpen}
        setOpen={(open) => setSuccessPopupOpen(open)}
        title={t('estimates.renewed-estimate-sent-successfully')}
        description={
          <Box
            sx={{
              p: {
                textAlign: 'center'
              }
            }}>
            <p>
              {t('estimates.your-customer')}{' '}
              {_.get(updatedEstimate, 'contact.email', '') || _.get(updatedEstimate, 'contact.phone', '')}{' '}
              {t('estimates.should-receive-the-renewed-estimate-shortly')}
            </p>
            <p>
              {t(
                'estimates.once-the-estimate-has-been-accepted-the-status-will-update-automatically-in-your-gosite-dashboard'
              )}
            </p>
          </Box>
        }
        confirmButtonText={t('estimates.back-to-dashboard')}
        onConfirm={() => {
          dispatch.estimates.getEstimateHistories({
            estimateId: updatedEstimate._id,
            query: { sort: { createdAt: 'desc' } }
          })
          dispatch.estimates.getEstimate(updatedEstimate._id)

          setUpdatedEstimate(undefined)
          setSuccessPopupOpen(false)
          document.dispatchEvent(new Event('gosite-estimateRenewed'))

          if (window.location.pathname.split('/').pop() !== 'estimates') {
            history.replace(`/${me.user_unique_id}/estimates`)
          }
        }}
      />
    </>
  )
}
