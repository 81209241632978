import React, { useState } from 'react'

import 'react-datepicker/dist/react-datepicker.css'
import AsyncCreatableSelect from 'react-select/async-creatable'
import Icon from '@components/icons'
import Tooltip from '@components/tooltip'
import notification from '@components/notification'
import _ from 'lodash'

import { numberMask } from '@helpers/inputmask'
import { phoneNumberFormat, validateEmail as isEmail, validateMobile as isMobile } from '@helpers/utility'
import { Box } from '@gositeinc/ui'

const customOptions = ({ label, subLabel, image, index }, { context }) => {
  if (context === 'value') {
    return (
      <div key={index} className="d-flex flex-grow-0">
        <Box
          className="mr-10"
          sx={{
            figure: {
              minWidth: '30px',
              width: '30px',
              objectFit: 'cover'
            }
          }}>
          <figure className={`mb-0`}>
            <img
              className={`w-100 rounded-circle`}
              src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
              alt=""
            />
          </figure>
        </Box>
        <div className="d-flex flex-grow-1 justify-content-between align-items-center">
          <div>{label || subLabel}</div>
        </div>
      </div>
    )
  }
  if (context === 'menu') {
    return (
      <React.Fragment>
        <div data-test="select-invoice-contact-option-value" id="contactOptionValue" className="d-flex flex-grow-0">
          <Box
            className="mr-10"
            sx={{
              figure: {
                minWidth: '30px',
                width: '30px',
                objectFit: 'cover'
              }
            }}>
            <figure className={`mb-0`}>
              <img
                className={`w-100 rounded-circle`}
                src={image || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                alt=""
              />
            </figure>
          </Box>
          <div className="d-flex align-items-center flex-grow-1 justify-content-between">
            <div className="text-truncate max-w">{label || subLabel}</div>
            <div className="text-truncate max-w">{label && subLabel ? `${subLabel}` : ''}</div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

export const ContactSelector = ({
  name,
  value,
  onChange,
  loadOptions,
  onCreateNewContact,
  emailPlaceHolder,
  phonePlaceHolder
}) => {
  const [hasInitialName, setHasInitialName] = useState(false)
  const [originalValues, setOriginalValues] = useState({
    name: '',
    phone: '',
    email: ''
  })

  const onSelectChange = (value) => {
    // remove null fields
    value = _.omitBy(value, _.isNull)
    if (value.phone) {
      value.phone = phoneNumberFormat(value.phone)
    }

    setHasInitialName(Boolean(value.name))
    onChange(value)
    setOriginalValues(value)
  }

  const shouldShowRevertEmail = originalValues.email && value && originalValues.email !== value.email
  const shouldShowRevertPhone = originalValues.phone && value && originalValues.phone !== value.phone

  return (
    <>
      {/* <div>value: {JSON.stringify(value)}</div> */}
      {!value ? (
        <AsyncCreatableSelect
          name={name}
          isClearable
          placeholder="Name, Phone or Email"
          className="dropdown-select no-arrow-down"
          classNamePrefix="dropdownSelect"
          loadOptions={loadOptions}
          value={value}
          formatOptionLabel={customOptions}
          noOptionsMessage={() => null}
          onChange={async (selectedContact, { action }) => {
            if (!selectedContact) {
              return
            }

            console.log(selectedContact)
            if (action === 'create-option') {
              const isSearchTextEmail = isEmail(selectedContact.label)
              const isSearchTextMobile = isMobile(selectedContact.label)

              if (!isSearchTextEmail && !isSearchTextMobile) {
                notification('error', 'Please enter a valid email or mobile')
                onChange(null)
                return
              }

              const selected = {
                ...(!isSearchTextMobile && !isSearchTextEmail ? { name: selectedContact.label } : {}),
                ...(isSearchTextEmail ? { email: selectedContact.label } : {}),
                ...(isSearchTextMobile ? { phone: selectedContact.label } : {})
              }

              const contact = await onCreateNewContact(selected)

              onSelectChange({
                ...selected,
                id: contact._id
              })
              return
            }

            onSelectChange(selectedContact.value)
          }}
          formatCreateLabel={(userInput) => (
            <div className="react-select-unavailable-data">
              <span className="react-select-highlight-text">Create New Contact</span>
            </div>
          )}
          isValidNewOption={(inputValue, selectValue, selectOptions) => {
            if (inputValue.length < 3) {
              return false
            }

            return (selectOptions || []).length <= 0
          }}
        />
      ) : (
        <div className="selected-user border rounded-sm">
          <div className="row-user-contact border-bottom d-flex align-items-center position-relative">
            <div className="user-contact pl-15">Contact</div>
            {!value.name || !hasInitialName ? (
              <React.Fragment>
                <div className="user-phone-value pr-25">
                  <input
                    type="text"
                    name="name"
                    className="form-control form-control-lg"
                    placeholder={'(Optional)'}
                    value={value.name}
                    onChange={(event) => {
                      onChange({
                        ...value,
                        name: event.target.value
                      })
                    }}
                  />
                </div>
                <div className="position-absolute cursor-pointer right-0 mr-15 icon-block">
                  <Icon
                    onClick={() => {
                      onChange(undefined)
                    }}
                    name="closeThin"
                    className="stroke-icon d-block"
                  />
                </div>
              </React.Fragment>
            ) : (
              <>
                {/* <Box
                  sx={{
                    figure: {
                      minWidth: '30px',
                      width: '30px',
                      objectFit: 'cover'
                    }
                  }}> */}
                <figure
                  className="mr-10 mb-0 ml-30"
                  style={{
                    minWidth: '30px',
                    width: '30px'
                  }}>
                  <img
                    className={`w-100 rounded-circle`}
                    src={'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                    alt=""
                    style={{
                      objectFit: 'cover'
                    }}
                  />
                </figure>
                {/* </Box> */}
                <div className="user-names d-flex justify-content-between">
                  {<span>{value.name}</span>}
                  <Icon
                    onClick={() => {
                      onChange(undefined)
                    }}
                    name="closeThin"
                    className="stroke-icon"
                  />
                </div>
              </>
            )}
          </div>
          <div className="row-user-email border-bottom d-flex align-items-center position-relative">
            <div className="user-email pl-15">Email</div>
            <div className="user-email-value pr-20">
              <input
                type="text"
                name="email"
                className="form-control form-control-lg"
                placeholder={emailPlaceHolder}
                value={value.email}
                onChange={(event) => {
                  onChange({
                    ...value,
                    email: event.target.value
                  })
                }}
              />
            </div>
            {shouldShowRevertEmail && (
              <div
                className=" position-absolute cursor-pointer right-0 mr-15"
                onClick={() => {
                  onChange({
                    ...value,
                    email: originalValues.email
                  })
                }}>
                <Tooltip position="left" message="Revert to email on file">
                  <Box
                    sx={{
                      'svg:hover': {
                        path: {
                          fill: '$primary'
                        }
                      }
                    }}>
                    <Icon name="undoThin" />
                  </Box>
                </Tooltip>
              </div>
            )}
          </div>
          <div className="row-user-phone  d-flex align-items-center position-relative">
            <div className="user-phone pl-15">Phone</div>
            <div className="user-phone-value pr-20">
              <input
                type="text"
                name="mobile"
                className="form-control form-control-lg"
                placeholder={phonePlaceHolder}
                value={value.phone}
                maxLength={14}
                onChange={(event) => {
                  onChange({
                    ...value,
                    phone: numberMask(event.target.value)
                  })
                }}
              />
            </div>
            {shouldShowRevertPhone && (
              <div
                className=" position-absolute cursor-pointer right-0 mr-15"
                onClick={() => {
                  onChange({
                    ...value,
                    phone: originalValues.phone
                  })
                }}>
                <Tooltip position="left" message="Revert to phone on file">
                  <Box
                    sx={{
                      'svg:hover': {
                        path: {
                          fill: '$primary'
                        }
                      }
                    }}>
                    <Icon name="undoThin" />
                  </Box>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  )
}
