import React, { PureComponent } from 'react'
import { compose } from 'recompose'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import { CardNumberElement as StripeCardNumberElement } from '@stripe/react-stripe-js'
import Popup from './basic-modal'
import Icon from '../icons'
import moment from 'moment'
import PredefinedTemplate from '../predefined-templates'
import config from '@config/index'
import Tooltip from '../tooltip'
import { UsCurrencyFormatter, setpaymentAttempt, getPaymentCount } from '@helpers/utility'
import ConfirmPopup from './confirm-modal'
import './booking-payment-popup.scss'
import { segmentEventTrack } from '@helpers/segment'
import ReCAPTCHA from 'react-google-recaptcha'
import notification from '@components/notification'
import { withElements, withElementsConsumer, ElementState, CardCvcElement, CardNumberElement, CardExpiryElement } from '@containers/payment/utility/stripe'

class BookingPaymentStripePopup extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      type: '',
      saveContact: false,
      payloadValues: '',
      showReCaptcha: false,
      capthcaValue: ''
    }
  }

  closeModal () {
    this.props.closeModal()
    // isFormChanged = false
  }

  async handleSubmit (values, { resetForm }) {
    const { stripe, stripeElements: elements } = this.props
    if (this.state.showReCaptcha) {
      if (!this.state.capthcaValue) {
        notification('error', 'Please complete the reCaptcha')
        return
      }
    }
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardNumberElement = elements.getElement(StripeCardNumberElement)

    // Use your card Element with other Stripe.js APIs
    var paymentId
    if (cardNumberElement !== null) {
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardNumberElement,
        billing_details: {
          name: values.txtCardHolder
        }
      })
      if (error) {
        notification('error', 'Error collecting payment.')
        return
      }
      paymentId = paymentMethod.id
    }
    const payloadData = Object.assign(this.props.payloadData)
    if (values.email_subject) {
      payloadData.email_subject = values.email_subject
    }
    if (values.email_subject) {
      payloadData.customer_message = values.customer_message
    }
    if (values.saveContact) {
      payloadData.contact_update = true
      // payloadData.contact_id = payloadData.contact_id
    } else {
      payloadData.contact_update = false
    }
    if (payloadData.collect_via === 'direct') {
      //   if (!this.state.cardToken) {
      //     this.setState({ cardError: true })
      //     return
      //   }
      //   payloadData.card_token = this.state.cardToken
      payloadData.cvv = values.cvv
      payloadData.expiry = values.expiry
      payloadData.postal = values.postal
      payloadData.card_holder = values.txtCardHolder
    }
    payloadData.payment_amount = this.getPaymentAmount()
    if (payloadData._id) {
      payloadData.booking_id = payloadData._id
    }
    // FOR ENTERPRISE ACCOUNT
    if (payloadData.parent_user && this.props.isEnterprise) {
      payloadData.location = payloadData.parent_user
    }

    // ADDED TO AVOID APPOINTMENT START TIME MISSING ERROR
    if (payloadData.appointment_time) {
      const timeArray = payloadData.appointment_time.split('-')
      payloadData.appointment_start_time = timeArray[0]
      payloadData.appointment_end_time = timeArray[1]

      if (payloadData.appointment_date) {
        payloadData.appointment_date = moment.utc(payloadData.appointment_date).format('L')
      }
    }
    // TILL HERE

    payloadData.admin_offline = true
    payloadData.email_notification = true
    if (payloadData.collect_via) {
      payloadData.is_payment = true
    }
    const segTrackData = {
      'event': 'add_booking_payment_complete',
      'properties': {
        'user_id': this.props.me && this.props.me._id,
        'role': this.props.me && this.props.me.role_id && this.props.me.role_id.role_name && this.props.me.role_id.role_name && this.props.me.role_id.role_name === 'trial' ? 'free-trial' : this.props.me.role_id.role_name,
        services_edit: this.props.isEdit,
        payment_amount: payloadData.payment_type ? payloadData.payment_type : null, // Full or partial payment
        payment_method: payloadData.collect_via === 'direct' ? 'manual' : (payloadData.collect_via === 'getpaid' ? 'payment_link' : null)
      }
    }
    // console.log(segTrackData , "segTrackData")
    segmentEventTrack(segTrackData)
    if (payloadData.collect_via === 'direct') {
      setpaymentAttempt()
    }
    payloadData.paymentMethod = paymentId
    payloadData.stripeObj = stripe
    await this.props.submitData(payloadData)
  }
  getContactAddText () {
    return 'Update contact with new information'
  }
  selectTemplateFn (selectedItem, setFieldValue) {
    setFieldValue('customer_message', selectedItem.message)
  }
  getFulladdress (data) {
    let addressField = ''
    if (data.service_address2) {
      addressField = addressField ? `${addressField}, ${data.service_address2}` : data.service_address2
    }
    if (data.service_city) {
      addressField = addressField ? `${addressField}, ${data.service_city}` : data.service_city
    }
    if (data.service_state) {
      addressField = addressField ? `${addressField}, ${data.service_state}` : data.service_state
    }
    if (data.service_zip) {
      addressField = addressField ? `${addressField}, ${data.service_zip}` : data.service_zip
    }
    return addressField || ''
  }
  componentDidMount () {
    window.addEventListener('message', this.listenCardConnect)
    let title; let type = ''
    let amountToPay = 0

    const { payloadData } = this.props
    if (payloadData.payment_type === null) {
      payloadData.payment_type = 'full'
    }
    this.setState({ payloadValues: payloadData })
    if (payloadData && payloadData.collect_via === 'direct') {
      title = 'Manual Transaction'
    } else if (payloadData && payloadData.collect_via === 'getpaid') {
      title = 'Send a Payment Link'
    } else if (payloadData && payloadData.collect_via === 'invoice') {
      title = 'Send an Invoice'
    }
    if (payloadData && ((payloadData.send_email && payloadData.send_sms) || (payloadData.customer_email && payloadData.customer_phone))) {
      type = 'email&sms'
    } else if (payloadData && ((!payloadData.send_email && payloadData.send_sms) || (!payloadData.customer_email && payloadData.customer_phone))) {
      type = 'sms'
    } else if (payloadData && ((payloadData.send_email && !payloadData.send_sms) || (payloadData.customer_email && !payloadData.customer_phone))) {
      type = 'email'
    }
    if (payloadData && payloadData.is_payment) {
      if (payloadData.allow_partial_payment) {
        if (payloadData.minimum_payment_type === 'percentage') {
          amountToPay = payloadData.service_rate * payloadData.service_quantity * (payloadData.minimum_payment_percentage / 100)
        } else if (payloadData.minimum_payment_type === 'amount') {
          amountToPay = payloadData.minimum_payment_amount
        }
      } else {
        amountToPay = payloadData.service_rate * payloadData.service_quantity
      }
    } else if (payloadData.payment_type === 'partial') {
      let amount
      let amount_to_be_paid
      if (payloadData.payments === undefined && payloadData.minimum_payment_type === 'percentage') {
        amount_to_be_paid = amountToPay = payloadData.service_amount * (payloadData.minimum_payment_percentage / 100)
      } else if (payloadData.payments === undefined && payloadData.minimum_payment_type === 'amount') {
        amount_to_be_paid = amountToPay = payloadData.minimum_payment_amount
      } else if ((payloadData.payments && payloadData.payments.length > 0)) {
        if (payloadData.minimum_payment_type === 'percentage') {
          if (payloadData.payment_amount !== undefined && payloadData.minPer === undefined) {
            amount_to_be_paid = amountToPay = payloadData.payment_amount
          }
        } else {
          amount_to_be_paid = payloadData.minimum_payment_amount
        }
      }

      if (typeof amount_to_be_paid === 'number') {
        amount = amount_to_be_paid
      } else if (typeof amount_to_be_paid === 'string') {
        if (amount_to_be_paid.includes(',')) {
          amount = Number(amount_to_be_paid.replace(',', ''))
        } else {
          amount = amount_to_be_paid
        }
      }
      if (payloadData.minimum_payment_type === 'amount') {
        payloadData.payment_amount = amount
        amountToPay = payloadData.payment_amount
      } else {
        payloadData.payment_amount = amount
        amountToPay = payloadData.payment_amount
      }
    } else if (payloadData.payment_type === 'full') {
      if (payloadData.payments === undefined) {
        amountToPay = payloadData.service_amount
        payloadData.payment_amount = payloadData.service_amount
      } else {
        amountToPay = payloadData.payment_amount
        // payloadData.payment_amount = payloadData.service_amount
      }
    }
    this.setState({
      amountToPay,
      title,
      type
    })
    if (getPaymentCount() >= 10) {
      this.setState({ showReCaptcha: true })
    }
  }
  componentWillUnmount () {
    window.removeEventListener('message', this.listenCardConnect)
  }
  cancelAppointment () {
    if (!this.props.isFromBookingQuickView) {
      this.setState({ showCancelconfirm: true })
    } else {
      this.props.closeModal()
    }
  }
  closeConfirmation () {
    this.setState({ showCancelconfirm: false })
    this.props.closeModal()
  }
  async handleCancelAppointment () {
    const segTrackData = {
      'event': 'add_booking_payment_abandon',
      'properties': {
        'user_id': this.props.me && this.props.me._id,
        'role': this.props.me && this.props.me.role_id && this.props.me.role_id.role_name && this.props.me.role_id.role_name && this.props.me.role_id.role_name === 'trial' ? 'free-trial' : this.props.me.role_id.role_name,
        payment_amount: this.state.payloadValues.payment_type, // Full or partial payment
        payment_method: this.state.payloadValues.collect_via === 'direct' ? 'manual' : (this.state.payloadValues.collect_via === 'getpaid' ? 'payment_link' : null)
      }
    }
    segmentEventTrack(segTrackData)
    this.setState({ cancelProgress: true })
    await this.props.cancelIncompleteBooking()
    this.setState({ showCancelconfirm: false, cancelProgress: false })
    this.props.closeModal()
  }
  expiryValidation (setFieldValue, str) {
    str = str.replace(/_/g, '')
    const arr = str.split('/')
    if (arr[0] && !arr[1]) {
      if (Number(arr[0]) > 12) {
        arr[0] = '12'
      }
      const reg1 = /^(0[1-9]|1[012])$/
      if (arr[0].length <= 1 || (reg1.test(arr[0]))) {
        setFieldValue('expiry', `${arr[0]}/`)
      }
    } else if (arr[0] && arr[1]) {
      const currentYear = new Date().getFullYear().toString().substr(-2)
      if (arr[1].length > 1 && Number(arr[1]) < currentYear) { arr[1] = currentYear }

      const reg2 = /^(0[1-9]|[12]\d|3[01])$/
      if (arr[1].length <= 2 || reg2.test(arr[1])) {
        setFieldValue('expiry', `${arr[0]}/${arr[1]}`)
      }
    } else {
      setFieldValue('expiry', '')
    }
  }

  getPaymentAmount () {
    const { payloadData } = this.props
    const amountToPay = payloadData.payment_amount
    return amountToPay
  }

  showPaymentDeatils () {
    const { payloadData = {} } = this.props
    return (
      payloadData.payments.map(item => {
        if (item.status !== 'draft' && item.status !== 'deleted' && item.status !== 'refund' && item.status !== 'canceled') {
          return (< tr >
            <td className="td-item-label td-item-label-name position-relative">
              <div className="d-flex justify-content-between">
                <div className="flex-grow-1 pr-15 ">
                  <div className="item-name">
                    {(item.status === 'accepted' || item.status === 'processing' || item.status === 'capture') ? 'Deposit'
                      : item.status === 'pending' ? 'Deposit (Due)' : null
                    }
                  </div>
                </div>
              </div>
            </td>
            <td className="td-item-label td-item-label-rate text-right light-gray"></td>
            <td className="td-item-label td-item-label-rate text-right light-gray"></td>
            <td className="text-right td-item-label-total">
              <strong>-${UsCurrencyFormatter(item.amount)}</strong>
            </td>
          </tr >
          )
        } else return null
      })
    )
  }
  onChangeRecaptche (val) {
    this.setState({ capthcaValue: val })
  }
  render () {
    const { title, amountToPay, showReCaptcha } = this.state
    const { payloadData } = this.props
    // console.log("render======>",payloadData,amountToPay)
    return (
      <React.Fragment>
        <Popup
          open={this.props.open}
          title={`ADD BOOKING / ${title}`}
          hideHeader={true}
          type='default'
          modalBackdropClicked={() => console.log(null)}
          id="modalSendEmailSmsTypeLink"
        >
          <header className="custom-payment-header position-relative d-flex flex-wrap align-items-center">
            <h5 className="modal-title mb-0 mr-5 light-text-color">Add Booking /</h5>
            <h5 className="modal-title mb-0">{title}</h5>
          </header>
          <Formik
            validateOnChange={false}
            validateOnBlur={true}
            initialValues={
              payloadData.collect_via !== 'direct'
                ? {
                  email_subject: payloadData && payloadData.email_subject ? payloadData.email_subject : 'New Booking: ' + moment().format('MMMM YYYY'),
                  customer_message: payloadData && payloadData.customer_message ? payloadData.customer_message : 'Thanks for your interest in our services',
                  saveContact: this.state.saveContact
                  // payment_type:
                } : {
                  email_subject: payloadData && payloadData.email_subject ? payloadData.email_subject : 'New Booking: ' + moment().format('MMMM YYYY'),
                  customer_message: payloadData && payloadData.customer_message ? payloadData.customer_message : 'Thanks for your interest in our services',
                  saveContact: this.state.saveContact,
                  is_payment: payloadData && payloadData.is_payment ? payloadData.is_payment : false,
                  payment_type: '',
                  payment_amount: amountToPay,
                  txtCardHolder: '',
                  postal: '',
                  card_number: new ElementState(),
                  card_expiry: new ElementState(),
                  card_cvc: new ElementState()
                }}
            validationSchema={
              payloadData.collect_via !== 'direct'
                ? Yup.object().shape({
                  email_subject: Yup.string().required('Required'),
                  customer_message: Yup.string().required('Required')
                })
                : Yup.object().shape({
                  email_subject: Yup.string().required('Required'),
                  customer_message: Yup.string().required('Required'),
                  txtCardHolder: Yup.string().required('Required'),
                  postal: Yup.string().required('Required'),
                  card_number: Yup.object().cardNumberElement(),
                  card_expiry: Yup.object().cardExpiryElement(),
                  card_cvc: Yup.object().cardCvcElement()
                })
            }
            onReset={this.cancelAppointment.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form id="frmSendEmailSms" name="frmSentInvoice" className={'pt-20 send-email-sms-popup-wrapper'}>
                  <button name="btninvoiceCloseModal" type="reset" onClick={() => this.cancelAppointment()} className="close custom-modal-close-button remove-modal-close top-0 mt-10" >
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-20 custom-cursor-hide">
                        <label className="label-text">
                                                    SENDING TO
                        </label>
                        <div className="d-flex align-items-center border rounded-sm p-15">
                          <figure className={'mb-0 mr-15 b-image-sending'}>
                            <img className={'w-100 rounded-circle image'} src={payloadData && payloadData.photo ? payloadData.photo : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'} alt="" />
                          </figure>
                          <div className="">
                            <div className="d-flex mb-5">
                              <h4>
                                {payloadData && payloadData.first_name
                                  ? <span>{payloadData.first_name}</span> : null}
                                {payloadData && payloadData.last_name
                                  ? <span className="pl-5">{payloadData.last_name}</span> : null}
                              </h4>
                            </div>
                            <div className="d-flex">
                              {payloadData && payloadData.customer_phone && <div className={`${payloadData && payloadData.isMobileChanged ? 'text-primary' : ''}`}>{payloadData.customer_phone}</div>}

                              {(payloadData && payloadData.customer_phone && payloadData && payloadData.customer_email) ? <span className={`pl-5 pr-5 dot-separate ${payloadData && payloadData.isMobileChanged && payloadData.isEmailChanged ? 'text-primary' : ''}  `}></span> : null}

                              {payloadData && payloadData.customer_email && <div className={`${payloadData && payloadData.isEmailChanged ? 'text-primary' : ''}`}>{payloadData.customer_email}</div>}
                            </div>
                            {payloadData && payloadData.service_address
                              ? <div className={`${payloadData.isAddressChanged ? 'text-primary' : ''}`}>
                                <span>{payloadData && payloadData.service_address ? `${payloadData.service_address} ` : ''}</span>
                                <span>{payloadData ? this.getFulladdress(payloadData) : ''}</span>
                              </div>
                              : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {payloadData && (payloadData.isEmailChanged || payloadData.isMobileChanged || payloadData.isNameChanged || payloadData.isAddressChanged)
                      ? <div className="col-12">
                        <div className="form-group form-check mb-30">
                          <label>
                            <Field
                              type="checkbox"
                              className="h-cb"
                              checked={values.saveContact}
                              onChange={(event) => {
                                setFieldValue('saveContact', event.target.checked)
                                this.setState({ saveContact: event.target.checked })
                              }}
                            />
                            <span id="frm_addService_chkBookingHours" className="checkbox-icon mr-10"></span>
                            {this.getContactAddText()}
                          </label>
                        </div>
                      </div> : null}

                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                        <div className="d-flex align-items-center justify-content-between">
                          <label className="label-text pr-10">
                                                        SERVICE
                          </label>
                          {!this.props.isFromBookingQuickView
                            ? <button type="button" onClick={() => this.props.onBookingEdit()} className="btn edit-btn text-primary h-auto line-height-normal fz-12 px-0 mb-10">EDIT</button>
                            : null
                          }
                        </div>
                        <div>
                          <table className="table table-add-booking booking-payment-table rounded-sm">
                            <thead><tr>
                              <th>Item</th>
                              <th className="text-right qty">QTY</th>
                              <th className="text-right rate">Rate</th>
                              <th className="text-right amount">Amount</th>
                            </tr></thead>
                            <tbody>
                              <tr>
                                <td className="td-item-label td-item-label-name position-relative">
                                  <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1 pr-15 ">
                                      <div className="item-name">
                                        {payloadData && payloadData.selectedService ? payloadData.selectedService.title : payloadData.service_name ? payloadData.service_name : ''}
                                      </div>

                                    </div>

                                  </div>
                                </td>
                                <td className="td-item-label td-item-label-qty text-right light-gray">
                                  {payloadData && payloadData.service_quantity}
                                </td>
                                <td className="td-item-label td-item-label-rate text-right light-gray">

                                  {payloadData && payloadData.service_rate ? `$ ${UsCurrencyFormatter(parseFloat(payloadData.service_rate))}` : ''}
                                </td>
                                <td className="text-right td-item-label-total">
                                                                    ${UsCurrencyFormatter(parseInt(payloadData.service_quantity) * parseFloat(payloadData && payloadData.service_rate))}
                                </td>

                              </tr>

                              {this.props.isFromBookingQuickView && payloadData.payments && payloadData.payments.length
                                ? this.showPaymentDeatils()
                                : null}

                              <tr>
                                <td className="td-item-label td-item-label-name position-relative">
                                  <div className="d-flex justify-content-between">
                                    <div className="flex-grow-1 pr-15 ">
                                      <div className="item-name">
                                                                                Pay Amount
                                      </div>

                                    </div>

                                  </div>
                                </td>
                                <td className="td-item-label td-item-label-rate text-right light-gray"></td>
                                <td className="td-item-label td-item-label-rate text-right light-gray"></td>
                                <td className="text-right td-item-label-total">
                                  <strong>{`$${((amountToPay))}`}</strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    {payloadData && payloadData.collect_via === 'direct'
                      ? <>
                                                <div className="col-12 col-sm-12" >
                                                  <div className="form-group">
                                                    <label className="label-text ">CARDHOLDER NAME <i className="star">*</i> <Tooltip position="top" message="Please enter the full name displayed on the payment card being processed" /></label>
                                                    <ErrorMessage id="frmBooking_nameError" name="txtCardHolder" component="span" className="form-error" />
                                                    <Field
                                                      data-test="transaction-cardholder-name-field"
                                                      id="frmBooking__txtCardHolder"
                                                      className="form-control form-control-lg mb-20"
                                                      type="text"
                                                      placeholder="Enter Cardholder Name"
                                                      name="txtCardHolder"
                                                      value={values.txtCardHolder}
                                                      onChange={(event) => {
                                                        setFieldValue('txtCardHolder', event.target.value)
                                                      }}
                                                    />
                                                  </div>
                                                </div>
                                                <div className="col-12">
                                                  <div className="form-group mb-10">
                                                    <label className="label-text ">CARD NUMBER <i className="star">*</i></label>
                                                    <ErrorMessage name="card_number" component="span" className={'form-error'} />
                                                    <Field
                                                      name="card_number"
                                                      className="form-control pt-10 form-control-lg mb-20"
                                                      component={CardNumberElement}
                                                    />
                                                  </div>
                                                  <div className="row pb-10">
                                                    <div className="col-4">
                                                      <div className="form-group">
                                                        <label className="label-text "> EXP
                                                          <i className="star">*</i>
                                                          <ErrorMessage name="card_expiry" component="span" className="form-error" />
                                                        </label>
                                                        <Field
                                                          name="card_expiry"
                                                          className="form-control pt-10 form-control-lg"
                                                          component={CardExpiryElement}
                                                        />
                                                      </div>
                                                    </div>
                                                    <div className="col-4">
                                                      <div className="form-group">
                                                        <label className="label-text ">CVV <i className="star">*</i>
                                                          <ErrorMessage name="card_cvc" component="span" className="form-error" />
                                                        </label>
                                                        <Field
                                                          name="card_cvc"
                                                          className="form-control pt-10 form-control-lg"
                                                          component={CardCvcElement}
                                                        />
                                                      </div>
                                                    </div>

                                                    <div className="col-4">
                                                      <div className="form-group">
                                                        <label className="label-text ">ZIP <i className="star">*</i>
                                                          <ErrorMessage name="postal" component="span" className="form-error" />
                                                        </label>
                                                        <Field
                                                          placeholder={'Zip'}
                                                          className="form-control form-control-lg"
                                                          type="text"
                                                          value={values.postal}
                                                          name="postal"
                                                          onChange={e => {
                                                            e.preventDefault()
                                                            const { value } = e.target
                                                            if (value.length <= 5) {
                                                              setFieldValue('postal', value)
                                                            }
                                                          }}
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>

                                                </div>
                                                {showReCaptcha
                                                  ? <div className="col-12">
                                                    <ReCAPTCHA
                                                      sitekey={config.googleReCAPTCHAKey}
                                                      onChange={(val) => this.onChangeRecaptche(val)}
                                                    />
                                                  </div>
                                                  : null}
                                            </> : null}

                    {this.state.type === 'email' || this.state.type === 'email&sms'
                      ? <React.Fragment><div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-15 custom-cursor-hide">
                          <label className="label-text">
                                                        EMAIL SUBJECT
                            <ErrorMessage name="email_subject" component="span" className={'form-error'} />
                          </label>
                          <Field
                            type="text"
                            name="email_subject"
                            className="form-control form-control-lg"
                            placeholder="Enter subject"
                            onChange={event => {
                              setFieldValue('email_subject', event.target.value)
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                          <label className="label-text">
                                                            EMAIL MESSAGE <i className="star">*</i>
                            <ErrorMessage name="customer_message" component="span" className={'form-error'} />
                          </label>
                          <div className="mb-15">
                            {!this.props.isEnterprise && this.props.predefinedTemplates && this.props.predefinedTemplates.length
                              ? <PredefinedTemplate templates={this.props.predefinedTemplates} setFieldValue={setFieldValue} selectTemplateFn={this.selectTemplateFn.bind(this)} />
                              : null}
                          </div>
                          <Field
                            type="text"
                            name="customer_message"
                            className="form-control form-control-lg"
                            placeholder="Enter message"
                            onChange={event => {
                              setFieldValue('customer_message', event.target.value)
                              // isFormChanged = true
                            }}
                          />
                        </div>
                      </div></React.Fragment> : null}
                  </div>
                  <footer className="d-flex mt-10">
                    <button
                      className={'btn btn-primary border-0 btn-xl  mr-10 flex-fill'}
                      id="frmBtnSendEmailSms"
                      disabled={this.props.loader}
                      type="submit">
                      {this.props.submitText}
                      {this.props.loader && <i className={'btn-icon top-minus-2 mr-5 btn-loader'}><Icon name="spinner" /></i>}
                    </button>
                    <button
                      type="reset"
                      id="frmBtnSendEmailSms_btnCancel"
                      className={'btn btn-xl ml-10 btn-outline flex-fill'}>CANCEL
                    </button>
                  </footer>
                </Form>)
            }}
          />
        </Popup>
        <ConfirmPopup
          id="modalConfirm_cancelAppointment"
          open={this.state.showCancelconfirm}
          title="Cancel Appointment"
          okText="YES, CANCEL APPOINTMENT"
          cancelText="NO"
          onOk={this.handleCancelAppointment.bind(this)}
          onCancel={this.closeConfirmation.bind(this)}
          modalBackdropClicked={this.closeConfirmation.bind(this)}
        >
          <p>Do you want to cancel this Appointment ?</p>
        </ConfirmPopup>
      </React.Fragment>
    )
  }
}

export default compose(withElements({
  key: config.stripe_pk
}), withElementsConsumer)(BookingPaymentStripePopup)
