import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Box, Container, Button } from '@gositeinc/ui'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import _ from 'lodash'
import { StepText } from './StepText'
import { StepTitle } from './StepTitle'
import { FormLabel } from './FormLabel'
import { FacebookPageSelector } from './FacebookPageSelector'
import { segmentEventTrack } from '@helpers/segment'
import { numberMask } from '@helpers/inputmask'

export const FacebookBusinessDetails = ({ step, onBack, onSkipped, onSubmitted }) => {
  const dispatch = useDispatch()

  const me = useSelector((state) => state.auth.me)

  useEffect(() => {
    if (!me) {
      dispatch.auth.getMe()
    }
    segmentEventTrack({
      event: 'Onboarding_Social_SignUp',
      properties: {
        Onboarding_Version: 'v4',
        Social_Account: 'Facebook'
      }
    })
  }, [])

  const schema = Yup.object().shape({
    account: Yup.object().required('Account is a required field')
  })

  const initialValues = {
    account: undefined
  }

  return (
    <Container maxWidth="xs">
      <StepText mt="79px">{step}</StepText>
      <StepTitle mt="4px">Let's import your profile.</StepTitle>

      <Formik
        validateOnBlur
        validateOnMount
        enableReinitialize
        initialValues={initialValues}
        isInitialValid={schema.isValidSync(initialValues)}
        validationSchema={schema}
        onSubmit={async ({ account = {} }) => {
          let emails = []
          if (account.emails && account.emails.length > 0) {
            emails = account.emails
          } else if (me && me.user_email) {
            emails = [me.user_email]
          }

          const payload = {
            locationName: account.name || '',
            address: _.get(account, 'location.street', ''),
            city: _.get(account, 'location.city', ''),
            state: _.get(account, 'location.state', ''),
            zip: _.get(account, 'location.zip', ''),
            displayLat: _.get(account, 'location.latitude', ''),
            displayLng: _.get(account, 'location.longitude', ''),
            phone: account.phone ? numberMask(account.phone.replace('+1', '')) : '',
            websiteUrl: account.website || '',
            emails
          }

          if (me.location_id) {
            await dispatch.manage_business.updateBusinessSettings({
              _id: me.location_id,
              ...payload
            })
          } else {
            await dispatch.accountsettings.upadteBusinessName({
              ...payload
            })
            await dispatch.auth.getMe()
          }

          segmentEventTrack({
            event: 'Onboarding_Social_Synced',
            properties: {
              Onboarding_Version: 'v4',
              Social_Account: 'Facebook'
            }
          })

          // logic copied from business-gmail.js
          // home page will sync facebook base on this value
          localStorage.setItem('facebookPayload', JSON.stringify(account))

          onSubmitted()
        }}
        render={({ isValid, isSubmitting, setFieldValue }) => {
          return (
            <Form className="w-100">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  gap: '16px',
                  mt: '39px'
                }}>
                <Box sx={{ width: '100%' }}>
                  <FormLabel mb="10px">
                    SELECT YOUR BUSINESS PROFILE<span style={{ color: '#EA4335' }}>*</span>
                  </FormLabel>

                  <FacebookPageSelector
                    onAccountSelected={(account) => {
                      console.log('Selected Accounts => ', account)
                      setFieldValue('account', account)
                    }}
                  />
                </Box>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '56px',
                  gap: '16px'
                }}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    onBack()
                  }}>
                  BACK
                </Button>
                <Button variant="contained" type="submit" disabled={!isValid || isSubmitting}>
                  CONTINUE
                </Button>
              </Box>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mt: '16px'
                }}>
                <Box
                  style={{
                    color: '#0357FF',
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    segmentEventTrack({
                      event: 'Onboarding_Social_Skipped',
                      properties: {
                        Onboarding_Version: 'v4',
                        Social_Account: 'Facebook'
                      }
                    })
                    onSkipped()
                  }}>
                  Proceed without Facebook Sync
                </Box>
              </Box>
            </Form>
          )
        }}
      />
    </Container>
  )
}
