import React from 'react'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { useSelector } from 'react-redux'

export const ElementsProvider = ({ apiKey, children, ...elementsOptions }) => {
  const accountSettings = useSelector((state) => state.accountsettings.accountsettings)
  const { payment: {
    merchant_id: merchantId
  } = {} } = accountSettings || {}

  const stripePromise = loadStripe(apiKey, {
    stripeAccount: merchantId
  })

  return (
    merchantId ? <Elements stripe={stripePromise} options={elementsOptions}>
      {children}
    </Elements > : children
  )
}
