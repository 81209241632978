import React from 'react'
import { UpgradeRoute, ReactivateRoute } from '@app/router/access-route'
import asyncComponent from '@helpers/async-func'

// import NewPlanCheckout from './NewPlanCheckout.tsx'

export default [
  <UpgradeRoute
    exact
    path="/:userId/upgrade/pro-plan"
    key="upgrade-pro-plan"
    layoutSettings={{ hideSideBar: true }}
    component={asyncComponent(() => import('./ProPlanCheckout'))}
  />,

  <ReactivateRoute
    exact
    path="/:userId/upgrade/new-plan"
    key="upgrade-new-plan"
    layoutSettings={{ hideSideBar: true }}
    component={asyncComponent(() => import('./new-plan/NewPlanCheckout'))}
  />,

  <ReactivateRoute
    exact
    path="/:userId/upgrade/new-plan-checkout"
    key="upgrade-new-plan"
    layoutSettings={{ hideSideBar: true }}
    component={asyncComponent(() => import('./NewPricingPlanCheckout'))}
  />,

  <ReactivateRoute
    exact
    path="/:userId/upgrade/update-plan"
    key="update-new-plan"
    layoutSettings={{ hideSideBar: true }}
    component={asyncComponent(() => import('./update-plan/UpdatePlanCheckout'))}
  />
]
