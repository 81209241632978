import React from 'react'
import asyncComponent from '@helpers/async-func'
import { EnterpriseRoute } from '@app/router/access-route'

export default [
  <EnterpriseRoute
    exact
    key="/enterprise/reviews"
    path={'/:userId/enterprise/reviews'}
    layoutSettings={{ page: 'reviews', title: 'Reviews' }}
    component={asyncComponent(() => import('./metrics'))}
  />,
  <EnterpriseRoute
    exact
    key="/enterprise/all-reviews"
    path={'/:userId/enterprise/all-reviews'}
    layoutSettings={{ page: 'reviews', title: 'Reviews' }}
    component={asyncComponent(() => import('./allPositive'))}
  />,
  <EnterpriseRoute
    exact
    key="/enterprise/reviews/feedback"
    path={'/:userId/enterprise/reviews/feedback'}
    layoutSettings={{ page: 'reviews', title: 'Feedback' }}
    component={asyncComponent(() => import('./negative'))}
  />
]
