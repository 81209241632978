import React from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Loader from '../loader'
import Icons from '../icons'
import style from './index.module.scss'
import { deleteAllCookies, isCognitoUser } from '@helpers/utility'
import config from '@config/index'
import { history } from '@app/store'

const mapDispatchToProps = ({ auth }) => {
  return {
    ...auth
  }
}
const mapStateToProps = ({ auth }) => {
  return {
    ...auth
  }
}
export class ReactivateLayout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      headerLogo: '',
      pageLoading: true
    }
  }

  componentDidMount() {
    this.init()
  }

  async init() {
    if (!this.props.domainSettings) {
      await this.props.validateDomain()
    }
    if (this.props.domainSettings && this.props.domainSettings.logoUrl) {
      this.setState({ headerLogo: this.props.domainSettings.logoUrl })
    }
    if (!this.props.me && this.props.user) await this.props.getMe()
    this.setState({ pageLoading: false })
  }

  onLogoError(ev) {
    if (!this.state.errored) {
      ev.target.onerror = null
      //   ev.target.style.display = 'none'
      this.setState({ errored: true })
    }
  }

  getUserName(me) {
    let name
    if (me) {
      if (me.first_name) {
        name = me.first_name
        if (me.last_name) {
          name = name + ' ' + me.last_name
        }
      } else if (me.last_name) {
        name = me.last_name
      } else {
        name = me.user_email
      }
    } else {
      name = ''
    }
    return name
  }

  async logout() {
    const cognitoUser = await isCognitoUser()
    await this.props.logout()
    if (cognitoUser) {
      await deleteAllCookies()
      window.open(`${config.cognitoUrl}?logout=true`, '_parent')
      localStorage.removeItem('language')
    } else {
      history.push('/login')
      window.location.reload()
    }
  }

  render() {
    const { me, children } = this.props
    const { headerLogo } = this.state

    return (
      <div className={'app-body overlay-loader-outer '}>
        {this.props.me && this.props.me._id ? (
          <main className="main mw-100 overflow-hidden h-100">
            <header className="main-header fixed-top">
              <div className={'container-fluid d-flex align-items-center justify-content-between h-100'}>
                {/* {headerLogo ? (
                  <Link to={`/${this.props.user.user_unique_id}/home`} className="d-flex align-items-center ">
                    <img className={`${style['logo']}`} alt="" src={headerLogo} onError={this.onLogoError.bind(this)} />
                  </Link>
                ) : null} */}

                <img className={`${style['logo']}`} alt="" src={headerLogo} onError={this.onLogoError.bind(this)} />

                <div className={`dropdown open b-profile d-inline-block ml-20`}>
                  <button
                    id="profileDropdown"
                    className="btn dropdown-toggle button p-0 border-0"
                    type="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false">
                    <img
                      className={`image rounded-circle ${style['profile-img-tag']}`}
                      src={
                        me && me.profile_picture
                          ? me.profile_picture
                          : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                      }
                      alt=""
                    />
                  </button>
                  <div className="dropdown-menu dropdown dropdown-menu-right border-0 shadow-sm rounded-lg">
                    <h4 className="dropdown-header dropdown-menu-item text-secondary" href="#!">
                      {me && this.getUserName(me)}
                    </h4>
                    <div className="dropdown-divider"></div>
                    <button
                      onClick={this.logout.bind(this)}
                      className={`dropdown-item dropdown-menu-item py-0 px-15 ${style['dropdown-item']}`}
                      id="logout">
                      <i className={`btn-icon mr-10 ${style['icon-dropdown-item']} ${style['icon-logout']}`}>
                        <Icons name="export" />
                      </i>
                      Log out
                    </button>
                  </div>
                </div>
              </div>
              <hr class="MuiDivider-root MuiDivider-fullWidth css-1dk8nlb-MuiDivider-root" />
            </header>
            <div
              style={{ marginTop: '30px' }}
              className="page-upgrade-wrapper d-xl-flex align-items-xl-center justify-content-xl-center">
              {children}
            </div>
          </main>
        ) : (
          <div className="full-page-loader">
            <Loader />
          </div>
        )}
      </div>
    )
  }
}

export default compose(connect(mapStateToProps, mapDispatchToProps))(ReactivateLayout)
