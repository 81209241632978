// props
// -----

// message: message to display
// type: either 'success' or 'error'

import React from 'react'
import ReactDom from 'react-dom'
import ReactNotification from 'react-notifications-component'
import Icon from '../icons'

class Notification extends React.Component {
  constructor (props) {
    super(props)
    this.addNotification = this.addNotification.bind(this)
    this.notificationDOMRef = React.createRef()
  }

  componentDidMount () {
    this.addNotification()
  }

  addNotification () {
    this.notificationDOMRef.current.addNotification({
      insert: 'bottom',
      container: 'bottom-left',
      content: (
        <div id="sectionNotification" className="notify-wrapper" data-test="notification-message">
          <div
            className={`notification d-flex align-items-center px-15 py-10 ${
              this.props.type === 'success'
                ? 'success-notification'
                : this.props.type === 'error'
                  ? 'error-notification'
                  : ''
            }`}>
            {this.props.type && this.props.type === 'success' ? (
              <div className="notification-icon text-center rounded-circle">
                <img src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/tick-icon.svg" alt="" />
              </div>
            ) : this.props.type && this.props.type === 'error' ? (
              <div className="notification-icon text-center rounded-circle">
                <Icon name="closeCross" />
              </div>
            ) : null}
            <div className="notification-content px-15">{this.props.message}</div>
            <button
              className="btn close-notification-btn p-0 mr-15"
              onClick={() => {
                ReactDom.render(null, document.getElementById('portal'))
                clearTimeout(timeOUt)
              }}>
              &times;
            </button>
          </div>
        </div>
      ),
      animationIn: ['animated', 'fadeIn'],
      animationOut: ['animated', 'fadeOut'],
      dismissable: { click: true, mobile: false }
    })
    let fadeoutInMilliSecs = 3000
    if (this.props.quickFadeout) {
      fadeoutInMilliSecs = 1000
    }
    const timeOUt = setTimeout(function () {
      ReactDom.render(null, document.getElementById('portal'))
    }, fadeoutInMilliSecs)
  }
  render () {
    return (
      <div className="app-content">
        <ReactNotification ref={this.notificationDOMRef} />
      </div>
    )
  }
}

export default function notification (type, message, title, quickFadeout = false) {
  if (!message) {
    return
  }

  const erroMessage = message.split('\n').map((item, i) => <p key={i}>{item}</p>)
  ReactDom.render(<Notification message={erroMessage} type={type} title={title} quickFadeout = {quickFadeout} />, document.getElementById('portal'))
}
