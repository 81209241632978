import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function reportAdmins (payload) {
  return api()
    .post(config.routes.reportAdmin, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchHostedPage (payload) {
  return api()
    .post(config.routes.fetchHostedPage, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchCompanyDetails (payload) {
  return api()
    .get(`${config.routes.fetchCompanyDetail}/${payload._id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchsubscriptionPlan (payload) {
  return api()
    .get(`${config.routes.fetchSubscriptionDetail}?isChargeBee=true&plan_id=${payload.plan_id}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchCouponData (payload) {
  return api()
    .get(`${config.routes.fetchCoupon}?${objToUrlParams(payload)}`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function createPandoc (payload) {
  return api()
    .post(config.routes.createPandoc, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function updateCompanyDetails (payload) {
  return api()
    .put(`${config.routes.fetchCompanyDetail}/${payload._id}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
export function chargeBeeredirectionCheck (payload) {
  return api()
    .post(`${config.routes.prospectUser}/${payload.id}/customer`, payload.data)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchPandocUrl (payload) {
  return api()
    .get(`${config.routes.pandoc}/${payload}/session`)
    .then(res => res.data)
    .catch(catchHandler)
}
export function fetchPandocStatus (payload) {
  return api()
    .get(`${config.routes.pandoc}/${payload}/status`)
    .then(res => res.data)
    .catch(catchHandler)
}

export function resubscribeEmail (params) {
  return api(true)
    .get(config.routes.resubscribe, { params })
    .then(res => res.data)
    .catch(catchHandler)
}

export function restartTrial (payload) {
  return api()
    .put(`${config.routes.restartTrial}/${payload._id}`, payload)
    .then(res => res.data)
    .catch(catchHandler)
}
