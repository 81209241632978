import React from "react";
import asyncComponent from "@helpers/async-func";
import {EnterpriseRoute} from "@app/router/access-route"


export default [
    <EnterpriseRoute
        exact
        key="/white-label-settings"
        path={"/:userId/white-label-settings"}
        layoutSettings={{page: "branding",title:"Branding"}}
        component={asyncComponent(() => import("../settings/branding"))}
    />,
    <EnterpriseRoute
        exact
        key="/site-settings"
        path={"/:userId/site-settings"}
        layoutSettings={{page: "site",title:"Site Settings"}}
        component={asyncComponent(() => import("../settings/site"))}
    />,
]
