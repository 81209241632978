import React from 'react'
import asyncComponent from '@helpers/async-func'
import { SelfServiceRoute, RestrictedRoute, PublicRoute } from '@app/router/access-route'

export default [
  <SelfServiceRoute
    exact
    key="/self-service"
    path={'/:userId/self-service'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./splash'))}
  />,
  <SelfServiceRoute
    exact
    key="/self-service"
    path={'/:userId/self-service/business-details'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./business-details'))}
  />,
  <SelfServiceRoute
    exact
    key="/self-service"
    path={'/:userId/self-service/subscription'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./subscription'))}
  />,
  <SelfServiceRoute
    exact
    key="/self-service"
    path={'/:userId/self-service/terms'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./terms'))}
  />,
  <SelfServiceRoute
    exact
    key="/self-service"
    path={'/:userId/self-service/payment'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./payment'))}
  />,
  <SelfServiceRoute
    exact
    key="/self-service"
    path={'/:userId/self-service/contract-summary'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./contarct-summary'))}
  />,
  <RestrictedRoute
    exact
    key="/change-password"
    path={'/:userId/self-service/change-password'}
    layoutSettings={{ hideHeader: false, hideFooter: false, page: 'onboarding' }}
    component={asyncComponent(() => import('./change-password'))}
  />,
  <RestrictedRoute
    exact
    key="/complete"
    path={'/:userId/self-service/complete'}
    layoutSettings={{ hideHeader: false, hideFooter: false, page: 'onboarding' }}
    component={asyncComponent(() => import('./complete'))}
  />,
  <RestrictedRoute
    exact
    key="/completeMessage"
    path={'/:userId/self-service/complete-message'}
    layoutSettings={{ hideHeader: false, hideFooter: false, page: 'onboarding' }}
    component={asyncComponent(() => import('./completeMessage'))}
  />,
  <SelfServiceRoute
    exact
    key="/redirect-chargebee"
    path={'/:userId/self-service/checkout'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./chargeBeeredirection'))}
  />,
  <PublicRoute
    exact
    key="/unsubscribe"
    path={'/unsubscribe'}
    layoutSettings={{ hideHeader: false, hideFooter: false }}
    component={asyncComponent(() => import('./unsubscribe'))}
  />
]
