import * as service from './service'
import { createModel } from '@rematch/core'
import notification from '@components/notification'

export const insurances = createModel()({
  state: {
    getApplicationsResponse: {
      loading: false,
      error: undefined,
      applications: []
    },
    getPoliciesResponse: {
      loading: false,
      error: undefined,
      policies: []
    },
    getBusinessIDResponse: {
      loading: false,
      error: undefined,
      businessID: undefined
    },
    getCoverdashAuthResponse: {
      error: undefined,
      token: ''
    },
    patchCompanyResponse: {
      loading: false,
      error: undefined
    }
  },
  reducers: {
    onBeforeGetApplications(state) {
      return {
        ...state,
        getApplicationsResponse: {
          loading: true,
          error: undefined,
          applications: []
        }
      }
    },
    onGetApplicationsSuccess(state, { applications }) {
      return {
        ...state,
        getApplicationsResponse: {
          loading: false,
          error: undefined,
          applications
        }
      }
    },
    onGetApplicationsError(state, error) {
      return {
        ...state,
        getApplicationsResponse: {
          loading: false,
          error,
          applications: []
        }
      }
    },
    onBeforeGetPolicies(state) {
      return {
        ...state,
        getPoliciesResponse: {
          loading: true,
          error: undefined,
          policies: []
        }
      }
    },
    onGetPoliciesSuccess(state, { policies }) {
      return {
        ...state,
        getPoliciesResponse: {
          loading: false,
          error: undefined,
          policies
        }
      }
    },
    onBeforeGetBusinessID(state) {
      return {
        ...state,
        getBusinessIDResponse: {
          loading: true,
          error: undefined,
          businessID: undefined
        }
      }
    },
    onGetBusinessIDSuccess(state, businessID) {
      return {
        ...state,
        getBusinessIDResponse: {
          loading: false,
          error: undefined,
          businessID
        }
      }
    },
    onGetBusinessIDError(state, error) {
      return {
        ...state,
        getBusinessIDResponse: {
          businessID: undefined,
          error,
          loading: false
        }
      }
    },
    onGetPoliciesError(state, error) {
      return {
        ...state,
        getPoliciesResponse: {
          policies: [],
          error,
          loading: false
        }
      }
    },
    onGetCoverdashAuthenticationError(state, error) {
      return {
        ...state,
        getCoverdashAuthResponse: {
          token: undefined,
          error
        }
      }
    },
    onBeforePatchCompany(state) {
      return {
        ...state,
        patchCompanyResponse: {
          error: undefined,
          loading: true
        }
      }
    },
    onPatchCompanyError(state, error) {
      notification('error', 'Failed to update company')
      return {
        ...state,
        patchCompanyResponse: {
          error: error,
          loading: false
        }
      }
    },
    onPatchCompanySuccess(state) {
      notification('success', 'Company successfully updated')
      return {
        ...state,
        patchCompanyResponse: {
          error: undefined,
          loading: false
        }
      }
    }
  },
  effects: (dispatch) => ({
    async getApplications(coverdashBuisnessID) {
      dispatch.insurances.onBeforeGetApplications()
      try {
        const applications = await service.getApplications(coverdashBuisnessID)
        dispatch.insurances.onGetApplicationsSuccess({ applications })
      } catch (e) {
        dispatch.insurances.onGetApplicationsError(e)
      }
    },
    async getPolicies(coverdashBuisnessID) {
      dispatch.insurances.onBeforeGetPolicies()
      try {
        const policies = await service.getPolicies(coverdashBuisnessID)
        dispatch.insurances.onGetPoliciesSuccess({ policies })
      } catch (e) {
        dispatch.insurances.onGetPoliciesError(e)
      }
    },
    async coverdashCreateBusiness(payload) {
      dispatch.insurances.onBeforeGetBusinessID()
      try {
        const businessId = await service.coverdashCreateBusiness(payload)
        dispatch.insurances.onGetBusinessIDSuccess(businessId)
        return businessId
      } catch (e) {
        dispatch.insurances.onGetBusinessIDError(e)
      }
    },
    async coverdashSaveBusinessID(payload) {
      try {
        const status = await service.coverdashSaveBusinessID(payload)
        return status
      } catch (e) {
        dispatch.insurances.onGetCoverdashAuthenticationError(e)
      }
    },
    async createApplicationSubmission() {
      try {
        const applicationSubmissionID = await service.createApplicationSubmission()
        return applicationSubmissionID
      } catch (e) {
        dispatch.insurances.onGetCoverdashAuthenticationError(e)
      }
    }
  })
})
