import { api, catchHandler } from "@helpers/axios";
import config from "@config/index";
import { objToUrlParams } from "@helpers/index";

export function getReviewsAnalytics(payload){
    return api()
        .get(`${config.routes.review_analytics}?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function getReviewsAnalyticsEnterprise(payload){
    return api()
        .get(`${config.routes.review_analytics}/user?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function getReviewLocations(payload){
    return api()
        .get(`${config.routes.leaderBoard}?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function reviewsGraphData(payload){
    return api()
        .get(`${config.routes.enterpriseReviewAnalytics}?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function getFeaturedReviews(payload){
    return api()
        .get(`${config.routes.review_analytics}/type?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function getUnaddressedReviews(payload){
    return api()
        .get(`${config.routes.reviews}?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function fetchEnterpriseReviewSettings(payload){
    return api()
        .get(`${config.routes.accountSettings}/${payload}/review-settings`)
        .then(res=>res.data)
        .catch(catchHandler)
}

export function feedbackReply(payload){
    console.log(payload)
    let data = {}
    let url = config.routes.feedbackreply
    if(payload.siteId === "DIRECT"){
        data =  {"message":payload.message,"review_id":payload.id}
        if(payload.userId)
            url = `${url}private-feedback/${payload.id}?customers=${payload.userId}`
        else 
            url = `${url}private-feedback/${payload.id}`
    }else if(payload.siteId === "FACEBOOK"){
        data =  {"comment":payload.message,"review_id":payload.id}
        url = `${url}facebook_reply${payload.userId?`?customers=${payload.userId}`: ""}`
    }else if(payload.siteId === "GOOGLEMYBUSINESS"){
        data =  {"comment":payload.message,"review_id":payload.id}
        url = `${url}gmb_reply?customers=${payload.userId}`
    }else if(payload.siteId === "YELP"){
        data =  {"comment":payload.message,"review_id":payload.id}
        url = `${url}yext_reply?customers=${payload.userId}`
    }

    return api()
        .post(`${url}`,data)
        .then(res=>res.data)
        .catch(catchHandler)
}
export function sendLocationReview(payload){
    return api()
        .post(`${config.routes.sendReview}?${objToUrlParams(payload.param)}`,payload.data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getFeedbackCount(payload){
    return api()
        .get(`${config.routes.reviews}?${objToUrlParams(payload)}`)
        .then(res=>res.data)
        .catch(catchHandler)
}