import { api, apiV2, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { getV2ConfigCatValue } from '@helpers/utility'

export function getLeaderboard() {
  return api()
    .get(`${config.routes.leaderboard}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getTableFormat() {
  return api()
    .get(`${config.routes.tableformat}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function tableLayout(payload) {
  return api()
    .post(`${config.routes.editColumnUpdate}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUser(payload) {
  return api()
    .get(`${config.routes.getUser}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUserAnalytics(payload) {
  return api()
    .get(`${config.routes.user}/${payload._id}/analytics`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBusinessType() {
  return api()
    .get(`${config.routes.businessType}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getProducts(payload) {
  return api()
    .get(`${config.routes.products}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getAccountSettings(payload) {
  return api()
    .get(`${config.routes.accountSettings}/getaccountsettings/${payload._id}?skip=t`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resetPassword(payload) {
  return api()
    .post(`${config.routes.resetUserAccount}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendQuickLogin(payload) {
  return api()
    .post(`${config.routes.magicLoginGenerate}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteLocation(payload) {
  return api()
    .delete(`${config.routes.deleteLocation}/${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function customerInfoUpdate(payload) {
  return api()
    .put(`${config.routes.user}/${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteUserData() {
  return api()
    .post(`${config.routes.deleteUserData}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUserActivities(payload) {
  return api()
    .get(`${config.routes.activities}/${payload.id}?${objToUrlParams(payload.query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPlatformUrl(payload) {
  return api()
    .get(`${config.routes.platformUrl}?userId=${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function loginWithEmail(payload) {
  return api()
    .post(`${config.routes.loginWithEmail}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function republishWebsites() {
  return api()
    .post(`${config.routes.republishWebsites}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function importLocationsFromCSV(payload) {
  return api()
    .post(`${config.routes.importLocation}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onBatchUpdate(payload) {
  return api()
    .post(`${config.routes.batchUpdate}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getEnterPriceUserApiData(payload) {
  return api()
    .get(config.routes.getApiData)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function enableApiConnect(payload) {
  return api()
    .post(`${config.routes.enterPriseEnableApiConnect}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateAccountSettings(payload) {
  return api()
    .put(
      `${config.routes.accountSettings}/updateaccountsettings${payload._id ? `/${payload._id}?skip=t` : ''}`,
      payload.data
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSocialConnections(payload) {
  return api()
    .get(`${config.routes.businessSettings}/${payload.id}/social-connections`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function loadBusinessSettings(payload) {
  return api()
    .get(`${config.routes.userBsSettings}/${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export async function updateUserBusinessSettings(payload) {
  // let requestURl = `${config.routes.businessSettings}/${payload._id}/users/${payload.locationId}`
  // if (payload && payload.type) {
  //   if (payload.type === 'logoUpdate') {
  //     const userID = payload && payload._id
  //     requestURl = `${config.routes.businessSettings}/${userID}/logo`
  //     delete payload.type
  //   }
  // }
  // if (payload.updatetType === 'coverImageUpdate') {
  //   const userID = payload && payload._id
  //   requestURl = `${config.routes.businessSettings}/${userID}/cover`
  //   delete payload.updatetType
  // }

  // delete payload._id
  // delete payload.locationId
  // if (!requestURl.includes('cover')) { delete payload.type }

  // // return api()
  // //   .put(requestURl, payload)
  // //   .then(res => res.data)
  // //   .catch(catchHandler)
  // /** ------- */
  let callV2flag = true
  const requestURlV2 = `${config.routes.gmbDataSyncV2}${config.routes.businessSettings}/${payload._id}`
  let requestURl = `${config.routes.businessSettings}/${payload._id}`
  if (payload && payload.type) {
    if (payload.type === 'logoUpdate') {
      const userID = payload && payload._id
      requestURl = `${config.routes.businessSettings}/${userID}/logo`
      callV2flag = false
      delete payload.type
    }
  }
  if (payload.updatetType === 'coverImageUpdate') {
    const userID = payload && payload._id
    requestURl = `${config.routes.businessSettings}/${userID}/cover`
    callV2flag = false
    delete payload.updatetType
  }

  delete payload._id
  delete payload.locationId
  if (!requestURl.includes('cover')) delete payload.type

  const value = await getV2ConfigCatValue('BusinessSettings')
  if (value && callV2flag) {
    return apiV2()
      .put(requestURlV2, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(requestURl, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}

export function UpdateSubscriptionInfo(payload) {
  return api()
    .put(`${config.routes.subscription}/${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUsStates(payload) {
  return api()
    .get(config.routes.usStates)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSmsNumber(payload) {
  return api()
    .get(`${config.routes.smsNumber}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendAreaCode(payload) {
  return api()
    .post(`${config.routes.user}/${payload.id}/twilio/add`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteAreaCode(payload) {
  return api()
    .post(`${config.routes.user}/${payload}/twilio/remove`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function paymentCardConnect(payload, type) {
  let url = `${config.routes.paymentCardConnect}`
  if (type) {
    url = `${config.routes.paymentCardConnect}?useAdminCard=true`
  }
  return api()
    .post(url, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function userfacebookSync(payload) {
  return api()
    .post(`${config.routes.businessSettings}/sync-facebook`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getLocationCategories(payload) {
  return api()
    .get(`${config.routes.locationCategories}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onDownloadActivities(payload) {
  return api()
    .get(`${config.routes.exportActivity}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onSendPushNotification(payload) {
  return api()
    .post(`${config.routes.sendPushNotification}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMediaPhoto(payload) {
  const data = {}
  if (!payload.id) {
    data.url = payload.url
  }

  return api()
    .delete(`${config.routes.businessSettings}/${payload._id}/users/${payload.locationId}/photo/${payload.id}`, {
      data
    })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMediaVideo(payload) {
  return api()
    .put(`${config.routes.businessSettings}/${payload._id}/users/${payload.locationId}`, payload.videoUrls)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function bulkUpdateLocations(payload) {
  return api()
    .post(`${config.routes.businessSettingsBatchUpdate}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function bulkUpdateLocationsV2(payload) {
  return apiV2()
    .post(`${config.routes.businessSettingsBatchUpdate}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function userfacebookUnSync(payload) {
  return api()
    .post(`${config.routes.businessSettings}/unsync-facebook`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function gmbUnSync(payload) {
  const value = await getV2ConfigCatValue('GMB')
  if (value) {
    return apiV2()
      .post(`${config.routes.gmbDataSyncV2}/user-unsync-google`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(`${config.routes.businessSettings}/user-unsync-google`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function gmbUnSyncEnterprise(payload) {
  return api()
    .post(
      `${config.routes.businessSettings}/user-unsync-google?${objToUrlParams({ customers: payload.user_id })}`,
      payload
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getGmbAccounts(payload) {
  return api(true)
    .post(config.routes.gmbData, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getGmbLocations(payload) {
  return api()
    .get(`${config.routes.gmbLocations}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function gmbSync(payload) {
  return api()
    .post(`${config.routes.accountSettings}/${payload.user_id}/syncgmbsettings`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function gmbImport(payload) {
  return api()
    .post(`${config.routes.businessSettings}/sync-google`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createYextAccount(payload) {
  return api()
    .post(`${config.routes.user}/${payload.user_id}/create-yext-account`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function syncYext(payload) {
  return api()
    .post(config.routes.syncYext, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getOffers(payload) {
  return api()
    .get(config.routes.yextLocationTypes)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function unsyncYext(payload) {
  return api()
    .post(config.routes.unsyncYext, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getMenuLocations(payload) {
  const requestURL = `${config.routes.menuLocation}/${payload}`
  return api()
    .get(requestURL)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addSectionitem(payload) {
  return api()
    .post(`${config.routes.addMenuSection}/${payload.id}`, payload.payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateSectionitem(payload) {
  return api()
    .put(`${config.routes.addMenuSection}/${payload.id}/${payload.payload._id}`, payload.payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getMenuOptions(payload) {
  return api()
    .get(config.routes.menuLabels)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createMenuItem(payload) {
  return api()
    .post(`${config.routes.menuItems}/${payload.businessId}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateMenuItem(payload) {
  return api()
    .put(`${config.routes.menuItems}/${payload.businessId}/${payload.itemId}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMenuItem(payload) {
  return api()
    .delete(`${config.routes.menuItems}/${payload.section_id}/${payload._id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMenuSection(payload) {
  return api()
    .delete(`${config.routes.addMenuSection}/${payload.activeMenuId}/${payload.sectionId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createMenu(payload) {
  return api()
    .post(`${config.routes.menuLocation}/${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function editMenu(payload) {
  return api()
    .put(`${config.routes.menuLocation}/${payload.id}/${payload.menuId}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteMenu(payload) {
  return api()
    .delete(`${config.routes.menuLocation}/${payload.id}/${payload.menuId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function reorderMenuItem(payload) {
  return api()
    .put(`${config.routes.addMenuSection}/${payload.id}/${payload.sectionId}/reorder`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getPresenceLocation(payload) {
  return api()
    .get(`${config.routes.presenceLocation}/list?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getConfigFlag(payload) {
  return api()
    .post(`${config.routes.getConfigFlag}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function checkYextDuplicate(id) {
  return api()
    .get(`${config.routes.businessSettings}/${id}/listings/duplicates`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function presenceOptOut(payload) {
  return api()
    .put(`${config.routes.presenceOptOut}?customers=${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function presenceOptIn(payload) {
  return api()
    .put(`${config.routes.presenceOptIn}?customers=${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function yextSuppress(payload) {
  return api()
    .put(`${config.routes.businessSettings}/${payload.businessId}/listings/duplicates/${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function yextDelete(payload) {
  return api()
    .delete(`${config.routes.businessSettings}/${payload.businessId}/listings/duplicates/${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addOffer(payload) {
  return api()
    .put(`${config.routes.businessSettings}/${payload.businessId}/add-location`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function removeOffer(payload) {
  return api()
    .put(`${config.routes.businessSettings}/${payload.businessId}/remove-location`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function uploadUserBusinessPhoto(payload) {
  const requestURL = `${config.routes.businessSettings}/${payload._id}/photo`
  delete payload._id
  return api()
    .put(requestURL, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSavedFilters(payload) {
  return api()
    .get(`${config.routes.getFilters}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onSaveFilter(payload) {
  return api()
    .post(`${config.routes.getFilters}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onUpdateFilter(payload) {
  return api()
    .put(`${config.routes.getFilters}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteSavedFilter(payload) {
  return api()
    .delete(`${config.routes.getFilters}/${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateMediaPhotoName(payload) {
  const userID = payload._id
  const data = { description: payload.description }
  if (!payload.id) {
    data.url = payload.url
  }

  return api()
    .put(`${config.routes.businessSettings}/${userID}/photo/${payload.id}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sortPhotos(payload) {
  const requestURL = `${config.routes.businessSettings}/${payload._id}/sort-photo`
  delete payload._id
  return api()
    .put(requestURL, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createUserBusinessSettings(payload) {
  const requestURl = `${config.routes.businessSettings}/${payload.locationId}/create`
  return api()
    .post(requestURl, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getlocationPredefinedTemplates(payload) {
  return api()
    .get(`${config.routes.predefinedTemplate}${payload.id}?${objToUrlParams(payload.query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchGositeGMBLocations(payload) {
  return api(true)
    .get(`${config.routes.gositeGmb}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function syncEnterpriseGMBPhoto(payload) {
  const data = {}
  if (!payload.photoId) {
    data.url = payload.url
  }
  return api()
    .put(
      `${config.routes.businessSettings}/${payload.businessId}/users/${payload.userId}/photo/${payload.photoId}`,
      data
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function moveLocation(payload) {
  return api()
    .post(`${config.routes.moveLocation}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resubmitYext(payload) {
  const data = {
    user_id: payload
  }
  return api()
    .post(`${config.routes.resubmitYext}`, data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSavedCard() {
  return api()
    .get(`${config.routes.getSavedCard}?useAdminCard=true`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getMultilocations(data) {
  return api()
    .get(`${config.routes.getPartners}?${objToUrlParams(data)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function activeInactiveDeleteLocationApi(payload) {
  return api()
    .put(`${config.routes.activeInactiveDelete}/${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function activeInactiveLocationUpdate(payload) {
  return api()
    .put(`${config.routes.activeInactive}/${payload.id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}

// only map for now, implement condition check for update
export function mapOrUpdateSimvolyWebsite(payload) {
  return api()
    .post(config.routes.mapSimvolyWebsite, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function deleteSimvolyWebsite(payload) {
  console.log('[deleteSimvolyWebsite] payload', {
    data: payload,
    headers: {
      'Content-Type': 'application/json'
    }
  })
  return api()
    .delete(config.routes.deleteSimvolyWebsite, {
      data: payload
    })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getSimvolyWebsites(payload) {
  return api()
    .get(`${config.routes.simvolyWebsites}?user_id=${payload.user_id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
