import React, { useEffect, useState } from 'react'
import { Box } from '@gositeinc/ui'
import Select from 'react-select'
import { decodeCookies, getCookie, getCookieByKey, googleWorkingHourBEFormatter } from '@helpers/utility'
import { COOKIES_KEY, LOGIN_SERVICE_PROVIDERS } from '@helpers/constants'
import _ from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

const { GOOGLE } = LOGIN_SERVICE_PROVIDERS

export const GMBLocationSelector = ({ onLocationSelected = () => {} }) => {
  const dispatch = useDispatch()

  const gmbData = useSelector((state) => state.manage_business.gmbData)
  const gmbLocations = useSelector((state) => state.manage_business.gmbLocations)

  const [providerToken, setProviderToken] = useState('')
  const [gmbAccountId, setGmbAccountId] = useState('')
  const [gmbAccountName, setGmbAccountName] = useState('')

  const gmbAccounts = _.get(gmbData, 'accounts', [])
  const accountOptions = gmbAccounts.map((account) => ({
    value: {
      name: account.name,
      accountName: account.accountName
    },
    label: account.accountName
  }))

  const locationOptions = Array.isArray(gmbLocations)
    ? gmbLocations
    // .filter((location) => location.verificationState === GOOGLE_STATUS.COMPLETED)
      .map((location) => ({
        value: location,
        label: `${location.title} ${_.get(location, 'storefrontAddress.addressLines', [])}`
      }))
    : undefined

  useEffect(() => {
    const provider = getCookieByKey('loginProvider')
    console.log('provider', provider)
    console.log('GOOGLE.name', GOOGLE.name)
    if (provider !== GOOGLE.name) {
      return
    }

    const cookie = getCookie()
    const token = cookie && cookie.token
    const decodeData = decodeCookies(token)

    const googleAccessToken = getCookieByKey(COOKIES_KEY.GOOGLE_ACCESS_TOKEN)

    const tmpProviderToken = decodeData['custom:ACCESS_TOKEN'] || googleAccessToken || ''

    setProviderToken(tmpProviderToken)

    dispatch.manage_business.getGMBDetailsFromGoogle({
      access_token: tmpProviderToken
    })
  }, [])

  return (
    <Box>
      <div className="form-group b-select-pages">
        <Select
          id="selectGmbProfileGeneralInfo"
          className="dropdown-select"
          classNamePrefix="dropdownSelect"
          placeholder="Choose an Account"
          options={accountOptions}
          onChange={async (event) => {
            const selectedAccount = event.value
            const accountName = _.get(selectedAccount, 'name', '')
            const accountId = _.get(accountName.split('/'), ['1'], undefined)

            if (!accountId) {
              console.error('failed to get account id from selectedAccount', selectedAccount)
              return
            }

            setGmbAccountId(accountId)
            setGmbAccountName(selectedAccount.accountName)

            console.log('accountId', accountId)
            console.log('providerToken', providerToken)

            await dispatch.manage_business.getGmbLocations({
              account_id: accountId,
              access_token: providerToken
            })
          }}
        />
      </div>
      {locationOptions && (
        <div className="form-group">
          <Select
            id="selectLocationGmbGeneralInfo"
            className="dropdown-select"
            classNamePrefix="dropdownSelect"
            placeholder="Choose a page"
            options={locationOptions}
            onChange={(event) => {
              const selectedLocation = event.value

              let locationDetails = {
                selectedLocationDetails: selectedLocation,
                locationName: _.get(selectedLocation, 'title', ''),
                locationId: _.get(_.get(selectedLocation, 'name', '').split('/'), ['1'], ''),
                locationUrl: _.get(selectedLocation, 'metadata.mapsUri', undefined),
                placeId: _.get(selectedLocation, 'metadata.placeId', undefined),
                businessPhone: _.get(selectedLocation, 'phoneNumbers.primaryPhone', ''),
                websiteUrl: _.get(selectedLocation, 'websiteUri', ''),
                accountId: gmbAccountId,
                accountName: gmbAccountName,
                lat: _.get(selectedLocation, 'latlng.latitude', undefined),
                lng: _.get(selectedLocation, 'longitude.longitude', undefined)
              }

              const storefrontAddress = selectedLocation.storefrontAddress
              if (storefrontAddress) {
                const { locality, administrativeArea, postalCode, addressLines } = storefrontAddress
                locationDetails = {
                  ...locationDetails,
                  businessAddresses: addressLines.join(' '),
                  city: locality,
                  state: administrativeArea,
                  zip: postalCode
                }
              }

              const regularHoursPeriods = _.get(selectedLocation, 'regularHours.periods', undefined)
              if (regularHoursPeriods) {
                locationDetails = {
                  ...locationDetails,
                  hours: googleWorkingHourBEFormatter(regularHoursPeriods) || ''
                }
              }

              onLocationSelected(locationDetails)
            }}
          />
        </div>
      )}
    </Box>
  )
}
