import { S3 } from '@config/constants'
export const dummyCsv = `firstname, lastname,email, extension, mobile, groupname, address, birthday
John, Doe, john@example.com, 12, 5555555555,,4 myrtle st Boston MA,12/18/1990
Aaron,,aaron@example.com,14,8888888888,gold,,08/27/1985
Mary, John, mary@example.com, 13, ,platinum,150 Greenwich St 47 Floor,08/27/1987`

const iconPrefix = `${S3}/image/svg-icons/contact-list-table/`

export const contactSources = [
  {
    source: 'contact-us',
    icon: 'email',
    imgUrl: `${iconPrefix}contact-us.svg`,
    label: 'contacts_hub.contact-us'
  },
  {
    source: 'appointment',
    icon: 'booking',
    imgUrl: `${iconPrefix}appointment.svg`,
    label: 'contacts_hub.appointment'
  },
  {
    source: 'lead_form',
    icon: 'booking',
    imgUrl: `${iconPrefix}appointment.svg`,
    label: 'contacts_hub.booking'
  },
  {
    source: 'transaction',
    icon: 'contactPayment',
    imgUrl: `${iconPrefix}transaction.svg`,
    label: 'contacts_hub.transaction'
  },
  {
    source: 'chat',
    icon: 'contactChat',
    imgUrl: `${iconPrefix}chat.svg`,
    label: 'contacts_hub.chat'
  },
  {
    source: 'direct',
    icon: 'contactUser',
    imgUrl: `${iconPrefix}direct.svg`,
    label: 'contacts_hub.direct'
  },
  {
    source: 'subscriber',
    icon: 'contactEnvelope',
    imgUrl: `${iconPrefix}subscriber.svg`,
    label: 'contacts_hub.subscriber'
  },
  {
    source: 'google',
    icon: 'contactGplus',
    imgUrl: `${iconPrefix}google.svg`,
    label: 'contacts_hub.google'
  },
  {
    source: 'outlook',
    icon: 'email',
    imgUrl: `${iconPrefix}outlook.svg`,
    label: 'contacts_hub.outlook'
  },
  {
    source: 'review',
    icon: 'contactReview',
    imgUrl: `${iconPrefix}review.svg`,
    label: 'contacts_hub.reviews'
  },
  {
    source: 'quickbooks',
    icon: 'contactUser',
    imgUrl: `${iconPrefix}direct.svg`,
    label: 'contacts_hub.quickbooks'
  },
  {
    source: 'campaign',
    icon: 'contactEnvelope',
    imgUrl: `${iconPrefix}campaign.svg`,
    label: 'contacts_hub.campaigns'
  },
  {
    source: 'thumbtack',
    icon: 'contactEnvelope',
    imgUrl: 'https://gosite-assets.s3-us-west-1.amazonaws.com/image/svg-icons/contact-list-table/thumtack.svg',
    label: 'contacts_hub.thumbtack'
  }
]
