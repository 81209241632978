import React from 'react'
import Popup from './basic-modal'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import './payment-complete-popup.scss'
import Icon from '../icons'
import Tooltip from '../tooltip'
import OnClickOutside from '../outsideClickComponent'
import { UsCurrencyFormatter } from '@helpers/utility'
import { withTranslation } from 'react-i18next'
import i18n from '@/i18n'

const amountTypes = [
  { value: 'amount', label: '$' },
  { value: 'percentage', label: '%' }
]

const getAmountDueTypes = () => {
  const amountDueTypes = [
    { value: 'full', label: i18n.t('bookings.full-payment') },
    { value: 'partial', label: i18n.t('bookings.deposit-partial-payment') }
  ]
  return amountDueTypes
}

const getPaymentCollectVia = () => {
  const paymentCollectVia = [
    { value: 'direct', label: i18n.t('bookings.manually-add-a-transaction') },
    { value: 'getpaid', label: i18n.t('bookings.payment-link') }
  ]
  return paymentCollectVia
}

class PaymentCompletePopup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  updateField(field, value) {
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails[field] = value
    this.setState({ bookingDetails: bookingDetails })
  }

  handleModalClose() {
    this.props.close()
  }

  toggleEditing() {
    this.setState({ isEditing: !this.state.isEditing })
  }

  handleSubmit(values) {
    if (values.allow_partial_payment) {
      if (values.minimum_payment_type === 'percentage') {
        values.payment_amount = Number(values.due_amount.replace(',', '')) * (values.minimum_payment_percentage / 100)
      } else if (values.minimum_payment_type === 'amount') {
        values.payment_amount = Number(values.minimum_payment_amount.replace(',', ''))
      }
    } else {
      if (values.due_amount) {
        values.payment_amount = Number(values.due_amount.replace(',', ''))
      } else {
        values.payment_amount = Number(values.minimum_payment_amount.replace(',', ''))
      }
    }
    values.payment_amount = parseFloat(values.payment_amount)

    delete values.minimum_payment_amount
    delete values.minimum_payment_percentage
    delete values.minimum_payment_type
    delete values.due_amount
    delete values.allow_partial_payment

    values.booking_id = this.state.bookingDetails && this.state.bookingDetails._id

    if (values.payment_type === null) {
      values.payment_type = 'full'
    }

    const payload = { ...this.state.bookingDetails, paymentCompleteData: { ...values }, ...values }
    this.props.handleSubmit(payload)
  }

  setPaymentDueType(setFieldValue, value) {
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.allow_partial_payment = value === 'partial'
    bookingDetails.payment_type = value
    // if(value === "partial" && bookingDetails.collect_via === "invoice"){
    //     bookingDetails.collect_via = "direct"
    // }
    this.setState({ bookingDetails: bookingDetails })
  }
  setPaymentViaType(setFieldValue, value) {
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.collect_via = value
    this.setState({ bookingDetails: bookingDetails })
  }
  selectMinimumAmountType(value) {
    let type = ''
    if (value === 'percentage') {
      type = 'percentage'
    } else if (value === 'amount') {
      type = 'amount'
    }
    const bookingDetails = { ...this.state.bookingDetails }
    bookingDetails.minimum_payment_type = type
    this.setState({ bookingDetails: bookingDetails })
  }
  getPaymentVia(via) {
    let label
    getPaymentCollectVia().forEach((t) => {
      if (t.value === via) {
        label = t.label
      }
    })
    return label
  }
  // getDueAmount() {
  //     let { bookingDetails = {} } = this.props
  //     let { service_rate = 0, service_quantity = 0 } = bookingDetails
  //     let amount = service_quantity * service_rate
  //     if (bookingDetails.payment_id && bookingDetails.payment_id.paid_amount && !bookingDetails.payment_id.balance_paid) {
  //         let paid_amount = bookingDetails.payment_id.paid_amount
  //         if((amount <= paid_amount) || amount === 0){
  //             return 0      //return 0 if fully paid or service rate is updated to 0
  //         }else{
  //             amount -= paid_amount
  //         }
  //     }
  //     return amount
  // }
  getDueAmount() {
    const { bookingDetails = {} } = this.props
    const { service_rate = 0, service_quantity = 0 } = bookingDetails
    let amount = service_quantity * service_rate
    if (bookingDetails.payments && bookingDetails.payments.length) {
      let paid_amount = 0
      const amounts = bookingDetails.payments.map((item) =>
        item.status !== 'draft' &&
        item.status !== 'deleted' &&
        item.status !== 'canceled' &&
        item.status !== 'declined' &&
        item.status !== 'refund'
          ? item.amount
          : 0
      )
      paid_amount = amounts.reduce((acc, currentValue) => acc + currentValue)
      amount -= paid_amount
    }
    return UsCurrencyFormatter(amount)
  }
  componentDidMount() {
    if (this.props.bookingDetails) {
      if (this.props.bookingDetails.payments.length === 1) {
        if (this.props.bookingDetails.minimum_payment_amount) {
          this.setState({ minPay: this.props.bookingDetails.minimum_payment_amount })
        } else if (this.props.bookingDetails.minimum_payment_percentage) {
          this.setState({ minPer: this.props.bookingDetails.minimum_payment_percentage })
        }
      }
      if (this.props.bookingDetails.service_id.minimum_payment_amount) {
        this.setState({ service_minimum_amount: this.props.bookingDetails.service_id.minimum_payment_amount })
      }
      this.props.bookingDetails.minimum_payment_amount = this.getDueAmount()
      this.props.bookingDetails.payment_type = null
      this.setState({ bookingDetails: this.props.bookingDetails })
    }
  }

  render() {
    const { t } = this.props
    const { bookingDetails } = this.state
    const paymentCollectVia = getPaymentCollectVia()
    return (
      <Popup
        open={this.props.open}
        title={t('payments.collect-payment')}
        type="default"
        modalBackdropClicked={() => console.log(null)}
        id="modalPaymentComplete">
        <Formik
          validateOnChange={false}
          validateOnBlur={false}
          enableReinitialize={true}
          initialValues={{
            allow_partial_payment: !!(
              bookingDetails &&
              bookingDetails.hasOwnProperty('payment_type') &&
              bookingDetails.payment_type === 'partial'
            ),
            collect_via:
              bookingDetails && bookingDetails.hasOwnProperty('collect_via')
                ? bookingDetails.collect_via
                : paymentCollectVia[0].value,
            minimum_payment_type:
              bookingDetails && bookingDetails.minimum_payment_type ? bookingDetails.minimum_payment_type : 'amount',
            minimum_payment_amount:
              bookingDetails && bookingDetails.minimum_payment_amount ? bookingDetails.minimum_payment_amount : '',
            minimum_payment_percentage:
              bookingDetails && bookingDetails.minimum_payment_percentage
                ? bookingDetails.minimum_payment_percentage
                : '',
            minPay: this.state.minPay,
            minPer: this.state.minPer,
            service_minimum_amount: this.state.service_minimum_amount,
            payment_type:
              bookingDetails && bookingDetails.hasOwnProperty('payment_type') ? bookingDetails.payment_type : 'full',
            due_amount: this.getDueAmount()
          }}
          validationSchema={Yup.object().shape({
            minimum_payment_percentage: Yup.string().test(
              'minimum_payment_percentage',
              t('bookings.entered-amount-should-be-equal-or-greater-than-minimum-payment'),
              function (value) {
                const {
                  minimum_payment_type,
                  allow_partial_payment,
                  minPay,
                  minimum_payment_percentage,
                  due_amount,
                  service_minimum_amount
                } = this.parent
                if (allow_partial_payment && minimum_payment_type === 'percentage') {
                  if (minPay) {
                    const perAmt = (value * this.parent.due_amount) / 100
                    if (perAmt < minPay) {
                      return false
                    }
                  }
                  const percantageValue = (due_amount * minimum_payment_percentage) / 100
                  if (service_minimum_amount && service_minimum_amount > percantageValue) {
                    return false
                  }
                  return value
                }
                return true
              }
            ),
            minimum_payment_amount: Yup.string().test(
              'minimum_payment_amount',
              t('bookings.entered-amount-should-be-equal-or-greater-than-minimum-payment'),
              function (value) {
                const { minimum_payment_type, allow_partial_payment, minPer, service_minimum_amount } = this.parent
                if (allow_partial_payment && minimum_payment_type === 'amount') {
                  if (minPer) {
                    const perAmt = (value / this.parent.due_amount) * 100
                    if (perAmt < minPer) {
                      return false
                    }
                  }
                  if (service_minimum_amount && service_minimum_amount > value) {
                    return false
                  }
                  return value
                }
                return true
              }
            )
          })}
          onReset={this.handleModalClose.bind(this)}
          onSubmit={this.handleSubmit.bind(this)}
          render={({ values, setFieldValue }) => {
            return (
              <Form className={'pt-20 complete-payment-popup-wrapper'}>
                <button
                  name="paymentCompleteCloseModal"
                  type="reset"
                  className="close custom-modal-close-button remove-modal-close mt-10">
                  <span aria-hidden="true">×</span>
                </button>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group mb-15">
                      <div className="card">
                        <div className="card-body p-0">
                          <div className="d-flex align-items-center py-5 py-sm-0 position-relative">
                            <div className={'w-50 d-flex align-items-center card-row-item-height'}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="line-height-normal">{t('bookings.amount-due')}</span>
                              </div>
                            </div>
                            <div
                              className={
                                'd-flex align-items-center justify-content-between px-15 w-50 card-row-item-height'
                              }>
                              <span className="line-height-normal">
                                {values.allow_partial_payment
                                  ? t('bookings.deposit-partial-payment')
                                  : t('bookings.full-payment')}
                              </span>
                              <div
                                onClick={() => this.setState({ showPaymentDueTypeList: true })}
                                className={
                                  'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                }
                                id="">
                                <div
                                  className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                  data-toggle="dropdown">
                                  {/* TODO : arrow-icon style yet to write */}
                                  <i className={'btn-icon ml-10 manage-icon-arrow arrow-icon'}>
                                    <Icon name="arrowDownThin" />
                                  </i>
                                </div>
                                {this.state.showPaymentDueTypeList ? (
                                  <OnClickOutside
                                    handleClickOutside={() => this.setState({ showPaymentDueTypeList: false })}>
                                    <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 width-200 overflow-y-scrollbar">
                                      {getAmountDueTypes().map((type, index) => (
                                        <li
                                          key={index}
                                          className="primary-font px-15"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            this.setPaymentDueType(setFieldValue, type.value)
                                            this.setState({ showPaymentDueTypeList: false })
                                          }}>
                                          <span>{type.label} 19</span>
                                        </li>
                                      ))}
                                    </ul>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          {values.allow_partial_payment ? (
                            <div className="d-flex align-items-center border-top py-5 py-sm-0">
                              <div className={'w-50 d-flex align-items-center card-row-item-height'}>
                                <div className="d-block h-100 px-15">
                                  <div className="d-flex align-items-center">
                                    <span className="line-height-normal">
                                      {values.allow_partial_payment ? t('bookings.partial-payment') : ''}
                                    </span>
                                    <span className="star text-danger position-relative mr-10">*</span>
                                    <div className="cursor-pointer position-relative top-minus-1">
                                      <Tooltip
                                        position="top"
                                        message={t('bookings.enter-the-minimum-payment-in-percentage-or-amount')}
                                      />
                                    </div>
                                  </div>
                                  <ErrorMessage
                                    name={
                                      values.minimum_payment_type === 'percentage'
                                        ? 'minimum_payment_percentage'
                                        : 'minimum_payment_amount'
                                    }
                                    component="span"
                                    id="lbl_serviceAssignedUser"
                                    className="form-error mt-3 mb-5 ml-0"
                                  />
                                </div>
                              </div>
                              <div
                                className={
                                  'd-flex align-items-center justify-content-between px-15 w-50 card-row-item-height'
                                }>
                                {values.minimum_payment_type === 'percentage' ? (
                                  <Field
                                    // disabled={values.service_rate && parseFloat(values.service_rate) > 0 ? false : true}
                                    id="frm_addService_txtPartialPayemnt"
                                    type="number"
                                    className={'form-control form-control-lg px-0 border-0 bg-white field-normal-width'}
                                    placeholder="0"
                                    value={values.minimum_payment_percentage}
                                    name="minimum_payment_percentage"
                                    onChange={(e) => {
                                      if (e.target.value === '' || (e.target.value > 0 && e.target.value <= 100)) {
                                        this.updateField('minimum_payment_percentage', e.target.value)
                                      }
                                    }}
                                  />
                                ) : this.state.isEditing ? (
                                  <Field
                                    // disabled={values.service_rate && parseFloat(values.service_rate) > 0 ? false : true}
                                    id="frm_addService_txtPartialPayemnt"
                                    type="number"
                                    className={'form-control form-control-lg px-0 border-0 bg-white field-normal-width'}
                                    placeholder="0"
                                    // value={values.due_amount}
                                    name="minimum_payment_amount"
                                    onBlur={this.toggleEditing.bind(this)}
                                    onChange={(e) => {
                                      if (e.target.value === '' || e.target.value > 0) {
                                        this.updateField('minimum_payment_amount', e.target.value)
                                      }
                                    }}
                                  />
                                ) : (
                                  <Field
                                    // disabled={values.service_rate && parseFloat(values.service_rate) > 0 ? false : true}
                                    id="frm_addService_txtPartialPayemnt"
                                    type="number"
                                    className={'form-control form-control-lg px-0 border-0 bg-white field-normal-width'}
                                    placeholder={values.minimum_payment_amount}
                                    value={Number(values.minimum_payment_amount)}
                                    name="minimum_payment_amount"
                                    onFocus={this.toggleEditing.bind(this)}
                                    readOnly
                                  />
                                )}
                                <div className={'d-flex align-items-center'}>
                                  <span>
                                    {values.minimum_payment_type === 'amount'
                                      ? '$'
                                      : values.minimum_payment_type === 'percentage'
                                      ? '%'
                                      : '$'}
                                  </span>
                                  <div
                                    onClick={() => this.setState({ showMinimumList: true })}
                                    className={'dropdown btn border-0 b-manage-dropdown pr-0 pl-0'}
                                    id="">
                                    <div
                                      className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent"
                                      data-toggle="dropdown">
                                      {/* TODO : arrow-icon STYLE */}
                                      <i className={'btn-icon ml-10 manage-icon-arrow arrow-icon'}>
                                        <Icon name="arrowDownThin" />
                                      </i>
                                    </div>
                                    {this.state.showMinimumList ? (
                                      <OnClickOutside
                                        handleClickOutside={() => this.setState({ showMinimumList: false })}>
                                        <ul
                                          className={
                                            'dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar width-50 text-center small-dropdown'
                                          }>
                                          {amountTypes.map((type, index) => (
                                            <li
                                              key={index}
                                              className="primary-font px-15"
                                              onClick={(e) => {
                                                e.stopPropagation()
                                                this.selectMinimumAmountType(type.value)
                                                this.setState({ showMinimumList: false })
                                              }}>
                                              <span>{type.label}</span>
                                            </li>
                                          ))}
                                        </ul>
                                      </OnClickOutside>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}

                          <div className="d-flex align-items-center border-top py-5 py-sm-0 position-relative">
                            <div className={'w-50 d-flex align-items-center card-row-item-height'}>
                              <div className="d-flex align-items-center h-100 px-15">
                                <span className="line-height-normal">{t('bookings.collect-via')}</span>
                              </div>
                            </div>
                            <div
                              className={
                                'd-flex align-items-center justify-content-between px-15 w-50 card-row-item-height'
                              }>
                              <span className="line-height-normal">{this.getPaymentVia(values.collect_via)}</span>
                              <div
                                onClick={() => this.setState({ showPaymentTypeList: true })}
                                className={
                                  'dropdown btn border-0 b-manage-dropdown position-absolute right-0 left-0 cursor-pointer'
                                }
                                id="">
                                <div
                                  className="dropdown-toggle dropdown-toggle-manage btn-manage w-100 bg-transparent text-right"
                                  data-toggle="dropdown">
                                  <i className={'btn-icon ml-10 manage-icon-arrow arrow-icon'}>
                                    <Icon name="arrowDownThin" />
                                  </i>
                                </div>
                                {this.state.showPaymentTypeList ? (
                                  <OnClickOutside
                                    handleClickOutside={() => this.setState({ showPaymentTypeList: false })}>
                                    <ul className="dropdown-menu show dropdown-menu-manage dropdown-menu-right max-height-200 overflow-y-scrollbar">
                                      {paymentCollectVia.map((type, index) => (
                                        <li
                                          key={index}
                                          className="primary-font px-15"
                                          onClick={(e) => {
                                            e.stopPropagation()
                                            this.setPaymentViaType(setFieldValue, type.value)
                                            this.setState({ showPaymentTypeList: false })
                                          }}>
                                          <span>{type.label}</span>
                                        </li>
                                      ))}
                                    </ul>
                                  </OnClickOutside>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <footer className="d-flex mt-10">
                  <button
                    className={'btn btn-primary border-0 btn-xl  mr-10 flex-fill'}
                    id="frmBtnCollect"
                    disabled={this.props.loader}
                    type="submit">
                    CONTINUE
                    {this.props.loader && (
                      <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                        <Icon name="spinner" />
                      </i>
                    )}
                  </button>
                  <button type="reset" id="frmBtnCancel" className={'btn btn-xl ml-10 btn-outline flex-fill'}>
                    CANCEL
                  </button>
                </footer>
              </Form>
            )
          }}
        />
      </Popup>
    )
  }
}

export default withTranslation(null, { withRef: true })(PaymentCompletePopup)
