import { postSignUpLink, getSignUpStatus, getFinancingPromoMessaging, getPrequalLink } from './service'
import { createModel } from '@rematch/core'

import { FinancingPromoQuery } from './types'
import { makeHyperlinksInsideText } from '@app/helpers'

export const wisetack = createModel()({
  state: {
    signUpStatus: 'NOT_FETCHED',
    financingPromoMessagingResponse: {
      loading: false,
      error: undefined,
      data: null
    },
    prequalLink: ''
  },
  reducers: {
    onBeforeGetSignUpStatus: (state) => {
      return {
        ...state,
        signUpStatus: 'NOT_FETCHED'
      }
    },
    onAfterGetSignUpStatus: (state, signUpStatus) => {
      return {
        ...state,
        signUpStatus
      }
    },
    onBeforeGetFinancingPromoMessaging(state) {
      return {
        ...state,
        financingPromoMessagingResponse: {
          data: null,
          error: undefined,
          loading: true
        }
      }
    },
    onGetFinancingPromoMessagingError(state, error) {
      return {
        ...state,
        financingPromoMessagingResponse: {
          data: null,
          error: error,
          loading: false
        }
      }
    },
    onGetFinancingPromoMessagingSuccess(state, data) {
      return {
        ...state,
        financingPromoMessagingResponse: {
          data: makeHyperlinksInsideText(data),
          error: undefined,
          loading: false
        }
      }
    },
    onAfterPrequalLink(state, prequalLink) {
      return {
        ...state,
        prequalLink
      }
    }
  },
  effects: (dispatch) => ({
    postSignUpLink: async () => {
      return await postSignUpLink()
    },
    getSignUpStatus: async () => {
      dispatch.wisetack.onBeforeGetSignUpStatus()
      const { status } = (await getSignUpStatus()) || { status: 'API_ERROR' }
      dispatch.wisetack.onAfterGetSignUpStatus(status)
    },
    getPrequalLink: async () => {
      const { prequalLink } = (await getPrequalLink()) || { prequalLink: '' }
      dispatch.wisetack.onAfterPrequalLink(prequalLink)
    },
    async getFinancingPromoMessaging(query: FinancingPromoQuery) {
      dispatch.wisetack.onBeforeGetFinancingPromoMessaging()
      try {
        const data = await getFinancingPromoMessaging(query)
        dispatch.wisetack.onGetFinancingPromoMessagingSuccess(data)
      } catch (e) {
        console.error('error=', e)
        dispatch.wisetack.onGetFinancingPromoMessagingError(e)
      }
    }
  })
})
