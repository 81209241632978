import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'

export default {
  state: {},
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess(state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onError(state, data, noNotif) {
      const err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : 'Please try again later'
      if (err && !noNotif) {
        notification('error', capitalize(err))
      }
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onGetLeaderboardSuccess(state, data) {
      return {
        ...state,
        loading: false,
        entPlacementLeaderboard: data.data
      }
    },
    onGetInsightsForSearch(state, data) {
      return {
        ...state,
        loading: false,
        searchInsights: data.data
      }
    },
    onGetInsightsForViews(state, data) {
      return {
        ...state,
        loading: false,
        viewInsights: data.data
      }
    },
    onGetInsightsForActions(state, data) {
      return {
        ...state,
        loading: false,
        actionInsights: data.data
      }
    },
    onGetInsightsForPhones(state, data) {
      return {
        ...state,
        loading: false,
        phoneInsights: data.data
      }
    },
    onListPresenceSuccess(state, data) {
      return {
        ...state,
        loading: false,
        entPresenceList: data
      }
    },
    onfetchEnterpriseQuestionssSuccess(state, data) {
      return {
        ...state,
        enterpriseQuestionsList: data,
        placementLoading: false
      }
    },
    onsendAnswerEnterpriseSuccess(state, data) {
      notification('success', 'Successfully added answer')

      return {
        ...state,
        placementLoading: false
      }
    },
    onUpdateAnswerSuccess(state, data) {
      notification('success', 'Successfully updated answer')

      return {
        ...state,
        placementLoading: false
      }
    },
    ondeleteAnswerEnterpriseSuccess(state, data) {
      notification('success', 'Successfully deleted answer')

      return {
        ...state,
        placementLoading: false
      }
    },
    ondeleteQuestionEnterpriseSuccess(state, data) {
      notification('success', 'Successfully deleted question')

      return {
        ...state,
        placementLoading: false
      }
    },
    onListAdminPostsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        adminPosts: data
      }
    },
    onCreateAdminPostSuccess(state, data) {
      if (data.success) {
        notification('success', data.success)
      }
      return {
        ...state,
        loading: false
      }
    },
    onGetAdminPostDetailsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        postDetails: data
      }
    },
    onupdateQuestionSuccess(state, data) {
      notification('success', 'Successfully updated question')
      return {
        ...state,
        placementLoading: false
      }
    },
    onfetchConfigFlag(state, achPaymentFeatureFlag) {
      return {
        ...state,
        achPaymentFeatureFlag: achPaymentFeatureFlag
      }
    },
    onaddQuestionSuccess(state, data) {
      notification('success', 'Successfully created question')
      return {
        ...state,
        placementLoading: false
      }
    }
  },
  effects: {
    async getEntPlacementLeaderboard(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getEntPlacementLeaderboard(payload)
        this.onGetLeaderboardSuccess(res)
        return res
      } catch (err) {
        this.onError(err, true)
      }
    },
    async fetchInsightsForSearch(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getInsightsForSearch(payload)
        this.onGetInsightsForSearch(res)
        return res
      } catch (err) {
        this.onError(err, true)
      }
    },
    async fetchInsightsForActions(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getInsightsForActions(payload)
        this.onGetInsightsForActions(res)
        return res
      } catch (err) {
        this.onError(err, true)
      }
    },
    async fetchInsightsForViews(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getInsightsForViews(payload)
        this.onGetInsightsForViews(res)
        return res
      } catch (err) {
        this.onError(err, true)
      }
    },
    async fetchInsightsForPhones(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getInsightsForPhones(payload)
        this.onGetInsightsForPhones(res)
        return res
      } catch (err) {
        this.onError(err, true)
      }
    },
    async listPresence(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.listPresence(payload)
        this.onListPresenceSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async fetchEnterpriseQuestions(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchEnterpriseQuestions(payload)
        this.onfetchEnterpriseQuestionssSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendAnswerEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendAnswerEnterprise(payload)
        this.onsendAnswerEnterpriseSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async UpdateAnswerEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.UpdateAnswer(payload)
        this.onUpdateAnswerSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteAnswerEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteAnswerEnterprise(payload)
        this.ondeleteAnswerEnterpriseSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteQuestionEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteQuestionEnterprise(payload)
        this.ondeleteQuestionEnterpriseSuccess(res.data)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async listAdminPosts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.listAdminPosts(payload)
        this.onListAdminPostsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async createAdminPost(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createAdminPost(payload)
        this.onCreateAdminPostSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getAdminPostDetails(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getAdminPostDetails(payload)
        this.onGetAdminPostDetailsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async addQuestionEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.addQuestionEnterprise(payload)
        this.onaddQuestionSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getConfigFlag(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getConfigFlag(payload)
        this.onfetchConfigFlag(res.achPaymentFeatureFlag)
        return res.achPaymentFeatureFlag
      } catch (e) {
        await this.onError(e)
      }
    },
    async updateQuestionEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateQuestionEnterprise(payload)
        this.onupdateQuestionSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },

    async deletePostEnterprise(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deletePost(payload)
        this.onSuccess(res, 'Post deleted successfully')
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
