import React from "react";
import asyncComponent from "@helpers/async-func";
import {EnterpriseRoute} from "@app/router/access-route"


export default [
    <EnterpriseRoute
        exact
        key="/messenger"
        path={"/:userId/enterprise/messenger"}
        layoutSettings={{page: "messenger",title:"Messenger"}}
        component={asyncComponent(() => import("./index"))}
    />,

]