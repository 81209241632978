import React from "react";
import asyncComponent from "@helpers/async-func";
import { RestrictedRoute } from "@app/router/access-route";
export default [
    <RestrictedRoute
        exact
        path={"/:userId/website/mywebsites"}
        key="site-list"
        layoutSettings={{page: "sites"}}
        component={asyncComponent(() => import("./site-list"))}
    />,
    <RestrictedRoute
        exact
        path={"/:userId/website/mywebsites/:websiteId"}
        key="site-detail"
        layoutSettings={{page: "sites"}}
        component={asyncComponent(() => import("./site-details"))}
    />,
    <RestrictedRoute
        exact
        path={"/:userId/themes"}
        key="theme-selection"
        layoutSettings={{page: "sites"}}
        component={asyncComponent(() => import("./site-theme-selection"))}
    />,
    <RestrictedRoute
        exact
        key="/trial-website"
        path={"/:userId/trial/website"}
        layoutSettings={{ page: "sites" }}
        component={asyncComponent(() => import("./trail-website"))}
    />,
 
]
