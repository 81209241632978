import { api, apiV2, builderApi, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { getV2ConfigCatValue } from '@helpers/utility'

export function getAccountSettings(payload) {
  return api()
    .get(`${config.routes.accountSettings}/getaccountsettings${payload._id ? `/${payload._id}` : ''}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function patchLocale(locale) {
  return (
    api()
      .put('/locale', {
        locale
      })
      // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
      .then((res) => res.data)
      .catch(catchHandler)
  )
}

export function upadteAccountSettings(payload) {
  return api()
    .put(`${config.routes.accountSettings}/updateaccountsettings/${payload._id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function upadteBusinessName(payload) {
  return api()
    .post(`${config.routes.businessSettings}/`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function upadteCustomLink(payload) {
  return api()
    .put(config.routes.UpdateCustomLink, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateCustomLinkSocial(payload) {
  return api()
    .put(config.routes.UpdateSocialCustomLink, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateSesEmail(payload) {
  return api()
    .put(config.routes.updateSesEmail, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateTemplateSettings(payload) {
  return api()
    .put(`${config.routes.accountSettings}/updateaccountsettings/${payload._id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateGositeSettings(payload) {
  return api()
    .put(config.routes.updateGositeAccount, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchwidgets(payload) {
  return api()
    .get(`${config.routes.reviewWidgets}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function widgetFavourate(payload) {
  return api()
    .post(`${config.routes.reviewWidgets}/${payload}/favourite`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function removeReviewWidget(payload) {
  return api()
    .put(config.routes.removeReviewWidget, { widgets: payload })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createReviewWidget(payload) {
  return api()
    .post(config.routes.createReviewWidget, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getReviewWidget(payload) {
  return api()
    .get(`${config.routes.reviewWidgets}/${payload}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateReviewWidget(payload) {
  return api()
    .put(config.routes.updatereviewWidget, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function createPaymentButtonWidget(payload) {
  return builderApi()
    .post(`${config.routes.websiteCount}/payment`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resendSesEmail(payload) {
  return api()
    .get(config.routes.resendSesEmail)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function unLinkCalendar(payload) {
  const value = await getV2ConfigCatValue('v2tempqacalendar')
  if (value) {
    return apiV2()
      .delete(config.routes.unLinkCalendarV2)
      .then((res) => res.data)
      .then((res) => {
        const modifiedV2Response = res
        modifiedV2Response.success = res.message
        return modifiedV2Response
      })
      .catch(catchHandler)
  } else {
    return api()
      .delete(config.routes.unLinkCalendar)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function updateQuickBookSettings(payload) {
  const value = await getV2ConfigCatValue('QB')
  if (value) {
    return apiV2()
      .put(config.routes.quickbookSettingsV2, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(config.routes.quickbookSettings, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export async function getQuickBookInfo(payload) {
  return api()
    .get(`${config.routes.quickBookInfo}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function updateThumbtackSettings(payload) {
  return apiV2()
    .post(`${config.routes.thumbtackAuthUpdateThumbtackSettingsV2}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getQuickBookTokens(payload) {
  return api()
    .put(config.routes.quickbookTokens, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function unLinkQuickbook(payload) {
  const value = await getV2ConfigCatValue('QB')
  if (value) {
    return apiV2()
      .post(config.routes.quickbookDeleteLinksV2, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(config.routes.deleteLinks, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function updateUser(payload) {
  return api()
    .put(`${config.routes.user}/${payload.id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateUserProfilePicture(payload) {
  return api()
    .put(`${config.routes.user}/${payload.id}${config.routes.addProfilePic}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateThumbtackCredentials(payload) {
  return api()
    .post(`${config.routes.connectTumbtack}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function removeThumbtackService(payload) {
  const value = await getV2ConfigCatValue('v2Thumbtack')
  if (value) {
    return apiV2()
      .delete(`${config.routes.removeThumtackServiceV2}`)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .put(`${config.routes.removeThumtackService}`, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function fetchLocationLogo(payload) {
  return api()
    .get(`${config.routes.locationLogo}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function isClosedNotification(payload) {
  return api()
    .put(`${config.routes.isClosedNotificationBlock}/${payload.userid}/update`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function closeQBAlert(payload) {
  return api()
    .put(`${config.routes.quickbookIgnore}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getUpgradeToProUrl(payload) {
  return api()
    .get(`${config.routes.getUpgradeToProUrl}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
