import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="/home"
    path={'/:userId/home'}
    layoutSettings={{ page: 'home' }}
    component={asyncComponent(() => import('../home'))}
  />,
  <RestrictedRoute
    exact
    key="/home/trail"
    path={'/:userId/trial/home/:showBusinessModal'}
    layoutSettings={{ page: 'home' }}
    component={asyncComponent(() => import('./preview'))}
  />,
  <RestrictedRoute
    exact
    key="/home/trail"
    path={'/:userId/trial/home'}
    layoutSettings={{ page: 'home' }}
    // component={asyncComponent(() => import("./preview"))}
    component={asyncComponent(() => import('../home'))}
  />
]
