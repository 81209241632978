import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import notification from '@components/notification'

export function getEstimates(query) {
  return api()
    .get(`${config.routes.getEstimates}?${objToUrlParams(query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getEstimatePdf(estimateId) {
  return api()
    .get(`${config.routes.getEstimates}/${estimateId}/pdf`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getEstimateAttachment(estimateId, s3Key, fileName) {
  return api()
    .get(`${config.routes.getEstimates}/${estimateId}/attachments/${s3Key}`, {
      responseType: 'blob'
    })
    .then((response) => {
      const href = URL.createObjectURL(response.data)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', fileName)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
    .catch(catchHandler)
}

export function getEstimateHistories({ estimateId, query }) {
  return api()
    .get(`${config.routes.getEstimates}/${estimateId}/histories?${objToUrlParams(query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getEstimate(estimateId) {
  return api()
    .get(`${config.routes.getEstimates}/${estimateId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function postEstimate({ contact, message, sendTime, sendVia, expiredAt, items, attachments }) {
  return api()
    .post(`${config.routes.postEstimate}`, {
      contact,
      message,
      sendTime,
      sendVia,
      expiredAt,
      items,
      attachments
    })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function uploadEstimateAttachments({ file, axiosConfig }) {
  const body = new FormData()
  body.append(`attachments`, file)
  return api()
    .post(config.routes.uploadEstimateAttachments, body, axiosConfig)
    .then((res) => {
      return res.data
    })
    .catch((e) => {
      notification('error', e.message)
    })
}

export function patchEstimate(estimateId, { contact, message, sendTime, sendVia, expiredAt, items, attachments }) {
  return api()
    .patch(`${config.routes.postEstimate}/${estimateId}`, {
      contact,
      message,
      sendTime,
      sendVia,
      expiredAt,
      items,
      attachments
    })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function renewEstimate(estimateId, { contact, message, sendTime, sendVia, expiredAt, items, attachments }) {
  return api()
    .post(`${config.routes.postEstimate}/${estimateId}/renew`, {
      contact,
      message,
      sendTime,
      sendVia,
      expiredAt,
      items,
      attachments
    })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function deleteEstimate({ estimateId }) {
  return api()
    .delete(`${config.routes.postEstimate}/${estimateId}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getEstimateMetrics() {
  return api()
    .get(`${config.routes.postEstimate}/metrics`)
    .then((res) => res.data)
    .catch(catchHandler)
}
