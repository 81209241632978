import { api, apiV2, builderApi, catchHandler, invoiceApi } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { getV2ConfigCatValue } from '@helpers/utility'

export function addBooking(payload) {
  return api()
    .post(config.routes.bookingCreate, payload, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBookingServices() {
  return api()
    .get(config.routes.getBookingService)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCustomFieldsProd(prodID) {
  return api()
    .get(`${config.routes.getCustomFields}?${objToUrlParams(prodID)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBookingServicesOnly() {
  return api()
    .get(config.routes.getBookingServiceOnly)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getStaffBookingService() {
  return api()
    .get(config.routes.getStaffBookingService)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getDateSlots(val) {
  return api()
    .get(`${config.routes.getDateSlots}/${val}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCalendarData(data) {
  return api()
    .get(`${config.routes.calanderLeads}?${objToUrlParams(data)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchBookingData(payload) {
  return api()
    .get(`${config.routes.bookinglist}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendMessageToBooking(payload) {
  const newData = {
    contact_type: payload.contact_type,
    message: payload.message,
    recipient: payload.recipient,
    subject: payload.subject
  }
  if (payload.media) newData.media = payload.media
  return api()
    .put(`${config.routes.bookingSendmessage}/${payload.id}/contact`, newData)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function rescheduleBooking(payload) {
  return api()
    .put(`${config.routes.rescheduleBooking}${payload.appId}/reschedule`, payload.res, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function cancelBooking(payload) {
  return api()
    .put(`${config.routes.rescheduleBooking}${payload.appId}/cancel`, {}, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function confirmBooking(payload) {
  return api()
    .put(`${config.routes.rescheduleBooking}${payload.appId}/confirm`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchServiceSlot(payload) {
  return api()
    .get(`${config.routes.getDateSlots}/${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBookingDetails(payload) {
  return api()
    .get(`${config.routes.bookingDetails}/${payload}?isReturnAll=true`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getWebsitesTotalCount() {
  return api()
    .get(`${config.routes.websiteCount}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getBookingHistory(payload) {
  return api()
    .get(`${config.routes.bookingDetails}/${payload}/history`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export async function connectGoogleCalendar(payload) {
  const value = await getV2ConfigCatValue('v2tempqacalendar')
  if (value) {
    return apiV2()
      .post(config.routes.bookingClaendarSyncV2, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api(true)
      .post(config.routes.bookingClaendarSync, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function bookingCreateButtonWidget(payload) {
  return builderApi()
    .post(config.routes.bookingButtonWidget, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function connectOutlookCalendar(payload) {
  return api(true)
    .post(config.routes.msAccount, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getInvoiceNumber(payload) {
  return api()
    .get(`${config.routes.paymentList}/next-invoice-number`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUnAvailableDateSlots(payload) {
  return api()
    .get(`${config.routes.getDateSlots}/${payload.serviceId}/unavailable?${objToUrlParams(payload.query)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function editBooking(payload) {
  return api()
    .put(`${config.routes.bookingCreate}/${payload.bookingId}`, payload.data, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function cancelIncompleteBooking(payload) {
  return api()
    .post(config.routes.cancelIncompleteBooking, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function collectBookingPayment(payload) {
  return invoiceApi()
    .post(config.routes.bookingPayment, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function collectBookingManualPayment(payload) {
  return api()
    .post(config.routes.bookingManualPayment, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function sendBookingGetPaidLink(payload) {
  return api()
    .post(config.routes.sendGetPaidLink, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
