import * as React from 'react'
import { useEffect } from 'react'
// form
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
// components
import { FormInput, FormInputMask, FormCheckbox } from './form-input'
import { history } from '@app/store'
import { useFeatureVal } from '@helpers/hooks'
import { featureFlags } from '@helpers/configcat'
import Icon from '@components/icons'

export const Checkout = () => {
  const featureVal = useFeatureVal(featureFlags.upgradeTrial)

  useEffect(() => {
    if (!featureVal) history.push('/login')
  }, [featureVal])

  return (
    <React.Fragment>
      <h1>Payment Information</h1>
      <Formik
        initialValues={{
          cardNumber: '',
          expiration: '',
          cvv: '',
          zipCode: '',
          address: '',
          city: '',
          termsOfService: false,
          agreeToSMS: false,
          nonRefundable: false
        }}
        validationSchema={Yup.object().shape({
          cardNumber: Yup.string()
            .matches(/^\d{4} \d{4} \d{4} \d{4}$/, 'Invalid credit card')
            .required('Required'),
          expiration: Yup.string()
            .matches(/^(0?[1-9]|1[012])\/\d{2}$/, 'Invalid expiration date')
            .required('Required'),
          cvv: Yup.number().lessThan(1000, 'cvv only 3 numbers').required('Required'),
          zipCode: Yup.string()
            .matches(/^[0-9]{5}(?:-[0-9]{4})?$/, 'bad zip')
            .required('Required'),
          address: Yup.string().required('Required'),
          city: Yup.string().required('Required'),
          termsOfService: Yup.boolean().oneOf([true], 'Required'),
          agreeToSMS: Yup.boolean().oneOf([true], 'Required'),
          nonRefundable: Yup.boolean().oneOf([true], 'Required')
        })}
        onSubmit={(values) => {
          values.cardNumber = values.cardNumber.replaceAll(/ /g, '')
          console.log(values)
        }}
        render={({ isSubmitting, errors, values, handleBlur, handleChange }) => (
          <Form>
            <FormInputMask
              required
              mask="9999 9999 9999 9999"
              placeholder="Enter Card Number"
              name="cardNumber"
              value={values.cardNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              CARD NUMBER
            </FormInputMask>
            <FormInputMask
              required
              mask="99/99"
              placeholder="MM/YY"
              name="expiration"
              value={values.expiration}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              EXPIRATION
            </FormInputMask>
            <FormInputMask
              required
              mask="999"
              name="cvv"
              value={values.cvv}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              CVV
            </FormInputMask>
            <FormInputMask
              required
              mask="99999"
              name="zipCode"
              value={values.zipCode}
              onChange={handleChange}
              onBlur={handleBlur}
            >
              ZIPCODE
            </FormInputMask>
            <FormInput required name="address" placeholder="Enter Address">
              ADDRESS
            </FormInput>
            <FormInput required name="city" placeholder="Enter City">
              CITY
            </FormInput>
            <div className="d-flex align-items-center flex-column">
              <FormCheckbox name="termsOfService">Terms of service</FormCheckbox>
              <FormCheckbox name="agreeToSMS">Agree to marketing from SMS 🐸</FormCheckbox>
              <FormCheckbox name="nonRefundable">non refund lol</FormCheckbox>
            </div>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                // disable if any fields are blank or if there is error
                !Object.values(values).reduce((acc: boolean, value) => acc && Boolean(value), true) ||
                Object.values(errors).reduce((acc: boolean, error) => acc || Boolean(error), false)
              }
            >
              PAY
              {isSubmitting && (
                <i className="btn-icon top-minus-2 ml-5 btn-loader">
                  <Icon name="spinner" />
                </i>
              )}
            </button>
            <button type="reset" className="btn btn-outline bg-white">
              CANCEL
            </button>
          </Form>
        )}
      />
    </React.Fragment>
  )
}
