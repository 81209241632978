import {
  getPromoCodeDetail,
  estimateV2Subscription
  // UpgradeToProPlanDetail
} from '../service'
import { getAddons } from './api'

export default {
  state: {
    newPricingPlan: {},
    finalPrice: 0,
    showPaymentConfirmation: false,
    promocode: '',
    promoCodeMessage: {
      message: '',
      invoiceName: '',
      success: true
    },
    pricingBreakup: {
      total: 0,
      plan: 0,
      additionalTools: 0,
      additionalUsers: 0,
      additionalLocations: 0,
      annualDiscount: 0,
      promocodeDiscount: 0
    }
  },
  reducers: {
    onSetNewPricingPlan (state, newPricingDetails) {
      return {
        ...state,
        newPricingPlan: newPricingDetails
      }
    },
    onSetFinalPrice (state, finalPrice) {
      return {
        ...state,
        finalPrice
      }
    },
    onSetPaymentSuccessScreen (state, showPaymentConfirmation) {
      return {
        ...state,
        showPaymentConfirmation
      }
    },
    onSetSelectedSubscription (state, selectedSubscription) {
      return {
        ...state,
        selectedSubscription
      }
    },
    setPromoCodeMessage (state, message) {
      return {
        ...state,
        promoCodeMessage: message
      }
    },
    setPricingBreakupData (state, priceBreakup) {
      return {
        ...state,
        pricingBreakup: priceBreakup
      }
    },
    onSetPromoCode (state, promocode) {
      return {
        ...state,
        promocode
      }
    }
  },
  effects: {
    async setNewPricingPlan (plan) {
      this.onSetNewPricingPlan(plan)
    },
    async setFinalPrice (finalPrice) {
      this.onSetFinalPrice(finalPrice)
    },
    async setPromocode (finalPrice) {
      this.onSetPromoCode(finalPrice)
    },
    async showPaymentSuccessScreen (showPaymentConfirmation) {
      this.onSetPaymentSuccessScreen(showPaymentConfirmation)
    },
    async applyPromoCode (data) {
      const { promoCode, selectedSku } = data
      this.setPromoCodeMessage({
        message: '',
        success: true
      })

      try {
        const promoCodeDetail = await getPromoCodeDetail(promoCode)
        if (promoCodeDetail.status === 'expired') {
          return this.setPromoCodeMessage({
            message: `"${promoCode}" Promo code is expired`,
            success: false
          })
        }
        if (promoCodeDetail != null) {
          try {
            const estimatev2Subscription = await estimateV2Subscription({
              promoCode,
              planId: selectedSku.config.chargebee_plan_id,
              addons: getAddons(data)
            })
            if (estimatev2Subscription && estimatev2Subscription.priceSummaryInDollars) {
              this.setPricingBreakupData(estimatev2Subscription.priceSummaryInDollars)
            }
          } catch (err) {
            console.error(`error`, err)
            if (err.data) {
              if (err.data && err.data.code && err.data.code === 'coupon_not_applicable') {
                return this.setPromoCodeMessage({
                  message: `"${promoCode}" Promo code is not applicable`,
                  success: false
                })
              }
              if (err.status && err.status === 500) {
                // promo code not found
                return this.setPromoCodeMessage({
                  message: `"${promoCode}" Promo code does not exist`,
                  success: false
                })
              }
              return this.setPromoCodeMessage({
                message: `"${promoCode}" Promo code is not applicable`,
                success: false
              })
            }
          }
        }
        this.setPromoCodeMessage({
          message: `"${promoCode}" Promo code successfully applied!`,
          invoiceName: promoCodeDetail.invoiceName ? promoCodeDetail.invoiceName : '',
          success: true
        })
      } catch (e) {
        console.error(`error`, e)

        if (e.data) {
          if (e.data.err) {
            const error = e.data.err
            if (error.http_status_code === 404) {
              // promo code not found
              return this.setPromoCodeMessage({
                message: `"${promoCode}" Promo code does not exist`,
                success: false
              })
            }
          }

          if (e.data.code === 'coupon_expired') {
            // expired
            return this.setPromoCodeMessage({
              message: `"${promoCode}" Promo code is expired`,
              success: false
            })
          }
        }

        // all other error
        return this.setPromoCodeMessage({
          message: `"${promoCode}" Promo code is not applicable`,
          success: false
        })
      }
    },
    async applyInitalPricingSummary (data) {
      const { selectedSku } = data
      this.setPromoCodeMessage({
        message: '',
        success: true
      })

      try {
        const estimatev2Subscription = await estimateV2Subscription({
          planId: selectedSku.config.chargebee_plan_id,
          addons: getAddons(data)
        })
        if (estimatev2Subscription && estimatev2Subscription.priceSummaryInDollars) {
          this.setPricingBreakupData(estimatev2Subscription.priceSummaryInDollars)
        }
      } catch (err) {
        console.error(`error`, err)
      }
    }
  }
}
