export function numberMask(data) {
    if (!data) {
        return "";
    }
    var value = data.toString().trim().replace(/\D/g, "");
    if (value.length < 4) return value;
    else if (value.length < 7 && value.length > 3)
        return "(" + value.slice(0, 3) + ")" + value.slice(3, 6);
    else
        return (
            "(" + value.slice(0, 3) + ") " + value.slice(3, 6) + "-" + value.slice(6)
        );
}

export function  formatPhone(number) {
    let formatednumber = null;
    if (number) {

        formatednumber = number.toString().replace(/\D/g, "")
        if (formatednumber.length >= 10) {
            formatednumber = "+1" + formatednumber.substring(formatednumber.length - 10);
        } else {
            formatednumber = null;
        }
    }
    return formatednumber;
}    