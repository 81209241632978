import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'

export default {
  state: {
    accSettings: {}
  },
  reducers: {
    onRequest (state) {
      return {
        ...state,
        loading: true,
        apiError: false
      }
    },
    onSuccess (state, data, msg) {
      if (msg) {
        notification('success', msg)
      }
      return {
        ...state,
        loading: false
      }
    },
    onSuccessWithMessage (state, data, message) {
      notification('success', data.message)
      return {
        ...state,
        loading: false
      }
    },
    onError (state, data) {
      const err =
                data && data.message
                  ? data.message
                  : data && data.data && data.data.message
                    ? data.data.message
                    : data && data.error
                      ? data.error
                      : data && data.data && data.data.error
                        ? data.data.error
                        : 'Please try again later'
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: true
      }
    },
    onGetWidgetSuccess (state, data) {
      const widgetData = data.data
      if (widgetData && widgetData.docs && widgetData.docs.length) {
        const docs = []
        widgetData.docs.forEach((item, index) => {
          item.index = index + 1
          docs.push(item)
        })
        widgetData.docs = docs
      }
      return {
        ...state,
        loading: false,
        widgetData
      }
    },
    onGetWidgetsDaetsilsSuccess (state, data) {
      return {
        ...state,
        loading: false,
        widgetDetails: data.data
      }
    },
    onGetCustomerProductsSuccess(state, data) {
        let customerProducts = data.locations && data.locations.length ? data.locations : []
        //IN-1666 - Removed messenger filter from redux variable to the multimessenger component 
        // (containers/enterprise/widgets/multimessenger/edit/index.js)
        return {
            ...state,
            loading: false,
            customerProducts
        }
    },
    onGetSettings (state, data) {
      return {
        ...state,
        loading: false,
        accSettings: data.data
      }
    },
    onGetBusinessSettings (state, data) {
      return {
        ...state,
        loading: false,
        businessSettings: data
      }
    },
    oncreateReviewWidgetSucces (state, data) {
      notification('success', 'Widget has been created successfully')
      return {
        ...state,
        newWidget: data,
        loading: false
      }
    },
    onGetReviewWidgetsSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        reviewWidgets: data
      }
    },
    ongetContactListSuccess (state, data) {
      return {
        ...state,
        reviewloading: false,
        contactList: data
      }
    },
    onRemoveReviewWidgetSuccess (state, data) {
      notification('success', 'Widget has been deleted successfully')
      return {
        ...state,
        // deleteWidget: data.data,
        loading: false
      }
    }

  },
  effects: {
    async getWidgets (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getWidgets(payload)
        this.onGetWidgetSuccess(res)
        return res
      } catch (err) {
        // this.onError(err)
      }
    },
    async deleteWidgets (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteWidgets(payload)
        this.onSuccess(res, 'Widget has been deleted successfully')
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getWidgetsDaetsils (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getWidgetsDaetsils(payload)
        this.onGetWidgetsDaetsilsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getCustomerProducts (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getCustomerProducts(payload)
        this.onGetCustomerProductsSuccess(res)
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async createWidgets (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createWidgets(payload)
        this.onSuccess(res, 'Widget has been created successfully')
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async updateWidgets (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateWidgets(payload)
        this.onSuccess(res, 'Widget has been updated successfully')
        return res
      } catch (err) {
        this.onError(err)
      }
    },
    async getSettings (payload) {
      this.onRequest()
      try {
        const res = await service.getSettings(payload.user, payload.custId)
        this.onGetSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updateMessengerSettings (payload) {
      this.onRequest()
      try {
        const res = await service.updateMessengerSettings(payload)
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getBusinessSettings () {
      this.onRequest()
      try {
        const res = await service.getBusinessSettings()
        this.onGetBusinessSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    // async getBusinessSettings() {
    //     this.onRequest()
    //     try {
    //         let res = await service.getBusinessSettings()
    //         this.onGetBusinessSettings(res)
    //         return res
    //     }
    //     catch (e) {
    //         this.onError(e)
    //     }
    // },
    async updateSettings (payload) {
      this.onRequest()
      try {
        const res = await service.updateSettings(payload.userId, payload.custId, payload.data)
        this.onGetSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addBookingSettings (payload) {
      this.onRequest()
      try {
        const res = await service.addBookingSettings(payload.custId, payload.data)
        this.onGetSettings(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createReviewWidget (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.createReviewWidget(payload)

        this.oncreateReviewWidgetSucces(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getReviewWidgets (payload) {
      this.onRequest()
      try {
        const res = await service.getReviewWidgets(payload)
        this.onGetReviewWidgetsSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async removeMultiReviewWidget (payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeReviewWidget(payload)
        this.onRemoveReviewWidgetSuccess(res)
        return res
        // return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async getContactList (payload) {
      this.onRequest()
      try {
        const res = await service.getContactList(payload)
        this.ongetContactListSuccess(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
