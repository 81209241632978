import { api, catchHandler, cognitoApi } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'
import { InvoiceList } from './myaccount-dto'
import { isCognitoUser } from '@helpers/utility'

export function updateProfileDetails(payload) {
  const id = payload.id
  delete payload.id
  return api()
    .put(`${config.routes.user}/${id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateProfilePic(payload) {
  const id = payload.id
  delete payload.id
  return api()
    .put(`${config.routes.user}/${id}/add-profile-picture`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateNotification(payload) {
  const id = payload.id
  delete payload.id
  return api()
    .put(`${config.routes.updateAccountSettings}/${id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function patchLocale(locale) {
  return (
    api()
      .put('/locale', {
        locale
      })
      // .get(`${config.amazonGatewayUrl}${config.routes.me}`)
      .then((res) => res.data)
      .catch(catchHandler)
  )
}

export function changePassword(payload) {
  if (isCognitoUser() || payload.googleSSOAccountsOriginReq) {
    return cognitoApi()
      .post(config.routes.cognitoChangePassword, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  } else {
    return api()
      .post(config.routes.changePassword, payload)
      .then((res) => res.data)
      .catch(catchHandler)
  }
}
export function getTimeZones(payload) {
  return api()
    .get(`${config.routes.timeZones}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getUserApiData(payload) {
  return api()
    .get(config.routes.getApiData)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function createPredefinedTemplate(payload) {
  const id = payload.id
  delete payload.id
  return api()
    .post(`${config.routes.predefinedTemplate}${id}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function updateUserAccountSettings(payload) {
  const id = payload.id
  delete payload.id
  return api()
    .put(`${config.routes.updateAccountSettings}/${id}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function connectFacebook(payload) {
  return api(true)
    .post(config.routes.facebookAuth, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function connectGoogle(payload) {
  return api(true)
    .post(config.routes.googleAuth, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function connectThumbtack(payload) {
  return api()
    .post(config.routes.thumbtack, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function disconnectThumbtack(payload) {
  return api()
    .post(config.routes.disconnectThumbtack, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function verifyMobile(payload) {
  return api()
    .post(`${config.routes.mobileVerify}?verifyphone=true`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function onRequestOtpOnCall(payload) {
  return api()
    .post(`${config.routes.mobileVerify}?verifyphone=true&otpbycall=1`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function verifyOtp(payload) {
  return api()
    .post(`${config.routes.otpVerify}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function unSyncSocialPlatform(payload) {
  return api()
    .post(config.routes.unsyncSocialConnect, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function revokeApiKey(payload) {
  return api()
    .get(config.routes.revokeApiKey)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function enableApiKey(payload) {
  return api()
    .get(config.routes.enableApiKey)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function disableApiKey(payload) {
  return api()
    .get(config.routes.disableApiKey)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateTwoWay(payload) {
  return api()
    .put(`${config.routes.updateTwoWay}/${payload.id}`, payload.datas)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function enableMFACognito(payload) {
  return cognitoApi()
    .post(`${config.routes.enableMFACognito}`, payload)
    .then((res) => res.data)
    .catch((err) => {
      throw err
    })
}
export function disableMFACognito(payload) {
  return cognitoApi()
    .post(`${config.routes.disableMFACognito}`, payload)
    .then((res) => res.data)
    .catch((err) => {
      throw err
    })
}
export function removeProfilePic(payload) {
  return api()
    .put(`${config.routes.user}/${payload.id}${config.routes.removeProfilePic}`, {})
    .then((res) => res.data)
    .catch(catchHandler)
}
export function enableRestApi() {
  return api()
    .get(`${config.routes.enableRestApi}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getChargeBeePaymentMethods() {
  return api()
    .put(`${config.routes.hostedPAges}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getCardList() {
  return api()
    .get(`${config.routes.chargeBeeCardList}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function listActiveSubscriptions(payload) {
  return api()
    .get(`${config.routes.chargeBeesubscriptions}?${objToUrlParams(payload.params)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getSubscription(payload) {
  return api()
    .get(config.routes.chargeBeesubscription)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getDclStatus(payload) {
  return api()
    .get(config.routes.chargeBeeDclStatus)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function collectUnpaidInvoices(payload) {
  return api()
    .post(config.routes.collectUnpaidInvoices)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function triggerContactSales(payload) {
  return api()
    .post(config.routes.triggerContactSales)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function setPrimaryCard(payload) {
  return api()
    .post(config.routes.chargeBeeSetDefultCard, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function removeCard(payload) {
  return api()
    .post(config.routes.chargeBeeSetRemoveCard, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getChargebeeSubscription(payload) {
  return api()
    .get(`users/${config.routes.chargeBeesubscription}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getChargebeeSubscriptionDetail() {
  return api()
    .get(config.routes.chargeBeesubscription)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function cancelChargebeeSubscription() {
  return api()
    .post(config.routes.chargebeeSubscriptionCancel)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function removeSubscriptionCancellation() {
  return api()
    .post(config.routes.chargebeeSubscriptionRemoveCancel)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function listInvoices(params) {
  return api()
    .get(`${config.routes.chargeBeeInvoices}?${objToUrlParams(params)}`)
    .then((res) => InvoiceList.fromJson(res.data))
    .catch(catchHandler)
}

export function changeSubscriptionPlan(planId) {
  return api()
    .put(config.routes.chargebeeSubscriptionPlan, {
      plan_id: planId
    })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function unlockInvoicesForUser(payload) {
  return api()
    .put(`${config.routes.unlockInvoices}${payload.user}`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
