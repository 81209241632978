import { Models } from '@rematch/core'
import auth from './containers/auth/model'
import review_request from './containers/reviews/utility/request-review/model'
import contact from './containers/contacts-hub/model'
import accountsettings from './containers/account-settings/model'
import groups from './containers/contacts-hub/model'
import review from './containers/reviews/model'
import common from './containers/common/model'
import services from './containers/services/model'
import booking from './containers/booking/model'
import payment from './containers/payment/model'
import manage_business from './containers/manage-my-business/model'
import manageBusiness from './containers/manage-my-business/model'
import medialibrary from './containers/media-library/model'
import placement from './containers/placement/model'
import site from './containers/sites/model'
import messenger from './containers/messenger/model'
import engagement from './containers/engagement/model'
import myaccount from './containers/myaccount/model'
import home from './containers/home/model'
import onboarding from './containers/onboarding-v4/model'
import selfservice from './containers/selfservice/model'
import location from './containers/enterprise/location/model'
import enterpriseReviews from './containers/enterprise/reviews/model'
import enterprisePlacement from './containers/enterprise/placement/model'
import enterpriseMangers from './containers/enterprise/managers/model'
import enterpriseReporting from './containers/enterprise/reporting/model'
import enterpriseContacts from './containers/enterprise/contact-hub/model'
import enterpriseHome from './containers/enterprise/home/model'
import enterpriseWidget from './containers/enterprise/widgets/model'
import settings from './containers/enterprise/settings/model'
import enterpriseMessenger from './containers/enterprise/messenger/model'
import instantSites from './containers/instant-sites/model'
import { actionCenter } from './containers/home/actionCenter/model'
import { paymentsGrowth } from './containers/home/paymentsOnboarding/model'
import { firstExperience } from './containers/first-experience/model'
import upgradePlan from './containers/upgrade/model'
import reactivate from './containers/reactivate/model'
import newPlan from './containers/upgrade/new-plan/model'
import updatePlan from './containers/upgrade/update-plan/model'
import { estimates } from './containers/estimate/model'
import { insurances } from './containers/insurance/model'
import { wisetack } from './containers/wisetack/model'

export interface RootModel extends Models<RootModel> {
  auth: typeof auth
  review_request: typeof review_request
  review: typeof review
  contact: typeof contact
  groups: typeof groups
  accountsettings: typeof accountsettings
  common: typeof common
  services: typeof services
  booking: typeof booking
  payment: typeof payment
  manage_business: typeof manage_business
  manageBusiness: typeof manageBusiness
  medialibrary: typeof medialibrary
  placement: typeof placement
  site: typeof site
  messenger: typeof messenger
  myaccount: typeof myaccount
  engagement: typeof engagement
  home: typeof home
  onboarding: typeof onboarding
  location: typeof location
  enterpriseReviews: typeof enterpriseReviews
  selfservice: typeof selfservice
  enterprisePlacement: typeof enterprisePlacement
  enterpriseMangers: typeof enterpriseMangers
  enterpriseReporting: typeof enterpriseReporting
  enterpriseContacts: typeof enterpriseContacts
  enterpriseWidget: typeof enterpriseWidget
  enterpriseHome: typeof enterpriseHome
  settings: typeof settings
  enterpriseMessenger: typeof enterpriseMessenger
  instantSites: typeof instantSites
  actionCenter: typeof actionCenter
  paymentsGrowth: typeof paymentsGrowth
  firstExperience: typeof firstExperience
  upgradePlan: typeof upgradePlan
  reactivate: typeof reactivate
  newPlan: typeof newPlan
  updatePlan: typeof updatePlan
  estimates: typeof estimates
  insurances: typeof insurances
  wisetack: typeof wisetack
}

export const models: RootModel = {
  auth,
  review_request,
  review,
  contact,
  groups,
  accountsettings,
  common,
  services,
  booking,
  payment,
  manage_business,
  manageBusiness,
  medialibrary,
  placement,
  site,
  messenger,
  myaccount,
  engagement,
  home,
  onboarding,
  location,
  enterpriseReviews,
  selfservice,
  enterprisePlacement,
  enterpriseMangers,
  enterpriseReporting,
  enterpriseContacts,
  enterpriseWidget,
  enterpriseHome,
  settings,
  enterpriseMessenger,
  instantSites,
  actionCenter,
  paymentsGrowth,
  firstExperience,
  upgradePlan,
  reactivate,
  newPlan,
  updatePlan,
  estimates,
  insurances,
  wisetack
}
