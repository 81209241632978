import _ from 'lodash'
import { useRef, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Dispatch, RootState } from '@app/store'
import { FeatureFlag, getFeatureVal } from './configcat'
// Hook
// https://usehooks.com/usePrevious/
export function usePrevious<T>(value: T): T | undefined {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()
  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes
  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export function useMediaQuery(mediaQuery: string): boolean {
  const [match, setMatch] = useState<boolean>(false)
  useEffect(() => {
    window.matchMedia(mediaQuery).addEventListener('change', (e) => setMatch(e.matches))
  }, [])
  return match
}

/**
 * Get a feature flag
 * @param feature
 * @returns
 */
export const useFeatureVal = (feature: FeatureFlag): boolean => {
  const dispatch = useDispatch<Dispatch>()
  const me = useSelector((state: RootState) => (state.auth as any).me) // change this when we fix the models
  const [featureStatus, setFeatureStatus] = useState<boolean>(true)
  // get me
  useEffect(() => {
    if (!me) dispatch.auth.getMe()
  }, [])
  // set state of feature flag value
  useEffect(() => {
    const asyncEffect = async () => {
      const status = await getFeatureVal(feature, me)
      setFeatureStatus(status)
    }
    asyncEffect()
  }, [me])

  return featureStatus
}
