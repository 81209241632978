import moment from 'moment';
import { segmentEventTrack } from '../segment';
import { SegmentEventData, ContactType } from './types';

export const trackContactImported = (type: ContactType, numOfContacts: number) => {
  const today = new Date();
  const time = moment(today).format('LT');
  const date = moment(today).format('L');
  const data: SegmentEventData = {
    event: 'contact-imported',
    properties: {
      imported_source: type,
      total_contacts: numOfContacts,
      date: date,
      time: time,
    },
  };
  segmentEventTrack(data);
};
