import * as React from 'react';

interface ClosePopupButtonProps {
  id?: string;
  name?: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
  className?: string;
}
export const ClosePopupButton = ({ name, id, onClick, className }: ClosePopupButtonProps) => {
  return (
    <button
      type="reset"
      id={id}
      name={name}
      className={`close custom-modal-close-button remove-modal-close ${className || ''}`}
      onClick={onClick}
    >
      <span aria-hidden="true">×</span>
    </button>
  );
};
