import React from 'react'
import Popup from '../popups/basic-modal'
import Icon from '@components/icons'
import moment from 'moment'
import * as Yup from 'yup'
import style from './review-estimate-popup.module.scss'

import { Formik, Form, Field } from 'formik'
import { Box, ThemeProvider, theme } from '@gositeinc/ui'
import { formatUSDFromCent } from '@helpers/utility'

import { WisetackPromotionalMessageContainer } from '@app/containers/wisetack/WisetackPromotionalMessageContainer'
import { WisetackDisclosureContainer } from '@app/containers/wisetack/WisetackDisclosureContainer'
import { useTranslation } from 'react-i18next'

const MessageSection = ({
  name,
  phone,
  email,
  avatarUrl,
  message,
  onConfirm = (message) => Promise.resolve(),
  onCancel = () => Promise.resolve()
}) => {
  const { t } = useTranslation()
  const schema = Yup.object().shape({
    message: Yup.string().min(1).required()
  })

  const initialValues = {
    message:
      message || t('estimates.let-us-know-if-you-have-any-questions-thanks-so-much-for-your-interest-in-our-services')
  }

  return (
    <Formik
      validateOnChange={true}
      validateOnBlur={true}
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={schema}
      isInitialValid={schema.isValidSync(initialValues)}
      onReset={() => {}}
      onSubmit={async ({ message }) => {
        await onConfirm(message)
      }}
      render={({ isValid, isSubmitting }) => {
        return (
          <Form id="frmSendInvoice" name="frmSentInvoice" className="custom-get-paid-form mt-30">
            <button
              name="btninvoiceCloseModal"
              type="reset"
              className="close custom-modal-close-button remove-modal-close"
              onClick={() => onCancel()}>
              <span aria-hidden="true">×</span>
            </button>
            <div className="row">
              <div className="col-12">
                <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                  <label className="label-text">{t('estimates.sending-to')}</label>
                  <Box
                    className="d-flex align-items-center border rounded-sm p-15"
                    sx={{
                      figure: {
                        minWidth: '3rem',
                        width: '3rem'
                      },
                      image: {
                        objectFit: 'cover'
                      }
                    }}>
                    <figure className="mb-0 mr-15">
                      <img
                        className="w-100 rounded-circle"
                        src={avatarUrl || 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'}
                        alt=""
                      />
                    </figure>
                    <div>
                      <div className="d-flex mb-5">
                        <h4>{name || ''}</h4>
                      </div>
                      <div className="d-flex">
                        {[phone, email]
                          .filter((info) => info)
                          .map((info, idx) => <div key={idx}>{info}</div>)
                          .reduce((prev, curr, idx) => [
                            prev,
                            <Box
                              key={`dot-${idx}`}
                              className="pl-5 pr-5"
                              sx={{
                                position: 'relative',
                                display: 'inline-block',
                                '&:after': {
                                  position: 'absolute',
                                  content: '" "',
                                  display: 'block',
                                  width: '2px',
                                  height: '2px',
                                  background: '#576c77',
                                  top: '50%',
                                  marginTop: '-1px',
                                  marginLeft: '-1px'
                                }
                              }}
                            />,
                            curr
                          ])}
                      </div>
                    </div>
                  </Box>
                </div>
              </div>
              <div className="col-12">
                <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                  <label className="label-text">MESSAGE</label>
                  <div className="d-flex flex-column">
                    <Field
                      type="text"
                      name="message"
                      className="form-control form-control-lg"
                      placeholder={t('estimates.enter-message')}
                      maxLength={512}
                    />
                  </div>
                </div>
              </div>
            </div>
            <footer className="d-block d-flex justify-content-around mt-10">
              <button
                className={'btn btn-primary border-0 btn-xl mr-10 flex-fill'}
                data-test="send-invoice-form-btn"
                id="frmBtnSendInvoice"
                disabled={isSubmitting || !isValid}
                type="submit">
                {t('estimates.send-estimate')}
                {isSubmitting && (
                  <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                    <Icon name="spinner" />
                  </i>
                )}
              </button>
              <button
                onClick={() => onCancel()}
                type="reset"
                id="frmBtnSendInvoice_btnCancel"
                className={'btn btn-xl ml-10 cancel-btn flex-fill'}>
                {t('estimates.cancel')}
              </button>
            </footer>
          </Form>
        )
      }}
    />
  )
}

const EstimatePreview = ({ estimate, businessName, onEdit = () => Promise.resolve() }) => {
  const { t } = useTranslation()
  const {
    contact: { name, phone, email },
    items,
    sendTime,
    attachments
  } = estimate

  function formatBytes(inputBytes, decimalPlaces = 2) {
    if (!+inputBytes) return '0 Bytes'
    const numOfDecimalPlaces = decimalPlaces < 0 ? 0 : decimalPlaces
    const numOfBytes = Math.floor(Math.log(inputBytes) / Math.log(1024))
    return `${parseFloat((inputBytes / Math.pow(1024, numOfBytes)).toFixed(numOfDecimalPlaces))} ${
      ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'][numOfBytes]
    }`
  }

  function formatDate(timeStamp) {
    const moment = require('moment')
    return moment(timeStamp).format('D MMM, YYYY')
  }

  const totalAmountInCents = items.reduce((prev, curr) => prev + curr.rate * curr.quantity, 0)
  const total = formatUSDFromCent(totalAmountInCents)
  return (
    <div className={style['invoice-preview-container']}>
      <div className={style['header']}>
        <div className={style['title']}>{t('estimates.estimate-summary')}</div>
        <div className={style['icon-container']} onClick={() => onEdit()} data-test="edit-invoice-form-btn">
          <Icon name="editPencilPaper" />
          <div data-test="invoice-preview-edit-btn" className={style['link']}>
            {t('estimates.edit-estimate')}
          </div>
        </div>
      </div>
      <div className={style['preview-main']}>
        <div className={style['main-header']}>
          <div className={style['head']}>{`${businessName} - ${moment().format('MMM YYYY')}`}</div>
          <div className={style['sub']}>{`${moment(Math.max(sendTime, Date.now())).format('MMMM D, YYYY')}`}</div>
        </div>
        <div className={style['bill-to']}>
          <div className={style['head']}>{t('estimates.bill-to-0')}</div>
          <div data-test="invoice-preview-contact" className={style['contact']}>
            {name && <div style={{ paddingBottom: '10px' }}>{`${name}`}</div>}
            {phone && <div style={{ paddingBottom: '10px' }}>{phone}</div>}
            {email && <div>{email}</div>}
          </div>
        </div>
        <div data-test="invoice-preview-item-list" className={style['item-list']}>
          {items.map(({ rate, name, quantity }, idx) => (
            <React.Fragment key={`${name}-${idx}`}>
              <div>
                <div className={style['item-title']}>
                  <div style={{ color: '#576C77' }}>{name}</div>
                  <div>{formatUSDFromCent(rate * quantity)}</div>
                </div>
                <div className={style['item-count']}>
                  <div>{t('estimates.qty')}</div>
                  <div>{quantity}</div>
                </div>
                <div className={style['item-price']}>
                  <div>{t('estimates.rate')}</div>
                  <div>{formatUSDFromCent(rate)}</div>
                </div>
              </div>
              <div className={style['subtotal']} style={{ paddingTop: '0.625rem' }}>
                <div>Subtotal</div>
                <div className={style['number']}>{formatUSDFromCent(rate * quantity)}</div>
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className={style['total']}>
          <div className={style['title']}>{t('estimates.total')}</div>
          <div>{total}</div>
        </div>
        <WisetackPromotionalMessageContainer amountInCents={totalAmountInCents} />
        <WisetackDisclosureContainer amountInCents={totalAmountInCents} />

        {attachments && attachments.length !== 0 ? (
          <div>
            <div className={style['head']}>
              <label className="label-text" style={{ 'margin-right': '70%', color: '#576C77' }}>
                {t('estimates.attachments-0')}
              </label>
            </div>

            <table className={style['table-files']}>
              <thead></thead>
              <tbody>
                {attachments.map((file, i) => (
                  <div className="border rounded-sm mb-15" key={file.name}>
                    <tr style={{ width: '100%' }}>
                      <td rowSpan={3} style={{ width: '10%' }}>
                        {file.s3Info.key.includes('.pdf') ? (
                          <Icon name="extPdf" className={style['show-files']} />
                        ) : file.s3Info.key.includes('.doc') ? (
                          <Icon name="extDoc" className={style['show-files']} />
                        ) : (
                          <Icon name="photo" className={style['show-files']} />
                        )}
                      </td>
                      <td style={{ width: '80%' }}>
                        <Box className="text-secondary" sx={{ paddingLeft: '3%' }}>
                          {file.name}{' '}
                        </Box>
                      </td>
                    </tr>
                    <tr style={{ width: '100%' }}>
                      <td>
                        {file.size > 5 * 1024 * 1024 ? (
                          <Box sx={{ paddingLeft: '3%', color: 'red', fontSize: '13px' }}>
                            {t('estimates.file-size-should-be-less-than-5-mb')}
                          </Box>
                        ) : (
                          <Box sx={{ paddingLeft: '3%', fontSize: '13px' }}>
                            {formatBytes(file.size)} . {formatDate(file.uploadedAt)}
                          </Box>
                        )}
                      </td>
                    </tr>
                  </div>
                ))}
              </tbody>
            </table>
          </div>
        ) : null}
      </div>
    </div>
  )
}

export const ReviewEstimatePopup = ({
  open,
  title,
  estimate,
  businessName,
  onConfirm = (message) => Promise.resolve(),
  onCancel = () => Promise.resolve(),
  onEdit = () => Promise.resolve()
}) => {
  const { t } = useTranslation()
  const { name, phone, email } = estimate.contact

  return (
    <ThemeProvider theme={theme}>
      <Popup
        open={open}
        title={title || t('estimates.review-and-send-estimate')}
        onCancel={() => onCancel()}
        type="default"
        modalBackdropClicked={() => onCancel()}
        id="modalSendInvoiceTypeLink">
        <MessageSection name={name} phone={phone} email={email} onConfirm={onConfirm} onCancel={onCancel} />
        <EstimatePreview estimate={estimate} businessName={businessName} onEdit={onEdit} />
      </Popup>
    </ThemeProvider>
  )
}
