import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="appointments"
    path={'/:userId/appointments'}
    layoutSettings={{ page: 'booking' }}
    component={asyncComponent(() => import('./booking/list'))}
  />,
  <RestrictedRoute
    exact
    key="/appointments/calendar"
    path={'/:userId/appointments/calendar'}
    layoutSettings={{ page: 'booking' }}
    component={asyncComponent(() => import('./booking/calendar-view'))}
  />,
  <RestrictedRoute
    exact
    key="appointment-details"
    path={'/:userId/appointments/appointment-details/:id'}
    layoutSettings={{ page: 'booking' }}
    component={asyncComponent(() => import('./booking/details/booking-details'))}
  />,
  <RestrictedRoute
    exact
    key="appointment-settings"
    path={'/:userId/appointment/settings'}
    layoutSettings={{ page: 'booking' }}
    component={asyncComponent(() => import('./settings/settings'))}
  />,
  <RestrictedRoute
    exact
    key="/appointments"
    path={'/:userId/trial/appointments'}
    layoutSettings={{ page: 'booking' }}
    component={asyncComponent(() => import('./trial'))}
  />
]
