import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { segmentIdentify } from '@helpers/segment'
import Loader from '../loader'
import { setHeader } from '@helpers/index'
import notification from '../notification'
import config from '@config/index'
import { IsJsonString } from '@helpers/utility'
import QuickbookThanks from '@containers/payment/utility/quickbook-thanks-popup'
import { IS_INTUIT_ONBOARDING, IS_THUMBTACK_ONBOARDING } from '@helpers/constants'
import { history } from '@app/store'

const mapDispatchToProps = ({ auth, accountsettings, common, instantSites }) => {
  return {
    ...auth,
    ...accountsettings,
    ...common,
    ...instantSites
  }
}
const mapStateToProps = ({ auth, accountsettings, common, instantSites }) => {
  return {
    ...auth,
    ...accountsettings,
    ...common,
    ...instantSites
  }
}
export class InstantSiteLayout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      page: ''
    }
    this.receiveMessage = this.receiveMessage.bind(this)
  }
  closeThanksModal () {
    this.setState({ showThanksModal: false })
  }

  async receiveMessage (event) {
    if (event && event.data) {
      const data = IsJsonString(event.data) ? JSON.parse(event.data) : {}
      if (
        data &&
            data &&
            data.code &&
            !this.state.quickbookEvent && !data.isThumbtack &&
            (window.location.pathname.indexOf('app-source-flow') > -1)
      ) {
        await this.setState({ quickbookEvent: true })

        if (this.state.quickbookEvent) {
          data.clientId = config.quickbooksClientId
          data.redirectUri = `${window.location.origin}/socialconnect`
          console.log(data)

          await this.props.quickBookAuth(data)
          if (!this.props.apiError) {
            await this.props.setQuickBookAlert(true)
            try {
              localStorage.setItem(IS_INTUIT_ONBOARDING, JSON.stringify(true))
            } catch (e) {
              console.log(e)
            }
          } else {
            notification('error', 'Failed to connect quickbooks')
          }

          if (this.props.quickbook) {
            const payload = { ...this.props.quickbook, ...this.props.qbsettings }
            this.props.updateQuickBookSettings(payload).then(async () => {
              await this.props.fetchAccountSettings({ _id: this.props.me._id })
              history.push('/')
            })
          }
        }
        await this.setState({ quickbookEvent: false })
      }

      if (
        data &&
            data &&
            data.code &&
            data.isThumbtack && !this.state.thumbtackEvent
      ) {
        await this.setState({ thumbtackEvent: true })

        if (this.state.thumbtackEvent) {
          data.clientId = config.thumbtackClientId
          data.redirectUri = `${window.location.origin}/thumbtack/redirect`
          console.log(data)

          await this.props.thumbtackAuth(data)
          if (!this.props.apiError) {
            await this.props.setThumbtackAlert(true)
            try {
              localStorage.setItem(IS_THUMBTACK_ONBOARDING, JSON.stringify(true))
            } catch (e) {
              console.log(e)
            }
          } else {
            notification('error', 'Failed to connect thumbtack')
          }

          if (this.props.thumbtack) {
            const payload = { ...this.props.thumbtack, ...this.props.qbsettings }
            console.log('payload is ', payload)
            this.props.updateThumbtackSettings(payload).then(async () => {
              await this.props.fetchAccountSettings({ _id: this.props.me._id })
              history.push('/')
            })
          }
        }
        await this.setState({ thumbtackEvent: false })
      }
    }
  }

  async componentDidMount () {
    if (!this.props.domainSettings) { await this.props.validateDomain() }
    setHeader(this.props.domainSettings, `  ${this.props.settings && this.props.settings.title ? this.props.settings.title : ''}`)
    if (!this.props.me && this.props.user) { await this.props.getMe() }

    // if (this.props.products && isEnabled("review-manager", this.props.products)) {
    //     let reviewpayload = {
    //         start: 0,
    //         limit: 6
    //     }
    //     await this.props.getRecentReview(reviewpayload)
    // }

    if (!this.props.hasMeValue) {
      this.props.updateHasMeValue()
      if (this.props.me && this.props.me._id) {
        // segment Identify
        segmentIdentify(this.props.me, this.props.role)
        await this.props.fetchAccountSettings({ _id: this.props.me._id })
      }
    }
    window.addEventListener('message', this.receiveMessage)
  }
  componentWillUnmount () {
    window.removeEventListener('message', this.receiveMessage)
  }
  render () {
    const { children } = this.props

    return (
      <div className={'app-body overlay-loader-outer '} >
        <Fragment>
          {this.props.me && this.props.me._id
            ? <div className="instant-main-wrapper pl-0 w-100 d-flex position-relative">
              <Fragment>
                <main className="main mw-100 flex-grow-1">
                  {children}
                </main>
              </Fragment>
            </div>
            : <div className="full-page-loader">
              <Loader />
            </div>
          }
          {this.state.showThanksModal ? (
            <QuickbookThanks
              close={this.closeThanksModal.bind(this)}
              quickbookData={this.props.quickbookData}
              ref={(ref) => (this.QuickbookThanks = ref)}
            />
          ) : null}
        </Fragment>
      </div>
    )
  }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(InstantSiteLayout)
