import React from "react"
import asyncComponent from "@helpers/async-func"
import { EnterpriseRoute } from "@app/router/access-route"

export default [
    <EnterpriseRoute 
        exact
        key="/enterprise/placement/overview"
        path="/:userId/enterprise/placement/overview"
        layoutSettings={{ page: "placement", title: "Placement" }}
        component={asyncComponent(() => import("./overview"))}
    />,
    <EnterpriseRoute 
        exact
        key="/enterprise/placement/listing"
        path="/:userId/enterprise/placement/listing"
        layoutSettings={{ page: "placement", title: "Placement" }}
        component={asyncComponent(() => import("./listings"))}
    />,
    <EnterpriseRoute 
        exact
        key="/enterprise/placement/posts"
        path="/:userId/enterprise/placement/posts"
        layoutSettings={{ page: "placement", title: "Placement" }}
        component={asyncComponent(() => import("./posts"))}
    />,
    <EnterpriseRoute 
        exact
        key="/enterprise/placement/questions"
        path="/:userId/enterprise/placement/questions"
        layoutSettings={{ page: "placement", title: "Placement" }}
        component={asyncComponent(() => import("./qa"))}
    />,
    <EnterpriseRoute 
        exact
        key="/enterprise/placement/post-details/:post_id"
        path="/:userId/enterprise/placement/posts-details/:post_id"
        layoutSettings={{ page: "placement", title: "Placement" }}
        component={asyncComponent(() => import("./posts/post-details"))}
    />
]