import React from 'react'
import '.././globelCreate.css'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import SentBulkReviewRequest from '../reviewBulkUpdate'
import { phoneNumberFormat, getcustomersReferenceParams, getFormatedToday, convertHoursToArray } from '@helpers/utility'
import { SendMessagePopup } from '@components/popups/sendMessagePopup'
import BookingPaymentPopupContainer from '../popups/booking-payment-popup-container'
import SendEmailSmsPopup from '../popups/send-email-sms-popup'
import AddServicePopup from '@containers/enterprise/reporting/utility/add-service-popup'
import AddBookingModal from '@containers/enterprise/reporting/utility/addBookingModalV2'
import AddContactModal from '@containers/enterprise/contact-hub/utility/addContactModal'
import { withElements } from '@containers/payment/utility/stripe'
import { trackUNBEvent } from '@helpers/segmentEvents/unb'
import { segmentEventTrack } from '@helpers/segment'
import { GlobalEnterpriseModal } from './globalEnterpriseModal'
import CreateGroupPopup from '@containers/contacts-hub/utility/create-group-popup'
import QuickView from '@containers/booking/utility/quick-view'
import notification from '../notification'
import config from '@config/index'
import { history } from '@app/store'
import AddManagerModal from '@containers/enterprise/managers/utility/addManager'

const mapDispatchToProps = ({
  auth,
  payment,
  common,
  booking,
  contact,
  location,
  enterpriseContacts,
  services,
  manageBusiness,
  accountsettings,
  paymentsGrowth,
  messenger,
  review,
  enterpriseReviews,
  enterpriseReporting
}) => {
  return {
    ...auth,
    ...payment,
    ...common,
    ...booking,
    ...contact,
    ...enterpriseContacts,
    ...location,
    ...services,
    ...manageBusiness,
    ...accountsettings,
    getPaymentsStatusCard: paymentsGrowth.getPaymentsStatusCard,
    getPaymentsFirstTimeCards: paymentsGrowth.getPaymentsFirstTimeCards,
    ...messenger,
    ...review,
    ...enterpriseReviews,
    ...enterpriseReporting
  }
}
const mapStateToProps = ({
  auth,
  payment,
  common,
  booking,
  contact,
  location,
  enterpriseContacts,
  services,
  manageBusiness,
  accountsettings,
  paymentsGrowth,
  messenger,
  review,
  enterpriseReviews,
  enterpriseReporting
}) => {
  return {
    ...auth,
    ...payment,
    ...common,
    ...booking,
    ...contact,
    ...enterpriseContacts,
    ...location,
    ...services,
    ...accountsettings,
    ...manageBusiness,
    firstTimeActionCards: paymentsGrowth.actions,
    statusCard: paymentsGrowth.statusCard,
    ...messenger,
    ...review,
    ...enterpriseReviews,
    ...enterpriseReporting
  }
}
class GlobalCreatePopup extends React.Component {
  t = this.props.t
  constructor(props) {
    super(props)
    this.state = {
      currentPage: 1,
      open: false,
      showMore: false,
      showSendSmsPopup: false,
      showInvoiceModal: false,
      invoiceItem: '',
      showGlobalPopup: true,
      openBookingModal: false,
      bookingType: 'new',
      me: '',
      amount: '',
      statusType: '',
      servicesList: '',
      invoiceSaveLoader: false,
      trackedFirstTransactions: false,
      openPaymentLinkPopup: false,
      paymentLinkLoader: false,
      contactInfo: '',
      showAddNewUserPopup: false,
      openSendBookingLinkModal: false,
      submitting: false,
      showImportMenu: false,
      locations: '',
      selectedLocations: [],
      filterValue: {},
      selectedServicePrice: '',
      selectedServiceTitle: ''
    }
    this.limit =
      this.props.domainSettings && this.props.domainSettings.generalSettings
        ? Number(this.props.domainSettings.generalSettings.frontend_list_count)
        : config.frontendPaginationLimit
    if (window.innerWidth > 1024) {
      document.body.classList.add('side-menu-open')
    }
    this.addContactModal = React.createRef()
    this.addModal = React.createRef()
  }

  showLess() {
    this.setState({ showMore: !this.state.showMore })
  }

  async componentDidMount() {
    this.init()
  }

  async init() {
    if (!this.props.products) {
      await this.products.getMe()
    }
    if (!this.props.enterpriselocations) {
      await this.props.getEnterpriseLocations()
    }
    const locations = []
    this.props.enterpriselocations &&
      this.props.enterpriselocations.forEach((loc) => {
        if (loc.location_identifier && loc.city) loc.name = loc.location_identifier + ' ' + loc.city
        else if (loc.city) loc.name = loc.city
        else if (loc.location_identifier) loc.name = loc.location_identifier
        else if (loc.locationName) loc.name = loc.locationName
        else loc.name = loc.displayname
        // if (loc.products.indexOf('booking') > -1) {
        locations.push({ _id: loc.customer, name: loc.name, products: loc.products, customerData: loc.customerData })
        // }
      })
    await this.setState({ locations: locations, selectedLocations: locations })
    if (this.props.products) {
      this.setState({ tableColumnFull: true })
    }
    this.props.fetchGroupsWithoutPaging({ fromenterprsie: true })
    this.props.getContactAnalytics()
  }

  showAddContactPopup() {
    trackUNBEvent(this.props.me, 'UNB_AddContact')
    this.setState({ showGlobalPopup: false })
    this.addContactModal.showPopup()
  }
  async saveGroup({ values, resetForm, location }) {
    await this.props.createGroups({ ...values, query: { customers: location._id } })
    this.props.fetchGroupsWithoutPaging({ fromenterprsie: true })
    this.resolve({ group: this.props.createdGroup })
    this.createGroupPopup.closeModal(true, resetForm)
    this.setState({ showGlobalPopup: false })
  }

  async openGroupCreatePopup(location) {
    this.createGroupPopup.showPopup(null, null, location)
    this.setState({ showGlobalPopup: false })
    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }
  onCloseModal() {
    this.setState({ showGlobalPopup: true })
  }

  closeAddContact() {
    this.setState({ showAddContact: false, showGlobalPopup: true })
  }

  async onAddContact({ values, resetForm }) {
    this.setState({ loading: true })
    values.query = { customers: values.location && values.location.value }
    await this.props.addContact(values)
    resetForm()
    if (!this.props.apiError) {
      this.closeAddContact()
      setTimeout(async () => {
        this.setState({ loading: false })
        this.setState({ showGlobalPopup: true })
      }, 1000)
    }
  }

  closeAddContactModal() {
    this.setState({ showAddContactModal: false, openPaymentLinkPopup: false, showGlobalPopup: true })
  }

  async showContactAddPopup(type, value, amount) {
    await this.setState({ contactType: type, contactVal: value, amount: amount || '' })
    this.setState({ showAddContactModal: true })
    this.closePaymentLinkModal()
  }

  async closeCreateGroupPopup() {
    await this.setState({ showGlobalPopup: true })
  }

  async openCreateGroupPopup() {
    trackUNBEvent(this.props.me, 'UNB_AddContactGroup')
    await this.createGroupPopup.showPopup('UNB')
    await this.setState({ showGlobalPopup: false })

    return new Promise((resolve, reject) => {
      this.resolve = resolve
      this.reject = reject
    })
  }

  // Review
  async showReviewRequestPopup() {
    trackUNBEvent(this.props.me, 'UNB_SendReviewRequest')
    this.setState({ showGlobalPopup: false })
    const data = await this.reviewRequestPop.showPopup()
    const data1 = {
      review_type: 'first_party',
      send_now: data.selectedContacts.length > 10,
      sent_to_all: false,
      recipient_contacts: data.selectedContacts
    }
    const payload = {}
    payload.data = data1
    payload.param = { customers: data.selectedLocation._id }
    await this.props.sendLocationReview(payload)
    this.reviewRequestPop.handleCancel()
  }

  generateLabels(item) {
    let label = ''
    if (item.email && item.mobile) {
      label = `${item.email}   -   ${phoneNumberFormat(item.mobile)}`
    } else if (item.email) {
      label = item.email
    } else if (item.mobile) {
      label = item.mobile
    }
    return label
  }

  async autoSuggestContacts(payload) {
    await this.props.autosuggestContacts(payload)
    let contactList = []
    let contactGroupList = []
    if (this.props.searchRes) {
      this.props.searchRes.forEach((element) => {
        if (element.type === 'contact') {
          const data = {
            label: element.first_name ? element.first_name : '',
            subLabel: this.generateLabels(element),
            value: element.first_name
              ? element.first_name
              : element.email
              ? element.email
              : phoneNumberFormat(element.mobile),
            valueData: element.email ? element.email : phoneNumberFormat(element.mobile),
            type: 'contact'
          }
          contactList = [...contactList, data]
        }
        if (element.type === 'group') {
          const data = {
            label: element.group_name ? element.group_name : '',
            value: element.contacts
              ? element.contacts.map((item) => {
                  return {
                    value: item.first_name
                      ? item.first_name
                      : item.email
                      ? item.email
                      : item.mobile
                      ? phoneNumberFormat(item.mobile)
                      : '',
                    valueData: item.email ? item.email : item.mobile ? phoneNumberFormat(item.mobile) : ''
                  }
                })
              : [],
            type: 'group'
          }
          contactGroupList = [...contactGroupList, data]
        }
      })
      this.setState({ reviewRequestContactList: contactList, reviewRequestGrouplist: contactGroupList })
    }
  }

  // booking
  addBookingPopup() {
    trackUNBEvent(this.props.me, 'UNB_AddBooking')
    this.setState({ showGlobalPopup: false })
    this.setState({ openBookingModal: true })
  }

  closeAddBookingUNB() {
    this.setState({ openBookingModal: false, editBookingDetails: null })
    this.setState({ showGlobalPopup: true })
  }
  closeAddBooking() {
    this.setState({ openBookingModal: false, editBookingDetails: null })
  }

  async getServiceDateSlots(val) {
    await this.props.getDateSlots(val.value)
    return this.props.dateSlots
  }

  async openBookingConfirmPopup(values) {
    this.setState({ bookingDetails: values })
    const custlocation =
      this.props.formatedLocations && this.props.formatedLocations.length
        ? this.props.formatedLocations.find((selItem) => selItem.customer === values.location)
        : {}
    const selectedLocationProducts = custlocation && custlocation.products ? custlocation.products : []
    if (this.state.bookingType === 'new' || (this.state.bookingType === 'edit' && values.status === 'incomplete')) {
      if (
        values.is_payment &&
        selectedLocationProducts.length &&
        selectedLocationProducts.includes('payment') &&
        values.service_rate &&
        parseFloat(values.service_rate) > 0
      ) {
        this.setState({ bookingloading: true })
        if (this.state.bookingType === 'edit' && values.booking_id) {
          values.isEdit = true
          this.setState({ isEdit: true })
        }
        if (values.status === '') {
          delete values.status
        }
        await this.props.addBooking({ ...values, query: { customers: values.location } })
        this.setState({ bookingloading: false })
        if (this.props.bookingresponse && !this.props.bookingApiError) {
          this.setState({ bookingresponse: this.props.bookingresponse, bookingDetails: this.props.bookingresponse })
          this.closeAddBooking()
          this.setState({ showPaymentPopup: true })
        }
      } else {
        this.closeAddBooking()
        this.setState({ showSendEmailSmsPopup: true })
      }
    } else if (this.state.bookingType === 'edit') {
      const payload = {
        bookingId: this.state.editBookingId,
        data: { ...values },
        query: { customers: values.location }
      }
      this.setState({ bookingloading: true })
      await this.props.editBooking(payload)
      this.setState({ bookingloading: false })
      this.closeAddBooking()
    }
  }

  async savePrivateContact(payload) {
    await this.props.savePrivateContact(payload)
    if (this.props.privateContact && this.props.privateContact.data) {
      this.props.segmentConatctTrack({ contactIds: [this.props.privateContact.data._id] })
    }
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_added',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
  }

  async cancelIncompleteBooking() {
    const { bookingresponse, editBookingDetails } = this.state
    if (bookingresponse) {
      const payload = { booking_id: bookingresponse._id }
      await this.props.cancelIncompleteBooking(payload)
    } else if (editBookingDetails) {
      const payload = { booking_id: editBookingDetails._id }
      await this.props.cancelIncompleteBooking(payload)
    }
    this.getBooking()
    this.setState({ bookingType: 'new', bookingresponse: null, editBookingDetails: null })
  }

  async createService(data, customer) {
    const newSerVice = await this.props.createPartnerService(data, customer)
    if (!this.props.AddServiceError && newSerVice) {
      await this.getSelectedLocationService(customer)
      return newSerVice
    }
  }

  async createServicePopoup(data, resetForm, customer) {
    await this.props.createPartnerService(data, customer)
    if (!this.props.AddServiceError) {
      await this.setState({ loading: true })
      let customerIds = []
      customerIds = this.state.selectedLocations.map((loc) => {
        return loc._id
      })
      const obj = {
        nopaging: true,
        refType: 'reference_number',
        source: 'direct'
      }
      const customers = getcustomersReferenceParams(customerIds, this.props.enterpriselocations)
      if (this.state.filterValue && this.state.filterValue.start_date) {
        obj.start_date = this.state.filterValue.start_date
        obj.end_date = this.state.filterValue.end_date
      }
      await this.props.getPartnerServices(obj, customers)
      await this.setState({ loading: false })
      this.AddServicePopUp.closeModal(true, resetForm)
    } else {
      this.AddServicePopUp.stopSpinner()
    }
  }

  async getSelectedLocationService(customer) {
    const customerIds = []
    customerIds.push(customer)
    const obj = {
      nopaging: true,
      refType: 'reference_number',
      source: 'direct'
    }
    const customers = getcustomersReferenceParams(customerIds, this.props.enterpriselocations)
    await this.props.getPartnerServices(obj, customers)
  }

  async autoSuggestContactsBooking(payload) {
    await this.props.autosuggestContacts(payload)
    let contactList = []
    if (this.props.searchRes) {
      this.props.searchRes.forEach((element) => {
        if (element.type === 'contact') {
          const data = {
            label: element.first_name ? `${element.first_name} ${element.last_name ? element.last_name : ''}` : '',
            subLabel: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            value: element.mobile ? phoneNumberFormat(element.mobile) : element.email,
            type: 'contact',
            image: element.image ? element.image : '',
            contactObj: element
          }
          contactList = [...contactList, data]
        }
      })
      this.setState({ contactList: contactList })
    }
  }

  closeSendEmailSms() {
    this.setState({ showSendEmailSmsPopup: false })
    this.setState({ showGlobalPopup: true })
  }

  async addBookingFromMessagepopup(values) {
    values.admin_offline = true
    delete values.isMobileChanged
    delete values.isEmailChanged
    delete values.isAddressChanged
    delete values.photo
    this.setState({ bookingloading: true })
    await this.props.addBooking({ ...values, query: { customers: values.location } })
    if (!this.props.apiError) {
      this.setState({ bookingloading: false })
      notification('success', this.t('bookings.appointment-created-successfully'))
      this.closeSendEmailSms()
      if (!this.props.bookingApiError && this.props.bookingresponse && this.props.bookingresponse._id) {
        this.openQuickView(this.props.bookingresponse)
        this.getBooking()
      }
    }
    this.setState({ bookingloading: false })
  }

  async updateContact(payload) {
    await this.props.updateContact(payload)
    if (!this.props.apiError) {
      const segmentData = {
        event: 'contact_updated',
        properties: {
          role:
            this.props.me &&
            this.props.me.role_id &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name &&
            this.props.me.role_id.role_name === 'trial'
              ? 'free-trial'
              : this.props.me.role_id.role_name,
          has_email: !!payload.email,
          has_phone: !!payload.mobile,
          has_address: !!(payload.address || payload.address2)
        }
      }
      segmentEventTrack(segmentData)
    }
  }

  openQuickView(event) {
    this.quickView.openDrawer({
      booking_id: event._id,
      event
    })
  }

  async getBooking() {
    this.setState({ loading: true })
    let customerIds = []
    customerIds = this.state.selectedLocations.map((loc) => {
      return loc._id
    })
    const payload = {
      start: (this.state.currentPage - 1) * this.limit,
      status: this.state.selectedStatus
        ? this.state.selectedStatus
        : this.props.accountsettings && this.props.accountsettings.default_booking_filter,
      limit: this.limit,
      nogroup: true,
      lead_type: 'appointment',
      refType: 'reference_number',
      sort: this.state.sortField,
      date: this.state.bookingDate,
      service: this.state.selectedService,
      asc: this.state.sortOrder === 'ASC' ? undefined : 1,
      customers: getcustomersReferenceParams(customerIds, this.props.enterpriselocations)
    }
    if (
      this.state.filterValue &&
      this.state.filterValue.start_date &&
      payload.status !== 'upcoming' &&
      !this.state.bookingDate
    ) {
      payload.start_date = this.state.filterValue.start_date
      payload.end_date = this.state.filterValue.end_date
    }
    if (payload.status === 'upcoming') payload.start_datetime = getFormatedToday()
    await this.props.fetchBookingData(payload)
    this.setState({ loading: false })
  }

  getButtonText() {
    let name = 'CONTINUE'
    if (this.state.bookingDetails.collect_via === 'invoice') name = this.t('bookings.send-invoice')
    else if (this.state.bookingDetails.collect_via === 'getpaid') name = this.t('bookings.send-payment-link')
    return name
  }

  async addBookingSubmit(values, resetForm) {
    const { bookingresponse } = this.state
    this.setState({ bookingloading: true })
    values.admin_offline = true
    delete values.isMobileChanged
    delete values.isEmailChanged
    delete values.isAddressChanged
    delete values.photo

    if (bookingresponse) {
      values.booking_id = bookingresponse._id
    }
    await this.props.addBooking({ ...values, query: { customers: values.location } })
    this.closeBookingPayment()
    if (!this.props.apiError) {
      this.setState({ bookingloading: false })
      notification('success', this.t('bookings.appointment-created-successfully'))
      if (this.props.bookingresponse && this.props.bookingresponse._id && !this.props.bookingApiError) {
        this.openQuickView(this.props.bookingresponse)
        setTimeout(() => {
          this.loadBookingDetails()
        }, 3000)
      }
    }
  }

  closeBookingPayment() {
    this.setState({ showPaymentPopup: false })
    this.setState({ bookingType: 'new' })
    this.setState({ showGlobalPopup: true })
  }

  onBookingEdit() {
    const editBookingDetails = this.state.bookingresponse
      ? this.state.bookingresponse
      : this.state.editBookingDetails
      ? this.state.editBookingDetails
      : {}
    this.setState({ bookingType: 'edit', editBookingDetails })
    this.closeBookingPayment()
    this.addBooking('edit')
  }

  async getServiceUserFunction(data) {
    await this.props.getServiceUser({ _id: data })
  }

  async UpdateService(data, resetForm) {
    console.log('UpdateService', data)
    this.setState({ updatedServiceObj: data, resetForm: resetForm })
    if (
      parseFloat(data.data.price) !== this.state.selectedServicePrice ||
      data.data.title !== this.state.selectedServiceTitle
    ) {
      await this.props.getRecurrenceInvoiceList(data.data._id)
      if (this.props.recurrenceInvoiceList && this.props.recurrenceInvoiceList > 0) {
        this.setState({ showPriceConfirmModal: true })
      } else {
        this.updateSelectedService(data, resetForm)
      }
    } else {
      this.updateSelectedService(data, resetForm)
    }
  }

  async getPartnerStaff(cid) {
    await this.props.getPartnerStaff({
      nopaging: true,
      role: 'staff,manager,customer',
      source: 'all',
      customers: cid,
      include_manager: 1
    })
  }

  async getPartnerBusiness(cid) {
    await this.props.loadBusinessSettings({ id: cid })
  }

  async getPartnerAccountSettings(cid) {
    await this.props.getLocationAccountSettings({ _id: cid })
  }

  async changeshowAddService() {
    this.setState({
      showAddService: false,
      showGlobalPopup: true
    })
  }

  async openAddServices() {
    trackUNBEvent(this.props.me, 'UNB_AddService')
    await this.setState({ showAddService: true, showGlobalPopup: false })
    await this.AddServicePopUp.showPopup('', 'add')
  }
  // messenger
  sendMessagePopup() {
    this.setState({ showSendSmsPopup: true, showGlobalPopup: false })
  }

  multiReviewWidget() {
    this.props.closeModal()
    history.push(`/${this.props.user.user_unique_id}/widgets/createwidget`)
  }

  redirectToAddLocation() {
    trackUNBEvent(this.props.me, 'UNB_AddLocation')
    this.props.closeModal()
    history.push(`/${this.props.user.user_unique_id}/location-new/add`)
  }

  redirectToAddManager() {
    // this.listManagers()
    this.getCategories()
    this.setState({ showGlobalPopup: false })
    this.addModal.showModal()
  }

  onCloseAddManagerModal(isManagerAdded) {
    if (isManagerAdded) {
      trackUNBEvent(this.props.me, 'UNB_AddManager')
      history.push(`/${this.props.me.user_unique_id}/enterprise/managers`)
    }
    this.props.closeModal()
  }

  async importGoogleContacts() {
    trackUNBEvent(this.props.me, 'UNB_SyncGoogleContacts')
    this.props.closeModal()
    history.push(`/${this.props.user.user_unique_id}/enterprise/widgets/settings`)
  }
  async importOutlookContacts() {
    trackUNBEvent(this.props.me, 'UNB_SyncOutlookContacts')
    this.props.closeModal()
    history.push(`/${this.props.user.user_unique_id}/enterprise/widgets/settings`)
  }
  async createMultiMessengerWidget() {
    trackUNBEvent(this.props.me, 'UNB_createMultiMessengerWidget')
    this.props.closeModal()
    history.push(`/${this.props.user.user_unique_id}/widgets/messenger/create`)
  }

  async widgetSetting() {
    trackUNBEvent(this.props.me, 'UNB_widgetSetting')
    this.props.closeModal()
    history.push(`/${this.props.user.user_unique_id}/enterprise/widgets/settings`)
  }

  async getCategories(search) {
    await this.props.getSavedFilters({ type: 'tag', search })
  }

  redirectToOutlookCalendar = async () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_ConnectOutlookCalendar')
    await this.props.saveUNBTabStatusForEnterprise({
      fromUNB: true,
      tab: 'widget-booking'
    })
    history.push(`/${this.props.me.user_unique_id}/enterprise/widgets/settings`)
  }

  redirectToConnectGoogleCalendar = async () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_ConnectGoogleCalendar')
    await this.props.saveUNBTabStatusForEnterprise({
      fromUNB: true,
      tab: 'widget-booking'
    })
    history.push(`/${this.props.me.user_unique_id}/enterprise/widgets/settings`)
  }

  redirectToSetupAutoResponse = async () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SetupAutoResponse')
    await this.props.saveUNBTabStatusForEnterprise({
      fromUNB: true,
      tab: 'widget-messenger'
    })
    history.push(`/${this.props.me.user_unique_id}/enterprise/widgets/settings`)
  }

  redirectToCreateBookingWidget = async () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_CreateBookingWidget')
    await this.props.saveUNBTabStatusForEnterprise({
      fromUNB: true,
      tab: 'widget-booking'
    })
    history.push(`/${this.props.me.user_unique_id}/enterprise/widgets/settings`)
  }

  redirectToSetupNotifications = async () => {
    this.props.closeModal()
    trackUNBEvent(this.props.me, 'UNB_SetupNotifications')
    await this.props.saveUNBTabStatusForEnterprise({
      fromUNB: true,
      tab: 'my-account-notification'
    })
    history.push(`/${this.props.me.user_unique_id}/myaccount`)
  }

  async sendBookingLink(data) {
    await this.props.sendBookingLink(data)
  }

  async listManagers() {
    this.setState({ loading: true })
    await this.props.listManagers({
      start: (this.state.page - 1) * this.limit,
      limit: this.limit,
      include_you: true,
      status: this.state.status,
      search: this.state.search,
      sortorder: this.state.sortOrder,
      sortfield: this.state.sortfield
    })
    this.setState({ loading: false })
  }

  render() {
    const { showGlobalPopup } = this.state
    return (
      <>
        {showGlobalPopup ? (
          <GlobalEnterpriseModal
            me={this.props.me}
            isReadOnlyRole={this.props.isReadOnlyRole}
            products={this.props.products}
            disabledProducts={this.props.disabledProducts}
            user={this.props.user}
            showMore={this.state.showMore}
            isContactEditPermitted={this.props.permissions.isContactEditPermitted}
            isContactCreatePermitted={this.props.permissions.isContactCreatePermitted}
            isReviewDeletePermitted={this.props.permissions.isReviewDeletePermitted}
            isBookingPermitted={this.props.permissions.isBookingPermitted}
            isMessengerSendPermitted={this.props.permissions.isMessengerSendPermitted}
            showReviewRequestPopup={this.showReviewRequestPopup.bind(this)}
            addBookingPopup={this.addBookingPopup.bind(this, 'new')}
            showAddContactPopup={this.showAddContactPopup.bind(this)}
            sendMessagePopup={this.sendMessagePopup.bind(this)}
            closeModal={this.props.closeModal.bind(this)}
            showLess={this.showLess.bind(this)}
            openAddServices={this.openAddServices.bind(this)}
            multiReviewWidget={this.multiReviewWidget.bind(this)}
            redirectToAddLocation={this.redirectToAddLocation.bind(this)}
            redirectToAddManager={this.redirectToAddManager.bind(this)}
            importGoogleContacts={this.importGoogleContacts.bind(this)}
            importOutlookContacts={this.importOutlookContacts.bind(this)}
            createMultiMessengerWidget={this.createMultiMessengerWidget.bind(this)}
            widgetSetting={this.widgetSetting.bind(this)}
            sendBookingLink={this.sendBookingLink.bind(this)}
            redirectToOutlookCalendar={this.redirectToOutlookCalendar.bind(this)}
            redirectToConnectGoogleCalendar={this.redirectToConnectGoogleCalendar.bind(this)}
            setupAutoResponse={this.redirectToSetupAutoResponse.bind(this)}
            redirectToCreateBookingWidget={this.redirectToCreateBookingWidget.bind(this)}
            redirectToSetupNotifications={this.redirectToSetupNotifications.bind(this)}
          />
        ) : (
          ''
        )}
        <AddManagerModal
          ref={(ref) => (this.addModal = ref)}
          locations={this.props.formatedLocations}
          categories={this.props.savedLocationFilter || []}
          getCategories={this.getCategories.bind(this)}
          createManager={this.props.createManager}
          manageUsers={this.props.manageUsers}
          createdManager={this.props.createdManager}
          onClose={this.onCloseAddManagerModal.bind(this)}
          fromUNB={true}
          userId={this.props.me._id}
          tagColors={
            (this.props.themeSettings &&
              this.props.themeSettings.generalSettings &&
              this.props.themeSettings.generalSettings.tag_colors) ||
            []
          }
        />
        <AddContactModal
          ref={(ref) => (this.addContactModal = ref)}
          close={this.onCloseModal.bind(this)}
          locations={this.props.formatedLocations}
          onSubmit={this.onAddContact.bind(this)}
          addGroups={this.saveGroup.bind(this)}
          newGroup={this.props.createdGroup}
          openCreatePopUp={this.openGroupCreatePopup.bind(this)}
          groups={this.props.locationGroups}
          getLocationGroups={this.props.getLocationGroups}
        />
        <CreateGroupPopup
          ref={(ref) => (this.createGroupPopup = ref)}
          closeCreateGroupPopup={this.closeCreateGroupPopup.bind(this)}
          onSubmit={this.saveGroup.bind(this)}
        />
        <>
          <SendMessagePopup
            open={this.state.showSendSmsPopup}
            onClose={() => this.setState({ showSendSmsPopup: false, showGlobalPopup: true })}
            enterprise={true}
            onSendMsgSuccess={(threadId) => {
              this.setState({ showSendSmsPopup: false, showGlobalPopup: true })
            }}
          />

          {this.state.openBookingModal ? (
            <AddBookingModal
              ref={(ref) => (this.bookingPopup = ref)}
              open={this.state.openBookingModal}
              serviceList={this.props.serviceList}
              predefinedTemplates={this.props.predefinedTemplates}
              getServiceDateSlots={this.getServiceDateSlots.bind(this)}
              processbooking={this.state.bookingloading}
              onSubmit={this.openBookingConfirmPopup.bind(this)}
              close={this.closeAddBookingUNB.bind(this)}
              getUnAvailableDateSlots={this.props.getUnAvailableDateSlots}
              unAvailabledateSlots={this.props.unAvailabledateSlots}
              products={this.props.products}
              autosuggestContacts={this.autoSuggestContactsBooking.bind(this)}
              contactList={this.state.contactList}
              savePrivateContact={this.savePrivateContact.bind(this)}
              privateContact={this.props.privateContact}
              me={this.props.me}
              accountsettings={this.props.accountsettings}
              locations={this.props.formatedLocations}
              bookingType={this.state.bookingType || 'new'}
              partnerServices={this.props.partnerServices}
              editBookingDetails={this.state.editBookingDetails}
              businessSettings={this.props.userBusinessSettings}
              loadBusinessSettings={this.props.loadBusinessSettings}
              fetchAccountSettings={this.props.fetchAccountSettings}
              getLocationAccountSettings={this.props.getLocationAccountSettings}
              userAccSettings={this.props.userAccSettings}
              cancelIncompleteBooking={this.cancelIncompleteBooking.bind(this)}
              createService={this.createService.bind(this)}
              getSelectedLocationService={this.getSelectedLocationService.bind(this)}
            />
          ) : null}
          {this.state.showSendEmailSmsPopup ? (
            <SendEmailSmsPopup
              open={this.state.showSendEmailSmsPopup}
              module={this.t('bookings.booking')}
              payloadData={this.state.bookingDetails}
              submitText={this.t('bookings.add-booking')}
              closeModal={this.closeSendEmailSms.bind(this)}
              submitData={this.addBookingFromMessagepopup.bind(this)}
              loader={this.state.bookingloading}
              updateContact={this.updateContact.bind(this)}
            />
          ) : null}
          {this.state.showPaymentPopup ? (
            <BookingPaymentPopupContainer
              open={this.state.showPaymentPopup}
              module={this.t('bookings.booking')}
              me={this.props.me}
              isEdit={this.state.isEdit}
              payloadData={this.state.bookingDetails}
              submitText={this.getButtonText()}
              submitData={this.addBookingSubmit.bind(this)}
              closeModal={this.closeBookingPayment.bind(this)}
              predefinedTemplates={this.props.predefinedTemplates}
              cancelIncompleteBooking={this.cancelIncompleteBooking.bind(this)}
              onBookingEdit={this.onBookingEdit.bind(this)}
              loader={this.state.bookingloading}
              isEnterprise={true}
            />
          ) : null}
          <QuickView
            ref={(ref) => (this.quickView = ref)}
            onBookingUpdate={this.getBooking.bind(this)}
            is_enterprise={true}
            allenterpriselocations={this.props.enterpriselocations}
            permissions={this.props.permissions}
            getPredefinedTemplate={this.props.getPredefinedTemplate}
          />
        </>
        <>
          <SentBulkReviewRequest
            getUserData={this.props.getUserData}
            userData={this.props.userData}
            close={this.onCloseModal.bind(this)}
            autosuggestContacts={this.autoSuggestContacts.bind(this)}
            contactList={this.state.reviewRequestContactList}
            contactGroupList={this.state.reviewRequestGrouplist}
            ref={(ref) => (this.reviewRequestPop = ref)}
            comment={this.props && this.props.accountsettings && this.props.accountsettings.comment}
            me={this.props.me}
            products={this.props.products}
            locations={this.props.locations}
            fetchEnterpriseReviewSettings={this.props.fetchEnterpriseReviewSettings}
            locationReviewSettings={this.props.locationReviewSettings}
          />
        </>
        {this.state.showAddService ? (
          <AddServicePopup
            serviceUSer={this.props.serviceUser}
            getServiceUserFunction={this.getServiceUserFunction.bind(this)}
            createService={this.createServicePopoup.bind(this)}
            UpdateService={this.UpdateService.bind(this)}
            ref={(ref) => (this.AddServicePopUp = ref)}
            businessHours={convertHoursToArray(this.props.businessSettings && this.props.businessSettings.hours)}
            me={this.props.me}
            disabledProducts={this.props.disabledProducts}
            assignedUser={this.props.partnerStaffs}
            getPartnerStaff={this.getPartnerStaff.bind(this)}
            getPartnerBusiness={this.getPartnerBusiness.bind(this)}
            getPartnerAccountSettings={this.getPartnerAccountSettings.bind(this)}
            partnerBusiness={this.props.userBusinessSettings ? this.props.userBusinessSettings : ''}
            partnerAccountSettings={this.props.userAccSettings ? this.props.userAccSettings : ''}
            changeshowAddService={this.changeshowAddService.bind(this)}
            editService={this.props.editService}
            locations={this.state.locations}
            enterpriselocations={this.props.enterpriselocations}
          />
        ) : (
          ''
        )}
      </>
    )
  }
}

// export default GlobalCreatePopup
export default compose(
  withElements({
    key: config.stripe_pk
  }),
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(GlobalCreatePopup)
