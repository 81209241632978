import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'

export const getPaymentsStatus = async () => {
  const testing = false
  const params = testing ? { skip_expiration: true } : {}
  return api()
    .get(`${config.routes.paymentsGrowthStatus}`, { params })
    .then((res) => res.data)
    .catch(catchHandler)
}

export const getPaymentsFirstTimeActions = async () => {
  return api()
    .get(`${config.routes.paymentsGrowth}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
