import React, { Fragment } from "react";
import { connect } from "react-redux";
import { compose } from "recompose";
import InfiniteScroll from "react-infinite-scroll-component";
import Style from "./index.module.scss";
import moment from "moment";
import { getIconImage, numberMask, isEnabled } from "@helpers/utility";
import Icons from "../icons";
import { history } from "@app/store";
import config from "@config/index";
import Tooltip from "../tooltip";

const mapDispatchToProps = ({ auth, accountsettings, review, common }) => {
    return {
        ...auth,
        ...accountsettings,
        ...review,
        ...common
    };
};

const mapStateToProps = ({ auth, accountsettings, review, common }) => {
    return {
        ...auth,
        ...accountsettings,
        ...review,
        ...common

    };
};

class Notification extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 12,
            start: 0,
            status: "all",
            notifications: [],
            popupLoader: true,
            notifTime: "",
            sendObjectId: "",
            showNotificationSidePopup: false,
            appointmentData: {},
            reviewRequestData: [],
            contactImportData: {},
            userImportData: {},
            subscriptionData: {},
            wbAnalyticsData: {},
            privateReviewData: {},
            gmbSuccessData: {},
            gmbFailData: {},
            yextSuccessData: {},
            yextFailData: {},
            subscriptionlimitData: {},
            customFormData: {},
            customFormWb: "",
            hubspotData: {},
        }
    }

    async getGositeNotifications(payload = { limit: this.state.limit, start: this.state.start, status: this.state.status }) {
        if (["partner", "manager"].indexOf(this.props.me.role_id.role_name) > -1) {
            /** [EVE-998] Changes for getting proper notifications for multi location user */
            if (this.props.me.isMultiLocation && this.props.me.total_accounts === 1) {
                await this.props.getGositeNotifications(payload)
            } else {
                await this.props.getEnterpriseNotifications(payload)
            }
        } else {
          await this.props.getGositeNotifications(payload)
        }
        if (this.props.notifications && this.props.notifications.docs && this.props.notifications.docs.length) {
            this.setState({ notifications: [...this.state.notifications, ...this.props.notifications.docs], start: (this.state.start + this.state.limit) })
        }
    }
    async init() {
        this.setState({ loading: true })
        await this.getGositeNotifications()
        this.setState({ loading: false })
    }

    componentDidMount() {
        this.init()
    }
    videoCommentReplace(text) {
        {
            let arr = text.split("'");
            return <Fragment><strong>{arr[1]}</strong><span>{arr[2]}</span></Fragment>
        }

    }
    cardConnectCommentReplace(text) {
        let arr = text.split(" ");
        return <Fragment><strong>Cardconnect </strong><span>{arr.slice(1).join(" ")}</span></Fragment>
    }
    cardFConnectCommentReplace(text) {
        let arr = text.split(" ");
        return <Fragment><span>{arr.slice(0, 3).join(" ")}</span><strong> {arr[3]} </strong><span>{arr.slice(4).join(" ")}</span></Fragment>
    }
    downloadCampainDetails(notification) {
        if (notification && notification.meta && notification.meta.s3response && notification.meta.s3response.Location) {
            window.open(notification.meta.s3response.Location)
        }
    }
    readNotifi(e, id, notification, index) {
        e.stopPropagation()
        this.readNotification(id, notification, index)
    }
    async readNotification(id, notification, index) {

        let payload = { id: id }
        if (this.props.user.role === "partner") {
            let activity = ["new_contact_fail", "google_sync_failed", "user_import_success", "user_import_failed"];
            payload.activity = activity
        }
        else if (["admin", "superadmin"].indexOf(this.props.me.role_id.role_name)) {
            let activity = ["user_import_success", "user_import_failed", "mid_missing", "cardconnect_settings_updated", "cardconnect_settings_update_failed", "scrape_process_complete", "hubspot_crm_status"];
            payload.activity = activity
        }

        await this.props.readNotification(payload)
        this.props.getNotificationCount()
        let allNotifications = [...this.state.notifications]
        allNotifications[index].is_new = false
        await this.setState({ notifications: allNotifications })
    }

    async markAsRead() {
        await this.props.readNotification()
        this.props.getNotificationCount()
        let allNotifications = [...this.state.notifications]
        allNotifications.forEach((item, index) => {
            item.is_new = false
        })
        await this.setState({ notifications: allNotifications })
    }

    viewModuleDetailsPopup(notification, index) {

        if (notification.is_new) {
            this.readNotification(notification._id, notification, index);
        }
        if (notification.activity_type === "new_review") {
            if (notification.object_id) {
                history.push(`/${this.props.user.user_unique_id}/reviews?reviewId=${notification.object_id}`)
            } else {
                history.push(`/${this.props.user.user_unique_id}/reviews`)
            }
        }
        else if (notification.activity_type === "private_review") {
            // TODO: NEED TO CONFORM feedbackId passed in query param
            history.push(`/${this.props.user.user_unique_id}/reviews/feedback?feedbackId=${notification.object_id}`)
        }
        else if (notification.activity_type === "website_appointment") {
            history.push(`/${this.props.user.user_unique_id}/appointments?bookingId=${notification.object_id}`);
        }
        else if (notification.activity_type === "website_contact_us") {
            if (notification && notification.meta && notification.meta.contact_id) {
                history.push(`/${this.props.user.user_unique_id}/contacts/mycontacts/${notification.meta.contact_id}`);
            }
        }
        else if (notification.activity_type === "website_custom_form") {
            if (notification && notification.meta && notification.meta.contact_id) {
                history.push(`/${this.props.user.user_unique_id}/contacts/mycontacts/${notification.meta.contact_id}`);
            }
        }
        else if (notification.activity_type === "new_message") {
            if (notification.meta.messenger_id) {
                history.push(`/${this.props.user.user_unique_id}/messenger?threadId=${notification.meta.messenger_id}`);
            }
        }
        else if (notification.activity_type === "new_payment" || notification.activity_type === "new_payment_messenger" || notification.activity_type === "new_payment_widget" || notification.activity_type === "new_payment_contact") {
            if (notification.object_id) {
                history.push(`/${this.props.user.user_unique_id}/transaction-details/${notification.object_id}`);
            }
        }
        else if (notification.activity_type === "new_qa" && ["partner", "manager"].indexOf(this.props.me.role_id.role_name) < 0) {
            history.push(`/${this.props.user.user_unique_id}/questions`);
        }
        else if ((notification.activity_type === "account_gmb_connect" || notification.activity_type === "account_gmb_disconnect" || notification.activity_type === "account_gmb_expired") && ["partner", "manager"].indexOf(this.props.me.role_id.role_name) < 0) {
            history.push(`/${this.props.user.user_unique_id}/business-settings`);
        }
        else if (["campaign_shedule_send", "review_send"].indexOf(notification.activity_type) > -1) {
            this.downloadCampainDetails(notification)
        }
    }
    gotoAppointmentDetails(appointment_id) {
        history.push(`/${this.props.user.user_unique_id}/appointments?bookingId=${appointment_id}`)
    }

    hasNotificationSidePopup(notificationType) {
        let list = [
            "review_send",
            "new_contact_success",
            "website_subscription",
            "website_contact_us",
            "audience_review",
            "product_limit_changed",
            "google_sync_failed",
            "google_sync_success",
            "yext_sync_failed",
            "website_custom_form",
            "user_import_success",
            "user_import_failed",
        ]
        return list.indexOf(notificationType) > -1
    }
    hideSidePopup() {
        this.setState({ showNotificationSidePopup: false })
    }
    getCustomFormText(text,field){
        var res = "";
        for(var i=0;i< text.length;i++){
            if( text[i]) {
                if(field === "Phone" || field === "phone" || field === "Mobile" || field === "mobile"){
                    res = res !=="" ? res + ", "+ numberMask(text[i]) : numberMask(text[i]);
                }else{
                    res = res !=="" ? res + ", "+ text[i] : text[i];
                }
            }
        }
        return res
    }
    async openNotificationSidePopup(e, notification, index) {
        e.stopPropagation();
        this.setState({
            popupLoader: true,
            notifTime: notification.created_at,
            sendObjectId: "",
            showNotificationSidePopup: true,
            appointmentData: {},
            reviewRequestData: [],
            contactImportData: {},
            userImportData: {},
            subscriptionData: {},
            wbAnalyticsData: {},
            privateReviewData: {},
            gmbSuccessData: {},
            gmbFailData: {},
            yextSuccessData: {},
            yextFailData: {},
            subscriptionlimitData: {},
            customFormData: null,
            customFormWb: "",
            hubspotData: {},
        })

        if (notification.is_new) {
            this.readNotification(notification._id, notification, index);
        }

        let popUpData = {}
        if (notification.activity_type === "review_send") {
            this.setState({ notificationModalTitle: "New Review request" })
            let payload = {}
            payload.date = moment.utc(notification.created_at).format("YYYY-MM-DD")
            payload.status = "send"
            payload.nopaging = true
            this.setState({ popuploading: true })
            await this.props.getReviewRequests(payload)

            if (this.props.reviewRequests) {
                this.setState({ reviewRequestData: this.props.reviewRequests })

            }
            this.setState({ popuploading: false })
        } else if (notification.activity_type === "new_contact_success") {
            this.setState({ notificationModalTitle: "Contacts Imported", contactImportData: notification.meta })

        } else if (notification.activity_type === "user_import_success") {
            this.setState({ notificationModalTitle: "Users Import", userImportData: notification.meta })

        } else if (notification.activity_type === "new_contact_fail") {
            this.setState({ notificationModalTitle: "Contact Import Failed", contactImportData: notification.meta })
        } else if (notification.activity_type === "website_subscription") {
            this.setState({ notificationModalTitle: "New Subscription" })
            this.setState({ popuploading: true })
            await this.props.getWebsiteSubscriptionData({ id: notification.object_id })
            if (this.props.websiteSubscription) {
                this.setState({ subscriptionData: this.props.websiteSubscription })
            }
            this.setState({ popuploading: false })

        } else if (notification.activity_type === "website_contact_us") {
            this.setState({ notificationModalTitle: "New Website Lead" })
            this.setState({ popuploading: true })
            await this.props.getWebsiteLeadData({ id: notification.object_id })
            if (this.props.appointmentData) {
                this.setState({ appointmentData: this.props.appointmentData })
            }
            this.setState({ popuploading: false })
        } else if (notification.activity_type === "website_custom_form") {
            this.setState({ notificationModalTitle: "New Website Lead", customFormData: notification.meta.custom_data, customFormWb: notification.meta.website_url ? notification.meta.website_url : "" })
        }
        else if (notification.activity_type === "audience_review") {
            this.setState({ notificationModalTitle: "Website Anaylyics" })

            let date = moment.utc(notification.created_at).format("MM/DD/YYYY");
            let payload = { "site_id": notification.object_id, date: date }
            this.setState({ popuploading: true })
            await this.props.getWebsiteAnalyticsSummary(payload)
            if (this.props.wbAnalyticsData) {
                popUpData.wbAnalyticsData = this.props.wbAnalyticsData;
            }
            this.setState({ popuploading: false })

        } else if (notification.activity_type === "private_review") {
            popUpData.notificationModalTitle = "Private Review";
            this.setState({ popuploading: true })
            await this.props.getFeedbackDetails(notification.object_id)
            if (this.props.feedback_details) {
                popUpData.privateReviewData = this.props.feedback_details && this.props.feedback_details.data;
            }
            this.setState({ popuploading: false })
        } else if (notification.activity_type === "product_limit_changed") {
            if (notification.comments.indexOf("website") > -1) {
                this.setState({ notificationModalTitle: "Website Limit Changed", subscriptionlimitData: { "website_limit": notification.meta.website_limit } })
            } else if (notification.comments.indexOf("contact") > -1) {
                this.setState({ notificationModalTitle: "Contact Limit Changed", subscriptionlimitData: { "contact_limit": notification.meta.contact_limit } })
            }

        } else if (notification.activity_type === "google_sync_success") {
            this.setState({ notificationModalTitle: "Google Sync Successful", gmbSuccessData: notification.user_id })

        } else if (notification.activity_type === "google_sync_failed") {
            this.setState({ notificationModalTitle: "Google Sync Failed", gmbFailData: notification.meta })

        } else if (notification.activity_type === "yext_sync_success") {
            this.setState({ notificationModalTitle: "Yext Sync Successful", yextSuccessData: notification.meta })
        } else if (notification.activity_type === "yext_sync_failed") {
            this.setState({ notificationModalTitle: "Yext Sync Failed", yextFailData: notification.meta })
        } else if (notification.activity_type === "hubspot_crm_status") {
            this.setState({ notificationModalTitle: "Hubspot Status", hubspotData: notification.meta })
        } else {
            this.setState({ notificationModalTitle: "New Appointment" })
            this.setState({ popuploading: true })
            await this.props.getWebsiteLeadData({ id: notification.object_id })
            if (this.props.appointmentData) {
                this.setState({ appointmentData: this.props.appointmentData })
                if (this.state.appointmentData.appointment_date) {
                    let parts = this.state.appointmentData.appointment_date.split("/");
                    let mydate = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);
                    this.setState({ formattedDate: mydate })
                }
                if (this.state.appointmentData.appointment_date) {
                    let mydate = this.state.appointmentData.appointment_date;
                    this.setState({ formattedDate: mydate })
                }
            }
            this.setState({ popuploading: false })
        }
    }

    getComment(notifi) {

        if (notifi.comments === "Yext location details updated with bussiness settings") { return <Fragment>Yext location details updated with <strong>business settings</strong></Fragment> }
        else if (notifi.comments === "You have 1 new reviews from Facebook") { return <Fragment>You have 1 new reviews from Facebook <strong>reviews</strong>from Facebook</Fragment> }
        else if (notifi.comments === "Bussiness settings has been synced with Yext") { return <Fragment> <strong>Business settings</strong> synced with Yext</Fragment> }
        else if (notifi.comments === "Contacts uploading failed") { return <Fragment> <strong>Contacts</strong> uploading failed</Fragment> }
        else if (notifi.comments === "You have a new website lead") { return <Fragment>You have a new <strong>website lead</strong></Fragment> }
        else if (notifi.comments === "You have a new message") { return <Fragment>You have a new <strong>message</strong></Fragment> }
        else if (notifi.comments === "You have a new private feedback") { return <Fragment>You have a new  <strong>private feedback</strong></Fragment> }
        else if (notifi.comments === "Admin updated your website limit") { return <Fragment>Admin updated your <strong>website limit</strong></Fragment> }
        else if (notifi.comments === "Contacts uploaded successfully") { return <Fragment> <strong>Contacts</strong> uploaded successfully</Fragment> }
        else if (notifi.comments === "New review request sent") { return <Fragment>New <strong>review request</strong> sent</Fragment> }
        else if (notifi.comments === "Business settings synced with google") { return <Fragment> <strong>Business settings</strong> synced with google</Fragment> }
        else if (notifi.comments === "Business settings syncing with google failed") { return <Fragment> <strong>Business settings</strong> synced with google failed</Fragment> }
        else if (notifi.comments === "Business settings synced with yext") { return <Fragment> <strong>Business settings</strong> synced with yext</Fragment> }
        else if (notifi.comments === "Business settings syncing with with yext failed") { return <Fragment> <strong>Business settings</strong> syncing with with yext failed</Fragment> }
        else if (notifi.comments === "You have a new subscription from website") { return <Fragment>You have a new <strong>subscription</strong> from website</Fragment> }
        else if (notifi.comments === "You have an appointment from website") { return <Fragment>You have an <strong>appointment</strong> from website</Fragment> }
        else if (notifi.comments === "Yext location details updated with business settings") { return <Fragment>Yext location details updated with <strong>Business settings</strong></Fragment> }
        else if (notifi.comments === "Your GoSite API activated by the administrator") { return <Fragment> Your<strong> GoSite API </strong> activated by the administrator</Fragment> }
        else if (notifi.comments === "Your GoSite API deactivated by the administrator") { return <Fragment>Your <strong> GoSite API </strong> deactivated by the administrator</Fragment> }
        else if (notifi.comments === "Users imported successfully") { return <Fragment> <strong>Users</strong> imported successfully</Fragment> }
        else if (notifi.comments === "You have a new review") { return <Fragment>You have a new <strong>review</strong></Fragment> }
        else if (notifi.comments === "Your Email campaign schedules have been sent!") { return <Fragment>Your <strong>Email campaign</strong> schedules have been sent</Fragment> }
        else if (notifi.comments === "Your Review request schedules have been sent") { return <Fragment>Your <strong>Review request</strong> schedules have been sent</Fragment> }
        else if (notifi.comments === "You have a new payment from website") {
            return <Fragment>You have a new payment from  <strong>website</strong>
            </Fragment>
        }
        else if (notifi.comments === "You have a new payment") { return <Fragment>You have a new <strong>payment</strong></Fragment> }
        else if (notifi.activity_type === "video_process_complete") { return <Fragment>{this.videoCommentReplace(notifi.comments)}</Fragment> }
        else if (notifi.activity_type === "cardconnect_settings_updated") { return <Fragment>{this.cardConnectCommentReplace(notifi.comments)}</Fragment> }
        else if (notifi.activity_type === "cardconnect_settings_update_failed") { return <Fragment>{this.cardFConnectCommentReplace(notifi.comments)}</Fragment> }
        else if (notifi.activity_type === "account_gmb_connect") { return <Fragment><strong>{notifi.meta.first_name}</strong> connected <strong>GMB</strong></Fragment> }
        else if (notifi.activity_type === "account_gmb_disconnect") { return <Fragment><strong>{notifi.meta.first_name}</strong> disconnected <strong>GMB</strong></Fragment> }
        else return notifi.comments
    }

    ifValidNotification(notif) {
        let isPartner = false
        if (["partner", "manager"].indexOf(this.props.me.role_id.role_name) > -1) {
            /** [EVE-998] Changes for getting proper notifications for multi location user */
            if (this.props.me.isMultiLocation && this.props.me.total_accounts === 1) {
                isPartner = false
            } else {
                isPartner = true
            }
        } else {
            isPartner = false
        }
        if (isPartner) {
            let activityTypes = [
                "new_review",
                "new_payment",
                "new_payment_messenger",
                "new_payment_widget",
                "new_payment_contact",
                "private_review",
                "website_appointment",
                "api_connect_disabled_by_admin",
                "api_connect_enabled_by_admin",
                "business_verification_request",
                "business_verification_complete",
                "scrape_process_complete",
                "video_process_complete",
            ]
            if (activityTypes.indexOf(notif.activity_type) > -1) {
                return false
            } else {
                return true
            }
        }
        return true
    }
    parseLocationIdentifier(data) {
        if (data && data.user_id && data.user_id.location_id && data.user_id.location_id.location_identifier)
            return data.user_id.location_id.location_identifier
        else if (data && data.user_id && data.user_id.location_id && data.user_id.location_id.locationName)
            return data && data.user_id && data.user_id.location_id && data.user_id.location_id.locationName && `${data.user_id.location_id.locationName}, ${this.fetchCityName(data.user_id.location_id)}`
        return false
    }
    fetchCityName(item) {
        let sel = null
        if (item._id) {
            let id = item._id
            if (this.props.formatedLocations.length) {
                sel = this.props.formatedLocations.find((item) => item.location_id === id)
            }

        }
        return sel && sel.city ? sel.city : ""
    }
    render() {
        let { notifications } = this.state
        return (

            <div id="notificationContainer" className={`${Style["notification-wrapper"]} m-auto dropdown-menu dropdown-menu-right ${this.props.show ? "d-block" : null}`} aria-labelledby="notification">
                <div className="px-20 py-10 d-flex justify-content-between align-items-center">
                    <h4>Notifications</h4>

                    <div className="position-relative d-flex align-items-center">
                        {this.props.notificationCount && this.props.notificationCount > 0 ?
                            <button id="btn_notifiMarkasReadBtn" onClick={this.markAsRead.bind(this)} type="button" className="btn btn-xs btn-view px-10 py-0 mr-15 border-0 rounded text-decoration-none">MARK ALL AS READ</button>
                            : null}
                        <div className="position-relative">
                            <button id="btn_notificationClose" type="button" onClick={this.props.close} className={`close remove-modal-close custom-modal-close-button ${Style["btn-dropdown-close"]}`}>×</button>
                        </div>
                    </div>
                </div>

                <div className="dropdown-divider"></div>
                {this.state.loading ?
                    <div>
                        <div className="dropdown-item text-center pt-10 pb-15">
                            <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="" />
                        </div>
                    </div>
                    :
                    notifications && notifications.length ?
                        <div className={`${Style["notification-list"]}`} id="notification-list">
                            <InfiniteScroll
                                dataLength={notifications && notifications.length}
                                next={this.getGositeNotifications.bind(this)}
                                hasMore={this.props.notifications && (this.props.notifications.total > (notifications.length))}
                                loader={
                                    <div className="text-center">
                                        <img src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="" />
                                    </div>
                                }
                                scrollableTarget="notification-list"
                            >
                                {notifications && notifications.length ? notifications.map((notifi, index) => (
                                    <Fragment key={index} >
                                        { notifi && ((notifi.comments === "You have a new message" && this.props.products && isEnabled("messenger", this.props.products)) || notifi.comments !== "You have a new message") && this.ifValidNotification(notifi) ?
                                            <div key={index} onClick={this.viewModuleDetailsPopup.bind(this, notifi, index)} className={`dropdown-item position-relative ${notifi.is_new ? `${Style["notification-active"]}` : `${Style["notification-inactive"]}`} ${notifi.activity_type === "admin_push" ? `${Style["cursor-none"]}` : ""}`  }>
                                                <div className={`d-flex py-10 ${Style["item-content"]}`}>
                                                    {notifi.activity_type ?
                                                        <div className={`${Style["b-icon"]} position-absolute`}>
                                                            <img src={getIconImage(notifi.activity_type)} alt="" />
                                                        </div>
                                                        : null}
                                                    <div className={`${Style["b-content"]} `}>
                                                        <p className="mb-0">
                                                            {notifi ? this.getComment(notifi) : ""}
                                                            {["partner", "manager"].indexOf(this.props.me.role_id.role_name) > -1 ?
                                                                (this.parseLocationIdentifier(notifi) && <span className="mb-0">
                                                                    {" at "}
                                                                    <strong >{this.parseLocationIdentifier(notifi)}</strong>

                                                                </span>)
                                                                :
                                                                null}
                                                        </p>

                                                        <div className="d-flex align-item-center">
                                                            <span>{moment(notifi.created_at).fromNow()}</span>
                                                            {this.hasNotificationSidePopup(notifi.activity_type) ?
                                                                <div >
                                                                    <button onClick={(e) => this.openNotificationSidePopup(e, notifi, index)} className={"primary-font position-relative top-minus-1 btn pl-5 pr-0 border-0 text-primary fz-12 btn-xs"}>
                                                                        {/* <i className={`btn-icon ${Style["zoom-icon"]}`}>
                                                                            <Icons name="zoom" />
                                                                        </i> */}
                                                                        View Details
                                                                    </button>
                                                                </div>
                                                                : null}
                                                        </div>
                                                    </div>
                                                    {notifi.is_new ?
                                                        <div className={`position-absolute d-flex align-items-center ${Style["b-read-icon"]}`}>
                                                            <Tooltip position="left" message="Mark as read">
                                                                <div onClick={(e) => this.readNotifi(e, notifi._id, notifi, index)} >
                                                                    <span className={`${Style["signal"]}`}></span>
                                                                </div>
                                                            </Tooltip>
                                                        </div>
                                                        : null}
                                                </div>
                                            </div> : null}
                                    </Fragment>
                                )) : null}
                            </InfiniteScroll>
                        </div>
                        :
                        <div className={`text-center ${Style["no-notification-list"]}`}>
                            <div className="d-inline-block mb-20">
                                <Icons className={`${Style["icon-block"]}`} name="bell" />
                            </div>
                            <div className={`${Style["text-block"]}`}>No notification to show</div>
                        </div>
                }
                {this.state.showNotificationSidePopup ?
                    <div className={` ${Style["notification-side-popup-wrapper"]}`}>
                        <div className={`border rounded bg-white ${Style["b-notification-side-popup"]}`}>
                            <div className="position-relative">
                                <button type="button" onClick={this.hideSidePopup.bind(this)} className={`close remove-modal-close custom-modal-close-button position-absolute ${Style["btn-notification-side-popup-close"]}`}>×</button>
                            </div>
                            <header className="p-15">
                                <h2 className={` ${Style["title-text"]}`}>{this.state.notificationModalTitle}</h2>
                            </header>
                            {this.state.notificationModalTitle && (this.state.notificationModalTitle !== "New Review request" && this.state.notificationModalTitle !== "WEBSITE ANALYTICS" && this.state.notificationModalTitle !== "Google Sync Failed") ?
                                <div className="p-10 text-center border border-top-0 border-right-0 border-left-0 mb-30">
                                    <i className={`mb-5 d-block mx-auto ${Style["b-icon-visit"]}`}><Icons name="visit" /></i>
                                    <span className={`mb-5 ${Style["notification-duration"]}`}>{this.state.notifTime ? moment(this.state.notifTime).fromNow() : ""}</span>
                                </div>
                                : null}
                            {this.state.popuploading ?
                                <div className="p-25">
                                    <img className="image-loader-dot" src="https://dufzo4epsnvlh.cloudfront.net/image/loader-dot.svg" alt="" />
                                </div>
                                : null}
                            {!this.state.reviewRequestData.length && !this.state.popuploading ?
                                <div className="px-15">
                                    {this.state.notificationModalTitle === "WEBSITE ANALYTICS" ?
                                        <h6>
                                            {this.state.wbAnalyticsData.sitedata.name} - Performance on web for {this.state.wbAnalyticsData.start_date} - {this.state.wbAnalyticsData.end_date}
                                        </h6> :
                                        null}
                                    <ul className={`p-0 ${Style["b-list"]}`}>
                                        {this.state.appointmentData && this.state.appointmentData.service_id && this.state.appointmentData.service_id.title ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Service :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.service_id.title}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.first_name ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>First Name :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.first_name}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.last_name ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Last Name :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.last_name}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.customer_email ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Email :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.customer_email}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.customer_phone ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Phone Number :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.customer_phone}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.appointment_date && this.state.formattedDate !== "Invalid Date" ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Date :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.formattedDate} {this.state.appointmentData.appointment_start_date}{this.state.appointmentData.appointment_time}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.customer_message ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Message :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.customer_message}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.website_id ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Website :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.website_id.domain || this.state.appointmentData.website_id.permalinkUrl}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.appointmentData && this.state.appointmentData.status ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Status :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.appointmentData.status}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.contactImportData && (this.state.contactImportData.succesful || (this.state.contactImportData.succesful === 0)) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Added contact(s) :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.contactImportData.succesful}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {!this.state.contactImportData.error && (this.state.contactImportData.duplicate || (this.state.contactImportData.duplicate === 0)) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Duplicate(s) found :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.contactImportData.duplicate}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {!this.state.contactImportData.error && (this.state.contactImportData.invalid || (this.state.contactImportData.invalid === 0)) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Invalid data(s) :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.contactImportData.invalid}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.contactImportData.total_contacts ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Total Contact(s) :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.contactImportData.total_contacts}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.contactImportData.error ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Reason(s) :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.contactImportData.error}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.userImportData && this.state.userImportData.completed ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Successfull import(s) :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.userImportData.completed}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.userImportData && this.state.userImportData.failed ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Failed import(s) :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.userImportData.failed}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.userImportData && this.state.userImportData.skip_data && this.state.userImportData.skip_data.length ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Reason(s) :</div>
                                                    {this.state.userImportData.skip_data.map((skip, index) => (
                                                        <div key={index} className={`d-inline-block ${Style["list-description"]}`}>{skip.resason}</div>
                                                    ))
                                                    }
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.subscriptionData && this.state.subscriptionData.email ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Email :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.subscriptionData.email}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.wbAnalyticsData && (this.state.wbAnalyticsData.pageviews || this.state.wbAnalyticsData.pageviews === 0) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Page views :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.wbAnalyticsData.pageviews}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.wbAnalyticsData && (this.state.wbAnalyticsData.bounces || this.state.wbAnalyticsData.bounces === 0) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Visitors :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.wbAnalyticsData.bounces}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.wbAnalyticsData && (this.state.wbAnalyticsData.sessions || this.state.wbAnalyticsData.sessions === 0) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Sessions :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.wbAnalyticsData.sessions}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.wbAnalyticsData && (this.state.wbAnalyticsData.returnuser || this.state.wbAnalyticsData.returnuser === 0) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Repeated visitors :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.wbAnalyticsData.returnuser}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.privateReviewData && this.state.privateReviewData.customer_feedback ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Message :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.privateReviewData.customer_feedback}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.privateReviewData && this.state.privateReviewData.customer_fn ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Name :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.privateReviewData.customer_fn} {this.state.privateReviewData.customer_ln}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.privateReviewData && this.state.privateReviewData.created_at ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Date :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.privateReviewData.created_at}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.privateReviewData && this.state.privateReviewData.customer_phone ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Phone :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.privateReviewData.customer_phone}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.subscriptionlimitData && this.state.subscriptionlimitData.website_limit ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Old limit :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.subscriptionlimitData.website_limit.old}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.subscriptionlimitData && this.state.subscriptionlimitData.website_limit ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>New limit :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.subscriptionlimitData.website_limit.new}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.subscriptionlimitData && this.state.subscriptionlimitData.contact_limit ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Old limit :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.subscriptionlimitData.contact_limit.old}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.subscriptionlimitData && this.state.subscriptionlimitData.contact_limit ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>New limit :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.subscriptionlimitData.contact_limit.new}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.gmbSuccessData && this.state.gmbSuccessData.location_id ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Location :</div>
                                                    <div>
                                                        <div className={` ${Style["list-description"]}`}>{this.state.gmbSuccessData.location_id.locationName}</div>
                                                    </div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.gmbFailData && (this.state.gmbFailData.error_details || this.state.gmbFailData.error_message) ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Reason(s) :</div>
                                                    <div>
                                                        {this.state.gmbFailData.error_details && this.state.gmbFailData.error_details.length && this.state.gmbFailData.error_details.map((err, index) => (
                                                            <div key={index} className={` ${Style["list-description"]}`}>{err}</div>
                                                        ))
                                                        }
                                                    </div>
                                                    {!this.state.gmbFailData.error_details ?
                                                        <div className={` ${Style["list-description"]}`}>{this.state.gmbFailData.error_message}</div> : null

                                                    }
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.yextSuccessData && this.state.yextSuccessData.error_details ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Reason(s) :</div>
                                                    <div>
                                                        {this.state.yextSuccessData.error_details && this.state.yextSuccessData.error_details.length && this.state.yextSuccessData.error_details.map((err, index) => (
                                                            <div key={index} className={` ${Style["list-description"]}`}>{err}</div>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            </li> : null}

                                        {this.state.yextFailData && this.state.yextFailData.error_details ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Reason(s) :</div>
                                                    <div>
                                                        {this.state.yextFailData.error_details && this.state.yextFailData.error_details.length && this.state.yextFailData.error_details.map((err, index) => (
                                                            <div key={index} className={` ${Style["list-description"]}`}>{err}</div>
                                                        ))
                                                        }
                                                    </div>
                                                </div>
                                            </li> : null}

                                        {this.state.hubspotData && this.state.hubspotData.email ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Email :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.hubspotData.email}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.hubspotData && this.state.hubspotData.phone ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Phone :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.hubspotData.phone}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.hubspotData && this.state.hubspotData.location_name ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Business Name  :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.hubspotData.location_name}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.hubspotData && this.state.hubspotData.industry_type ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Category  :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.hubspotData.industry_type}</div>
                                                </div>
                                            </li>
                                            : null}
                                        {this.state.hubspotData && this.state.hubspotData.service_area ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Service Area :</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>{this.state.hubspotData.service_area}</div>
                                                </div>
                                            </li>
                                            : null}


                                    </ul>
                                    {this.state.notificationModalTitle === "New Appointment" ?
                                        <div>
                                            <button className="btn" onClick={this.gotoAppointmentDetails.bind(this, this.state.appointmentData._id)}>Manage
                                            </button>
                                        </div>
                                        : null}
                                </div>
                                : null}
                            {this.state.reviewRequestData && this.state.reviewRequestData.length ?
                                <div className="table-responsive-lg no-table-below-md pt-10 pr-20 pb-10 pl-10">
                                    <table className="table rounded shadow">
                                        <thead className="thead-bg">
                                            <tr>
                                                <th>Email Address</th>
                                                <th>Time Sent </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.reviewRequestData.map((data, index) => (
                                                <tr key={index} >
                                                    <td>{data.recipient_email}</td>
                                                    <td>{moment.utc(data.sent_at).format(config.date_time_format)}</td>
                                                </tr>
                                            ))}

                                        </tbody>
                                    </table>
                                </div> : null}
                            {this.state.customFormData ?
                                <div className="px-15">
                                    <ul className={`p-0 ${Style["b-list"]}`}>
                                        {this.state.customFormData ?
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>Website:</div>
                                                    <div className={`d-inline-block ${Style["list-description"]}`}>
                                                        {this.state.customFormWb}
                                                    </div>
                                                </div>
                                            </li>
                                            : null}
                                        {Object.keys(this.state.customFormData).map(field =>
                                            <li className={`${Style["list-item"]}`}>
                                                <div className="d-block">
                                                    <div className={`d-inline-block mr-5 ${Style["list-title"]}`}>{field}:</div>
                                                    {Array.isArray(this.state.customFormData[field]) ?
                                                        <div className={`d-inline-block ${Style["list-description"]}`}>
                                                            {this.getCustomFormText(this.state.customFormData[field],field)}
                                                        </div>:
                                                        <div className={`d-inline-block ${Style["list-description"]}`}>
                                                            {field === "Phone" || field === "phone" || field === "Mobile" || field === "mobile" ? numberMask(this.state.customFormData[field]) : this.state.customFormData[field]}
                                                        </div>
                                                    }
                                                </div>
                                            </li>)}

                                    </ul>
                                </div>
                                : null}
                        </div>
                    </div>
                    : null}
            </div>
        )
    }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Notification);