// props
// 
// submitBtnId : id for submit button
// cancelBtnId : id for cancel button
// okText : ok button text
// cancelText : cancel button text
// title : modal title


import React from "react"
import Modal from "react-bootstrap4-modal";

class ConfirmPopup extends React.Component {
    handleSubmit(event) {
        event.preventDefault()
        this.props.onOk()
    }
    handleCancel() {
        this.props.onCancel()
    }
    modalBackdropClicked() {
        this.props.modalBackdropClicked()
    }
    render() {
        const { open, title } = this.props;
        return (
            <Modal
                id={this.props.id}
                visible={open}
                onClickBackdrop={this.modalBackdropClicked.bind(this)}
                dialogClassName={"modal-custom-sm remove-modal"}
            >
                <div className="modal-header border-0 position-relative pb-0">
                    <h5 className="modal-title">{title || "Confirmation"}</h5>
                    <button type="button" className="close custom-modal-close-button" onClick={this.handleCancel.bind(this)}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div className="modal-body pt-5 pb-15">
                    {this.props.children ? this.props.children : null}
                </div> 

                <div className="modal-footer border-0 px-15 pb-15 pt-0 px-sm-30 pb-sm-30 d-block d-sm-flex modal-custom-footer">  
                    <button id={this.props.okID} type="button" className="btn btn-xl border-0 btn-danger mx-0 mb-10 mb-sm-0 mr-sm-20 btn-block" onClick={this.handleSubmit.bind(this)}>
                        {this.props.okText || "Ok"}
                    </button>
                    <button id={this.props.cancelID} type="button" className={`btn btn-xl btn-outline mx-0 mb-10 mb-sm-0 custom-btn-width ${this.props.cancelText === "NO" ? "custom-btn-width-sm" : ""}`} onClick={this.handleCancel.bind(this)}>
                        {this.props.cancelText || "Cancel"}
                    </button>
                </div>
            </Modal>
        )
    }
}
export default ConfirmPopup
