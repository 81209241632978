import React from 'react'
import { ReactivateRoute } from '@app/router/access-route'
import asyncComponent from '@helpers/async-func'

export default [
  <ReactivateRoute
    exact
    path="/:userId/reactivate-subscription"
    key="reactivate-subscription"
    layoutSettings={{ hideSideBar: true }}
    component={asyncComponent(() => import('./index'))}
  />
]
