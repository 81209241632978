import * as React from 'react'
// routing
import { RestrictedRoute } from '@app/router/access-route'
// pages
import { Checkout } from './checkout'

export const routes = [
  <RestrictedRoute
    exact
    path="/:userId/checkout"
    key="checkout"
    layoutSettings={{ page: true, hideLayout: true }}
    component={() => <Checkout />}
  />
]
