import React from 'react'
import asyncComponent from '@helpers/async-func'
import { EnterpriseRoute } from '@app/router/access-route'

export default [
  <EnterpriseRoute
    exact
    key="/enterprise/services"
    path={'/:userId/enterprise/services'}
    layoutSettings={{ page: 'services', title: 'Services' }}
    component={asyncComponent(() => import('./services'))}
  />,
  <EnterpriseRoute
    exact
    key="/enterprise/appointments"
    path={'/:userId/enterprise/appointments'}
    layoutSettings={{ page: 'booking', title: 'Booking' }}
    component={asyncComponent(() => import('./booking/list'))}
  />,
  <EnterpriseRoute
    exact
    key="/enterprise/appointments/appointment-details/"
    path={'/:userId/enterprise/appointments/appointment-details/:id'}
    layoutSettings={{ page: 'booking', title: 'Booking' }}
    component={asyncComponent(() => import('./booking/details'))}
  />
]
