import React, { useEffect, useRef } from 'react'
import { isEnabled, isDisabled, checkPermissionWOproduct } from '@helpers/utility'
import { useTranslation } from 'react-i18next'

export function GlobalEnterpriseModal({
  showMore,
  isContactCreatePermitted,
  isBookingPermitted,
  isMessengerSendPermitted,
  isContactEditPermitted,
  showAddContactPopup,
  sendMessagePopup,
  isReviewDeletePermitted,
  addBookingPopup,
  showReviewRequestPopup,
  showLess,
  closeModal,
  products,
  user,
  disabledProducts,
  openAddServices,
  multiReviewWidget,
  redirectToAddLocation,
  redirectToAddManager,
  importGoogleContacts,
  importOutlookContacts,
  createMultiMessengerWidget,
  redirectToConnectGoogleCalendar,
  redirectToOutlookCalendar,
  sendBookingLink,
  widgetSetting,
  me,
  setupAutoResponse,
  redirectToCreateBookingWidget,
  redirectToSetupNotifications
}) {
  // Create a ref that we add to the element for which we want to detect outside clicks
  const ref = useRef()
  useOnClickOutside(ref, closeModal)
  const { t } = useTranslation()

  const enableAddContact = Boolean(
    isContactCreatePermitted &&
      ((products && isEnabled('contact-manager', products)) ||
        (!isEnabled('contact-manager', products) && !isDisabled('contact-manager', disabledProducts)))
  )

  const enableContactHub = Boolean(
    (products && isEnabled('contact-manager', products)) ||
      (!isEnabled('contact-manager', products) &&
        !isDisabled('contact-manager', disabledProducts) &&
        user.role &&
        (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner))
  )

  const enableBooking = Boolean(
    isBookingPermitted &&
      ((products && isEnabled('booking', products)) ||
        (!isEnabled('booking', products) &&
          !isDisabled('booking', disabledProducts) &&
          user.role &&
          (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner)))
  )

  const enableSendReviewRequest = Boolean(isReviewDeletePermitted)

  const enableSendReviewLink = Boolean(isReviewDeletePermitted)

  // messenger
  const enableSendMessage = Boolean(
    isMessengerSendPermitted &&
      ((products && isEnabled('messenger', products)) ||
        (!isEnabled('messenger', products) &&
          !isDisabled('messenger', disabledProducts) &&
          user.role &&
          (user.role === 'customer' || user.role === 'trial' || user.isSMBPartner)))
  )

  return (
    <div
      ref={ref}
      className={`global-modal-root ${showMore ? 'global-modal-root-expanded' : 'global-modal-root-collapsed'}`}>
      <div className="global-modal">
        <div className="most-popular">
          <span className="popular-heading">{t('common.most-popular')}</span>
          {enableAddContact && (
            <span className="feature-link" onClick={showAddContactPopup}>
              {t('common.add-contact')}
            </span>
          )}
          {isBookingPermitted && enableBooking && (
            <span className="feature-link" onClick={addBookingPopup}>
              {t('services.add-booking')}
            </span>
          )}
          {/** Hidden till EVE-422 is resolved */}
          {/* {enableSendMessage && (
            <span className="feature-link" onClick={sendMessagePopup}>
              Send Message
            </span>
          )} */}
          {enableSendReviewLink && (
            <span className="feature-link" onClick={showReviewRequestPopup}>
              {' '}
              {t('common.send-review-link')}{' '}
            </span>
          )}
        </div>

        {showMore && (
          <div className="products-list">
            <div>
              <div className={'feature-section'}>
                {enableContactHub && (
                  <div>
                    <span className="feature-title">{t('common.contact-hub')}</span>
                    {isContactCreatePermitted && (
                      <span className="feature-link" onClick={showAddContactPopup}>
                        {t('common.add-contact')}
                      </span>
                    )}
                    {/* <span className="feature-link" onClick={importGoogleContacts}>
                        Sync Google Contacts{' '}
                    </span>
                    <span className="feature-link" onClick={importOutlookContacts}>
                        Sync OutLook Contacts
                    </span> */}
                  </div>
                )}
              </div>
            </div>
            <div>
              <div className={'feature-section'}>
                {enableSendMessage && (
                  <div>
                    <span className="feature-title">Messenger</span>
                    {/** Hidden till EVE-422 is resolved */}
                    {/* {isContactEditPermitted && (
                      <div>
                        <span className="feature-link" onClick={sendMessagePopup}>
                          Send Message
                        </span>
                      </div>
                    )} */}
                    <>
                      {' '}
                      {isEnabled('messenger', products) && checkPermissionWOproduct('Admin.Messenger.Manage', me) ? (
                        <span className="feature-link" onClick={setupAutoResponse}>
                          {t('common.setup-auto-response')}
                        </span>
                      ) : null}
                      {/* <span className="feature-link" onClick={syncFacebookMessenger}>
                          Sync Facebook Messenger
                        </span>
\                        <span className="feature-link" onClick={syncThumbtackMessages}>
                        Sync Thumbtack Messages
                        </span> */}
                    </>
                  </div>
                )}
              </div>
              {enableBooking && (
                <div className={'feature-section'}>
                  <span className="feature-title">{t('settings.booking')}</span>
                  <span className="feature-link" onClick={addBookingPopup}>
                    {t('bookings.add-booking-0')}
                  </span>
                  <span className="feature-link" onClick={redirectToConnectGoogleCalendar}>
                    {t('common.connect-google-calendar')}
                  </span>
                  <>
                    <span className="feature-link" onClick={redirectToOutlookCalendar}>
                      {t('common.connect-outlook-calendar')}
                    </span>
                    {/* <span className="feature-link" onClick={sendBookingLink}>
                      Send Booking Link
                    </span> */}
                  </>
                </div>
              )}
            </div>
            <div>
              {enableSendReviewRequest && (
                <div className={'feature-section'}>
                  <span className="feature-title">{t('settings.reviews')}</span>
                  <span className="feature-link" onClick={showReviewRequestPopup}>
                    {' '}
                    {t('reviews.send-review-request')}{' '}
                  </span>
                </div>
              )}
            </div>
            <div>
              {
                <div className={'feature-section'}>
                  <span className="feature-title">{t('reviews.widgets')}</span>
                  {/** Hidden till CORE-2006 is resolved */}
                  {/* <span className="feature-link" onClick={redirectToCreateBookingWidget}>
                    Create Booking Widget
                  </span> */}
                  <span className="feature-link" onClick={multiReviewWidget}>
                    {' '}
                    {t('services.create-multi-review-widget')}{' '}
                  </span>
                  <span className="feature-link" onClick={createMultiMessengerWidget}>
                    {' '}
                    {t('services.create-multi-messenger-widget')}{' '}
                  </span>
                </div>
              }
              {
                <div className={'feature-section'}>
                  <span className="feature-title">{t('services.setting')}</span>
                  <span className="feature-link" onClick={openAddServices}>
                    {' '}
                    {t('common.add-service')}{' '}
                  </span>
                  <span className="feature-link" onClick={redirectToAddLocation}>
                    {' '}
                    {t('services.add-location')}{' '}
                  </span>
                  <span className="feature-link" onClick={redirectToAddManager}>
                    {' '}
                    {t('services.add-manager')}{' '}
                  </span>
                  <span className="feature-link" onClick={widgetSetting}>
                    {' '}
                    {t('services.widget-settings')}{' '}
                  </span>
                  <span className="feature-link" onClick={redirectToSetupNotifications}>
                    {t('common.setup-notifications')}
                  </span>
                </div>
              }
            </div>
          </div>
        )}
      </div>

      <div className={'show-less-more'}>
        <span className={'show-less-more-button'} onClick={showLess}>
          {showMore ? t('common.show-less') : t('common.show-more')}
        </span>
      </div>
    </div>
  )
}

// Hook
function useOnClickOutside(ref, handler) {
  useEffect(
    () => {
      const listener = (event) => {
        // Do nothing if clicking ref's element or descendent elements
        if (!ref.current || ref.current.contains(event.target)) {
          return
        }
        handler(event)
      }
      document.addEventListener('mousedown', listener)
      document.addEventListener('touchstart', listener)
      return () => {
        document.removeEventListener('mousedown', listener)
        document.removeEventListener('touchstart', listener)
      }
    },
    // Add ref and handler to effect dependencies
    // It's worth noting that because passed in handler is a new ...
    // ... function on every render that will cause this effect ...
    // ... callback/cleanup to run every render. It's not a big deal ...
    // ... but to optimize you can wrap handler in useCallback before ...
    // ... passing it into this hook.
    [ref, handler]
  )
}
