import React from 'react'
import Header from '../header'
import Footer from '../footer'
import SideBar from '../enterpriseSidebar'
import { connect } from 'react-redux'
import { compose } from 'recompose'
import Loader from '../loader'
import { setFavicon, setHeader } from '@helpers/index'
import { connectWS } from '../websocket'
import { isEnabled, setCookies, getCookie } from '@helpers/utility'
import { segmentIdentify } from '@helpers/segment'
import ResetPasswordModal from '@containers/home/utility/resetPasswordPopUp'
import { featureFlags, getFeatureVal } from '@helpers/configcat'

const mapDispatchToProps = ({ auth, enterpriseMessenger, home }) => {
  return {
    ...auth,
    ...enterpriseMessenger,
    ...home
  }
}

const mapStateToProps = ({ auth, enterpriseMessenger, home }) => {
  return {
    ...auth,
    ...enterpriseMessenger,
    ...home
  }
}
export class Layout extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      menuOpen: true,
      page: '',
      showResetPassword: false,
      showUpgradeTrial: false
    }
  }

  async getNotificationCount () {
    const payload = { role: this.props.me.role_id.role_name }
    if (this.props.me.role_id.role_name === 'partner') {
      const activity = ['new_contact_fail', 'google_sync_failed', 'google_sync_success', 'user_import_success', 'user_import_failed']
      payload.activity = activity
    } else if (['admin', 'superadmin'].indexOf(this.props.me.role_id.role_name)) {
      const activity = ['new_contact_fail', 'google_sync_failed', 'google_sync_success', 'user_import_success', 'user_import_failed', 'mid_missing', 'cardconnect_settings_updated', 'cardconnect_settings_update_failed', 'scrape_process_complete', 'twilio_number_request', 'hubspot_crm_status']
      payload.activity = activity
    }
    await this.props.getNotificationCount(payload)
  }

  async componentDidMount () {
    if (!this.props.me && this.props.user) {
      await this.props.getMe()
    }

    const asyncMount = async () => {
      let innerMe = this.props.me
      if (!this.props.me) innerMe = await this.props.getMe()
      if (innerMe && innerMe.isMultiLocation) {
        const flagStatus = await getFeatureVal(featureFlags.upgradeToProMultiLocation, innerMe)
        let subScribed = false
        const { chargeBeeData } = innerMe.chargebeeData || await this.props.getChargebeeSubscription()

        if (chargeBeeData) {
          if ((chargeBeeData.chargebee_account_id && chargeBeeData.chargebee_subscription_id) || chargeBeeData.chargebee_not_required) {
            subScribed = true
          }
        }
        this.setState({
          showUpgradeTrial:
                flagStatus && !subScribed && innerMe && !innerMe.offline_payment
        })
      }
    }
    this.init()
    asyncMount()
  }

  async init () {
    if (!this.props.domainSettings) { await this.props.validateDomain() }

    setFavicon(this.props.domainSettings)
    setHeader(this.props.domainSettings, ` ${this.props.settings.siteTitle ? this.props.settings.siteTitle : this.props.settings.title}`)
    if (!this.props.me && this.props.user) {
      await this.props.getMe()
      // if(this.props.me && this.props.me.company_settings && this.props.me.company_settings.new_update)
      //     this.brandingModal.showModal()
    }

    segmentIdentify(this.props.me, this.props.user ? this.props.user.role : '')

    if (this.props.me && this.props.me && this.props.me.status && this.props.me.status === 'new') { this.setState({ showResetPassword: true }) }

    if (this.props.me && this.props.me._id) {
      this.getNotificationCount()
    }
    if (!this.props.enterpriselocations) {
      await this.props.getEnterpriseLocations()
    }
    // Initialize websocket
    if (this.props.me && this.props.me._id) {
      if (this.props.products &&
                isEnabled('messenger', this.props.products) && ['partner', 'manager'].indexOf(this.props.me.role_id.role_name) > -1) {
        if (!this.props.ws || localStorage.getItem('wsDisconnected') === 'true') {
          if (localStorage.getItem('wsDisconnected') === 'true') { localStorage.removeItem('wsDisconnected') }
          await connectWS(this.props.me).then((ws) => this.subscribeChannel(ws))
        } else {
          this.props.ws.addEventListener('message', (e) => this.updateMessageCount(e))
        }
      }
    }
  }
  async subscribeChannel (ws) {
    await this.props.updateWebsocket(ws)
    const data = {
      channelId: this.props.me._id,
      globalId: this.props.me._id,
      userId: this.props.me._id
    }
    const subchannel = {
      'action': 'subscribeChannel',
      'data': data
    }
    const subchannel2 = JSON.stringify(subchannel)
    ws.send(subchannel2)

    var globalIds = []
    if (this.props.enterpriselocations && this.props.enterpriselocations.length) {
      this.props.enterpriselocations.forEach(loc => {
        if (loc.products.indexOf('messenger') > -1) {
          globalIds.push(loc.customer)
        }
      })
    }
    var history = {
      'action': 'listMultipleLocationsGH',
      'data': { 'globalIds': globalIds }
    }
    var history1 = JSON.stringify(history)
    ws.send(history1)

    ws.addEventListener('message', (e) => this.updateMessageCount(e))
  }
  updateMessageCount (e) {
    var payload = JSON.parse(e.data)
    if (payload.type === 'awsPushNotification') {
      var result = payload.data
      if (result.meta.type === 'messenger' && !result.meta.data) {
        if (!window.location.href.includes('/messenger')) {
          if (result.sender_id && result.sender_id !== this.props.me._id) {
            this.props && this.props.updateMessageLayoutCount(true)
          } else if (!result.sender_id) {
            this.props && this.props.updateMessageLayoutCount(true)
          }
        }
      }
    } else if (payload.type === 'listMultipleLocationsGH') {
      const unreadMessages = this.unreadCount(payload.data.Items)
      if (unreadMessages && unreadMessages.length && !window.location.href.includes('/messenger')) {
        this.props.updateMessageLayoutCount(true)
      }
      this.props && this.props.updateMessagelistGlobalHistory(payload.data)
    } else if (payload.type === 'typingStarted' || payload.type === 'typingEnd') {
      this.props && this.props.updateTypingStatus(payload)
    } else if (payload.type === 'liveUsers') {
      this.props && this.props.handleWSPayloadSuccess(payload)
    }
  }
  unreadCount (m) {
    var self = this
    return m && m.length ? m.filter(function (message) {
      if (!self.props.me.messenger_view_time) {
        return true
      }
      return message.createdAt > self.props.me.messenger_view_time
    }) : null
  }
  toggleMenu () {
    this.setState(prevState => { return { menuOpen: !prevState.menuOpen } })
  }

  closePasswordPopUp () {
    this.setState({ showResetPassword: false })
  }
  async handleResetPassword (val) {
    await this.setState({ passwordResetloader: true })
    await this.props.resetPassword(val)
    const cookie = await getCookie()
    cookie.status = 'active'
    await setCookies(cookie)
    this.props.getMe()
    await this.setState({ passwordResetloader: false })
    this.setState({ showResetPassword: false })
  }

  render () {
    const { children, settings } = this.props
    return (
      <div className={`app-body overlay-loader-outer ${this.state.menuOpen ? '' : 'menu-off'}`} >
        {/* <BrandingUpdateModal
                    ref={ref => this.brandingModal = ref}
                /> */}
        { this.state.showResetPassword
          ? <ResetPasswordModal open = {this.state.showResetPassword}
            handleSubmitFunction ={(val) => this.handleResetPassword(val)}
            closeModal = {() => this.closePasswordPopUp()}
            role={this.props.user && this.props.user.role}
            loading={this.state.passwordResetloader}
          />
          : null
        }
        {
          !settings.hideHeader && this.props.me && this.props.me._id && this.props.customers
            ? <Header
              showUpgradeTrial={this.state.showUpgradeTrial}
              page={settings.page}
              title={settings.title}
              getNotificationCount={this.getNotificationCount}
              onMinimise={this.toggleMenu.bind(this)}
            />
            : null
        }
        {!settings.hideHeader
          ? <React.Fragment>
            {this.props.me && this.props.me._id && this.props.customers
              ? <div className= {`${this.state.showUpgradeTrial
                ? 'main-wrapper enterprises-wrapper-show-upgrade-trial'
                : 'main-wrapper enterprises-wrapper'
              } w-100 d-flex position-relative`}>
                <React.Fragment>
                  <SideBar
                    showUpgradeTrial={this.state.showUpgradeTrial}
                    className="master-sidebar"
                    page={settings.page} />
                  <main className="main mw-100 flex-grow-1">
                    {children}
                  </main>
                </React.Fragment>
              </div>
              : <div className="full-page-loader">
                <Loader />
              </div>
            }
          </React.Fragment>
          : null}

        {
          (!settings.hideFooter && this.props.me && this.props.me._id && this.props.customers)
            ? <Footer />
            : null}
      </div>
    )
  }
}
export default compose(connect(mapStateToProps, mapDispatchToProps))(Layout)
