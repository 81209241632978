import React from 'react'
import asyncComponent from '@helpers/async-func'
import { RestrictedRoute, PublicRoute } from '@app/router/access-route'

export default [
  <RestrictedRoute
    exact
    key="/transactions"
    path={'/:userId/transactions'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./payment-list/payment-list'))}
  />,
  <RestrictedRoute
    exact
    key="/transactions/widgets"
    path={'/:userId/transactions/widgets'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./widget-list/widget-list'))}
  />,
  <RestrictedRoute
    exact
    key="/transactions/settings"
    path={'/:userId/transactions/settings'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./settings/settings'))}
  />,
  <RestrictedRoute
    exact
    key="/transactions/details"
    path={'/:userId/transaction-details/:transactionId'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./paymentDetails/paymentDetails'))}
  />,
  <RestrictedRoute
    exact
    key="/payment-preview"
    path={'/:userId/trial/activate-transactions'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./preview'))}
  />,
  <PublicRoute
    exact
    key="/payment-preview"
    path={'/payment-widget'}
    component={asyncComponent(() => import('./payment-widget/payment-widget'))}
  />,
  <RestrictedRoute
    exact
    key="/transactions"
    path={'/:userId/trial/transactions'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./payment-list/payment-list'))}
  />,
  <RestrictedRoute
    exact
    key="/transactions/details"
    path={'/:userId/trial/transaction-details/:transactionId'}
    layoutSettings={{ page: 'payment' }}
    component={asyncComponent(() => import('./paymentDetails/paymentDetails'))}
  />,
  <RestrictedRoute
    exact
    key="/invoices"
    path={'/:userId/invoices'}
    layoutSettings={{ page: 'invoice' }}
    component={asyncComponent(() => import('./payment-list/invoice-list'))}
  />,
  <RestrictedRoute
    exact
    key="/invoices"
    path={'/:userId/trial/invoices'}
    layoutSettings={{ page: 'invoice' }}
    component={asyncComponent(() => import('./payment-list/invoice-list'))}
  />,
  <RestrictedRoute
    exact
    key="/invoices/details"
    path={'/:userId/trial/invoice-details/:transactionId'}
    layoutSettings={{ page: 'invoice' }}
    component={asyncComponent(() => import('./paymentDetails/paymentDetails'))}
  />,
  <RestrictedRoute
    exact
    key="/invoices/details"
    path={'/:userId/invoice-details/:transactionId'}
    layoutSettings={{ page: 'invoice' }}
    component={asyncComponent(() => import('./paymentDetails/paymentDetails'))}
  />
]
