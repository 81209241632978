import { api, catchHandler } from '@helpers/axios'
import config from '@config/index'
import { objToUrlParams } from '@helpers/index'

export function getActivityList (payload) {
  return api()
    .get(
      `${config.routes.activities}?activity=all&start=${payload.start}&limit=${payload.limit}&activity_type=${
        payload.activity_type
      }${payload.start_date ? `${`&start_date=${payload.start_date}`}` : ''}${
        payload.end_date ? `${`&end_date=${payload.end_date}`}` : ''
      }`
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchBussiness (payload) {
  return api()
    .get(`${config.routes.fetchBussiness}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function resetPassword (payload) {
  return api()
    .post(config.routes.resetPassword, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function skipPasswordModal (payload) {
  return api()
    .get(config.routes.skipPassword)
    .then((res) => res.data)
    .catch(catchHandler)
}
