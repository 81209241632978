import React, { PureComponent, Fragment } from 'react'
import Popup from '@components/popups/basic-modal'
import style from '../index.module.scss'
import { isEnabled } from '@helpers/utility'
import Icon from '@components/icons'
import { withTranslation } from 'react-i18next'

class QuickbookConfirm extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      sync_qb_contacts: false,
      sync_transaction_to_qb: true,
      sync_invoice_to_qb: false
    }
  }
  closeModal() {
    this.props.closeModal()
  }
  handleCancel(values, { resetForm }) {
    this.props.closeModal()
  }
  async handelCheckbox(item) {
    await this.setState({ [item]: !this.state[item] })
  }
  handleSubmit() {
    this.props.handleSubmit(this.state)
  }
  componentDidMount() {
    if (
      this.props.products &&
      isEnabled('quickbooks-transactions', this.props.products) &&
      this.props.me &&
      this.props.me.account_settings &&
      this.props.me.account_settings.payment &&
      this.props.me.account_settings.payment.merchant_id &&
      this.props.me.role_id &&
      (this.props.me.role_id.role_name === 'customer' ||
        this.props.me.role_id.role_name === 'trial' ||
        this.props.me.role_id.role_name === 'staff')
    ) {
      this.setState({ sync_transaction_to_qb: true })
    }
    if (this.props.products && isEnabled('quickbooks-contact-hub', this.props.products)) {
      this.setState({ sync_qb_contacts: true })
    }
    if (this.props.products && isEnabled('quickbooks-invoices', this.props.products)) {
      this.setState({ sync_invoice_to_qb: true })
    }

    if (this.props.screen) this.setState({ screen: this.props.screen })
    if (this.props.ftuUser)
      this.setState({ sync_transaction_to_qb: true, sync_qb_contacts: true, sync_invoice_to_qb: true })
  }
  render() {
    const t = this.props.t
    return (
      <Fragment>
        <Popup
          open={this.props.open}
          title={t('payments.connect-to-quickbooks')}
          onCancel={() => this.closeModal.bind(this)}
          type="small"
          modalBackdropClicked={this.closeModal.bind(this)}
          id="modalQuickbookConnection">
          <button
            onClick={this.closeModal.bind(this)}
            type="reset"
            className="close custom-modal-close-button remove-modal-close">
            <span aria-hidden="true">×</span>
          </button>
          <div className="pt-10">
            <p className="mb-25 fz-16">{t('payments.gosite-would-like-to-access-and-write-your-quickbooks-data-to')}</p>
            {(this.props.products && isEnabled('quickbooks-contact-hub', this.props.products)) || this.props.ftuUser ? (
              <div className="d-flex align-items-start mb-20">
                <div className={'form-group mb-0 form-check'}>
                  <label className="d-flex align-items-start">
                    <input
                      className=""
                      type="checkbox"
                      checked={this.state.sync_qb_contacts}
                      onChange={() => this.handelCheckbox('sync_qb_contacts')}
                    />
                    <span className="checkbox-icon"></span>
                    <div className="ml-20">
                      <h3 className="mb-10 fz-16 secondary-font-bold">{t('payments.sync-contacts-to-gosite')}</h3>
                      <p className="mb-0 fz-14 text-secondary">
                        {t('payments.pulls-your-quickbooks-customer-list-every-time-you-visit-gosite')}
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            ) : null}
            {(this.props.products &&
              isEnabled('quickbooks-transactions', this.props.products) &&
              this.props.me &&
              this.props.me.account_settings &&
              this.props.me.account_settings.payment &&
              this.props.me.account_settings.payment.merchant_id &&
              this.props.me.role_id) ||
            /* RU-1212: Removed the role check so that the option is visible for users with role partner as well. */
            this.props.ftuUser ? (
              <div className="d-flex align-items-start mb-20">
                <div className={'form-group mb-0 form-check'}>
                  <label className="d-flex align-items-start">
                    <input
                      className=""
                      type="checkbox"
                      checked={this.state.sync_transaction_to_qb}
                      onChange={() => this.handelCheckbox('sync_transaction_to_qb')}
                    />
                    <span className="checkbox-icon"></span>
                    <div className="ml-20">
                      <h3 className="mb-10 fz-16 secondary-font-bold">
                        {t('payments.sync-transactions-to-quickbooks')}
                      </h3>
                      <p className="mb-0 fz-14 text-secondary">
                        {t('payments.pushes-gosite-transactions-to-quickbooks-every-time-you-visit-gosite')}
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            ) : null}
            {(this.props.products && isEnabled('quickbooks-invoices', this.props.products)) || this.props.ftuUser ? (
              <div className="d-flex align-items-start mb-20">
                <div className={'form-group mb-0 form-check'}>
                  <label className="d-flex align-items-start">
                    <input
                      className=""
                      type="checkbox"
                      checked={this.state.sync_invoice_to_qb}
                      onChange={() => this.handelCheckbox('sync_invoice_to_qb')}
                    />
                    <span className="checkbox-icon"></span>
                    <div className="ml-20">
                      <h3 className="mb-5 fz-16 secondary-font-bold  position-relative top-minus-2">
                        {t('payments.sync-invoices-to-quickbooks')}
                      </h3>
                      <p className="mb-0 fz-14 text-secondary">
                        {t('payments.pushes-gosite-invoices-to-quickbooks-every-time-you-visit-gosite')}
                      </p>
                    </div>
                  </label>
                </div>
              </div>
            ) : null}

            <p className="mb-25">
              {t('payments.learn-more-about-our-integration-with-quickbooks-in-our')}{' '}
              <a
                href="https://help.gosite.com/en/articles/4580231-importing-contacts-from-quickbooks"
                target="_blank"
                rel="noopener noreferrer">
                {t('payments.knowledge-base')}
              </a>
            </p>
          </div>
          <footer className="d-block d-sm-flex">
            {this.props.ftuUser ? (
              <button
                className={'btn btn-primary border-0 btn-sm mb-10 mb-sm-0 mr-20 flex-fill'}
                id="connectQuickbookBtn"
                onClick={this.handleSubmit.bind(this)}
                disabled={
                  !this.state.sync_qb_contacts && !this.state.sync_transaction_to_qb && !this.state.sync_invoice_to_qb
                }
                type="submit">
                {t('payments.sync-data')}
                {this.props.qbSyncDataLoading && (
                  <i className="btn-icon top-minus-2 ml-10">
                    <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
                  </i>
                )}
              </button>
            ) : (
              <Icon
                id="connectQuickbookBtn"
                name="connectToQuickbooks"
                style={{ width: '223px' }}
                onClick={this.handleSubmit.bind(this)}
                className="mr-15"
              />
            )}
            {this.props.qbSyncDataLoading && (
              <i className="btn-icon top-minus-2 ml-10">
                <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
              </i>
            )}
            <button
              type="reset"
              id="connectQuickbookCancelBtn"
              onClick={this.closeModal.bind(this)}
              className={'btn btn-sm btn-outline m-0 flex-fill ' + style['cancel-button']}>
              {this.props.ftuUser ? t('payments.skip-for-now') : t('payments.cancel-0')}
            </button>
          </footer>
        </Popup>
      </Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(QuickbookConfirm)
