import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { WisetackPromotionalMessage } from '@app/components/wisetack/WisetackPromotionalMessage'
import { FinancingPromoMessagingResponse } from './types'
import { ThemeProvider, theme } from '@gositeinc/ui'
import { useFeatureVal } from '@app/helpers/hooks'
import { featureFlags } from '@app/helpers/configcat'
import { WISETACK_RAW_STATUS } from '@app/helpers/wisetack'

type WisetackPromotionalMessageContainerProps = { amountInCents: number; isSingleLineLayout?: boolean }

export const WisetackPromotionalMessageContainer = ({
  amountInCents,
  isSingleLineLayout = false
}: WisetackPromotionalMessageContainerProps) => {
  const dispatch = useDispatch()
  const isFeatureEnabled = useFeatureVal(featureFlags.wisetack)

  const financingPromoMessaging = useSelector(
    (state: { wisetack: { financingPromoMessagingResponse: FinancingPromoMessagingResponse } }) =>
      state.wisetack.financingPromoMessagingResponse.data
  )

  const wisetackSignupStatus = useSelector<{ wisetack: { signUpStatus: string } }, string>(
    (state) => state.wisetack.signUpStatus
  )

  useEffect(() => {
    dispatch.wisetack.getSignUpStatus()
    dispatch.wisetack.getFinancingPromoMessaging({
      amountInCents
    })
  }, [amountInCents, dispatch])

  if (wisetackSignupStatus !== WISETACK_RAW_STATUS.APPLICATION_APPROVED || !isFeatureEnabled) {
    return <></>
  }

  const monthlyAmount =
    financingPromoMessaging && financingPromoMessaging.asLowAs
      ? Number(financingPromoMessaging.asLowAs.monthlyPayment)
      : null

  const apr =
    financingPromoMessaging && financingPromoMessaging.asLowAs && financingPromoMessaging.asLowAs.apr
      ? Number(financingPromoMessaging.asLowAs.apr)
      : null

  const termLength =
    financingPromoMessaging && financingPromoMessaging.asLowAs && financingPromoMessaging.asLowAs.termLength
      ? Number(financingPromoMessaging.asLowAs.termLength)
      : null
  const totalAmount = amountInCents / 100

  return monthlyAmount && apr && termLength ? (
    <ThemeProvider theme={theme}>
      <WisetackPromotionalMessage
        isSingleLineLayout={isSingleLineLayout}
        monthlyAmount={monthlyAmount}
        apr={apr}
        termLength={termLength}
        totalAmount={totalAmount}
      />
    </ThemeProvider>
  ) : null
}
