import * as service from './service'
import { createModel } from '@rematch/core'
import notification from '@components/notification'
import axios from 'axios'

export const estimates = createModel()({
  state: {
    getEstimateResponse: {
      loading: false,
      error: undefined,
      estimate: undefined
    },
    getEstimatesResponse: {
      loading: false,
      error: undefined,
      estimates: [],
      total: 0
    },
    getEstimateHistoriesResponse: {
      loading: false,
      error: undefined,
      histories: []
    },
    postEstimateResponse: {
      loading: false,
      error: undefined
    },
    deleteEstimateResponse: {
      loading: false,
      error: undefined
    },
    patchEstimateResponse: {
      loading: false,
      error: undefined
    },
    renewEstimateResponse: {
      loading: false,
      error: undefined
    },
    getEstimateMetricsResponse: {
      loading: false,
      error: undefined,
      metrics: {
        totalEstimateSent: 0,
        acceptedLastWeek: 0,
        acceptedLastMonth: 0
      }
    },
    uploadingAttachments: [],
    uploadedAttachments: []
  },
  reducers: {
    onBeforeGetEstimateHistories(state) {
      return {
        ...state,
        getEstimateHistoriesResponse: {
          histories: [],
          error: undefined,
          loading: true
        }
      }
    },
    onGetEstimateHistoriesError(state, error) {
      return {
        ...state,
        getEstimateHistoriesResponse: {
          histories: [],
          error: error,
          loading: false
        }
      }
    },
    onGetEstimateHistoriesSuccess(state, histories) {
      return {
        ...state,
        getEstimateHistoriesResponse: {
          histories: histories,
          error: undefined,
          loading: false
        }
      }
    },
    onBeforeGetEstimates(state) {
      return {
        ...state,
        getEstimatesResponse: {
          estimates: [],
          error: undefined,
          loading: true
        }
      }
    },
    onGetEstimatesError(state, error) {
      return {
        ...state,
        getEstimatesResponse: {
          estimates: [],
          error: error,
          loading: false
        }
      }
    },
    onGetEstimatesSuccess(state, { total, estimates }) {
      return {
        ...state,
        getEstimatesResponse: {
          total,
          estimates,
          error: undefined,
          loading: false
        }
      }
    },
    onAfterEstimateAttachmentUpload(state, { key, originalname, size }) {
      return {
        ...state,
        uploadingAttachments: state.uploadingAttachments.filter((attachment) => attachment.fileName !== originalname),
        uploadedAttachments: [
          ...state.uploadedAttachments,
          {
            s3Info: {
              key: key
            },
            name: originalname,
            size: size,
            uploadedAt: Date.now()
          }
        ]
      }
    },
    onBeforeGetEstimate(state) {
      return {
        ...state,
        getEstimateResponse: {
          estimate: undefined,
          error: undefined,
          loading: true
        }
      }
    },
    onGetEstimateError(state, error) {
      return {
        ...state,
        getEstimatesResponse: {
          estimate: undefined,
          error: error,
          loading: false
        }
      }
    },
    onGetEstimateSuccess(state, estimate) {
      return {
        ...state,
        getEstimateResponse: {
          estimate,
          error: undefined,
          loading: false
        }
      }
    },
    onBeforePostEstimate(state) {
      return {
        ...state,
        postEstimateResponse: {
          error: undefined,
          loading: true
        }
      }
    },
    onPostEstimateError(state, error) {
      notification('error', 'Failed to create estimate')
      return {
        ...state,
        postEstimateResponse: {
          error: error,
          loading: false
        }
      }
    },
    onPostEstimateSuccess(state) {
      notification('success', 'Estimate successfully sent')
      return {
        ...state,
        postEstimateResponse: {
          error: undefined,
          loading: false
        }
      }
    },
    onBeforeDeleteEstimate(state) {
      return {
        ...state,
        deleteEstimateResponse: {
          error: undefined,
          loading: true
        }
      }
    },
    onDeleteEstimateError(state, error) {
      notification('error', 'Failed to delete estimate')
      return {
        ...state,
        deleteEstimateResponse: {
          error: error,
          loading: false
        }
      }
    },
    onDeleteEstimateSuccess(state) {
      notification('success', 'Estimate successfully deleted')
      return {
        ...state,
        deleteEstimateResponse: {
          error: undefined,
          loading: false
        }
      }
    },
    onBeforePatchEstimate(state) {
      return {
        ...state,
        patchEstimateResponse: {
          error: undefined,
          loading: true
        }
      }
    },
    onPatchEstimateError(state, error) {
      notification('error', 'Failed to update estimate')
      return {
        ...state,
        patchEstimateResponse: {
          error: error,
          loading: false
        }
      }
    },
    onPatchEstimateSuccess(state) {
      notification('success', 'Estimate successfully updated')
      return {
        ...state,
        patchEstimateResponse: {
          error: undefined,
          loading: false
        }
      }
    },
    onBeforeRenewEstimate(state) {
      return {
        ...state,
        renewEstimateResponse: {
          error: undefined,
          loading: true
        }
      }
    },
    onRenewEstimateError(state, error) {
      notification('error', 'Failed to renew estimate')
      return {
        ...state,
        renewEstimateResponse: {
          error: error,
          loading: false
        }
      }
    },
    onRenewEstimateSuccess(state) {
      notification('success', 'Estimate successfully renewed')
      return {
        ...state,
        renewEstimateResponse: {
          error: undefined,
          loading: false
        }
      }
    },
    onBeforeGetEstimateMetrics(state) {
      return {
        ...state,
        getEstimateMetricsResponse: {
          error: undefined,
          loading: true,
          metrics: {
            totalEstimateSent: 0,
            acceptedLastWeek: 0,
            acceptedLastMonth: 0
          }
        }
      }
    },
    onGetEstimateMetricsError(state, error) {
      return {
        ...state,
        getEstimateMetricsResponse: {
          error: error,
          loading: false,
          metrics: {
            totalEstimateSent: 0,
            acceptedLastWeek: 0,
            acceptedLastMonth: 0
          }
        }
      }
    },
    onGetEstimateMetricsSuccess(state, metrics) {
      return {
        ...state,
        getEstimateMetricsResponse: {
          error: undefined,
          loading: false,
          metrics
        }
      }
    },
    onBeforeUploadingEstimateAttachments(state, { files }) {
      return {
        ...state,
        uploadingAttachments: files
      }
    },
    onEstimateAttachmentUploadProgress(state, { file, progress }) {
      console.log('onEstimateAttachmentUploadProgress', { file, progress })

      const copyOfUploadingAttachements = [...state.uploadingAttachments]
      const index = copyOfUploadingAttachements.findIndex((f) => f.name === file.name)

      if (index < 0) {
        return {
          ...state
        }
      }

      const fileToBeUpdated = copyOfUploadingAttachements[index]
      fileToBeUpdated.progress = progress
      if (progress === 100) {
        copyOfUploadingAttachements.splice(index, 1)
      }

      return {
        ...state,
        uploadingAttachments: copyOfUploadingAttachements
      }
    },
    onClearUploadingEstimateAttachments(state) {
      return {
        ...state,
        uploadingAttachments: []
      }
    },
    onClearUploadedEstimateAttachments(state) {
      return {
        ...state,
        uploadedAttachments: []
      }
    },
    onCancelUploadingEstimateAttachment(state, fileNameToCancel) {
      const fileToCancel = state.uploadingAttachments.find((file) => file.name === fileNameToCancel)
      fileToCancel.cancelToken.cancel(fileNameToCancel + ' upload cancelled')
      return {
        ...state,
        uploadingAttachments: state.uploadingAttachments.filter((file) => file.name !== fileNameToCancel)
      }
    },
    onRemoveUploadedEstimateAttachment(state, fileNameToRemove) {
      state.uploadedAttachments.find((file) => file.name === fileNameToRemove)
      return {
        ...state,
        uploadedAttachments: state.uploadedAttachments.filter((file) => file.name !== fileNameToRemove)
      }
    },
    onSetUploadedEstimateAttachments(state, uploadedAttachments) {
      return {
        ...state,
        uploadedAttachments: uploadedAttachments
      }
    }
  },
  effects: (dispatch) => ({
    async getEstimate(estimateId, rootState) {
      dispatch.estimates.onBeforeGetEstimate()
      try {
        const estimate = await service.getEstimate(estimateId)
        dispatch.estimates.onGetEstimateSuccess(estimate)
      } catch (e) {
        dispatch.estimates.onGetEstimateError(e)
      }
    },
    async getEstimateAttachment(payload, rootState) {
      try {
        const attachment = await service.getEstimateAttachment(payload.estimateId, payload.s3Key, payload.fileName)
        notification('success', 'Successfully downloaded attachment')
        return attachment
      } catch (e) {
        notification('error', 'Failed to download attachment')
      }
    },
    async getEstimates({ query }, rootState) {
      dispatch.estimates.onBeforeGetEstimates()
      try {
        const { estimates, total } = await service.getEstimates(query)
        dispatch.estimates.onGetEstimatesSuccess({ estimates, total })
      } catch (e) {
        dispatch.estimates.onGetEstimatesError(e)
      }
    },
    async getEstimateHistories({ estimateId, query }, rootState) {
      dispatch.estimates.onBeforeGetEstimateHistories()
      try {
        // const { estimates, total } = await service.getEstimates(query)
        const histories = await service.getEstimateHistories({ estimateId, query })
        dispatch.estimates.onGetEstimateHistoriesSuccess(histories)
      } catch (e) {
        dispatch.estimates.onGetEstimateHistoriesError(e)
      }
    },
    async postEstimate({ contact, sendTime, expiredAt, sendVia, items, message, attachments }) {
      dispatch.estimates.onBeforePostEstimate()
      try {
        const res = await service.postEstimate({ contact, sendTime, expiredAt, sendVia, items, message, attachments })
        dispatch.estimates.onPostEstimateSuccess()
        return res
      } catch (e) {
        dispatch.estimates.onPostEstimateError(e)
      }
    },
    async uploadEstimateAttachments({ files }) {
      const filesWithCancelToken = files.map((file) => {
        file.progress = 0
        file.cancelToken = axios.CancelToken.source()
        return file
      })

      dispatch.estimates.onBeforeUploadingEstimateAttachments({ files: filesWithCancelToken })

      for (const file of filesWithCancelToken) {
        if (file.size <= 5 * 1024 * 1024) {
          const axiosConfig = {
            cancelToken: file.cancelToken.token,
            onUploadProgress: (event) => {
              const progress = Math.round((event.loaded * 100) / event.total)
              dispatch.estimates.onEstimateAttachmentUploadProgress({ file, progress })
            }
          }
          const res = await service.uploadEstimateAttachments({ file, axiosConfig })
          if (res) {
            dispatch.estimates.onAfterEstimateAttachmentUpload(res.files[0])
          }
        }
      }
    },
    async patchEstimate({ estimateId, body: { contact, sendTime, expiredAt, sendVia, items, message, attachments } }) {
      dispatch.estimates.onBeforePatchEstimate()
      try {
        console.log(estimateId, { contact, sendTime, expiredAt, sendVia, items, message, attachments })
        const res = await service.patchEstimate(estimateId, {
          contact,
          sendTime,
          expiredAt,
          sendVia,
          items,
          message,
          attachments
        })
        dispatch.estimates.onPatchEstimateSuccess()
        return res
      } catch (e) {
        dispatch.estimates.onPatchEstimateError(e)
      }
    },
    async renewEstimate({ estimateId, body: { contact, sendTime, expiredAt, sendVia, items, message, attachments } }) {
      dispatch.estimates.onBeforeRenewEstimate()
      try {
        console.log(estimateId, { contact, sendTime, expiredAt, sendVia, items, message, attachments })
        const res = await service.renewEstimate(estimateId, {
          contact,
          sendTime,
          expiredAt,
          sendVia,
          items,
          message,
          attachments
        })
        dispatch.estimates.onRenewEstimateSuccess()
        return res
      } catch (e) {
        dispatch.estimates.onRenewEstimateError(e)
      }
    },
    async deleteEstimate({ estimateId }) {
      dispatch.estimates.onBeforeDeleteEstimate()
      try {
        const res = await service.deleteEstimate({ estimateId })
        dispatch.estimates.onDeleteEstimateSuccess()
        return res
      } catch (e) {
        dispatch.estimates.onDeleteEstimateError(e)
      }
    },
    async getEstimateMetrics() {
      dispatch.estimates.onBeforeGetEstimateMetrics()
      try {
        const { totalEstimateSent, acceptedLastWeek, acceptedLastMonth } = await service.getEstimateMetrics()
        dispatch.estimates.onGetEstimateMetricsSuccess({
          totalEstimateSent,
          acceptedLastWeek,
          acceptedLastMonth
        })
        return {
          totalEstimateSent,
          acceptedLastWeek,
          acceptedLastMonth
        }
      } catch (e) {
        dispatch.estimates.onGetEstimateMetricsError(e)
      }
    }
  })
})
