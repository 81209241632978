import React from 'react'
import Popup from '@components/popups/basic-modal'
import style from '../team/index.module.scss'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { capitalizeunderscore, checkPermissionWOproduct, getAdminOrPartnerCookie } from '@helpers/utility'
import * as Yup from 'yup'
import Icon from '@components/icons'

const adminUser = getAdminOrPartnerCookie()
export default class createTeamModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      stepValue: 1,
      userEmail: '',
      displayName: '',
      firstName: '',
      lastName: '',
      message: '',
      qev: false,
      switchPermisson: false,
      isNew: false
    }
  }
  showPopup (item) {
    this.setState({ open: true, item: item })
  }
  closeModal () {
    this.props.close()
  }
  async handleSubmitAddNewUser (values) {
    this.setState({ statusSpinner: true })
    if (this.props.type === 'new') {
      await this.props.handleSubmitAddNewUser(values)
      if (!this.props.apiError) {
        this.setState({ stepValue: 2, switchPermisson: true, isNew: true })
      }
    } else if (this.props.type === 'edit') {
      await this.updateStaffUser(values)
    }
    this.setState({ statusSpinner: false })
  }
  async updateStaffUser (values) {
    this.setState({ statusSpinner: true })
    const payload = { _id: this.props.userData && this.props.userData._id }
    payload.data = {
      ...this.props.userData,
      user_email: values.userEmail,
      display_name: values.displayName,
      first_name: values.firstName,
      last_name: values.lastName,
      qev: values.qev
    }
    await this.props.updateStaffUser(payload)
    this.setState({ statusSpinner: false })
    this.props.close()
  }
  async handleUpdateUserPermission () {
    this.setState({ statusSpinner: true })

    const payload = { user_id: this.props.userData && this.props.userData._id }

    const selectArray = []
    if (this.state.permissions) {
      const permissions = [...this.state.permissions]
      permissions.forEach((item) => {
        item.permissions.forEach((innerItem) => {
          if (innerItem.isSelected) {
            selectArray.push(innerItem._id)
          }
        })
      })
    }
    payload.permissions = selectArray
    await this.props.userRolePermissions(payload)
    this.setState({ statusSpinner: false })
    if (this.state.isNew) {
      this.setState({ stepValue: 3 })
    } else {
      this.props.close()
    }
  }
  async sendMessage () {
    if (this.state.message.length) {
      this.setState({ statusSpinner: true })
      const payload = { _id: this.props.userData._id, data: { message: this.state.message } }
      await this.props.sendWelcomeMessage(payload)
      this.setState({ statusSpinner: false })
      this.props.close()
    }
  }
  setStepTo (step) {
    if (this.state.switchPermisson) {
      this.setState({ stepValue: step })
    }
  }
  componentDidMount () {
    if (this.props.userData) {
      this.setState({
        userEmail: this.props.userData.user_email ? this.props.userData.user_email : '',
        displayName: this.props.userData.display_name ? this.props.userData.display_name : '',
        firstName: this.props.userData.first_name ? this.props.userData.first_name : '',
        lastName: this.props.userData.last_name ? this.props.userData.last_name : '',
        qev: this.props.userData.qev ? this.props.userData.qev : false,
        type: 'edit',
        switchPermisson: true
      })
    }
    if (this.props.permissions) {
      this.setState({ permissions: this.props.permissions })
    }
  }
  componentDidUpdate (prevProps) {
    if (JSON.stringify(prevProps.permissions) !== JSON.stringify(this.props.permissions)) {
      this.setState({ permissions: this.props.permissions })
    }
    if (JSON.stringify(prevProps.userData) !== JSON.stringify(this.props.userData)) {
      this.setState({
        userEmail: this.props.userData.user_email ? this.props.userData.user_email : '',
        displayName: this.props.userData.display_name ? this.props.userData.display_name : '',
        firstName: this.props.userData.first_name ? this.props.userData.first_name : '',
        lastName: this.props.userData.last_name ? this.props.userData.last_name : '',
        qev: this.props.userData.qev ? this.props.userData.qev : false,
        type: 'edit',
        switchPermisson: true
      })
    }
  }

  getPermissonIcons (value) {
    const product = value.toLowerCase()

    if (product === 'booking') {
      return 'coloredBooking'
    } else if (product === 'review monitor') {
      return 'reviewMonitorActive'
    } else if (product === 'review request') {
      return 'reviewRequestActive'
    } else if (product === 'gmb') {
      return 'googleStrokeDark'
    } else if (product === 'sites') {
      return 'site'
    } else if (product === 'contacts') {
      return 'contact'
    } else if (product === 'messenger') {
      return 'messengerActive'
    } else if (product === 'engagement') {
      return 'engagementActive'
    } else if (product === 'payment') {
      return 'paymentsActivePrimary'
    } else if (product === 'placement') {
      return 'placement'
    } else if (product === 'posts') {
      return 'postsActive'
    } else if (
      product === 'quickbooks contact-hub' ||
      product === 'quickbooks transactions' ||
      product === 'quickbooks invoices-beta'
    ) {
      return 'contactQuickook'
    } else {
      return 'contactQuickook'
    }
  }
  getProductName (name) {
    let productName = ''
    if (name === 'sites') productName = 'Site'
    else if (name === 'gmb') productName = 'Google'
    else if (name === 'quickbooks-contact-hub') productName = 'QuickBooks Contact Hub'
    else if (name === 'quickbooks-invoices') productName = 'QuickBooks Invoices'
    else if (name === 'quickbooks-transactions') productName = 'QuickBooks Transactions'
    else productName = capitalizeunderscore(name)

    return productName
  }
  handleCheckChange (input, value) {
    console.log(input, value)
    const permissions = [...this.state.permissions]

    permissions.forEach((item) => {
      if (item.product === input) {
        item.permissions.forEach((innerItem) => {
          if (value) innerItem.isSelected = true
          else innerItem.isSelected = false
        })
      }
    })

    this.setState({ permissions: permissions })
  }

  isChecked (input) {
    var count = 0
    var length = 0
    const permissions = [...this.state.permissions]
    permissions.forEach((item) => {
      if (item.product === input) {
        length = item.permissions.length
        item.permissions.forEach((innerItem) => {
          if (innerItem.isSelected === true) {
            count++
          }
        })
      }
    })
    if (count === length) {
      return true
    } else {
      return false
    }
  }

  render () {
    console.log('this.props.type', this.props.type)
    const { stepValue, userEmail, displayName, firstName, lastName, switchPermisson, qev } = this.state
    const { teamUserCalendar } = this.props
    return (
      <Popup
        className="px-30"
        hideHeader={true}
        open={this.props.open}
        type="wide"
        modalBackdropClicked={this.closeModal.bind(this)}>
        <header className={`p-15 p-md-30 position-relative ${style['b-popup-header']}`}>
          <button onClick={this.closeModal.bind(this)} className={`close ${style['custom-modal-close']}`}>
            <span aria-hidden="true">×</span>
          </button>
          <ul className={`pl-0 pt-20 d-flex justify-content-center ${style['b-progress']}`}>
            <li
              onClick={this.setStepTo.bind(this, 1)}
              className={`pt-30 position-relative ${style['progress-item']} ${stepValue === 1 ? style['active'] : ''}`}>
              Profile Info
            </li>
            <li
              onClick={this.setStepTo.bind(this, 2)}
              className={`px-5 px-sm-20 position-relative pt-30 ${style['progress-item']} ${stepValue === 2 ? style['active'] : ''} ${!switchPermisson ? style['disable-step'] : ''}  `}>
              Permissions
            </li>
            {this.state.isNew ? (
              <li
                onClick={this.setStepTo.bind(this, 3)}
                className={`pt-30 position-relative ${style['progress-item']} ${stepValue === 3 ? style['active'] : ''} ${!switchPermisson ? style['disable-step'] : ''}   `}>
                Message
              </li>
            ) : null}
            {!this.state.isNew ? (
              <li
                onClick={this.setStepTo.bind(this, 3)}
                className={`pt-30 position-relative ${style['progress-item']} ${stepValue === 3 ? style['active'] : ''} ${!switchPermisson ? style['disable-step'] : ''}   `}>
                Calendar
              </li>
            ) : null}
          </ul>
        </header>
        {stepValue === 1 ? (
          <Formik
            enableReinitialize
            initialValues={{
              firstName: firstName,
              lastName: lastName,
              userEmail: userEmail,
              displayName: displayName,
              qev: qev
            }}
            validationSchema={Yup.object().shape({
              firstName: Yup.string().required('Required'),
              userEmail: Yup.string().email('Invalid email').required('Required')
            })}
            onSubmit={this.handleSubmitAddNewUser.bind(this)}
            validateOnChange={false}
            validateOnBlur={false}
            render={({ values, setFieldValue }) => {
              return (
                <Form>
                  <div className="row gutter-16">
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label className="label-text">
                          FIRST NAME
                          <i className="star text-danger">&nbsp;*</i>
                          <ErrorMessage
                            id="transWidgetTitleError"
                            name="firstName"
                            component="span"
                            className="form-error"
                          />
                        </label>
                        <Field
                          className="form-control form-control-md"
                          type="text"
                          name="firstName"
                          placeholder="Enter First Name"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label className="label-text">LAST NAME</label>
                        <Field
                          className="form-control form-control-md"
                          type="text"
                          name="lastName"
                          placeholder="Enter Last Name"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label className="label-text">
                          CONTACT EMAIL
                          <i className="star text-danger">&nbsp;*</i>
                          <ErrorMessage
                            id="transWidgetTitleError"
                            name="userEmail"
                            component="span"
                            className="form-error"
                          />
                        </label>
                        <Field
                          className="form-control form-control-md"
                          type="text"
                          name="userEmail"
                          placeholder="Enter Contact Email"
                        />
                      </div>
                    </div>
                    <div className="col-12 col-sm-6">
                      <div className="form-group">
                        <label className="label-text">DISPLAY NAME</label>
                        <Field
                          className="form-control form-control-md"
                          type="text"
                          name="displayName"
                          placeholder="Enter Display Name"
                        />
                      </div>
                    </div>
                    {(this.props.me && checkPermissionWOproduct('Manage.Whitelist.Email', this.props.me)) ||
                      (adminUser && adminUser.length && adminUser[0] && adminUser[0].whiteListPermission)
                      ? (<div className="col-12 col-sm-6">
                        <div className="form-group form-check">
                          <label>
                            <input
                              type="checkbox"
                              checked={values.qev}
                              name="qev"
                              onChange={(event) => {
                                setFieldValue('qev', event.target.checked)
                              }}
                            />
                            <span id="checkBoxDoorDeliveryGeneralInfo" className="checkbox-icon"></span>
                          </label>
                          <label className="pl-10">Bypass Email Validation</label>
                        </div>
                      </div>) : null}
                  </div>

                  <footer className="d-block d-sm-flex mt-20">
                    <button
                      disabled={this.state.statusSpinner}
                      type="submit"
                      className="btn btn-xl btn-primary text-white border-0 flex-grow-1 btn-block d-block d-sm-inline-block mb-20 mb-sm-0 mr-20">
                      {this.props.type === 'edit' ? 'UPDATE USER INFO' : 'CREATE USER'}
                      {this.state.statusSpinner ? (
                        <i className="btn-icon top-minus-2">
                          <img
                            className="spinner"
                            src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                            alt=""
                          />
                        </i>
                      ) : null}
                    </button>
                    <button className="btn btn-xl btn-outline btn-width-full-xs" onClick={this.closeModal.bind(this)}>
                      CANCEL
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        ) : null}
        {this.state.stepValue === 2 ? (
          <div className={`${style['b-permission']}`}>
            <header className="mb-30">
              <h3 className="primary-font-bold">Select product permissions</h3>
            </header>
            <div className="row gutter-16">
              {this.state.permissions &&
                this.state.permissions.map((item, index) => (
                  <div key={index} className="col-12 col-sm-6 px-md-10 mb-10">
                    <label className={`border rounded px-15 d-flex align-items-center ${style['product-list-item']}`}>
                      <input
                        className={`${style['product-list-item-field']}`}
                        type="checkbox"
                        checked={this.isChecked(item.product)}
                        onChange={(e) => this.handleCheckChange(item.product, e.target.checked)}
                      />
                      <i className={`btn-icon top-minus-2 mr-20 ${style['product-icon']}`}>
                        <Icon name={this.getPermissonIcons(capitalizeunderscore(item.product_slug))} />
                      </i>
                      <strong className={`${style['list-text']}`}>{this.getProductName(item.product_slug)}</strong>
                      <i className="btn-icon top-minus-2 ml-auto">
                        {this.isChecked(item.product) ? <Icon name="greyTick"></Icon> : <Icon name="fillPlus"></Icon>}
                      </i>
                    </label>
                  </div>
                ))}
            </div>
            <footer className="d-block d-sm-flex mt-20">
              <button
                disabled={this.state.statusSpinner}
                onClick={this.handleUpdateUserPermission.bind(this)}
                className="btn btn-xl btn-primary text-white border-0 flex-grow-1 btn-block d-block d-sm-inline-block mb-20 mb-sm-0 mr-20">
                UPDATE USER INFO
                {this.state.statusSpinner ? (
                  <i className="btn-icon top-minus-2">
                    <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
                  </i>
                ) : null}
              </button>
              <button className="btn btn-xl btn-outline btn-width-full-xs" onClick={this.closeModal.bind(this)}>
                CANCEL
              </button>
            </footer>
          </div>
        ) : null}

        {this.state.stepValue === 3 && this.state.isNew ? (
          <div className="mb-10">
            <h3 className="mb-30 primary-font-bold">Welcome Message</h3>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <textarea
                    className="form-control form-control-lg"
                    name="message"
                    placeholder="Write a welcome message..."
                    onChange={(e) => this.setState({ message: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <footer className="d-block d-sm-flex mt-10">
              <button
                disabled={this.state.statusSpinner}
                type="submit"
                onClick={this.sendMessage.bind(this)}
                className="btn btn-lg btn-primary text-white border-0 flex-grow-1 btn-block d-block d-sm-inline-block mb-20 mb-sm-0 mr-20">
                SEND MESSAGE
                {this.state.statusSpinner ? (
                  <i className="btn-icon top-minus-2">
                    <img className="spinner" src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg" alt="" />
                  </i>
                ) : null}
              </button>
              <button
                className="btn btn-lg flex-grow-0 cancel-btn d-block d-sm-inline-block"
                onClick={this.closeModal.bind(this)}>
                CANCEL
              </button>
            </footer>
          </div>
        ) : null}
        {this.state.stepValue === 3 && !this.state.isNew ? (
          <div className="mb-10">
            {teamUserCalendar && teamUserCalendar.calendar_type === 'google' ? (
              <div id="sectionConnectAccount" className="text-center mb-20">
                <div className="d-inline-block">
                  <i className={'btn-icon top-minus-2 mr-5 '}>
                    <img src="https://dufzo4epsnvlh.cloudfront.net/image/google-plus.png" alt="" />
                  </i>
                  Google Calendar Synced
                  <button
                    id="btnGoolgeUnsync"
                    className={'btn btn-outline-danger ml-15'}
                    onClick={() => this.props.openUnsyncPopup('google')}>
                    UNSYNC
                  </button>
                </div>
              </div>
            ) : null}
            {teamUserCalendar && teamUserCalendar.calendar_type === 'outlook' ? (
              <div id="sectionConnectAccount" className="text-center mb-20">
                <div className="d-inline-block">
                  <i className={`btn-icon top-minus-2 mr-10 ${style['icon-GMB-sync']}`}>
                    <img src="https://dufzo4epsnvlh.cloudfront.net/image/microsoft-icon.svg" alt="" />
                  </i>
                  Outlook Calendar Synced
                  <button
                    id="btnOutlookCalendarUnsync"
                    className={'btn btn-outline-danger ml-15'}
                    onClick={() => this.props.openUnsyncPopup('outlook')}>
                    UNSYNC
                  </button>
                </div>
              </div>
            ) : null}
            {!teamUserCalendar ||
              (teamUserCalendar &&
                teamUserCalendar.calendar_type !== 'outlook' &&
                teamUserCalendar.calendar_type !== 'google')
              ? (<div className="justify-content-center d-flex">
                <h5 className="mt-10 mb-20 fz-14">No calendars connected</h5>
              </div>) : null}
          </div>
        ) : null}
      </Popup>
    )
  }
}
