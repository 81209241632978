import * as service from './service'
import notification from '@components/notification'
import { capitalize } from '@helpers/index'
import { getMessengerNotes, deleteMessengerNote, createNote } from '../messenger/service'
import i18n from '@/i18n'

export default {
  state: {
    notes: [],
    apiError: null
  },
  reducers: {
    onRequest(state) {
      return {
        ...state,
        loading: true
      }
    },
    onNoteRequest(state) {
      return {
        ...state,
        Notesloading: true
      }
    },
    onError(state, data) {
      let err =
        data && data.message
          ? data.message
          : data && data.data && data.data.message
          ? data.data.message
          : data && data.error
          ? data.error
          : data && data.data && data.data.error
          ? data.data.error
          : i18n.t('common.please-try-again-later')
      if (typeof err !== 'string') err = i18n.t('common.unable-to-import-contacts')
      notification('error', capitalize(err))
      return {
        ...state,
        loading: false,
        apiError: err
      }
    },
    onSuccess(state, data) {
      return {
        ...state,
        loading: false
      }
    },
    onSuccessWithMessage(state, data, message) {
      notification('success', message)
      return {
        ...state,
        loading: false,
        apiError: null
      }
    },
    onFetchContactSuccess(state, data) {
      return {
        ...state,
        loading: false,
        contacts: data
      }
    },
    onFetchContactDetailsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        contactDetails: data.data
      }
    },
    createGroupsSuccess(state, data) {
      notification('success', i18n.t('common.group-created-successfully'))
      return {
        ...state,
        loading: false,
        createdGroup: data.data,
        apiError: null
      }
    },
    onFetchGroupsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        groups: data
      }
    },
    onFetchGroupsWithoutPagingSuccess(state, data) {
      return {
        ...state,
        allGroups: data
      }
    },
    onRemoveFromGroupSuccess(state, data, message) {
      notification('success', message)
      return {
        ...state,
        loading: false,
        apiError: null
      }
    },
    hasFilterApplied(state, data) {
      let contactSearch = false
      if (data.group_id !== undefined || data.source !== undefined) {
        contactSearch = true
      }
      return {
        ...state,
        loading: false,
        filter: data,
        contactSearch
      }
    },
    onFetchContactForGroupSuccess(state, data) {
      return {
        ...state,
        contacts: data.data
      }
    },
    onGetAnalyticsSuccess(state, data) {
      return {
        ...state,
        contactAnalytics: data.data
      }
    },
    onGetNotesSuccess(state, data) {
      return {
        ...state,
        Notesloading: false,
        notes: data && data.data
      }
    },
    onAddNoteSuccess(state, data) {
      let notes = []
      const newNote = data && data.data
      notes = [...state.notes, newNote]
      return {
        ...state,
        Notesloading: false,
        notes: notes
      }
    },
    onDeleteNoteSuccess(state, data) {
      notification('success', data.message ? capitalize(data.message) : '')
      const deletedNote = data && data.data
      let notes = []
      notes = state.notes.filter((item) => {
        return item._id !== deletedNote._id
      })
      return {
        ...state,
        notes: notes
      }
    },
    onPinNotesSuccess(state, data) {
      return {
        ...state,
        Notesloading: false
      }
    },
    onGetActivitiesSuccess(state, data) {
      return {
        ...state,
        loading: false,
        activites: data
      }
    },
    onGetActivitySuccess(state, data) {
      return {
        ...state,
        loading: false,
        activity: data
      }
    },
    onGetActivityList(state, data) {
      let delCount = 0
      let activitiesTotl = 0
      for (let i = data.data.docs.length - 1; i >= 0; i--) {
        if (data.data.docs[i].meta_data && data.data.docs[i].meta_data.succesful === 0) {
          data.data.docs[i].delstatus = 'deleted'
          delCount++
        }
      }
      for (let i = data.data.docs.length - 1; i >= 0; i--) {
        if (!data.data.docs[i].status || (data.data.docs[i].status && data.data.docs[i].status !== 'canceled')) {
          if (data.data.docs[i].display_message) activitiesTotl++
        }
      }
      let array = data && data.data.docs && data.data.docs.length ? data.data.docs : []
      if (state.activityList && state.activityList.docs && state.activityList.docs.length) {
        array = [...state.activityList.docs, ...array]
      }
      data.data.docs = array
      console.log('array===>', array.length)
      return {
        ...state,
        loading: false,
        activityList: data.data,
        activitiesTotl: activitiesTotl,
        delCount: delCount
      }
    },
    onGetActivitiesCount(state, data) {
      return {
        ...state,
        loading: false,
        activityCount: data.data
      }
    },
    onfetchBlockedContactsSuccess(state, data) {
      return {
        ...state,
        loading: false,
        blockedContacts: data
      }
    },
    onblockNumberSuccess(state, data) {
      notification('success', i18n.t('common.contact-blocked'))
      return {
        ...state,
        loading: false
      }
    },
    onunblockNumberSuccess(state, data) {
      notification('success', i18n.t('common.contact-unblocked'))
      return {
        ...state,
        loading: false
      }
    },
    onSyncMsContactsSuccess(state, data) {
      console.log('ms data received==>', data)
      return {
        ...state,
        loading: false
      }
    },
    onImportContactSuccess(state, data) {
      let importedContacts = []
      let source = 'import'
      let defaultKeys = 'email'
      if (data && data.contacts && data.contacts.contact_data) {
        importedContacts = JSON.parse(data.contacts.contact_data)
        source = data.contacts.source
        if (source === 'quickbooks') defaultKeys = 'id'
      } else {
        notification(
          'success',
          i18n.t('contacts_hub.upload-successful-this-will-take-some-time-on-our-side-to-process-well-keep-you-updated')
        )
      }
      return {
        ...state,
        loading: false,
        importedContacts,
        source,
        defaultKeys
      }
    },
    onAddContactSuccess(state, data, msg) {
      notification('success', msg)
      return {
        ...state,
        loading: false,
        addedContact: data && data.data,
        apiError: null
      }
    },
    onGetMessengerNotesSuccess(state, data) {
      return {
        ...state,
        Notesloading: false,
        notes: data && data.data
      }
    },
    onImportSocilaContactSuccess(state, data) {
      notification(
        'success',
        i18n.t('contacts_hub.upload-successful-this-will-take-some-time-on-our-side-to-process-well-keep-you-updated')
      )
      return {
        ...state,
        loading: false,
        importedContacts: [],
        source: '',
        defaultKeys: ''
      }
    },
    onAutosuggestContactsSuccess(state, data) {
      const contacts = data.map(function (item) {
        return item._source
      })
      return {
        ...state,
        paymentLoading: false,
        searchRes: contacts
      }
    },
    onDeleteMessengerNoteSuccess(state, data) {
      notification('success', i18n.t('contacts_hub.note-deleted-successfully'))
      return {
        ...state,
        paymentLoading: false
      }
    },
    addMessengerNoteSuccess(state, data) {
      return {
        ...state,
        paymentLoading: false
      }
    },
    messengerLeadSucess(state, data) {
      let messengerContacts = null
      if (data && data.data && data.data.length === 1) messengerContacts = data.data[0]

      return {
        ...state,
        messageLeads: data.data,
        messengerContacts
      }
    },
    updatemessengerLeadSucess(state, data) {
      const messageLeads = state.messageLeads
      let messengerContacts = {}
      if (messageLeads && messageLeads.length) messengerContacts = messageLeads.find((x) => x._id === data._id)

      return {
        ...state,
        messengerContacts
      }
    },
    updateConatctSucess(state, data) {
      let apiError = null
      let conatctUpdate = false
      if (data && data.error) {
        apiError = data
        notification('error', data.error)
      } else {
        conatctUpdate = true
        if (!window.location.href.includes('/messenger')) {
          notification('success', i18n.t('contacts_hub.contact-has-been-updated-successfully'))
        }
      }
      return {
        ...state,
        loading: false,
        conatctUpdate,
        apiError,
        updatedContactDetails: data.data
      }
    },
    clearContactActivities(state) {
      return {
        ...state,
        loading: false,
        activityList: null,
        activitiesTotl: null,
        delCount: null
      }
    },
    clearFilters(state) {
      return {
        ...state,
        loading: false,
        filter: null
      }
    },
    onendMessageToContacSuccess(state, data) {
      notification('success', i18n.t('contacts_hub.message-sent-successfully'))
      return {
        ...state,
        loading: false,
        messsengerContacts: data.messenegerData
      }
    },
    onVerifyContactEmailSuccess(state, data) {
      notification('success', i18n.t('contacts_hub.contacts-verification-process-started'))
      return {
        ...state,
        loading: false
      }
    }
  },
  effects: {
    async fetchContacts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getContacts(payload)
        this.onFetchContactSuccess(res)
        this.hasFilterApplied({ group_id: payload.group_id, source: payload.source })
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchContactDetails(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getContactDetails(payload)
        this.onFetchContactDetailsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async sendMessageToContacts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendMessageToContact(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.message-sent-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteContact(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteContact(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.contact-has-been-removed-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addContact(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.addContact(payload)
        this.onAddContactSuccess(res, i18n.t('contacts_hub.contact-has-been-created-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchGroups(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getGroups(payload)
        this.onFetchGroupsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchGroupsWithoutPaging(payload, rootState) {
      try {
        const query = { nopaging: true, status: 'active', type: 'group' }
        if (payload && payload.fromenterprsie) {
          query.skip_inactive = 1
          query.skip_active = 1
        }
        const res = await service.getGroups(query)
        this.onFetchGroupsWithoutPagingSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async createGroups(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.addGroups(payload)
        this.createGroupsSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteGroups(payload, rootState) {
      try {
        const res = await service.removeGroups(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.group-deleted-successfully'))
      } catch (e) {
        this.onError(e)
      }
    },
    async renameGroups(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.renameGroups(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.group-renamed-successfully'))
      } catch (e) {
        this.onError(e)
      }
    },
    async addContactToGroup(payload, rootState) {
      try {
        const res = await service.addContactToGroupsAdd(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.contact-added-to-group-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addContactToGroupFromSettings(payload, rootState) {
      try {
        const res = await service.addContactToGroupsAdd(payload)
        notification('success', i18n.t('contacts_hub.groups-successfully-updated'))
        this.onSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async removeContactFromGroup(payload, rootState) {
      try {
        const res = await service.addContactToGroupsRemove(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.contact-s-has-been-removed-successfully'))
      } catch (e) {
        this.onError(e)
      }
    },
    async removeContactFromGroupFromSettings(payload, rootState) {
      try {
        const res = await service.addContactToGroupsRemove(payload)
        notification('success', i18n.t('contacts_hub.groups-successfully-updated'))
        this.onSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchAllContactsToGroups(payload, rootState) {
      try {
        const res = await service.fetchAllContactsToGroups(payload)
        this.onFetchContactForGroupSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async importContactsFromCSV(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.importContactsFromCSV(payload)
        this.onImportContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async importGoogleContacts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.importGoogleContacts(payload)
        this.onImportContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getContactAnalytics(payload, rootState) {
      try {
        const res = await service.contactAnalytics(payload)
        this.onGetAnalyticsSuccess(res)
        return res
      } catch (e) {
        // this.onError(e)
      }
    },
    async paymentRequest(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.paymentRequest(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.transaction-request-has-been-sent-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getNotes(payload, rootState) {
      this.onNoteRequest()
      try {
        const res = await service.getNotes(payload)
        this.onGetNotesSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async addNote(payload, rootState) {
      this.onNoteRequest()
      try {
        const res = await service.addNote(payload)
        this.onAddNoteSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteNote(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteNote(payload)
        this.onDeleteNoteSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async pinNotes(payload, rootState) {
      this.onNoteRequest()
      try {
        const res = await service.pinNotes(payload)
        this.onPinNotesSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getActivity(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getActivity(payload)
        this.onGetActivitySuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getActivities(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getActivities(payload)
        this.onGetActivitiesSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getActivityList(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getActivityList(payload)
        this.onGetActivityList(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async getActivitiesCount(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.getActivitiesCount(payload)
        this.onGetActivitiesCount(res)
        return res.data
      } catch (e) {
        this.onError(e)
      }
    },
    async updateContact(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.updateContact(payload)
        this.updateConatctSucess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async fetchBlockedContacts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.fetchBlockedContacts(payload)
        this.onfetchBlockedContactsSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async blockNumber(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.blockNumber(payload)
        this.onblockNumberSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async unblockNumber(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.unblockNumber(payload)
        this.onunblockNumberSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async syncMsContacts(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.syncMsContacts(payload)
        this.onSyncMsContactsSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async quickBookContact(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.quickBookContact(payload)
        this.onImportContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async outlookContact(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.outlookContact(payload)
        this.onImportContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async getMessengerNotes(payload, rootState) {
      this.onRequest()
      try {
        const res = await getMessengerNotes(payload)
        await this.onGetMessengerNotesSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async socialContactsImport(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.socialContactsImport(payload)
        this.onImportSocilaContactSuccess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async autosuggestContacts(payload) {
      try {
        const res = await service.autosuggestContacts(payload)
        this.onAutosuggestContactsSuccess(res.data)
        return res.data.map((item) => item._source)
      } catch (e) {
        console.log(e)
      }
    },
    async deleteMessengerNote(payload) {
      this.onRequest()
      try {
        const res = await deleteMessengerNote(payload)
        await this.onDeleteMessengerNoteSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async addMessengerNote(payload) {
      this.onRequest()
      try {
        const res = await createNote(payload)
        await this.addMessengerNoteSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async messengerLeads(payload) {
      this.onRequest()
      try {
        const res = await service.messengerLeads(payload)
        this.messengerLeadSucess(res)
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async updatedmessengerLeads(payload) {
      try {
        await this.updatemessengerLeadSucess(payload)
        return true
      } catch (e) {
        console.log(e)
      }
    },
    async flushContactActivities() {
      try {
        this.clearContactActivities()
        return true
      } catch (e) {
        console.log(e)
      }
    },
    async segmentConatctTrack(payload) {
      try {
        const res = await service.segmentConatctTrack(payload)
        return res
      } catch (e) {
        console.log(e)
      }
    },
    async flushFilters() {
      try {
        this.clearFilters()
        return true
      } catch (e) {
        console.log(e)
      }
    },
    async deleteGroup(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteGroup(payload)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.group-deleted-successfully'))
        return res
      } catch (e) {
        this.onError(e)
      }
    },
    async deleteEnterpriseNote(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.deleteEnterpriseNote(payload)
        this.onDeleteNoteSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async removeFromGroup(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.removeFromGroup(payload)
        this.onRemoveFromGroupSuccess(res)
      } catch (e) {
        this.onError(e)
      }
    },
    async sendMessageToContactMessenger(payload, rootState) {
      this.onRequest()
      try {
        const res = await service.sendMessageToContactMessenger(payload)
        this.onendMessageToContacSuccess(res)
        return res.messenegerData
      } catch (e) {
        this.onError(e)
      }
    },
    async verifyContactEmail() {
      this.onRequest()
      try {
        const res = await service.verifyContactEmail()
        this.onVerifyContactEmailSuccess(res)
        return res.messenegerData
      } catch (e) {
        this.onError(e)
      }
    },
    async exportContactsToFile(userToken) {
      this.onRequest()
      try {
        const res = await service.exportToFile(userToken)
        this.onSuccessWithMessage(res, i18n.t('contacts_hub.all-contacts-have-been-successfully-exported'))
        return res
      } catch (e) {
        this.onError(e)
      }
    }
  }
}
