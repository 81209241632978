import { api, catchHandler, adminDownloadApi } from '@helpers/axios'
import { objToUrlParams } from '@helpers/index'
import config from '@config/index'

export function getContacts (payload) {
  return api()
    .get(`${config.routes.contacts}/es?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchAllContactsToGroups (payload) {
  return api()
    .get(`${config.routes.contacts}/es?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function autosuggestContacts (payload) {
  return api()
    .get(`${config.routes.contactsAutosuggest}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getContactDetails (payload) {
  return api()
    .get(`${config.routes.contacts}/${payload.id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function sendMessageToContact (payload) {
  return api()
    .post(`${config.routes.contacts}/${payload.id}/contact`, payload, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteContact (payload) {
  return api()
    .put(config.routes.contactDelete, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteGroup (payload) {
  return api()
    .put(config.routes.deleteGroup, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addContact (payload) {
  return api()
    .post(config.routes.contactCreate, payload, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function getGroups (payload) {
  return api()
    .get(`${config.routes.contacts}/es?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addGroups (payload) {
  return api()
    .post(`${config.routes.groups}/create`, payload, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function removeGroups (payload) {
  return api()
    .put(`${config.routes.groups}/delete`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function renameGroups (payload) {
  return api()
    .put(`${config.routes.groups}/update`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addContactToGroupsAdd (payload) {
  return api()
    .post(`${config.routes.groups}/addcontacts`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addContactToGroupsRemove (payload) {
  return api()
    .post(`${config.routes.groups}/removecontacts`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function removeFromGroup (payload) {
  return api()
    .put(`${config.routes.contacts}/deletefromgroup`, payload)
    .then((res) => {
      return res.data
    })
    .catch(catchHandler)
}

export function importContactsFromCSV (payload) {
  return api()
    .post(config.routes.importContact, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function importGoogleContacts (payload) {
  return api(true)
    .post(config.routes.importGoogleContact, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function contactAnalytics () {
  return api()
    .get(config.routes.contactAnalytics)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function paymentRequest (payload) {
  return api()
    .post(config.routes.paymentRequest, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getNotes (payload) {
  return api()
    .get(
      `${config.routes.contacts}/${payload.id}/notes?sort=${payload.sort}&asc=${payload.asc}&nopaging=${payload.nopaging}&search=${payload.search}`
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function addNote (payload) {
  return api()
    .post(`${config.routes.contacts}/${payload.id}/notes`, { note: payload.note }, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteNote (payload) {
  return api()
    .put(`${config.routes.contacts}/notes/${payload}/remove`, {})
    .then((res) => res.data)
    .catch(catchHandler)
}
export function pinNotes (payload) {
  return api()
    .put(`${config.routes.messenger}/${payload.id}/messages/note/pin-note`, payload.data)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getActivities (payload) {
  return api()
    .get(`${config.routes.contacts}/${payload.id}/activities`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getActivity (payload) {
  return api()
    .get(`${config.routes.contacts}/${payload.id}/activity`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getActivityList (payload) {
  return api()
    .get(
      `${config.routes.contacts}/${payload.id}/activity?limit=${payload.limit}&start=${payload.start}&activity_type=${payload.activity_type}`
    )
    .then((res) => res.data)
    .catch(catchHandler)
}
export function getActivitiesCount (payload) {
  return api()
    .get(`${config.routes.contacts}/${payload.id}/activities`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function updateContact (payload) {
  return api()
    .put(config.routes.updateContact, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function fetchBlockedContacts (payload) {
  return api()
    .get(`${config.routes.contactListFromES}?${objToUrlParams(payload)}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function blockNumber (payload) {
  return api()
    .post(`${config.routes.contacts}/block-unblock`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function unblockNumber (payload) {
  return api()
    .post(`${config.routes.messenger}/block-unblock`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function syncMsContacts (payload) {
  return api()
    .post(`${config.routes.msAuth}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function quickBookContact (payload) {
  return api(true)
    .post(config.routes.quickBookContact, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function outlookContact (payload) {
  return api(true)
    .post(config.routes.outlookContacts, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function socialContactsImport (payload) {
  return api(true)
    .post(config.routes.socialcontactsimportsome, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function messengerLeads (id) {
  return api()
    .get(`${config.routes.messenger}/threads/${id}`)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function segmentConatctTrack (payload) {
  return api()
    .post(`${config.routes.segmentConatctTrack}`, payload)
    .then((res) => res.data)
    .catch(catchHandler)
}
export function deleteEnterpriseNote (payload) {
  return api()
    .put(`${config.routes.contacts}/notes/${payload.id}/remove?customers=${payload.customers}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function sendMessageToContactMessenger (payload) {
  return api()
    .post(`${config.routes.contacts}/${payload.id}/notify`, payload, { params: payload.query })
    .then((res) => res.data)
    .catch(catchHandler)
}

export function verifyContactEmail () {
  return api()
    .get(`${config.routes.verifyContactEmail}`)
    .then((res) => res.data)
    .catch(catchHandler)
}

export function exportToFile (usertoken) {
  return adminDownloadApi()
    .get(`${config.routes.contacts}?export=true&token=${usertoken}`)
    .then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'Contacts.csv')
      document.body.appendChild(link)
      link.click()
    })
    .catch(catchHandler)
}
