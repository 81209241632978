import React from 'react'
import ContentLoader from 'react-content-loader'
import { findWithAttr, convertHoursToArray, slotConversionFunction } from '@helpers/utility'
import { capitalize } from '@helpers/index'
import InputMask from 'react-input-mask'
import hoursStyle from './index.module.scss'
import './business-hours.scss'
import Icon from '@components/icons'
import { withTranslation } from 'react-i18next'
const selectOptions = [
  { label: 'AM', value: 'AM' },
  { label: 'PM', value: 'PM' }
]

const defaultHours = {
  friday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  },
  monday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  },
  saturday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  },
  sunday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  },
  thursday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  },
  tuesday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  },
  wednesday: {
    open: true,
    open_allday: false,
    slots: [
      {
        time_end: '5:00 PM',
        time_end1: '5:00',
        time_end2: 'PM',
        time_start: '9:00 AM',
        time_start1: '9:00',
        time_start2: 'AM'
      }
    ],
    sortOrder: 5,
    split: false
  }
}
class TimeInput extends React.Component {
  onChange = (event) => {
    const { slot, k } = this.props
    let timeValue
    let time = event.target.value
    if (time && time[0] && parseInt(time[0]) > 1) {
      // time = `0${time}`
      const t1 = '0'
      const t2 = `${t1}${time[0]}`
      if (time.length === 5) timeValue = `${t2}${time[2]}${time[3]}${time[4]}`
      else timeValue = `${t2}${time[1]}${time[2]}${time[3]}`

      time = timeValue
    }
    if (time && time[0] && parseInt(time[0]) === 1) {
      if (time.length === 5 && parseInt(time[1]) > 2) {
        timeValue = `${time[0]}0${time[2]}${time[3]}${time[4]}`
        time = timeValue
      }
    }
    slot[k] = time

    this.props.handleChange(time, this.props.day, this.props.index, slot)
  }
  render() {
    const { disabled, placeholder } = this.props
    let { time } = this.props
    let startsWithOne
    let timeValue = ''

    if (time && time[0] && parseInt(time[0]) > 1) {
      // time = `0${time}`
      const t1 = '0'
      const t2 = `${t1}${time[0]}`
      if (time.length === 5) timeValue = `${t2}${time[2]}${time[3]}${time[4]}`
      else timeValue = `${t2}${time[1]}${time[2]}${time[3]}`

      time = timeValue
    }
    if (time && time[0] && parseInt(time[0]) === 1) {
      if (time.length === 5 && parseInt(time[1]) > 2) {
        timeValue = `${time[0]}0${time[2]}${time[3]}${time[4]}`
        time = timeValue
      } else if (time.length === 4 && parseInt(time[0]) === 1) {
        timeValue = `0${time[0]}${time[2]}${time[3]}`
        time = timeValue
      }
    }

    if (time) {
      startsWithOne = time[0] === '1'
    }
    const mask = [/[0-9]/, startsWithOne ? /[0-2]/ : /[0-9]/, ':', /[0-5]/, /[0-9]/]
    return (
      <InputMask
        maskPlaceholder="0"
        disabled={disabled}
        className="form-control form-control-xs border-0"
        placeholder={placeholder}
        mask={mask}
        value={time}
        onChange={this.onChange}
      />
    )
  }
}

class businessHours extends React.Component {
  t = this.props.t

  dayShort = {
    Monday: this.t('settings.mon'),
    Tuesday: this.t('settings.tue'),
    Wednesday: this.t('settings.wed'),
    Thursday: this.t('settings.thu'),
    Friday: this.t('settings.fri'),
    Saturday: this.t('settings.sat'),
    Sunday: this.t('settings.sun')
  }

  constructor(props) {
    super(props)
    this.state = {
      hideBusinessHours: props.hideHours,
      formattedHours:
        props.page === 'servicePopup' ? [] : props.hours ? convertHoursToArray(props.hours) : defaultHours,
      setFlag: false
    }
  }

  static getDerivedStateFromProps(props, state) {
    if (props.hours && props.page === 'servicePopup' && !state.setFlag) {
      if (JSON.stringify(state.formattedHours) !== JSON.stringify(props.hours)) {
        const formattedHours = slotConversionFunction(props.hours)
        return { formattedHours: formattedHours, setFlag: true }
      }
    } else {
      return null
    }
  }

  async handleChange(value, day, index, slot) {
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)
      formattedHours[day].slots[index] = slot
      return { formattedHours }
    })
    this.props.handleChange(this.state.formattedHours)
  }
  async toggleBusinessHours() {
    await this.setState({ hideBusinessHours: !this.state.hideBusinessHours })
    this.props.toggleHours(this.state.hideBusinessHours)
  }
  async open24Hours(day) {
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)
      formattedHours[day].slots = formattedHours[day].slots[0] ? [formattedHours[day].slots[0]] : []
      formattedHours[day].open_allday = !formattedHours[day].open_allday
      formattedHours[day].isDisabledtrue = formattedHours[day].open_allday

      if (formattedHours[day].open_allday) {
        if (formattedHours[day].slots[0]) {
          formattedHours[day].slots[0].time_start1 = '00:00'
          formattedHours[day].slots[0].time_end1 = '00:00'
          formattedHours[day].slots[0].time_start2 = 'AM'
          formattedHours[day].slots[0].time_end2 = 'AM'
        }
      } else {
        if (formattedHours[day].slots[0]) {
          formattedHours[day].slots[0].time_start1 = ''
          formattedHours[day].slots[0].time_end1 = ''
          formattedHours[day].slots[0].time_start2 = 'AM'
          formattedHours[day].slots[0].time_end2 = 'PM'
        }
      }
      return { formattedHours }
    })
    this.props.handleChange(this.state.formattedHours)
  }

  async toggleOpenHours(day) {
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)
      formattedHours[day].open_allday = false
      formattedHours[day].open = !formattedHours[day].open
      formattedHours[day].slots = [formattedHours[day].slots[0]]
      formattedHours[day].slots[0].time_start2 = 'AM'
      formattedHours[day].slots[0].time_end2 = 'PM'
      if (formattedHours[day].open) {
        formattedHours[day].slots[0].time_start1 = '09:00'
        formattedHours[day].slots[0].time_end1 = '05:00'
      } else {
        formattedHours[day].slots[0].time_start1 = ''
        formattedHours[day].slots[0].time_end1 = ''
      }
      return { formattedHours }
    })
    this.props.handleChange(this.state.formattedHours)
  }
  async addSlot(day) {
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)
      formattedHours[day].slots.push({
        time_start: '',
        time_start2: 'AM',
        time_end: '',
        time_end2: 'PM',
        minval: 0,
        maxval: 1440
      })
      formattedHours[day].split = true
      return { formattedHours }
    })
  }
  async applyToAll(input) {
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)
      Object.keys(formattedHours).forEach((day) => {
        formattedHours[day] = JSON.parse(JSON.stringify(input))
      })
      return { formattedHours }
    })
    // let formattedHours = Object.assign({}, this.state.formattedHours);
    // Object.keys(formattedHours).forEach(function(day) {
    //     formattedHours[day] = JSON.parse(JSON.stringify(input));
    // });
    this.props.handleChange(this.state.formattedHours)
  }
  async removeSlot(day, index) {
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)
      if (formattedHours[day].slots.length === 1) {
        formattedHours[day].open = false
        formattedHours[day].open_allday = false
        formattedHours[day].slots[index].time_start1 = ''
        formattedHours[day].slots[index].time_start2 = 'AM'
        formattedHours[day].slots[index].time_end1 = ''
        formattedHours[day].slots[index].time_end2 = 'PM'
      } else {
        formattedHours[day].slots.splice(index, 1)
        if (formattedHours[day].slots.length === 1) formattedHours[day].split = false
      }
      return { formattedHours }
    })
    this.props.handleChange(this.state.formattedHours)
  }
  async timeDropdownChange(day, index, key, event) {
    const data = event.target.value
    await this.setState((prevState) => {
      const formattedHours = Object.assign({}, prevState.formattedHours)

      formattedHours[day].slots[index][key] = data
      return { formattedHours }
    })
    this.props.handleChange(this.state.formattedHours)
  }
  defaultOption(value) {
    if (value) {
      const index = findWithAttr(selectOptions, 'value', value)
      return selectOptions[index]
    } else {
      return selectOptions[0]
    }
  }
  render() {
    const { loading } = this.props
    const { formattedHours, hideBusinessHours } = this.state
    const t = this.t
    return (
      <section className="mb-20 business-settings-business-hours-wrapper">
        {this.props.page !== 'servicePopup' && this.props.page !== 'messenger' && this.props.page !== 'bulkupdate' && (
          <header className="business-hours-head mb-15 mb-md-0 pb-30 border-bottom d-flex">
            <h2 className="secondary-font-bold">{t('settings.business-hours')}</h2>
            <div className="custom-tooltip ml-10 cursor-pointer" onClick={this.toggleBusinessHours.bind(this)}>
              <i className={`btn-icon d-flex top-4 ${hoursStyle['icon-hours-visible']}`}>
                <Icon name={hideBusinessHours ? 'eyeInvisible' : 'eyeVisible'} />
              </i>
              <div className={'custom-tooltip-active'}>
                <div className="content-block d-block mt-10 width-150">
                  <div className="tooltip-description mb-0">
                    <p className="text-center mb-0">
                      {hideBusinessHours ? t('settings.show-business-hours') : t('settings.hide-business-hours')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}
        {!loading ? (
          <div className={'business-hours-wrapper'}>
            {Object.keys(formattedHours).map((day, index) => (
              <div
                className={`mb-15 mb-md-0 pt-4 pb-15 pb-md-5 border-bottom ${
                  !formattedHours[day].open ? 'row-disable' : null
                }`}
                key={day}>
                <div className="d-block d-md-flex b-hours ">
                  <div className="mb-10 mb-md-0 left-content">
                    <div className="d-flex mt-10 h-100">
                      <div className="d-flex w-100 justify-content-between ">
                        <div className="form-group mb-0 form-check checkbox-weeks">
                          <label>
                            <input
                              type="checkbox"
                              checked={formattedHours[day].open}
                              onChange={this.toggleOpenHours.bind(this, day)}
                            />
                            <span className="checkbox-icon mr-10"></span>
                            {this.props.page === 'servicePopup'
                              ? this.dayShort[capitalize(day)]
                              : capitalize(t(`settings.${day}`))}
                          </label>
                        </div>
                        <div className="form-group mb-0 form-switch switch">
                          {(formattedHours[day].open || this.props.page === 'messenger') && (
                            <label>
                              {t('settings.24-hours')}
                              <input
                                name="start_invoice"
                                type="checkbox"
                                checked={formattedHours[day].open_allday}
                                onChange={this.open24Hours.bind(this, day)}
                                disabled={!formattedHours[day].open}
                              />
                              <span className="checkbox-icon ml-5 ml-md-10"></span>
                            </label>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="right-content">
                    {formattedHours[day].slots.map((item, idx) => (
                      <div className="d-block d-md-flex" key={idx}>
                        <div className="d-flex flex-grow-1">
                          <div className="flex-grow-1">
                            <div className="d-flex">
                              <div className="b-time">
                                <TimeInput
                                  time={item.time_start1}
                                  handleChange={this.handleChange.bind(this)}
                                  index={idx}
                                  day={day}
                                  slot={item}
                                  k="time_start1"
                                  placeholder={t('settings.open')}
                                  disabled={formattedHours[day].open_allday || !formattedHours[day].open}
                                />
                              </div>
                              <div className="d-flex align-items-center b-time-session position-relative">
                                <select
                                  name="timeStart"
                                  value={item.time_start2}
                                  onChange={this.timeDropdownChange.bind(this, day, idx, 'time_start2')}
                                  className="form-control border-0 w-100 position-relative z-index-1 cursor-pointer"
                                  id="formPaymentVerifyCredential_month"
                                  disabled={formattedHours[day].open_allday || !formattedHours[day].open}>
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                                </select>
                                <span className="caret-button-down position-absolute z-index-0 custom-caret-button-down"></span>
                              </div>
                            </div>
                          </div>

                          <div className={'flex-grow-1 '}>
                            <div className="d-flex">
                              <div className="b-time">
                                <TimeInput
                                  time={item.time_end1}
                                  handleChange={this.handleChange.bind(this)}
                                  index={idx}
                                  day={day}
                                  slot={item}
                                  k="time_end1"
                                  placeholder={t('settings.close')}
                                  disabled={formattedHours[day].open_allday || !formattedHours[day].open}
                                />
                              </div>
                              <div className="d-flex align-items-center b-time-session position-relative">
                                <select
                                  name="timeEnd"
                                  value={item.time_end2}
                                  onChange={this.timeDropdownChange.bind(this, day, idx, 'time_end2')}
                                  className="form-control border-0 w-100 position-relative z-index-1 cursor-pointer"
                                  id="formPaymentVerifyCredential_month"
                                  disabled={formattedHours[day].open_allday || !formattedHours[day].open}>
                                  <option value="AM">AM</option>
                                  <option value="PM">PM</option>
                                </select>
                                <span className="caret-button-down position-absolute z-index-0 custom-caret-button-down"></span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="d-flex flex-grow-1 b-customize ">
                          {formattedHours[day].open && (
                            <div className="ml-md-10">
                              <span className="">
                                <button
                                  type="button"
                                  className={`btn add-slot-btn pl-0 common-action-btn-icons action-plus-icon ${hoursStyle['action-btn-icons']}`}
                                  disabled={formattedHours[day].open_allday}
                                  onClick={this.addSlot.bind(this, day)}>
                                  <i className="btn-icon plus-icon-effect d-flex rounded-circle">
                                    <Icon name="plusThin" />
                                  </i>
                                </button>
                              </span>
                              {index === 0 && formattedHours[day].slots.length === idx + 1 && (
                                <span className="">
                                  <button
                                    type="button"
                                    className={`btn apply-to-all-btn pr-0 common-action-btn-icons action-tick-icon ${hoursStyle['action-btn-icons']}`}
                                    onClick={this.applyToAll.bind(this, formattedHours[day])}
                                    title={t('settings.apply-to-all')}>
                                    <i className="btn-icon tick-icon-effect d-flex rounded-circle">
                                      <Icon name="tickThin" />
                                    </i>
                                  </button>
                                </span>
                              )}
                              <span className="">
                                <button
                                  type="button"
                                  className={`btn remove-slot-btn common-action-btn-icons action-close-icon ${hoursStyle['action-btn-icons']} `}
                                  onClick={this.removeSlot.bind(this, day, idx)}
                                  title={t('settings.remove-slot')}>
                                  <i className="btn-icon close-icon-effect d-flex rounded-circle">
                                    <Icon name="closeThin" />
                                  </i>
                                </button>
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="business-hours-loader">
            {[1, 2, 3, 4, 5, 6, 7].map((item) => (
              <div className="row" key={item}>
                {[1, 2, 3, 4, 5, 6].map((itm) => (
                  <div className="col-2" key={itm}>
                    <ContentLoader>
                      <rect rx="4" ry="4" width="100%" height="50px" />
                    </ContentLoader>
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </section>
    )
  }
}

export default withTranslation()(businessHours)
