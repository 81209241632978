import React, { PureComponent } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import Popup from './basic-modal'
import Icon from '../icons'
import moment from 'moment'
import PredefinedTemplate from '../predefined-templates'
import { withTranslation } from 'react-i18next'
let isFormChanged = false

class SendEmailSmsPopup extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      type: '',
      saveContact: false
    }
  }
  closeModal() {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }
  handleCancel(values, { resetForm }) {
    this.props.closeModal(isFormChanged)
    isFormChanged = false
  }
  async handleSubmit(values, { resetForm }) {
    const payloadData = Object.assign(this.props.payloadData)
    if (values.email_subject) {
      payloadData.email_subject = values.email_subject
    }
    if (values.email_subject) {
      payloadData.customer_message = values.customer_message
    }
    if (values.saveContact) {
      payloadData.contact_update = true
      // payloadData.contact_id = payloadData.contact_id
    } else {
      payloadData.contact_update = false
    }
    await this.props.submitData(payloadData)
  }
  getContactAddText() {
    // if(this.props.payloadData && this.props.payloadData.isEmailChanged && this.props.payloadData.isMobileChanged && this.props.payloadData.isNameChanged) {
    //     return "Update contact with new phone number, email and name";
    // }if(this.props.payloadData && this.props.payloadData.isEmailChanged && this.props.payloadData.isMobileChanged && !this.props.payloadData.isNameChanged) {
    //     return "Update contact with new phone number and email";
    // }if(this.props.payloadData && this.props.payloadData.isEmailChanged && !this.props.payloadData.isMobileChanged && this.props.payloadData.isNameChanged) {
    //     return "Update contact with new email and name";
    // }if(this.props.payloadData && !this.props.payloadData.isEmailChanged && this.props.payloadData.isMobileChanged && this.props.payloadData.isNameChanged) {
    //     return "Update contact with new phone number and name";
    // }else if(this.props.payloadData && this.props.payloadData.isEmailChanged && !this.props.payloadData.isMobileChanged && !this.props.payloadData.isNameChanged) {
    //     return "Update contact with new email";
    // }else if(this.props.payloadData && !this.props.payloadData.isEmailChanged && this.props.payloadData.isMobileChanged && !this.props.payloadData.isNameChanged) {
    //     return "Update contact with new phone number";
    // }else if(this.props.payloadData && !this.props.payloadData.isEmailChanged && !this.props.payloadData.isMobileChanged && this.props.payloadData.isNameChanged) {
    //     return "Update contact with new name";
    // }
    return this.props.t('common.update-contact-with-new-information')
  }
  selectTemplateFn(selectedItem, setFieldValue) {
    setFieldValue('customer_message', selectedItem.message)
  }
  getFulladdress(data) {
    let addressField = ''
    if (data.service_address2) {
      addressField = addressField ? `${addressField}, ${data.service_address2}` : data.service_address2
    }
    if (data.service_city) {
      addressField = addressField ? `${addressField}, ${data.service_city}` : data.service_city
    }
    if (data.service_state) {
      addressField = addressField ? `${addressField}, ${data.service_state}` : data.service_state
    }
    if (data.service_zip) {
      addressField = addressField ? `${addressField}, ${data.service_zip}` : data.service_zip
    }
    return addressField || ''
  }
  componentDidMount() {
    let title = ''
    if (this.props.payloadData && this.props.payloadData.send_email && this.props.payloadData.send_sms) {
      title = this.props.t('common.send-via-email-and-sms')
      this.setState({ type: 'email&sms', title })
    } else if (this.props.payloadData && !this.props.payloadData.send_email && this.props.payloadData.send_sms) {
      title = this.props.t('common.send-via-sms')
      this.setState({ type: 'sms', title })
    } else if (this.props.payloadData && this.props.payloadData.send_email && !this.props.payloadData.send_sms) {
      title = this.props.t('common.send-via-email')
      this.setState({ type: 'email', title })
    }
  }
  render() {
    const { title } = this.state
    return (
      <React.Fragment>
        <Popup
          open={this.props.open}
          title={title}
          onCancel={() => this.closeModal.bind(this)}
          type="default"
          modalBackdropClicked={() => console.log(null)}
          id="modalSendEmailSmsTypeLink">
          <Formik
            validateOnChange={false}
            validateOnBlur={true}
            // enableReinitialize={true}
            initialValues={{
              email_subject:
                this.props.payloadData && this.props.payloadData.email_subject
                  ? this.props.payloadData.email_subject
                  : 'New Booking: ' + moment().format('MMMM YYYY'),
              customer_message:
                this.props.payloadData && this.props.payloadData.customer_message
                  ? this.props.payloadData.customer_message
                  : 'Thanks for your interest in our services',
              saveContact: this.state.saveContact
            }}
            validationSchema={Yup.object().shape({
              email_subject: Yup.string().required('Required'),
              customer_message: Yup.string().required('Required')
            })}
            onReset={this.handleCancel.bind(this)}
            onSubmit={this.handleSubmit.bind(this)}
            render={({ values, setFieldValue }) => {
              return (
                <Form id="frmSendEmailSms" name="frmSentInvoice" className={'pt-20 send-email-sms-popup-wrapper'}>
                  <button
                    name="btninvoiceCloseModal"
                    type="reset"
                    className="close custom-modal-close-button remove-modal-close">
                    <span aria-hidden="true">×</span>
                  </button>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group custom-form-group-dropdown mb-10 custom-cursor-hide">
                        <label className="label-text">SENDING TO</label>
                        <div className="d-flex align-items-center border rounded-sm p-15">
                          <figure className={'mb-0 mr-15 b-image-sending'}>
                            <img
                              className={'w-100 rounded-circle image'}
                              src={
                                this.props.payloadData && this.props.payloadData.photo
                                  ? this.props.payloadData.photo
                                  : 'https://dufzo4epsnvlh.cloudfront.net/image/user_avatar.svg'
                              }
                              alt=""
                            />
                          </figure>
                          <div className="">
                            <div className="d-flex mb-5">
                              <h4>
                                {this.props.payloadData && this.props.payloadData.first_name ? (
                                  <span>{this.props.payloadData.first_name}</span>
                                ) : null}
                                {this.props.payloadData && this.props.payloadData.last_name ? (
                                  <span className="pl-5">{this.props.payloadData.last_name}</span>
                                ) : null}
                              </h4>
                            </div>
                            <div className="d-flex">
                              {this.props.payloadData && this.props.payloadData.customer_phone && (
                                <div
                                  className={`${
                                    this.props.payloadData && this.props.payloadData.isMobileChanged
                                      ? 'text-primary'
                                      : ''
                                  }`}>
                                  {this.props.payloadData.customer_phone}
                                </div>
                              )}

                              {this.props.payloadData &&
                              this.props.payloadData.customer_phone &&
                              this.props.payloadData &&
                              this.props.payloadData.customer_email ? (
                                <span
                                  className={`pl-5 pr-5 dot-separate ${
                                    this.props.payloadData &&
                                    this.props.payloadData.isMobileChanged &&
                                    this.props.payloadData.isEmailChanged
                                      ? 'text-primary'
                                      : ''
                                  }  `}></span>
                              ) : null}

                              {this.props.payloadData && this.props.payloadData.customer_email && (
                                <div
                                  className={`${
                                    this.props.payloadData && this.props.payloadData.isEmailChanged
                                      ? 'text-primary'
                                      : ''
                                  }`}>
                                  {this.props.payloadData.customer_email}
                                </div>
                              )}
                            </div>
                            {this.props.payloadData && this.props.payloadData.service_address ? (
                              <div className={`${this.props.payloadData.isAddressChanged ? 'text-primary' : ''}`}>
                                <span>
                                  {this.props.payloadData && this.props.payloadData.service_address
                                    ? `${this.props.payloadData.service_address} `
                                    : ''}
                                </span>
                                <span>{this.props.payloadData ? this.getFulladdress(this.props.payloadData) : ''}</span>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    {this.props.payloadData &&
                    (this.props.payloadData.isEmailChanged ||
                      this.props.payloadData.isMobileChanged ||
                      this.props.payloadData.isNameChanged ||
                      this.props.payloadData.isAddressChanged) ? (
                      <div className="col-12">
                        <div className="form-group form-check mb-30">
                          <label>
                            <Field
                              type="checkbox"
                              className="h-cb"
                              checked={values.saveContact}
                              onChange={(event) => {
                                setFieldValue('saveContact', event.target.checked)
                                this.setState({ saveContact: event.target.checked })
                              }}
                            />
                            <span id="frm_addService_chkBookingHours" className="checkbox-icon mr-10"></span>
                            {this.getContactAddText()}
                          </label>
                        </div>
                      </div>
                    ) : null}
                    {this.state.type === 'email' || this.state.type === 'email&sms' ? (
                      <React.Fragment>
                        <div className="col-12">
                          <div className="form-group custom-form-group-dropdown mb-15 custom-cursor-hide">
                            <label className="label-text">
                              EMAIL SUBJECT <i className="star">*</i>
                              <ErrorMessage name="email_subject" component="span" className={'form-error'} />
                            </label>
                            <Field
                              type="text"
                              name="email_subject"
                              className="form-control form-control-lg"
                              placeholder="Enter subject"
                              onChange={(event) => {
                                setFieldValue('email_subject', event.target.value)
                                isFormChanged = true
                              }}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group custom-form-group-dropdown mb-30 custom-cursor-hide">
                            <label className="label-text">
                              EMAIL MESSAGE <i className="star">*</i>
                              <ErrorMessage name="customer_message" component="span" className={'form-error'} />
                            </label>
                            <div className="mb-15">
                              <PredefinedTemplate
                                templates={this.props.predefinedTemplates}
                                setFieldValue={setFieldValue}
                                selectTemplateFn={this.selectTemplateFn.bind(this)}
                              />
                            </div>
                            <Field
                              type="text"
                              name="customer_message"
                              className="form-control form-control-lg"
                              placeholder="Enter message"
                              onChange={(event) => {
                                setFieldValue('customer_message', event.target.value)
                                isFormChanged = true
                              }}
                            />
                          </div>
                        </div>
                      </React.Fragment>
                    ) : null}
                  </div>
                  <footer className="d-block d-sm-flex mt-10">
                    <button
                      className={'btn btn-primary border-0 btn-xl  mr-10 flex-fill'}
                      id="frmBtnSendEmailSms"
                      disabled={this.props.loader}
                      type="submit">
                      {this.props.submitText}
                      {this.props.loader && (
                        <i className={'btn-icon top-minus-2 mr-5 btn-loader'}>
                          <Icon name="spinner" />
                        </i>
                      )}
                    </button>
                    <button
                      type="reset"
                      id="frmBtnSendEmailSms_btnCancel"
                      className={'btn btn-xl ml-10 cancel-btn flex-fill'}>
                      CANCEL
                    </button>
                  </footer>
                </Form>
              )
            }}
          />
        </Popup>
      </React.Fragment>
    )
  }
}

export default withTranslation(null, { withRef: true })(SendEmailSmsPopup)
