import * as React from 'react'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReCAPTCHA from 'react-google-recaptcha'
import Style from '@containers/myaccount/index.module.scss'
import { numberMask } from '@helpers/inputmask'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import MediaLibraryPopup from '@containers/media-library/media/media-library'
import Popup from '../basic-modal'
import Icons from '../../icons'
import PredefinedTemplate from '../../predefined-templates'
import notification from '../../notification'
import { ClosePopupButton } from '../components/closePopupButton'
import { LoadingSubmitButton, CancelButton } from '../components/buttons'
import { LocationList } from '../sendMessagePopup/locationList'
import { MessageCreatableSelect } from '../sendMessagePopup/messageCreatableSelect'
import {
  isEnabled,
  phoneNumberFormat,
  validateEmail,
  validateMobile,
  phoneNumberAutomasking,
  encryptMessageUsingRSAPub
} from '@helpers/utility'
import { segmentEventTrack } from '@helpers/segment'
import { trackSendMessage } from '@containers/messenger/tracking'
import { Button, Box } from '@gositeinc/ui'
import config from '@config/index'
import { useCaptchaVerification } from '@app/helpers/useCaptchaVerification'
import { useTranslation } from 'react-i18next'

export const SendMessagePopup = ({
  open,
  onClose,
  enterprise,
  bulkUpdate,
  onSendMsgSuccess,
  verifyOtp,
  onRequestOtp,
  onRequestOtpOnCall,
  securityText,
  title,
  flow
}) => {
  const { t } = useTranslation()
  const [submitting, setSubmitting] = useState(false)
  const [contactDetails, setContactDetails] = useState()
  const [selectedContacts, setSelectedContacts] = useState()
  // media popup state
  const [mediaUrl, setMediaUrl] = useState('')
  const [mediaDisplayName, setMediaDisplayName] = useState('')
  const [openMediaPopup, setOpenMediaPopup] = useState(false)
  const [verifyPopupData, setVerifyPopupData] = useState({
    // open: false,
    contact_phone: '',
    submitting: false,
    otpSubmitting: false,
    otpPage: false
  })

  // rematch
  const me = useSelector((state) => state.auth.me)
  const accountSettings = useSelector((state) => state.accountsettings.accountsettings)
  const products = useSelector((state) => state.auth.products)
  const formattedLocations = useSelector((state) => state.auth.formatedLocations)
  // const formattedLocations = [{name: 'first location', customer: 'first customer'}, {name: '2nd location name', customer: '2nd customer'}]
  const msgApiError = useSelector((state) => state.messenger.msgApiError)
  const predefinedTemplates = useSelector((state) => state.common.predefinedTemplates)
  const enterpriseData = useSelector((state) => state.enterpriseReviews.locationReviewSettings)
  const dispatch = useDispatch()
  const {
    capthcaValue,
    noOfRetries,
    isResendCodeDisabled,
    showReCaptcha,
    timer,
    onChangeRecaptche,
    setIsResendCodeDisabled,
    resetTimer,
    incrementNumberOfRetries
  } = useCaptchaVerification()
  // var resolve = ''

  // get anything we're missing in the store
  useEffect(() => {
    const getState = async () => {
      if (!me) await dispatch.auth.getMe()
      if (!accountSettings) dispatch.accountsettings.fetchAccountSettings()
      if (enterprise && !formattedLocations) dispatch.auth.getEnterpriseLocations()
      if (!predefinedTemplates) dispatch.common.getPredefinedTemplate({ id: me._id })
      setVerifyPopupData({
        ...verifyPopupData,
        contact_phone: me.contact_phone ? me.contact_phone : ''
      })
    }
    getState()
  }, [])

  const twilioEnabled = Boolean(
    (me && me.account_settings && me.account_settings.twilio && me.account_settings.twilio.length) ||
      (enterprise && enterpriseData && enterpriseData.twilio && enterpriseData.twilio.length)
  )

  const handleReset = () => {
    setContactDetails(undefined)
    setMediaUrl('')
    setMediaDisplayName('')
    setOpenMediaPopup(false)
    setSelectedContacts(undefined)
    setSubmitting(false)
    onClose()
  }

  const handleSubmit = async (values) => {
    setSubmitting(true)
    if (contactDetails) {
      // add encrypted message to payload
      const encryptedMessage = await encryptMessageUsingRSAPub(values.message)
      // create the base payload
      const payload = {
        id: contactDetails.contact_id || null,
        contact_type: contactDetails.mobile ? 'sms' : 'email',
        recipient: values.contact,
        first_name: contactDetails.first_name || null,
        message: encryptedMessage,
        created_by_flow: flow
      }

      if (mediaUrl) payload.media = mediaUrl
      if (enterprise) payload.query = { customers: values.customer }

      const isMobile = !!contactDetails.mobile

      // if there is no id, create a new private contact
      if (!payload.id) {
        const contactPayload = {
          email: !isMobile ? payload.recipient : '',
          mobile: isMobile ? payload.recipient : '',
          country: 'US'
        }
        if (enterprise) {
          contactPayload.query = { customers: values.customer }
        }

        const privateContact = await dispatch.payment.savePrivateContact(contactPayload)
        if (privateContact && privateContact.data) {
          payload.id = privateContact.data._id
          if (!isMobile) {
            if (me && me.bussiness_name)
              payload.subject = t('messenger.you-have-an-email-from-0', {
                0: me.bussiness_name
              })
            else payload.subject = t('messenger.you-have-an-email')
          }
        }
      }

      const res = await dispatch.contact.sendMessageToContactMessenger(payload)
      const messengerContacts = res
      if (!msgApiError && messengerContacts && messengerContacts.messenger && messengerContacts.messenger.lead) {
        onSendMsgSuccess(messengerContacts.messenger.lead, payload.message, payload.contact_type)
      }
      trackSendMessage({
        user_id: me && me._id,
        contact_id: payload.id,
        contact_type: payload.contact_type,
        recipient: payload.recipient
      })
    }
    setSubmitting(false)
    handleReset()
  }

  const verifyPhoneNumber = (contact_phone) =>
    new Promise((resolve) => {
      onRequestOtp({ contact_phone }).then(function (response) {
        resolve(response)
      })
    })

  const handleVerifyPopupSubmit = async (values) => {
    await setSubmitting(true)
    setVerifyPopupData({ ...verifyPopupData, contact_phone: values.txt_phone })
    const resData = await verifyPhoneNumber(values.txt_phone)
    if (!resData) {
      trackPhoneNumber(false)
    } else {
      resetTimer()
      trackPhoneNumber(true)
      await setVerifyPopupData({ ...verifyPopupData, contact_phone: values.txt_phone, otpPage: true })
    }
    await setSubmitting(false)
  }

  // check this 555
  const trackPhoneNumber = (valid) => {
    let data = {}
    data = {
      event: 'entered_mobile_phone_verification',
      properties: {
        user_id: me && me._id,
        valid_number: valid,
        role: me && me.role_id && me.role_id.role_name
      }
    }
    segmentEventTrack(data)
  }

  const trackResendOTP = (type) => {
    let data = {}
    data = {
      event: 'retried_mobile_phone_verification',
      properties: {
        user_id: me && me._id,
        retry_method: type,
        role: me && me.role_id && me.role_id.role_name
      }
    }
    segmentEventTrack(data)
  }
  const trackOTP = (valid) => {
    let data = {}
    data = {
      event: 'entered_code_verification',
      properties: {
        user_id: me && me._id,
        valid_code: valid,
        role: me && me.role_id && me.role_id.role_name
      }
    }
    segmentEventTrack(data)
  }

  const onMediaSuccess = (media) => {
    setOpenMediaPopup(false)
    if (media.length && media[0] && media[0].type) {
      if (media[0].type === 'doc') {
        const fileExtension = media[0].fileUrl.split('.').pop()

        if (fileExtension && fileExtension.toLowerCase() !== 'pdf') {
          notification('error', t('messenger.sms-supports-only-pdf-documents'))
          return
        }
      } else if (media[0].type === 'video' && media[0].file_size && media[0].file_size > 5000) {
        notification('error', t('messenger.sms-video-limit-is-5mb'))
        return
      }
    }
    setMediaUrl(media[0].fileUrl)
    setMediaDisplayName(media[0].displayName)
  }

  const searchContacts = async (inputValue) => {
    if (products && isEnabled('contact-manager', products)) {
      const searchRes = await dispatch.contact.autosuggestContacts({ search: inputValue })
      if (searchRes) {
        const contactList = []
        let i = 0
        for (const result of searchRes) {
          // format the result
          if (result.type === 'contact') {
            const contact = {
              label: '',
              subLabel: '',
              value: '',
              image: '',
              index: i,
              contactObj: result
            }
            // label
            if (result.first_name) {
              if (result.last_name) contact.label = `${result.first_name} ${result.last_name}`
              else contact.label = `${result.first_name}`
            }
            // subLabel
            if (result.mobile) {
              const formattedMobile = phoneNumberFormat(result.mobile)
              if (formattedMobile) {
                contact.value = formattedMobile
                contact.subLabel = formattedMobile
              }
            } else if (result.email) {
              contact.value = result.email
              contact.subLabel = result.email
            }
            // image
            if (result.image) contact.image = result.image

            contactList.push(contact)
            i += 1
          }
        }
        if (contactList.length > 0) return contactList
      }
    }
    return []
  }

  const handleSelectChange = (value, action) => {
    // if the user wants to clear the creatable select
    if (action.action === 'clear') {
      setContactDetails(undefined)
      setSelectedContacts(undefined)
      return ''
    } else if (value && !('length' in value) && value.value) {
      // if the user is creating a new option
      if (
        action.action === 'create-option' ||
        (action.action === 'select-option' && !value.contactObj && value.value)
      ) {
        if (action.action === 'select-option' && !value.contactObj && value.value) {
          value.label = value.value
        }
        const validMobile = validateMobile(value.value)
        const validEmail = validateEmail(value.value)
        if (!validMobile && !validEmail) {
          notification('error', t('payments.please-enter-a-valid-email-or-mobile'))
          setContactDetails(undefined)
          setSelectedContacts(undefined)
          return ''
        }
        if (validMobile) {
          setContactDetails({ mobile: value.value })
          setSelectedContacts(value)
          return value.value
        }
        if (validEmail) {
          setContactDetails({ email: value.value })
          setSelectedContacts(value)
          return value.value
        }
      }
      // if the user clicked on an option
      if (action.action === 'select-option' && value.contactObj) {
        setContactDetails(value.contactObj)
        setSelectedContacts(value)
        return value.value
      }
    }
    return ''
  }

  // const showPopup = (item, phone) => {
  //   // this.setState({ open: true, me: item, phNumber: phNumber })
  //   setVerifyPopupData({ ...verifyPopupData, contact_phone: phone })
  //   return new Promise((resolve, reject) => {
  //     // resolve = resolve
  //     // this.reject = reject
  //   }).catch((e) => {})
  // }

  const handleOtpSubmit = async (values, { resetForm }) => {
    let payload = {}
    payload.otp = values.txt_otp
    payload.contact_phone = verifyPopupData.contact_phone
    payload = { ...me, ...payload }
    if (noOfRetries >= 2 && !capthcaValue) {
      notification('error', t('home.please-complete-the-recaptcha'))
      return
    }
    await setVerifyPopupData({ ...verifyPopupData, otpSubmitting: true })
    const res = await verifyOtp({ payload, resetForm })
    console.log('Final res ======>>>>>')
    if (res) {
      await dispatch.auth.getMe()
      trackOTP(true)
      await setVerifyPopupData({ ...verifyPopupData, otpSubmitting: false, txt_otp: '' })

      resetForm({ txt_otp: '' })
    } else {
      trackOTP(false)
      setVerifyPopupData({ ...verifyPopupData, otpSubmitting: false, txt_otp: '' })
    }
  }

  const handleCancel = async (values, { resetForm }) => {
    await setVerifyPopupData({
      ...verifyPopupData,
      contact_phone: '',
      submitting: false,
      otpSubmitting: false,
      otpPage: false
    })
    closeModal(true, resetForm)
  }

  const closeModal = (resetFlag, resetForm) => {
    onClose()
    setVerifyPopupData({ ...verifyPopupData, otpSubmitting: false })
    if (resetFlag) reset(resetForm)
  }
  const reset = (resetForm) => {
    setVerifyPopupData({ ...verifyPopupData, txt_phone: '' })

    resetForm({ txt_phone: '' })
  }

  const handleVerifyPopupCancel = () => {
    setVerifyPopupData({ ...verifyPopupData, otpPage: false })
    onClose()
  }

  const handleResend = async () => {
    incrementNumberOfRetries()
    setIsResendCodeDisabled(true)
    resetTimer()
    trackResendOTP('text')
    await onRequestOtp({ contact_phone: verifyPopupData.contact_phone })
  }

  const handleOtpOnCall = async () => {
    incrementNumberOfRetries()
    setIsResendCodeDisabled(true)
    resetTimer()
    trackResendOTP('call')
    await onRequestOtpOnCall({ contact_phone: verifyPopupData.contact_phone })
  }
  return (
    <div>
      {twilioEnabled ? (
        <Popup
          open={open}
          title={t('common.send-message')}
          type="small"
          overrideForm={true}
          id={'modalSendMessage'}
          modalBackdropClicked={() => handleReset()}>
          <React.Fragment>
            <div className="message-modal" id="test0000">
              <div className="form-group"></div>
              {open ? (
                <Formik
                  initialValues={{
                    contact: '',
                    message: '',
                    customer: ''
                  }}
                  validationSchema={
                    !enterprise
                      ? Yup.object().shape({
                          contact: Yup.string().required(t('bookings.required')),
                          message: Yup.string().required(t('bookings.required'))
                        })
                      : Yup.object().shape({
                          contact: Yup.string().required(t('bookings.required')),
                          message: Yup.string().required(t('bookings.required')),
                          customer: Yup.string().required(t('bookings.required'))
                        })
                  }
                  onReset={(values, actions) => {
                    actions.resetForm({
                      contact: '',
                      message: '',
                      customer: ''
                    })
                    handleReset()
                  }}
                  onSubmit={(values) => {
                    handleSubmit(values)
                  }}
                  render={({ values, setFieldValue }) => {
                    return (
                      <Form name="frmBookingDetailsSendMsg" id="frmBookingDetailsSendMsg">
                        <ClosePopupButton name="btnCloseBookingSendMsgModal" onClick={() => handleReset()} />
                        <div>
                          <div className="form-group">
                            <label className="label-text">
                              {t('payments.contact')} <i className="star">*</i>
                              <ErrorMessage name="contact" component="span" className="form-error" />
                            </label>
                            <MessageCreatableSelect
                              id="modalSendMessage_txtSearchNumber"
                              name="txtPhone"
                              placeholder={t('messenger.search-contact')}
                              isValidNewOption={(inputValue, selectValue, selectOptions) => {
                                if (inputValue) {
                                  if (selectOptions.length > 0) return false
                                  else return true
                                }
                                return false
                              }}
                              value={selectedContacts || []}
                              loadOptions={searchContacts}
                              onChange={(value, action) => {
                                const contact = handleSelectChange(value, action)
                                setFieldValue('contact', contact)
                              }}
                            />
                          </div>
                          {enterprise && (
                            <div className="auto-search-wrapper mb-20">
                              <div className="form-group">
                                <label className="label-text">
                                  {t('common.location')} <i className="star">*</i>
                                  <ErrorMessage name="customer" component="span" className="form-error" />
                                </label>
                                <LocationList
                                  bulkUpdate={bulkUpdate}
                                  formattedLocations={formattedLocations}
                                  onListItemClick={(value) => setFieldValue('customer', value)}
                                />
                              </div>
                            </div>
                          )}
                          <div className="form-group">
                            <label className="label-text">
                              {' '}
                              {t('messenger.message')} <i className="star">*</i>
                              <ErrorMessage name="message" component="span" className="form-error" />
                            </label>
                            {open && !enterprise && (
                              <div id="container_frmBookingDetailsSentMessageTemplate" className="mb-20">
                                <PredefinedTemplate
                                  templates={predefinedTemplates}
                                  setFieldValue={setFieldValue}
                                  selectTemplateFn={(selectedItem) => {
                                    setFieldValue('message', selectedItem.message)
                                  }}
                                />
                              </div>
                            )}
                            <Field
                              id="frmSent_txtMessage"
                              component="textarea"
                              className="form-control form-control-textarea"
                              type="text"
                              placeholder={t('settings.enter-message')}
                              name="message"
                              data-test="text-message-field"
                            />
                          </div>
                          {mediaUrl !== '' ? (
                            <div className="d-flex align-items-center mb-30">
                              <i className="btn-icon icon-attch-custom">
                                <Icons name="attachment" />
                              </i>
                              <strong className="mx-10 text-truncate">{mediaDisplayName}</strong>
                              <button
                                name="btnCloseAttachedFile"
                                type="button"
                                className="btn-close-custom px-0 bg-white d-flex justify-content-center align-items-center"
                                onClick={() => setMediaUrl('')}>
                                <i className="btn-icon icon-close-custom d-flex">
                                  <Icons name="close" />
                                </i>
                              </button>
                            </div>
                          ) : (
                            <div className="form-group mb-30">
                              <button
                                name="btnAttachSendMsgModal"
                                className="btn btn-sm text-secondary is-loader btn-attchment rounded d-flex align-items-center text-uppercase"
                                onClick={() => setOpenMediaPopup(true)}
                                type="button"
                                data-test="attach-files-btn">
                                <i className="btn-icon mr-10 top-minus-2">
                                  <Icons name="attachment"></Icons>
                                </i>
                                {t('messenger.attach-files')}
                              </button>
                            </div>
                          )}
                        </div>
                        <footer className="d-block d-flex">
                          <LoadingSubmitButton
                            type="submit"
                            name="btnSendMessage"
                            id="btn_send"
                            isSubmitting={submitting}>
                            {t('common.send-uppercase')}
                          </LoadingSubmitButton>
                          <CancelButton
                            name="btnCancelMessage"
                            type="reset"
                            id="btn_cancel"
                            data-test="modal-cancel-message-btn">
                            {t('common.cancel-uppercase')}
                          </CancelButton>
                        </footer>
                      </Form>
                    )
                  }}
                />
              ) : null}
              {openMediaPopup && (
                <MediaLibraryPopup
                  open={openMediaPopup}
                  onSubmit={onMediaSuccess}
                  onCancel={() => setOpenMediaPopup(false)}
                  id="media-library-popup"
                  multiple={false}
                  type={['image', 'video', 'doc', 'icon']}
                  showShapes={true}
                  hideImages={true}
                />
              )}
            </div>
          </React.Fragment>
        </Popup>
      ) : (
        <Popup
          id="modalPhoneVerify7"
          open={open}
          title=""
          onCancel={() => handleVerifyPopupCancel()}
          type="small"
          modalBackdropClicked={() => handleReset()}
          hideHeader={true}>
          {!verifyPopupData.otpPage ? (
            <React.Fragment>
              <div
                id="test0002"
                className="modal-header border-0 position-relative justify-content-center pb-0 pl-10 pr-10">
                <div className="mb-0">
                  <div className="text-center mb-5 mt-5">
                    <img
                      alt=""
                      src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/verify-icon.svg"
                      className="mb-20"
                    />
                    <h2 className="mb-10 fz-24">{`${title || t('home.verify-your-account')}`}</h2>
                  </div>
                  {securityText && <p className="text-center">{securityText}</p>}
                  <p className="text-center mb-0">
                    {t(
                      'home.enter-your-mobile-number-below-and-we-will-send-you-a-confirmation-code-in-the-next-few-seconds'
                    )}
                  </p>
                </div>
              </div>

              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                initialValues={{
                  txt_phone: verifyPopupData.contact_phone
                    ? phoneNumberAutomasking(verifyPopupData.contact_phone.replace('+1', ''))
                    : ''
                }}
                validationSchema={Yup.object().shape({
                  txt_phone: Yup.string().required(t('bookings.required'))
                })}
                onReset={handleCancel}
                onSubmit={(values) => {
                  handleVerifyPopupSubmit(values)
                }}
                render={({ values, setFieldValue, resetForm }) => {
                  return (
                    <Form id="frm_phoneVerify">
                      <button type="reset" className={'close remove-modal-close'}>
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="label-text">
                              <ErrorMessage name="txt_phone" component="span" className="form-error" />
                            </label>
                            <Field
                              className="form-control form-control-lg"
                              type="text"
                              placeholder={t('home.phone-number')}
                              name="txt_phone"
                              value={values.txt_phone ? numberMask(values.txt_phone.replace('+1', '')) : ''}
                              onChange={(event) => {
                                setFieldValue('txt_phone', numberMask(event.target.value))
                              }}
                              id="frm_phoneVerifyPhone_txtPhone"
                              maxLength={14}
                            />
                          </div>
                        </div>
                      </div>
                      <footer className="d-block text-center mt-20">
                        <button
                          type="submit"
                          disabled={submitting}
                          className={'btn btn-primary btn-lg btn-block d-block border-0'}
                          id="frm_phoneVerifyPhone_btnNext">
                          {' '}
                          {t('common.next')}
                          {submitting && (
                            <i className="btn-icon top-minus-2">
                              <img
                                className="spinner"
                                src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                                alt=""
                              />
                            </i>
                          )}
                        </button>
                        <button
                          type="reset"
                          style={{ width: 'fit-content' }}
                          id="frm_phoneVerifyPhone_btnSkip"
                          className={'btn btn-lg mx-auto btn-block d-block btn-link text-decoration-none'}>
                          {' '}
                          {t('common.skip')}
                        </button>
                      </footer>
                    </Form>
                  )
                }}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div className="modal-header d-block border-0 position-relative justify-content-center pb-15 pl-0 pr-0">
                <div className="mb-0">
                  <div className="text-center mb-5">
                    <img
                      alt=""
                      className="mb-20"
                      src="https://dufzo4epsnvlh.cloudfront.net/image/icon-new/verify-icon.svg"
                    />
                    <h2 className="mb-10 fz-24">{t('home.enter-verification-code')}</h2>
                  </div>
                  <p className="text-center ">
                    {t('home.a-4-digit-code-has-been-sent-to')}
                    <span className="d-block">{verifyPopupData.contact_phone}</span>
                  </p>
                </div>
              </div>
              <Formik
                validateOnBlur={false}
                validateOnChange={false}
                enableReinitialize
                initialValues={{
                  txt_otp: ''
                }}
                validationSchema={Yup.object().shape({
                  txt_otp: Yup.string().required(t('bookings.required'))
                })}
                onReset={handleCancel}
                onSubmit={handleOtpSubmit}
                render={({ values, setFieldValue, resetForm }) => {
                  return (
                    <Form>
                      <button type="reset" className={'close remove-modal-close'}>
                        <span aria-hidden="true">×</span>
                      </button>
                      <div className="row">
                        <div className="col-12">
                          <div className="form-group">
                            <label className="d-block text-center">
                              {t('home.enter-the-4-digit-verification-code')}
                              <ErrorMessage name="txt_otp" component="span" className="form-error" />
                            </label>
                            <Field
                              className="form-control form-control-lg"
                              type="text"
                              name="txt_otp"
                              maxLength={4}
                              placeholder={t('home.enter-verification-code')}
                            />
                            <div className="text-center mt-10">
                              <span>{t('home.we-just-sent-a-text-message-to')}</span>
                              <span className="d-block">{verifyPopupData.contact_phone}</span>
                              {noOfRetries < 3 && (
                                <div className={`${Style['receive-code']}`}>
                                  {t('home.didn-and-apos-t-receive-the-code')}
                                  {timer > 0
                                    ? t('messenger.resend-code-in-timer-seconds', {
                                        0: timer
                                      })
                                    : ' ?'}
                                  <Box>
                                    <Button
                                      disabled={isResendCodeDisabled && timer !== 0}
                                      variant="text"
                                      onClick={handleResend}
                                      className={`border-0 ${Style['undo-btn']}`}>
                                      <i className={`${Style['icons-undo']}`}>
                                        <Icons name="undo" />
                                      </i>
                                      {t('home.resend-code')}
                                    </Button>
                                    <Button
                                      disabled={isResendCodeDisabled && timer !== 0}
                                      variant="text"
                                      onClick={handleOtpOnCall}
                                      className={`border-0 ${Style['undo-btn']}`}>
                                      <i className={`${Style['icons-undo']}`}>
                                        <Icons name="undo" />
                                      </i>
                                      {t('home.otp-on-call')}
                                    </Button>
                                  </Box>
                                </div>
                              )}
                              {showReCaptcha ? (
                                <div className="col-12 pt-20">
                                  <ReCAPTCHA
                                    sitekey={config.googleReCAPTCHAKey}
                                    onChange={(val) => onChangeRecaptche(val)}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                      <footer className="d-block mt-10">
                        <button
                          type="submit"
                          disabled={verifyPopupData.otpSubmitting || (showReCaptcha && !capthcaValue)}
                          className={'btn btn-primary btn-lg btn-block border-0 d-block'}
                          id="get-paid">
                          {' '}
                          {t('home.next')}
                          {verifyPopupData.otpSubmitting && (
                            <i className="btn-icon top-minus-2">
                              <img
                                className="spinner"
                                src="https://dufzo4epsnvlh.cloudfront.net/image/default.svg"
                                alt=""
                              />
                            </i>
                          )}
                        </button>
                        <button
                          type="reset"
                          style={{ width: 'fit-content' }}
                          className={'btn btn-lg mx-auto btn-block d-block btn-link text-decoration-none'}>
                          {' '}
                          {t('messenger.skip')}
                        </button>
                      </footer>
                    </Form>
                  )
                }}
              />
            </React.Fragment>
          )}
        </Popup>
      )}
    </div>
  )
}
