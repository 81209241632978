import config from "@config/index";
import { api, catchHandler } from "@helpers/axios";
import {objToUrlParams} from "@helpers/index"

export function getWidgets(payload) {
    return api()
        .get(`${config.routes.getWidgets}/list`, { params: payload })
        .then(res => res.data)
        .catch(catchHandler)
}
export function deleteWidgets(id) {
    return api()
        .delete(`${config.routes.getWidgets}/${id}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getWidgetsDaetsils(id) {
    return api()
        .get(`${config.routes.getWidgets}/${id}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getCustomerProducts() {
    return api()
        .get(`${config.routes.customerProducts}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function createWidgets(payload) {
    return api()
        .post(`${config.routes.getWidgets}/create`,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function updateWidgets(payload) {
    return api()
        .put(`${config.routes.getWidgets}/update`,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getSettings(user, query) {
    return api()
        .get(`${config.routes.getAccountSettings}${user}?${objToUrlParams(query)}`)
        .then(res => res.data)
        .catch(catchHandler);
}
export function getBusinessSettings() {
    return api()
        .get(`${config.routes.businessSettings}`)
        .then(res => res.data)
        .catch(catchHandler);
}
export function updateMessengerSettings(payload) {
    return api()
        .put(`${config.routes.updateAccountSettings}/${payload.id}?${objToUrlParams(payload.query)}`,payload.payload)
        .then(res => res.data)
        .catch(catchHandler);
}
export function updateSettings(userId, customId, data) {
    return api()
        .put(`${config.routes.updateAccountSettings}/${userId}?${objToUrlParams(customId)}`, data)
        .then(res => res.data)
        .catch(catchHandler)
}
export function addBookingSettings(id, payload) {
    return api()
        .post(`${config.routes.bookingButtonWidget}?${objToUrlParams(id)}`,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function createReviewWidget(payload) {
    return api()
        .post(config.routes.createReviewWidget,payload)
        .then(res => res.data)
        .catch(catchHandler);
}
export function getReviewWidgets(payload) {
    return api()
        .get(`${config.routes.reviewWidgets}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function removeReviewWidget(payload){
    return api()
        .put(config.routes.removeReviewWidget, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getContactList(payload) {
    return api()
        .get(`${config.routes.contactList}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
