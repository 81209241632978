import { api, catchHandler } from "@helpers/axios";
import config from "@config/index";
import {objToUrlParams} from "@helpers/index"

export function reportAdmin(payload) {
    return api()
        .post(config.routes.reportAdmin, payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getPredefinedTemplate(payload) {
    return api()
        .get(`${config.routes.predefinedTemplate}${payload.id}`,{ params: payload.query })
        .then(res => res.data)
        .catch(catchHandler)
}
export function getWebsiteSubscriptionData(payload){
    return api()
        .get(`${config.routes.websiteSubscription}/${payload.id}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getWebsiteLeadData(payload){
    return api()
        .get(`${config.routes.websiteLead}/${payload.id}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getWebsiteAnalyticsSummary(payload){
    return api()
        .get(`${config.routes.websiteAnalytics}?${objToUrlParams(payload)}`)
        .then(res => res.data)
        .catch(catchHandler)
}
export function activateProduct(payload){
    return api()
        .post(config.routes.trialActivate,payload)
        .then(res => res.data)
        .catch(catchHandler)
}
export function getEmailTemplate(id){
    return api(true)
        .get(`${config.routes.viewEmail}/${id}`)
        .then(res => res.data)
        .catch(catchHandler)
}
